import React from 'react';
import nextId from 'react-id-generator';
import { Typography } from '@ui';
import DemographicsSection from '../components/DemographicsSection';
import FinancialSection from '../components/FinancialSection';
import InterestsSection from '../components/InterestsSection';
import PurchaseSection from '../components/PurchaseSection';

export const sections = [
    {
        key: nextId(),
        label: (
            <Typography.Title data-e2e='insights_demographics_tab' level={3} align='center'>
                Demographics
            </Typography.Title>
        ),
        children: <DemographicsSection />
    },
    {
        key: nextId(),
        label: (
            <Typography.Title data-e2e='insights_financial_tab' level={3} align='center'>
                Financial
            </Typography.Title>
        ),
        children: <FinancialSection />
    },
    {
        key: nextId(),
        label: (
            <Typography.Title data-e2e='insights_purchase_tab' level={3} align='center'>
                Purchase
            </Typography.Title>
        ),
        children: <PurchaseSection />
    },
    {
        key: nextId(),
        label: (
            <Typography.Title data-e2e='insights_interests_tab' level={3} align='center'>
                Interests
            </Typography.Title>
        ),
        children: <InterestsSection />
    }
];
