import React from 'react';
import { COLORS } from '@constants';
import { Button } from '../../../../../Button';
import { Icon } from '../../../../../Icon';

const PaginationNext = ({ currentPage, totalPages }) => {
    return (
        <Button data-e2e='pagination_next_btn' disabled={currentPage === totalPages}>
            <Icon size='24px' color={COLORS.$gray60Black} component={() => <Icon.ArrowForwardIcon />} />
        </Button>
    );
};

export default PaginationNext;
