import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledFontExample = styled.div`
    font-family: ${({ $font }) => ($font?.toLowerCase() === 'arial' ? 'Arial' : 'Times New Roman')};
    color: ${COLORS.$gray100Black};
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 96px;
    text-align: center;
`;
