import React from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS, ROUTE_PATHS } from '@constants';
import { Button, Icon, Typography } from '@ui';
import { Flex } from 'antd';

const EmptyState = () => {
    const navigate = useNavigate();
    return (
        <Flex vertical style={{ flexGrow: 1 }}>
            <Typography.Text data-e2e='campaigns_page_title' type='em0'>
                Campaigns
            </Typography.Text>
            <Flex align='center' justify='center' style={{ flexGrow: 1 }}>
                <Flex data-e2e='campaigns_empty-state_content' vertical align='center' gap={24}>
                    <Typography.Title data-e2e='campaigns_no-campaigns_text' level={2} color={COLORS.$gray60Black}>
                        You have no launched campaigns
                    </Typography.Title>
                    <Button
                        data-e2e='campaigns_add-new-campaign_btn'
                        onClick={() => navigate(ROUTE_PATHS.CAMPAIGNS_CREATE_STEP_1)}
                        type='primary'
                        size='large'
                        iconRight={<Icon size='24px' color={COLORS.$white} component={() => <Icon.PlusIcon />} />}
                    >
                        Add New Campaign
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default EmptyState;
