import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { COLORS, ROUTE_PATHS } from '@constants';
import { useStore } from '@hooks';
import { Loader } from '@ui';
import { Flex } from 'antd';
import { observer } from 'mobx-react';
import styled from 'styled-components';

export const StyledWrapper = styled(Flex)`
    width: 100%;
    padding-inline: 24px;
    height: 100vh;
    background: ${COLORS.$gray10Black};
`;

const OauthBigCommerceCallback = observer(() => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const store = useStore();
    const { oauthPayload, setOauthPayload } = store.authStore;

    useEffect(() => {
        const params = oauthPayload || Object.fromEntries([...searchParams]);

        const hasRequiredParams = params.account_uuid && params.code && params.context && params.scope;

        if (!hasRequiredParams) {
            navigate(ROUTE_PATHS.REQUEST_ACCESS);
        }

        if (!oauthPayload) {
            setOauthPayload(params);
        }

        navigate(ROUTE_PATHS.CONNECTOR);
    }, []);

    return (
        <StyledWrapper justify='center' align='center'>
            <Loader size='120px' />
        </StyledWrapper>
    );
});

export default OauthBigCommerceCallback;
