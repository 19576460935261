export const PERFORMANCE_METRICS = [
    'roas',
    'cpa',
    'revenue',
    'orders',
    'aov',
    'spend',
    'audience',
    'views',
    'interactions'
];

export const METRICS = {
    campaign: {
        title: 'Campaign',
        dataIndex: 'campaign',
        key: 'campaign',
        width: '28%',
        active: true
    },
    revenue: {
        color: 3,
        title: 'Revenue',
        dataIndex: 'revenue',
        key: 'revenue',
        tooltip: 'The total amount of money generated from attributed orders in the given time period',
        category: 'Summary',
        active: true
    },
    spend: {
        color: 6,
        title: 'Spend',
        dataIndex: 'spend',
        key: 'spend',
        tooltip: 'The total amount of money spent in the given time period',
        category: 'Summary',
        active: true
    },
    roas: {
        color: 1,
        title: 'ROAS',
        dataIndex: 'roas',
        key: 'roas',
        tooltip: 'The Revenue divided by Spend for the given time period',
        category: 'Summary',
        active: true
    },
    cpa: {
        color: 2,
        title: 'CPA',
        dataIndex: 'cpa',
        key: 'cpa',
        tooltip: 'The Spend divided by the number of orders for the given time period',
        category: 'Summary',
        active: false
    },
    views: {
        color: 8,
        title: 'Views',
        dataIndex: 'views',
        key: 'views',
        tooltip:
            'The total amount of times products were viewed in Klickly marketing messages in the given time period',
        category: 'Engagements details',
        active: true
    },
    interactions: {
        color: 9,
        title: 'Interactions',
        dataIndex: 'interactions',
        key: 'interactions',
        tooltip:
            'The total number of user-generated actions (i.e. excluding product views) occurring inside a marketing message within the given time period',
        category: 'Engagements details',
        active: true
    },
    audience: {
        color: 7,
        title: 'Audience',
        dataIndex: 'audience',
        key: 'audience',
        tooltip:
            'The total number of unique views and interactions (by unique SID (session ID)) with a Klickly marketing message in the given time period',
        category: 'Engagements details',
        active: false
    },
    orders: {
        color: 4,
        title: 'Orders',
        dataIndex: 'orders',
        key: 'orders',
        tooltip: 'The total number of attributed orders for the given time period',
        category: 'Order details',
        active: true
    },
    total_products: {
        title: 'Products sold',
        dataIndex: 'totalProducts',
        key: 'total_products',
        tooltip: 'The total number of products sold in the given time period',
        category: 'Order details',
        active: false
    },
    new_customers: {
        title: 'New customers',
        dataIndex: 'newCustomers',
        key: 'new_customers',
        tooltip:
            "The total number of orders generated by customers who hadn't previously purchased (based on email address)", // eslint-disable-line
        category: 'Order details',
        active: false
    },
    aov: {
        color: 5,
        title: 'AOV',
        dataIndex: 'aov',
        key: 'aov',
        tooltip: 'The average revenue per attributed order',
        category: 'Order details',
        active: false
    },
    avg_days_from_first_touch_point: {
        title: 'Avg. days from first touchpoint',
        dataIndex: 'avgDaysFromFirstTouchPoint',
        key: 'avg_days_from_first_touch_point',
        tooltip:
            'The average number of days between a customer’s first Klickly marketing message and their purchase date',
        category: 'Order details',
        active: false
    },
    avg_days_from_last_touch_point: {
        title: 'Avg. days from last touchpoint',
        dataIndex: 'avgDaysFromLastTouchPoint',
        key: 'avg_days_from_last_touch_point',
        tooltip:
            'The average number of days between a customer’s last Klickly marketing message and their purchase date',
        category: 'Order details',
        active: false
    }
};
