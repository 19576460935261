import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorMessage } from '@components';
import { BOTTOM_INDENTS, COLORS, ROUTE_PATHS, TOP_INDENTS, VALIDATION_RULES } from '@constants';
import { useStore } from '@hooks';
import { Button, Icon, Input, RedirectLink } from '@ui';
import { errorHandler } from '@utils';
import { Flex, Form } from 'antd';
import { observer } from 'mobx-react';

export const LoginForm = observer(() => {
    const store = useStore();
    const { signIn, error, oauthPayload, embeddedLoadPayload, hasUser } = store.authStore;
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const [formSubmitting, setFormSubmitting] = useState(false);

    const from = location.state?.from?.pathname || ROUTE_PATHS.HOME;

    const onSubmit = async (values) => {
        setFormSubmitting(true);

        if (!hasUser && (embeddedLoadPayload || oauthPayload)) {
            return navigate(ROUTE_PATHS.REQUEST_ACCESS);
        }

        try {
            const response = await signIn(values);

            if (embeddedLoadPayload) {
                return navigate(ROUTE_PATHS.APP_EMBEDDED);
            }

            if (oauthPayload) {
                return navigate(ROUTE_PATHS.CONNECTOR);
            }

            if (response?.isConnectorPending) {
                navigate(ROUTE_PATHS.CONNECTOR);
            } else {
                navigate(from, { replace: true });
            }
        } catch (error) {
            errorHandler({ error });
        }

        setFormSubmitting(false);
    };

    return (
        <React.Fragment>
            <Form data-e2e='login_form' form={form} onFinish={onSubmit}>
                <Form.Item
                    name='email'
                    rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.EMAIL]}
                    style={BOTTOM_INDENTS.S}
                >
                    <Input data-e2e='login_email_input' type='email' label='Email' />
                </Form.Item>
                <Form.Item name='password' rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.PASSWORD]}>
                    <Input
                        data-e2e='login_password_input'
                        type='password'
                        label='Password'
                        suffix={
                            <Icon
                                data-e2e='login_show-password_icon'
                                size='24px'
                                color={COLORS.$gray20Black}
                                component={() => <Icon.LockIcon />}
                            />
                        }
                    />
                </Form.Item>
                <Form.Item style={TOP_INDENTS.L}>
                    <Button
                        data-e2e='login_login_btn'
                        htmlType='submit'
                        type='primary'
                        size='large'
                        block
                        justify='between'
                        loading={formSubmitting}
                        iconRight={
                            <Icon size='24px' color={COLORS.$white} component={() => <Icon.ArrowForwardIcon />} />
                        }
                    >
                        Log in
                    </Button>
                </Form.Item>
            </Form>
            {error && <ErrorMessage message={error.description} />}
            <Flex style={TOP_INDENTS.L} justify='center'>
                <RedirectLink
                    data-e2e='login_forgot-password_link'
                    to={ROUTE_PATHS.FORGOT_PASSWORD}
                    text='Forgot Password?'
                    fontSize='16px'
                />
            </Flex>
        </React.Fragment>
    );
});
