import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchSuffix } from '@components';
import { BOTTOM_INDENTS, COLORS, ROUTE_PATHS } from '@constants';
import { useDebounce, useStore } from '@hooks';
import { Button, Icon, Input, Typography } from '@ui';
import { Flex } from 'antd';

const Header = ({ setSearchValue }) => {
    const ref = useRef(null);
    const navigate = useNavigate();

    const store = useStore();
    const { isActive } = store.accountsStore;

    const handleSearch = useDebounce((e) => {
        setSearchValue(e.target.value);
    }, 1000);

    return (
        <Flex justify='space-between' align='center' style={BOTTOM_INDENTS.XL_PLUS}>
            <Typography.Text data-e2e='campaigns_page_title' type='em0'>
                Campaigns
            </Typography.Text>
            <Flex gap={24} align='center'>
                <Input
                    data-e2e='campaigns_search-campaigns_input'
                    style={{ width: 314 }}
                    ref={ref}
                    value={ref?.current?.value}
                    label='Search campaigns'
                    onChange={handleSearch}
                    suffix={<SearchSuffix inputRef={ref} onSetSearchValue={setSearchValue} />}
                />
                {isActive && (
                    <Button
                        data-e2e='campaigns_add-new-campaign_btn'
                        onClick={() => navigate(ROUTE_PATHS.CAMPAIGNS_CREATE_STEP_1)}
                        type='primary'
                        size='large'
                        iconRight={<Icon size='24px' color={COLORS.$white} component={() => <Icon.PlusIcon />} />}
                    >
                        Add New Campaign
                    </Button>
                )}
            </Flex>
        </Flex>
    );
};

export default Header;
