import styled from 'styled-components';

export const StyledLoader = styled.div`
    position: ${({ $position }) => $position || 'absolute'};
    bottom: ${({ $bottom }) => $bottom || 0};
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ $overlayColor }) => $overlayColor || 'transparent'};
    z-index: ${({ $zIndex }) => $zIndex};
    border-radius: ${({ $borderRadius }) => $borderRadius || '0px'};
`;
