import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BOTTOM_INDENTS, FIELD_NAME, ROUTE_PATHS } from '@constants';
import { CAMPAIGN_TYPE_ID } from '@enums';
import { useStore } from '@hooks';
import { Loader, Typography } from '@ui';
import { Form, Layout } from 'antd';
import { observer } from 'mobx-react';
import { useDebouncedCallback } from 'use-debounce';
import { CAMPAIGN_TYPES } from '../../constants';
import { useFormInit } from '../../form';
import CampaignTypeCards from './components/CampaignTypeCards';
import EmptyState from './components/EmptyState';
import { PAGE } from './constants';
import { useCampaignTypes } from './hooks';

const { Content } = Layout;

const Step1 = observer(() => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);

    const store = useStore();
    const campaignWizardStore = store.campaignWizardStore;
    const {
        campaign,
        createCampaign,
        pending,
        setFormSubmitting,
        setIsNextLoading,
        setIsNextDisabled,
        formSubmitting,
        getCampaign,
        calculatePriority,
        setPriorityPending
    } = store.campaignWizardStore;
    const {
        getAccountProductTypes,
        getAccountCollections,
        currentAccount: { productTypes, platformId }
    } = store.accountsStore;
    const { products, getProducts } = store.productsStore;

    const form = useFormInit();

    const selectedType = Form.useWatch(FIELD_NAME.CAMPAIGN.TYPE, form);

    useEffect(() => {
        (async () => {
            const promises = [
                getProducts(),
                !productTypes.length ? getAccountProductTypes() : null,
                getAccountCollections()
            ].filter(Boolean);

            await Promise.all(promises);
            if (campaign) {
                form.setFieldValue(FIELD_NAME.CAMPAIGN.TYPE, campaign.catalogType);
            }
            setLoading(false);
        })();
    }, []);

    const campaignTypes = useCampaignTypes();

    const debouncedRecalculate = useDebouncedCallback((options) => {
        calculatePriority(options);
    }, 1000);

    useEffect(() => {
        if (selectedType) {
            setPriorityPending(true);
            const catalogType = form.getFieldValue(FIELD_NAME.CAMPAIGN.TYPE);
            const productTypes = campaignTypes.specificProductTypes?.expandedContentProps.selectedListItems.map(
                (item) => item.id
            );
            const productCollections = campaignTypes.specificCollections?.expandedContentProps.selectedListItems.map(
                (item) => item.id
            );
            debouncedRecalculate({ campaignId: 0, values: { catalogType, productTypes, productCollections } });
        }
    }, [
        selectedType,
        campaignTypes.specificProductTypes?.expandedContentProps.selectedListItems.length,
        campaignTypes.specificCollections?.expandedContentProps.selectedListItems.length
    ]);

    let nextDisabled = !selectedType;

    if (selectedType === CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCT_TYPES) {
        const { selectedListItems } = campaignTypes.specificProductTypes?.expandedContentProps ?? {};
        nextDisabled = !selectedType || !selectedListItems.length;
    }

    if (selectedType === CAMPAIGN_TYPE_ID.SPECIFIC_COLLECTIONS) {
        const { selectedListItems } = campaignTypes.specificCollections?.expandedContentProps ?? {};
        nextDisabled = !selectedType || !selectedListItems.length;
    }

    const nextLoading = pending || formSubmitting;

    useEffect(() => {
        setIsNextLoading(nextLoading);
    }, [nextLoading]);

    useEffect(() => {
        setIsNextDisabled(nextDisabled);
    }, [nextDisabled]);

    const onFinish = async (values) => {
        const fromCampaign = searchParams.get('fromCampaign');

        if (fromCampaign && form.getFieldValue(FIELD_NAME.CAMPAIGN.TYPE) === campaignWizardStore.campaign.catalogType) {
            return navigate(`${ROUTE_PATHS.CAMPAIGNS_CREATE}/step-2/${campaignWizardStore.campaign.id}`);
        }

        setFormSubmitting(true);

        if (values[FIELD_NAME.CAMPAIGN.TYPE] === CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCT_TYPES) {
            const { selectedListItems } = campaignTypes.specificProductTypes?.expandedContentProps ?? {};
            const productTypeIds = selectedListItems.map((item) => item.id);

            values.productTypes = productTypeIds;
        }

        if (values[FIELD_NAME.CAMPAIGN.TYPE] === CAMPAIGN_TYPE_ID.SPECIFIC_COLLECTIONS) {
            const { selectedListItems } = campaignTypes.specificCollections?.expandedContentProps ?? {};
            const productCollectionIds = selectedListItems.map((item) => item.id);

            values.productCollections = productCollectionIds;
        }

        const title = CAMPAIGN_TYPES.find(({ value }) => value === values[FIELD_NAME.CAMPAIGN.TYPE]).label;
        await createCampaign({ title, ...values, prevCampaign: fromCampaign });

        const { campaign, error } = campaignWizardStore;

        if (!error) {
            const campaignId = campaignWizardStore.campaign.id;
            await getCampaign(campaignId);

            setFormSubmitting(false);

            navigate(`${ROUTE_PATHS.CAMPAIGNS_CREATE}/step-2/${campaign.id}`);
        }

        setFormSubmitting(false);
    };

    if (loading) {
        return <Loader size='120px' />;
    }

    if (!products.length) {
        return <EmptyState />;
    }

    return (
        <Content tagName='div'>
            <Typography.Title data-e2e='campaignwizard_step-1_title' style={BOTTOM_INDENTS.M}>
                {PAGE.TITLE}
            </Typography.Title>
            <Form form={form} onFinish={onFinish}>
                <CampaignTypeCards
                    platformId={platformId}
                    campaignTypes={Object.values(campaignTypes)}
                    selectedType={selectedType}
                />
            </Form>
        </Content>
    );
});

export default Step1;
