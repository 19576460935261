import React from 'react';
import { COLORS } from '@constants';
import { Icon, Typography } from '@ui';
import { Flex, Tooltip as AntdTooltip } from 'antd';
import { TableTitleIcon } from '../TableTitleIcon/styled';
import { StyledWrapper } from './styled';

export const TableTitleTooltip = ({ text, trigger = 'hover', ...props }) => {
    const title = (
        <div data-e2e='orders_date-tooltip_content'>
            <Flex color={COLORS.$white} vertical gap={16}>
                <Typography.Text data-e2e='orders_date-tooltip_header' color={COLORS.$white} type='headline3'>
                    {text}
                </Typography.Text>
                <Flex justify='space-between'>
                    <Typography.Text data-e2e='orders_date-tooltip-timezone_label' color={COLORS.$white} type='body2'>
                        Timezone:
                    </Typography.Text>
                    <Typography.Text data-e2e='orders_date-tooltip-timezone_value' type='body2' color={COLORS.$white}>
                        UTC +2
                    </Typography.Text>
                </Flex>
            </Flex>
        </div>
    );

    return (
        <AntdTooltip overlayStyle={{ minWidth: 278 }} zIndex={20} title={title} trigger={trigger} {...props}>
            <StyledWrapper>
                <Icon
                    data-e2e='orders_date-tooltip_icon'
                    size='16px'
                    component={() => <TableTitleIcon color={COLORS.$gray40Black} />}
                />
            </StyledWrapper>
        </AntdTooltip>
    );
};
