import React from 'react';
import { Divider } from '@ui';
import { Flex } from 'antd';
import MenuAccountItem from '../MenuAccountItem';

const MenuAccountItems = ({ items }) => {
    return (
        <Flex data-e2e='header_user-dropdown-menu_accounts' component='ul' vertical style={{ width: 224 }}>
            {items.map((item, i) => {
                return (
                    <React.Fragment key={i}>
                        <MenuAccountItem item={item} />
                        {i !== items.length - 1 && <Divider margin='0' />}
                    </React.Fragment>
                );
            })}
        </Flex>
    );
};

export default MenuAccountItems;
