import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledLabel = styled.label`
    display: flex;
    align-items: center;
    justify-content: start;
    height: ${({ $height }) => $height || '56px'};
    cursor: ${({ $cursor }) => $cursor || 'pointer'};

    &:hover {
        label:not(:has(input:disabled)) {
            border: 2px solid ${({ $color }) => $color || COLORS.$pink};
        }

        label:has(input:checked) {
            border: 2px solid ${({ $color }) => $color || COLORS.$gray40Black};
        }
    }

    p {
        margin-left: ${({ $labelLeftIndent }) => $labelLeftIndent || '16px'};
        color: ${({ $labelColor }) => $labelColor || COLORS.$gray60Black};
    }

    &:has(input:checked) {
        p {
            color: ${({ $labelColor }) => $labelColor || COLORS.$gray100Black};
        }
    }
`;
