import React, { Fragment, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CancelSave } from '@components';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { useStore } from '@hooks';
import { Loader, Typography } from '@ui';
import { showFormNotification } from '@utils';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import CustomizeUTM from './components/CustomizeUTM';
import EnableGoogleAnalytics from './components/EnableGoogleAnalytics';
import TWSettings from './components/TWSettings';
import { mapFormToSettings, mapSettingsToForm } from './utils';

const Tracking = observer(() => {
    const store = useStore();
    const [form] = Form.useForm();
    const { getTrackingSettings, updateTrackingSettings } = store.accountsStore;
    const [searchParams] = useSearchParams();
    const showtw = Number(searchParams.get('tw')) === 1;

    const [loading, setLoading] = useState(true);
    const [formSubmitting, setFormSubmitting] = useState(false);

    const resetForm = () => {
        const { tripleWhaleSettings, utmSettings, ga4Settings } = store.accountsStore.currentAccount;
        form.setFieldsValue(mapSettingsToForm({ utmSettings, ga4Settings, tripleWhaleSettings }));
    };

    useEffect(() => {
        (async () => {
            await getTrackingSettings();
            resetForm();
            setLoading(false);
        })();
    }, []);

    const handleFormSubmit = async (values) => {
        setFormSubmitting(true);

        await updateTrackingSettings(mapFormToSettings(values));

        resetForm();
        setFormSubmitting(false);
        showFormNotification(store.accountsStore.error);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Fragment>
            <Typography.Title data-e2e='tracking_page_title' level={2} style={BOTTOM_INDENTS.M}>
                Account Tracking
            </Typography.Title>
            <Typography.Text
                data-e2e='tracking_page_description'
                type='body2'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.M}
            >
                Your account tracking includes both UTMs and GA-specific tracking tools.
            </Typography.Text>
            <Form form={form} onFinish={handleFormSubmit} scrollToFirstError={{ behavior: 'smooth', block: 'center' }}>
                <CustomizeUTM style={BOTTOM_INDENTS.M} />
                <EnableGoogleAnalytics style={BOTTOM_INDENTS.M} />
                {showtw && <TWSettings style={BOTTOM_INDENTS.M} />}
                <CancelSave onCancel={resetForm} loading={formSubmitting} />
            </Form>
        </Fragment>
    );
});

export default Tracking;
