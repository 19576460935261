import nextId from 'react-id-generator';
import { brandsOpenApi, COLORS, defaultTimezoneOffset } from '@constants';
import { axiosBoa, getErrorMessage } from '@utils';
import dayjs from 'dayjs';
import * as fileDownload from 'js-file-download';
import { flow, getParent, types } from 'mobx-state-tree';
import { graphColorsMap } from '../pages/Performance/components/Overview/constants';
import { METRICS, PERFORMANCE_METRICS } from '../pages/Performance/constants';
import { detailizationByKey } from '../pages/Performance/utils';
import { models } from './constants';

export const PerformanceStore = types
    .model('PerformanceStore', {
        performanceList: types.array(models.PerformanceModel),
        reportsList: types.array(models.ReportsModel),
        metrics: types.array(models.MetricsModel),
        detailizationTab: types.maybeNull(types.string),
        selectedChartMetrics: types.array(types.string),
        chartLabels: types.array(types.string),
        chartDatasets: types.frozen(),
        performanceCampaigns: types.array(
            types.model({
                id: types.maybeNull(types.number),
                title: types.maybeNull(types.string)
            })
        ),
        liveCampaigns: types.array(
            types.model({
                revenue: types.maybeNull(types.number),
                roas: types.maybeNull(types.string),
                id: types.maybeNull(types.number),
                title: types.maybeNull(types.string),
                priority: types.maybeNull(types.number),
                account: types.model({
                    shortId: types.maybeNull(types.string)
                }),
                views: types.maybeNull(types.number),
                spend: types.maybeNull(types.number)
            })
        ),
        selectedReportMetrics: types.array(
            types.model({
                title: types.maybeNull(types.string),
                dataIndex: types.maybeNull(types.string),
                key: types.maybeNull(types.string),
                width: types.maybeNull(types.string),
                category: types.maybeNull(types.string),
                tooltip: types.maybeNull(types.string),
                active: types.maybeNull(types.boolean),
                buffer: types.maybeNull(types.boolean),
                default: types.maybeNull(types.boolean)
            })
        ),
        overviewPending: true,
        pendingLiveCampaigns: false,
        pendingMetricsChart: false,
        pendingMetricsOverview: false,
        pendingReports: true,
        pendingBreakdowns: true
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get liveCampaignsList() {
            return this.liveCampaigns.slice(0, 3);
        },
        get activeReportMetrics() {
            return this.selectedReportMetrics.filter((item) => item.active);
        }
    }))
    .actions((self) => ({
        setDefaultReportMetrics: () => {
            self.selectedReportMetrics = Object.values(METRICS);
        },
        setBufferReportMetrics: (checked, item) => {
            const foundIndex = self.selectedReportMetrics.findIndex((x) => x.key === item.key);
            self.selectedReportMetrics[foundIndex].buffer = checked;
        },
        resetBufferReportMetric: () => {
            self.selectedReportMetrics = self.selectedReportMetrics.map((column) => {
                return { ...column, buffer: null };
            });
        },
        applyReportMetric: () => {
            self.selectedReportMetrics = self.selectedReportMetrics.map((column) => {
                return !Object.is(column?.buffer, null) && !Object.is(column?.buffer, undefined)
                    ? { ...column, active: column.buffer, buffer: null }
                    : column;
            });
        },
        setDetailizationTab: (tab) => {
            self.detailizationTab = tab ?? '1';
        },
        addMetricChart: (label) => {
            self.selectedChartMetrics = [...self.selectedChartMetrics, label];
        },
        deleteMetricChart: (label) => {
            if (self.selectedChartMetrics.length > 1) {
                self.chartDatasets = self.chartDatasets.filter((item) => item.label !== label);
                self.selectedChartMetrics = self.selectedChartMetrics.filter((item) => item !== label);
            }
        },
        getOverview: flow(function* getOverview(props = {}) {
            self.overviewPending = true;
            try {
                yield self.getLiveCampaigns(props);
                yield self.getChartData(props);
                yield self.getMetricsOverview(props);
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.overviewPending = false;
            }
        }),
        getCampaignsForPerformance: flow(function* getCampaignsForPerformance() {
            try {
                const response = yield axiosBoa.get(brandsOpenApi.performance.campaigns.list, {
                    params: {
                        pagination: 'none'
                    }
                });

                self.performanceCampaigns = response.data;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        getLiveCampaigns: flow(function* getLiveCampaigns(props = {}) {
            self.pendingLiveCampaigns = true;
            try {
                const params = {
                    timezone_offset: defaultTimezoneOffset,
                    date_from: props.date_from,
                    date_to: props.date_to
                };

                self.liveCampaigns = yield axiosBoa.get(brandsOpenApi.performance.liveCampaigns.get, { params });
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pendingLiveCampaigns = false;
            }
        }),
        getMetricsOverview: flow(function* getMetricsOverview(props = {}) {
            self.pendingMetricsOverview = true;
            try {
                const params = { timezone_offset: defaultTimezoneOffset, ...props };
                self.performanceList = yield axiosBoa.get(brandsOpenApi.performance.metricsOverview.get, { params });
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pendingMetricsOverview = false;
            }
        }),
        getMetricChart: flow(function* getMetricChart(metric, params) {
            const result = yield axiosBoa.get(brandsOpenApi.performance.metricsChart.get(metric), { params });

            const labels = result.labels.map((value) => {
                return self.detailizationTab === '3'
                    ? dayjs(value.replace('/', '/01/')).format('MM/YYYY')
                    : dayjs(value).format('D');
            });

            const metricColorId = METRICS[result.datasets[0].label].color;
            const datasets = [
                {
                    ...result.datasets[0],
                    borderColor: graphColorsMap[metricColorId],
                    backgroundColor: COLORS.$white,
                    dates: result.labels.map((value) => {
                        return self.detailizationTab === '3'
                            ? dayjs(value.replace('/', '/01/')).format('MMM YYYY')
                            : dayjs(value).format('D MMM YYYY');
                    })
                }
            ];

            return { labels, datasets };
        }),
        getChartData: flow(function* getChartData(props = {}) {
            self.pendingMetricsChart = true;
            try {
                const params = {
                    timezone_offset: defaultTimezoneOffset,
                    date_trunc: detailizationByKey(self.detailizationTab),
                    ...props
                };

                if (!props.selectedMetric && self.selectedChartMetrics.length > 1) {
                    const datasets = [];
                    let labels;

                    for (const metric of self.selectedChartMetrics) {
                        const { labels: metricLabels, datasets: metricDatasets } = yield self.getMetricChart(
                            metric,
                            params
                        );
                        labels = metricLabels;
                        datasets.push(...metricDatasets);
                    }

                    self.chartDatasets = datasets;
                    self.chartLabels = labels;
                } else {
                    const metric = props.selectedMetric || self.selectedChartMetrics[0];
                    const { labels: metricLabels, datasets: metricDatasets } = yield self.getMetricChart(
                        metric,
                        params
                    );

                    self.chartDatasets = [
                        ...self.chartDatasets.filter((item) => item.label !== metricDatasets[0].label),
                        ...metricDatasets
                    ];

                    if (JSON.stringify(self.chartLabels) !== JSON.stringify(metricLabels)) {
                        self.chartLabels = metricLabels;
                    }
                }
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pendingMetricsChart = false;
            }
        }),
        downloadChartData: flow(function* downloadChartData(props) {
            try {
                const params = {
                    download: true,
                    timezone_offset: defaultTimezoneOffset,
                    date_trunc: detailizationByKey(self.detailizationTab),
                    metrics: self.selectedChartMetrics,
                    ...props
                };
                const data = yield axiosBoa.get(
                    brandsOpenApi.performance.metricsChart.get(self.selectedChartMetrics[0]),
                    { params }
                );

                const from = dayjs(params.date_from).format('DD/MM/YYYY');
                const to = dayjs(params.date_to).format('DD/MM/YYYY');
                fileDownload(data, `performance_overview_${from}-${to}.csv`);
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        getBreakdowns: flow(function* getBreakdowns(props = {}) {
            self.pendingBreakdowns = true;
            try {
                const params = {
                    timezone_offset: defaultTimezoneOffset,
                    ...props
                };
                self.breakdowns = yield axiosBoa.get(brandsOpenApi.performance.breakdowns.get, { params });
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pendingBreakdowns = false;
            }
        }),
        getReports: flow(function* getReports(props = {}) {
            self.pendingReports = true;
            try {
                const params = {
                    timezone_offset: defaultTimezoneOffset,
                    date_from: props.date_from,
                    date_to: props.date_to,
                    metrics: self.selectedReportMetrics
                        .filter((item) => item.active && item.key !== 'campaign')
                        .map((item) => item.key)
                };

                const reportsList = yield axiosBoa.get(brandsOpenApi.performance.reports.get, { params });

                self.reportsList = reportsList.map((report) => ({
                    ...report,
                    key: nextId()
                }));
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pendingReports = false;
            }
        }),
        downloadReports: flow(function* downloadReports(props = {}) {
            try {
                const params = {
                    timezone_offset: defaultTimezoneOffset,
                    metrics: self.selectedReportMetrics
                        .filter((item) => item.active && item.key !== 'campaign')
                        .map((item) => item.key),
                    download: true,
                    ...props
                };

                const data = yield axiosBoa.get(brandsOpenApi.performance.reports.get, { params });

                const from = dayjs(params.date_from).format('DD/MM/YYYY');
                const to = dayjs(params.date_to).format('DD/MM/YYYY');
                fileDownload(data, `performance_report_${from}-${to}.csv`);
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        afterCreate: () => {
            self.detailizationTab = '1';
            self.selectedReportMetrics = Object.values(METRICS);
            self.selectedChartMetrics = [PERFORMANCE_METRICS[0]];
            self.chartDatasets = [];
            self.chartLabels = [];
        }
    }));
