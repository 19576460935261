export const getButtonType = (type) => {
    switch (type) {
        case 'label':
        case 'chips':
            return 'default';
        case 'link':
            return 'ghost';
        default:
            return type;
    }
};
