import { COLORS } from '@constants';
import { PRODUCT_ECOMMERCE_STATUS, PRODUCT_KLICKLY_STATUS } from '@enums';
export const getKlicklyStatusColor = (status) => {
    switch (status) {
        case PRODUCT_KLICKLY_STATUS.CONNECTED:
            return COLORS.$robinBlue;
        case PRODUCT_KLICKLY_STATUS.ACCEPTED:
            return COLORS.$robinBlue;
        case PRODUCT_KLICKLY_STATUS.NOT_CONNECTED:
            return COLORS.$gray40Black;
        case PRODUCT_KLICKLY_STATUS.IN_REVIEW:
            return COLORS.$alert;
        case PRODUCT_KLICKLY_STATUS.REJECTED:
            return COLORS.$tartRed;
        case PRODUCT_KLICKLY_STATUS.DELETED:
            return COLORS.$gray40Black;
        default:
            return COLORS.$gray40Black;
    }
};

export const getEcommerceStatusColor = (status) => {
    switch (status) {
        case PRODUCT_ECOMMERCE_STATUS.ARCHIVED:
            return COLORS.$gray40Black;
        case PRODUCT_ECOMMERCE_STATUS.DRAFT:
            return COLORS.$gray40Black;
        case PRODUCT_ECOMMERCE_STATUS.ACTIVE:
            return COLORS.$robinBlue;
        default:
            return COLORS.$gray40Black;
    }
};
