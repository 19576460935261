import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStore } from '@hooks';
import { Loader } from '@ui';
import { observer } from 'mobx-react';
import AuthLayout from '../../layouts/AuthLayout';
import Confirmed from './components/Confirmed';
import { StyledText } from './styled';

const InviteeUser = observer(() => {
    const store = useStore();
    const { verifyToken } = store.accountsStore;

    const [loading, setLoading] = useState(true);

    const [searchParams] = useSearchParams();
    const action = searchParams.get('action');
    const token = searchParams.get('token');

    const confirmed = action === 'confirm-invite';

    useEffect(() => {
        (async () => {
            if (confirmed && token) {
                await verifyToken(token);
                setLoading(false);
            }
        })();
    }, []);

    if (loading) {
        return <Loader />;
    }

    const { error } = store.accountsStore;

    if (error) {
        return (
            <AuthLayout
                description={
                    <StyledText>
                        Hi, It seems there might be an issue with the invitation link you used. To gain access, please
                        request a new invitation from the store owner.
                    </StyledText>
                }
                leftCol=''
                title='Create Staff Account'
            />
        );
    }

    if (confirmed) {
        return <Confirmed token={token} />;
    }

    return null;
});

export default InviteeUser;
