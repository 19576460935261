import React from 'react';
import { CustomerInfoFields } from '@components';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Typography, WhiteCover } from '@ui';

const CustomerServiceInfo = ({ style }) => {
    return (
        <WhiteCover data-e2e='customer-management_customer-service_section' title='Customer Service Info' style={style}>
            <Typography.Text
                data-e2e='customer-management_customer-service_description'
                type='body2'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.M}
            >
                This is the information you provide to your new customers in case they have questions. It should include
                a phone number where they can contact you during business hours and an email from which they can receive
                support.
            </Typography.Text>
            <CustomerInfoFields />
        </WhiteCover>
    );
};

export default CustomerServiceInfo;
