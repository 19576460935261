import React, { useEffect } from 'react';

const Perks = () => {
    useEffect(() => {
        const root = document.querySelector('#root');
        const prevOverflowY = root.style.overflowY;

        root.style.overflowY = 'hidden';

        return () => {
            root.style.overflowY = prevOverflowY;
        };
    }, []);

    return (
        <iframe
            style={{ width: '100%', height: '100%', minHeight: 'calc(100vh - 78px)' }}
            title='PerksPage'
            className='perks-page__iframe'
            src='https://perks.carrd.co/'
            frameBorder='0'
        />
    );
};

export default Perks;
