import React from 'react';
import { useStore } from '@hooks';
import { Loader } from '@ui';
import { observer } from 'mobx-react';
import { columnsInner } from '../../constants';
import { StyledTable } from '../../styled';

const CampaignProductsTable = observer(({ campaignId }) => {
    const store = useStore();
    const { getCampaign, getCampaignProducts } = store.campaignsStore;
    const campaign = getCampaign(campaignId);

    const handleProductsTableChange = (campaignId, pagination) => {
        const page = pagination?.current;
        getCampaignProducts(campaignId, page);
    };

    return campaign?.products?.data ? (
        <StyledTable
            rowClassName={(record) => {
                return record.errorMessage ? 'disabled' : '';
            }}
            innerTable
            columns={columnsInner}
            dataSource={campaign.products.data}
            rowKey={(r) => r.id}
            pagination={
                campaign.products?.meta?.totalPages > 1
                    ? {
                          pageSize: campaign.products?.meta?.perPage,
                          total: campaign.products?.meta?.total,
                          current: campaign.products?.meta?.page,
                          totalPages: campaign.products?.meta?.totalPages
                      }
                    : false
            }
            onChange={(pagination) => {
                handleProductsTableChange(campaign.id, pagination);
            }}
            borderColor='transparent'
        />
    ) : (
        <Loader size='20px' />
    );
});

export default CampaignProductsTable;
