import { COLORS } from '@constants';
import styled, { css } from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
    position: relative;

    && {
        ${({ $hasPrefix }) =>
            $hasPrefix &&
            css`
                .ant-select-selector {
                    padding-left: 56px;
                }
            `};
    }

    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
        .ant-select-selector {
        border-color: ${COLORS.$gray20Black};
    }

    .ant-select {
        width: 100%;

        .ant-select-item-option-selected {
            .ant-select-item-option-content {
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 16px;
                    height: 8px;
                    border-left: 2px solid ${COLORS.$gray100Black};
                    border-bottom: 2px solid ${COLORS.$gray100Black};
                    transform: rotate(-45deg) translate(-1px, -1px) translate3d(0, -50%, 0);
                }
            }
            .ant-select-item-option-state {
                display: none;
            }
        }
    }

    .ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(
            .ant-pagination-size-changer
        )
        .ant-select-selector {
        border-width: 1px;
    }

    .ant-select-multiple .ant-select-selector {
        min-height: 56px;
        padding: 10px 16px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 48px;
    }

    .ant-select-selection-item {
        margin-bottom: ${(props) => (props.$hasLabel ? '-10px' : '0')};
    }
`;

export const StyledPrefix = styled.span`
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 2;
    color: ${COLORS.$gray60Black};
`;
