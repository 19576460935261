import { COLORS } from '@constants';

export const graphColorsMap = {
    1: COLORS.$robinBlue,
    2: COLORS.$alert,
    3: COLORS.$violet,
    4: COLORS.$pinkDark,
    5: COLORS.$info,
    6: COLORS.$cornflowerBlue,
    7: COLORS.$success,
    8: COLORS.$pink,
    9: COLORS.$tartRed
};
