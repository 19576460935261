import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, PlusOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { ROUTE_PATHS } from '@constants';
import { ACCOUNT_USER_ROLE } from '@enums';
import { useClickOutside, useStore } from '@hooks';

export const useDropDown = () => {
    const location = useLocation();
    const store = useStore();
    const { me, switchAccount, logout } = store.authStore;

    const navigate = useNavigate();

    const menuRef = useRef();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useClickOutside(menuRef, () => {
        setIsMenuOpen((prevMenuOpen) => prevMenuOpen && false);
    });

    const onAccountClick = async (accountId) => {
        setIsMenuOpen(false);
        await switchAccount(accountId);
        navigate(ROUTE_PATHS.HOME);
        window.location.reload();
    };

    const accounts = store.authStore.accounts.map((account) => ({
        ...account,
        onClick: () => onAccountClick(account.id)
    }));

    const menuItems = [
        {
            icon: <UserOutlined />,
            label: 'Your profile',
            onClick: () => {
                setIsMenuOpen(false);
                navigate(ROUTE_PATHS.PROFILE);
            }
        },
        {
            icon: <PlusOutlined />,
            label: 'Add new store',
            onClick: () => {
                setIsMenuOpen(false);
                navigate(ROUTE_PATHS.REGISTER);
            }
        },
        {
            icon: <ArrowLeftOutlined />,
            label: 'Log out',
            onClick: async () => {
                await logout();
                navigate(ROUTE_PATHS.LOGIN, { state: { from: location, fromLogout: true }, replace: true });
                setIsMenuOpen(false);
            }
        }
    ];

    if (me?.permission?.role !== ACCOUNT_USER_ROLE.MEMBER && !store.accountsStore.isBlocked) {
        menuItems.unshift({
            icon: <SettingOutlined />,
            label: 'Account settings',
            onClick: () => {
                setIsMenuOpen(false);
                navigate(ROUTE_PATHS.COMPANY_INFO);
            }
        });
    }

    return { menuRef, accounts, menuItems, isMenuOpen, setIsMenuOpen };
};
