import { COLORS } from '@constants';
import { Tabs } from 'antd';
import styled, { css } from 'styled-components';

export const StyledTabs = styled(Tabs)`
    .ant-tabs-tab {
        font-weight: 500;
        padding: 16px 0;
        color: ${COLORS.$gray60Black};

        p,
        h3 {
            font-weight: 500;
            color: ${COLORS.$gray60Black};
            transition: all 0.1s ease;
        }

        .ant-badge .ant-badge-count {
            font-weight: 500;
            transition: all 0.1s ease;
        }
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        p,
        h3 {
            color: ${COLORS.$gray100Black};
        }

        .ant-tabs-tab-btn {
            color: ${COLORS.$gray100Black};
        }

        .ant-badge .ant-badge-count {
            background: ${COLORS.$gray100Black};
        }
    }

    .ant-tabs-ink-bar {
        display: none;
    }

    .ant-tabs-tab:not(.ant-tabs-tab-disabled):not(.ant-tabs-tab-active):hover {
        color: ${COLORS.$gray100Black};

        .ant-badge .ant-badge-count {
            background-color: ${COLORS.$gray100Black};
        }

        p,
        h3 {
            color: ${COLORS.$gray100Black};
        }
    }

    .ant-tabs-tab.ant-tabs-tab-active:not(.ant-tabs-tab-disabled) {
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 4px;
            background-color: ${COLORS.$pink};
        }
    }

    ${({ $noHighLight }) =>
        $noHighLight &&
        css`
            .ant-tabs-tab:not(.ant-tabs-tab-disabled):hover {
                &:after {
                    background-color: transparent;
                }
            }
            .ant-tabs-ink-bar {
                display: none;
            }
        `};

    ${({ $noBottomLine }) =>
        $noBottomLine &&
        css`
            .ant-tabs-nav:before {
                border-bottom: none;
            }
        `};

    ${({ $fullWidth, $itemsCount }) =>
        $fullWidth &&
        css`
            .ant-tabs-nav-list {
                width: 100%;
            }
            .ant-tabs-tab {
                width: calc(100% / ${$itemsCount});
            }

            &.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
                margin: 0;
            }

            .ant-tabs-tab-btn {
                width: 100%;
            }
        `};
`;
