import { types } from 'mobx-state-tree';
import { AccountsStore } from './AccountsStore';
import { AuthStore } from './AuthStore';
import { CampaignsStore } from './CampaignsStore';
import { CampaignWizardStore } from './CampaignWizardStore';
import { CustomCreativesStore } from './CustomCreativesStore';
import { ForgotPasswordStore } from './ForgotPasswordStore';
import { GoogleAdsStore } from './GoogleAdsStore';
import { InsightsDemoStore } from './InsightsDemoStore';
import { InsightsStore } from './InsightsStore';
import { IntegrationsStore } from './IntegrationsStore';
import { InvoicesStore } from './InvoicesStore';
import { OrdersStore } from './OrdersStore';
import { PerformanceStore } from './PerfomanceStore';
import { ProductsStore } from './ProductsStore';
import { ProfileStore } from './ProfileStore';

export const RootStore = types.model('RootStore', {
    authStore: types.optional(AuthStore, {}),
    accountsStore: types.optional(AccountsStore, {}),
    campaignsStore: types.optional(CampaignsStore, { filters: {}, sorters: {} }),
    campaignWizardStore: types.optional(CampaignWizardStore, {}),
    forgotPasswordStore: types.optional(ForgotPasswordStore, {}),
    insightsStore: types.optional(InsightsStore, {}),
    insightsDemoStore: types.optional(InsightsDemoStore, {}),
    invoicesStore: types.optional(InvoicesStore, {
        charges: [],
        chargesMeta: {},
        chargesFilters: {},
        chargesSorters: {},
        payouts: [],
        payoutsMeta: {},
        payoutsFilters: {},
        payoutsSorters: {}
    }),
    ordersStore: types.optional(OrdersStore, { filters: {}, sorters: {} }),
    performanceStore: types.optional(PerformanceStore, {}),
    productsStore: types.optional(ProductsStore, { filters: {}, sorters: {} }),
    profileStore: types.optional(ProfileStore, {}),
    integrationsStore: types.optional(IntegrationsStore, {}),
    googleAdsStore: types.optional(GoogleAdsStore, {}),
    customCreativesStore: types.optional(CustomCreativesStore, {}),
    appPending: types.maybeNull(types.boolean)
});
