import { Link } from 'react-router-dom';
import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledGetStartedButton = styled(Link)`
    height: 48px;
    background: ${COLORS.$gray100Black};
    border-radius: 8px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    font-family: 'IBM Plex Sans', Helvetica, sans-serif;
    color: white;
    text-decoration: none;
    width: fit-content;

    &:hover {
        color: white;
        text-decoration: none;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`;
