/* eslint-disable react/react-in-jsx-scope */
import { CONTACT_VALUE_TYPE } from '@enums';

export const FIELD_NAMES = {
    NOTIFICATION_CONTACTS: 'notificationContacts',
    KLICKLY_EMAIL: 'klicklyEmail',
    VALUE_TYPE: 'valueType',
    VALUE: 'value',
    NOTIFY_ON_ACTIONS: 'notifyOnActions'
};

export const CONTACT_METHODS_OPTIONS = [
    { value: CONTACT_VALUE_TYPE.EMAIL, label: <span data-e2e='notifications_email_option'>Email</span> },
    { value: CONTACT_VALUE_TYPE.PHONE, label: <span data-e2e='notifications_phone_option'>Phone</span> }
];
