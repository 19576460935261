import { COLORS } from '@constants';
import { Tag } from 'antd';
import styled from 'styled-components';

export const StyledTag = styled(Tag)`
    &.ant-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        height: 32px;
        padding: 4px 8px;
        font-size: 16px;
        line-height: 32px;
        color: ${COLORS.$gray100Black};
        border: 1px solid ${COLORS.$gray20Black};

        .ant-tag-close-icon {
            display: flex;
            align-items: center;
            margin-right: 8px;

            path {
                stroke-width: 4;
            }
        }
    }
`;
