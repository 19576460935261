const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2
});

export const oldFormatter = (
    num,
    params = {
        thousand: false,
        million: false,
        billion: false
    }
) => {
    const { thousand, billion, million } = params;

    const thousands = num / 1000;
    const millions = num / 1000000;
    const billions = num / 1000000000;

    if (thousand && millions < 1) {
        return thousands >= 1 ? `${formatter.format(Math.floor(thousands))}k` : formatter.format(num);
    }

    if (million && billions < 1) {
        return millions >= 1 ? `${formatter.format(Math.floor(millions))}M` : formatter.format(num);
    }

    if (billion) {
        return billions >= 1 ? `${formatter.format(Math.floor(billions))}B` : formatter.format(num);
    }

    return formatter.format(num);
};

export const precisionNumberFormatter = (num, strictInt = false) => {
    if (num === null) return (0).toFixed(2);

    if (num >= 1000000000) {
        const formatted = (num / 1000000000).toFixed(2);
        return formatted.endsWith('.00') ? formatted.slice(0, -3) + 'B' : formatted + 'B';
    } else if (num >= 1000000) {
        const formatted = (num / 1000000).toFixed(2);
        return formatted.endsWith('.00') ? formatted.slice(0, -3) + 'M' : formatted + 'M';
    } else if (num >= 1000) {
        const formatted = (num / 1000).toFixed(2);
        return formatted.endsWith('.00') ? formatted.slice(0, -3) + 'K' : formatted + 'K';
    } else {
        return num % 1 === 0 && strictInt ? num.toFixed(0) : num.toFixed(2);
    }
};
