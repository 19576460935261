import { COLORS } from '../constants/colors';

export const ORDER_FINANCE_STATUS = {
    PENDING: 0,
    AUTHORIZED: 1,
    CAPTURED: 2,
    CAPTURE_PENDING: 3,
    DECLINED: 4,
    HOLD_FOR_REVIEW: 5,
    VOID: 6,
    VOID_PENDING: 7,
    PARTIALLY_PAID: 8,
    PAID: 9,
    PARTIALLY_REFUNDED: 10,
    REFUNDED: 11,
    CANCELLED: 12,
    DISPUTED: 13
};

export const CAMPAIGN_COMMISSION_SOURCE_TYPE = {
    SESSION: 0,
    CROSS_DEVICE: 1,
    LIVERAMP: 2,
    MATCH_POINT: 3,
    SUBSCRIPTION: 4
};

export const ORDER_FULFILLMENT_STATUS = {
    UNFULFILLED: 0,
    FULFILLED: 1,
    PARTIALLY_FULFILLED: 2,
    SCHEDULED: 3,
    ON_HOLD: 4
};

export const ORDER_KLICKLY_STATUS = {
    REJECTED: 0,
    CANCELLED: 1,
    NEW: 2,
    PAID: 3,
    FAILED_PAYMENT: 4,
    APPROVED: 5,
    COMPLETED: 6,
    PARTIAL_REFUNDED: 7,
    REFUNDED: 8
};

export const ORDER_ECOMMERCE_STATUS = {
    REJECTED: 0,
    CANCELLED: 1,
    NEW: 2,
    PAID: 3,
    FAILED_PAYMENT: 4,
    APPROVED: 5,
    COMPLETED: 6,
    PARTIAL_COMPLETED: 7,
    PARTIAL_REFUNDED: 8,
    REFUNDED: 9,
    DISPUTED: 10
};

export const ORDER_ECOMMERCE_STATUS_LABELS = {
    [ORDER_ECOMMERCE_STATUS.REJECTED]: { title: 'Rejected', color: COLORS.$gray40Black },
    [ORDER_ECOMMERCE_STATUS.CANCELLED]: { title: 'Cancelled', color: COLORS.$gray40Black },
    [ORDER_ECOMMERCE_STATUS.NEW]: { title: 'Received', color: COLORS.$cornflowerBlue },
    [ORDER_ECOMMERCE_STATUS.PAID]: { title: 'Paid', color: COLORS.$cornflowerBlue },
    [ORDER_ECOMMERCE_STATUS.FAILED_PAYMENT]: { title: 'Failed payment', color: COLORS.$gray40Black },
    [ORDER_ECOMMERCE_STATUS.APPROVED]: { title: 'Approved', color: COLORS.$robinBlue },
    [ORDER_ECOMMERCE_STATUS.COMPLETED]: { title: 'Fulfilled', color: COLORS.$robinBlue },
    [ORDER_ECOMMERCE_STATUS.PARTIAL_COMPLETED]: { title: 'Prt. Completed', color: COLORS.$robinBlue },
    [ORDER_ECOMMERCE_STATUS.PARTIAL_REFUNDED]: { title: 'Prt. Refunded', color: COLORS.$gray40Black },
    [ORDER_ECOMMERCE_STATUS.REFUNDED]: { title: 'Refunded', color: COLORS.$gray40Black },
    [ORDER_ECOMMERCE_STATUS.DISPUTED]: { title: 'Disputed', color: COLORS.$gray40Black }
};
export const ORDER_FINANCE_STATUS_LABELS = {
    [ORDER_FINANCE_STATUS.PENDING]: { title: 'Pending', color: COLORS.$cornflowerBlue },
    [ORDER_FINANCE_STATUS.AUTHORIZED]: { title: 'Authorized', color: COLORS.$robinBlue },
    [ORDER_FINANCE_STATUS.CAPTURED]: { title: 'Captured', color: COLORS.$robinBlue },
    [ORDER_FINANCE_STATUS.CAPTURE_PENDING]: { title: 'Cpt. pending', color: COLORS.$cornflowerBlue },
    [ORDER_FINANCE_STATUS.DECLINED]: { title: 'Declined', color: COLORS.$error },
    [ORDER_FINANCE_STATUS.HOLD_FOR_REVIEW]: { title: 'Hold review', color: COLORS.$cornflowerBlue },
    [ORDER_FINANCE_STATUS.VOID]: { title: 'Voided', color: COLORS.$cornflowerBlue },
    [ORDER_FINANCE_STATUS.VOID_PENDING]: { title: 'Void Pending', color: COLORS.$cornflowerBlue },
    [ORDER_FINANCE_STATUS.PARTIALLY_PAID]: { title: 'Prt. paid', color: COLORS.$cornflowerBlue },
    [ORDER_FINANCE_STATUS.PAID]: { title: 'Paid', color: COLORS.$robinBlue },
    [ORDER_FINANCE_STATUS.PARTIALLY_REFUNDED]: { title: 'Prt. refunded', color: COLORS.$tartRed },
    [ORDER_FINANCE_STATUS.REFUNDED]: { title: 'Refunded', color: COLORS.$gray40Black },
    [ORDER_FINANCE_STATUS.CANCELLED]: { title: 'Cancelled', color: COLORS.$gray40Black },
    [ORDER_FINANCE_STATUS.DISPUTED]: { title: 'Disputed', color: COLORS.$gray40Black }
};
export const ORDER_FULFILLMENT_STATUS_LABELS = {
    [ORDER_FULFILLMENT_STATUS.UNFULFILLED]: { title: 'Unfulfilled', color: COLORS.$gray40Black },
    [ORDER_FULFILLMENT_STATUS.FULFILLED]: { title: 'Fulfilled', color: COLORS.$robinBlue },
    [ORDER_FULFILLMENT_STATUS.PARTIALLY_FULFILLED]: { title: 'Prt. fulfilled', color: COLORS.$robinBlue },
    [ORDER_FULFILLMENT_STATUS.SCHEDULED]: { title: 'Scheduled', color: COLORS.$cornflowerBlue },
    [ORDER_FULFILLMENT_STATUS.ON_HOLD]: { title: 'On hold', color: COLORS.$cornflowerBlue }
};
