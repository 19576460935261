import React, { useCallback, useState } from 'react';
import { BOTTOM_INDENTS, COLORS, FIELD_NAME, SUGGESTED_CC_COMMISSION } from '@constants';
import { useStore } from '@hooks';
import { Loader, Slider, Typography, WhiteCover } from '@ui';
import { Flex, Form } from 'antd';
import _ from 'lodash';
import { CommissionInput } from '../CommissionInput';

const CampaignCommission = ({ instantOnInputChange, debouncedOnInputChange, loading }) => {
    const store = useStore();
    const { campaign } = store.campaignWizardStore;
    const [isTouched, setIsTouched] = useState(false);

    const suggestedPercent =
        !isTouched && campaign.commission <= SUGGESTED_CC_COMMISSION ? SUGGESTED_CC_COMMISSION : undefined;

    const form = Form.useFormInstance();

    const debouncedOnChange = useCallback(
        _.debounce((value) => {
            if (value < 0 || value === 0) {
                form.setFieldValue(FIELD_NAME.CAMPAIGN.COMMISSION, 1);
            }

            if (value > 100) {
                form.setFieldValue(FIELD_NAME.CAMPAIGN.COMMISSION, 100);
            }

            debouncedOnInputChange();
        }, 500),
        []
    );

    const onChange = (value) => {
        setIsTouched(true);
        instantOnInputChange();
        debouncedOnChange(value);
    };

    return (
        <WhiteCover
            data-e2e='campaignwizard_cc_block'
            title='Campaign commission'
            style={{ ...BOTTOM_INDENTS.M, paddingBottom: 40 }}
        >
            {loading && <Loader />}
            <Typography.Text data-e2e='campaignwizard_cc_description' type='body2' color={COLORS.$gray60Black}>
                Your Campaign Commission is how much you&apos;ll pay Klickly when Klickly drives sales of your products
                in this campaign.
            </Typography.Text>
            <Typography.Text
                data-e2e='campaignwizard_cc_hint'
                type='body2'
                style={{ marginBottom: 60 }}
                color={COLORS.$gray60Black}
            >
                (Note: increasing your commission gives you a higher Priority)
            </Typography.Text>
            <Flex gap={24} style={{ paddingRight: 13 }}>
                <div style={{ flex: 1 }}>
                    <Form.Item name={FIELD_NAME.CAMPAIGN.COMMISSION} noStyle>
                        <Slider suggestedPercent={suggestedPercent} min={1} onChange={onChange} />
                    </Form.Item>
                </div>

                <Form.Item name={FIELD_NAME.CAMPAIGN.COMMISSION} noStyle>
                    <CommissionInput
                        data-e2e='campaignwizard_cc_input'
                        onChange={onChange}
                        suffix={<Typography.Text type='badgeLarge'>%</Typography.Text>}
                    />
                </Form.Item>
            </Flex>
        </WhiteCover>
    );
};

export default CampaignCommission;
