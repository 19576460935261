import React, { useState } from 'react';
import { COLORS } from '@constants';
import { useStore } from '@hooks';
import { Button, Typography } from '@ui';
import { Flex } from 'antd';
import { observer } from 'mobx-react';
import { StyledAlert } from '../../styled';

const CompleteDisabled = observer(() => {
    const { accountsStore } = useStore();
    const [loading, setLoading] = useState(false);

    return (
        <StyledAlert>
            <Flex justify='center' align='center' gap={10}>
                <Typography.Text
                    data-e2e='payments_stripe-complete-disabled-status_title'
                    align='center'
                    color={COLORS.$tartRed}
                    type='descriptor1'
                >
                    Your information is being verified by Stripe. Payouts are currently disabled.
                </Typography.Text>
                <Button
                    data-e2e='payments_stripe-update_btn'
                    loading={loading}
                    style={{ width: 184 }}
                    block
                    type='primary'
                    onClick={async () => {
                        setLoading(true);
                        await accountsStore.handleUpdateStripeAccount('account_update');
                    }}
                >
                    Update
                </Button>
            </Flex>
        </StyledAlert>
    );
});

export default CompleteDisabled;
