import React, { useEffect, useState } from 'react';
import { BOTTOM_INDENTS, COLORS, RIGHT_INDENTS } from '@constants';
import { Loader, Typography } from '@ui';
import { Col, Empty } from 'antd';
import { StyledSpace } from '../HorizontalBar/styled';
import ProductCard from './components/ProductCard';
import { StyledCard, StyledCardsWrap } from './styled';

const TopProducts = ({ title = '', data = {}, pending = true, type = 'performance', ...props }) => {
    const isHomePage = type === 'homepage';
    const [selectedCategory, setSelectedCategory] = useState('revenue');

    const handleSelectCategory = (key) => {
        setSelectedCategory(key);
    };

    const selectedData = data?.[selectedCategory];

    const checkCategoriesData = () => {
        Object.entries(mapData).forEach(([key, value]) => {
            if (value) {
                handleSelectCategory(key);
            }
        });
    };

    useEffect(() => {
        // if default category has no data, change default category
        if (!selectedData?.length) {
            checkCategoriesData();
        }
    }, [selectedCategory]);

    const mapData = {
        views: data?.views,
        interactions: data?.interactions,
        revenue: data?.revenue
    };

    const isData = selectedData && selectedData.length !== 0;

    return (
        <Col span={24} style={BOTTOM_INDENTS.M} {...props}>
            <StyledCard
                $isHomePage={isHomePage}
                $isEmpty={!isData}
                title={
                    <Typography.Text
                        color={COLORS.$gray40Black}
                        type={isHomePage ? 'badgeLarge' : 'badgeSmall'}
                        style={RIGHT_INDENTS.S}
                    >
                        {title}
                    </Typography.Text>
                }
                extra={
                    isHomePage ? null : (
                        <StyledSpace size='middle'>
                            {mapData.views && (
                                <Typography.Title
                                    data-e2e='performance_top-products-views_btn'
                                    color={selectedCategory === 'views' ? COLORS.$gray100Black : COLORS.$gray60Black}
                                    level={3}
                                    onClick={() => handleSelectCategory('views')}
                                >
                                    Views
                                </Typography.Title>
                            )}
                            {mapData.interactions && (
                                <Typography.Title
                                    data-e2e='performance_top-products-interactions_btn'
                                    color={
                                        selectedCategory === 'interactions' ? COLORS.$gray100Black : COLORS.$gray60Black
                                    }
                                    level={3}
                                    onClick={() => handleSelectCategory('interactions')}
                                >
                                    Interactions
                                </Typography.Title>
                            )}
                            {mapData.revenue && (
                                <Typography.Title
                                    data-e2e='performance_top-products-revenue_btn'
                                    color={selectedCategory === 'revenue' ? COLORS.$gray100Black : COLORS.$gray60Black}
                                    level={3}
                                    onClick={() => handleSelectCategory('revenue')}
                                >
                                    Revenue
                                </Typography.Title>
                            )}
                        </StyledSpace>
                    )
                }
            >
                {pending ? <Loader /> : null}
                {!pending && isData ? (
                    <StyledCardsWrap data-e2e='top-products_cards_section'>
                        {selectedData.map((productData, i) => (
                            <ProductCard
                                key={i}
                                data={productData}
                                selectedCategory={selectedCategory}
                                isHomePage={isHomePage}
                            />
                        ))}
                    </StyledCardsWrap>
                ) : null}
                {!pending && !isData ? (
                    <Empty data-e2e='no-data_placeholder' image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : null}
            </StyledCard>
        </Col>
    );
};

export default TopProducts;
