import { COLORS } from '@constants';
import { Input } from 'antd';
import styled from 'styled-components';

export const StyledInputSearch = styled(Input.Search)`
    &&& {
        width: 100%;

        .ant-input-wrapper {
            border-radius: 8px;
            border: 1px solid ${COLORS.$gray20Black};
            overflow: hidden;
        }

        input.ant-input {
            border: 0;
        }

        .ant-input-group-addon {
            inset-inline-start: 0;
            background: ${COLORS.$white};

            .ant-input-search-button {
                height: 54px;
                outline: none;

                &:hover {
                    background: none;
                }
            }
        }
    }
`;
