import React from 'react';

export const PAGE = {
    TITLE: 'Launch campaign',
    DESCRIPTION: (
        <React.Fragment>
            Confirm details and launch your campaign. Following launch, your products will be shown to new and
            re-targeted users via ads and product listings across 1000s of sites and marketplaces.
        </React.Fragment>
    )
};
