import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
    display: block;
    width: 160px;
    height: 31px;
    margin-right: 16px;

    > svg {
        width: 100%;
        height: 100%;
        display: block;
    }
`;
