import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { ACCOUNT_STATUS, PLATFORM_NAME } from '@enums';
import { useStore } from '@hooks';
import { Button, Icon, Loader, Typography, WhiteCover } from '@ui';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import CoverTitle from '../CoverTitle';
import Connected from './Connected/index';
import NotConnected from './NotConnected/index';

const IntegrationBig = observer(({ storeUrl, isValid = false }) => {
    const store = useStore();
    const { getEcommerceRedirect, isFormPending } = store.authStore;
    const { companyName, currentAccount } = store.accountsStore;

    const connected = currentAccount.status !== ACCOUNT_STATUS.DISCONNECTED;

    const updateScopes = () => {
        getEcommerceRedirect(PLATFORM_NAME.SHOPIFY, {
            companyName,
            shop: storeUrl,
            token_type: 'online'
        });
    };

    return (
        <Spin spinning={isFormPending} indicator={<Loader />}>
            <WhiteCover
                data-e2e='integrations_bigcommerce_section'
                customTitle={<CoverTitle title='Big Commerce' icon={<Icon.BigCommerceIcon />} />}
                style={BOTTOM_INDENTS.M}
                rightContent={
                    <Button
                        data-e2e='integrations_go-to-bigcommerce_btn'
                        type='link'
                        padding='0'
                        onClick={() => {
                            window.open(
                                `https://store-${currentAccount.externalId}.mybigcommerce.com/manage`,
                                '_blank'
                            );
                        }}
                    >
                        Go to BigCommerce
                    </Button>
                }
            >
                <Typography.Text
                    data-e2e='integrations_bigcommerce_description'
                    type='body2'
                    color={COLORS.$gray60Black}
                    style={BOTTOM_INDENTS.M}
                >
                    Sync BigCommerce to manage inventory, product details and fulfill orders all from BigCommerce.
                </Typography.Text>
                {connected ? (
                    <Connected
                        isValid={isValid}
                        trimmedDomain={`store-${currentAccount.externalId}`}
                        updateScopes={updateScopes}
                    />
                ) : (
                    <NotConnected
                        url={`https://store-${currentAccount.externalId}.mybigcommerce.com/manage/marketplace/apps/47763`}
                    />
                )}
            </WhiteCover>
        </Spin>
    );
});

export default IntegrationBig;
