import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CancelSave } from '@components';
import { BOTTOM_INDENTS, COLORS, ROUTE_PATHS } from '@constants';
import { useStore } from '@hooks';
import { Divider, Icon, Radio, RedirectLink, Typography } from '@ui';
import { Card, Flex, Form } from 'antd';
import { observer } from 'mobx-react';
import { STRIPE_ACCOUNT_STATUS } from '../../../../../../constants/stripe';
import StripeAccountStatus from './components/StripeAccountStatus';
import { StyledCard } from './styled';

const Payments = observer(() => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { accountsStore } = useStore();
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const { stripeAccountStatus, commissionOffsetEnabled } = accountsStore;

    const showPaymentsEnabled = accountsStore.setupInfo.showPayments;

    useEffect(() => {
        if (!showPaymentsEnabled) {
            navigate(ROUTE_PATHS.HOME, { replace: true });
        }
    }, []);

    const resetForm = () => {
        form.resetFields();
    };

    const onSubmit = async (values) => {
        setLoading(true);
        const isOffsetEnabled = values.payments === 'offset';
        await accountsStore.updateCommissionOffset({ commission: { commissionOffsetEnabled: isOffsetEnabled } });
        setLoading(false);
    };

    useEffect(() => {
        const handleStripeQueryParams = async () => {
            if (searchParams.has('code')) {
                await accountsStore.stripeAccountOauthCallback(searchParams.get('code'));
                searchParams.delete('code');
                searchParams.delete('scope');
            }

            if (searchParams.has('from_stripe')) {
                await accountsStore.stripeAccountInfo();
                searchParams.delete('from_stripe');
            }

            setSearchParams(searchParams);
        };

        handleStripeQueryParams();
    }, []);

    return (
        <Fragment>
            <Typography.Title data-e2e='payments_page_title' level={2} style={BOTTOM_INDENTS.M}>
                Accept payments from customers
            </Typography.Title>
            <Typography.Text
                data-e2e='payments_page_description'
                type='body2'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.M}
            >
                Consumers will most often buy on your site, but we also allow them to buy directly within Klickly’s
                Commerce Modules. We call these &quot;in-Klickly sales.&quot; If consumers buy within our widgets, that
                means we have funds to send you. Please tell us how you’d like to receive these funds.
            </Typography.Text>
            <Form
                onFinish={onSubmit}
                form={form}
                initialValues={{
                    payments: commissionOffsetEnabled ? 'offset' : 'direct'
                }}
            >
                <Form.Item name='payments'>
                    <Radio.Group name='payments'>
                        <Card style={BOTTOM_INDENTS.M}>
                            <Flex gap={24} align='center'>
                                <Radio.WithLabel value='offset'>
                                    <Typography.Title level={2} as='span'>
                                        Enable Commission Offset
                                    </Typography.Title>
                                </Radio.WithLabel>
                            </Flex>
                            <Divider margin='24px 0' />
                            <Typography.Text
                                data-e2e='payments_enable-commission-offset_description'
                                type='body2'
                                color={COLORS.$gray60Black}
                            >
                                When enabled, Commission Offset will apply funds earned from Commerce Module
                                (in-Klickly) purchases towards your Klickly marketing invoices. (This avoids the need to
                                set up Direct Payout via Stripe.)
                            </Typography.Text>
                        </Card>
                        <Card style={BOTTOM_INDENTS.M}>
                            <Flex gap={24} align='center'>
                                <Radio.WithLabel value='direct'>
                                    <Typography.Title level={2} as='span'>
                                        Enable Direct Payout
                                    </Typography.Title>
                                </Radio.WithLabel>
                            </Flex>
                            <Divider margin='24px 0' />
                            <Typography.Text
                                data-e2e='payments_enable-direct-payout_description'
                                type='body2'
                                color={COLORS.$gray60Black}
                                style={BOTTOM_INDENTS.M}
                            >
                                When enabled, Direct Payout will send the funds you earn from any Commerce Module
                                (in-Klickly) purchases directly to your bank account.
                            </Typography.Text>
                            <StyledCard data-e2e='payments_stripe_section'>
                                <Flex justify='space-between' align='center' gap={24} style={BOTTOM_INDENTS.M}>
                                    <Flex align='center' gap={24}>
                                        <Icon.StripeIcon style={{ fontSize: '40px' }} />
                                        <Typography.Title level={3}>Stripe</Typography.Title>
                                    </Flex>
                                    {stripeAccountStatus === STRIPE_ACCOUNT_STATUS.COMPLETE ||
                                    stripeAccountStatus === STRIPE_ACCOUNT_STATUS.STANDARD_COMPLETE ? (
                                        <RedirectLink
                                            target='_blank'
                                            to='https://dashboard.stripe.com/login'
                                            fontSize='16px'
                                            text='Go to my Stripe account'
                                        />
                                    ) : null}
                                </Flex>
                                <Typography.Text
                                    data-e2e='payments_stripe_description'
                                    type='body2'
                                    color={COLORS.$gray60Black}
                                    style={BOTTOM_INDENTS.M}
                                >
                                    We use Stripe’s KYC-compliant payout technology to enable Direct Payout (note: the
                                    info requested by Stripe is not stored by Klickly, but rather passed via secure API
                                    directly to Stripe to fulfill their KYC / anti-money laundering requirements – learn
                                    more about Stripe’s processing requirements{' '}
                                    <a data-e2e='payments_stripe-requirements_link' href='/'>
                                        here
                                    </a>
                                    ).
                                </Typography.Text>
                                <StripeAccountStatus />
                            </StyledCard>
                        </Card>
                    </Radio.Group>
                </Form.Item>
                <CancelSave onCancel={resetForm} loading={loading} />
            </Form>
        </Fragment>
    );
});

export default Payments;
