import React, { Fragment } from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Typography } from '@ui';
import { PAGE } from '../../constants';

const Header = () => {
    return (
        <Fragment>
            <Typography.Title data-e2e='campaignwizard_step-5_title' style={BOTTOM_INDENTS.M}>
                {PAGE.TITLE}
            </Typography.Title>
            <Typography.Text
                data-e2e='campaignwizard_step-5_description'
                type='body2'
                style={BOTTOM_INDENTS.M}
                color={COLORS.$gray60Black}
            >
                {PAGE.DESCRIPTION}
            </Typography.Text>
        </Fragment>
    );
};

export default Header;
