import React from 'react';
import { Button } from '../../../Button';
import DropdownItem from './components/DropdownItem';
import { StyledDropdownWrapper, StyledWrapper } from './styled';

const FilterDropdown = (props) => {
    const { close, confirm, filters, selectedKeys, setSelectedKeys } = props;
    return (
        <StyledWrapper data-e2e='common_filter_section'>
            <StyledDropdownWrapper>
                {filters.map((filter) => {
                    return (
                        <DropdownItem
                            key={filter.value}
                            {...filter}
                            checked={selectedKeys.includes(filter.value)}
                            onChange={({ target: { checked } }) => {
                                if (checked) {
                                    setSelectedKeys([...selectedKeys, filter.value]);
                                } else {
                                    setSelectedKeys(selectedKeys.filter((key) => key !== filter.value));
                                }
                            }}
                        />
                    );
                })}
            </StyledDropdownWrapper>
            <Button
                data-e2e='common_filter-apply_btn'
                type='primary'
                onClick={() => {
                    confirm();
                    close();
                }}
                block
            >
                Apply
            </Button>
        </StyledWrapper>
    );
};

export default FilterDropdown;
