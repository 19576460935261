import { COLORS } from '@constants';
import { CAMPAIGN_STATUS } from '@enums';

export const getCampaignStatusColor = (status) => {
    switch (status) {
        case CAMPAIGN_STATUS.ACTIVE:
            return COLORS.$robinBlue;
        case CAMPAIGN_STATUS.PAUSED:
            return COLORS.$gray40Black;
        case CAMPAIGN_STATUS.DELETED:
            return COLORS.$gray20Black;
        default:
            return COLORS.$gray60Black;
    }
};
