import { Checkbox, Modal, Row, Typography } from 'antd';
import styled from 'styled-components';
import { BrandsLogoIcon } from '../../ui/Icon/constants';

export const StyledModal = styled(Modal)`
    padding: 24px;
    position: fixed;
    top: 164px;
    left: 29.2%;
    transform: translateX(-50%, 0%);
`;

export const StyledTypographyHeader = styled(Typography.Text)`
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
`;

export const StyledBrandsLogoIcon = styled(BrandsLogoIcon)`
    position: absolute;
    top: 20px;
    right: 20px;
`;
export const StyledTypography = styled(Typography.Text)`
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 12px;
    display: block;
`;

export const StyledRow = styled(Row)`
    margin-top: 34px;
`;

export const StyledCheckbox = styled(Checkbox)`
    transform: scale(0.8);
    transform-origin: left center;
`;

export const CheckboxText = styled.span`
    font-size: 0.9em;
`;

export const ButtonGroup = styled.div`
    display: flex;
    gap: 26px;
`;
