import * as get from 'lodash/get';

export const getOptions = () => {
    return {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = get(context, `dataset.data[${context?.dataIndex}].feature.properties.name`, '');
                        const value = get(context, 'formattedValue', '');
                        return `${label}: ${value}%`;
                    }
                }
            }
        },
        scales: {
            color: {
                axis: 'x',
                display: false,
                interpolate: (v) => {
                    if (v < 0.1) {
                        return 'rgba(218, 23, 129, 0.1)';
                    } else if (v < 0.2) {
                        return 'rgba(218, 23, 129, 0.2)';
                    } else if (v < 0.3) {
                        return 'rgba(218, 23, 129, 0.3)';
                    } else if (v < 0.4) {
                        return 'rgba(218, 23, 129, 0.4)';
                    } else if (v < 0.5) {
                        return 'rgba(218, 23, 129, 0.5)';
                    } else if (v < 0.6) {
                        return 'rgba(218, 23, 129, 0.6)';
                    } else if (v < 0.7) {
                        return 'rgba(218, 23, 129, 0.7)';
                    } else if (v < 0.8) {
                        return 'rgba(218, 23, 129, 0.8)';
                    } else if (v < 0.9) {
                        return 'rgba(218, 23, 129, 0.9)';
                    }
                    return 'rgba(218, 23, 129, 1)';
                },
                missing: 'white',
                legend: {
                    display: false
                }
            }
        }
    };
};
