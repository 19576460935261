import { brandsOpenApi, CUSTOM_CREATIVES_SIZES, FILE_EXTENSION_MAP } from '@constants';
import { axiosBoa, getErrorMessage } from '@utils';
import { flow, getParent, types } from 'mobx-state-tree';
import dataURItoBlob from '../utils/dataURItoBlob';
import { CustomCreativeUnitModel } from './constants/customCreativeUnitModel';

export const CustomCreativesStore = types
    .model('CustomCreativesStore', {
        id: types.maybeNull(types.number),
        enabledByBrand: types.maybeNull(types.boolean),
        customCreativeUrl: types.maybeNull(types.string),
        units: types.array(CustomCreativeUnitModel),
        unitPreviewImageSrc: types.maybeNull(types.string),
        dbEnabledByBrand: types.maybeNull(types.boolean),
        dbCustomCreativeUrl: types.maybeNull(types.string),
        pending: false,
        error: types.maybeNull(types.model({ title: types.string, description: types.string }))
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        getModifiedUnits() {
            return self.units.filter((unit) => unit.isModified());
        },
        get unitsWithImages() {
            return self.units.filter((unit) => !!unit.localData.imageSrc);
        },
        get isRejected() {
            return self.units.some((unit) => unit.serverData.validationStatus === 0);
        },
        get customCreativePreviewSrc() {
            const unit = self.units.find((unit) => unit.size === '300x600');
            return unit?.localData?.imageSrc ?? null;
        },
        get isDataModified() {
            const isToggleModified = self.enabledByBrand !== self.dbEnabledByBrand;
            const isUrlModified = self.customCreativeUrl !== self.dbCustomCreativeUrl;
            const isUnitsModified = self.units.some((unit) => unit.isModified());

            return isToggleModified || isUrlModified || isUnitsModified;
        }
    }))
    .actions((self) => ({
        setEnabledByBrand(value) {
            self.enabledByBrand = value;
        },
        setCreativeUrl(value) {
            self.customCreativeUrl = value;
        },
        setUnitsFromServer(serverData) {
            const serverMap = new Map(serverData.map((item) => [item.size, item]));

            // Initialize all sizes with server data or defaults
            self.units = CUSTOM_CREATIVES_SIZES.map(({ size }) =>
                CustomCreativeUnitModel.create({
                    size,
                    serverData: serverMap.get(size) || { validationStatus: 1, imageSrc: '' },
                    localData: serverMap.get(size) || { validationStatus: 1, imageSrc: '' }
                })
            );
        },
        getUnitBySize(size) {
            return self.units.find((unit) => unit.size === size);
        },
        updateUnitField(size, field, value) {
            const unit = self.getUnitBySize(size);
            if (unit) {
                unit.updateField(field, value);
            }
        },
        resetUnit(size) {
            const unit = self.getUnitBySize(size);
            if (unit) {
                unit.resetToServerData();
            }
        },
        getCustomCreatives: flow(function* getCustomCreatives(campaignId) {
            self.pending = true;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.customCreatives.get(campaignId));

                self.enabledByBrand = response.enabledByBrand;
                self.customCreativeUrl = response.customCreativeUrl;
                self.setUnitsFromServer(response.units);

                self.dbEnabledByBrand = response.enabledByBrand;
                self.dbCustomCreativeUrl = response.customCreativeUrl;
            } catch (error) {
                self.setUnitsFromServer([]);
                self.error = getErrorMessage(error);
            } finally {
                self.pending = false;
            }
        }),
        prepareFormData() {
            const formData = new FormData();
            formData.append('enabledByBrand', self.enabledByBrand);
            formData.append('customCreativeUrl', self.customCreativeUrl);

            self.units.forEach((unit) => {
                const size = unit.size;
                // New or updated image: Add to FormData
                if (unit.isModified() && unit.localData.imageSrc) {
                    const fileBlob = dataURItoBlob(unit.localData.imageSrc); // Convert base64 to Blob
                    const fileExtension = FILE_EXTENSION_MAP[fileBlob.type];
                    formData.append('files[]', fileBlob, `unit_${size}${fileExtension}`);
                    formData.append('updatedSizes[]', unit.size);
                }

                // Mark deleted images
                if (unit.isDeleted()) {
                    formData.append('deletedSizes[]', unit.size);
                }
            });

            return formData;
        },
        updateCustomCreatives: flow(function* updateCustomCreatives(campaignId) {
            if (!campaignId || !self.isDataModified) return false;

            try {
                const formData = self.prepareFormData(campaignId);

                yield axiosBoa.post(brandsOpenApi.customCreatives.update(campaignId), formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        })
    }));
