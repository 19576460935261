import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '@constants';
import { useStore } from '@hooks';
import { Loader } from '@ui';
import { axiosBoa } from '@utils';
import { observer } from 'mobx-react';
import { StyledWrapper } from '../Oauth/BigCommerce/Callback';

const AppEmbedded = observer(() => {
    const store = useStore();
    const { embeddedLoadPayload, me, whoAmI, oauthPayload, getInitialData } = store.authStore;

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (!me) {
                await whoAmI();
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (me) {
                try {
                    const response = await axiosBoa.get(ROUTE_PATHS.AUTH_APP_EMBEDDED, {
                        params: { ...embeddedLoadPayload, ...oauthPayload }
                    });

                    if (!response.account?.companyName) {
                        await getInitialData();
                        return navigate(ROUTE_PATHS.ADD_COMPANY_NAME);
                    }

                    return navigate(ROUTE_PATHS.WELCOME);
                } catch (err) {
                    return navigate(ROUTE_PATHS.REQUEST_ACCESS);
                }
            }
        })();
    }, [me]);

    return (
        <StyledWrapper justify='center' align='center'>
            <Loader size='120px' />
        </StyledWrapper>
    );
});

export default AppEmbedded;
