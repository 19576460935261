import React from 'react';
import { StyledText } from './styled';

const Text = ({ children, type, color, align, as = 'p', marginBottom, ...props }) => {
    return (
        <StyledText $type={type} $color={color} $align={align} as={as} $marginBottom={marginBottom} {...props}>
            {children}
        </StyledText>
    );
};

export default Text;
