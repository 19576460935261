import React, { useEffect, useState } from 'react';
import { SearchSuffix } from '@components';
import { COLORS } from '@constants';
import { Divider, Icon, Input, Loader, Typography } from '@ui';
import { formatString } from '@utils';
import { Flex } from 'antd';
import styled from 'styled-components';
import { ScrollContainer, StyledListItem, StyledTag } from './styled';

const StyledDiv = styled.div`
    max-height: ${(props) => (props.loading ? 0 : 'unset')};
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
`;

const CardExpandedContent = ({
    placeholder = '',
    listItems,
    selectedListItems,
    onListItemClick,
    onTagClose,
    searchValue,
    setSearchValue,
    onSearchChange,
    initialAction,
    willUnmountAction
}) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            await initialAction();
            setLoading(false);
        })();

        return () => {
            willUnmountAction();
        };
    }, []);

    return (
        <StyledDiv loading={loading}>
            {loading && <Loader />}
            <Divider />
            <Flex vertical gap={16}>
                <Input
                    data-e2e={`campaignwizard_${formatString(placeholder)}_input`}
                    label={placeholder}
                    value={searchValue}
                    onChange={onSearchChange}
                    onPressEnter={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                    allowClear={{
                        clearIcon: (
                            <Icon
                                style={{ marginTop: 3 }}
                                size='24px'
                                component={() => <Icon.CloseIcon width={24} height={24} />}
                            />
                        )
                    }}
                    suffix={!searchValue && <SearchSuffix onSetSearchValue={setSearchValue} />}
                />
                <Flex gap={16} wrap='wrap'>
                    {selectedListItems.map((item) => (
                        <StyledTag
                            data-e2e={`campaignwizard_selected-item-${item.id}_value`}
                            onClose={() => {
                                onTagClose(item);
                            }}
                            closeIcon={
                                <Icon
                                    data-e2e={`campaignwizard_delete-selected-item-${item.id}_btn`}
                                    component={() => <Icon.CloseIcon />}
                                    color={COLORS.$white}
                                />
                            }
                            key={item.id}
                        >
                            {item.title}
                        </StyledTag>
                    ))}
                </Flex>

                <div style={{ maxHeight: 250, overflow: 'hidden' }}>
                    <ScrollContainer>
                        <Flex vertical style={{ paddingRight: 10 }}>
                            {listItems.map((item) => (
                                <StyledListItem
                                    data-e2e={`campaignwizard_item-${item.id}_option`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onListItemClick(item);
                                    }}
                                    key={item.id}
                                >
                                    <Flex align='center'>
                                        <Typography.Text type='descriptor1' style={{ marginRight: 'auto' }}>
                                            {item.title}
                                        </Typography.Text>
                                        {selectedListItems.find((i) => i.id === item.id) && (
                                            <Icon.CheckIcon
                                                data-e2e={`campaignwizard_item-${item.id}_check-mark`}
                                                width='24px'
                                                height='24px'
                                                color={COLORS.$pink}
                                            />
                                        )}
                                    </Flex>
                                </StyledListItem>
                            ))}
                        </Flex>
                    </ScrollContainer>
                </div>
            </Flex>
        </StyledDiv>
    );
};

export default CardExpandedContent;
