import { CAMPAIGN_COMMISSION_TYPE_NAME } from '@enums';
import { dayjs } from '@utils';
import { types } from 'mobx-state-tree';

const AddressModel = types.model({
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    address1: types.maybeNull(types.string),
    address2: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    countryCode: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    zip: types.maybeNull(types.string)
});

export const OrderModel = types.model({
    key: types.maybeNull(types.string),
    klicklyOrderNumber: types.maybeNull(types.string),
    financialStatus: types.maybeNull(types.number),
    klicklyStatus: types.maybeNull(types.number),
    ecommerceStatus: types.maybeNull(types.number),
    fulfillmentStatus: types.maybeNull(types.number),
    ecommerceOrderId: types.maybeNull(types.string),
    ecommerceOrderName: types.maybeNull(types.string),
    ecommerceCreatedAt: types.maybeNull(types.string),
    campaigns: types.array(
        types.model({
            title: types.maybeNull(types.string),
            catalogType: types.maybeNull(types.number)
        })
    ),
    subtotalPrice: types.maybeNull(types.union(types.float, types.number)),
    taxPrice: types.maybeNull(types.number),
    totalPrice: types.maybeNull(types.union(types.float, types.number)),
    isSubscription: types.boolean,
    metrics: types.maybeNull(
        types
            .model({
                daysToConversion: types.maybeNull(types.union(types.float, types.number)),
                totalClicks: types.maybeNull(types.number),
                totalViews: types.maybeNull(types.number),
                totalMouseovers: types.maybeNull(types.number),
                totalEngagements: types.maybeNull(types.number),
                lastViewAt: types.maybeNull(types.string),
                lastClickAt: types.maybeNull(types.string),
                lastMouseoverAt: types.maybeNull(types.string),
                commissionType: types.maybeNull(types.number),
                commissionSourceType: types.maybeNull(types.number),
                commissionCharged: types.maybeNull(types.union(types.float, types.number)),
                isCommissionLimitedToSubtotal: types.boolean
            })
            .views((self) => ({
                get lastEngagement() {
                    const engagementTimes = [self.lastViewAt, self.lastClickAt, self.lastMouseoverAt].filter(
                        (time) => time !== null
                    );

                    if (engagementTimes.length === 0) return null; // No engagements

                    const now = dayjs().utc();
                    const closest = engagementTimes.reduce((a, b) => {
                        return Math.abs(dayjs(b).diff(now)) < Math.abs(dayjs(a).diff(now)) ? b : a;
                    });

                    return dayjs(closest).format('MMM D YYYY');
                },
                get commissionTypeName() {
                    return CAMPAIGN_COMMISSION_TYPE_NAME[self.commissionType];
                },
                get daysToConversionToDisplay() {
                    const roundedDays = Math.round(self.daysToConversion);
                    return roundedDays >= 1 ? roundedDays : '<1';
                }
            }))
    ),
    orderItems: types.array(
        types.model({
            price: types.maybeNull(types.number),
            image: types.maybeNull(types.string),
            ecommerceVariantId: types.maybeNull(types.string),
            quantity: types.maybeNull(types.number),
            restOfSiteCommission: types.maybeNull(types.number),
            campaignCommission: types.maybeNull(types.number),
            title: types.maybeNull(types.string),
            product: types.maybeNull(
                types.model({
                    title: types.maybeNull(types.string),
                    variants: types.array(
                        types.model({
                            ecommerceId: types.maybeNull(types.string),
                            name: types.maybeNull(types.string)
                        })
                    )
                })
            )
        })
    ),
    email: types.maybeNull(types.string),
    shippingPrice: types.maybeNull(types.number),
    phone: types.maybeNull(types.string),
    shippingAddress: types.maybeNull(AddressModel),
    billingAddress: types.maybeNull(AddressModel),
    createdAt: types.maybeNull(types.string),
    processedAt: types.maybeNull(types.string)
});
