import React from 'react';
import { COLORS } from '@constants';
import { Icon } from '../Icon';
import { StyledLoader } from './styled';

export const Loader = ({
    overlayColor,
    position = 'absolute',
    bottom,
    color = 'primary',
    size = '80px',
    zIndex = 5,
    borderRadius = '0px'
}) => {
    return (
        <StyledLoader
            $position={position}
            $bottom={bottom}
            $overlayColor={overlayColor}
            $zIndex={zIndex}
            $borderRadius={borderRadius}
        >
            <Icon
                data-e2e='common_loader'
                color={color}
                size={size}
                component={() => <Icon.SpinnerIcon color={COLORS.$pink} />}
            />
        </StyledLoader>
    );
};
