import React from 'react';
import { COLORS, TOP_INDENTS } from '@constants';
import { Typography } from '@ui';

export const ErrorMessage = ({ message, style, ...props }) => {
    return message ? (
        <Typography.Text type='descriptor2' color={COLORS.$tartRed} style={{ ...TOP_INDENTS.XS, ...style }} {...props}>
            {message.toString()}
        </Typography.Text>
    ) : null;
};
