import React from 'react';
import { COLORS } from '@constants';
import { formatString } from '@utils';
import noPhoto from '../../../../../../../../assets/svg/noPhoto.svg';
import Metrics from './components/Metrics';
import { StyledContent, StyledImage, StyledMetricsWrap, StyledText, StyledWrap } from './styled';

const ProductCard = ({ data = {}, selectedCategory, isHomePage = false }) => {
    const ViewComponent = () => <Metrics title='Views:' value={data.views} />;
    const InteractionComponent = () => <Metrics title='Interactions:' value={data.interactions} />;
    const RevenueComponent = () => <Metrics title='Revenue:' value={data.revenue} />;

    const renderComponents = (selectedCategory) => {
        const order = {
            views: ['views', 'interactions', 'revenue'],
            interactions: ['interactions', 'views', 'revenue'],
            revenue: ['revenue', 'views', 'interactions']
        };

        const components = {
            views: (
                <span data-e2e={`top-products_${formatString(data.title)}_product-views`}>
                    <ViewComponent />
                </span>
            ),
            interactions: (
                <span data-e2e={`top-products_${formatString(data.title)}_product-interactions`}>
                    <InteractionComponent />
                </span>
            ),
            revenue: (
                <span data-e2e={`top-products_${formatString(data.title)}_product-revenue`}>
                    <RevenueComponent />
                </span>
            )
        };

        return order[selectedCategory].map((category) => components[category]);
    };

    return (
        <StyledWrap data-e2e={`top-products_${formatString(data.title)}_product-card`} $isHomePage={isHomePage}>
            {data.imageSrc ? (
                <StyledImage src={data.imageSrc} alt={data.title} />
            ) : (
                <StyledImage src={noPhoto} alt={data.title} />
            )}
            <StyledContent>
                <StyledText type='badgeMedium' color={COLORS.$gray40Black}>
                    {data.title}
                </StyledText>
                <StyledMetricsWrap>{renderComponents(selectedCategory)}</StyledMetricsWrap>
            </StyledContent>
        </StyledWrap>
    );
};

export default ProductCard;
