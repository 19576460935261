import React from 'react';
import { TitleWithTooltip } from '@components';
import { tooltips } from '../../constants';
import { StyledWrapper } from './styled';

const Header = () => {
    return (
        <StyledWrapper data-e2e='insights_header'>
            <TitleWithTooltip iconSize='24px' title='Insights' tooltip={tooltips.insights} />
        </StyledWrapper>
    );
};

export default Header;
