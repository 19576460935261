import React, { useCallback } from 'react';
import { BOTTOM_INDENTS, COLORS, FIELD_NAME } from '@constants';
import { Typography, WhiteCover } from '@ui';
import { Flex, Form } from 'antd';
import _ from 'lodash';
import { CommissionInput } from '../CommissionInput';

const NewCustomerCommission = ({ instantOnInputChange, debouncedOnInputChange }) => {
    const form = Form.useFormInstance();

    const debouncedOnChange = useCallback(
        _.debounce((value) => {
            if (value < 0) {
                form.setFieldValue(FIELD_NAME.ACCOUNT.NEW_CUSTOMER_COMMISSION, 0);
            }

            debouncedOnInputChange();
        }, 500),
        []
    );

    const onChange = (value) => {
        instantOnInputChange();
        debouncedOnChange(value);
    };

    return (
        <WhiteCover data-e2e='campaignwizard_ncc_block' title='New-customer commission' style={BOTTOM_INDENTS.M}>
            <Flex gap={48} style={{ paddingRight: 13 }}>
                <div data-e2e='campaignwizard_ncc_description' style={{ flex: 1 }}>
                    <Typography.Text type='body2' color={COLORS.$gray60Black}>
                        Your new-customer commission is how you incentivize Klickly to help drive sales from{' '}
                        <a
                            style={{ color: COLORS.$info }}
                            href='https://faq.brands.klickly.com/pricing-and-how-we-charge/#what-are-the-different-types-of-commissions'
                            rel='noopener noreferrer'
                            target='_blank'
                        >
                            new customers
                        </a>
                        , no matter what products they buy. (Note: this commission will only apply when it exceeds other
                        commission types)
                    </Typography.Text>
                </div>

                <Form.Item name={FIELD_NAME.ACCOUNT.NEW_CUSTOMER_COMMISSION} noStyle>
                    <CommissionInput
                        data-e2e='campaignwizard_ncc_input'
                        onChange={onChange}
                        prefix={<Typography.Text type='badgeLarge'>$</Typography.Text>}
                    />
                </Form.Item>
            </Flex>
        </WhiteCover>
    );
};

export default NewCustomerCommission;
