/* eslint-disable */
import React from 'react';
import { ROUTE_PATHS } from '@constants';

export const NOTIFICATION_TYPES = {
    PAYMENT_CONNECT: 1,
    SCRIPT_TAG_CONNECT: 2,
    SCRIPT_TAG_THANK_YOU_PAGE_CONNECT: 3,
    API_KEY_CONNECT: 4,
    ACCOUNT_ON_PAUSE: 5,
    CONNECTOR_CONNECT: 6,
    ACCOUNT_UNINSTALLED: 7,
    NOTIFICATION_UPDATE: 8,
    MANUAL_STORE_ONBOARDING: 9
};

export const ACCOUNT_BLOCKED_REASONS = {
    BAD_PLAN: 'badPlan',
    CURRENCY: 'currency',
    INTEGRATION_INACTIVE: 'integrationInactive',
    MISSING_US_SHIPPING_METHOD: 'missingUSShippingMethod',
    SETUP_REQUIRED: 'setupRequired'
};

const { CURRENCY, BAD_PLAN, INTEGRATION_INACTIVE, MISSING_US_SHIPPING_METHOD, SETUP_REQUIRED } = ACCOUNT_BLOCKED_REASONS;

const {
    PAYMENT_CONNECT,
    SCRIPT_TAG_CONNECT,
    SCRIPT_TAG_THANK_YOU_PAGE_CONNECT,
    API_KEY_CONNECT,
    ACCOUNT_ON_PAUSE,
    CONNECTOR_CONNECT,
    ACCOUNT_UNINSTALLED,
    NOTIFICATION_UPDATE,
    MANUAL_STORE_ONBOARDING
} = NOTIFICATION_TYPES;

export const createNotificationsMap = (actions) => {
    return {
        [MANUAL_STORE_ONBOARDING]: {
            title: 'Connect your store to Klickly',
            description: 'We need to ensure a seamless flow of data between your store and Klickly’s platform in order for you to promote your products with Klickly. Please complete the setup process to enable these real-time updates and optimized ad functionality. Note: we advise coordinating with your e-commerce site developer to facilitate this setup, as it may require technical knowledge for proper implementation.',
            buttonText: 'Complete setup',
            link: ROUTE_PATHS.INTEGRATIONS_SETTINGS
        },
        [PAYMENT_CONNECT]: {
            title: 'Payments failed',
            description: 'Your payment method on file has failed, and your campaigns are now paused. Please update your credit card to resume advertising (paused campaigns lose their learning quickly).',
            buttonText: 'Update payment method',
            link: ROUTE_PATHS.ACCOUNT_MANAGEMENT
        },
        [NOTIFICATION_UPDATE]: {
            title: 'App update required for your Shopify integration',
            description:
                'Please update your app to the latest version for continued compatibility with your Shopify store and improved performance',
            buttonText: 'Update Integration',
            action: actions[NOTIFICATION_TYPES.NOTIFICATION_UPDATE]
        },
        [SCRIPT_TAG_CONNECT]: {
            title: 'Connect Script Tag to your web pages',
            description: (
                <p>
                    We encountered an issue while trying to connect the script tag to your webpages. Please ensure the
                    script tag is correctly placed in your website&apos;s code. If the problem persists, contact us at{' '}
                    <a href='mailto:help@klickly.com'>help@klickly.com</a> for assistance.
                </p>
            ),
            buttonText: 'Go to Integrations Settings',
            link: ROUTE_PATHS.INTEGRATIONS_SETTINGS
        },
        [API_KEY_CONNECT]: {
            title: 'Unable to Set Up API Key',
            description: (
                <p>
                    We encountered an issue while trying to set up your API key. Please ensure you have entered the
                    correct key and try again. If the problem persists, contact us at{' '}
                    <a href='mailto:help@klickly.com'>help@klickly.com</a> for assistance.
                </p>
            ),
            buttonText: 'Go to Account Settings',
            link: ROUTE_PATHS.ACCOUNT_SETTINGS
        },
        [SCRIPT_TAG_THANK_YOU_PAGE_CONNECT]: {
            title: 'Script Tag on Thank You Page Failed',
            description: (
                <p>
                    We encountered an issue while trying to connect the script tag to your Thank You page. Please check
                    the placement of the script tag in your website&apos;s code. If the problem persists, contact us at{' '}
                    <a href='mailto:help@klickly.com'>help@klickly.com</a> for assistance.
                </p>
            ),
            buttonText: 'Go to Account Settings',
            link: ROUTE_PATHS.INTEGRATIONS_SETTINGS
        },
        [ACCOUNT_ON_PAUSE]: {
            title: 'Account Paused',
            description:
                "Your account is currently on hold, and Klickly will temporarily cease serving new ads featuring your products. Feel free to resume whenever you're ready to reactivate your account.",
            buttonText: 'Go to Account Settings',
            link: ROUTE_PATHS.ACCOUNT_MANAGEMENT
        },
        [ACCOUNT_UNINSTALLED]: {
            title: 'Account Uninstalled',
            description:
                "Your account is currently uninstalled, and Klickly will temporarily cease serving new ads featuring your products. Feel free to resume whenever you're ready to reactivate your account.",
            buttonText: 'Go to Integrations Settings',
            link: ROUTE_PATHS.INTEGRATIONS_SETTINGS
        },
        [CONNECTOR_CONNECT]: {
            title: 'Connector App is not connected',
            description: 'To advertise your products on Klickly, please connect your account to our connector app.',
            buttonText: 'Go to Integrations Settings',
            link: ROUTE_PATHS.INTEGRATIONS_SETTINGS
        },
        [CURRENCY]: {
            title: 'Account is currently inactive',
            description: (
                <p>
                    To go live, please set USD as your store's main currency within your e-commerce platform. For assistance, contact us at{' '}
                    <a href='mailto:help@klickly.com'>help@klickly.com</a>.
                </p>
            ),
            buttonText: 'Re-try account activation',
            action: actions[CURRENCY]
        },
        [BAD_PLAN]: {
            title: 'Account is currently inactive',
            description: (
                <p>
                    Your store is currently inactive due to your e-commerce platform plan.
                    Please activate your store first or contact
                    {' '}<a href='mailto:help@klickly.com'>help@klickly.com</a>{' '}
                    if you have any questions or concerns.
                </p>
            ),
            buttonText: 'Re-try account activation',
            action: actions[BAD_PLAN]
        },
        [MISSING_US_SHIPPING_METHOD]: {
            title: 'Account is currently inactive',
            description: (
                <p>
                    To go live, enable shipping to US addresses in your e-commerce platform. Please contact us at
                    {' '}<a href='mailto:help@klickly.com'>help@klickly.com</a>{' '}
                    if you have any questions or concerns.
                </p>
            ),
            buttonText: 'Re-try account activation',
            action: actions[MISSING_US_SHIPPING_METHOD]
        },
        [INTEGRATION_INACTIVE]: {
            title: 'Account is currently inactive',
            description: (
                <p>
                    Your ecommerce API tokens have been revoked or not working correctly.
                    It may be caused by cancelled ecommerce plan.
                    Please activate your store first or contact
                    {' '}<a href='mailto:help@klickly.com'>help@klickly.com</a>{' '}
                    if you have any questions or concerns.
                </p>
            ),
            buttonText: 'Re-try account activation',
            action: actions[INTEGRATION_INACTIVE]
        },
        [SETUP_REQUIRED]: {
            title: 'Account is currently inactive',
            description: (
                <p>
                    Your store is currently inactive due to your e-commerce platform setup. Please finish store setup first or contact us at{' '}
                    <a href='mailto:help@klickly.com'>help@klickly.com</a>.
                </p>
            ),
            buttonText: 'Re-try account activation',
            action: actions[SETUP_REQUIRED]
        }
    }
};
