import React from 'react';
import { ErrorFallback } from '@components';
import { Icon } from '@ui';

const Page404 = () => {
    return (
        <ErrorFallback
            icon={<Icon.SearchIcon />}
            title="Look like this page doesn't exist"
            description='Sorry, we can’t find this page. But you may explore other Klickly services via top menu'
        />
    );
};

export default Page404;
