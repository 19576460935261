import { COLORS } from '@constants';
import { Carousel } from 'antd';
import styled from 'styled-components';

export const StyledImgWrapper = styled.button`
    cursor: ${({ showModalOnClick }) => (showModalOnClick ? 'pointer' : 'auto')};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border: 0;
    padding: 0;
    margin: 0;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: opacity 0.3s ease;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover .overlay {
        opacity: ${({ showModalOnClick }) => (showModalOnClick ? 1 : 0)};
    }
`;

export const StyledText = styled.p`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
`;

export const StyledCarousel = styled(Carousel)`
    .slick-arrow.slick-prev {
        inset-inline-start: -20px;
    }

    .slick-arrow.slick-next {
        display: ${(props) => (props.currentslide === props.slidecount ? 'none' : 'block')};
        inset-inline-end: -20px;
    }

    .slick-arrow::after {
        color: ${COLORS.$pink};
        border-inline-width: 3px 0;
        border-block-width: 3px 0;
    }
`;

export const Line = styled.div`
    width: 100%;
    height: 4px;
    background: linear-gradient(
        to right,
        ${COLORS.$violet} 0%,
        ${COLORS.$magenta} 25%,
        ${COLORS.$alert} 75%,
        ${COLORS.$yellow} 100%
    );
`;
