import { COLORS } from '@constants';
import { Input } from '@ui';
import styled from 'styled-components';

export const StyledInput = styled(Input)`
    &.ant-input-group-wrapper {
        padding: 0;
        border: 0;
    }

    & input {
        padding: 24px 16px 6px;
    }

    .ant-input-group-addon {
        background-color: white;
        color: gray;
        border-left: none;
    }

    .ant-input {
        border-right: none;

        &:hover {
            border-color: ${COLORS.$gray20Black};
        }
    }

    .ant-input.ant-input-status-success {
        background-color: ${COLORS.$white};
    }

    .ant-input:focus {
        border-color: ${COLORS.$gray60Black};

        ~ span {
            border-color: ${COLORS.$gray60Black};
        }
    }

    .ant-input[aria-invalid='true'] {
        ~ span {
            border-color: ${COLORS.$tartRed};
        }
    }
`;
