import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Typography } from '@ui';
import { StyledDescription, StyledLink, StyledText } from './styled';

const OrdersModal = () => {
    return (
        <React.Fragment>
            <Typography.Text type='descriptor1' color={COLORS.$black} style={BOTTOM_INDENTS.M}>
                Klickly offers several advanced commission options, including custom commissions on new and cold
                customers, and commission refunds on certain canceled orders. In order to enable these advanced options,
                Klickly’s commission system needs to be able to determine when a shopper last purchased from your store
                (if ever) and the status of orders that Klickly helped enable (e.g. paid, fulfilled, and refunded).
            </Typography.Text>
            <StyledText type='descriptor1' color={COLORS.$black} style={BOTTOM_INDENTS.M}>
                You can enable these advanced commission options in 2 ways:
                <ul>
                    <li>
                        <b>
                            Webhooks (<i>Recommended</i>):
                        </b>{' '}
                        Use this method to provide historical and real-time updates about the status of your orders
                        (e.g. paid, fulfilled, and refunded). See the{' '}
                        <StyledLink onClick={() => window.open('/docs/api#tag/webhook_orders', '_blank')}>
                            Webhooks guide
                        </StyledLink>{' '}
                        for more information.
                    </li>
                    <li>
                        <b>CSV Upload:</b> Use this method to upload your orders using a csv file. As this method relies
                        on <i>occasional</i> order status refreshes, it is not recommended for ongoing order management.
                        See the{' '}
                        <StyledLink onClick={() => window.open('/docs/api#tag/bulk_orders', '_blank')}>
                            csv upload guide
                        </StyledLink>{' '}
                        for more information.
                    </li>
                </ul>
            </StyledText>
            <StyledDescription>
                <Typography.Text align='left' type='descriptor1'>
                    Once at least 1 order has been correctly imported, the status of this step will automatically change
                    to Connected.
                </Typography.Text>
            </StyledDescription>
        </React.Fragment>
    );
};

export default OrdersModal;
