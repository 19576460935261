import React, { useEffect, useState } from 'react';
import { CancelSave, CustomerInfoFields } from '@components';
import { BOTTOM_INDENTS, COLORS, CUSTOMER_INFO_FIELD_NAMES } from '@constants';
import { useStore } from '@hooks';
import { Divider, Typography } from '@ui';
import { showFormNotification } from '@utils';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import InfoContentWrap from '../InfoContentWrap';

const CustomerServiceInfo = observer(({ closeCollapse }) => {
    const store = useStore();
    const { customerInfoFormUpdate } = store.accountsStore;
    const [formSubmitting, setFormSubmitting] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        resetForm();
    }, []);

    const handleSubmit = async (values) => {
        setFormSubmitting(true);
        await customerInfoFormUpdate(values);
        setFormSubmitting(false);
        closeCollapse();

        showFormNotification(store.accountsStore.error);
    };

    const resetForm = () => {
        const { customersEmail, customersPhone, addressForCustomers } = store.accountsStore;

        form.setFieldsValue({
            [CUSTOMER_INFO_FIELD_NAMES.PHONE_NUMBER]: customersPhone.value?.replace('+', ''),
            [CUSTOMER_INFO_FIELD_NAMES.EMAIL]: customersEmail.value,
            [CUSTOMER_INFO_FIELD_NAMES.STATE]: addressForCustomers.province,
            [CUSTOMER_INFO_FIELD_NAMES.CITY]: addressForCustomers.city,
            [CUSTOMER_INFO_FIELD_NAMES.ADDRESS]: addressForCustomers.address1,
            [CUSTOMER_INFO_FIELD_NAMES.APT_NUMBER]: addressForCustomers.address2,
            [CUSTOMER_INFO_FIELD_NAMES.ZIP_CODE]: addressForCustomers.zipCode
        });
    };

    const onCancel = () => {
        resetForm();
        closeCollapse();
    };

    return (
        <InfoContentWrap>
            <Typography.Text
                data-e2e='launchcampaign_customer-service_description'
                type='body2'
                color={COLORS.$gray60Black}
            >
                This is the information you provide to your new customers in case they have questions. It should include
                a phone number where they can contact you during business hours and an email from which they can receive
                support.
            </Typography.Text>
            <Divider margin='32px 0' />
            <Typography.Title level={3} style={BOTTOM_INDENTS.S}>
                Customer Service Info
            </Typography.Title>
            <Form data-e2e='launchcampaign_customer-service_form' form={form} onFinish={handleSubmit}>
                <CustomerInfoFields />
                <Divider margin='32px 0' />
                <CancelSave onCancel={onCancel} loading={formSubmitting} />
            </Form>
        </InfoContentWrap>
    );
});

export default CustomerServiceInfo;
