export const getOptions = ({ chart = 1, externalTooltipHandler = () => null }) => {
    return {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false,
                position: 'nearest',
                external: externalTooltipHandler
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false
                },
                border: {
                    display: false
                },
                ticks: {
                    display: chart === 1,
                    font: {
                        weight: 600
                    }
                }
            },
            y: {
                stacked: false,
                grid: {
                    display: false
                },
                border: {
                    display: false
                },
                ticks: {
                    display: false
                }
            }
        },
        interaction: {
            mode: 'point'
        }
    };
};
