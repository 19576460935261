import React, { useEffect, useState } from 'react';
import { COLORS } from '@constants';
import { Col, Row, Tooltip as AntdTooltip } from 'antd';
import { Icon } from '../Icon';

export const Tooltip = ({ title, trigger = 'click', children, ...props }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const tooltipContainerElement = document.querySelector('.ant-tooltip');
        if (tooltipContainerElement) {
            tooltipContainerElement.setAttribute('data-e2e', 'common_tooltip_content');
        }
    }, [open]);

    const tooltipTitle =
        trigger === 'click' ? (
            <Row gutter={16}>
                <Col data-e2e='common_tooltip_text'>{title}</Col>
                <Col>
                    <Icon
                        size='16px'
                        color={COLORS.$white}
                        component={() => <Icon.CloseIcon />}
                        onClick={() => setOpen(false)}
                    />
                </Col>
            </Row>
        ) : (
            title
        );

    return (
        <div data-e2e='common_tooltip'>
            <AntdTooltip
                zIndex={20}
                title={tooltipTitle}
                trigger={trigger}
                open={open}
                onOpenChange={setOpen}
                {...props}
            >
                {children}
            </AntdTooltip>
        </div>
    );
};
