import { COLORS } from '@constants';

export const getValuesByPriority = (priority, step) => {
    if (priority < 40) {
        return {
            title: 'Low priority',
            color: COLORS.$gray60Black
        };
    }
    return priority < 60
        ? {
              title: 'Medium priority',
              color: COLORS.$alert
          }
        : {
              title: 'High priority',
              color: COLORS.$pink
          };
};
