import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FIELD_NAME, ROUTE_PATHS } from '@constants';
import { useStore } from '@hooks';
import { Form, Layout } from 'antd';
import { observer } from 'mobx-react';
import Preview from '../../components/Preview';
import { useFormInit } from '../../form';
import Step5Content from './components/Content';

const { Sider } = Layout;

const Step5 = observer(({ edit = false }) => {
    const navigate = useNavigate();
    const params = useParams();

    const store = useStore();
    const {
        accountProductsIds,
        pending,
        previewProductsPending,
        productsSelectionPending,
        formSubmitting,
        campaign,
        setIsNextDisabled,
        setIsNextLoading,
        updateCampaign,
        submitCampaign,
        syncSelectionWithServer,
        setFormSubmitting
    } = store.campaignWizardStore;
    const { getCampaignCommissions } = store.campaignsStore;
    const { updateCommissions, currentAccount } = store.accountsStore;

    const form = useFormInit();
    Form.useWatch(FIELD_NAME.CAMPAIGN.NAME, form);

    const nextDisabled = !campaign?.productsCount || !form.getFieldValue(FIELD_NAME.CAMPAIGN.NAME);

    const nextLoading = pending || previewProductsPending || productsSelectionPending || formSubmitting;

    useEffect(() => {
        setIsNextLoading(nextLoading);
    }, [nextLoading]);

    useEffect(() => {
        setIsNextDisabled(nextDisabled);
    }, [nextDisabled]);

    const handleFinish = async ({
        boostId,
        title,
        campaignCommission,
        coldCustomerCommission,
        newCustomerCommission,
        restOfSiteCommission
    }) => {
        setFormSubmitting(true);
        await syncSelectionWithServer();

        const commissions = [
            { form: coldCustomerCommission, store: currentAccount.commission.coldCustomer },
            { form: newCustomerCommission, store: currentAccount.commission.newCustomer },
            { form: restOfSiteCommission, store: currentAccount.commission.restOfSite }
        ];

        const hasCommissionChanged = commissions.some((commission) => commission.form !== commission.store);

        if (hasCommissionChanged) {
            await updateCommissions({
                restOfSite: restOfSiteCommission ?? 0,
                newCustomer: newCustomerCommission ?? 0,
                coldCustomer: coldCustomerCommission ?? 0
            });
        }
        await updateCampaign({ values: { boostId, title, commission: campaignCommission ?? 1 } });
        await getCampaignCommissions();
        const { ready, notConfirmed } = await submitCampaign();
        setFormSubmitting(false);

        if (ready || (notConfirmed.length === 1 && notConfirmed[0].name === 'hasValidScriptTags')) {
            navigate(ROUTE_PATHS.CAMPAIGNS);
        } else {
            navigate(`${ROUTE_PATHS.LAUNCH_CHECK}/${params?.id}`);
        }
    };

    const creatives = campaign.creatives[0];

    return (
        <Layout hasSider style={{ justifyContent: 'space-between', gap: 40 }}>
            <Form form={form} onFinish={handleFinish}>
                <Step5Content />
            </Form>
            <Sider width='300px' style={{ backgroundColor: 'unset' }}>
                <Preview
                    font={creatives.fontName}
                    primaryColor={creatives.primaryColor}
                    secondaryColor={creatives.secondaryColor}
                    customSelectedProducts={accountProductsIds}
                    offerMessage={creatives.offerMessage}
                    imageFormat={creatives.productImageBehavior}
                    brandLink={store.accountsStore?.currentAccount?.domains?.[0]?.shopDomain}
                    brandName={store.accountsStore?.currentAccount?.companyName}
                    logo={campaign.creatives[0].logoUrl}
                />
            </Sider>
        </Layout>
    );
});

export default Step5;
