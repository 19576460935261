import React from 'react';
import { COLORS, RIGHT_INDENTS } from '@constants';
import { Icon, Typography } from '@ui';
import { formatPrice } from '@utils';
import { Flex, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { PAYOUT_ITEM_TYPE } from '../../../constants';

const sharedOnCell = (record) => {
    if (record.showDetails) {
        return {
            colSpan: 0
        };
    }
    return {};
};

export const columnsInner = [
    {
        title: <span data-e2e='invoices_payout-items-sequence-number_column'>#</span>,
        dataIndex: 'sequenceNumber',
        key: 'sequenceNumber',
        render: (item, record) =>
            !record.showDetails ? (
                <span data-e2e={`invoices_payout-item-${record.id}-sequence-number_value`}>{`#${item}`}</span>
            ) : (
                <Flex align='center' justify='space-between' gap={24} style={RIGHT_INDENTS.XXS}>
                    <Flex align='center' gap={24}>
                        <Tooltip
                            title={
                                <Flex
                                    data-e2e='invoices_payout-offset-commission-tooltip_description'
                                    vertical
                                    gap={12}
                                >
                                    <Typography.Title color={COLORS.$white} level={3}>
                                        What is this?
                                    </Typography.Title>
                                    <Typography.Text color={COLORS.$white}>
                                        Consumers will most often buy on your site, but we also allow them to buy
                                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                                        directly within Klickly’s Commerce Modules. We call these "in-Klickly sales". If
                                        consumers buy within our widgets, that means we have funds to send you.
                                    </Typography.Text>
                                    <Typography.Text color={COLORS.$white}>
                                        You had enabled Commission Offset, which meant that funds earned from Commerce
                                        Module (in-Klickly) purchases were applied towards a Klickly marketing invoice.
                                    </Typography.Text>
                                </Flex>
                            }
                        >
                            <Icon
                                data-e2e='invoices_payout-offset-commission-tooltip_icon'
                                roundedSize='24px'
                                color={COLORS.$info}
                                roundedColor={COLORS.$robinTintsBlue}
                                hoverRoundedColor={COLORS.$robinTintsBlue}
                                size='16px'
                                component={() => <Icon.InfoIcon />}
                            />
                        </Tooltip>
                        <Typography.Text
                            data-e2e='invoices_payout-offset-title_value'
                            type='descriptor1'
                            color={COLORS.$info}
                        >
                            Balance offset against a marketing invoice (Invoice #{record.invoiceId})
                        </Typography.Text>
                    </Flex>
                    <Typography.Text
                        data-e2e='invoices_payout-offset-amount_value'
                        type='descriptor1'
                        color={COLORS.$info}
                    >
                        -{formatPrice(record.amount)}
                    </Typography.Text>
                </Flex>
            ),
        onCell: (record) => ({
            colSpan: record.showDetails ? 8 : 1
        })
    },
    {
        title: <span data-e2e='invoices_payout-items-type_column'>Type</span>,
        dataIndex: 'type',
        key: 'type',
        render: (value, record) => (
            <span data-e2e={`invoices_payout-item-${record.id}-type_value`}>{PAYOUT_ITEM_TYPE[value]}</span>
        ),
        onCell: sharedOnCell
    },
    {
        title: <span data-e2e='invoices_payout-items-date_column'>Date</span>,
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (value, record) => (
            <span data-e2e={`invoices_payout-item-${record.id}-date_value`}>
                {dayjs(value).format('ddd, MMM D[th], YYYY')}
            </span>
        ),
        onCell: sharedOnCell
    },
    {
        title: <span data-e2e='invoices_payout-items-order-number_column'>Order number</span>,
        dataIndex: 'order',
        key: 'order',
        render: (order, record) => (
            <span data-e2e={`invoices_payout-item-${record.id}-order-number_value`}>{order?.klicklyOrderNumber}</span>
        ),
        onCell: sharedOnCell
    },
    {
        title: <span data-e2e='invoices_payout-items-gross-amount_column'>Gross amount</span>,
        dataIndex: 'chargedAmount',
        key: 'grossAmount',
        render: (_, record) => (
            <span data-e2e={`invoices_payout-item-${record.id}-gross-amount_value`}>
                {formatPrice(record.amount + record.fee)}
            </span>
        ),
        onCell: sharedOnCell
    },
    {
        title: <span data-e2e='invoices_payout-items-fees_column'>Fees</span>,
        dataIndex: 'fee',
        key: 'fee',
        render: (value, record) => (
            <span data-e2e={`invoices_payout-item-${record.id}-fees_value`}>{formatPrice(value)}</span>
        ),
        onCell: sharedOnCell
    },
    {
        title: <span data-e2e='invoices_payout-items-refunded_column'>Refunded</span>,
        dataIndex: 'refunded',
        key: 'refunded',
        render: (value, record) => (
            <span data-e2e={`invoices_payout-item-${record.id}-refunded_value`}>{formatPrice(value)}</span>
        ),
        onCell: sharedOnCell
    },
    {
        title: <span data-e2e='invoices_payout-items-net-amount_column'>Net amount</span>,
        dataIndex: 'netAmount',
        key: 'netAmount',
        render: (_, record) => (
            <span data-e2e={`invoices_payout-item-${record.id}-net-amount_value`}>
                {formatPrice(record.amount - record.refunded)}
            </span>
        ),
        onCell: sharedOnCell
    }
];
