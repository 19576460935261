import _ from 'lodash';
import { getSnapshot, types } from 'mobx-state-tree';

const CustomCreativeUnitData = types.model({
    id: types.maybeNull(types.number),
    imageSrc: types.optional(types.string, ''),
    validationStatus: types.optional(types.integer, 0)
});

export const CustomCreativeUnitModel = types
    .model('CustomCreativeModel', {
        size: types.string,
        serverData: CustomCreativeUnitData,
        localData: CustomCreativeUnitData
    })
    .views((self) => ({
        isModified() {
            return JSON.stringify(getSnapshot(self.serverData)) !== JSON.stringify(getSnapshot(self.localData));
        },
        isDeleted() {
            return self.serverData.imageSrc && !self.localData.imageSrc;
        },
        getModifiedFields() {
            const modifiedFields = {};
            Object.keys(self.localData).forEach((key) => {
                if (self.localData[key] !== self.serverData[key]) {
                    modifiedFields[key] = self.localData[key];
                }
            });
            return modifiedFields;
        }
    }))
    .actions((self) => ({
        updateField(field, value) {
            if (_.has(self.localData, field)) {
                self.localData[field] = value;
            }
        },
        // Reset localData to match serverData
        resetToServerData() {
            self.localData = getSnapshot(self.serverData);
        }
    }));
