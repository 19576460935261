import React, { useState } from 'react';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { useStore } from '@hooks';
import { Button, Icon, Typography } from '@ui';
import { observer } from 'mobx-react';
import config from '../../../../../../../../../../config';
import { StyledDescription, StyledInput } from './styled';

const ApiSetupModal = observer(({ data }) => {
    const store = useStore();
    const { apiToken } = store.accountsStore;

    const [masked, setMasked] = useState(true);
    const maskedApiKey = apiToken
        ? `${'*'.repeat(apiToken.split('-')[0].length)}-${apiToken.split('-')[1].slice(0, 2)}${'*'.repeat(
              apiToken.split('-')[1].length - 2
          )}`
        : '';

    return (
        <React.Fragment>
            <Typography.Text type='descriptor1' color={COLORS.$black} style={BOTTOM_INDENTS.M}>
                All Klickly REST API requests should be sent to the following server using your Secret Key in the header
                as <strong>x-klickly-api-token</strong>:
            </Typography.Text>
            <StyledInput
                disabled
                style={BOTTOM_INDENTS.M}
                suffix={<Button.Copy value={config.boaUrl} />}
                value={config.boaUrl}
                label='API endpoint'
            />
            <StyledInput
                prefix={
                    <Button
                        type='link'
                        onClick={() => setMasked(!masked)}
                        icon={
                            <Icon
                                size='24px'
                                color={COLORS.$gray60Black}
                                component={() => (masked ? <Icon.EyeIcon /> : <EyeInvisibleOutlined />)}
                            />
                        }
                    />
                }
                disabled
                style={BOTTOM_INDENTS.M}
                suffix={<Button.Copy value={apiToken} />}
                value={masked ? maskedApiKey : apiToken}
                label='Secret Key'
            />
            <StyledDescription>
                <Typography.Text align='left' type='descriptor1'>
                    An <span>API key</span> is a unique identifier provided to users for accessing a service&apos;s API.
                    It acts as a digital key, granting permission to interact with the service programmatically. This
                    key is essential for authentication and authorization, ensuring that only authorized individuals or
                    applications can access the service&apos;s data or functionalities.
                </Typography.Text>
            </StyledDescription>
        </React.Fragment>
    );
});

export default ApiSetupModal;
