import React from 'react';
import { PLATFORM_ID, PRODUCT_ECOMMERCE_STATUS_LABELS, PRODUCT_KLICKLY_STATUS_LABELS } from '@enums';
import { Icon, Image, Tag } from '@ui';
import { formatString } from '@utils';
import ProductActions from '../components/ProductActions';
import { getEcommerceStatusColor, getKlicklyStatusColor } from '../utils';

export const getColumns = (productTypes, platformId) => {
    const isManual = platformId === PLATFORM_ID.KLICKLY;

    const standardPercentWidth = isManual ? 18 : 14;
    const columns = [
        {
            title: '',
            dataIndex: 'images',
            key: 'image',
            width: '5%',
            render: (images, record) => {
                return record.primaryImage?.src ? (
                    <Image
                        data-e2e={`products_${record.id}-product_image`}
                        preview={false}
                        height={36}
                        width={36}
                        alt={record.title}
                        src={record.primaryImage?.src}
                    />
                ) : (
                    <Icon.ProductNoImageIcon height={36} width={36} />
                );
            }
        },
        {
            title: <span data-e2e='products_title_column'>Title</span>,
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: '26%',
            render: (title, record) => <span data-e2e={`products_${record.id}_title`}>{title}</span>
        },
        {
            title: <span data-e2e='products_product-type_column'>Product type</span>,
            dataIndex: 'type',
            key: 'type',
            width: '13%',
            filters: productTypes.map(({ title, id }) => ({
                text: <span data-e2e={`products_${formatString(title)}-product-type_selector`}>{title}</span>,
                value: id
            })),
            render: (type, record) => (
                <span data-e2e={`products_${record.id}-product-type_value`}>
                    {type.title === 'default' ? '-' : type.title}
                </span>
            )
        },
        {
            title: <span data-e2e='products_inventory_column'>Inventory</span>,
            dataIndex: 'quantity',
            className: 'title-right',
            key: 'quantity',
            align: 'right',
            sorter: true,
            width: `${standardPercentWidth - 1}%`,
            render: (quantity, record) => (
                <span data-e2e={`products_${record.id}-inventory_value`}>{quantity || '-'}</span>
            )
        },
        {
            title: <span data-e2e='products_klickly-status_column'>Klickly Status</span>,
            dataIndex: 'klicklyStatus',
            key: 'klicklyStatus',
            width: `${standardPercentWidth}%`,
            render: (status, record) => {
                return (
                    <Tag
                        data-e2e={`products_${record.id}-klickly-status_value`}
                        color={getKlicklyStatusColor(record.klicklyStatus)}
                    >
                        {PRODUCT_KLICKLY_STATUS_LABELS[record.klicklyStatus]}
                    </Tag>
                );
            }
        },
        {
            title: <span data-e2e='products_ecommerce-status_column'>Ecommerce Status</span>,
            dataIndex: 'ecommerceStatus',
            key: 'ecommerceStatus',
            width: `${standardPercentWidth}%`,
            filters: Object.entries(PRODUCT_ECOMMERCE_STATUS_LABELS[platformId]).map(([status, { label, e2e }]) => ({
                text: <span data-e2e={e2e}>{label}</span>,
                value: Number(status)
            })),
            render: (status, record) => {
                if (status === undefined) return '-';
                return (
                    <Tag
                        data-e2e={`products_${record.id}-ecommerce-status_value`}
                        color={getEcommerceStatusColor(status)}
                    >
                        {PRODUCT_ECOMMERCE_STATUS_LABELS[platformId][status].label}
                    </Tag>
                );
            }
        }
    ];

    if (!isManual) {
        columns.push({
            title: <span data-e2e='products_actions_column'>Actions</span>,
            dataIndex: 'actions',
            className: 'title-right',
            key: 'actions',
            align: 'right',
            render: (_, product, record) => {
                return <ProductActions product={product} />;
            }
        });
    }

    return columns;
};
