import styled from 'styled-components';

export const StyledPerformance = styled.div`
    padding-top: 64px;
    padding-bottom: 64px;
    overflow: hidden;

    @media (max-width: 900px) {
        padding-bottom: 96px;
        padding-top: 20px;
    }

    &.bg-gradient {
        &:before {
            clip-path: polygon(0px 150px, 100% 0px, 100% 100%, 0px 100%);

            @media (max-width: 900px) {
                clip-path: polygon(0px 0px, 100% 30px, 100% 100%, 0px calc(100% - 30px));
            }
        }
    }

    .performance-section__wrapper {
        z-index: 1;
        position: relative;

        h2 {
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 64px;
            text-align: center;
            margin-bottom: 16px;
            color: #04080f;
            font-family: 'IBM Plex Sans', Helvetica, sans-serif;

            @media (max-width: 900px) {
                font-size: 48px;
                line-height: 56px;
                text-align: left;
            }
        }

        p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: #04080f;
            font-family: 'IBM Plex Sans', Helvetica, sans-serif;

            @media (max-width: 900px) {
                font-size: 16px;
                font-weight: 500;
            }
        }

        &__items {
            margin-top: 48px;
            display: flex;
            justify-content: center;
            gap: 16px;

            @media (max-width: 1200px) {
                flex-wrap: wrap;
                margin-top: 24px;
            }

            @media (max-width: 500px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 16px;
                grid-row-gap: 16px;
            }

            &__single {
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    @media (max-width: 500px) {
                        width: 155px;
                        height: 124px;
                        object-fit: contain;
                    }
                }

                p {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: center;
                    color: #04080f;
                    font-family: 'IBM Plex Sans', Helvetica, sans-serif;
                }
            }
        }
    }
`;
