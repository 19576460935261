import { FIELD_NAME } from '@constants';
import { Form } from 'antd';

export const usePreviewProducts = (products, customSelectedProducts = []) => {
    const form = Form.useFormInstance();
    const selectedProducts = Form.useWatch(FIELD_NAME.CAMPAIGN.SELECTED_PRODUCTS, form);
    return products
        .filter(({ id }) => (selectedProducts || customSelectedProducts).includes(id))
        .sort(() => Math.random() - Math.random());
};
