import { COLORS } from '@constants';
import { Radio } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Radio.Group)`
    padding: 24px;
    background: ${COLORS.$gray10Black};
    border: 1px solid ${COLORS.$gray15Black};
    border-radius: 8px;
`;

export const StyledAlert = styled.div`
    padding: 23px 24px;
    border: 1px solid ${({ $isValid }) => ($isValid ? COLORS.$robinBlue : COLORS.$tartRed)};
    border-radius: 8px;
    background: ${({ $isValid }) => ($isValid ? COLORS.$robinTintsBlue : COLORS.$tartRedLite)};
`;

export const StyledWrapper = styled.div`
    position: relative;
`;

export const StyledBlur = styled.div`
    filter: ${({ $isLoading }) => ($isLoading ? 'blur(4px)' : '')};
`;
