import styled from 'styled-components';

export const StyledFooter = styled.div`
    padding: 9px 0 40px 0;

    @media (max-width: 768px) {
        padding: 0;
    }

    .main-footer__wrapper {
        display: flex;
        justify-content: space-between;
        gap: 24px;
        align-items: center;
        padding: 38px 0;
        border-bottom: 1px solid #04080f;

        @media (max-width: 1200px) {
            flex-direction: column;
            align-items: flex-start;
        }

        @media (max-width: 768px) {
            border-bottom: 0;
            padding: 24px 0;
        }

        &__logo {
            display: flex;
            align-items: center;
            gap: 24px;

            p {
                color: #04080f;
                font-family: 'IBM Plex Sans', Helvetica, sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;

                @media (max-width: 768px) {
                    color: #77838f;
                }
            }
        }

        &__links {
            display: flex;
            gap: 24px;
            align-items: center;
            row-gap: 16px;

            @media (max-width: 1200px) {
                flex-wrap: wrap;
            }

            a {
                font-family: 'IBM Plex Sans', Helvetica, sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                text-decoration: none;
                color: #04080f;

                @media (max-width: 768px) {
                    color: #77838f;
                }
            }
        }
    }
`;
