import React from 'react';
import { StyledPerformance } from './styled';

const performanceItems = [
    {
        text: '90M Consumer CDP'
    },
    {
        text: 'Underlying Merchant Sales'
    },
    {
        text: 'Full funnel data points'
    },
    {
        text: 'Average ROAS per client'
    },
    {
        text: 'Premium destinations'
    },
    {
        text: 'Advanced data reporting'
    }
];

const PerformanceSection = () => {
    return (
        <StyledPerformance className='bg-gradient bg-gradient-pink-invert'>
            <div data-e2e='landing_performance-section' className='container-content'>
                <div className='performance-section__wrapper'>
                    <h2 data-e2e='landing_performance-section_title'>
                        Pure performance: only pay for sales Klickly helped drive
                    </h2>
                    <p data-e2e='landing_performance-section_description'>
                        We are the only acquisition platform that lets you choose your own commission (and pay only on
                        success). Why? Because we can. We have game-changing proprietary data, AI, and scale. So, unlike
                        other platforms, we put our money where our mouth is and only charge when we do what we promise:
                        drive customer acquisition.
                    </p>
                    <div className='performance-section__wrapper__items'>
                        {performanceItems.map((item, index) => {
                            return (
                                <div key={index} className='performance-section__wrapper__items__single'>
                                    <img alt={item.text} src={require(`../../images/performance_${++index}.svg`)} />
                                    <p data-e2e={`landing_performance-item-${index}_title`}>{item.text}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </StyledPerformance>
    );
};

export default PerformanceSection;
