import React, { Fragment } from 'react';
import nextId from 'react-id-generator';
import { SectionHeader } from '@components';
import { BOTTOM_INDENTS, TOP_INDENTS } from '@constants';
import { RedirectLink } from '@ui';
import { Row } from 'antd';
import SinglePerformance from '../components/SinglePerformance';
import { PERFORMANCE_BLOCKS } from '../constants';

const EmptyState = () => {
    return (
        <Fragment>
            <SectionHeader data-e2e='home_performance-section_title' title='Performance overview' />
            <Row style={TOP_INDENTS.XL} gutter={[16, 16]}>
                {PERFORMANCE_BLOCKS.map((item) => {
                    return <SinglePerformance key={nextId()} item={item} />;
                })}
            </Row>
            <RedirectLink
                data-e2e='home_performance_link'
                style={{ ...TOP_INDENTS.XL_PLUS, ...BOTTOM_INDENTS.XL_PLUS }}
                text='See detailed performance'
                fontSize='16px'
                to='/'
            />
        </Fragment>
    );
};

export default EmptyState;
