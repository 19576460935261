import React from 'react';
import { COLORS, TOP_INDENTS } from '@constants';
import { Divider, Progress, Typography } from '@ui';
import { formatString } from '@utils';
import { Card, Col, Row } from 'antd';

const SingleCampaign = ({ item }) => {
    return (
        <Col data-e2e={`home_${formatString(item.title)}-campaign_card`} span={8}>
            <Card>
                <Row wrap={false} align='middle' gutter={[16, 16]}>
                    <Col>
                        <Progress
                            data-e2e={`home_${formatString(item.title)}-campaign_priority-circle`}
                            strokeColor={{ '0%': COLORS.$yellow, '100%': COLORS.$magenta }}
                            type='circle'
                            strokeWidth={14}
                            percent={item.priority}
                            size={96}
                        />
                        <Typography.Text style={TOP_INDENTS.XXS} color={COLORS.$gray40Black} type='descriptor2'>
                            Average priority
                        </Typography.Text>
                    </Col>
                    <Col>
                        <Typography.Text
                            data-e2e={`home_${formatString(item.title)}-campaign_title`}
                            type='badgeMedium'
                        >
                            {item.title}
                        </Typography.Text>
                    </Col>
                </Row>
                <Divider style={{ borderBlockStart: `1px solid ${COLORS.$gray15Black}` }} margin='16px 0' />
                <Row gutter={[8, 8]} align='middle'>
                    <Col span={6}>
                        <Typography.Text color={COLORS.$gray40Black} type='badgeSmall' align='right'>
                            Revenue
                        </Typography.Text>
                    </Col>
                    <Col span={6}>
                        <Typography.Text
                            data-e2e={`home_${formatString(item.title)}-campaign-revenue_value`}
                            color={COLORS.$gray100Black}
                            type='badgeSmall'
                        >
                            {item.revenue || '-'}
                        </Typography.Text>
                    </Col>
                    <Col span={6}>
                        <Typography.Text color={COLORS.$gray40Black} type='badgeSmall' align='right'>
                            ROAS
                        </Typography.Text>
                    </Col>
                    <Col span={6}>
                        <Typography.Text
                            data-e2e={`home_${formatString(item.title)}-campaign-roas_value`}
                            color={COLORS.$gray100Black}
                            type='badgeSmall'
                        >
                            {item.roas || '-'}
                        </Typography.Text>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
};

export default SingleCampaign;
