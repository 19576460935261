import { useCallback, useState } from 'react';
import { CAMPAIGN_TYPE_ID } from '@enums';
import { useStore } from '@hooks';
import { Icon } from '@ui';
import _ from 'lodash';
import { toJS } from 'mobx';

const useSpecificCollection = () => {
    const store = useStore();
    const { productCollections } = store.accountsStore.currentAccount;

    const [collectionsToShow, setCollectionsToShow] = useState([]);
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const debouncedOnSearchChange = useCallback(
        _.debounce((string) => {
            setCollectionsToShow(
                toJS(productCollections.filter(({ title }) => title.toLowerCase().includes(string.toLowerCase())))
            );
        }, 500),
        []
    );

    const onSearchChange = (e) => {
        setSearchValue(e.target.value);
        debouncedOnSearchChange(e.target.value);
    };

    const toggleCollection = (item) => {
        if (!selectedCollections.find((i) => i.id === item.id)) {
            setSelectedCollections([...selectedCollections, item]);
        } else {
            setSelectedCollections(selectedCollections.filter((i) => i.id !== item.id));
        }
    };

    const unselectCollection = (item) => {
        setSelectedCollections(selectedCollections.filter((i) => i.id !== item.id));
    };

    const initialAction = () => {
        const { productCollections } = store.accountsStore.currentAccount;
        setCollectionsToShow(toJS(productCollections));
    };

    const willUnmountAction = () => {
        setSelectedCollections([]);
        setSearchValue('');
    };

    const specificProductTypeProps = {
        value: CAMPAIGN_TYPE_ID.SPECIFIC_COLLECTIONS,
        label: 'Specific Collections',
        description: 'Allow Klickly to advertise using products from one or more specific collections.',
        CardIcon: Icon.CollectionsIcon,
        expandable: true,
        expandedContentProps: {
            placeholder: 'Filter collections by name',
            listItems: collectionsToShow,
            selectedListItems: selectedCollections,
            onListItemClick: toggleCollection,
            onTagClose: unselectCollection,
            searchValue,
            setSearchValue,
            onSearchChange,
            initialAction,
            willUnmountAction
        }
    };

    return specificProductTypeProps;
};

export { useSpecificCollection };
