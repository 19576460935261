import React, { useState } from 'react';
import { COLORS } from '@constants';
import { Select as AntdSelect } from 'antd';
import { Icon } from '../Icon';
import Label from './components/Label';
import { Tag } from './components/Tag';
import { StyledPrefix, StyledWrapper } from './styled';

export const Select = ({ label, open = false, prefix, mode, ...props }) => {
    const isMultiple = mode === 'multiple';
    const OpenedSelectIcon = isMultiple ? Icon.SearchIcon : Icon.ArrowChevronUpIcon;
    const [isOpen, setIsOpen] = useState(open);
    const selectIcon = isOpen ? (
        <OpenedSelectIcon width='24px' height='24px' color={COLORS.$gray60Black} />
    ) : (
        <Icon.ArrowChevronDownIcon width='24px' height='24px' color={COLORS.$gray60Black} />
    );

    return (
        <StyledWrapper $hasPrefix={Boolean(prefix)} $hasLabel={Boolean(label)}>
            {prefix && (
                <StyledPrefix>
                    {typeof prefix === 'boolean' ? (
                        <Icon size='24px' color={COLORS.$gray60Black} component={() => selectIcon} />
                    ) : (
                        prefix
                    )}
                </StyledPrefix>
            )}
            <Label isMultiple={isMultiple} label={label} hasPrefix={Boolean(prefix)}>
                <AntdSelect
                    getPopupContainer={(node) => node}
                    open={isOpen}
                    mode={mode}
                    tagRender={Tag}
                    placeholder={label}
                    onDropdownVisibleChange={(open) => {
                        setIsOpen(open);
                    }}
                    suffixIcon={prefix ? null : selectIcon}
                    {...props}
                />
            </Label>
        </StyledWrapper>
    );
};
