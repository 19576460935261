import _ from 'lodash';
import { types } from 'mobx-state-tree';

const CommissionModel = types
    .model({
        accountId: types.number,
        coldCustomer: types.number,
        coldCustomerEnabled: types.boolean,
        commissionOffsetEnabled: types.boolean,
        id: types.number,
        newCustomer: types.number,
        newCustomerEnabled: types.boolean,
        restOfSite: types.number,
        restOfSiteEnabled: types.boolean
    })
    .actions((self) => ({
        update(values) {
            Object.assign(self, values);
        }
    }));

const DomainModel = types.model({
    accountId: types.number,
    createdAt: types.string,
    id: types.number,
    type: types.number,
    class: types.number,
    isPrimary: types.boolean,
    rejectedAt: types.maybeNull(types.string),
    shopDomain: types.string,
    status: types.number,
    updatedAt: types.maybeNull(types.string),
    verifiedAt: types.maybeNull(types.string)
});

const Ga4SettingsModel = types.model({
    id: types.number,
    accountId: types.number,
    trackCompanyId: types.number,
    enabled: types.boolean,
    eventsToTrack: types.array(types.number),
    measurementId: types.maybeNull(types.string)
});

const ThirdPartyAppsSettings = types.model({
    id: types.number,
    accountId: types.number,
    transUnionGUID: types.maybeNull(types.string),
    transUnionRequestMessage: types.maybeNull(types.string),
    transUnionRequestedAt: types.maybeNull(types.string)
});

const TwSettingsModel = types.model({
    id: types.number,
    accountId: types.number,
    enabled: types.boolean,
    eventsToTrack: types.array(types.number),
    includeNonLinkedViews: types.boolean,
    trackCompanyId: types.number
});

const UtmSettingsModel = types.model({
    id: types.number,
    accountId: types.number,
    campaign: types.string,
    enabled: types.boolean,
    source: types.string,
    medium: types.string,
    term: types.string
});

const DomainScriptsModel = types.array(
    types.model({
        id: types.number,
        status: types.number,
        lastPingAt: types.maybeNull(types.string),
        templateVariables: types.model({
            searchParams: types.model({
                version: types.string,
                platform: types.string,
                shopDomain: types.string,
                account_id: types.number,
                b_type_cart: types.maybeNull(types.string),
                b_type_checkout: types.maybeNull(types.string),
                b_type_visit: types.maybeNull(types.string)
            })
        }),
        domain: types.model({
            id: types.number,
            shopDomain: types.string
        }),
        scriptTag: types.model({
            id: types.number,
            htmlId: types.string,
            src: types.maybeNull(types.string),
            tag: types.maybeNull(types.string)
        })
    })
);

const SyncProgressModel = types
    .model({
        accountId: types.maybeNull(types.number),
        createdAt: types.maybeNull(types.string),
        currentPage: types.maybeNull(types.string),
        done: types.maybeNull(types.boolean),
        errorMessage: types.maybeNull(types.string),
        failedAt: types.maybeNull(types.string),
        id: types.maybeNull(types.number),
        progressCount: types.maybeNull(types.number),
        resourceName: types.string,
        status: types.maybeNull(types.number),
        totalCount: types.maybeNull(types.number),
        updatedAt: types.maybeNull(types.string)
    })
    .views((self) => ({
        get percentDone() {
            if (self.totalCount === 0) return 0;
            return Math.floor((self.progressCount / self.totalCount) * 100);
        }
    }));

export const CurrentAccountModel = types.maybeNull(
    types.model({
        attributionWindowSettings: types.maybeNull(
            types.model({
                numberOfClickDays: types.number,
                numberOfViewDays: types.number
            })
        ),
        commission: types.maybeNull(CommissionModel),
        companyName: types.maybeNull(types.string),
        connectorStatus: types.number,
        country: types.string,
        countryCode: types.string,
        createdAt: types.string,
        currency: types.string,
        domainScripts: DomainScriptsModel,
        domains: types.array(DomainModel),
        externalId: types.maybeNull(types.string),
        ga4Settings: types.maybeNull(Ga4SettingsModel),
        id: types.number,
        isMigratedBrand: types.boolean,
        logoUrl: types.maybeNull(types.string),
        plan: types.string,
        platformId: types.number,
        prevStatus: types.maybeNull(types.number),
        productCollections: types.array(types.model({ id: types.number, title: types.string })),
        productTypes: types.array(types.model({ id: types.number, title: types.string })),
        resourcesOutSync: types.array(types.string),
        settings: types.maybeNull(
            types.model({
                id: types.number,
                accountId: types.number,
                autoReviewEnabled: types.boolean,
                packageSize: types.model({
                    height: types.maybeNull(types.number),
                    length: types.maybeNull(types.number),
                    weight: types.maybeNull(types.number),
                    width: types.maybeNull(types.number)
                }),
                showModal: types.model({
                    setupPayouts: types.boolean
                }),
                customCreativesEnabled: types.maybeNull(types.boolean)
            })
        ),
        stripeSettings: types.maybeNull(
            types.model({
                id: types.number,
                accountId: types.maybeNull(types.number),
                stripeCustomerId: types.maybeNull(types.string),
                stripeAccountId: types.maybeNull(types.string),
                stripeAccountType: types.maybeNull(types.string),
                stripeAccountStatus: types.number,
                holdPayments: types.boolean,
                stripeAccountUpdatedAt: types.maybeNull(types.string)
            })
        ),
        status: types.number,
        statusBlockReasons: types.array(types.string),
        // connector's app connected platforms shopify <= connector app => klickly, other dummy platforms
        storeName: types.maybeNull(types.string),
        syncProgress: types.array(SyncProgressModel),
        timeZone: types.string,
        tripleWhaleSettings: types.maybeNull(TwSettingsModel),
        thirdPartyAppsSettings: types.maybeNull(ThirdPartyAppsSettings),
        lastUninstalledAt: types.maybeNull(types.string),
        unitSystem: types.string,
        utmSettings: types.maybeNull(UtmSettingsModel),
        weightUnit: types.string
    })
);

// * Contacts
export const Contact = types.model({
    id: types.number,
    accountId: types.number,
    type: types.number,
    value: types.maybeNull(types.string),
    valueType: types.number,
    confirmed: types.boolean,
    verified: types.boolean,
    notifyOnActions: types.array(types.number)
});

// * Addresses
export const Address = types.model({
    id: types.number,
    accountId: types.number,
    address1: types.maybeNull(types.string),
    address2: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    province: types.maybeNull(types.string),
    provinceCode: types.maybeNull(types.string),
    zipCode: types.maybeNull(types.string),
    confirmed: types.boolean,
    type: types.number
});

// * Invoices
const InvoiceItem = types.model({
    id: types.number,
    chargedAmount: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    refundedAmount: types.maybeNull(types.number),
    sequenceNumber: types.maybeNull(types.number),
    boostId: types.maybeNull(types.number),
    orderId: types.maybeNull(types.number),
    boost: types.maybeNull(
        types.model({
            id: types.maybeNull(types.number),
            title: types.maybeNull(types.string)
        })
    ),
    order: types.maybeNull(
        types.model({
            id: types.maybeNull(types.number),
            klicklyOrderNumber: types.maybeNull(types.string)
        })
    ),
    campaigns: types.array(
        types.model({
            campaign: types.model({ title: types.string })
        })
    )
});
export const Charge = types.model({
    id: types.number,
    createdAt: types.maybeNull(types.string),
    sequenceNumber: types.maybeNull(types.number),
    status: types.maybeNull(types.number),
    amount: types.maybeNull(types.number),
    items: types.array(InvoiceItem),
    payoutOffsets: types.array(
        types.model({
            id: types.number,
            payoutItemId: types.maybeNull(types.number),
            amount: types.maybeNull(types.number),
            payoutItem: types.maybeNull(
                types.model({
                    payout: types.maybeNull(
                        types.model({
                            sequenceNumber: types.maybeNull(types.number)
                        })
                    )
                })
            )
        })
    )
});

// * States
export const State = types.model({
    id: types.string,
    name: types.string,
    selected: types.boolean
});

// * Team member
export const TeamMember = types
    .model({
        id: types.maybe(types.number),
        tempId: types.maybe(types.string),
        role: types.number,
        status: types.number,
        lastLoginAt: types.maybe(types.string),
        createdAt: types.maybe(types.string),
        user: types.model({
            currentAccountId: types.maybeNull(types.number),
            email: types.string,
            firstName: types.maybe(types.string),
            lastName: types.maybe(types.string)
        })
    })
    .actions((self) => ({
        update(updatedMember) {
            _.merge(self, updatedMember);
        }
    }));

// * SetupInfo
const PaymentMethod = types.maybeNull(
    types.union(
        types
            .model({
                type: types.literal('card'),
                brand: types.string,
                last4: types.string,
                exp_month: types.number,
                exp_year: types.number
            })
            .views((self) => ({
                get isVerified() {
                    return true;
                }
            })),
        types
            .model({
                type: types.literal('us_bank_account'),
                bank_name: types.string,
                status: types.string,
                last4: types.string,
                verification_link: types.maybeNull(types.string)
            })
            .views((self) => ({
                get isVerified() {
                    return self.status === 'succeeded';
                }
            }))
    )
);

const ApiToken = types.model({
    id: types.number,
    accountId: types.number,
    status: types.number,
    token: types.string,
    createdAt: types.string,
    revokedAt: types.maybeNull(types.string)
});

const ScriptTag = types.model({
    platformId: types.number,
    src: types.maybeNull(types.string),
    status: types.number,
    lastPingAt: types.maybeNull(types.string),
    lastPingPurchaseAt: types.maybeNull(types.string)
});

export const SetupInfo = types.model({
    scriptTag: ScriptTag,
    apiTokens: types.array(ApiToken),
    paymentMethod: PaymentMethod,
    showPayments: types.boolean,
    productCatalog: types.maybeNull(types.number),
    purchaseEventStatus: types.maybeNull(types.number),
    historical: types.maybeNull(
        types.model({
            hasOrders: types.maybeNull(types.boolean),
            hasCustomers: types.maybeNull(types.boolean)
        })
    )
});

export const UnsubscribeList = types.model({
    role: types.number,
    companyName: types.string,
    accountId: types.number,
    contactId: types.number,
    notifications: types.array(types.number)
});

export const Verification = types.model({
    status: types.number,
    expiredAt: types.string,
    isExpired: types.boolean,
    user: types.maybeNull(
        types.model({
            firstName: types.maybeNull(types.string),
            lastName: types.maybeNull(types.string),
            email: types.maybeNull(types.string),
            password: types.maybeNull(types.boolean)
        })
    ),
    account: types
        .model({
            storeName: types.string,
            contacts: types.array(types.model({ value: types.string }))
        })
        .views((self) => ({
            get email() {
                const contacts = self.contacts.filter((contact) => contact.value);
                if (contacts.length) return contacts[0];
                return undefined;
            }
        }))
});
