import { useEffect, useState } from 'react';
import { useStore } from '@hooks';

export const useProgress = (setLoading) => {
    const store = useStore();
    const { productsSyncProgress, isManual } = store.accountsStore;
    const { getProducts } = store.productsStore;
    const defaultShowProgress = isManual ? false : !productsSyncProgress?.done;
    const [showProgress, setShowProgress] = useState(defaultShowProgress);

    useEffect(() => {
        let timerId;

        if (productsSyncProgress?.done && showProgress) {
            timerId = setTimeout(async () => {
                setShowProgress(false);
                setLoading(true);
                await getProducts();
                setLoading(false);
            }, 2000);
        }

        return () => clearTimeout(timerId);
    }, [productsSyncProgress?.done]);

    return showProgress;
};
