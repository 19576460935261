import { formatPrice } from '@utils';
import { PAYOUT_STATUS } from '../../../constants';

export const getOffsetBalance = (payouts) => {
    const openPayouts = payouts.filter((item) => item.status === PAYOUT_STATUS.OPEN);
    if (!openPayouts.length) {
        return formatPrice(0);
    }

    const price = openPayouts[0].items.reduce((acc, item) => {
        const { offsets = [] } = item;
        let res = item.amount - item.refunded;
        offsets.forEach((item) => {
            res -= item.amount;
        });
        acc += res;

        return acc;
    }, 0);

    return formatPrice(price);
};

export const calculateItemsFullTotalAmount = (payouts) => {
    return payouts.map((payout) => {
        const numbers = payout.items.reduce(
            (acc, item) => {
                const itemPaid = item.offsets?.reduce((sum, offset) => sum + offset.amount, 0);

                acc.amount += item.amount;
                acc.fee += item.fee;
                acc.refunded += item.refunded;
                acc.paid = acc.paid + (itemPaid || 0);

                return acc;
            },
            { amount: 0, fee: 0, refunded: 0, paid: 0 }
        );

        numbers.fullTotal = numbers.amount - numbers.refunded - numbers.paid;
        return { ...payout, ...numbers };
    });
};
