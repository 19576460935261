import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
    min-height: 100%;
    padding-left: 24px;
    padding-right: 24px;
    background: ${COLORS.$gray10Black};

    & > .ant-row {
        max-width: 1324px;
        margin: 0 auto;
    }
`;
