import React from 'react';
import { COLORS } from '@constants';
import { SCRIPT_TAG_STATUS } from '@enums';
import { Icon } from '@ui';
import { StyledText } from '../LineItem/styled';

const StatusBadge = ({ status }) => {
    if (status === SCRIPT_TAG_STATUS.CONNECTED) {
        return (
            <StyledText type='badgeSmall' color={COLORS.$robinBlue}>
                <Icon size='24px' component={() => <Icon.CheckCircleIcon />} />
                Connected
            </StyledText>
        );
    }

    if (status === SCRIPT_TAG_STATUS.PENDING) {
        return (
            <StyledText type='badgeSmall' color={COLORS.$alert}>
                <Icon size='24px' component={() => <Icon.PendingCircleIcon />} />
                Pending...
            </StyledText>
        );
    }

    if (status === SCRIPT_TAG_STATUS.NOT_CONNECTED) {
        return (
            <StyledText type='badgeSmall' color={COLORS.$tartRed}>
                <Icon size='24px' component={() => <Icon.CloseCircleIcon />} />
                Not Set
            </StyledText>
        );
    }
};

export default StatusBadge;
