import { COLORS } from '@constants';
import { Flex } from 'antd';
import styled, { css } from 'styled-components';

export const StyledMenuItem = styled(Flex)`
    cursor: pointer;

    &:hover {
        p {
            color: ${COLORS.$gray100Black};
        }
    }

    ${(props) =>
        props.$currentAccountItem &&
        css`
            p {
                font-weight: 500;
                color: ${COLORS.$pink};
            }

            &:hover {
                p {
                    color: ${COLORS.$pink};
                }
            }
        `}
`;
