export const COLORS = {
    $black: '#000000',
    $gray100Black: '#04080F',
    $gray80Black: '#1C3144',
    $gray60Black: '#77838F',
    $gray40Black: '#A4ADB4',
    $gray20Black: '#D2D6DA',
    $gray15Black: '#E8E8E8',
    $gray10Black: '#F4F4F4',
    $white: '#FFFFFF',
    $tartRed: '#F25858',
    $tartRedLite: '#FFEBEB',
    $robinBlue: '#26A6A6',
    $robinTintsBlue: '#DEF8F8',
    $violet: '#8338EC',
    $seaWeed: '#028090',
    $forestGreen: '#248232',
    $cornflowerBlue: '#00398F',
    $pinkLight: '#FF008B',
    $pink: '#DA1781',
    $pinkDark: '#B20061',
    $pinkGradient: 'linear-gradient(90deg, #DA1781 0%, #FF008B 100%)',
    $error: '#EF2E2E',
    $alert: '#FFC53A',
    $success: '#1CA355',
    $info: '#33B6DF',
    $yellow: '#FFD700',
    $oldLace: '#FFF7E5',
    $magenta: '#FF41F2'
};
