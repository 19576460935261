import { COLORS } from '@constants';
import { Icon } from '@ui';
import styled from 'styled-components';

export const InfoIcon = styled(Icon.HintIcon)`
    &:hover {
        color: ${COLORS.$gray15Black};
        cursor: pointer;
    }
`;

export const SkeletonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        width: 40px;
        margin-bottom: 10px;

        .ant-skeleton-title {
            height: 20px;
        }
    }

    .description {
        width: 40px;

        &:not(:last-child) {
            margin-bottom: 4px;
        }

        .ant-skeleton-title {
            height: 8px;
        }
    }
`;
