import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Col, Row } from 'antd';
import { Divider } from '../Divider';
import { Radio } from '../Radio';
import { Typography } from '../Typography';
import { StyledWrapper } from './styled';

export const WhiteCover = ({
    title,
    customTitle,
    children,
    isValid,
    hasDivider = true,
    rightContent,
    loading,
    ...props
}) => {
    return (
        <StyledWrapper {...props}>
            {(title || customTitle) && (
                <Row gutter={16} align='middle' justify='space-between' style={!hasDivider && BOTTOM_INDENTS.M}>
                    <Col>{title ? <Typography.Title level={2}>{title}</Typography.Title> : customTitle}</Col>
                    {isValid && (
                        <Col>
                            <Radio.WithLabel
                                value='arial'
                                checked
                                color={COLORS.$robinBlue}
                                labelColor={COLORS.$robinBlue}
                                cursor='default'
                                labelLeftIndent='8px'
                                height='24px'
                            >
                                Approved
                            </Radio.WithLabel>
                        </Col>
                    )}
                    {rightContent && <Col>{rightContent}</Col>}
                </Row>
            )}
            {hasDivider && <Divider />}
            {children}
        </StyledWrapper>
    );
};
