import React from 'react';
export const DETAILIZATION_TABS = [
    {
        key: '1',
        label: <span data-e2e='performance_days-detalization_tab'>Days</span>,
        id: 'day'
    },
    {
        key: '2',
        label: <span data-e2e='performance_weeks-detalization_tab'>Weeks</span>,
        id: 'week'
    },
    {
        key: '3',
        label: <span data-e2e='performance_months-detalization_tab'>Months</span>,
        id: 'month'
    }
];
