import React, { Fragment, useEffect, useState } from 'react';
import { CancelSave } from '@components';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { useStore } from '@hooks';
import { Loader, Typography } from '@ui';
import { showFormNotification } from '@utils';
import { Form } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import AccountStatus from './components/AccountStatus';
import BillingInfo from './components/BillingInfo';
import Pricing from './components/Pricing';
import TeamAccounts from './components/TeamAccounts';
import { FIELD_NAMES } from './constants';
import { calculateMembersGroups, formatCommissions, formatMembersGroups } from './utils';

const AccountManagement = observer(() => {
    const store = useStore();
    const {
        currentAccount,
        getAccountCommissions,
        handleAccountManagementSave,
        getAccountSettings,
        getTeamMembers,
        resetUIMembers
    } = store.accountsStore;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [formSubmitting, setFormSubmitting] = useState(false);

    const resetForm = () => {
        const {
            currentAccount: { commission },
            teamMembers
        } = store.accountsStore;

        form.setFieldsValue({
            [FIELD_NAMES.REST_OF_SITE_COMMISSION]: commission.restOfSite,
            [FIELD_NAMES.COLD_CUSTOMER_COMMISSION]: commission.coldCustomer,
            [FIELD_NAMES.NEW_CUSTOMER_COMMISSION]: commission.newCustomer,
            [FIELD_NAMES.TEAM_MEMBERS]: teamMembers
        });
    };

    useEffect(() => {
        (async () => {
            await Promise.all([getAccountCommissions(), getAccountSettings(), getTeamMembers()]);
            resetForm();
            setLoading(false);
        })();
    }, []);

    const handleFormSubmit = async (commissions) => {
        setFormSubmitting(true);

        const bdMembers = toJS(store.accountsStore.bdTeamMembers);
        const uiMembers = toJS(store.accountsStore.uiTeamMembers);
        const membersGroups = calculateMembersGroups(bdMembers, uiMembers);
        const formattedMembersGroups = formatMembersGroups(membersGroups);

        const formattedCommissions = formatCommissions(commissions);

        await handleAccountManagementSave({ ...formattedCommissions, ...formattedMembersGroups });

        resetForm();
        setFormSubmitting(false);
        showFormNotification(store.accountsStore.error);
    };

    const onCancel = () => {
        resetForm();
        resetUIMembers();
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Fragment>
            <Typography.Title data-e2e='account-management_page_title' level={2} style={BOTTOM_INDENTS.M}>
                Account Management
            </Typography.Title>
            <Typography.Text
                data-e2e='account-management_page_description'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.M}
            >
                {`Your account summary includes important information about your company's Klickly account, including
            terms, team members, and billing`}
            </Typography.Text>
            <AccountStatus />
            <Pricing
                form={form}
                handleFormSubmit={handleFormSubmit}
                attributionWindow={currentAccount.attributionWindowSettings}
                style={BOTTOM_INDENTS.M}
            />
            <TeamAccounts style={BOTTOM_INDENTS.M} />
            <BillingInfo style={BOTTOM_INDENTS.M} />
            <CancelSave onSave={form.submit} onCancel={onCancel} loading={formSubmitting} />
        </Fragment>
    );
});

export default AccountManagement;
