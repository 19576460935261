import React from 'react';
import { COLORS } from '@constants';
import { Typography } from '@ui';
import { formatString } from '@utils';
import { Flex } from 'antd';
import { TableTitleTooltip } from '../TableTitleTooltip';
import { StyledTitleWrapper } from './styled';

const Title = ({ text, tooltipText }) => {
    return (
        <StyledTitleWrapper>
            <Flex gap={8} id='orders-table-title' align='center'>
                <Typography.Text
                    data-e2e={`orders_${formatString(text)}_column`}
                    color={COLORS.$gray40Black}
                    type='descriptor2'
                >
                    {text}
                </Typography.Text>
                <TableTitleTooltip text={tooltipText} />
            </Flex>
        </StyledTitleWrapper>
    );
};

export default Title;
