import { COLORS } from '@constants';
import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledHeader = styled(Layout.Header)`
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${COLORS.$gray15Black};
    border-bottom: 1px solid ${COLORS.$gray20Black};
    padding: 0 24px;

    .ant-menu {
        background-color: transparent;
        border-bottom: none;
        font-weight: 500;
        line-height: 80px;
        margin: 0 auto;
    }

    .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
        color: ${COLORS.$gray100Black};
    }
    .ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover:not(.ant-menu-item-selected) {
        .ant-menu-title-content {
            color: ${COLORS.$gray100Black};
        }
        &::after {
            border-bottom-color: transparent;
        }
    }
`;

export const StyledInnerWrapper = styled.div`
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
`;

export const StyledContent = styled(Layout.Content)`
    width: 100%;
    max-width: 1328px;
    min-height: calc(100vh - 257px);
    margin: 0 auto;
    padding: 48px 24px 0;
    display: flex;
    flex-direction: column;
`;

export const StyledFooter = styled(Layout.Footer)`
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${COLORS.$gray10Black};
    padding: 0 24px;
`;
