import React, { useEffect } from 'react';
import MainFooter from './components/Footer';
import GetStarted from './components/GetStarted';
import MainHeader from './components/Header';
import HeroSection from './components/HeroSection';
import InfoSection from './components/InfoSection';
import PartnersSection from './components/PartnersSection';
import PerformanceSection from './components/PerformanceSection';
import PoweredBy from './components/PoweredBy';
import TrustedBrands from './components/TrustedBrands';
import VerticalSlider from './components/VerticalSlider';
import { StyledLandingPage } from './styled';

function LandingPage() {
    useEffect(() => {
        // EVENT #1: VIEW CONTENT ­ this event is triggered when people land on our site
        // TODO
        // reactPixel.track(PixelEvents.ViewContent);
    }, []);

    return (
        <StyledLandingPage>
            <MainHeader />
            <HeroSection />
            <PoweredBy />
            <VerticalSlider />
            <InfoSection />
            <TrustedBrands />
            <PerformanceSection />
            <PartnersSection />
            <GetStarted />
            <MainFooter />
        </StyledLandingPage>
    );
}

export default LandingPage;
