import React from 'react';
import { COLORS } from '@constants';
import { useStore } from '@hooks';
import { Button, Typography } from '@ui';
import { Avatar, Dropdown, Flex } from 'antd';
import { observer } from 'mobx-react';
import DropdownMenu from './components/DropdownMenu';
import { useDropDown } from './hooks';
import { StyledText } from './styled';

const UserInfoWithDropDownMenu = observer(() => {
    const store = useStore();
    const { firstName, lastName } = store.authStore;
    const { logoUrl, companyName, companyInitials } = store.accountsStore;

    const { menuRef, menuItems, accounts, isMenuOpen, setIsMenuOpen } = useDropDown();

    const handleMenuButtonClick = () => {
        setIsMenuOpen((prevMenuOpen) => !prevMenuOpen);
    };

    return (
        <Dropdown
            trigger='click'
            dropdownRender={() => <DropdownMenu menuItems={menuItems} accounts={accounts} />}
            placement='bottomRight'
            getPopupContainer={(triggerNode) => triggerNode}
            open={isMenuOpen}
        >
            <div ref={menuRef}>
                <Button
                    data-e2e='header_user-dropdown_btn'
                    type='noStyle'
                    padding='8px 24px'
                    onClick={handleMenuButtonClick}
                >
                    <Flex gap={16} justify='center' align='center'>
                        <Flex vertical>
                            <StyledText data-e2e='header_company-name_text' type='badgeMedium'>
                                {companyName}
                            </StyledText>
                            <Typography.Text
                                data-e2e='header_username_text'
                                style={{ textAlign: 'end' }}
                                type='body2'
                                color={COLORS.$gray60Black}
                            >
                                {firstName} {lastName}
                            </Typography.Text>
                        </Flex>
                        <Flex justify='center' align='center'>
                            {logoUrl ? (
                                <Avatar src={<img src={logoUrl} alt='avatar' />} size={40} />
                            ) : (
                                <Avatar data-e2e='header_company_logo' size={40}>
                                    {companyInitials}
                                </Avatar>
                            )}
                        </Flex>
                    </Flex>
                </Button>
            </div>
        </Dropdown>
    );
});

export default UserInfoWithDropDownMenu;
