import React from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS, ROUTE_PATHS } from '@constants';
import { Button, Icon, Typography } from '@ui';
import { Col, Row } from 'antd';

const CreateCampaign = () => {
    const navigate = useNavigate();
    return (
        <Col data-e2e='orders_no-orders-state_content'>
            <Typography.Title
                data-e2e='orders_no-orders_text'
                align='center'
                color={COLORS.$gray60Black}
                marginBottom='24px'
                level={2}
            >
                You have no orders yet
            </Typography.Title>
            <Typography.Text
                data-e2e='orders_launch-campaign_text'
                align='center'
                color={COLORS.$gray60Black}
                marginBottom='24px'
                type='body2'
            >
                Launch a campaign to start earning with Klickly
            </Typography.Text>
            <Row justify='center'>
                <Col>
                    <Button
                        data-e2e='orders_add-campaign_btn'
                        onClick={() => navigate(ROUTE_PATHS.CAMPAIGNS_CREATE_STEP_1)}
                        iconRight={<Icon size='24px' color={COLORS.$white} component={() => <Icon.PlusIcon />} />}
                        type='primary'
                    >
                        Add new campaign
                    </Button>
                </Col>
            </Row>
        </Col>
    );
};

export default CreateCampaign;
