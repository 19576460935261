import React, { Fragment } from 'react';
import { VALIDATION_RULES } from '@constants';
import { Divider, Input, Typography } from '@ui';
import { Col, Form, Row } from 'antd';
import { FIELD_NAMES } from '../../../constants';

const Settings = () => {
    return (
        <Fragment>
            <Row justify='space-between' align='middle'>
                <Col>
                    <Typography.Text data-e2e='tracking_source_block' type='badgeSmall'>
                        Source
                    </Typography.Text>
                </Col>
                <Col>
                    <Form.Item name={FIELD_NAMES.CUSTOMIZE_UTM_SOURCE} rules={[...VALIDATION_RULES.REQUIRED]}>
                        <Input data-e2e='tracking_source_input' style={{ width: 224 }} value='klickly' />
                    </Form.Item>
                </Col>
            </Row>
            <Divider margin='24px 0' />
            <Row justify='space-between' align='middle'>
                <Col>
                    <Typography.Text data-e2e='tracking_medium_block' type='badgeSmall'>
                        Medium
                    </Typography.Text>
                </Col>
                <Col>
                    <Form.Item name={FIELD_NAMES.CUSTOMIZE_UTM_MEDIUM} rules={[...VALIDATION_RULES.REQUIRED]}>
                        <Input data-e2e='tracking_medium_input' style={{ width: 224 }} value='marketing' />
                    </Form.Item>
                </Col>
            </Row>
            <Divider margin='24px 0' />
            <Row justify='space-between' align='middle' style={{ paddingBlock: 16 }}>
                <Col>
                    <Typography.Text data-e2e='tracking_campaign_block' type='badgeSmall'>
                        Campaign
                    </Typography.Text>
                </Col>
                <Col>
                    <Typography.Title data-e2e='tracking_campaign_value' level={3}>
                        Campaign name
                    </Typography.Title>
                </Col>
            </Row>
            <Divider margin='24px 0' />
            <Row justify='space-between' align='middle' style={{ paddingBlock: 16 }}>
                <Col>
                    <Typography.Text data-e2e='tracking_term_block' type='badgeSmall'>
                        Term
                    </Typography.Text>
                </Col>
                <Col>
                    <Typography.Title data-e2e='tracking_term_value' level={3}>
                        Product name
                    </Typography.Title>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Settings;
