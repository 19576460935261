import React from 'react';
import { useStore } from '@hooks';
import { formatString } from '@utils';
import { Checkbox } from 'antd';
import { observer } from 'mobx-react';
import { StyledTypography, StyledWrapper } from './styled';

const ListItem = observer(({ item, ...props }) => {
    const store = useStore();
    const { selectedReportMetrics, setBufferReportMetrics } = store.performanceStore;
    const column = selectedReportMetrics.find((value) => value.key === item.key);
    const isActive = column?.buffer ?? column?.active;

    return (
        <StyledWrapper>
            <Checkbox
                data-e2e={`report_${formatString(item.title)}_checkbox`}
                checked={isActive}
                onChange={({ target: { checked } }) => {
                    setBufferReportMetrics(checked, item);
                }}
                {...props}
            />
            <StyledTypography
                data-e2e={`report_${formatString(item.title)}-checkbox_description`}
                type='descriptor1'
                title={item.title}
            >
                {item.title}
            </StyledTypography>
        </StyledWrapper>
    );
});

export default ListItem;
