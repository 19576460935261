import React from 'react';
import { notification } from 'antd';

export const NotificationContext = React.createContext();

export const NotificationProvider = ({ children }) => {
    const [api, contextHolder] = notification.useNotification({ placement: 'topRight' });

    return (
        <NotificationContext.Provider value={api}>
            {contextHolder}
            {children}
        </NotificationContext.Provider>
    );
};
