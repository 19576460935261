import React, { useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

export const ScrollTopWrapper = observer(() => {
    const location = useLocation();

    useLayoutEffect(() => {
        document.querySelector('#root').scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <React.Fragment>
            <Outlet />
        </React.Fragment>
    );
});
