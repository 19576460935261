import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTE_PATHS } from '@constants';
import { ACCOUNT_USER_ROLE } from '@enums';
import { useStore } from '@hooks';
import { Divider, Typography } from '@ui';
import { Col, Layout, Row } from 'antd';
import { observer } from 'mobx-react';
import SideBar from './components/SideBar';
import { StyledLayout } from './styled';

const AccountSettingsLayout = observer(() => {
    const store = useStore();
    const { me } = store.authStore;

    if (me?.permission?.role === ACCOUNT_USER_ROLE.MEMBER) {
        return <Navigate to={ROUTE_PATHS.HOME} />;
    }

    return (
        <StyledLayout>
            <Layout.Header>
                <Typography.Text data-e2e='account-settings_page_title' type='em0'>
                    Account Settings
                </Typography.Text>
                <Divider margin='48px 0' />
            </Layout.Header>
            <Layout hasSider>
                <Layout.Sider width={305} style={{ backgroundColor: 'inherit' }}>
                    <SideBar />
                </Layout.Sider>
                <Layout.Content>
                    <Row justify='end'>
                        <Col flex='867px' style={{ minHeight: 500 }}>
                            <Outlet />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        </StyledLayout>
    );
});

export default AccountSettingsLayout;
