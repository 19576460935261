import React from 'react';
import { formatPrice } from '@utils';

export const columnsInner = [
    {
        title: <span data-e2e='product_variant_column'>Variant</span>,
        dataIndex: 'name',
        key: 'name',
        width: '33%',
        render: (name, record) => <span data-e2e={`product_${record.id}-variant_value`}>{name}</span>
    },
    {
        title: <span data-e2e='product_sku-id_column'>SKU ID</span>,
        dataIndex: 'sku',
        key: 'sku',
        render: (sku, record) => <span data-e2e={`product_${record.id}-sku_value`}>{sku || '-'}</span>
    },
    {
        title: <span data-e2e='product_inventory_column'>Inventory</span>,
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'right',
        render: (quantity, record) => <span data-e2e={`product_${record.id}-inventory_value`}>{quantity || '-'}</span>
    },
    {
        title: <span data-e2e='product_original-price_column'>Original Price</span>,
        dataIndex: 'originalPrice',
        key: 'originalPrice',
        align: 'right',
        render: (originalPrice, record) => (
            <span data-e2e={`product_${record.id}-original-price_value`}>
                {originalPrice ? formatPrice(originalPrice) : '-'}
            </span>
        )
    },
    {
        title: <span data-e2e='product_sale-price_column'>Sale Price</span>,
        dataIndex: 'price',
        key: 'price',
        align: 'right',
        render: (price, record) => (
            <span data-e2e={`product_${record.id}-sale-price_value`}>{price ? formatPrice(price) : '-'}</span>
        )
    }
];
