import React, { Fragment, useEffect, useState } from 'react';
import { CancelSave } from '@components';
import { BOTTOM_INDENTS, COLORS, CUSTOMER_INFO_FIELD_NAMES } from '@constants';
import { useStore } from '@hooks';
import { Loader, Typography } from '@ui';
import { showFormNotification } from '@utils/formNotification';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import AcceptPayments from './components/AcceptPayments';
import CustomerServiceInfo from './components/CustomerServiceInfo';
import SalesTaxes from './components/SalesTaxes';
import Shipping from './components/Shipping';

const CustomerManagement = observer(() => {
    const store = useStore();
    const { isShowAcceptPayments } = store.profileStore;
    const {
        getContactsForCustomers,
        getAddressForCustomers,
        getAccountSettings,
        customerManagementUpdate,
        states,
        getStates
    } = store.accountsStore;

    const [loading, setLoading] = useState(true);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [form] = Form.useForm();

    const resetForm = () => {
        const { currentAccount, customersEmail, customersPhone, addressForCustomers } = store.accountsStore;

        form.setFieldsValue({
            [CUSTOMER_INFO_FIELD_NAMES.PHONE_NUMBER]: customersPhone.value?.replace('+', ''),
            [CUSTOMER_INFO_FIELD_NAMES.EMAIL]: customersEmail.value,
            [CUSTOMER_INFO_FIELD_NAMES.STATE]: addressForCustomers.province,
            [CUSTOMER_INFO_FIELD_NAMES.CITY]: addressForCustomers.city,
            [CUSTOMER_INFO_FIELD_NAMES.ADDRESS]: addressForCustomers.address1,
            [CUSTOMER_INFO_FIELD_NAMES.APT_NUMBER]: addressForCustomers.address2,
            [CUSTOMER_INFO_FIELD_NAMES.ZIP_CODE]: addressForCustomers.zipCode,
            [CUSTOMER_INFO_FIELD_NAMES.LENGTH]: currentAccount.settings.packageSize?.length,
            [CUSTOMER_INFO_FIELD_NAMES.WIDTH]: currentAccount.settings.packageSize?.width,
            [CUSTOMER_INFO_FIELD_NAMES.HEIGHT]: currentAccount.settings.packageSize?.height,
            [CUSTOMER_INFO_FIELD_NAMES.WEIGHT]: currentAccount.settings.packageSize?.weight,
            [CUSTOMER_INFO_FIELD_NAMES.STATES_LIST]: states.filter((state) => state?.selected).map((state) => state.id)
        });
    };

    useEffect(() => {
        (async () => {
            await Promise.all([getContactsForCustomers(), getAddressForCustomers(), getAccountSettings(), getStates()]);
            resetForm();
            setLoading(false);
        })();
    }, []);

    const onFinish = async (values) => {
        setFormSubmitting(true);

        const statesList = store.accountsStore.states.map((state) => ({
            id: state.id,
            name: state.name,
            selected: values[CUSTOMER_INFO_FIELD_NAMES.STATES_LIST].includes(state.id)
        }));

        await customerManagementUpdate({ ...values, statesList });

        resetForm();
        setFormSubmitting(false);
        showFormNotification(store.accountsStore.error);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Fragment>
            <Typography.Title data-e2e='customer-management_page_title' level={2} style={BOTTOM_INDENTS.M}>
                Customer Management
            </Typography.Title>
            <Typography.Text
                data-e2e='customer-management_page_description'
                type='body2'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.M}
            >
                Your Customer Management settings include information that customers might need to know in order to
                engage with you and shop from your store.
            </Typography.Text>
            {isShowAcceptPayments && <AcceptPayments />}
            <Form form={form} onFinish={onFinish} scrollToFirstError={{ behavior: 'smooth', block: 'center' }}>
                <CustomerServiceInfo style={BOTTOM_INDENTS.M} />
                <Shipping style={BOTTOM_INDENTS.M} />
                <SalesTaxes style={BOTTOM_INDENTS.M} />
                <CancelSave onCancel={resetForm} loading={formSubmitting} />
            </Form>
        </Fragment>
    );
});

export default CustomerManagement;
