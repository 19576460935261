export const MENU_ITEM_IDS = {
    OVERVIEW: 'overview',
    BREAKDOWNS: 'breakdowns',
    REPORTS: 'reports'
};

export const MENU_ITEM_TITLES = {
    OVERVIEW: 'Overview',
    BREAKDOWNS: 'Breakdowns',
    REPORTS: 'Reports'
};

export const MENU_ITEMS = [
    {
        id: MENU_ITEM_IDS.OVERVIEW,
        title: MENU_ITEM_TITLES.OVERVIEW
    },
    {
        id: MENU_ITEM_IDS.BREAKDOWNS,
        title: MENU_ITEM_TITLES.BREAKDOWNS
    },
    {
        id: MENU_ITEM_IDS.REPORTS,
        title: MENU_ITEM_TITLES.REPORTS
    }
];
