import React from 'react';
import { COLORS, LEFT_INDENTS } from '@constants';
import { Typography } from '@ui';
import { formatString } from '@utils';
import leftBorder from './images/metric_box_left_border.svg';
import leftBorderWhite from './images/metric_box_left_border_white.svg';
import rightBorder from './images/metric_box_right_border.svg';
import rightBorderWhite from './images/metric_box_right_border_white.svg';
import { StyledBody, StyledContent, StyledWrap } from './styled';

const HorizontalCard = ({ data = {}, isFirstElem, isLastElem, isHomePage = false }) => {
    return (
        <StyledWrap data-e2e={`breakdowns_${formatString(data.label)}_card`}>
            {!isFirstElem ? <img src={isHomePage ? leftBorderWhite : leftBorder} alt='left-border' /> : null}
            <StyledContent $isFirstElem={isFirstElem} $isLastElem={isLastElem} $isHomePage={isHomePage}>
                <Typography.Text type='badgeMedium' color={COLORS.$gray40Black}>
                    {data.label}
                </Typography.Text>
                <StyledBody>
                    <Typography.Title data-e2e={`breakdowns_${formatString(data.label)}_value`}>
                        {data.value}
                    </Typography.Title>
                    {data.postfix ? (
                        <Typography.Title style={LEFT_INDENTS.XXXS}>{data.postfix}</Typography.Title>
                    ) : null}
                </StyledBody>
            </StyledContent>
            {!isLastElem ? <img src={isHomePage ? rightBorderWhite : rightBorder} alt='right-border' /> : null}
        </StyledWrap>
    );
};

export default HorizontalCard;
