import { useEffect, useRef } from 'react';

export const useDidMountEffect = (effectFn, dependencyList) => {
    const didMountFlag = useRef(false);

    useEffect(() => {
        if (didMountFlag.current) {
            effectFn();
        } else {
            didMountFlag.current = true;
        }
    }, dependencyList);
};
