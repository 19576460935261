import React from 'react';
import { COLORS } from '@constants';
import { Button } from '@ui';
import { Flex } from 'antd';

export const CancelSave = ({
    style,
    onSave,
    onCancel,
    cancelText = 'Cancel',
    saveText = 'Save',
    disabled,
    loading
}) => {
    return (
        <Flex style={style} justify='end' gap={16}>
            <Button
                data-e2e='common_cancel_btn'
                style={{ width: 160 }}
                backgroundColor={COLORS.$white}
                block
                onClick={onCancel}
            >
                {cancelText}
            </Button>
            <Button
                data-e2e='common_submit_btn'
                htmlType='submit'
                type='primary'
                style={{ width: 160 }}
                onClick={onSave}
                loading={loading}
                disabled={disabled}
            >
                {saveText}
            </Button>
        </Flex>
    );
};
