import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Icon, Typography } from '@ui';
import { Card, Flex } from 'antd';

const RequestSentMessage = ({ style }) => {
    return (
        <Card color={COLORS.$robinBlue} style={{ backgroundColor: COLORS.$robinBlue, ...BOTTOM_INDENTS.M, ...style }}>
            <Flex data-e2e='creative-settings_status_block' justify='space-between' align='center'>
                <Typography.Text type='headline2' color={COLORS.$white}>
                    Thank you! Your request has been submitted.
                </Typography.Text>
                <div style={{ padding: '4px 12px' }}>
                    <Flex align='center' gap={6}>
                        <Flex
                            justify='center'
                            align='center'
                            style={{
                                borderRadius: '1000px',
                                backgroundColor: COLORS.$white,
                                width: 24,
                                height: 24
                            }}
                        >
                            <Icon.CheckIcon width={16} height={16} color={COLORS.$robinBlue} />
                        </Flex>
                        <Typography.Text
                            data-e2e='creative-settings_approved-status_title'
                            type='badgeLarge'
                            color={COLORS.$white}
                        >
                            Sent
                        </Typography.Text>
                    </Flex>
                </div>
            </Flex>
        </Card>
    );
};

export default RequestSentMessage;
