import { Button } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        background-color: ${(props) => props.backgroundcolor};
        position: relative;
        overflow: hidden;
    }

    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${(props) => (props.$isShutDown ? 'rgba(0, 0, 0, 0.0)' : 'rgba(0, 0, 0, 0.2)')};
        pointer-events: none; /* Ensure the pseudo-element doesn't interfere with button interactions */
        z-index: 1;
    }

    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover span {
        position: relative;
        z-index: 2;
    }

    svg {
        color: rgba(0, 0, 0, 0.4);
    }

    display: flex;
    align-items: center;
    padding: 4px 12px;
    cursor: pointer;
    height: 32px;
    font-size: 14px;
    border-radius: 16px;
    border: none;
    font-family: IBM Plex Sans;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: white;
    background-color: ${(props) => props.backgroundcolor};
    justify-content: center;
`;
