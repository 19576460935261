import React from 'react';
import { COLORS } from '@constants';
import { useStore } from '@hooks';
import { Button, Icon, Typography } from '@ui';
import { formatString } from '@utils';
import { Flex } from 'antd';
import { observer } from 'mobx-react';
import { TOTAL_STEPS } from '../../constants';
import { useFormContext } from '../../form';
import { useCampaignWizard } from '../../hooks/useCampaignWizard';
import { useOnLaunch } from '../../pages/LaunchCheck/hooks/useOnLaunch';

const NextButton = observer(() => {
    const store = useStore();
    const { isNextDisabled, isNextLoading } = store.campaignWizardStore;

    const { isCreate, isEdit, isLaunchCheck, step } = useCampaignWizard();

    const onLaunch = useOnLaunch();
    const formRef = useFormContext();

    let onNextClick;

    if (isCreate || isEdit) {
        onNextClick = formRef.current?.submit;
    }

    if (isLaunchCheck) {
        onNextClick = onLaunch;
    }

    const buttonLabel = isEdit
        ? 'Save'
        : step === 1
        ? 'Create'
        : step === TOTAL_STEPS || isLaunchCheck
        ? 'Launch'
        : 'Next Step';

    return (
        <Button
            style={{ width: 200 }}
            type='primary'
            disabled={isNextDisabled}
            loading={isNextLoading}
            onClick={onNextClick}
            data-e2e={`campaignwizard_${formatString(buttonLabel)}_btn`}
        >
            <React.Fragment>
                <Flex justify='center' align='center' gap={8}>
                    <Typography.Text type='badgeMedium' color={COLORS.$white}>
                        {buttonLabel}
                    </Typography.Text>
                    <Icon size='24px' component={() => (isEdit ? <Icon.CheckIcon /> : <Icon.ArrowForwardIcon />)} />
                </Flex>
            </React.Fragment>
        </Button>
    );
});

export default NextButton;
