import React, { useState } from 'react';
import { PaymentMethods } from '@components';
import { useStore } from '@hooks';
import { Button, WhiteCover } from '@ui';
import { observer } from 'mobx-react';
import AddPaymentMethodContent from './components/AddPaymentMethodContent';

const BillingInfo = observer(({ style = {} }) => {
    const store = useStore();
    const { paymentIntent } = store.accountsStore;

    const [loading, setLoading] = useState();
    const [clientSecret, setClientSecret] = useState();
    const [showAddPaymentForm, setShowAddPaymentForm] = useState(false);

    const onAddPaymentMethod = async () => {
        setLoading(true);
        const response = await paymentIntent();
        setClientSecret(response.clientSecret);
        setLoading(false);
        setShowAddPaymentForm(true);
    };

    const onCancel = () => {
        setShowAddPaymentForm(false);
        setClientSecret(null);
    };

    return (
        <WhiteCover
            data-e2e='account-management_billing-info_section'
            title='Billing Information'
            rightContent={
                !showAddPaymentForm && (
                    <Button
                        data-e2e='account-management_add-payment-method_btn'
                        type='link'
                        loading={loading}
                        onClick={onAddPaymentMethod}
                    >
                        Add new payment method
                    </Button>
                )
            }
            style={{ ...style }}
        >
            {showAddPaymentForm && <AddPaymentMethodContent clientSecret={clientSecret} onCancel={onCancel} />}
            <PaymentMethods />
        </WhiteCover>
    );
});

export default BillingInfo;
