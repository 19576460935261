import React from 'react';

export const getExpandable = (expandable) =>
    expandable
        ? {
              ...expandable,
              expandedRowRender: (record, i) => {
                  return <div className='custom-expandable-content'>{expandable.expandedRowRender(record, i)}</div>;
              }
          }
        : null;
