import React, { useState } from 'react';
import { TOP_INDENTS } from '@constants';
import { Col, Row } from 'antd';
import { Button } from '../../../Button';
import { Icon } from '../../../Icon';
import { Input } from '../../../Input';
import PaginationNext from './components/PaginationNext';
import PaginationPrev from './components/PaginationPrev';

const Pagination = ({
    position = 'end',
    showPageSelector = true,
    currentPage = 1,
    totalPages = 1,
    onGoToPage = () => null
}) => {
    const [pageToGo, setPageToGo] = useState(null);
    return (
        <Row justify={position} align='middle' style={TOP_INDENTS.XL_PLUS}>
            <Col>
                <Row align='middle' gutter={32}>
                    <Col>
                        <Row align='middle' gutter={16}>
                            <Col>
                                <Button
                                    disabled={currentPage === 1}
                                    icon={<Icon size='24px' component={() => <Icon.ArrowBackIcon />} />}
                                    onClick={() => {
                                        onGoToPage(currentPage - 1 || 0);
                                        setPageToGo(null);
                                    }}
                                />
                            </Col>
                            <Col>
                                Page {currentPage} of {totalPages}
                            </Col>
                            <Col>
                                <Button
                                    disabled={currentPage === totalPages}
                                    icon={<Icon size='24px' component={() => <Icon.ArrowForwardIcon />} />}
                                    onClick={() => {
                                        onGoToPage(currentPage + 1);
                                        setPageToGo(null);
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    {showPageSelector && (
                        <Col>
                            <Row align='middle' gutter={8} wrap={false}>
                                <Col>
                                    <Input.Number
                                        width='85px'
                                        value={pageToGo}
                                        onChange={setPageToGo}
                                        placeholder='Page #'
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        disabled={
                                            pageToGo === currentPage ||
                                            pageToGo < 1 ||
                                            pageToGo > totalPages ||
                                            !pageToGo
                                        }
                                        onClick={() => onGoToPage(pageToGo)}
                                    >
                                        Go
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    );
};

Pagination.Prev = PaginationPrev;
Pagination.Next = PaginationNext;

export default Pagination;
