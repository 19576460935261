import { COLORS } from '@constants';
import { ACCOUNT_USER_ROLE, ACCOUNT_USER_STATUS } from '@enums';

export const ACCOUNT_USER_STATUS_LABELS = {
    [ACCOUNT_USER_STATUS.BLOCKED]: { title: 'Blocked', color: COLORS.$gray40Black },
    [ACCOUNT_USER_STATUS.ACTIVE]: { title: 'Active', color: COLORS.$robinBlue },
    [ACCOUNT_USER_STATUS.INVITED]: { title: 'Invited', color: COLORS.$cornflowerBlue },
    [ACCOUNT_USER_STATUS.PENDING]: { title: 'Draft', color: COLORS.$gray40Black },
    [ACCOUNT_USER_STATUS.DECLINED]: { title: 'Declined', color: COLORS.$tartRed }
};

export const ACCOUNT_USER_ROLE_LABELS = {
    [ACCOUNT_USER_ROLE.OWNER]: { title: 'Owner' },
    [ACCOUNT_USER_ROLE.ADMIN]: { title: 'Admin' },
    [ACCOUNT_USER_ROLE.MEMBER]: { title: 'Member' }
};
