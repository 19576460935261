import React from 'react';
import { useStore } from '@hooks';
import { observer } from 'mobx-react';
import { StyledImagePreview } from './styled';

const CustomCreativesUnit = observer(() => {
    const store = useStore();
    const { customCreativePreviewSrc } = store.customCreativesStore;

    return customCreativePreviewSrc ? (
        <StyledImagePreview $width={600} $height={300}>
            <img src={customCreativePreviewSrc} alt='' />
        </StyledImagePreview>
    ) : null;
});

export default CustomCreativesUnit;
