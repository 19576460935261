import React from 'react';
import { COLORS, RIGHT_INDENTS } from '@constants';
import { Icon, Tag, Typography } from '@ui';
import { StyledLeft, StyledWrapper } from './styled';

const Header = ({ itemKey, title, status }) => {
    const iconsMap = {
        'customer-service': <Icon.MobilePhoneIcon />,
        'payment-info': <Icon.CreditCardIcon />,
        'contact-info': <Icon.ContactCenterIcon />,
        'script-tag': <Icon.SettingsIcon />
    };

    const tagColorMap = {
        Incomplete: COLORS.$tartRed,
        Finished: COLORS.$robinBlue
    };

    return (
        <StyledWrapper>
            <StyledLeft>
                <Icon
                    size='56px'
                    color={COLORS.$gray60Black}
                    component={() => iconsMap[itemKey]}
                    style={RIGHT_INDENTS.M}
                />
                <Typography.Text data-e2e={`launchcampaign-${itemKey}_title`} type='badgeMedium'>
                    {title}
                </Typography.Text>
            </StyledLeft>
            {typeof status === 'object' ? (
                status
            ) : (
                <Tag data-e2e={`launchcampaign-${itemKey}_status`} color={tagColorMap[status] || COLORS.$white}>
                    {status}
                </Tag>
            )}
        </StyledWrapper>
    );
};

export default Header;
