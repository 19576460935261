import React from 'react';
import { CUSTOMER_INFO_FIELD_NAMES, validateDebounce, VALIDATION_RULES } from '@constants';
import { Input } from '@ui';
import { Col, Form, Row } from 'antd';

export const CustomerInfoFields = () => {
    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form.Item
                    name={CUSTOMER_INFO_FIELD_NAMES.EMAIL}
                    rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.EMAIL]}
                    validateDebounce={validateDebounce}
                    style={{ marginBottom: 0 }}
                >
                    <Input data-e2e='customer-service_email_input' label='Email Address' />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    name={CUSTOMER_INFO_FIELD_NAMES.PHONE_NUMBER}
                    rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.PHONE]}
                    validateDebounce={validateDebounce}
                >
                    <Input.Phone data-e2e='customer-service_phone_input' label='Phone Number' />
                </Form.Item>
            </Col>
            <Col span={18}>
                <Form.Item name={CUSTOMER_INFO_FIELD_NAMES.ADDRESS}>
                    <Input data-e2e='customer-service_address_input' label='Address (optional)' />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name={CUSTOMER_INFO_FIELD_NAMES.APT_NUMBER}>
                    <Input data-e2e='customer-service_apt-number_input' label='Apt. number (optional)' />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={CUSTOMER_INFO_FIELD_NAMES.CITY}>
                    <Input data-e2e='customer-service_city_input' label='City (optional)' />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name={CUSTOMER_INFO_FIELD_NAMES.STATE}>
                    <Input data-e2e='customer-service_state_input' label='State (optional)' />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    name={CUSTOMER_INFO_FIELD_NAMES.ZIP_CODE}
                    rules={[...VALIDATION_RULES.ZIP_CODE]}
                    validateDebounce={validateDebounce}
                >
                    <Input data-e2e='customer-service_zip_input' label='ZIP code (optional)' />
                </Form.Item>
            </Col>
        </Row>
    );
};
