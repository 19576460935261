import React from 'react';
import { COLORS, RIGHT_INDENTS } from '@constants';
import { Typography } from '@ui';
import { StyledRow, StyledWrapper } from '../styled';

export const getBarTooltipBody = (label, value, diff, baselineValue, records, chartValuePrefix, chartValueSuffix) => (
    <StyledWrapper>
        <StyledRow>
            <Typography.Text type='body2' as='span' style={RIGHT_INDENTS.XXS}>
                {label}:
            </Typography.Text>
            <Typography.Text type='badgeSmall' as='span' style={RIGHT_INDENTS.XXS}>
                {chartValuePrefix}
                {value}
                {chartValueSuffix}
            </Typography.Text>
            <Typography.Text type='badgeSmall' as='span' color={Number(diff) > 0 ? COLORS.$success : COLORS.$error}>
                ({diff}%)
            </Typography.Text>
        </StyledRow>
        <StyledRow>
            <Typography.Text type='body2' as='span' style={RIGHT_INDENTS.XXS}>
                Baseline:
            </Typography.Text>
            <Typography.Text type='badgeSmall' as='span'>
                {chartValuePrefix}
                {baselineValue}
                {chartValueSuffix}
            </Typography.Text>
        </StyledRow>
        <StyledRow class='insights-chart-tooltip__row'>
            <Typography.Text type='body2' as='span' style={RIGHT_INDENTS.XXS}>
                Records:
            </Typography.Text>
            <Typography.Text type='badgeSmall' as='span'>
                {records}
            </Typography.Text>
        </StyledRow>
    </StyledWrapper>
);

export const getDonutTooltipBody = (label, value, records) => (
    <StyledWrapper>
        <StyledRow>
            <Typography.Text type='body2' as='span' style={RIGHT_INDENTS.XXS}>
                {label}:
            </Typography.Text>
            <Typography.Text type='badgeSmall' as='span' style={RIGHT_INDENTS.XXS}>
                {value}%
            </Typography.Text>
        </StyledRow>
        <StyledRow class='insights-chart-tooltip__row'>
            <Typography.Text type='body2' as='span' style={RIGHT_INDENTS.XXS}>
                Records:
            </Typography.Text>
            <Typography.Text type='badgeSmall' as='span'>
                {records}
            </Typography.Text>
        </StyledRow>
    </StyledWrapper>
);

export const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'transparent';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transition = 'all .1s ease';

        const content = document.createElement('div');
        content.className = 'insights-tooltip';

        tooltipEl.appendChild(content);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};
