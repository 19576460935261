import React from 'react';
import { BOTTOM_INDENTS, FIELD_NAME } from '@constants';
import { Input, Loader, WhiteCover } from '@ui';
import { Form } from 'antd';

const CampaignName = ({ pending }) => {
    return (
        <WhiteCover
            data-e2e='campaignwizard_campaign-name_block'
            title='Campaign name'
            style={BOTTOM_INDENTS.M}
            hasDivider={false}
        >
            {pending && <Loader />}
            <Form.Item
                initialValue=' '
                name={FIELD_NAME.CAMPAIGN.NAME}
                rules={[{ required: true, message: 'Campaign name is required' }]}
            >
                <Input data-e2e='campaignwizard_campaign-name_input' label='Enter Campaign Name' />
            </Form.Item>
        </WhiteCover>
    );
};

export default CampaignName;
