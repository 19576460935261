import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledButtonPreview = styled.div`
    font-family: ${({ $font }) => ($font?.toLowerCase() === 'arial' ? 'Arial' : 'Times New Roman')};
    display: flex;
    width: 204px;
    height: 56px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    color: ${({ $color }) => $color || COLORS.$white};
    border-radius: 2px;
    background: ${({ $background }) => $background || COLORS.$pink};
    cursor: default;
`;
