import React from 'react';
import { Button } from '../../Button';
import { getPaginationItemRender } from './getPaginationItemRender';

export const getDefaultPaginationProps = (currentPage, totalPages, pageToGo, setPageToGo) => ({
    locale: { items_per_page: '', jump_to: '', jump_to_confirm: '', page: '' },
    itemRender: getPaginationItemRender(currentPage, totalPages),
    showQuickJumper: {
        goButton: <Button data-e2e='pagination_go_btn'>Go</Button>
    },
    pageSize: 10
});
