import React from 'react';
import { COLORS } from '@constants';
import { StyledFlex, StyledLink } from './styled';

const Title = ({ text, onClick }) => {
    return (
        <StyledFlex align='center' justify='space-between'>
            {text}
            <StyledLink color={COLORS.$info} onClick={onClick}>
                API integration guide
            </StyledLink>
        </StyledFlex>
    );
};

export default Title;
