import React from 'react';
import { COLORS } from '@constants';
import { Icon } from '@ui';
import { Col, Row } from 'antd';

const FooterSocialLinks = () => {
    return (
        <Row gutter={24} align='middle' justify='center'>
            <Col data-e2e='footer_facebook_link'>
                <a href='https://www.facebook.com/klickly' target='_blank' rel='noreferrer'>
                    <Icon color={COLORS.$gray60Black} component={() => <Icon.FacebookIcon />} />
                </a>
            </Col>
            <Col data-e2e='footer_twitter_link'>
                <a href='https://x.com/KlicklyTeam' target='_blank' rel='noreferrer'>
                    <Icon color={COLORS.$gray60Black} component={() => <Icon.TwitterIcon />} />
                </a>
            </Col>
            <Col data-e2e='footer_linkedin_link'>
                <a href='https://www.linkedin.com/company/klickly' target='_blank' rel='noreferrer'>
                    <Icon color={COLORS.$gray60Black} component={() => <Icon.LinkedInIcon />} />
                </a>
            </Col>
        </Row>
    );
};

export default FooterSocialLinks;
