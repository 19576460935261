import React from 'react';
import { BOTTOM_INDENTS, FIELD_NAME } from '@constants';
import { Flex, Form } from 'antd';
import { ColorPicker } from '../../ui/ColorPicker';
import { WhiteCover } from '../../ui/WhiteCover';
import { StyledButtonPreview } from './styled';

export const BrandColors = () => {
    const form = Form.useFormInstance();
    const font = Form.useWatch(FIELD_NAME.CREATIVE.FONT, form);
    const primaryColor = Form.useWatch(FIELD_NAME.CREATIVE.PRIMARY_COLOR, form);
    return (
        <WhiteCover data-e2e='creative-settings_brand-colors_block' style={BOTTOM_INDENTS.M} title='Brand Colors'>
            <Flex gap={24} align='center'>
                <Flex vertical gap={16} style={{ flexBasis: '50%' }}>
                    <Form.Item name={FIELD_NAME.CREATIVE.PRIMARY_COLOR} noStyle>
                        <ColorPicker format='hex' label='Primary Color' />
                    </Form.Item>
                    <Form.Item format='hex' name={FIELD_NAME.CREATIVE.SECONDARY_COLOR} noStyle>
                        <ColorPicker label='Secondary Color' />
                    </Form.Item>
                </Flex>

                <Flex justify='center' align='center' style={{ flexBasis: '50%' }}>
                    <StyledButtonPreview
                        data-e2e='creative-settings_brand-colors-btn_preview'
                        $font={font}
                        $background={primaryColor}
                    >
                        Buy Now
                    </StyledButtonPreview>
                </Flex>
            </Flex>
        </WhiteCover>
    );
};
