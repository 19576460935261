import React from 'react';
import { COLORS } from '@constants';
import { useStore } from '@hooks';
import { Icon, Typography } from '@ui';
import { formatString } from '@utils';
import { observer } from 'mobx-react';
import { StyledMenuItem } from './styled';

const MenuAccountItem = observer(({ item }) => {
    const store = useStore();
    const currentAccountItem = store.accountsStore?.currentAccount?.id === item.id;

    return (
        <StyledMenuItem
            data-e2e={`header_user-dropdown-menu_${formatString(item.companyName)}_account`}
            gap={16}
            style={{ paddingBlock: 12 }}
            onClick={item.onClick}
            align='center'
            $currentAccountItem={currentAccountItem}
        >
            {item.logoUrl ? (
                <img
                    data-e2e={`header_user-dropdown-menu_${formatString(item.companyName)}_account-logo`}
                    style={{ borderRadius: '100px' }}
                    src={item.logoUrl}
                    width='16px'
                    height='16px'
                    alt=''
                />
            ) : (
                <Icon color={COLORS.$gray60Black} size='16px' component={() => <Icon.PersonIcon />} />
            )}

            <Typography.Text
                data-e2e={`header_user-dropdown-menu_${formatString(item.companyName)}_account-name`}
                color={COLORS.$gray60Black}
                className='asd'
                type='descriptor1'
            >
                {item.companyName}
            </Typography.Text>
        </StyledMenuItem>
    );
});

export default MenuAccountItem;
