import styled from 'styled-components';

export const StyledTrustedSection = styled.div`
    position: relative;
    margin-bottom: 64px;
    overflow: hidden;

    @media (max-width: 900px) {
        margin-bottom: 96px;
    }

    .trusted__wrapper {
        margin-top: 128px;

        @media (max-width: 900px) {
            margin-top: 0;
        }

        &__brands {
            margin-top: 48px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 48px;
            grid-row-gap: 48px;

            @media (max-width: 1200px) {
                display: flex;
                flex-wrap: wrap;
                gap: 48px;
                justify-content: center;
            }

            &__single {
                display: flex;
                flex-direction: column;
                gap: 24px;
                max-width: 322px;
            }

            p {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                color: #04080f;
                font-family: 'IBM Plex Sans', Helvetica, sans-serif;
            }
        }

        h2 {
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 64px;
            margin-bottom: 16px;
            text-align: center;
            color: #04080f;
            font-family: 'IBM Plex Sans', Helvetica, sans-serif;

            @media (max-width: 900px) {
                font-size: 48px;
                line-height: 56px;
                text-align: left;
            }
        }

        p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: #04080f;
            font-family: 'IBM Plex Sans', Helvetica, sans-serif;

            @media (max-width: 900px) {
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
`;
