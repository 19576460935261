import { COLORS } from '@constants';
import { Card } from 'antd';
import styled from 'styled-components';

export const StyledWrapper = styled(Card)`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 101;
    transform: translate(-50%, -50%);
    width: calc(100% - 80px);
    max-width: 1280px;
    background-color: ${COLORS.$gray10Black};
`;

export const StyledMask = styled.div`
    z-index: 100;
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
`;
