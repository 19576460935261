import React from 'react';
import { COLORS } from '@constants';
import { Icon } from '@ui';
import { StyledIcon } from './styled';

const QuestionCircleIcon = ({ style, ...props }) => {
    return (
        <Icon
            {...props}
            style={{ paddingBlock: 4, ...style }}
            color={COLORS.$gray40Black}
            stroke='white'
            size='24px'
            component={() => <StyledIcon />}
        />
    );
};

export default QuestionCircleIcon;
