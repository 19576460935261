import React from 'react';
import { ROUTE_PATHS } from '@constants';
import { formatString } from '@utils';
import { notification } from 'antd';
import { getErrorMessage } from './getErrorMessage';

export function errorHandler({ error, show = true }) {
    if (!error || (!error.errors && !error?.response)) {
        return false;
    }

    if (error?.response?.status === 409) {
        window.location.replace(ROUTE_PATHS.HOME);
    }

    const errorMessage = getErrorMessage(error);

    const title = '';
    const description = errorMessage?.description || 'Something Went Wrong';
    const link = errorMessage?.link;

    if (show) {
        notification.error({
            message: title,
            description: (
                <p data-e2e={`error-notification_${formatString(description)}`} style={{ paddingRight: 32 }}>
                    {description}
                    {link && (
                        <span>
                            <br />
                            <a href={link}>{link}</a>
                        </span>
                    )}
                </p>
            ),
            duration: 5
        });
    }
}
