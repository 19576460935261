import { FIELD_NAME } from '@constants';
import { useStore } from '@hooks';
import _ from 'lodash';

export const useCalculatePriority = () => {
    const store = useStore();
    const { calculatePriority: calculate, userExcludedIds, userIncludedIds } = store.campaignWizardStore;

    const calculatePriority = (formValues = {}) => {
        const campaign = store.campaignWizardStore.campaign;
        const commission = store.accountsStore.currentAccount.commission;

        const storeValues = {
            [FIELD_NAME.CAMPAIGN.COMMISSION]: campaign?.commission,
            [FIELD_NAME.ACCOUNT.REST_OF_SITE_COMMISSION]: commission?.restOfSite,
            [FIELD_NAME.ACCOUNT.NEW_CUSTOMER_COMMISSION]: commission?.newCustomer,
            [FIELD_NAME.ACCOUNT.COLD_CUSTOMER_COMMISSION]: commission?.coldCustomer,
            [FIELD_NAME.CAMPAIGN.BOOST]: campaign?.boostId,
            userExcludedIds,
            userIncludedIds
        };

        // Form values take precidence over store values
        const mergedValues = _.mergeWith({}, storeValues, formValues, (storeVal, formVal) =>
            formVal !== undefined ? formVal : storeVal
        );

        return calculate({
            values: mergedValues
        });
    };

    return calculatePriority;
};
