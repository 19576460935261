import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Typography } from '@ui';
import { Checkbox, Col, Form, Row } from 'antd';
import { FIELD_NAMES } from '../../../../../constants';

const NonLinkedViews = () => {
    return (
        <Row align='middle' gutter={[24, 24]} wrap={false}>
            <Col>
                <Form.Item name={FIELD_NAMES.TRIPLE_WHALE_NON_LINKED_VIEWS} valuePropName='checked'>
                    <Checkbox data-e2e='tracking_tw-non-linked-views_checkbox' />
                </Form.Item>
            </Col>
            <Col>
                <Typography.Text
                    data-e2e='tracking_tw-non-linked-views_title'
                    type='badgeSmall'
                    style={BOTTOM_INDENTS.XXS}
                >
                    Include non-linked views
                </Typography.Text>
                <Typography.Text
                    data-e2e='tracking_tw-non-linked-views_description'
                    color={COLORS.$gray60Black}
                    type='descriptor2'
                >
                    Non-linked views include product views that do not result in a consumer landing in your store.We
                    don’t recommend including non-linked views in your primary TW property, due to high view volume.
                </Typography.Text>
            </Col>
        </Row>
    );
};
export default NonLinkedViews;
