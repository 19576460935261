import { PLATFORM_ID } from './platforms';

export const PRODUCT_KLICKLY_STATUS = {
    REJECTED: 0,
    NOT_CONNECTED: 1,
    IN_REVIEW: 2,
    ACCEPTED: 3,
    CONNECTED: 4,
    DELETED: 5
};

export const PRODUCT_ECOMMERCE_STATUS = {
    DELETED: 0,
    ARCHIVED: 1,
    DRAFT: 2,
    ACTIVE: 3
};

export const PRODUCT_VALIDATION_STATUS = {
    INVALID: 0,
    PENDING: 1,
    VALID: 2
};

// Whether product participates in at least one live campaign
export const PRODUCT_CONNECTION_STATUS = {
    NOT_CONNECTED: 0,
    CONNECTED: 1
};

export const PRODUCT_KLICKLY_STATUS_LABELS = {
    [PRODUCT_KLICKLY_STATUS.REJECTED]: 'Rejected',
    [PRODUCT_KLICKLY_STATUS.NOT_CONNECTED]: 'Not connected',
    [PRODUCT_KLICKLY_STATUS.IN_REVIEW]: 'In review',
    [PRODUCT_KLICKLY_STATUS.ACCEPTED]: 'Accepted',
    [PRODUCT_KLICKLY_STATUS.CONNECTED]: 'Connected',
    [PRODUCT_KLICKLY_STATUS.DELETED]: 'Deleted'
};

export const PRODUCT_ECOMMERCE_STATUS_LABELS = {
    [PLATFORM_ID.SHOPIFY]: {
        [PRODUCT_ECOMMERCE_STATUS.ARCHIVED]: { label: 'Archived', e2e: 'products_archived-ecommerce-status_selector' },
        [PRODUCT_ECOMMERCE_STATUS.DRAFT]: { label: 'Draft', e2e: 'products_draft-ecommerce-status_selector' },
        [PRODUCT_ECOMMERCE_STATUS.ACTIVE]: { label: 'Active', e2e: 'products_active-ecommerce-status_selector' }
    },
    [PLATFORM_ID.KLICKLY]: {
        [PRODUCT_ECOMMERCE_STATUS.ARCHIVED]: { label: 'Archived', e2e: 'products_archived-ecommerce-status_selector' },
        [PRODUCT_ECOMMERCE_STATUS.DRAFT]: { label: 'Draft', e2e: 'products_draft-ecommerce-status_selector' },
        [PRODUCT_ECOMMERCE_STATUS.ACTIVE]: { label: 'Active', e2e: 'products_active-ecommerce-status_selector' }
    },
    [PLATFORM_ID.BIGCOMMERCE]: {
        [PRODUCT_ECOMMERCE_STATUS.ARCHIVED]: { label: 'Archived', e2e: 'products_draft-ecommerce-status_selector' },
        [PRODUCT_ECOMMERCE_STATUS.DRAFT]: { label: 'Hidden', e2e: 'products_draft-ecommerce-status_selector' },
        [PRODUCT_ECOMMERCE_STATUS.ACTIVE]: { label: 'Active', e2e: 'products_active-ecommerce-status_selector' }
    }
};

export const statusesMap = {
    [PRODUCT_KLICKLY_STATUS.ACCEPTED]: {
        connectionStatuses: [PRODUCT_CONNECTION_STATUS.NOT_CONNECTED],
        validationStatuses: [PRODUCT_VALIDATION_STATUS.VALID]
    },
    [PRODUCT_KLICKLY_STATUS.NOT_CONNECTED]: {
        connectionStatuses: [PRODUCT_CONNECTION_STATUS.NOT_CONNECTED],
        validationStatuses: [PRODUCT_VALIDATION_STATUS.PENDING, PRODUCT_VALIDATION_STATUS.INVALID]
    },
    [PRODUCT_KLICKLY_STATUS.CONNECTED]: {
        connectionStatuses: [PRODUCT_CONNECTION_STATUS.CONNECTED],
        validationStatuses: [PRODUCT_VALIDATION_STATUS.VALID]
    },
    [PRODUCT_KLICKLY_STATUS.IN_REVIEW]: {
        connectionStatuses: [PRODUCT_CONNECTION_STATUS.CONNECTED],
        validationStatuses: [PRODUCT_VALIDATION_STATUS.PENDING]
    },
    [PRODUCT_KLICKLY_STATUS.REJECTED]: {
        connectionStatuses: [PRODUCT_CONNECTION_STATUS.CONNECTED],
        validationStatuses: [PRODUCT_VALIDATION_STATUS.INVALID]
    }
};
