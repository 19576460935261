import { Progress } from 'antd';
import styled from 'styled-components';

export const StyledProgress = styled(Progress)`
    .ant-progress-text {
        font-weight: 500;
    }

    * {
        -webkit-backface-visibility: unset;
    }
`;
