import Icon from '@ant-design/icons';
import styled, { css } from 'styled-components';

export const StyledIcon = styled(Icon)`
    color: ${({ $color }) => $color};
    font-size: ${({ $size }) => $size};
    position: relative;
    ${({ $stroke }) => $stroke && `stroke: ${$stroke};`}

    & > * {
        z-index: 2;
        position: relative;
    }

    &.ant-spin-dot {
        font-size: ${({ $size }) => $size};
    }

    ${({ $roundedSize }) =>
        $roundedSize &&
        css`
            cursor: pointer;

            &::after {
                transition: all 0.2s ease-in;
                height: ${({ $roundedSize }) => $roundedSize};
                width: ${({ $roundedSize }) => $roundedSize};
                outline: 2px solid ${({ $outlineColor }) => $outlineColor};
                outline-offset: -2px;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);

                content: '';
                position: absolute;
                border-radius: 50%;
                z-index: 1;
                background-color: ${({ $roundedColor }) => $roundedColor || 'transparent'};
                opacity: ${({ $roundedOpacity }) => $roundedOpacity || 1};
            }

            &:hover:after {
                background-color: ${({ $hoverRoundedColor }) => $hoverRoundedColor || 'transparent'};
            }
        `};
`;
