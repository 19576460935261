import React, { Fragment } from 'react';
import { SectionHeader } from '@components';
import { TOP_INDENTS } from '@constants';
import { Row } from 'antd';
import SingleRecommendation from './components/SingleRecommendation';
import { getRecommendations } from './constants';

const RecommendationsSection = () => {
    return (
        <Fragment>
            <SectionHeader data-e2e='home_recommendations-section_title' title='Recommendations' />
            <Row style={TOP_INDENTS.M} gutter={[16, 16]}>
                {getRecommendations.map((item) => (
                    <SingleRecommendation item={item} key={item.title} />
                ))}
            </Row>
        </Fragment>
    );
};

export default RecommendationsSection;
