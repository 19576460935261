import { COLORS } from '@constants';

export const APPEARANCE = {
    theme: 'stripe',
    variables: {
        colorBackground: COLORS.$white,
        colorText: COLORS.$gray100Black,
        colorTextPlaceholder: COLORS.$gray40Black,
        colorDanger: COLORS.$error,
        fontFamily: 'IBM Plex Sans, sans-serif',
        fontSizeBase: '16px',
        borderRadius: '8px'
    },
    rules: {
        '.Input': {
            border: `1px solid ${COLORS.$gray20Black}`,
            borderWidth: '1px',
            boxShadow: 'none',
            outline: 'none',
            lineHeight: '30px',
            fontSize: '16px'
        },

        '.Input--invalid': {
            boxShadow: 'none'
        },
        '.Input--empty:focus': {
            boxShadow: 'none',
            outline: 'none',
            border: `1px solid ${COLORS.$gray60Black}`
        },
        '.Input:focus': {
            boxShadow: 'none',
            outline: 'none',
            border: `1px solid ${COLORS.$gray60Black}`
        }
    }
};
