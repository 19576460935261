import React from 'react';
import { ACCOUNT_STATUS } from '@enums';
import { Button } from '@ui';
import { StyledTypography } from '../../styled';

const Unpause = ({ changeStatus, customClose }) => {
    return (
        <StyledTypography data-e2e='account-management_unpause-account-modal_description'>
            Your account is now paused. You will no longer be able to create or edit your campaigns. Click{' '}
            <Button
                data-e2e='account-management_unpause-account-modal_link'
                type='link'
                onClick={async () => {
                    await changeStatus(ACCOUNT_STATUS.ACTIVE);
                    customClose();
                }}
                padding={0}
                fontSize='18px'
            >
                here
            </Button>{' '}
            to unpause.
        </StyledTypography>
    );
};

export default Unpause;
