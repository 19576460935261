import React from 'react';
import { COLORS } from '@constants';
import { Typography } from '../../../Typography';
import { StyledTooltip } from './styled';

const Tooltip = ({ suggestedPercent, offset = 0 }) => {
    return (
        <StyledTooltip $offset={suggestedPercent + offset}>
            <div className='tooltip__body'>
                <Typography.Text data-e2e='common_tooltip-suggested_value' type='badgeMedium' color={COLORS.$white}>
                    {suggestedPercent}% suggested
                </Typography.Text>
            </div>
            <div className='tooltip__triangle' />
        </StyledTooltip>
    );
};

export default Tooltip;
