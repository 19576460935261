import React from 'react';
import { COLORS } from '@constants';
import { useStore } from '@hooks';
import { Typography } from '@ui';
import { Col, Flex, Row } from 'antd';
import { observer } from 'mobx-react';

const Welcome = observer(() => {
    const store = useStore();
    const { firstName, lastName } = store.authStore;
    const { logoUrl, companyInitials } = store.accountsStore;

    return (
        <Row align='middle' gutter={[16, 16]}>
            <Col>
                {logoUrl ? (
                    <img
                        data-e2e='home_brand_logo'
                        style={{ borderRadius: '100px' }}
                        src={logoUrl}
                        width='160px'
                        height='160px'
                        alt=''
                    />
                ) : (
                    <Flex
                        style={{ backgroundColor: COLORS.$gray40Black, width: 160, height: 160, borderRadius: 1000 }}
                        data-e2e='home_brand_logo'
                        justify='center'
                        align='center'
                    >
                        <Typography.Title color={COLORS.$white} style={{ fontSize: 72 }}>
                            {companyInitials}
                        </Typography.Title>
                    </Flex>
                )}
            </Col>
            <Col>
                <Typography.Title data-e2e='home_welcome_title' marginBottom='0' level={2}>
                    Welcome, {firstName} {lastName}
                </Typography.Title>
            </Col>
        </Row>
    );
});

export default Welcome;
