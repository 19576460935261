import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BOTTOM_INDENTS, COLORS, FIELD_NAME, ROUTE_PATHS, SUGGESTED_ROS_COMMISSION } from '@constants';
import { useStore } from '@hooks';
import { Typography } from '@ui';
import { Form, Layout } from 'antd';
import { observer } from 'mobx-react';
import Boost from '../../components/Boost';
import CampaignCommission from '../../components/CampaignCommission';
import ColdCustomerCommission from '../../components/ColdCustomerCommission';
import NewCustomerCommission from '../../components/NewCustomerCommission';
import RestOfSiteCommission from '../../components/RestOfSiteCommission';
import { useFormInit } from '../../form';
import { useCalculatePriority } from '../../hooks/useCalculatePriority';
import CampaignPriority from './components/CampaignPriority';
import { PAGE } from './constants';

const { Content, Sider } = Layout;

const Step3 = observer(() => {
    const params = useParams();
    const navigate = useNavigate();

    const store = useStore();
    const {
        setIsNextLoading,
        setIsNextDisabled,
        campaign,
        setPriorityPending,
        setFormSubmitting,
        formSubmitting,
        updateCampaign,
        updatePriority
    } = store.campaignWizardStore;
    const { commissionsApplied } = store.accountsStore;
    const { getCampaignCommissions } = store.campaignsStore;
    const { commission } = store.accountsStore.currentAccount;

    const form = useFormInit();

    const nextLoading = formSubmitting;

    useEffect(() => {
        setIsNextLoading(nextLoading);
    }, [nextLoading]);

    useEffect(() => {
        setIsNextDisabled(false);
    }, []);

    const instantOnInputChange = () => {
        setPriorityPending(true);
    };

    const calculatePriority = useCalculatePriority();

    const debouncedOnInputChange = useCallback(() => {
        const formValues = form.getFieldsValue(true);
        calculatePriority(formValues);
    });

    const onFinish = async ({ boostId, campaignCommission, ...values }) => {
        setFormSubmitting(true);
        // TODO change back end logic in order to improve this place (auto recalculate priority after boost or commission changed)
        await updateCampaign({ values: { boostId, commission: campaignCommission } });
        await updatePriority({ values: { ...values, boostId, campaignCommission } });
        await getCampaignCommissions();
        setFormSubmitting(false);

        const { error } = store.campaignWizardStore;
        if (!error) navigate(`${ROUTE_PATHS.CAMPAIGNS_CREATE}/step-4/${params?.id}`);
    };

    const initialFormValues = {
        [FIELD_NAME.CAMPAIGN.BOOST]: campaign?.boostId,
        [FIELD_NAME.CAMPAIGN.COMMISSION]: campaign?.commission,
        [FIELD_NAME.ACCOUNT.REST_OF_SITE_COMMISSION]: commission?.restOfSite || SUGGESTED_ROS_COMMISSION,
        [FIELD_NAME.ACCOUNT.NEW_CUSTOMER_COMMISSION]: commission?.newCustomer,
        [FIELD_NAME.ACCOUNT.COLD_CUSTOMER_COMMISSION]: commission?.coldCustomer
    };

    useEffect(() => {
        calculatePriority(initialFormValues);
    }, []);

    return (
        <Layout hasSider style={{ justifyContent: 'space-between', gap: 40 }}>
            <Form form={form} initialValues={initialFormValues} onFinish={onFinish}>
                <Content style={{ maxWidth: 867 }} tagName='div'>
                    <Typography.Title data-e2e='campaignwizard_step-4_title' style={BOTTOM_INDENTS.M}>
                        {PAGE.TITLE}
                    </Typography.Title>
                    <Typography.Text
                        data-e2e='campaignwizard_step-4_description'
                        type='body2'
                        style={BOTTOM_INDENTS.M}
                        color={COLORS.$gray60Black}
                    >
                        {PAGE.DESCRIPTION}
                    </Typography.Text>
                    <CampaignCommission
                        instantOnInputChange={instantOnInputChange}
                        debouncedOnInputChange={debouncedOnInputChange}
                    />
                    {!commissionsApplied ? (
                        <React.Fragment>
                            <RestOfSiteCommission
                                instantOnInputChange={instantOnInputChange}
                                debouncedOnInputChange={debouncedOnInputChange}
                            />
                            <NewCustomerCommission
                                instantOnInputChange={instantOnInputChange}
                                debouncedOnInputChange={debouncedOnInputChange}
                            />
                            <ColdCustomerCommission
                                instantOnInputChange={instantOnInputChange}
                                debouncedOnInputChange={debouncedOnInputChange}
                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Form.Item name={FIELD_NAME.ACCOUNT.REST_OF_SITE_COMMISSION} noStyle />
                            <Form.Item name={FIELD_NAME.ACCOUNT.NEW_CUSTOMER_COMMISSION} noStyle />
                            <Form.Item name={FIELD_NAME.ACCOUNT.COLD_CUSTOMER_COMMISSION} noStyle />
                        </React.Fragment>
                    )}

                    <Boost
                        instantOnInputChange={instantOnInputChange}
                        debouncedOnInputChange={debouncedOnInputChange}
                    />
                </Content>
            </Form>
            <Sider width='300px' style={{ backgroundColor: 'unset' }}>
                <CampaignPriority />
            </Sider>
        </Layout>
    );
});

export default Step3;
