import React, { useEffect } from 'react';
import { useStore } from '@hooks';
import { Loader, Table } from '@ui';
import { observer } from 'mobx-react';
import { columnsInner } from '../../constants';

const ProductVariantsTable = observer(({ productId }) => {
    const store = useStore();
    const { getProduct, getProductVariants } = store.productsStore;
    const product = getProduct(productId);

    useEffect(() => {
        getProductVariants(productId);
    }, []);

    if (product.variants.loading && !product.variants.data.length) {
        return <Loader data-e2e={`products_${productId}-product-details_loader`} size='20px' />;
    }

    if (!product.variants.data.length) {
        return null;
    }

    const handleProductVariantsTableChange = (productId, pagination) => {
        const page = pagination?.current;
        getProductVariants(productId, page);
    };

    const pagination =
        product.variants.meta.totalPages > 1
            ? {
                  pageSize: product.variants.meta.perPage,
                  total: product.variants.meta.total,
                  current: product.variants.meta.page,
                  totalPages: product.variants.meta.totalPages
              }
            : false;

    return (
        <Table
            data-e2e={`products_${productId}-product-details_section`}
            innerTable
            rowKey={(record) => record.id}
            borderColor='transparent'
            columns={columnsInner}
            dataSource={product.variants.data}
            showSizeChanger={false}
            pagination={pagination}
            onChange={(pagination) => {
                handleProductVariantsTableChange(product.id, pagination);
            }}
        />
    );
});

export default ProductVariantsTable;
