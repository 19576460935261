import React from 'react';
import { Typography } from '../../../Typography';
import BaseRadio from '../BaseRadio';
import { StyledLabel } from './styled';

const Label = ({ children, style, labelColor, cursor, labelLeftIndent, height, ...props }) => {
    return (
        <StyledLabel
            $height={height}
            $cursor={cursor}
            $labelColor={labelColor}
            $labelLeftIndent={labelLeftIndent}
            style={style}
        >
            <BaseRadio {...props} />
            <Typography.Text data-e2e='common_radio_label' type='badgeMedium'>
                {children}
            </Typography.Text>
        </StyledLabel>
    );
};

export default Label;
