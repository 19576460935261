import { COLORS } from '@constants';
import { Flex } from 'antd';
import styled from 'styled-components';

export const StyledWrapper = styled(Flex)`
    width: 100%;
    padding-inline: 24px;
    height: 100vh;
    background: ${COLORS.$gray10Black};
`;
