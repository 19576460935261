import React from 'react';
import { Typography } from '@ui';
import { formatString } from '@utils';
import { Col, Row } from 'antd';
import { StyledInnerWrapper } from '../../styled';
import { FOOTER_LINKS } from './constants';
import { StyledNavLink } from './styled';

const FooterLinks = () => {
    return (
        <StyledInnerWrapper>
            <Row gutter={24} justify='center' align='middle'>
                {FOOTER_LINKS.map((link) => (
                    <Col key={link.key}>
                        <StyledNavLink to={link.key} target='_blank'>
                            <Typography.Text
                                data-e2e={`footer_${formatString(link.title)}_link`}
                                type='descriptor2'
                                color='inherit'
                            >
                                {link.title}
                            </Typography.Text>
                        </StyledNavLink>
                    </Col>
                ))}
            </Row>
        </StyledInnerWrapper>
    );
};

export default FooterLinks;
