import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 1px solid ${COLORS.$gray15Black};
    border-radius: 8px;
    padding: 8px 24px;
`;

export const StyledLeft = styled.div`
    display: flex;
    align-items: center;
`;
