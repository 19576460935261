import React, { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { SearchSuffix } from '@components';
import { FIELD_NAME } from '@constants';
import { CAMPAIGN_TYPE_ID, TABS_TYPES } from '@enums';
import { useStore } from '@hooks';
import { Icon, Input, Tabs } from '@ui';
import { Form } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { useDebouncedCallback } from 'use-debounce';
import { useCalculatePriority } from '../../hooks/useCalculatePriority';
import LeftTable from './components/LeftTable';
import RightTable from './components/RightTable';
import TabLabel from './components/TabLabel';
import { StyledInputWrapper, StyledWrapper } from './styled';

const ProductsSelection = observer(() => {
    const form = Form.useFormInstance();
    const isInitialRender = useRef(true);

    const store = useStore();
    const {
        campaign,
        accountProductsMeta,
        computedCampaignProductsCount,
        getAccountProducts,
        setProductsSelectionPending,
        getCurrentTabProducts,
        handleProductsSelection,
        resetSearch,
        resetSelectedProductsIds,
        resetProducts,
        resetProductsSelection,
        selectedProductsIds,
        searchValue,
        setSearchValue,
        setTabType,
        getPreviewProducts,
        userExcludedIds,
        userIncludedIds,
        setPriorityPending
    } = store.campaignWizardStore;
    const params = useParams();

    useEffect(() => {
        if (campaign?.id && campaign.id === Number(params.id)) {
            getAccountProducts({ withCampaignProductsMeta: true });
        }
    }, [campaign?.id]);

    useEffect(() => {
        return () => {
            setTabType(TABS_TYPES.ACCOUNT_PRODUCTS);
            resetSearch();
            resetProducts();
            resetSelectedProductsIds();
            resetProductsSelection();
        };
    }, []);

    const calculatePriority = useCalculatePriority();
    const debouncedRecalculate = useDebouncedCallback(() => {
        const { COMMISSION, BOOST } = FIELD_NAME.CAMPAIGN;
        const { REST_OF_SITE_COMMISSION, NEW_CUSTOMER_COMMISSION, COLD_CUSTOMER_COMMISSION } = FIELD_NAME.ACCOUNT;

        const formValues = form.getFieldsValue([
            COMMISSION,
            REST_OF_SITE_COMMISSION,
            NEW_CUSTOMER_COMMISSION,
            COLD_CUSTOMER_COMMISSION,
            BOOST
        ]);

        calculatePriority(formValues);
    }, 1000);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }

        setPriorityPending(true);
        debouncedRecalculate();
    }, [userExcludedIds.length, userIncludedIds.length]);

    const debounceGetProducts = useCallback(
        _.debounce(({ withAnotherTabMeta = false } = {}) => {
            getCurrentTabProducts({ withAnotherTabMeta });
        }, 300),
        []
    );

    const debounceGetPreviewProducts = useCallback(
        _.debounce(() => {
            getPreviewProducts();
        }, 500),
        []
    );

    const onSearchChange = (e) => {
        setProductsSelectionPending(true);
        setSearchValue(e.target.value);
        debounceGetProducts({ withAnotherTabMeta: true });
    };

    const onTabChange = (currentTab) => {
        setProductsSelectionPending(true);
        resetSelectedProductsIds();
        resetProducts();
        setTabType(currentTab);
        debounceGetProducts();
    };

    const onRow = (record) => ({
        onClick: () => {
            let selectedIds;
            if (selectedProductsIds.includes(record.id)) {
                selectedIds = _.without(selectedProductsIds, record.id);
            } else {
                selectedIds = [...selectedProductsIds, record.id];
            }
            handleProductsSelection(selectedIds);
            debounceGetPreviewProducts();
        }
    });

    const handleRowsSelectionChange = (ids) => {
        handleProductsSelection(ids);
        debounceGetPreviewProducts();
    };

    const items = [
        {
            key: TABS_TYPES.ACCOUNT_PRODUCTS,
            label: <TabLabel label='Catalog' count={accountProductsMeta.total} />,
            children: <LeftTable onRow={onRow} handleRowsSelectionChange={handleRowsSelectionChange} />
        },
        {
            key: TABS_TYPES.CAMPAIGN_PRODUCTS,
            label: (
                <TabLabel
                    label={campaign?.catalogType === CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCTS ? 'Inclusions' : 'Exclusions'}
                    count={computedCampaignProductsCount}
                />
            ),
            children: <RightTable handleRowsSelectionChange={handleRowsSelectionChange} onRow={onRow} />
        }
    ];

    return (
        <StyledWrapper>
            <StyledInputWrapper>
                <Input
                    data-e2e='campaignwizard_search-products_input'
                    label='Search Products'
                    value={searchValue}
                    onChange={onSearchChange}
                    onPressEnter={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                    allowClear={{
                        clearIcon: (
                            <Icon
                                style={{ marginTop: 3 }}
                                size='24px'
                                component={() => (
                                    <Icon.CloseIcon
                                        data-e2e='campaignwizard_clear-search-input_icon'
                                        width={24}
                                        height={24}
                                    />
                                )}
                            />
                        )
                    }}
                    suffix={!searchValue && <SearchSuffix onSetSearchValue={setSearchValue} />}
                    style={{ borderWidth: 2 }}
                />
            </StyledInputWrapper>
            <Tabs items={items} onChange={onTabChange} destroyInactiveTabPane fullWidth />
        </StyledWrapper>
    );
});

export default ProductsSelection;
