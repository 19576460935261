import React, { useEffect } from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { PURCHASE_EVENT_STATUS, SCRIPT_TAG_STATUS } from '@enums';
import { useModal } from '@hooks';
import { Typography } from '@ui';
import { Flex } from 'antd';
import dayjs from 'dayjs';
import { useScriptTag } from '../../../../../../../../../components/IntegrationKlickly/hooks/useScriptTag';
import AnalyticsScriptModal from '../components/AnalyticsScriptModal';
import ApiSetupModal from '../components/ApiSetupModal';
import CustomersModal from '../components/CustomersModal';
import OrdersModal from '../components/OrdersModal';
import ProductCatalogModal from '../components/ProductCatalogModal';
import PurchaseEventsModal from '../components/PurchaseEventsModal';
import Footer from '../shared/Footer';
import Title from '../shared/Title';

export const useSetupModal = (store, data, activeKey, key) => {
    const { open, close, update, modal } = useModal();
    const { hasRequiredParams } = useScriptTag();

    const {
        setupInfo,
        setupHistorical,
        setupPurchase,
        purchaseSetupPending = false,
        setupScriptTag,
        historicalSetupOrdersPending = false,
        historicalSetupCustomersPending = false,
        scriptTagSetupPending = false
    } = store.accountsStore;

    const onClose = () => {
        close();
    };

    const MODAL_CONFIG = {
        'api-setup': {
            title: <Title text='API setup' onClick={() => window.open('/docs/api', '_blank')} />,
            content: <ApiSetupModal data={data} />,
            footer: <Footer showStatus={false} showConfirm={false} close={onClose} />
        },
        'analytics-script': {
            title: <Title text='Analytics script setup' onClick={() => window.open('/docs/api', '_blank')} />,
            content: <AnalyticsScriptModal data={data} />,
            footer: (
                <Footer
                    close={onClose}
                    status={data.status}
                    confirmDisable={!hasRequiredParams}
                    confirm={setupScriptTag}
                    fetching={scriptTagSetupPending}
                    pending={data.status === SCRIPT_TAG_STATUS.PENDING}
                    error={
                        setupInfo.scriptTag.lastPingAt && data.status === SCRIPT_TAG_STATUS.NOT_CONNECTED ? (
                            <Flex vertical style={BOTTOM_INDENTS.M}>
                                <Typography.Text type='badgeSmall' color={COLORS.$tartRed}>
                                    Script check failed at{' '}
                                    {dayjs(setupInfo.scriptTag.lastPingAt).format('DD MMM YYYY, HH:mm:ss')}
                                </Typography.Text>
                                <Typography.Text type='badgeSmall' color={COLORS.$tartRed}>
                                    We were unable to confirm the script in your Store
                                </Typography.Text>
                            </Flex>
                        ) : null
                    }
                />
            )
        },
        'purchase-event': {
            title: <Title text='Purchase events setup' onClick={() => window.open('/docs/api', '_blank')} />,
            content: <PurchaseEventsModal data={data} />,
            footer: (
                <Footer
                    key={data.status}
                    fetching={purchaseSetupPending}
                    pending={data.status === PURCHASE_EVENT_STATUS.PENDING}
                    confirm={setupPurchase}
                    close={onClose}
                    status={data.status}
                    error={
                        setupInfo.scriptTag.lastPingPurchaseAt &&
                        data.status === PURCHASE_EVENT_STATUS.NOT_CONNECTED ? (
                            <Flex vertical style={BOTTOM_INDENTS.M}>
                                <Typography.Text type='badgeSmall' color={COLORS.$tartRed}>
                                    Script check failed at{' '}
                                    {dayjs(setupInfo.scriptTag.lastPingPurchaseAt).format('DD MMM YYYY, HH:mm:ss')}
                                </Typography.Text>
                                <Typography.Text type='badgeSmall' color={COLORS.$tartRed}>
                                    We were unable to confirm the script on your Thank You Page
                                </Typography.Text>
                            </Flex>
                        ) : null
                    }
                />
            )
        },
        'product-catalog': {
            title: (
                <Title text='Product catalog setup' onClick={() => window.open('/docs/api#tag/products', '_blank')} />
            ),
            content: <ProductCatalogModal data={data} />,
            footer: <Footer close={onClose} status={data.status} showConfirm={false} />
        },
        orders: {
            title: <Title text='Orders setup' onClick={() => window.open('/docs/api#tag/webhook_orders', '_blank')} />,
            content: <OrdersModal data={data} />,
            footer: (
                <Footer
                    close={onClose}
                    status={data.status}
                    confirm={() => setupHistorical('orders')}
                    pending={historicalSetupOrdersPending}
                />
            )
        },
        customers: {
            title: (
                <Title
                    text='Customers setup'
                    onClick={() => window.open('/docs/api#tag/webhook_customers', '_blank')}
                />
            ),
            content: <CustomersModal data={data} />,
            footer: (
                <Footer
                    close={onClose}
                    status={data.status}
                    pending={historicalSetupCustomersPending}
                    confirm={() => setupHistorical('customers')}
                />
            )
        }
    };

    const openModal = (key) => {
        open({
            ...MODAL_CONFIG[key],
            settings: {
                width: '630px',
                footer: MODAL_CONFIG[key].footer
            }
        });
    };

    // Use update to dynamically update the modal when `data.status` changes
    useEffect(() => {
        if (activeKey === key && Boolean(modal)) {
            update({
                settings: {
                    footer: MODAL_CONFIG[key].footer
                }
            });
        }
    }, [
        data.status,
        purchaseSetupPending,
        scriptTagSetupPending,
        historicalSetupOrdersPending,
        historicalSetupCustomersPending
    ]);

    return { openModal };
};
