import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Button, Typography } from '@ui';
import { StyledCode, StyledDescription, StyledText } from './styled';

const purchaseScript = `
<script>
    klpixel("event", "purchase", {
        shop: {
            checkout: {
                order_id: number,
                order_financial_status: 'paid',
                order_created_at: new Date(),
                order_tags: [string],
                email: string,
                phone: string,
                customer_id: number,
                line_items: [{
                    product_name: string,
                    product_id: number,
                    image: string,
                    price: number,
                    quantity: number
                }]
            }
        }
    });
</script>`.trim();

const purchaseScriptColored = `
<pre>
&lt;script&gt;
    klpixel("event", "purchase", {
        shop: {
            checkout: {
                order_id: <span>number</span>,
                order_financial_status: "paid",
                order_created_at: new Date(),
                order_tags: <span>[string]</span>,
                email: <span>string</span>,
                phone: <span>string</span>,
                customer_id: <span>number</span>,
                line_items: [{
                    product_name: <span>string</span>,
                    product_id: <span>number</span>,
                    image: <span>string</span>,
                    price: <span>number</span>,
                    quantity: <span>number</span>
                }]
            }
        }
    });
&lt;script&gt;
</pre>
`;

const PurchaseEventsModal = () => {
    return (
        <React.Fragment>
            <Typography.Text type='descriptor1' color={COLORS.$black} style={BOTTOM_INDENTS.M}>
                In order to scale your marketing campaigns and accurately charge commissions, Klickly&apos;s Conversion
                Script needs the ability to analyze your purchase events.
            </Typography.Text>
            <StyledText type='descriptor1' color={COLORS.$black} style={BOTTOM_INDENTS.M}>
                To install your analytics script:
                <ul>
                    <li>Copy the below script</li>
                    <li>
                        Paste the script on your store’s thank you page, modifying the event object to include the
                        required order details
                    </li>
                    <li>
                        Make a purchase (real or test) on your site (we require at least 1 live purchase to confirm the
                        script is working)
                    </li>
                    <li>Click “Confirm connection” to allow Klickly to confirm installation</li>
                </ul>
            </StyledText>
            <StyledCode style={BOTTOM_INDENTS.M}>
                <Button.Copy value={purchaseScript} />
                <div
                    dangerouslySetInnerHTML={{
                        __html: purchaseScriptColored
                    }}
                />
            </StyledCode>
            <StyledDescription>
                <Typography.Text align='left' type='descriptor1'>
                    Once correctly installed, the status of this step will automatically change to Connected.
                </Typography.Text>
            </StyledDescription>
        </React.Fragment>
    );
};

export default PurchaseEventsModal;
