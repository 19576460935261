import React from 'react';
import { COLORS } from '@constants';
import { Button, Typography } from '@ui';
import { Flex } from 'antd';
import { observer } from 'mobx-react';
import { StyledAlert } from '../../styled';

const StandardIncomplete = observer(() => {
    return (
        <StyledAlert>
            <Flex justify='space-between' align='center'>
                <Typography.Text
                    data-e2e='payments_stripe-standard-incomplete-status_title'
                    color={COLORS.$tartRed}
                    type='descriptor1'
                >
                    Please enable transfers to your Stripe account
                </Typography.Text>
                <Button
                    data-e2e='payments_stripe-update_btn'
                    style={{ width: 184 }}
                    block
                    type='primary'
                    onClick={() => window.open('https://dashboard.stripe.com/login', '_blank')}
                >
                    Update
                </Button>
            </Flex>
        </StyledAlert>
    );
});

export default StandardIncomplete;
