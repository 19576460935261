import { Modal, Typography } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
    padding: 24px;
    position: absolute;
    top: 164px;
    left: 50%;
    transform: translateX(-50%);

    .ant-modal-close-x {
        margin-top: 7px;
        margin-right: 10px;
        font-size: 24px;
    }

    .ant-modal-close:hover {
        background-color: transparent !important;
    }

    &.ant-zoom-appear,
    &.ant-zoom-enter {
        transform: translateX(-50%) scale(1) !important;
    }

    .ant-modal-content {
        animation: none !important;
    }
`;

export const StyledTypographyHeader = styled(Typography.Text)`
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
`;

export const StyledTypography = styled(Typography.Text)`
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 12px;
    display: block;
`;

export const StyledCloseButton = styled.span.attrs({
    'data-e2e': 'home_close-support-modal_icon'
})``;
