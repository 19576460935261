import { brandsOpenApi } from '@constants';
import { CAMPAIGN_PRODUCTS_LIST_TYPES, CAMPAIGN_STATUS, CAMPAIGN_SUBMIT_ERROR } from '@enums';
import { axiosBoa, getErrorMessage } from '@utils';
import { notification } from 'antd';
import { flow, getParent, types } from 'mobx-state-tree';
import { models } from './constants';

export const CampaignsStore = types
    .model('CampaignsStore', {
        campaigns: types.array(models.CampaignModel),
        meta: types.optional(
            types.model({
                page: types.number,
                perPage: types.number,
                total: types.number,
                totalPages: types.number
            }),
            {
                page: 1,
                perPage: 10,
                total: 0,
                totalPages: 0
            }
        ),
        filters: types.model({
            campaign_commissions: types.maybe(types.array(types.number)),
            catalog_types: types.maybe(types.array(types.number)),
            statuses: types.maybe(types.array(types.number)),
            search: types.maybe(types.string)
        }),
        sorters: types.model({
            sort: types.maybe(types.string),
            order: types.maybe(types.string)
        }),
        pending: false,
        campaignCommissions: types.array(types.number),
        error: types.maybeNull(types.model({ title: types.string, description: types.string }))
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        getCampaign(id) {
            return self.campaigns.find((campaign) => campaign.id === id);
        },
        getStoreCampaignProducts(campaignIndex) {
            return self.campaigns[campaignIndex]?.products?.data || [];
        }
    }))
    .actions((self) => ({
        resetFilters() {
            self.filters = {};
        },
        updateFilters(filters) {
            Object.assign(self.filters, filters);
        },
        resetSorters() {
            self.sorters = {};
        },
        updateSorters(sorters) {
            Object.assign(self.sorters, sorters);
        },
        getCampaigns: flow(function* getCampaigns(p = {}) {
            self.pending = true;

            const params = {};
            Object.assign(params, self.filters, self.sorters, p);

            try {
                const data = yield axiosBoa.get(brandsOpenApi.campaigns.list, { params });
                self.campaigns = data.data;
                self.meta = data.meta;

                yield self.root.googleAdsStore.getData();
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pending = false;
            }
        }),
        getCampaignProducts: flow(function* getCampaignProducts(campaignId, page = 1) {
            const campaign = self.getCampaign(campaignId);
            campaign.productsPending = true;
            try {
                const data = yield axiosBoa.post(brandsOpenApi.campaigns.products.listPost(campaignId), {
                    type: CAMPAIGN_PRODUCTS_LIST_TYPES.INCLUDED,
                    page,
                    limit: 10
                });
                campaign.products = data;
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                campaign.productsPending = false;
            }
        }),
        getCampaignCommissions: flow(function* getCampaignCommissions() {
            self.error = null;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.campaigns.list, {
                    params: {
                        select: 'campaign_commissions',
                        pagination: 'none',
                        fields: ['commission']
                    }
                });

                const uniqueCommissions = [...new Set(response.data.map((item) => item.commission))];

                self.campaignCommissions = uniqueCommissions;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        pauseCampaign: flow(function* pauseCampaign(campaignId) {
            const campaign = self.getCampaign(campaignId);
            campaign.pending = true;

            try {
                const pausedCampaign = yield axiosBoa.patch(brandsOpenApi.campaigns.patch(campaignId), {
                    status: CAMPAIGN_STATUS.PAUSED
                });

                Object.assign(campaign, pausedCampaign);
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                setTimeout(() => {
                    self.setPendingOff(campaignId);
                }, 5000);
            }
        }),
        resumeCampaign: flow(function* resumeCampaign(campaignId) {
            const campaign = self.getCampaign(campaignId);
            campaign.pending = true;

            try {
                const { ready, notConfirmed } = yield axiosBoa.post(brandsOpenApi.campaigns.submit(campaignId));

                if (ready) {
                    notification.success({
                        message: 'Success',
                        description: (
                            // eslint-disable-next-line react/react-in-jsx-scope
                            <span data-e2e='campaigns_resumed-successfully_notification'>
                                Campaign will be active soon
                            </span>
                        )
                    });
                } else {
                    Object.values(notConfirmed).map((error) => {
                        notification.error({
                            message: 'Can not activate campaign',
                            description: (
                                // eslint-disable-next-line react/react-in-jsx-scope
                                <span data-e2e='campaigns_cannot-resume_notification'>
                                    {CAMPAIGN_SUBMIT_ERROR[error.name]}
                                </span>
                            ),
                            duration: 0
                        });
                    });
                }
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.reFetchCampaign(campaignId, [
                    CAMPAIGN_STATUS.ACTIVE,
                    CAMPAIGN_STATUS.PAUSED,
                    CAMPAIGN_STATUS.CREATED,
                    CAMPAIGN_STATUS.DELETED,
                    CAMPAIGN_STATUS.FROZEN
                ]);
            }
        }),
        deleteCampaign: flow(function* deleteCampaign(campaignId) {
            const campaign = self.getCampaign(campaignId);
            campaign.isDeleting = true;
            try {
                const deletedCampaign = yield axiosBoa.delete(brandsOpenApi.campaigns.patch(campaignId));
                Object.assign(campaign, deletedCampaign);
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                campaign.isDeleting = false;
            }
        }),
        setPendingOff(campaignId) {
            const campaign = self.getCampaign(campaignId);
            campaign.pending = false;
        },
        reFetchCampaign: flow(function* refetchCampaign(campaignId, expectedStatuses) {
            const campaign = self.getCampaign(campaignId);

            try {
                const reFetchedCampaign = yield axiosBoa.get(brandsOpenApi.campaigns.get(campaignId));
                Object.assign(campaign, reFetchedCampaign);
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                if (expectedStatuses.includes(campaign.status)) {
                    campaign.pending = false;
                } else {
                    setTimeout(() => {
                        self.reFetchCampaign(campaignId, expectedStatuses);
                    }, 3000);
                }
            }
        })
    }));
