import React, { useCallback } from 'react';
import { BoostRadios } from '@components';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Loader, Typography, WhiteCover } from '@ui';
import { Form } from 'antd';
import _ from 'lodash';

const Boost = ({ instantOnInputChange, debouncedOnInputChange, loading }) => {
    const name = 'boostId';

    const debouncedOnChange = useCallback(_.debounce(debouncedOnInputChange, 500), []);

    const onChange = () => {
        instantOnInputChange();
        debouncedOnChange();
    };

    return (
        <WhiteCover data-e2e='campaignwizard_weekly-boost_block' title='Weekly Boost'>
            {loading && <Loader />}
            <Typography.Text
                data-e2e='campaignwizard_weekly-boost_description'
                type='body2'
                style={BOTTOM_INDENTS.M}
                color={COLORS.$gray60Black}
            >
                This recurring weekly Boost increases your campaign Priority, until you turn it off. (Hint: can&apos;t
                afford commission, but still want high Priority? Add a weekly Boost.)
            </Typography.Text>
            <Form.Item name={name}>
                <BoostRadios data-e2e='campaignwizard_weekly-boost_radios' name={name} onChange={onChange} />
            </Form.Item>
            <Typography.Text data-e2e='campaignwizard_weekly-boost_hint' type='body2' color={COLORS.$gray60Black}>
                Note: This is a recurring weekly fee
            </Typography.Text>
        </WhiteCover>
    );
};

export default Boost;
