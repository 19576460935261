import { brandsOpenApi } from '@constants';
import { axiosBoa, getErrorMessage } from '@utils';
import { flow, getParent, types } from 'mobx-state-tree';

export const ForgotPasswordStore = types
    .model('ForgotPasswordStore', {
        pending: types.maybeNull(types.boolean),
        error: types.maybeNull(types.model({ title: types.string, description: types.string }))
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => ({
        sendForgotPasswordLink: flow(function* sendForgotPasswordLink({ data, onSuccess = () => null }) {
            self.pending = true;
            try {
                yield axiosBoa.post(brandsOpenApi.forgotPassword.request, data);
                onSuccess();
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pending = false;
            }
        }),
        resetPassword: flow(function* resetPassword({ token, data, onSuccess = () => null }) {
            self.pending = true;
            try {
                yield axiosBoa.post(brandsOpenApi.forgotPassword.confirm(token), data);
                onSuccess();
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pending = false;
            }
        })
    }));
