import { METRICS } from '../../../../../constants';

export const getSplitColumnsByCategory = () => {
    return Object.values(METRICS).reduce((acc, item) => {
        switch (item.category) {
            case 'Summary':
                return { ...acc, summaryCategoryCol: [...(acc.summaryCategoryCol || []), item] };
            case 'Engagements details':
                return { ...acc, engagementsDetailsCategoryCol: [...(acc.engagementsDetailsCategoryCol || []), item] };
            case 'Order details':
                return { ...acc, orderDetailsCategoryCol: [...(acc.orderDetailsCategoryCol || []), item] };
            default:
                return acc;
        }
    }, {});
};
