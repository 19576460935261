import React, { Fragment } from 'react';
import NonLinkedViews from './components/NonLinkedViews';

const Settings = () => {
    return (
        <Fragment>
            <NonLinkedViews />
        </Fragment>
    );
};

export default Settings;
