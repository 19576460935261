import React, { useEffect } from 'react';
import { BOTTOM_INDENTS, COLORS, defaultTimeProps, TOP_INDENTS } from '@constants';
import { DOMAIN_CLASS } from '@enums';
import { useStore } from '@hooks';
import { Button, Divider, Icon, Loader, Tag, Typography } from '@ui';
import { Card, Flex, Row } from 'antd';
import { observer } from 'mobx-react';
import config from '../../config';
import { LogoConnector } from '../../ui/LogoConnector';
import CheckboxCard from '../Performance/components/Overview/components/CheckboxCard';
import CheckboxCardsPlaceholder from '../Performance/components/Overview/components/CheckboxCardsPlaceholder';
import { PERFORMANCE_METRICS } from '../Performance/constants';
import { StyledFlex, StyledLoaderWrapper, StyledWrapper } from './styled';

const Welcome = observer(() => {
    const date = defaultTimeProps;
    const store = useStore();
    const { firstName, lastName } = store.authStore;
    const { currentAccount, getCurrentAccount, pending } = store.accountsStore;
    const { performanceList, pendingMetricsOverview, getMetricsOverview } = store.performanceStore;
    const shopDomain = currentAccount?.domains?.find(
        (domain) => domain.class === DOMAIN_CLASS.MAIN_ECOMMERCE
    )?.shopDomain;

    const defaultProps = {
        ...date
    };

    useEffect(() => {
        (async () => {
            await getCurrentAccount();
        })();
    }, [pending]);

    useEffect(() => {
        getMetricsOverview({
            metrics: PERFORMANCE_METRICS,
            ...defaultProps
        });
    }, []);

    if (pending) {
        return (
            <StyledLoaderWrapper>
                <Loader size='120px' />
            </StyledLoaderWrapper>
        );
    }

    return (
        <StyledWrapper>
            <StyledFlex align='center' justify='space-between'>
                <LogoConnector />
                <Typography.Title level={2}>
                    Welcome, {firstName} {lastName}
                </Typography.Title>
                <Button
                    style={{ width: 160 }}
                    type='primary'
                    onClick={() => {
                        window.open(config.appUrl, '_blank');
                    }}
                >
                    Visit Klickly
                </Button>
            </StyledFlex>
            <Divider margin={0} />
            <Card style={{ ...TOP_INDENTS.XL_PLUS, ...BOTTOM_INDENTS.XL_PLUS }}>
                <Flex align='center' justify='space-between' gap={16}>
                    <Flex gap={16} align='center'>
                        <Icon color={COLORS.$gray60Black} size='80px' component={() => <Icon.CampaignIcon />} />
                        <Flex vertical>
                            <Typography.Text marginBottom='8px' type='badgeLarge'>
                                App conection status
                            </Typography.Text>
                            <Typography.Text color={COLORS.$gray60Black} type='body2'>
                                Your Klickly Brands advertising connection is ready. All necessary information will
                                automatically be updated from now on.
                            </Typography.Text>
                        </Flex>
                    </Flex>
                    <Tag color={COLORS.$robinBlue}>
                        <Typography.Text type='badgeSmall' color={COLORS.$white}>
                            Connected to {shopDomain}
                        </Typography.Text>
                    </Tag>
                </Flex>
            </Card>
            <Typography.Title level={2} style={BOTTOM_INDENTS.L}>
                Performance overview (last 30 days)
            </Typography.Title>
            <Row gutter={[16, 16]}>
                {pendingMetricsOverview ? (
                    <CheckboxCardsPlaceholder />
                ) : (
                    performanceList?.map((metric) => {
                        return <CheckboxCard key={metric.id} date={date} metric={metric} hideInfo />;
                    })
                )}
            </Row>
        </StyledWrapper>
    );
});

export default Welcome;
