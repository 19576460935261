import { CAMPAIGN_TYPE_ID } from '@enums';
import { useStore } from '@hooks';
import { Icon } from '@ui';
import { useSpecificCollection } from './useSpecificCollection';
import { useSpecificProductType } from './useSpecificProductType';

const useCampaignTypes = () => {
    const specificProductTypes = useSpecificProductType();
    const specificCollections = useSpecificCollection();

    const store = useStore();
    const { isBigCommerce, isManual } = store.accountsStore;

    const campaigns = {
        entireCatalog: {
            label: 'My Entire Catalog',
            value: CAMPAIGN_TYPE_ID.MY_ENTIRE_CATALOG,
            description: 'Allow Klickly to advertise all of your available products.',
            CardIcon: Icon.EntireCatalogIcon
        },
        specificProducts: {
            label: 'Specific Products',
            value: CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCTS,
            description: 'Allow Klickly to advertise using 1 or more specific products.',
            CardIcon: Icon.SpecificProductsIcon
        },
        specificCollections,
        specificProductTypes,
        bestSellers: {
            label: 'My Best-sellers',
            value: CAMPAIGN_TYPE_ID.BEST_SELLERS,
            description: 'Allow Klickly to advertise your best sellers.',
            CardIcon: Icon.BestsellersSmallIcon
        }
    };

    if (isBigCommerce || isManual) {
        delete campaigns.specificCollections;
        delete campaigns.specificProductTypes;
    }

    return campaigns;
};

export { useCampaignTypes };
