export const FIELD_NAMES = {
    CUSTOMIZE_UTM: 'customizeUtm',
    CUSTOMIZE_UTM_SOURCE: 'customizeUtmSource',
    CUSTOMIZE_UTM_MEDIUM: 'customizeUtmMedium',
    GOOGLE_ANALYTICS: 'googleAnalytics',
    GOOGLE_ANALYTICS_MEASUREMENT_ID: 'googleAnalyticsMeasurementId',
    GOOGLE_ANALYTICS_VIEWS: 'googleAnalyticsViews',
    GOOGLE_ANALYTICS_CLICKS: 'googleAnalyticsClicks',
    GOOGLE_ANALYTICS_ADD_TO_CART: 'googleAnalyticsAddToCarts',
    GOOGLE_ANALYTICS_PURCHASES: 'googleAnalyticsPurchases',
    TRIPLE_WHALE: 'tripleWhale',
    TRIPLE_WHALE_VIEWS: 'tripleWhaleViews',
    TRIPLE_WHALE_CLICKS: 'tripleWhaleClicks',
    TRIPLE_WHALE_ADD_TO_CART: 'tripleWhaleAddToCarts',
    TRIPLE_WHALE_PURCHASES: 'tripleWhalePurchases',
    TRIPLE_WHALE_NON_LINKED_VIEWS: 'tripleWhaleNonLinkedViews'
};
