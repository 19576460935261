import React from 'react';
import { useStore } from '@hooks';
import { observer } from 'mobx-react';
import { StyledTag, StyledWrap } from './styled';

const TAG_STYLE_MAP = {
    0: 'rejected',
    1: 'pending',
    2: 'approved'
};

const Tags = observer(() => {
    const store = useStore();
    const { unitsWithImages, updateUnitField } = store.customCreativesStore;

    if (!unitsWithImages?.length) return null;

    return (
        <StyledWrap>
            {unitsWithImages.map((unit) => {
                const localData = unit.localData;
                if (localData.imageSrc) {
                    return (
                        <StyledTag
                            key={unit.size}
                            closable
                            onClose={() => updateUnitField(unit.size, 'imageSrc', '')}
                            $style={TAG_STYLE_MAP[localData.validationStatus]}
                        >
                            {unit.size}
                        </StyledTag>
                    );
                }
            })}
        </StyledWrap>
    );
});

export default Tags;
