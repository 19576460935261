import { brandsOpenApi } from '@constants';
import { axiosBoa, errorHandler, getErrorMessage } from '@utils';
import { flow, getParent, types } from 'mobx-state-tree';
import config from '../config';
import { models } from './constants';

export const AuthStore = types
    .model('AuthStore', {
        pending: true,
        oauthPayload: types.maybeNull(types.frozen()),
        embeddedLoadPayload: types.maybeNull(types.frozen()),
        // shows if this bc account has users when installinh in oauth process
        hasUser: types.maybeNull(types.boolean),
        error: types.maybeNull(types.model({ title: types.string, description: types.string })),
        isFormPending: types.maybeNull(types.boolean),
        me: types.optional(
            types.union(
                types.model('MeModel', {
                    id: types.maybeNull(types.number),
                    firstName: types.maybeNull(types.string),
                    lastName: types.maybeNull(types.string),
                    phone: types.maybeNull(types.string),
                    email: types.maybeNull(types.string),
                    currentAccount: types.maybeNull(models.AccountModel),
                    accounts: types.array(
                        types.model({
                            account: models.AccountModel
                        })
                    ),
                    permission: types.maybeNull(
                        types.model({
                            role: types.maybe(types.number),
                            status: types.maybe(types.number)
                        })
                    )
                }),
                types.literal(undefined),
                types.literal(null)
            ),
            undefined
        )
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get currentAccountId() {
            return self.me?.currentAccount?.id;
        },
        get firstName() {
            return self.me?.firstName || '';
        },
        get lastName() {
            return self.me?.lastName || '';
        },
        get companyName() {
            const companyName = self.me?.currentAccount?.companyName;
            const storeName = self.me?.currentAccount?.storeName;
            return companyName || storeName || '';
        },
        get accounts() {
            return self.me?.accounts?.map((item) => item.account) || [];
        },
        get hasAccount() {
            return !!self.me?.accounts?.length;
        },
        get logoUrl() {
            return self.me?.currentAccount?.logoUrl || null;
        }
    }))
    .actions((self) => ({
        setEmbeddedLoadPayload: (payload) => {
            self.embeddedLoadPayload = payload;
        },
        setOauthPayload: (link) => {
            self.oauthPayload = link;
        },
        signIn: flow(function* signIn(values) {
            const response = yield axiosBoa.post(brandsOpenApi.auth.signIn, values);
            yield self.whoAmI();
            return response;
        }),
        logout: flow(function* logout() {
            yield axiosBoa.get(brandsOpenApi.auth.logout);
            self.me = null;
        }),
        signUp: flow(function* signUp(values) {
            self.isFormPending = true;

            try {
                const response = yield axiosBoa.post(brandsOpenApi.auth.signUp, values);
                yield self.whoAmI();
                return response;
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.isFormPending = false;
            }
        }),
        whoAmI: flow(function* whoAmI() {
            try {
                self.me = yield axiosBoa.get(brandsOpenApi.auth.me);
            } catch (error) {
                self.me = null;
            } finally {
                self.pending = false;
            }
        }),
        getInitialData: flow(function* getInitialData() {
            yield self.whoAmI();
            if (self.me?.currentAccount) {
                yield self.root.accountsStore.getSetupInfo();
                yield self.root.accountsStore.getCurrentAccount();
                yield self.root.googleAdsStore.getData();
                if (self.root.accountsStore.isShopify) {
                    yield self.root.integrationsStore.checkScopes();
                }
            }
        }),
        createCustomAccount: flow(function* createCustomAccount(values) {
            self.isFormPending = true;

            try {
                yield axiosBoa.post(brandsOpenApi.auth.createCustomAccount, values);
                yield self.getInitialData();
                return true;
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.isFormPending = false;
            }
        }),
        switchAccount: flow(function* switchAccount(accountId) {
            self.switchPending = true;

            try {
                yield axiosBoa.post(brandsOpenApi.auth.switchAccount, { accountId });
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.switchPending = false;
            }
        }),
        getEcommerceRedirect: flow(function* getEcommerceRedirect(platform, params) {
            self.error = null;
            self.isFormPending = true;

            try {
                const redirect = yield axiosBoa.get(brandsOpenApi.auth.getEcommerceRedirect(platform), { params });
                window.location.replace(redirect.url);
            } catch (error) {
                errorHandler({ error });
            } finally {
                self.isFormPending = false;
            }
        }),
        onConnectUserToAccount: flow(function* onConnectUserToAccount(setLoading) {
            try {
                setLoading(true);
                yield axiosBoa.get(brandsOpenApi.auth.userAccountConnect);

                window.location.replace(config.connectorAppUrl + '/status');
            } catch (error) {
                self.error = getErrorMessage(error);
                setLoading(false);
            }
        }),
        updateAccount: (accountId, data) => {
            const allowedKeys = ['id', 'companyName', 'storeName', 'status', 'logoUrl'];
            const filteredAccount = Object.keys(data)
                .filter((key) => allowedKeys.includes(key))
                .reduce((acc, key) => {
                    acc[key] = data[key];
                    return acc;
                }, {});

            const accountToUser = self.me.accounts.find((a) => a.account.id === accountId);
            if (accountToUser) {
                Object.assign(accountToUser.account, filteredAccount);
            }

            if (self.me.currentAccount.id === accountId) {
                Object.assign(self.me.currentAccount, filteredAccount);
            }
        },
        checkAccountUser: flow(function* checkAccountUser(params) {
            try {
                const response = yield axiosBoa.get(brandsOpenApi.auth.checkAccountUser, {
                    params: {
                        ...params,
                        externalId: self.oauthPayload?.context?.split('/')[1] || null
                    }
                });
                self.hasUser = response.hasUser;
                return response.hasUser;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        })
    }));
