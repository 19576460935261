export const FIELD_NAMES = {
    BILLING_LIST: 'billingList',
    COLD_CUSTOMER_COMMISSION: 'coldCustomer',
    NEW_CUSTOMER_COMMISSION: 'newCustomer',
    REST_OF_SITE_COMMISSION: 'restOfSite',
    TEAM_MEMBERS: 'teamMembers'
};

export const FIELD_NAMES_ADD_MEMBER = {
    FIRSTNAME: 'firstName',
    LASTNAME: 'lastName',
    EMAIL: 'email',
    ACCESS: 'role'
};

export const FIELD_NAMES_EDIT_MEMBER = {
    FIRSTNAME: 'firstName',
    LASTNAME: 'lastName',
    EMAIL: 'email',
    ACCESS: 'role'
};
