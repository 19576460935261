import React from 'react';
import { Col, Row } from 'antd';
import Welcome from './components/Welcome';

const HeadSection = () => {
    return (
        <Row>
            <Col span={12}>
                <Welcome />
            </Col>
        </Row>
    );
};

export default HeadSection;
