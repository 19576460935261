import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BOTTOM_INDENTS, COLORS, ROUTE_PATHS, TOP_INDENTS } from '@constants';
import { ACCOUNT_USER_ROLE, NOTIFICATION_ACTIONS } from '@enums';
import { useStore } from '@hooks';
import { Button, Loader, Typography } from '@ui';
import { Col, Divider, Flex, Form, Row } from 'antd';
import * as upperFirst from 'lodash/upperFirst';
import { observer } from 'mobx-react';
import { UNSUBSCRIBE_EXAMPLES } from './constants';
import { StyledCheckboxGroup, StyledDivider, StyledWrapper } from './styled';

const Unsubscribe = observer(() => {
    const [complete, setComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const store = useStore();
    const { token } = useParams();
    const { unsubscribeList, getUnsubscribeList, updateContacts, getInitValuesUnsubscribe } = store.accountsStore;

    const notificationCategories = Object.entries(NOTIFICATION_ACTIONS).map(([key, value]) => {
        const category = key.toLowerCase();
        return {
            label: `'${upperFirst(category)}' ${UNSUBSCRIBE_EXAMPLES[category] || ''}`,
            value: value,
            disabled: false
        };
    });

    useEffect(() => {
        (async () => {
            await getUnsubscribeList(token);
        })();
    }, []);

    const saveContacts = async (values) => {
        setLoading(true);
        await updateContacts({ token, data: values });
        setLoading(false);
        setComplete(true);
    };

    if (!unsubscribeList.length && loading) {
        return <Loader />;
    }

    return (
        <StyledWrapper>
            <Col style={TOP_INDENTS.XL_PLUS} span={24}>
                <Row align='middle'>
                    <Col span={24}>
                        <Typography.Title align='center' level={1}>
                            We&apos;re sad to see you go!
                        </Typography.Title>
                    </Col>
                </Row>
            </Col>
            <StyledDivider />
            <Col span={24} style={BOTTOM_INDENTS.XL_PLUS}>
                <Row justify='center'>
                    <Col md={16}>
                        <Typography.Title align='center' level={3}>
                            It&apos;s not the same without you... We hate goodbyes, but if you change your mind, you
                            know where you can find us.
                        </Typography.Title>
                    </Col>
                </Row>
            </Col>
            {complete ? (
                <Flex gap={25} vertical justify='center' align='center'>
                    <Typography.Title level={2}>You’ve been successfully unsubscribed!</Typography.Title>
                    <Button onClick={() => navigate(ROUTE_PATHS.HOME)} type='primary'>
                        Go to dashboard
                    </Button>
                </Flex>
            ) : (
                <Flex vertical justify='center'>
                    {unsubscribeList.length ? (
                        <React.Fragment>
                            <Flex style={BOTTOM_INDENTS.M}>
                                <Typography.Text color={COLORS.$gray60Black}>
                                    You have the following subscriptions. Uncheck to unsubscribe.
                                </Typography.Text>
                            </Flex>
                            <Form onFinish={saveContacts} initialValues={getInitValuesUnsubscribe()}>
                                {unsubscribeList?.map((account) => {
                                    const accountNotificationCategories = notificationCategories.map(
                                        (notification) => ({
                                            ...notification,
                                            disabled:
                                                account.role === ACCOUNT_USER_ROLE.OWNER &&
                                                notification.value === NOTIFICATION_ACTIONS.ACCOUNT
                                        })
                                    );
                                    return (
                                        <React.Fragment key={account.contactId}>
                                            <Form.Item name={account.contactId}>
                                                <StyledCheckboxGroup
                                                    options={accountNotificationCategories}
                                                    onChange={(checkedValues) => {
                                                        form.setFieldsValue({
                                                            [account.contactId]: checkedValues
                                                        });
                                                    }}
                                                />
                                            </Form.Item>
                                            <Divider />
                                        </React.Fragment>
                                    );
                                })}
                                <Flex style={BOTTOM_INDENTS.M}>
                                    <Typography.Text color={COLORS.$gray60Black}>
                                        Note: you can’t unsubscribe from some of the emails (i.e. ‘Accounts’ if they
                                        attached to account owner.)
                                    </Typography.Text>
                                </Flex>
                                <Flex justify='center'>
                                    <Button loading={loading} htmlType='submit' type='primary'>
                                        Unsubscribe
                                    </Button>
                                </Flex>
                            </Form>
                        </React.Fragment>
                    ) : (
                        <Flex justify='center' style={BOTTOM_INDENTS.M}>
                            <Typography.Text color={COLORS.$gray60Black}>
                                You don&apos;t have any subscriptions
                            </Typography.Text>
                        </Flex>
                    )}
                </Flex>
            )}
        </StyledWrapper>
    );
});

export default Unsubscribe;
