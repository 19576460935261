import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    height: 56px;
    margin-bottom: 4px;
    padding: 6px 16px;
    border: 1px solid ${COLORS.$gray20Black};
    border-radius: 8px;
    background: ${COLORS.$white};
`;
