import React from 'react';
import { CAMPAIGN_STATUS, CAMPAIGN_STATUS_LABEL_BY_ID } from '@enums';
import { useStore } from '@hooks';
import { Tag } from '@ui';
import { observer } from 'mobx-react';
import { getCampaignStatusColor } from '../../utils';

const Status = observer(({ campaignId }) => {
    const store = useStore();
    const { getCampaign } = store.campaignsStore;
    const campaign = getCampaign(campaignId);

    const color = campaign.refetching ? getCampaignStatusColor() : getCampaignStatusColor(campaign.status);

    const label = campaign.refetching
        ? CAMPAIGN_STATUS_LABEL_BY_ID[CAMPAIGN_STATUS.PENDING]
        : CAMPAIGN_STATUS_LABEL_BY_ID[campaign.status];

    return (
        <Tag data-e2e={`campaign_${campaignId}-status_value`} color={color}>
            {label}
        </Tag>
    );
});

export default Status;
