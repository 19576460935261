import { STRIPE_ACCOUNT_STATUS } from '../../../../../../../constants/stripe';
import Complete from '../components/Complete';
import CompleteDisabled from '../components/CompleteDisabled';
import Incomplete from '../components/Incomplete';
import NoAccount from '../components/NoAccount';
import StandardComplete from '../components/StandardComplete';
import StandardIncomplete from '../components/StandardIncomplete';

export const STRIPE_STATUS_COMPONENT = {
    [STRIPE_ACCOUNT_STATUS.COMPLETE]: Complete,
    [STRIPE_ACCOUNT_STATUS.COMPLETE_DISABLED]: CompleteDisabled,
    [STRIPE_ACCOUNT_STATUS.INCOMPLETE]: Incomplete,
    [STRIPE_ACCOUNT_STATUS.STANDARD_COMPLETE]: StandardComplete,
    [STRIPE_ACCOUNT_STATUS.STANDARD_INCOMPLETE]: StandardIncomplete,
    [STRIPE_ACCOUNT_STATUS.NO_ACCOUNT]: NoAccount
};
