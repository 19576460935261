import React, { useState } from 'react';
import { ACCOUNT_USER_STATUS } from '@enums';
import { useStore } from '@hooks';
import { Button, WhiteCover } from '@ui';
import { Flex, Form, notification } from 'antd';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import AddMemberForm from './components/AddMemberForm';
import TeamMember from './components/TeamMember';

const TeamAccounts = observer(({ style = {} }) => {
    const store = useStore();
    const [form] = Form.useForm();

    const [isAddMemberOpen, setIsAddMemberOpen] = useState(false);
    const { uiTeamMembers, removeUIMember, currentAccount, addUIMember } = store.accountsStore;

    const onOpenAddTeamMember = () => {
        setIsAddMemberOpen(true);
    };

    const onCloseAddTeamMember = () => {
        setIsAddMemberOpen(false);
    };

    const onAddTeamMember = ({ role, ...user }) => {
        const teamMember = {
            tempId: uuidv4(),
            status: ACCOUNT_USER_STATUS.PENDING,
            role,
            user: { ...user, currentAccountId: currentAccount.id }
        };
        try {
            addUIMember(teamMember);
        } catch (error) {
            notification.error({ message: 'Something went wrong', description: error.message });
        } finally {
            setIsAddMemberOpen(false);
        }
    };

    return (
        <WhiteCover
            data-e2e='account-management_team-member_section'
            style={{ ...style }}
            title='Team Accounts'
            rightContent={
                <Button data-e2e='account-management_add-team-member_btn' type='link' onClick={onOpenAddTeamMember}>
                    Add team member
                </Button>
            }
        >
            {isAddMemberOpen && <AddMemberForm form={form} onFinish={onAddTeamMember} onClose={onCloseAddTeamMember} />}

            <Flex vertical gap={24}>
                {uiTeamMembers.map((member) => {
                    return (
                        <TeamMember
                            key={member.id || member.tempId}
                            member={member}
                            onDelete={() => {
                                removeUIMember(member);
                            }}
                        />
                    );
                })}
            </Flex>
        </WhiteCover>
    );
});

export default TeamAccounts;
