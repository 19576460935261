import React, { useRef, useState } from 'react';
import { COLORS } from '@constants';
import { Button, Icon, Loader, Typography } from '@ui';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { observer } from 'mobx-react';
import { useStore } from '../../hooks';
import LogoView from './components/LogoView';
import { StyledCloseIcon, StyledWrapper } from './styled';
import { handlePreview } from './utils';

export const CompanyLogo = observer(({ size = 130, ...props }) => {
    const store = useStore();
    const ref = useRef(null);
    const { updateAccount, getCurrentAccount } = store.accountsStore;
    const [loading, setLoading] = useState(false);

    return (
        <StyledWrapper data-e2e='account-settings_company-logo_section' $size={size} ref={ref} {...props}>
            <ImgCrop
                modalProps={{
                    getContainer: () => ref?.current,
                    title: <Typography.Title level={2}>Change logo</Typography.Title>,
                    closeIcon: (
                        <StyledCloseIcon
                            size='24px'
                            component={() => <Icon.CloseIcon />}
                            data-e2e='account-settings_logo-modal-close_btn'
                        />
                    )
                }}
                modalTitle={
                    <Typography.Title data-e2e='account-settings_logo-modal_title' level={2}>
                        Change logo
                    </Typography.Title>
                }
                modalWidth={560}
                cropShape='round'
                modalOk='Save'
            >
                <Upload
                    accept='.png, .jpg, .jpeg'
                    listType='picture-circle'
                    onPreview={handlePreview}
                    maxCount={1}
                    showUploadList={false}
                    customRequest={async ({ file }) => {
                        setLoading(true);
                        await updateAccount({ logo: file });
                        await getCurrentAccount();
                        setLoading(false);
                    }}
                >
                    {loading && <Loader />}
                    <LogoView size={size} />
                    <Button data-e2e='account-settings_change-logo_btn' type='noStyle' padding='8px'>
                        <Icon color={COLORS.$gray60Black} size='24px' component={() => <Icon.EditIcon />} />
                    </Button>
                </Upload>
            </ImgCrop>
        </StyledWrapper>
    );
});
