export const getImageFromUploadedFile = (file) => {
    if (Array.isArray(file) && file?.length > 0 && file?.every((f) => f)) {
        return URL.createObjectURL(file?.[0]?.originFileObj);
    }

    if (typeof file === 'string') {
        return file;
    }
    return null;
};
