import React from 'react';
import { COLORS } from '@constants';
import { StyledButton, StyledWrapper } from './styled';

const SortingBlock = ({ activeSortState, onAscClick, onDescClick }) => {
    return (
        <StyledWrapper>
            <StyledButton
                data-e2e='insights_descending_btn'
                color={activeSortState === 'desc' ? COLORS.$gray100Black : COLORS.$gray40Black}
                type='link'
                onClick={onDescClick}
            >
                Descending
            </StyledButton>
            <StyledButton
                data-e2e='insights_ascending_btn'
                color={activeSortState === 'asc' ? COLORS.$gray100Black : COLORS.$gray40Black}
                type='link'
                onClick={onAscClick}
            >
                Ascending
            </StyledButton>
        </StyledWrapper>
    );
};

export default SortingBlock;
