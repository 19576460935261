import React from 'react';
import { ROUTE_PATHS } from '@constants';
import { RedirectLink } from '@ui';

export const LoginButton = () => {
    return (
        <RedirectLink
            data-e2e='signup_header-signin_link'
            fontSize='16px'
            text='Sign in'
            align='right'
            to={ROUTE_PATHS.LOGIN}
        />
    );
};
