import React from 'react';
import Label from '../Label';
import { StyledInputPassword } from './styled';

const InputPassword = ({ label, noPrefix, ...props }) => {
    return (
        <Label label={label} noPrefix={noPrefix}>
            <StyledInputPassword placeholder={label} {...props} />
        </Label>
    );
};

export default InputPassword;
