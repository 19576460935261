import React, { useEffect, useState } from 'react';
import { dayjs } from '@utils';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import { StyledRangePicker } from './styled';

export const RangePicker = ({ onChange = () => null, ...props }) => {
    const [open, setOpen] = useState(false);

    const handleChange = (result) => {
        return onChange({
            min: result?.[0].startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
            max: result?.[1].endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        });
    };

    useEffect(() => {
        document.querySelectorAll('.ant-picker-input input').forEach((element, index) => {
            element.setAttribute('data-e2e', `datepicker_${index === 0 ? 'start' : 'end'}-date_input`);
        });

        if (open) {
            const datePickerDropdown = document.querySelector('.ant-picker-dropdown');
            if (datePickerDropdown) {
                datePickerDropdown.setAttribute('data-e2e', 'datepicker_popup');
            }
        }
    }, [open]);

    return (
        <StyledRangePicker
            className='range-picker'
            getPopupContainer={() => document.querySelector('.range-picker')}
            allowClear={{ clearIcon: <Icon.CloseIcon width='24px' height='24px' data-e2e='datepicker_clear_icon' /> }}
            presets={[
                {
                    label: (
                        <Typography.Text type='badgeLarge' data-e2e='datepicker_quick-select_title'>
                            Quick select
                        </Typography.Text>
                    )
                },
                {
                    label: <span data-e2e='datepicker_last-7-days_select'>Last 7 days</span>,
                    value: [dayjs().subtract(7, 'd'), dayjs()]
                },
                {
                    label: <span data-e2e='datepicker_last-30-days_select'>Last 30 days</span>,
                    value: [dayjs().subtract(30, 'd'), dayjs()]
                },
                {
                    label: <span data-e2e='datepicker_week-to-date_select'>Week-to-date</span>,
                    value: [dayjs().weekday(1), dayjs()]
                },
                {
                    label: <span data-e2e='datepicker_month-to-date_select'>Month-to-date</span>,
                    value: [dayjs().startOf('month'), dayjs()]
                },
                {
                    label: <span data-e2e='datepicker_year-to-date_select'>Year-to-date</span>,
                    value: [dayjs().startOf('year'), dayjs()]
                }
            ]}
            suffixIcon={<Icon.DatePickerIcon width='24px' height='24px' data-e2e='datepicker_open_icon' />}
            separator=':'
            onOpenChange={setOpen}
            onChange={handleChange}
            {...props}
        />
    );
};
