import { brandsOpenApi } from '@constants';
import { axiosBoa, getErrorMessage } from '@utils';
import { flow, getParent, types } from 'mobx-state-tree';

export const IntegrationsStore = types
    .model('IntegrationsStore', {
        rechargeToken: types.maybe(
            types.model({ id: types.number, accessToken: types.string, isActive: types.boolean })
        ),
        loopToken: types.maybeNull(types.model({ accessToken: types.string })),
        rechargeRedirectUrl: types.maybe(types.string),
        accountHasAllScopes: types.maybe(types.boolean)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get isRechargeConnected() {
            return !!self.rechargeToken?.isActive;
        },
        get isLoopConnected() {
            return !!self.loopToken?.accessToken;
        },
        get loopAccessToken() {
            return self.loopToken?.accessToken;
        }
    }))
    .actions((self) => ({
        getRechargeRedirect: flow(function* getRechargeRedirect() {
            const { url } = yield axiosBoa.get(brandsOpenApi.recharge.getRedirectUrl);
            self.rechargeRedirectUrl = url;
        }),
        getRechargeToken: flow(function* getRechargeToken() {
            const { rechargeToken } = yield axiosBoa.get(brandsOpenApi.accounts.getCurrent, {
                params: {
                    select: 'recharge_token'
                }
            });
            self.rechargeToken = rechargeToken || undefined;
        }),
        getLoopToken: flow(function* getLoopToken() {
            const { loopToken } = yield axiosBoa.get(brandsOpenApi.accounts.getCurrent, {
                params: {
                    select: 'loop_token'
                }
            });
            self.loopToken = loopToken;
        }),
        handleLoopToken: function handleLoopToken(value) {
            if (value) {
                return this.updateLoopToken(value);
            }

            return this.deleteLoopToken();
        },
        updateLoopToken: flow(function* updateLoopToken(value) {
            try {
                const accessToken = yield axiosBoa.post(brandsOpenApi.loop.setup, {
                    accessToken: value
                });

                self.loopToken = { accessToken };
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        deleteLoopToken: flow(function* deleteLoopToken() {
            try {
                yield axiosBoa.delete(brandsOpenApi.loop.delete);

                self.loopToken = null;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        checkScopes: flow(function* checkScopes() {
            const { hasAllScopes } = yield axiosBoa.get(brandsOpenApi.accounts.getCurrent, {
                params: {
                    select: 'has_all_scopes,id'
                }
            });
            self.accountHasAllScopes = hasAllScopes;
        })
    }));
