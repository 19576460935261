import React from 'react';
import nextId from 'react-id-generator';
import { Divider, Radio, Typography } from '@ui';
import { formatString, oldFormatter } from '@utils';
import { Col, Form, Row } from 'antd';
import { NotSelectedRadioStyles, SelectedRadioStyles } from './styled';

// TODO make cleaner solution for radios, maybe map radios, make cleaner styles, make cleaner BoostRadio component

const BoostRadio = ({ label, price, views, name, ...props }) => {
    const form = Form.useFormInstance();

    if (form.getFieldValue(name) === props.value) {
        return (
            <SelectedRadioStyles key={nextId()}>
                <Typography.Title data-e2e={`boosts_${formatString(label)}_title`} level={3}>
                    {label}
                </Typography.Title>
                <Divider margin='16px 0' />
                <Row justify='space-between' align='middle' gutter={16}>
                    <Col span={12}>
                        <Typography.Text data-e2e={`boosts_${formatString(label)}_price`} type='badgeMedium'>
                            {`$${price}`}/wk
                        </Typography.Text>
                    </Col>
                    <Col span={12}>
                        <Typography.Text
                            data-e2e={`boosts_${formatString(label)}_views`}
                            type='descriptor2'
                            align='right'
                        >
                            {oldFormatter(views, { thousand: true })} est. views
                        </Typography.Text>
                    </Col>
                </Row>
                <Radio {...props} value={null} />
            </SelectedRadioStyles>
        );
    }
    return (
        <NotSelectedRadioStyles
            key={nextId()}
            onClick={() => {
                if (form.getFieldValue(name) === props.value) {
                    form.setFieldValue(name, null);
                }
            }}
        >
            <Typography.Title data-e2e={`boosts_${formatString(label)}_title`} level={3}>
                {label}
            </Typography.Title>
            <Divider margin='16px 0' />
            <Row justify='space-between' align='middle' gutter={16}>
                <Col span={12}>
                    <Typography.Text data-e2e={`boosts_${formatString(label)}_price`} type='badgeMedium'>
                        {`$${price}`}/wk
                    </Typography.Text>
                </Col>
                <Col span={12}>
                    <Typography.Text data-e2e={`boosts_${formatString(label)}_views`} type='descriptor2' align='right'>
                        {oldFormatter(views, { thousand: true })} est. views
                    </Typography.Text>
                </Col>
            </Row>
            <Radio {...props} />
        </NotSelectedRadioStyles>
    );
};

export default BoostRadio;
