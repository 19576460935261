import styled from 'styled-components';

export const StyledWrapper = styled.div`
    padding: 24px;
    min-width: 272px;
`;

export const StyledDropdownWrapper = styled.div`
    margin-bottom: 16px;
`;
