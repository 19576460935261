import { COLORS } from '@constants';
import { Flex } from 'antd';
import styled from 'styled-components';

export const StyledMenuItem = styled(Flex)`
    cursor: pointer;

    &:hover {
        p {
            color: ${COLORS.$gray100Black};
        }
    }
`;
