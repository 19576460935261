import { CloseOutlined } from '@ant-design/icons';
import { COLORS } from '@constants';
import { Button } from 'antd';
import styled from 'styled-components';
import { SupportIcon } from '../../../ui/Icon/constants';

export const StyledSupportIcon = styled(SupportIcon)`
    transform: rotate(90deg);
`;

export const StyledCloseOutlined = styled(CloseOutlined)`
    font-size: 24px !important;
`;

export const StyledVerticalButton = styled(Button)`
    height: 56px;
    width: 131px;
    padding: 16px 0;
    border-radius: 8px 8px 0 0;
    background: linear-gradient(${COLORS.$pink} 0%, ${COLORS.$pinkLight} 100%);
    border: 2px solid #da1781;
    color: ${COLORS.$white};
    position: fixed;
    right: -38px;
    top: 166px;
    transform: rotate(-90deg);
    cursor: pointer;
    z-index: 1001;
    transition: background-color 0s, color 0s !important;

    span {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-weight: 500;
        font-size: 16px;
    }

    &:hover {
        border: 2px solid #da1781 !important;
        background: ${COLORS.$pinkDark} !important;
        color: ${COLORS.$white} !important;
    }
`;
