import React from 'react';
import { Divider } from '@ui';
import { Flex } from 'antd';
import MenuActionItem from '../MenuActionItem';

const MenuActionItems = ({ items }) => {
    return (
        <Flex data-e2e='header_user-dropdown-menu_action-items' component='ul' vertical style={{ width: 224 }}>
            {items.map((item, i) => {
                return (
                    <React.Fragment key={i}>
                        <MenuActionItem item={item} />
                        {i !== items.length - 1 && <Divider margin='0' />}
                    </React.Fragment>
                );
            })}
        </Flex>
    );
};

export default MenuActionItems;
