import React from 'react';
import { COLORS } from '@constants';
import { Icon, Typography } from '@ui';
import { formatString } from '@utils';
import { StyledMenuItem } from './styled';

const MenuActionItem = ({ item }) => {
    return (
        <StyledMenuItem gap={16} style={{ paddingBlock: 12 }} onClick={item.onClick} align='center'>
            <Icon size='16px' color={COLORS.$gray40Black} component={() => item.icon} />
            <Typography.Text
                data-e2e={`header_user-dropdown-menu_${formatString(item.label)}_item`}
                color={COLORS.$gray60Black}
                type='descriptor1'
            >
                {item.label}
            </Typography.Text>
        </StyledMenuItem>
    );
};

export default MenuActionItem;
