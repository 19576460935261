import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { COLORS } from '@constants';
import { Button } from '@ui';
import { observer } from 'mobx-react';
import { useCampaignWizard } from '../../hooks/useCampaignWizard';

const PrevStepButton = observer(() => {
    const navigate = useNavigate();
    const { isCreate, isLaunchCheck, campaignId, step } = useCampaignWizard();

    let prevStep;

    if (isLaunchCheck) prevStep = 5;
    if (isCreate && step > 1) prevStep = step - 1;

    const isVisible = (isCreate && step > 1) || isLaunchCheck;

    const path = step === 2 ? `campaigns/create/step-${prevStep}` : `campaigns/create/step-${prevStep}/${campaignId}`;

    const onBackClick = () => {
        navigate({
            pathname: path,
            search: step === 2 ? createSearchParams({ fromCampaign: campaignId }).toString() : undefined
        });
    };

    if (!isVisible) return null;

    return (
        <Button
            data-e2e='campaignwizard_prev-step_btn'
            style={{ width: 134 }}
            onClick={onBackClick}
            backgroundColor={COLORS.$white}
        >
            Back
        </Button>
    );
});

export default PrevStepButton;
