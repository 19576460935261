import { COLORS } from '@constants';
import styled, { css } from 'styled-components';

export const StyledLabel = styled.div`
    position: relative;

    label {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 3;
        border: 2px solid transparent;
        padding: 16px;
        color: ${COLORS.$gray40Black};
        font-size: 16px;
        pointer-events: none;
        touch-action: none;
        transition: all 0.2s ease-in-out;
    }

    :has(.ant-select-selection-item) > label,
    :has(.ant-select-focused) > label,
    :has(.ant-tag) > label
    {
        top: -13px;
        font-size: 12px;
        transition: all 0.2s ease-in-out;

        ${({ $isMultiple }) =>
            $isMultiple &&
            css`
                color: transparent;
            `})};
    }

    &:has(.ant-input-prefix) > label {
        left: 40px;
    }

    ${({ $hasPrefix }) =>
        $hasPrefix &&
        css`
            label {
                left: 40px;
            }
        `};
`;
