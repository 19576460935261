import React from 'react';
import { COLORS } from '@constants';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import { StyledContent, StyledHeader, StyledWrapper } from './styled';

export const ModalContainer = ({ title = '', children, onClose, addStyle = {} }) => {
    return (
        <StyledWrapper>
            <StyledHeader $marginBottom={addStyle.marginBottom} $borderBottom={addStyle.borderBottom}>
                <Typography.Title level={2}>{title}</Typography.Title>
                <Button type='noStyle' padding='8px' onClick={onClose}>
                    <Icon size='24px' color={COLORS.$gray60Black} component={() => <Icon.CloseIcon />} />
                </Button>
            </StyledHeader>
            <StyledContent>{children}</StyledContent>
        </StyledWrapper>
    );
};
