import React from 'react';
import BaseInput from '../BaseInput';
import { formatInputValue } from './utils';

const InputDate = ({ value, onChange, ...props }) => {
    return (
        <BaseInput
            value={formatInputValue(value)}
            onChange={({ target: { value: v } }) => onChange(v.replace(/\D/g, '').substr(0, 10))}
            {...props}
        />
    );
};

export default InputDate;
