import { FONTS } from '../../../constants';

export const getFontByThemeAndLevel = (level) => {
    switch (level) {
        default:
            return FONTS.headline1;
        case 2:
            return FONTS.headline2;
        case 3:
            return FONTS.headline3;
        case 0:
            return FONTS.em0;
    }
};
