import { ACCOUNT_STATUS, CONNECTOR_KLICKLY_STATUS } from '@enums';
import { useStore } from './useStore';

export const useConnector = () => {
    const store = useStore();

    const { currentAccount } = store.accountsStore;

    const connectedToShopify = currentAccount?.status !== ACCOUNT_STATUS.DISCONNECTED;
    const connectedToKlickly = currentAccount?.connectorStatus === CONNECTOR_KLICKLY_STATUS.CONNECTED;
    const connected = connectedToShopify && connectedToKlickly;

    return { connectedToShopify, connectedToKlickly, connected };
};
