import React, { Fragment } from 'react';
import { useStore } from '@hooks';
import { Icon } from '@ui';
import { observer } from 'mobx-react';
import { INVOICE_STATUS, INVOICE_STATUS_LABELS } from '../../../../constants';
import { StyledIcon, StyledTag } from './styled';

const Status = observer(({ status, record }) => {
    const store = useStore();
    const { retryFailedInvoiceCharge, retryChargeSubmitted } = store.invoicesStore;

    const isFailed = status === INVOICE_STATUS.FAILED;
    const clickable = isFailed && !retryChargeSubmitted;

    const handleTagClick = () => {
        if (clickable) {
            retryFailedInvoiceCharge({ invoiceId: record.id });
        }
    };

    return (
        <Fragment>
            <StyledTag
                color={INVOICE_STATUS_LABELS[status].color}
                data-e2e={`invoices_charge-${record.id}-status_value`}
                onClick={handleTagClick}
                $clickable={isFailed && !retryChargeSubmitted}
            >
                {INVOICE_STATUS_LABELS[status].title}
                {isFailed ? (
                    <StyledIcon
                        size='16px'
                        color='canvas'
                        component={() => <Icon.ResetIcon />}
                        $rotate={retryChargeSubmitted}
                    />
                ) : null}
            </StyledTag>
        </Fragment>
    );
});

export default Status;
