import styled from 'styled-components';

export const StyledGetStarted = styled.div`
    position: relative;
    padding-bottom: 128px;
    overflow: hidden;

    @media (max-width: 900px) {
        padding-bottom: 96px;
    }

    &.bg-gradient {
        &:before {
            clip-path: polygon(0px 0px, 100% 150px, 100% 100%, 0px 100%);

            @media (max-width: 900px) {
                top: 70px;
                height: calc(100% - 70px);
                clip-path: polygon(0px 0px, 100% 30px, 100% 100%, 0px 100%);
            }
        }
    }

    .get-started__wrapper {
        z-index: 1;
        position: relative;
        display: flex;
        align-items: center;
        gap: 48px;

        @media (max-width: 900px) {
            flex-direction: column;
            gap: 5px;
        }

        &__image {
            width: 100%;

            @media (max-width: 900px) {
                order: -1;
                display: flex;
                justify-content: center;
            }

            @media (max-width: 500px) {
                order: -1;
                display: flex;
                justify-content: flex-start;
            }

            img {
                width: 527px;
                height: 322px;
            }
        }

        &__content {
            h3 {
                font-size: 64px;
                font-style: normal;
                font-weight: 700;
                line-height: 64px;
                margin-bottom: 16px;
                color: #04080f;
                font-family: 'IBM Plex Sans', Helvetica, sans-serif;
            }

            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                color: #04080f;
                font-family: 'IBM Plex Sans', Helvetica, sans-serif;
                margin-bottom: 16px;
            }
        }
    }
`;
