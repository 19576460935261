import { COLORS } from '@constants';
import { DatePicker } from 'antd';
import styled from 'styled-components';

export const StyledRangePicker = styled(DatePicker.RangePicker)`
    && {
        border-width: 2px;

        .ant-picker-range-separator {
            padding: 0 5px;
        }

        .ant-picker-input {
            display: flex;
            justify-content: center;

            input {
                width: 100px;
                text-align: center;
                font-weight: 500;
                font-size: 16px;
            }
        }

        .ant-picker-active-bar {
            display: none;
        }

        /* Range Picker */
        .ant-picker-panel-layout {
            padding: 24px;
            gap: 16px;

            .ant-picker-panels {
                gap: 16px;

                .ant-picker-panel {
                    border-radius: 8px;
                    background: ${COLORS.$gray10Black};
                }
            }
        }

        .ant-picker-dropdown {
            .ant-picker-header {
                border-bottom: 0;

                .ant-picker-header-view {
                    button {
                        font-size: 20px;
                        font-weight: 500;

                        &.ant-picker-year-btn {
                            margin-inline-start: 5px;
                        }
                    }
                }
            }

            .ant-picker-presets {
                order: 3;

                ul {
                    border-inline-end: 0;
                    padding-top: 0;

                    li {
                        text-align: center;
                        border-bottom: 1px solid ${COLORS.$gray15Black};
                        color: ${COLORS.$gray60Black};
                        padding-inline: 30px;

                        &:first-of-type {
                            pointer-events: none;
                            padding: 22px 0;
                            display: flex;
                            justify-content: center;

                            &:hover {
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }

        .ant-picker-date-panel {
            width: auto;

            .ant-picker-body {
                padding: 0;
            }

            .ant-picker-content {
                width: auto;

                .ant-picker-cell {
                    .ant-picker-cell-inner {
                        font-weight: 500;
                    }

                    &:not(.ant-picker-cell-in-view) {
                        opacity: 0;
                    }
                }

                tbody {
                    tr {
                        border-top: 1px solid ${COLORS.$gray15Black};
                    }
                }

                tr {
                    th {
                        width: auto;
                        font-size: 12px;
                        color: ${COLORS.$gray40Black};
                    }
                }
            }
        }
    }
`;
