import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FIELD_NAME, ROUTE_PATHS, TOP_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Tabs, Typography } from '@ui';
import { getImageFromUploadedFile, getPickerColor } from '@utils';
import { Flex, Form, Layout } from 'antd';
import { observer } from 'mobx-react';
import NextButton from '../../components/NextStepButton';
import Preview from '../../components/Preview';
import { useFormInit } from '../../form';
import Step4Content from '../Step4/components/Content';
import Step5Content from '../Step5/components/Content';
const { Content, Sider } = Layout;

const EditCampaign = observer(() => {
    const navigate = useNavigate();
    const params = useParams();

    const store = useStore();
    const {
        campaign,
        accountProductsIds,
        priorityPending,
        syncSelectionWithServer,
        updateCampaign,
        submitCampaign,
        previewProductsPending,
        productsSelectionPending,
        creativeSettingsUpdate,
        formSubmitting,
        setFormSubmitting,
        setIsNextDisabled,
        setIsNextLoading,
        updatePriority
    } = store.campaignWizardStore;

    const { updateData: updateGoogleAds } = store.googleAdsStore;

    const { getCampaignCommissions } = store.campaignsStore;

    const form = useFormInit();

    const font = Form.useWatch(FIELD_NAME.CREATIVE.FONT, form);
    const primaryColor = Form.useWatch(FIELD_NAME.CREATIVE.PRIMARY_COLOR, form);
    const secondaryColor = Form.useWatch(FIELD_NAME.CREATIVE.SECONDARY_COLOR, form);
    const offerMessage = Form.useWatch(FIELD_NAME.CREATIVE.OFFER_MESSAGE, form);
    const imageFormat = Form.useWatch(FIELD_NAME.CREATIVE.PRODUCT_IMAGES_FORMAT, form);
    Form.useWatch(FIELD_NAME.CAMPAIGN.NAME, form);
    const logo = campaign.creatives[0].logoUrl;

    const nextDisabled = !campaign?.productsCount || !form.getFieldValue(FIELD_NAME.CAMPAIGN.NAME) || !logo;

    const nextLoading = priorityPending || previewProductsPending || productsSelectionPending || formSubmitting;

    useEffect(() => {
        setIsNextLoading(nextLoading);
    }, [nextLoading]);

    useEffect(() => {
        setIsNextDisabled(nextDisabled);
    }, [nextDisabled]);

    const handleFinish = async ({
        title,
        file,
        newCustomerCommission,
        coldCustomerCommission,
        restOfSiteCommission,
        campaignCommission,
        boostId,
        ...values
    }) => {
        setFormSubmitting(true);
        await syncSelectionWithServer();
        await updateGoogleAds(values);
        // TODO change back end logic in order to improve this place (auto recalculate priority after boost or commission changed)
        await updateCampaign({ values: { title, boostId, commission: campaignCommission } });
        await getCampaignCommissions();
        await updatePriority({
            values: { newCustomerCommission, coldCustomerCommission, restOfSiteCommission, campaignCommission, boostId }
        });
        await store.customCreativesStore.updateCustomCreatives(params?.id);

        await creativeSettingsUpdate({ values });
        const { ready } = await submitCampaign();

        setFormSubmitting(false);

        if (ready) {
            navigate(ROUTE_PATHS.CAMPAIGNS);
        } else {
            navigate(`${ROUTE_PATHS.LAUNCH_CHECK}/${params?.id}`);
        }
    };

    const items = [
        {
            key: 1,
            label: (
                <Typography.Text data-e2e='campaign-edit_performance-settings_tab' align='center' type='badgeMedium'>
                    Performance settings
                </Typography.Text>
            ),
            children: <Step5Content />
        },
        {
            key: 2,
            label: (
                <Typography.Text data-e2e='campaign-edit_creative-settings_tab' align='center' type='badgeMedium'>
                    Creative settings
                </Typography.Text>
            ),
            children: <Step4Content />
        }
    ];

    return (
        <React.Fragment>
            <Layout hasSider style={{ justifyContent: 'space-between', gap: 40 }}>
                <Content tagName='div' style={{ maxWidth: 867 }}>
                    <Form form={form} onFinish={handleFinish}>
                        <Tabs items={items} fullWidth tabBarStyle={{ marginBottom: 24 }} />
                        <Form.Item name={FIELD_NAME.CREATIVE.FONT} noStyle />
                        <Form.Item name={FIELD_NAME.CREATIVE.PRIMARY_COLOR} noStyle />
                        <Form.Item name={FIELD_NAME.CREATIVE.OFFER_MESSAGE} noStyle />
                        <Form.Item name={FIELD_NAME.CREATIVE.PRODUCT_IMAGES_FORMAT} noStyle />
                    </Form>
                </Content>
                <Sider width='300px' style={{ backgroundColor: 'unset' }}>
                    <Preview
                        font={font}
                        primaryColor={getPickerColor(primaryColor)}
                        secondaryColor={getPickerColor(secondaryColor)}
                        logo={getImageFromUploadedFile(logo)}
                        customSelectedProducts={accountProductsIds}
                        offerMessage={offerMessage}
                        imageFormat={imageFormat}
                        brandLink={store.accountsStore?.currentAccount?.domains?.[0]?.shopDomain}
                        brandName={store.accountsStore?.currentAccount?.companyName}
                    />
                </Sider>
            </Layout>

            <Flex justify='center' style={TOP_INDENTS.XL_PLUS}>
                <NextButton />
            </Flex>
        </React.Fragment>
    );
});

export default EditCampaign;
