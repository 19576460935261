import React from 'react';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { convertDataToBarScheme } from '../../../helpers';
import { useCustomTooltip } from '../../../hooks';
import { getOptions } from './utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const BarChart = ({ data, chartType = 'vertical', chartValuePrefix = '', chartValueSuffix = '%' }) => {
    if (!data) return null;
    const { labels, seedData, baseData, diffData, count, datasets } = convertDataToBarScheme({
        data,
        chartValuePrefix,
        chartValueSuffix
    });
    const chartData = {
        labels,
        datasets
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const createTooltip = useCustomTooltip({
        chartModel: 'bar',
        data: { seedData, baseData, count, diffData, labels },
        hideNegative: false,
        chartValuePrefix,
        chartValueSuffix,
        chartType
    });

    const options = getOptions(createTooltip, chartType);

    return <Bar plugins={[ChartDataLabels]} options={options} data={chartData} />;
};

export default BarChart;
