import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BOTTOM_INDENTS, COLORS, TOP_INDENTS } from '@constants';
import { Divider, Icon, Typography } from '@ui';
import { Flex } from 'antd';
import { StyledMask, StyledWrapper } from './styled';

const SetupWizard = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [setupCounter, setSetupCounter] = useState(0);
    const [importingCounter, setImportingCounter] = useState(0);
    const [completingCounter, setCompletingCounter] = useState(0);

    useEffect(() => {
        document.querySelector('#root').style.overflow = 'hidden';

        const animateCounter = (setter, onComplete) => {
            let counter = 0;
            const interval = setInterval(() => {
                counter += 1;
                setter(counter);
                if (counter >= 100) {
                    clearInterval(interval);
                    if (onComplete) onComplete();
                }
            }, 10);
        };

        animateCounter(setSetupCounter, () => {
            animateCounter(setImportingCounter, () => {
                animateCounter(setCompletingCounter, () => {
                    setTimeout(() => {
                        searchParams.delete('setup');
                        setSearchParams(searchParams);
                    }, 200);
                });
            });
        });

        return () => {
            document.querySelector('#root').style.overflow = 'scroll';
        };
    }, []);

    return (
        <StyledMask>
            <StyledWrapper>
                <Typography.Title align='center' level={2} color={COLORS.$gray100Black}>
                    Setting up your app...
                </Typography.Title>
                <Divider margin='24px 0' />
                <Flex vertical>
                    <Flex align='center' justify='space-between' style={BOTTOM_INDENTS.M}>
                        <Flex gap={24} align='center'>
                            <Icon.BoxIcon />
                            <Typography.Text type='descriptor1'>Reviewing shopping cart set-up</Typography.Text>
                        </Flex>
                        <Typography.Text type='badgeLarge' color={COLORS.$robinBlue}>
                            {setupCounter ? (setupCounter >= 100 ? 'Done!' : `${setupCounter}%`) : ''}
                        </Typography.Text>
                    </Flex>
                    <Flex align='center' justify='space-between' style={BOTTOM_INDENTS.M}>
                        <Flex gap={24} align='center'>
                            <Icon.CarbonIcon />
                            <Typography.Text type='descriptor1'>Importing products</Typography.Text>
                        </Flex>
                        <Typography.Text type='badgeLarge' color={COLORS.$robinBlue}>
                            {importingCounter ? (importingCounter >= 100 ? 'Done!' : `${importingCounter}%`) : ''}
                        </Typography.Text>
                    </Flex>
                    <Flex align='center' justify='space-between'>
                        <Flex gap={24} align='center'>
                            <Icon.NimbusIcon />
                            <Typography.Text type='descriptor1'>Completing synchronization</Typography.Text>
                        </Flex>
                        <Typography.Text type='badgeLarge' color={COLORS.$robinBlue}>
                            {completingCounter ? (completingCounter >= 100 ? 'Done!' : `${completingCounter}%`) : ''}
                        </Typography.Text>
                    </Flex>
                </Flex>
                <Typography.Text type='badgeMedium' color={COLORS.$robinBlue} align='center' style={TOP_INDENTS.XL}>
                    Please wait...
                </Typography.Text>
            </StyledWrapper>
        </StyledMask>
    );
};

export default SetupWizard;
