import { Icon, Tag } from '@ui';
import styled, { css, keyframes } from 'styled-components';

const rotateAnimation = keyframes`
    0% { transform: rotate(360deg) }
    100% { transform: rotate(0deg) }
`;

export const StyledTag = styled(Tag)`
    ${({ $clickable }) =>
        $clickable
            ? css`
                  cursor: pointer;
              `
            : css`
                  pointer-events: none;
                  touch-action: none;
              `}
`;

export const StyledIcon = styled(Icon)`
    margin-left: 8px;
    transform: rotate(360deg);

    ${({ $rotate }) =>
        $rotate
            ? css`
                  animation-name: ${rotateAnimation};
                  animation-duration: 1s;
                  animation-iteration-count: infinite;
              `
            : ''}
`;
