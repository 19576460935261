import { COLORS } from '@constants';
import { Card } from 'antd';
import styled, { css } from 'styled-components';

export const StyledCard = styled(Card)`
    & .ant-card-head {
        min-height: auto;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    & .ant-card-body {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: content-box;
        height: 370px;
    }

    ${({ $isHomePage }) =>
        $isHomePage
            ? css`
                  &&& {
                      background-color: transparent;
                      border-style: none;

                      .ant-card-head,
                      .ant-card-body {
                          padding-left: 0;
                          padding-right: 0;
                          padding-top: 0;
                      }

                      .ant-card-head {
                          padding-top: 0;
                          border-bottom: none;
                          padding-bottom: 8px;
                      }

                      .ant-card-body {
                          ${({ $isEmpty }) =>
                              $isEmpty
                                  ? css`
                                        background-color: ${COLORS.$white};
                                        border-radius: 8px;
                                        height: 120px;
                                    `
                                  : ''}
                      }
                  }
              `
            : ''}
`;

export const StyledCardsWrap = styled.div`
    width: 100%;
    display: flex;

    & > div:not(:first-child):not(:last-child) {
        margin: 0 8px;
    }

    & > div:first-child {
        margin-right: 8px;
    }

    & > div:last-child {
        margin-left: 8px;
    }
`;
