export const CONTACT_TYPE = {
    ECOMMERCE: 1,
    NOTIFICATION: 2,
    FOR_CUSTOMER: 3,
    FOR_KLICKLY: 4
};

export const CONTACT_VALUE_TYPE = {
    EMAIL: 1,
    PHONE: 2
};

export const ADDRESS_TYPE = {
    ECOMMERCE: 1,
    FOR_CUSTOMER: 2,
    FOR_KLICKLY: 3
};
