import { statusesMap } from '@enums';
import { getProductPrimaryImage } from '@utils';
import { types } from 'mobx-state-tree';

export const ProductImageModel = types.model({
    id: types.number,
    src: types.string,
    isPrimary: types.boolean,
    isEcommercePrimary: types.boolean,
    position: types.number
});

export const ProductModel = types
    .model({
        id: types.maybeNull(types.number),
        title: types.maybeNull(types.string),
        ecommerceId: types.maybe(types.string),
        ecommerceStatus: types.maybe(types.number),
        connectionStatus: types.number,
        validationStatus: types.number,
        type: types.maybeNull(
            types.model({
                title: types.maybe(types.string)
            })
        ),
        quantity: types.maybeNull(types.number),
        images: types.array(ProductImageModel),
        variants: types.optional(
            types.model({
                data: types.array(
                    types.model({
                        id: types.maybeNull(types.number),
                        name: types.maybeNull(types.string),
                        originalPrice: types.maybeNull(types.union(types.float, types.number)),
                        price: types.maybeNull(types.union(types.float, types.number)),
                        quantity: types.maybeNull(types.number),
                        sku: types.maybeNull(types.string)
                    })
                ),
                meta: types.maybeNull(
                    types.model({
                        page: types.maybeNull(types.number),
                        perPage: types.maybeNull(types.number),
                        total: types.maybeNull(types.number),
                        totalPages: types.maybeNull(types.number)
                    })
                ),
                loading: false
            }),
            { data: [], meta: null, loading: false }
        )
    })
    .views((self) => ({
        get klicklyStatus() {
            for (const [statusKey, statusConfig] of Object.entries(statusesMap)) {
                const isConnectionValid = statusConfig.connectionStatuses.includes(self.connectionStatus);
                const isValidationValid = statusConfig.validationStatuses.includes(self.validationStatus);

                if (isConnectionValid && isValidationValid) {
                    return Number(statusKey);
                }
            }

            return null;
        },

        get primaryImage() {
            return getProductPrimaryImage(self.images);
        }
    }));
