import { getParent, types } from 'mobx-state-tree';

export const AddressModel = types
    .model('addressModel', {
        id: types.number,
        accountId: types.number,
        type: types.number,
        country: types.maybeNull(types.string),
        province: types.maybeNull(types.string),
        provinceCode: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        address1: types.maybeNull(types.string),
        address2: types.maybeNull(types.string),
        zipCode: types.maybeNull(types.string),
        confirmed: types.boolean
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        return {};
    });
