import React from 'react';
import { COLORS } from '@constants';
import { Icon, Tooltip } from '@ui';
import { tooltips } from '../../../../constants';
import { formatNumberToUSLocale } from '../../../../helpers';
import { StyledIcon } from '../styled';

export const columns = [
    {
        title: 'No',
        dataIndex: 'no',
        key: 'no'
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state'
    },
    {
        title: () => (
            <Tooltip title={tooltips.topStatesSample} placement='right' trigger={['click', 'hover']}>
                <span>
                    Sample
                    <StyledIcon size='16px' component={() => <Icon.QuestionCircleIcon />} color={COLORS.$gray40Black} />
                </span>
            </Tooltip>
        ),
        dataIndex: 'sample',
        key: 'sample',
        render: (data) => `${data}%`
    },
    {
        title: () => (
            <Tooltip title={tooltips.topStatesRecords} placement='right' trigger={['click', 'hover']}>
                <span>
                    Records
                    <StyledIcon size='16px' component={() => <Icon.QuestionCircleIcon />} color={COLORS.$gray40Black} />
                </span>
            </Tooltip>
        ),
        dataIndex: 'records',
        key: 'records',
        render: (data) => formatNumberToUSLocale(data)
    }
];
