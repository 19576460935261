import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Typography } from '@ui';
import { Col, Form, Row, Switch } from 'antd';
import { FIELD_NAMES } from '../../../../../constants';

const Clicks = () => {
    return (
        <Row align='middle' gutter={[48, 48]} wrap={false} style={BOTTOM_INDENTS.M} justify='space-between'>
            <Col>
                <Typography.Text data-e2e='tracking_clicks_block' type='badgeSmall' style={BOTTOM_INDENTS.XXS}>
                    Clicks
                </Typography.Text>
                <Typography.Text data-e2e='tracking_clicks_description' color={COLORS.$gray60Black} type='descriptor2'>
                    Track clicks in Google Analytics, including both in-widget and in-Klickly product clicks
                </Typography.Text>
            </Col>
            <Col>
                <Form.Item name={FIELD_NAMES.GOOGLE_ANALYTICS_CLICKS} valuePropName='checked'>
                    <Switch data-e2e='tracking_clicks_toggle' />
                </Form.Item>
            </Col>
        </Row>
    );
};

export default Clicks;
