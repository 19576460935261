import React, { Fragment, useEffect, useState } from 'react';
import { useStore } from '@hooks';
import { Loader } from '@ui';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import Empty from './components/Empty';
import Header from './components/Header';
import RequestInsights from './components/RequestInsights';
import { sections } from './constants/sections';
import { StyledTabs } from './styled';

const Insights = observer(() => {
    const store = useStore();
    const { insightsStore, accountsStore } = store;
    const { isEmpty, getInsightsData } = insightsStore;
    const { hasGUID } = accountsStore;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            if (hasGUID) {
                setLoading(true);
                await getInsightsData();
                setLoading(false);
            }
        })();
    }, []);

    if (!hasGUID) {
        return <RequestInsights />;
    }

    if (loading) return <Loader />;

    if (isEmpty) return <Empty />;

    return (
        <Fragment>
            <Row>
                <Col span={24}>
                    <Header />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <StyledTabs fullWidth centered defaultActiveKey='1' items={sections} />
                </Col>
            </Row>
        </Fragment>
    );
});

export default Insights;
