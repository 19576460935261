import React, { useEffect } from 'react';
import { Chart } from 'chart.js';
import { ChoroplethController, ColorScale, GeoFeature, ProjectionScale, topojson } from 'chartjs-chart-geo';
import { getOptions } from './utils';

Chart.register(ChoroplethController, ProjectionScale, ColorScale, GeoFeature);

const LocationChart = ({ data, mapData }) => {
    useEffect(() => {
        if (!data || !mapData) return null;
        const options = getOptions();
        const canvas = document.getElementById('canvas');
        if (!canvas) return;

        const nation = topojson.feature(mapData, mapData.objects.nation).features[0];
        const states = topojson.feature(mapData, mapData.objects.states).features;

        // eslint-disable-next-line
        const chart = new Chart(canvas.getContext('2d'), {
            type: 'choropleth',
            data: {
                labels: states.map((d) => d.properties.name),
                datasets: [
                    {
                        label: 'States',
                        outline: nation,
                        data: states.map((d) => ({
                            feature: d,
                            value: data[d.properties.name] ? data[d.properties.name] : 0
                        }))
                    }
                ]
            },
            options: options
        });
    }, [data, mapData]);

    return <canvas id='canvas' />;
};

export default LocationChart;
