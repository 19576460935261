import React from 'react';

export const PAGE = {
    TITLE: 'Review creative settings',
    DESCRIPTION1: (
        <React.Fragment>
            While your creatives will be dynamically created, you can control various elements, including the logo,
            colors, and font types. Review your creative settings below. These changes will apply to all campaigns.
        </React.Fragment>
    ),
    DESCRIPTION2: 'Note: Any changes to your creative settings will be reviewed prior to going live.'
};
