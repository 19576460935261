import React from 'react';
import { columns } from './constants';
import { StyledTable } from './styled';
import { getDataSource } from './utils';

const TopStates = ({ data }) => {
    if (!data) return null;

    return (
        <StyledTable borderColor='transparent' dataSource={getDataSource(data)} columns={columns} pagination={false} />
    );
};

export default TopStates;
