import React, { useState } from 'react';
import { Annotation, ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { COLORS } from '@constants';
import { useStore } from '@hooks';
import { Divider, Tooltip, Typography, WhiteCover } from '@ui';
import { Flex, Tooltip as AntTooltip } from 'antd';
import { geoCentroid } from 'd3-geo';
import { tooltips } from '../../../../constants';
import QuestionCircleIcon from '../../../QuestionCircleIcon';
import { offsets, stateAbbreviations } from '../../constants';

const TopStatesMap = () => {
    const store = useStore();
    const { getStateByLabel, top5States } = store.insightsDemoStore;
    const { usMapData } = store.insightsStore;

    const [hoveredState, setHoveredState] = useState('');
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
        setPosition({ x: e.clientX, y: e.clientY });
    };

    const tooltipTitle = (
        <div>
            <p>{hoveredState}</p>
            <p>Records: {getStateByLabel(hoveredState)?.records || 0}</p>
            <p>Percent: {getStateByLabel(hoveredState)?.percent || 0}</p>
        </div>
    );

    return (
        <WhiteCover hasDivider={false}>
            <Flex gap={16} align='center'>
                <Tooltip title={tooltips.topStates} trigger='hover'>
                    <div>
                        <QuestionCircleIcon />
                    </div>
                </Tooltip>
                <Typography.Text color={COLORS.$gray40Black} type='badgeMedium'>
                    Top 5 States
                </Typography.Text>
            </Flex>
            <Divider margin='24px 0' />
            <div
                style={{
                    aspectRatio: '1.57/1',
                    position: 'relative',
                    overflow: 'hidden'
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: '50%',
                        transform: 'translateY(-50%)'
                    }}
                >
                    <ComposableMap projection='geoAlbersUsa' onMouseMove={handleMouseMove}>
                        <Geographies geography={usMapData}>
                            {({ geographies }) =>
                                geographies.map((geo) => {
                                    const { name } = geo.properties;

                                    const stateCode = geo.id;
                                    const abbreviation = stateAbbreviations[stateCode];

                                    const centroid = geoCentroid(geo);

                                    const isTop5 = top5States.find((state) => state.label === name);
                                    const state = getStateByLabel(name);

                                    const opacity = isTop5 ? 0.7 : state?.percent > 0 ? 0.5 : 0.3;

                                    return (
                                        <React.Fragment key={geo.rsmKey}>
                                            <Geography
                                                geography={geo}
                                                onMouseEnter={(e) => {
                                                    setHoveredState(name);
                                                }}
                                                onMouseLeave={() => {
                                                    setHoveredState('');
                                                }}
                                                style={{
                                                    default: {
                                                        fill: COLORS.$pink,
                                                        stroke: COLORS.$pinkDark,
                                                        fillOpacity: opacity
                                                    },
                                                    hover: {
                                                        fill: COLORS.$pinkLight,
                                                        fillOpacity: 1
                                                    },
                                                    pressed: {
                                                        fill: '#E42',
                                                        outline: 'none'
                                                    }
                                                }}
                                            />
                                            {abbreviation && (
                                                <Annotation
                                                    subject={[centroid[0], centroid[1]]}
                                                    dx={offsets[abbreviation]?.[0] || 0}
                                                    dy={offsets[abbreviation]?.[1] || 0}
                                                >
                                                    <text
                                                        textAnchor='middle'
                                                        fill='#000'
                                                        fontSize={12}
                                                        style={{
                                                            pointerEvents: 'none'
                                                        }}
                                                    >
                                                        {abbreviation}
                                                    </text>
                                                </Annotation>
                                            )}
                                        </React.Fragment>
                                    );
                                })
                            }
                        </Geographies>
                    </ComposableMap>
                    <AntTooltip
                        arrow={false}
                        open={Boolean(hoveredState)}
                        title={tooltipTitle}
                        placement='top'
                        overlayStyle={{
                            position: 'fixed',
                            left: position.x + 20,
                            top: position.y - 20,
                            pointerEvents: 'none'
                        }}
                    >
                        <div style={{ width: 0, height: 0 }} />
                    </AntTooltip>
                </div>
            </div>
        </WhiteCover>
    );
};

export default TopStatesMap;
