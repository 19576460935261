export const CUSTOM_CREATIVES_SIZES = [
    {
        index: 1,
        size: '970x250',
        width: 970,
        height: 250
    },
    {
        index: 2,
        size: '728x90',
        width: 728,
        height: 90
    },
    {
        index: 3,
        size: '320x50',
        width: 320,
        height: 50
    },
    {
        index: 4,
        size: '320x100',
        width: 320,
        height: 100
    },
    {
        index: 5,
        size: '300x250',
        width: 300,
        height: 250
    },
    {
        index: 6,
        size: '300x600',
        width: 300,
        height: 600
    },
    {
        index: 7,
        size: '336x280',
        width: 336,
        height: 280
    },
    {
        index: 8,
        size: '160x600',
        width: 160,
        height: 600
    }
];

export const FILE_EXTENSION_MAP = {
    'image/jpeg': '.jpg',
    'image/jpg': '.jpg',
    'image/png': '.png'
};
