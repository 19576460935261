import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledTooltip = styled.div`
    box-sizing: border-box;
    width: max-content;
    position: absolute;
    transform: translateX(-50%);
    top: -45px;
    left: ${({ $offset }) => `${($offset - 1) * (100 / 99)}%`};

    .tooltip__body {
        border-radius: 8px;
        background-color: ${COLORS.$pink};
        padding: 8px 16px;
        border: 2px solid ${COLORS.$pink};
    }

    .tooltip__triangle {
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        border-width: 10px;
        border-style: solid;
        border-color: ${COLORS.$pink} transparent transparent transparent;
        z-index: -1;
    }
`;
