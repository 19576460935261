import React, { Fragment } from 'react';
import { CancelSave } from '@components';
import { StyledButtonContainer, StyledTypography } from '../../styled';
import ShutdownConfirm from '../ShutdownConfirm/index';

const Shutdown = ({ customClose, setModalContent, changeStatus }) => {
    return (
        <Fragment>
            <StyledTypography data-e2e='account-management_shutdown-account-modal_description'>
                You&apos;re about to shut down your account. Please be advised: this action cannot be undone and you
                will permanently lose access to your data. If you&apos;re unsure, please contact your account manager.
                Remember: you can adjust your commission up or down at any time!
            </StyledTypography>
            <StyledButtonContainer>
                <CancelSave
                    saveText='Shutdown'
                    onCancel={customClose}
                    onSave={() => {
                        setModalContent(
                            <ShutdownConfirm
                                changeStatus={changeStatus}
                                setModalContent={setModalContent}
                                customClose={customClose}
                            />
                        );
                    }}
                />
            </StyledButtonContainer>
        </Fragment>
    );
};

export default Shutdown;
