import React, { Fragment } from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Divider, Typography } from '@ui';
import { Col, Form, Row, Switch } from 'antd';
import { FIELD_NAMES } from '../../../../../constants';

const Views = () => {
    return (
        <Fragment>
            <Typography.Title data-e2e='tracking_events_title' level={2}>
                Events
            </Typography.Title>
            <Divider margin='24px 0' />
            <Typography.Text
                data-e2e='tracking_events_description'
                style={BOTTOM_INDENTS.M}
                type='body2'
                color={COLORS.$gray60Black}
            >
                Choose the events you would like to track in Google Analytics
            </Typography.Text>
            <Row align='middle' gutter={[48, 48]} wrap={false} style={BOTTOM_INDENTS.M} justify='space-between'>
                <Col>
                    <Typography.Text
                        data-e2e='tracking_engaged-views_block'
                        type='badgeSmall'
                        style={BOTTOM_INDENTS.XXS}
                    >
                        Engaged Views
                    </Typography.Text>
                    <Typography.Text
                        data-e2e='tracking_engaged-views_description'
                        color={COLORS.$gray60Black}
                        type='descriptor2'
                        style={BOTTOM_INDENTS.XXS}
                    >
                        Track views in Google Analytics, including all in-Klickly and widget product views that led to
                        an engagement (due to high volume, non-engagement views are filtered out)
                    </Typography.Text>
                </Col>
                <Col>
                    <Form.Item name={FIELD_NAMES.GOOGLE_ANALYTICS_VIEWS} valuePropName='checked'>
                        <Switch data-e2e='tracking_engaged-views_toggle' />
                    </Form.Item>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Views;
