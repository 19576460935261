import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledLabel = styled.label`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: 1px solid ${COLORS.$gray60Black};
    border-radius: 50%;
    background: ${COLORS.$white};
    cursor: pointer;

    &:hover {
        border: 2px solid ${({ $color }) => $color || COLORS.$pink};
    }

    &:has(.ant-radio-wrapper-disabled) {
        border: 1px solid ${COLORS.$gray20Black};

        &:hover {
            border: 1px solid ${COLORS.$gray20Black};
        }
    }

    .ant-radio-wrapper {
        display: none;
    }

    &:has(input:checked) {
        background: ${({ $color }) => $color || COLORS.$pink};
        border: 1px solid ${({ $color }) => $color || COLORS.$pink};

        .custom-check-mark {
            background: ${({ $color }) => $color || COLORS.$pink};
        }

        &:hover {
            border: 2px solid ${COLORS.$gray40Black};
        }
    }

    &:has(.ant-radio-wrapper-checked.ant-radio-wrapper-disabled) {
        background: ${COLORS.$gray20Black};
        border: 1px solid ${COLORS.$gray20Black};

        &:hover {
            border: 1px solid ${COLORS.$gray20Black};
        }

        .custom-check-mark {
            background: ${COLORS.$gray20Black};
        }
    }
`;

export const StyledCheckMark = styled.div`
    width: 12px;
    height: 6px;
    border-bottom: 2px solid ${COLORS.$white};
    border-left: 2px solid ${COLORS.$white};
    background: ${COLORS.$white};
    transform: rotate(-45deg) translate(1px, -1px);
`;
