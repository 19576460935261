import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Divider, Typography } from '@ui';
import { Card, Col, Form, Row, Switch } from 'antd';
import { FIELD_NAMES } from '../constants';
import Settings from './components/Settings';

const EnableGoogleAnalytics = ({ style }) => {
    const form = Form.useFormInstance();
    const isDropDownSettingsVisible = Form.useWatch(FIELD_NAMES.GOOGLE_ANALYTICS, form);

    return (
        <Card data-e2e='tracking_enable-ga_section' style={style}>
            <Row justify='space-between' align='middle'>
                <Col>
                    <Typography.Title data-e2e='tracking_enable-ga_title' level={2}>
                        Enable Google Analytics
                    </Typography.Title>
                </Col>
                <Col>
                    <Form.Item name={FIELD_NAMES.GOOGLE_ANALYTICS} valuePropName='checked'>
                        <Switch data-e2e='tracking_enable-ga_toggle' />
                    </Form.Item>
                </Col>
            </Row>
            <Divider margin='24px 0' />
            <Typography.Text
                data-e2e='tracking_enable-ga_description'
                type='body2'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.S}
            >
                Track Klickly events in your existing GA dashboard.
            </Typography.Text>
            <Typography.Text type='body2' color={COLORS.$gray60Black} style={BOTTOM_INDENTS.M}>
                Get advanced insights from our Growth team by granting View Only access to accounts@klickly.com Click{' '}
                <a
                    data-e2e='tracking_ga-details_link'
                    href='https://support.google.com/analytics/answer/9305788?hl=en#zippy=%2Cin-this-article'
                    target='_blank'
                    rel='noreferrer'
                >
                    here for details.
                </a>
            </Typography.Text>
            {isDropDownSettingsVisible && <Settings />}
        </Card>
    );
};

export default EnableGoogleAnalytics;
