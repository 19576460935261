import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
`;

export const StyledIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 12px;
    border-radius: 4px;
    background-color: ${COLORS.$gray20Black};
`;
