import React from 'react';
import { ROUTE_PATHS } from '@constants';
import { isMobileLandscapeView, isMobileView } from '@utils';
import klicklyLogoBlack from '../../images/klickly_logo_black.svg';
import klicklyLogoGray from '../../images/klickly_logo_gray.svg';
import { StyledFooter } from './styled';

const MainFooter = () => {
    return (
        <StyledFooter>
            <div className='container-fluid'>
                <div data-e2e='landing_footer' className='main-footer__wrapper'>
                    <div className='main-footer__wrapper__logo'>
                        <a data-e2e='landing_klickly_logo' href='/' rel='noopener noreferrer'>
                            {isMobileView() || isMobileLandscapeView() ? (
                                <img alt='klickly logo' src={klicklyLogoGray} />
                            ) : (
                                <img alt='klickly logo' src={klicklyLogoBlack} />
                            )}
                        </a>
                        <p>© Klickly, Inc</p>
                    </div>
                    <div className='main-footer__wrapper__links'>
                        <a
                            data-e2e='landing_terms_link'
                            href={ROUTE_PATHS.TERMS}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Terms
                        </a>

                        <a
                            data-e2e='landing_privacy_link'
                            href={ROUTE_PATHS.PRIVACY}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Privacy
                        </a>

                        <a
                            href='https://www.klickly.com/docs/privacy/opt_out'
                            target='_blank'
                            rel='noopener noreferrer'
                            data-e2e='landing_do-not-sell-info_link'
                        >
                            Do not sell/share my personal information
                        </a>

                        <a
                            href='https://www.klickly.com/docs/disclosure-sensitive-info'
                            target='_blank'
                            rel='noopener noreferrer'
                            data-e2e='landing_limit-sensitive-info_link'
                        >
                            Limit the use of my sensitive personal information
                        </a>

                        <a
                            data-e2e='landing_press_link'
                            href='https://www.klickly.com/press'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Press
                        </a>
                    </div>
                </div>
            </div>
        </StyledFooter>
    );
};

export default MainFooter;
