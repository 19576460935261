import { COLORS } from '@constants';
import styled, { css } from 'styled-components';

export const StyledWrap = styled.div`
    position: relative;
    display: flex;
    height: 120px;
    width: 100%;
`;

export const StyledContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid ${COLORS.$gray15Black};

    border-left-style: none;
    border-right-style: none;

    ${({ $isFirstElem }) =>
        $isFirstElem
            ? css`
                  border-left-style: solid;
                  border-bottom-left-radius: 8px;
                  border-top-left-radius: 8px;
              `
            : ''}
    ${({ $isLastElem }) =>
        $isLastElem
            ? css`
                  border-right-style: solid;
                  border-bottom-right-radius: 8px;
                  border-top-right-radius: 8px;
              `
            : ''}

    ${({ $isHomePage }) =>
        $isHomePage
            ? css`
                  background-color: ${COLORS.$white};
              `
            : ''}
`;

export const StyledBody = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & h1 {
        margin-top: 0 !important;
    }
`;
