import React from 'react';
import { Icon, Typography } from '@ui';
import { Col, Row } from 'antd';

const CoverTitle = ({ icon, title }) => {
    return (
        <Row gutter={24} align='middle' wrap={false}>
            <Col>
                <Icon size='56px' component={() => icon} />
            </Col>
            <Col>
                <Typography.Title level={2}>{title}</Typography.Title>
            </Col>
        </Row>
    );
};

export default CoverTitle;
