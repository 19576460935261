import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { usePolling, useStore } from '@hooks';
import { Typography } from '@ui';
import { Flex, Progress } from 'antd';
import { observer } from 'mobx-react';

const ProductsSyncProgress = observer(() => {
    const store = useStore();
    const { getAccountSyncProgress, productsSyncProgress } = store.accountsStore;

    usePolling(getAccountSyncProgress, 1000, true);

    return (
        <Flex data-e2e='products_sync-products_section' align='center' justify='center' vertical>
            <Typography.Text type='badgeLarge' color={COLORS.$gray60Black} style={BOTTOM_INDENTS.M}>
                We are syncing your products
            </Typography.Text>
            <Progress
                data-e2e='products_sync_progress-circle'
                type='circle'
                percent={productsSyncProgress?.percentDone || 0}
            />
        </Flex>
    );
});

export default ProductsSyncProgress;
