import React from 'react';
import { BOTTOM_INDENTS, COLORS, validateDebounce, VALIDATION_RULES } from '@constants';
import { Icon, Input, Typography } from '@ui';
import { Form } from 'antd';
import { FIELD_NAMES } from '../../../../../constants';
import { useMeasurementIdModal } from '../../hooks';
import { QuestionIcon } from '../QuestionIcon';
import { StyledCard } from './styled';

const MeasurementId = () => {
    const { showMeasurementIdModal } = useMeasurementIdModal();

    return (
        <StyledCard style={BOTTOM_INDENTS.XL_PLUS}>
            <Typography.Text style={BOTTOM_INDENTS.M} type='body2' color={COLORS.$gray60Black}>
                Enter your store’s Google Analytics Measurement ID
            </Typography.Text>
            <Form.Item
                name={FIELD_NAMES.GOOGLE_ANALYTICS_MEASUREMENT_ID}
                rules={[
                    (form) => {
                        return form.getFieldValue(FIELD_NAMES.GOOGLE_ANALYTICS)
                            ? VALIDATION_RULES.REQUIRED[0]
                            : undefined;
                    },
                    (form) => {
                        return form.getFieldValue(FIELD_NAMES.GOOGLE_ANALYTICS)
                            ? VALIDATION_RULES.MEASUREMENT_ID[0]
                            : undefined;
                    }
                ]}
                validateDebounce={validateDebounce}
            >
                <Input
                    data-e2e='tracking_measurement-id_input'
                    label='G-XXXXXXXXXX'
                    suffix={
                        <Icon
                            onClick={showMeasurementIdModal}
                            component={() => <QuestionIcon data-e2e='tracking_measurement-id-hint_icon' />}
                        />
                    }
                />
            </Form.Item>
        </StyledCard>
    );
};

export default MeasurementId;
