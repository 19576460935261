import React, { useEffect } from 'react';
import { useStore } from '@hooks';
import { Loader } from '@ui';
import { observer } from 'mobx-react';
import { STRIPE_ACCOUNT_STATUS } from '../../../../../../../../constants/stripe';
import { STRIPE_STATUS_COMPONENT } from '../../constants';
import { StyledBlur, StyledWrapper } from '../../styled';
import NoAccount from '../NoAccount';

const StripeAccountStatus = observer(() => {
    const { accountsStore } = useStore();
    const { stripeAccountStatus } = accountsStore;

    useEffect(() => {
        if (stripeAccountStatus === STRIPE_ACCOUNT_STATUS.COMPLETE_DISABLED) {
            (async () => {
                await accountsStore.stripeAccountInfo();
            })();
        }
    }, [stripeAccountStatus]);

    const Component = STRIPE_STATUS_COMPONENT[stripeAccountStatus] || NoAccount;

    return (
        <StyledWrapper>
            {accountsStore.accountStatusPending ? <Loader /> : null}
            <StyledBlur $isLoading={accountsStore.accountStatusPending}>
                <Component />
            </StyledBlur>
        </StyledWrapper>
    );
});

export default StripeAccountStatus;
