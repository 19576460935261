import { COLORS } from '@constants';
import { Flex } from 'antd';
import styled from 'styled-components';

export const StyledLink = styled.a`
    color: ${COLORS.$info};
    font-weight: inherit;
`;

export const StyledError = styled.div`
    border-radius: 8px;
    border: 1px solid ${COLORS.$tartRed};
    background-color: ${COLORS.$tartRedLite};
    padding: 24px;
`;

export const StyledConfirm = styled(Flex)`
    border-radius: 8px;
    border: 1px solid ${COLORS.$robinBlue};
    background-color: ${COLORS.$robinTintsBlue};
    padding: 24px;
`;
