import React, { forwardRef, useEffect } from 'react';
import { Slider as AntdSlider } from 'antd';
import Tooltip from './components/Tooltip';
import { MARKS } from './constants';
import { StyledWrapper } from './styled';

const Slider = forwardRef(({ style, formatter, suggestedPercent, percentOffset, marks, ...props }, ref) => {
    useEffect(() => {
        const sliderHandle = document.querySelector('.ant-slider-handle');
        if (sliderHandle) {
            sliderHandle.setAttribute('data-e2e', 'common_slider_handle');
        }
    }, []);

    return (
        <StyledWrapper data-e2e='common_slider' style={style}>
            <div>
                {suggestedPercent && <Tooltip suggestedPercent={suggestedPercent} offset={percentOffset} />}
                <AntdSlider
                    ref={ref}
                    marks={marks || MARKS}
                    tooltip={{
                        open: true,
                        placement: 'top',
                        formatter: (value) => {
                            return (
                                <span data-e2e='common_slider_value'>{formatter ? formatter(value) : `${value}%`}</span>
                            );
                        },
                        getPopupContainer: (triggerNode) => triggerNode
                    }}
                    {...props}
                />
            </div>
        </StyledWrapper>
    );
});

Slider.displayName = 'Slider';

export { Slider };
