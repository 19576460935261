import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginForm } from '@components';
import { COLORS, ROUTE_PATHS, TOP_INDENTS, VALIDATION_RULES } from '@constants';
import { USER_VERIFICATION_STATUS } from '@enums';
import { useStore } from '@hooks';
import { Button, Icon, Input, RedirectLink } from '@ui';
import { Col, Flex, Form, notification, Row } from 'antd';
import { observer } from 'mobx-react';
import AuthLayout from '../../../../layouts/AuthLayout';
import { StyledCheckbox, StyledForm, StyledText } from '../../styled';

const Confirmed = observer(({ token }) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [formSubmitting, setFormSubmitting] = useState(false);

    const store = useStore();

    const { verification, onInviteComplete, inviteCompletePending } = store.accountsStore;
    const firstName = verification?.user?.firstName;
    const storeName = verification?.account?.storeName;
    const lastName = verification?.user?.lastName;
    const email = verification?.user?.email;

    if (verification.status === USER_VERIFICATION_STATUS.SUCCESS) {
        return (
            <AuthLayout
                description={
                    <StyledText>
                        Hi {firstName},<br />
                        You are already a member. Sign in using your email and password.
                    </StyledText>
                }
                leftCol=''
                title='Create Staff Account'
            >
                <LoginForm />
            </AuthLayout>
        );
    }

    if (verification.status === USER_VERIFICATION_STATUS.PENDING && verification.isExpired) {
        const storeEmail = verification.account.email;

        const handleContactClick = () => {
            if (storeEmail) {
                const emailLink = `mailto:${storeEmail}?subject=Contact Store Support`;
                window.location.href = emailLink;
            } else {
                notification.info({
                    message: 'Ooops',
                    description: 'Email contact not available at this time.'
                });
            }
        };

        return (
            <AuthLayout
                description={
                    <StyledText>
                        Hi {firstName},<br />
                        The invitational link is no longer valid. Please request the invitation from the store owner
                        once again.
                    </StyledText>
                }
                leftCol=''
                title='Create Staff Account'
            >
                <Button data-e2e='create-staff-account_contact-store-support_btn' onClick={handleContactClick} block>
                    Contact store support
                </Button>
            </AuthLayout>
        );
    }

    return (
        <AuthLayout
            description={
                <StyledText>
                    Hi {firstName},<br />
                    You’ve been invited to create a staff account with {storeName}. Finalize your account details to
                    gain full access to {storeName}.
                </StyledText>
            }
            leftCol=''
            title='Create Staff Account'
        >
            <StyledForm
                $pending={inviteCompletePending}
                form={form}
                onFinish={async (values) => {
                    setFormSubmitting(true);
                    await onInviteComplete('confirm-invite', token, values);
                    setFormSubmitting(false);
                    navigate(ROUTE_PATHS.LOGIN, { replace: true });
                }}
                initialValues={{ firstName, lastName, email }}
            >
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item name='firstName' rules={VALIDATION_RULES.REQUIRED}>
                            <Input data-e2e='create-staff-account_first-name_input' type='text' label='First Name' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='lastName' rules={VALIDATION_RULES.REQUIRED}>
                            <Input data-e2e='create-staff-account_last-name_input' type='text' label='Last Name' />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    style={TOP_INDENTS.S}
                    name='email'
                    rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.EMAIL]}
                >
                    <Input data-e2e='create-staff-account_email_input' disabled type='email' label='Email' />
                </Form.Item>
                {verification?.user?.password ? null : (
                    <React.Fragment>
                        <Form.Item
                            style={TOP_INDENTS.S}
                            name='password'
                            rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.PASSWORD]}
                        >
                            <Input
                                suffix={
                                    <Icon
                                        size='24px'
                                        data-e2e='create-staff-account_show-created-password_icon'
                                        color={COLORS.$gray20Black}
                                        component={() => <Icon.LockIcon />}
                                    />
                                }
                                type='password'
                                label='Create Password'
                                data-e2e='create-staff-account_create-password_input'
                            />
                        </Form.Item>
                        <Form.Item
                            style={TOP_INDENTS.S}
                            dependencies={['password']}
                            name='confirmPassword'
                            rules={[
                                { required: true, message: 'This field is required' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The confirmation password does not match'));
                                    }
                                })
                            ]}
                        >
                            <Input
                                suffix={
                                    <Icon
                                        data-e2e='create-staff-account_show-confirmed-password_icon'
                                        size='24px'
                                        color={COLORS.$gray20Black}
                                        component={() => <Icon.LockIcon />}
                                    />
                                }
                                type='password'
                                label='Confirm Password'
                                data-e2e='create-staff-account_confirm-password_input'
                            />
                        </Form.Item>
                    </React.Fragment>
                )}
                <Form.Item
                    style={TOP_INDENTS.L}
                    name='agreement'
                    valuePropName='checked'
                    rules={VALIDATION_RULES.CHECKBOX}
                >
                    <StyledCheckbox data-e2e='create-staff-account_agreement_checkbox' value='agreement'>
                        I have read and agree to the{' '}
                        <RedirectLink
                            data-e2e='create-staff-account_terms-of-service_link'
                            text='Terms of Service'
                            style={{ display: 'inline' }}
                            fontSize={16}
                            to={ROUTE_PATHS.TERMS}
                            target='_blank'
                        />{' '}
                        and{' '}
                        <RedirectLink
                            data-e2e='create-staff-account_privacy-policy_link'
                            text='Privacy Policy.'
                            style={{ display: 'inline' }}
                            fontSize={16}
                            to={ROUTE_PATHS.PRIVACY}
                            target='_blank'
                        />
                    </StyledCheckbox>
                </Form.Item>
                <Flex justify='center' gutter={[16, 16]}>
                    <Form.Item style={TOP_INDENTS.L}>
                        <Button
                            loading={formSubmitting}
                            htmlType='submit'
                            type='primary'
                            size='large'
                            block
                            justify='between'
                            data-e2e='create-staff-account_create-account_btn'
                            iconRight={
                                <Icon size='24px' color={COLORS.$white} component={() => <Icon.ArrowForwardIcon />} />
                            }
                        >
                            Create Account
                        </Button>
                    </Form.Item>
                </Flex>
            </StyledForm>
        </AuthLayout>
    );
});

export default Confirmed;
