import { COLORS } from '@constants';

export const INVOICE_STATUS = {
    FAILED: 0,
    REFUNDED: 1,
    ON_HOLD: 2,
    PENDING: 3,
    PROCESSING: 4,
    PAID: 5,
    PRT_REFUNDED: 6,
    PAUSED: 7
};

export const PAYOUT_STATUS = {
    FAILED: 0,
    CANCELLED: 1,
    OPEN: 2,
    PENDING: 3,
    PROCESSING: 4,
    PAID: 5,
    PAUSED: 6
};

export const INVOICE_STATUS_LABELS = {
    [INVOICE_STATUS.FAILED]: { title: 'Failed. Retry', color: COLORS.$tartRed },
    [INVOICE_STATUS.REFUNDED]: { title: 'Refunded', color: COLORS.$gray40Black },
    [INVOICE_STATUS.PRT_REFUNDED]: { title: 'Prt. Refunded', color: COLORS.$gray40Black },
    [INVOICE_STATUS.ON_HOLD]: { title: 'On hold', color: COLORS.$robinBlue },
    [INVOICE_STATUS.PENDING]: { title: 'Pending', color: COLORS.$robinBlue },
    [INVOICE_STATUS.PROCESSING]: { title: 'Processing', color: COLORS.$robinBlue },
    [INVOICE_STATUS.PAID]: { title: 'Paid', color: COLORS.$cornflowerBlue },
    [INVOICE_STATUS.PAUSED]: { title: 'Paused', color: COLORS.$gray40Black }
};

export const PAYOUT_STATUS_LABELS = {
    [PAYOUT_STATUS.FAILED]: { title: 'Failed', color: COLORS.$gray40Black },
    [PAYOUT_STATUS.CANCELLED]: { title: 'Canceled', color: COLORS.$gray40Black },
    [PAYOUT_STATUS.OPEN]: { title: 'Open', color: COLORS.$alert },
    [PAYOUT_STATUS.PENDING]: { title: 'Pending', color: COLORS.$robinBlue },
    [PAYOUT_STATUS.PROCESSING]: { title: 'Processing', color: COLORS.$robinBlue },
    [PAYOUT_STATUS.PAID]: { title: 'Paid', color: COLORS.$cornflowerBlue },
    [PAYOUT_STATUS.PAUSED]: { title: 'Paused', color: COLORS.$cornflowerBlue }
};

export const PAYOUT_ITEM_TYPE = {
    1: 'Transfer',
    2: 'Refund'
};
