import React, { Fragment, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { useStore } from '@hooks';
import { Button, Icon, Loader, Select } from '@ui';
import { formatString } from '@utils';
import { Col, Row } from 'antd';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from 'chart.js';
import { observer } from 'mobx-react';
import {
    CUSTOMER_OPTIONS,
    DETAILIZATION_TABS,
    DEVICES_OPTIONS,
    MENU_ITEM_IDS,
    PERFORMANCE_METRICS,
    titlePositionPluginConfig
} from '../../constants';
import { usePerformanceScrollFetch } from '../../hooks';
import CheckboxCard from './components/CheckboxCard';
import CheckboxCardsPlaceholder from './components/CheckboxCardsPlaceholder';
import { useDidMountEffect } from './hooks/useDidMountEffect';
import { CHART_OPTIONS } from './constants';
import { StyledChart, StyledTabs } from './styled';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Overview = observer(({ date, onComponentViewUpdate }) => {
    const store = useStore();
    const {
        getOverview,
        detailizationTab,
        performanceList,
        pendingMetricsChart,
        overviewPending,
        pendingMetricsOverview,
        getChartData,
        getMetricsOverview,
        getCampaignsForPerformance,
        chartDatasets,
        chartLabels,
        setDetailizationTab,
        downloadChartData,
        performanceCampaigns
    } = store.performanceStore;
    const [initialFetchDone, setInitialFetch] = useState(false);
    const [campaign, setCampaign] = useState();
    const [device, setDevice] = useState();
    const [customer, setCustomer] = useState();

    const defaultProps = {
        ...date,
        ...(campaign ? { campaign_id: campaign === 'all' ? null : campaign } : {}),
        ...(device ? { device_type: device === 'all' ? null : device } : {}),
        ...(customer ? { customer_type: customer === 'all' ? null : customer } : {})
    };

    const { componentRef } = usePerformanceScrollFetch({
        initialFetchDone,
        onInitialFetch: () => {
            setInitialFetch(true);
            getOverview({
                metrics: PERFORMANCE_METRICS,
                ...date
            });
        },
        onReFetch: () => null,
        onComponentViewUpdate,
        date
    });

    useEffect(() => {
        getCampaignsForPerformance();
    }, []);

    useDidMountEffect(() => {
        getChartData({
            ...defaultProps
        });
    }, [detailizationTab]);

    useDidMountEffect(() => {
        getChartData(defaultProps);
        getMetricsOverview({
            metrics: PERFORMANCE_METRICS,
            ...defaultProps
        });
    }, [date, campaign, device, customer]);

    const chart = {
        data: { datasets: chartDatasets, labels: chartLabels },
        options: {
            ...CHART_OPTIONS,
            scales: {
                ...CHART_OPTIONS.scales,
                x: {
                    ...CHART_OPTIONS.scales.x,
                    offset: chartLabels.length < 2
                }
            }
        }
    };

    const campaigns = [
        {
            label: <span data-e2e='performance_all-campaigns_dropdown-item'>All campaigns</span>,
            value: 'all'
        },
        ...(performanceCampaigns?.map((item) => {
            return {
                label: <span data-e2e={`performance_${formatString(item.title)}_dropdown-item`}>{item.title}</span>,
                value: item.id
            };
        }) ?? [])
    ];

    return (
        <div ref={componentRef} id={MENU_ITEM_IDS.OVERVIEW} style={{ scrollMarginTop: '180px' }}>
            <Row style={{ ...BOTTOM_INDENTS.XL_PLUS }} gutter={[16, 16]}>
                <Col flex='1 0 auto'>
                    <Select
                        data-e2e='performance_campaigns_dropdown'
                        label='Campaign'
                        defaultValue={campaigns[0]?.value}
                        options={campaigns}
                        value={campaign}
                        onChange={(val) => {
                            setCampaign(val);
                        }}
                    />
                </Col>
                <Col flex='1 0 auto'>
                    <Select
                        data-e2e='performance_device_dropdown'
                        label='Device'
                        defaultValue={DEVICES_OPTIONS[0].value}
                        options={DEVICES_OPTIONS}
                        value={device}
                        onChange={(val) => setDevice(val)}
                    />
                </Col>
                <Col flex='1 0 auto'>
                    <Select
                        data-e2e='performance_customer_dropdown'
                        label='Customer'
                        defaultValue={CUSTOMER_OPTIONS[0].value}
                        options={CUSTOMER_OPTIONS}
                        value={customer}
                        onChange={(val) => setCustomer(val)}
                    />
                </Col>
                <Col flex='0 0 auto'>
                    <Button
                        data-e2e='performance_download-scv_top-btn'
                        onClick={() => downloadChartData(defaultProps)}
                        icon={<Icon size='24px' color={COLORS.$gray60Black} component={() => <Icon.DownloadIcon />} />}
                        disabled={pendingMetricsChart || overviewPending}
                    >
                        Download CSV
                    </Button>
                </Col>
            </Row>
            <Fragment>
                <StyledChart data-e2e='performance_chart'>
                    {pendingMetricsChart || overviewPending ? <Loader overlayColor='white' borderRadius='8px' /> : null}
                    <Line data={chart.data} options={chart.options} plugins={[titlePositionPluginConfig]} redraw />
                </StyledChart>
                <StyledTabs
                    data-e2e='performance_chart-detalization_section'
                    onChange={(val) => {
                        setDetailizationTab(val);
                    }}
                    noBottomLine
                    centered
                    defaultActiveKey={detailizationTab}
                    items={DETAILIZATION_TABS}
                />
                <Row gutter={[16, 16]}>
                    {pendingMetricsOverview || overviewPending ? (
                        <CheckboxCardsPlaceholder data-e2e='performance_card_placeholder' />
                    ) : (
                        performanceList?.map((metric) => {
                            return <CheckboxCard key={metric.id} date={date} metric={metric} />;
                        })
                    )}
                </Row>
            </Fragment>
        </div>
    );
});

export default Overview;
