import React from 'react';
import { COLORS } from '@constants';
import { StyledNavLink } from './styled';

export const RedirectLink = ({
    to,
    text,
    display,
    fontWeight,
    fontSize,
    align = 'right',
    color = COLORS.$info,
    ...props
}) => {
    return (
        <StyledNavLink
            $fontWeight={fontWeight}
            $fontSize={fontSize}
            $color={color}
            $align={align}
            $display={display}
            to={to}
            {...props}
        >
            {text}
        </StyledNavLink>
    );
};
