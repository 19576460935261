import React from 'react';
import { useModal } from '@hooks';
import { Button } from '@ui';
import { Flex } from 'antd';
import AdExampleModal from '../components/AdExamplesModal';

export const useAdExamplesModal = (brandedData, promotions) => {
    const { open, close } = useModal();

    const openModal = () =>
        open({
            title: <span data-e2e='campaignwizard_ad-examples-modal_title'>Design preview</span>,
            content: <AdExampleModal brandedData={brandedData} promotions={promotions} />,
            addStyle: {
                borderBottom: '1px solid transparent',
                marginBottom: '0'
            },
            settings: {
                width: '1100px',
                footer: (
                    <Flex justify='flex-end'>
                        <Button style={{ width: '160px' }} padding='8px' onClick={close}>
                            Close
                        </Button>
                    </Flex>
                )
            }
        });

    return { openModal };
};
