import React from 'react';
import { useParams } from 'react-router-dom';
import { PaymentForm } from '@components';
import { ROUTE_PATHS } from '@constants';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from '../../../../../../../../config';
import { APPEARANCE } from './constants';

const stripePromise = loadStripe(config.stripePublishKey);

const AddPaymentMethodContent = ({ clientSecret, onCancel }) => {
    const params = useParams();
    const returnUrl = `${window.location.origin}${ROUTE_PATHS.LAUNCH_CHECK}/${params?.id}`;
    const stripeOptions = { clientSecret, appearance: APPEARANCE };

    return (
        <Elements stripe={stripePromise} options={stripeOptions}>
            <PaymentForm onCancel={onCancel} returnUrl={returnUrl} />
        </Elements>
    );
};

export default AddPaymentMethodContent;
