/* eslint-disable react/react-in-jsx-scope */

const getNumberValueLengthValidation = (length, errorMessage) => {
    const message = errorMessage || `Field should contain ${length} numbers`;
    return () => ({
        validator(_, value) {
            if (!value || (typeof value === 'number' && value.toString().length === length)) {
                return Promise.resolve();
            }
            return Promise.reject(new Error(message));
        }
    });
};

export const VALIDATION_RULES = {
    REQUIRED: [{ required: true, message: <span data-e2e='common_required-field_error'>This field is required</span> }],
    EMAIL: [
        {
            pattern: /^\S+@\S+\.\S+$/,
            message: <span data-e2e='common_invalid-email_error'>Field should be a valid email</span>
        }
    ],
    PHONE: [
        {
            pattern: /^\d{10}$/,
            message: <span data-e2e='common_invalid-phone_error'>Field should be a valid phone</span>
        }
    ],
    ZIP_CODE: [
        {
            pattern: /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/,
            message: <span data-e2e='common_invalid-zip-code_error'>Invalid postal code</span>
        }
    ],
    MEASUREMENT_ID: [
        {
            pattern: /^G-[A-Z0-9]{1,10}$/,
            message: <span data-e2e='common_invalid-measurement-id_error'>Field should be a valid measurement id</span>
        }
    ],
    CHECKBOX: [
        {
            required: true,
            transform: (value) => value || undefined,
            type: 'boolean',
            message: <span data-e2e='common_required-checkbox_error'>This checkbox is required</span>
        }
    ],
    PASSWORD: [
        {
            min: 6,
            message: <span data-e2e='common_invalid-password_error'>Password should contain at least 6 characters</span>
        }
    ],
    NAME: [
        { required: true, message: <span data-e2e='common_required-field_error'>This field is required</span> },
        { min: 2, message: <span data-e2e='common_min-length_error'>Field should contain at least 2 characters</span> },
        {
            pattern: /([A-zÀ-ÿ-. ']+[ ]*)+/gm,
            message: <span data-e2e='common_invalid-value_error'>Invalid field value</span>
        }
    ],
    COMMENT: [
        { required: true, message: <span data-e2e='common_mandatory-field_error'>This field is mandatory</span> },
        { min: 50, message: <span data-e2e='common_min-length_error'>min 50 characters</span> }
    ],
    ROUTING_NUMBER: [
        { required: true, message: <span data-e2e='common_required-field_error'>This field is required</span> },
        getNumberValueLengthValidation(9, 'Routing number must have 9 digits')
    ],
    ACCOUNT_NUMBER: [
        { required: true, message: <span data-e2e='common_required-field_error'>This field is required</span> },
        { pattern: /^[0-9]{6,17}$/g, message: 'Wrong account number' }
    ],
    CARD_NUMBER: [
        { required: true, message: <span data-e2e='common_required-field_error'>This field is required</span> },
        getNumberValueLengthValidation(16)
    ],
    CARD_CVV: [
        { required: true, message: <span data-e2e='common_required-field_error'>This field is required</span> },
        getNumberValueLengthValidation(3)
    ]
};

export const validateDebounce = 1000;
