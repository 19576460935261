export const sortData = (data, sorting = 'desc') => {
    if (!data || !data.length) {
        return [];
    }

    return data
        .slice()
        .filter((obj) => obj.seedfilePercent)
        .sort((obj1, obj2) => {
            return sorting === 'desc'
                ? Number(obj2.seedfilePercent.replace(/[^.,a-zA-Z0-9]/g, '')) -
                      Number(obj1.seedfilePercent.replace(/[^.,a-zA-Z0-9]/g, ''))
                : Number(obj1.seedfilePercent.replace(/[^.,a-zA-Z0-9]/g, '')) -
                      Number(obj2.seedfilePercent.replace(/[^.,a-zA-Z0-9]/g, ''));
        });
};

export const formatNumberToUSLocale = (number) => {
    let val = number;

    if (typeof val === 'string') {
        val = val.replace(/[^0-9.-]/g, '');
    }

    return new Intl.NumberFormat('en-US').format(Number(val));
};
