import React, { Fragment, useEffect } from 'react';
import { BOTTOM_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Tabs, Typography } from '@ui';
import { observer } from 'mobx-react';
import { tabs } from './constants/tabs';

const Invoices = observer(() => {
    const store = useStore();
    const { resetChargesFilters, resetChargesSorters } = store.invoicesStore;
    useEffect(() => {
        return () => {
            resetChargesFilters();
            resetChargesSorters();
        };
    }, []);

    return (
        <Fragment>
            <Typography.Title data-e2e='invoices_page_title' level={2} style={BOTTOM_INDENTS.M}>
                Invoices
            </Typography.Title>
            <Tabs destroyInactiveTabPane fullWidth centered defaultActiveKey='1' items={tabs} />
        </Fragment>
    );
});

export default Invoices;
