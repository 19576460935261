import { brandsOpenApi, FIELD_NAME } from '@constants';
import { CAMPAIGN_PRODUCTS_LIST_TYPES, CAMPAIGN_TYPE_ID, TABS_TYPES } from '@enums';
import { axiosBoa, getErrorMessage } from '@utils';
import _ from 'lodash';
import { flow, getParent, types } from 'mobx-state-tree';
import { getCommissionsByForm } from '../pages/CampaignWIzard/utils';
import { Campaign, defaultSelectionTableState, Product, SelectionTable } from './constants';

const MAX_PREVIEW_PRODUCTS_COUNT = 8;

export const CampaignWizardStore = types
    .model('CampaignWizardStore', {
        campaign: types.maybeNull(Campaign),
        selectionTable: types.optional(SelectionTable, defaultSelectionTableState),
        previewProducts: types.array(Product),
        priorityPending: false,
        previewProductsPending: false,
        productsSelectionPending: false,
        pending: false,
        formSubmitting: false,
        isNextDisabled: true,
        isNextLoading: false,
        error: types.maybeNull(types.model({ title: types.string, description: types.string }))
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get accountProducts() {
            return self.selectionTable.accountProducts.data;
        },
        get accountProductsMeta() {
            return self.selectionTable.accountProducts.meta;
        },
        get allAccountProductsSelected() {
            return self.selectedProductsIds.length === self.accountProducts.length;
        },
        get accountProductsIds() {
            return self.selectionTable.accountProducts.data.map((product) => product.id);
        },
        get campaignProducts() {
            return self.selectionTable.campaignProducts.data;
        },
        get campaignProductsMeta() {
            return self.selectionTable.campaignProducts.meta;
        },
        get allCampaignProductsSelected() {
            return self.selectedProductsIds.length === self.campaignProducts.length;
        },
        get campaignProductsIds() {
            return self.selectionTable.campaignProducts.data.map((product) => product.id);
        },
        get campaignProductsListType() {
            return self.campaign?.catalogType === CAMPAIGN_TYPE_ID.MY_ENTIRE_CATALOG ||
                self.campaign?.catalogType === CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCT_TYPES ||
                self.campaign?.catalogType === CAMPAIGN_TYPE_ID.SPECIFIC_COLLECTIONS ||
                self.campaign?.catalogType === CAMPAIGN_TYPE_ID.BEST_SELLERS
                ? CAMPAIGN_PRODUCTS_LIST_TYPES.EXCLUDED
                : CAMPAIGN_PRODUCTS_LIST_TYPES.INCLUDED;
        },
        get campaignProductsRealTimeTotalPages() {
            return Math.ceil(
                (self.campaignProductsMeta.total -
                    self.campaignProducts.length +
                    self.selectionTable.selectedProductsIds.length) /
                    self.campaignProductsMeta.perPage
            );
        },
        get campaignPriority() {
            return self.campaign?.priority || 0;
        },
        get campaignHasSelectedProducts() {
            if (self.campaign.catalogType === CAMPAIGN_TYPE_ID.MY_ENTIRE_CATALOG) {
                return self.userExcludedIds.length !== self.campaign.productsCount;
            }
            if (self.campaign.catalogType === CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCTS) {
                return self.userIncludedIds.length > 0;
            }

            return undefined;
        },
        get userIncludedIds() {
            return self.selectionTable.userIncludedIds;
        },
        get userExcludedIds() {
            return self.selectionTable.userExcludedIds;
        },
        get selectedProducts() {
            return self.selectionTable.accountProducts.data.filter((product) =>
                self.selectedProductsIds.includes(product.id)
            );
        },
        get selectedProductsIds() {
            return self.selectionTable.selectedProductsIds;
        },
        // in case of entire catalog || specific types || specific collections - value for excluded tab counter
        // in case of specific products - value for included tab counter
        get computedCampaignProductsCount() {
            return self.selectionTable.campaignProducts.computedCount;
        },
        get currentTabType() {
            return self.selectionTable.currentTabType;
        },
        get searchValue() {
            return self.selectionTable.searchValue;
        }
    }))
    .actions((self) => ({
        // * Actions for External Usage
        setProductsSelectionPending(pending) {
            self.productsSelectionPending = pending;
        },
        setIsNextDisabled(value) {
            self.isNextDisabled = value;
        },
        setIsNextLoading(value) {
            self.isNextLoading = value;
        },
        setFormSubmitting(value) {
            self.formSubmitting = value;
        },
        setPending(pending) {
            self.pending = pending;
        },
        // * Campaign
        getCampaign: flow(function* getCampaign(campaignId) {
            self.error = null;
            try {
                const campaign = yield axiosBoa.get(brandsOpenApi.campaigns.get(campaignId), {
                    params: {
                        include: 'creatives,boost'
                    }
                });
                self.campaign = campaign;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        resetCampaign() {
            self.campaign = null;
        },
        createCampaign: flow(function* createCampaign(values) {
            self.error = null;
            try {
                const campaign = yield axiosBoa.post(brandsOpenApi.campaigns.create, values);
                self.campaign = campaign;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        updateCampaign: flow(function* updateCampaign({ campaignId = self.campaign?.id, values }) {
            self.error = null;
            try {
                const campaign = yield axiosBoa.patch(brandsOpenApi.campaigns.patch(campaignId), values);
                self.campaign = _.merge(self.campaign, campaign);
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        submitCampaign: flow(function* submitCampaign(campaignId = self.campaign?.id) {
            self.error = null;
            try {
                const response = yield axiosBoa.post(brandsOpenApi.campaigns.submit(campaignId));
                return response;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        // * Products
        getAccountProducts: flow(function* getAccountProducts({
            campaignId = self.campaign?.id,
            params = {},
            withCampaignProductsMeta = false
        } = {}) {
            self.error = null;
            self.productsSelectionPending = true;
            try {
                const commonParams = {
                    userIncludedIds: self.userIncludedIds,
                    userExcludedIds: self.userExcludedIds,
                    search: self.searchValue ? `title:${self.searchValue}` : undefined
                };

                const accountProductsParams = Object.assign({}, commonParams, params);
                const campaignProductsParams = Object.assign({}, commonParams, {
                    page: 1,
                    limit: 1,
                    type: self.campaignProductsListType
                });

                const [accountProducts, { meta: campaignProductsMeta }] = yield Promise.all([
                    self.fetchProducts(campaignId, accountProductsParams),
                    withCampaignProductsMeta
                        ? self.fetchProducts(campaignId, campaignProductsParams)
                        : Promise.resolve({})
                ]);

                self.selectionTable.accountProducts.data = accountProducts.data;
                self.selectionTable.accountProducts.meta = accountProducts.meta;
                self.syncSelectedProductsIds();

                if (withCampaignProductsMeta) {
                    self.selectionTable.campaignProducts.meta.total = campaignProductsMeta.total;
                    self.selectionTable.campaignProducts.computedCount = campaignProductsMeta.total;
                }
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.productsSelectionPending = false;
            }
        }),
        getCampaignProducts: flow(function* getCampaignProducts({
            campaignId = self.campaign?.id,
            params = {},
            withAccountProductsMeta = false
        } = {}) {
            self.error = null;
            self.productsSelectionPending = true;
            try {
                const commonParams = {
                    userIncludedIds: self.userIncludedIds,
                    userExcludedIds: self.userExcludedIds,
                    search: self.searchValue ? `title:${self.searchValue}` : undefined
                };

                const campaignProductsParams = Object.assign(
                    {},
                    commonParams,
                    { type: self.campaignProductsListType },
                    params
                );

                const accountProductsParams = Object.assign({}, commonParams, { page: 1, limit: 1 });

                const [campaignProducts, accountProducts] = yield Promise.all([
                    self.fetchProducts(campaignId, campaignProductsParams),
                    withAccountProductsMeta
                        ? self.fetchProducts(campaignId, accountProductsParams)
                        : Promise.resolve({})
                ]);

                self.selectionTable.campaignProducts.data = campaignProducts.data;
                self.selectionTable.campaignProducts.meta = campaignProducts.meta;
                self.selectionTable.campaignProducts.computedCount = campaignProducts.meta.total;
                self.setSelectedProductsIds(self.campaignProductsIds);

                if (withAccountProductsMeta) {
                    self.selectionTable.accountProducts.meta.total = accountProducts.meta.total;
                }
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.productsSelectionPending = false;
            }
        }),
        getCurrentTabProducts: flow(function* getCurrentTabProducts({ params, withAnotherTabMeta = false } = {}) {
            if (self.currentTabType === TABS_TYPES.ACCOUNT_PRODUCTS) {
                yield self.getAccountProducts({
                    params,
                    withCampaignProductsMeta: withAnotherTabMeta
                });
            }

            if (self.currentTabType === TABS_TYPES.CAMPAIGN_PRODUCTS) {
                yield self.getCampaignProducts({
                    params,
                    withAccountProductsMeta: withAnotherTabMeta
                });
            }
        }),
        getPreviewProducts: flow(function* getPreviewProducts(campaignId = self.campaign?.id) {
            self.error = null;
            self.previewProductsPending = true;
            try {
                const params = {
                    userIncludedIds: self.userIncludedIds,
                    userExcludedIds: self.userExcludedIds,
                    withStats: false,
                    limit: MAX_PREVIEW_PRODUCTS_COUNT,
                    type: 4
                };

                const previewProducts = yield self.fetchProducts(campaignId, params);
                self.previewProducts = previewProducts.data;
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.previewProductsPending = false;
            }
        }),
        resetPreviewProducts() {
            self.previewProducts = [];
        },
        resetProducts() {
            if (self.currentTabType === TABS_TYPES.ACCOUNT_PRODUCTS) {
                self.resetAccountProducts();
            }

            if (self.currentTabType === TABS_TYPES.CAMPAIGN_PRODUCTS) {
                self.resetCampaignProducts();
            }
        },
        resetAccountProducts() {
            self.selectionTable.accountProducts.meta.page = 1;
            self.selectionTable.accountProducts.meta.totalPages = 1;
            self.selectionTable.accountProducts.data = [];
        },
        resetCampaignProducts() {
            self.selectionTable.campaignProducts.meta.page = 1;
            self.selectionTable.accountProducts.meta.totalPages = 1;
            self.selectionTable.campaignProducts.data = [];
        },
        resetSelectedProductsIds() {
            self.selectionTable.selectedProductsIds = [];
        },
        setPrimaryImageRestPlaces(productId, imageId) {
            const accountProduct = self.selectionTable.accountProducts.data.find((product) => product.id === productId);
            if (accountProduct) {
                accountProduct.setPrimaryImageLocally(imageId);
            }

            const campaignProduct = self.selectionTable.campaignProducts.data.find(
                (product) => product.id === productId
            );
            if (campaignProduct) {
                campaignProduct.setPrimaryImageLocally(imageId);
            }

            const previewProduct = self.previewProducts.find((product) => product.id === productId);
            if (previewProduct) {
                previewProduct.setPrimaryImageLocally(imageId);
            }
        },
        // * Selections
        handleProductsSelection(ids) {
            let included;
            let excluded;
            if (
                (self.campaign.catalogType === CAMPAIGN_TYPE_ID.MY_ENTIRE_CATALOG ||
                    self.campaign.catalogType === CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCT_TYPES ||
                    self.campaign.catalogType === CAMPAIGN_TYPE_ID.SPECIFIC_COLLECTIONS ||
                    self.campaign.catalogType === CAMPAIGN_TYPE_ID.BEST_SELLERS) &&
                self.selectionTable.currentTabType === TABS_TYPES.CAMPAIGN_PRODUCTS
            ) {
                included = _.difference(self.selectionTable.selectedProductsIds, ids);
                excluded = _.difference(ids, self.selectionTable.selectedProductsIds);
            } else {
                excluded = _.difference(self.selectionTable.selectedProductsIds, ids);
                included = _.difference(ids, self.selectionTable.selectedProductsIds);
            }

            self.handleInclusionsAndExclusions({ included, excluded });
            self.handleCampaignProductsCount({ included, excluded });
            self.setSelectedProductsIds(ids);

            // * handle campaign.productsCount

            self.campaign.productsCount += included.length;
            self.campaign.productsCount -= excluded.length;
        },
        resetProductsSelection() {
            self.selectionTable.userExcludedIds = [];
            self.selectionTable.userIncludedIds = [];
        },
        syncSelectionWithServer: flow(function* syncSelectionWithServer() {
            self.error = null;
            try {
                if (
                    self.campaign.catalogType === CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCTS ||
                    self.campaign.catalogType === CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCT_TYPES ||
                    self.campaign.catalogType === CAMPAIGN_TYPE_ID.SPECIFIC_COLLECTIONS ||
                    self.campaign.catalogType === CAMPAIGN_TYPE_ID.BEST_SELLERS
                ) {
                    yield Promise.all([
                        self.userIncludedIds.length
                            ? axiosBoa.post(
                                  brandsOpenApi.campaigns.products.add(self.campaign.id),
                                  self.userIncludedIds
                              )
                            : undefined,
                        self.userExcludedIds.length
                            ? axiosBoa.delete(brandsOpenApi.campaigns.products.remove(self.campaign.id), {
                                  data: self.userExcludedIds
                              })
                            : undefined
                    ]);
                }

                if (self.campaign.catalogType === CAMPAIGN_TYPE_ID.MY_ENTIRE_CATALOG) {
                    yield Promise.all([
                        self.userExcludedIds.length
                            ? axiosBoa.post(
                                  brandsOpenApi.campaigns.products.add(self.campaign.id),
                                  self.userExcludedIds
                              )
                            : undefined,
                        self.userIncludedIds.length
                            ? axiosBoa.delete(brandsOpenApi.campaigns.products.remove(self.campaign.id), {
                                  data: self.userIncludedIds
                              })
                            : undefined
                    ]);
                }
                self.selectionTable.userExcludedIds = [];
                self.selectionTable.userIncludedIds = [];
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        // * Others
        setTabType(tabType) {
            self.selectionTable.currentTabType = tabType;
        },
        setSearchValue(searchValue) {
            self.selectionTable.searchValue = searchValue;
        },
        resetSearch() {
            self.selectionTable.searchValue = '';
        },
        creativeSettingsUpdate: flow(function* creativeSettingsUpdate({ campaignId = self.campaign?.id, values }) {
            self.error = null;
            try {
                const updatedCreative = yield axiosBoa.patch(
                    brandsOpenApi.campaigns.creative.update(campaignId),
                    values
                );
                self.campaign.creatives = [updatedCreative];
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        campaignCreativeImageUpload: flow(function* campaignCreativeImageUpload({
            campaignId = self.campaign?.id,
            values
        }) {
            self.error = null;
            try {
                const response = yield axiosBoa.post(brandsOpenApi.campaigns.creativeImage.upload(campaignId), values, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                Object.assign(self.campaign.creatives[0], response);
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        calculatePriority: flow(function* calculatePriority({ campaignId = self.campaign?.id, values } = {}) {
            const nonNullableFields = [
                FIELD_NAME.ACCOUNT.COLD_CUSTOMER_COMMISSION,
                FIELD_NAME.ACCOUNT.NEW_CUSTOMER_COMMISSION,
                FIELD_NAME.ACCOUNT.REST_OF_SITE_COMMISSION,
                FIELD_NAME.CAMPAIGN.COMMISSION
            ];

            const fieldsValid = nonNullableFields.every((field) => values[field] !== null);

            if (!fieldsValid) return;

            self.error = null;
            self.priorityPending = true;
            try {
                const { priority } = yield axiosBoa.post(
                    brandsOpenApi.campaigns.priority.calculate(campaignId),
                    values
                );

                if (self.campaign) {
                    self.campaign.priority = priority;
                } else {
                    self.campaign = { id: 0, priority };
                }
            } catch (error) {
                self.error = getErrorMessage(error);
                self.priorityPending = false;
            } finally {
                self.priorityPending = false;
            }
        }),
        setPriorityPending(pending) {
            self.priorityPending = pending;
        },
        updatePriority: flow(function* updatePriority({ campaignId = self.campaign?.id, values }) {
            self.error = null;
            try {
                const {
                    priority,
                    boostId,
                    commission,
                    restOfSiteCommission,
                    newCustomerCommission,
                    coldCustomerCommission
                } = yield axiosBoa.patch(
                    brandsOpenApi.campaigns.priority.update(campaignId),
                    getCommissionsByForm(values)
                );

                self.campaign.priority = priority;
                self.campaign.commission = commission;
                self.campaign.boostId = boostId;
                self.root.accountsStore.currentAccount.commission.update({
                    coldCustomer: coldCustomerCommission,
                    newCustomer: newCustomerCommission,
                    restOfSite: restOfSiteCommission
                });
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        // * For internal usage (for now)
        fetchProducts: flow(function* fetchProducts(campaignId, params = {}) {
            const responseData = yield axiosBoa.post(brandsOpenApi.campaigns.products.listPost(campaignId), params);
            return responseData;
        }),
        syncSelectedProductsIds() {
            const included = _.intersection(self.accountProductsIds, self.selectionTable.userIncludedIds);
            const excluded = _.intersection(self.accountProductsIds, self.selectionTable.userExcludedIds);

            const result = _.chain(self.accountProducts)
                .filter((product) => product.inCatalog)
                .map((product) => product.id)
                .without(...excluded)
                .union(included)
                .value();

            self.selectionTable.selectedProductsIds = result;
        },
        handleInclusionsAndExclusions({ included = [], excluded = [] }) {
            self.selectionTable.userIncludedIds = _.union(self.selectionTable.userIncludedIds, included);
            self.selectionTable.userExcludedIds = _.without(self.selectionTable.userExcludedIds, ...included);

            self.selectionTable.userExcludedIds = _.union(self.selectionTable.userExcludedIds, excluded);
            self.selectionTable.userIncludedIds = _.without(self.selectionTable.userIncludedIds, ...excluded);
        },
        handleCampaignProductsCount({ included = [], excluded = [] }) {
            if (
                self.campaign.catalogType === CAMPAIGN_TYPE_ID.MY_ENTIRE_CATALOG ||
                self.campaign.catalogType === CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCT_TYPES ||
                self.campaign.catalogType === CAMPAIGN_TYPE_ID.SPECIFIC_COLLECTIONS ||
                self.campaign.catalogType === CAMPAIGN_TYPE_ID.BEST_SELLERS
            ) {
                self.selectionTable.campaignProducts.computedCount =
                    self.computedCampaignProductsCount - included.length + excluded.length;
            }

            if (self.campaign.catalogType === CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCTS) {
                self.selectionTable.campaignProducts.computedCount =
                    self.computedCampaignProductsCount + included.length - excluded.length;
            }
        },
        setSelectedProductsIds(ids) {
            self.selectionTable.selectedProductsIds = ids;
        },
        afterCreate() {}
    }));
