export const TOKEN_STATUS = {
    REVOKED: 0,
    ACTIVE: 1
};

export const USER_VERIFICATION_STATUS = {
    FAILURE: 0,
    PENDING: 1,
    SUCCESS: 2
};
