import React from 'react';
import googlePartnerImage from '../../images/google_partner.svg';
import magentoPartnerImage from '../../images/magento_partner.svg';
import rechargePartnerImage from '../../images/recharge_partner.svg';
import shopifyPartnerImage from '../../images/shopify_partner.svg';
import { StyledPartners } from './styled';

const PARTNERS = [
    { name: 'shopify_partner', image: shopifyPartnerImage },
    { name: 'google_partner', image: googlePartnerImage },
    { name: 'magento_partner', image: magentoPartnerImage },
    { name: 'recharge_partner', image: rechargePartnerImage }
];

const PartnersSection = () => {
    return (
        <StyledPartners>
            <div data-e2e='landing_partners-section' className='container-content'>
                <div className='partners-section__wrapper'>
                    <h3 data-e2e='landing_partners-section_title'>Leading Industry Partners</h3>
                    <div className='partners-section__wrapper__items'>
                        {PARTNERS.map(({ name, image }, index) => {
                            return (
                                <img
                                    data-e2e={`landing_${name}_logo`}
                                    key={`partners-${index}`}
                                    alt={name}
                                    src={image}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </StyledPartners>
    );
};

export default PartnersSection;
