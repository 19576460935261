import React from 'react';
import { COLORS } from '@constants';
import { Icon, Tooltip, Typography } from '@ui';
import { formatString } from '@utils';
import { Col, Row } from 'antd';
import { StyledIcon } from './styled';

const CardTitle = ({ title, tooltip }) => {
    return (
        <Row align='middle' justify='start' gutter={16}>
            <Col
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Tooltip title={tooltip} placement='right' trigger={['click', 'hover']}>
                    <span>
                        <StyledIcon
                            data-e2e={`insights_${formatString(title)}_tooltip`}
                            size='24px'
                            component={() => <Icon.QuestionCircleIcon />}
                            color={COLORS.$gray40Black}
                        />
                    </span>
                </Tooltip>
            </Col>
            <Col>
                <Typography.Text
                    data-e2e={`insights_${formatString(title)}-section_title`}
                    color={COLORS.$gray40Black}
                    type='badgeMedium'
                >
                    {title}
                </Typography.Text>
            </Col>
        </Row>
    );
};

export default CardTitle;
