import React, { useEffect, useState } from 'react';
import nextId from 'react-id-generator';
import { COLORS } from '@constants';
import { useNotification, usePolling, useStore } from '@hooks';
import { Button, Icon, Input, Typography } from '@ui';
import { Flex } from 'antd';
import { observer } from 'mobx-react';

const CARD_BRAND_ICON_MAP = {
    visa: Icon.VisaIcon,
    mastercard: Icon.MastercardIcon,
    amex: Icon.AmexIcon,
    discover: Icon.DiscoverIcon
};

export const PaymentMethods = observer(() => {
    const notification = useNotification();

    const [isPolling, setIsPolling] = useState(false);

    const store = useStore();
    const { paymentMethod, getSetupInfo } = store.accountsStore;

    const { startPolling, stopPolling } = usePolling(getSetupInfo, 5000, false);

    useEffect(() => {
        if (paymentMethod?.isVerified && isPolling) {
            setIsPolling(false);
            stopPolling();
            notification.success({
                message: 'Bank Account Verified!',
                description: 'Your bank account has been verified successfully'
            });
        }
    }, [paymentMethod?.isVerified]);

    const onVerifyClick = () => {
        setIsPolling(true);
        startPolling();
        window.open(paymentMethod.verification_link, '_blank', 'noreferrer');
    };

    if (!paymentMethod) {
        return null;
    }

    if (paymentMethod.type === 'card') {
        return (
            <Flex data-e2e='payment-method_card' vertical gap={16}>
                <Input
                    data-e2e='payment-method_value'
                    readOnly
                    key={nextId()}
                    label='Your credit card'
                    prefix={
                        <Icon
                            size='24px'
                            color={COLORS.$gray60Black}
                            component={CARD_BRAND_ICON_MAP[paymentMethod.brand] || ''}
                        />
                    }
                    suffix={
                        <Typography.Text type='descriptor1'>{`${paymentMethod.exp_month}/${paymentMethod.exp_year}`}</Typography.Text>
                    }
                    value={`${paymentMethod.brand} ending in ${paymentMethod.last4}`}
                />
            </Flex>
        );
    }

    if (paymentMethod.type === 'us_bank_account') {
        const suffixText = paymentMethod.isVerified ? 'Verified' : 'Not verified';
        const suffixColor = paymentMethod.isVerified ? COLORS.$robinBlue : COLORS.$error;

        return (
            <Flex data-e2e='payment-method_card' vertical gap={16}>
                <Input
                    data-e2e='payment-method_value'
                    readOnly
                    key={nextId()}
                    label='Your bank account'
                    suffix={
                        <Typography.Text data-e2e='payment-method-status' type='badgeMedium' color={suffixColor}>
                            {suffixText}
                        </Typography.Text>
                    }
                    value={`${paymentMethod.bank_name} ending in ${paymentMethod.last4}`}
                />

                {!paymentMethod.isVerified && (
                    <React.Fragment>
                        <Typography.Text
                            data-e2e='payment-method_not-verified_description'
                            color={COLORS.$gray60Black}
                            type='body2'
                        >
                            Your online bank account has not been verified. To make payments with this account, you must
                            prove that you are authorized to use it.
                        </Typography.Text>

                        <Typography.Text color={COLORS.$gray60Black} type='body2'>
                            Click{' '}
                            <Button
                                data-e2e='payment-method_verify-bank-account_btn'
                                style={{ padding: 0 }}
                                type='link'
                                onClick={onVerifyClick}
                            >
                                here
                            </Button>{' '}
                            to verify your bank account.
                        </Typography.Text>
                    </React.Fragment>
                )}
            </Flex>
        );
    }
});
