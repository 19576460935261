import React from 'react';
import { useStore } from '@hooks';
import { WhiteCover } from '@ui';
import { Col, Row } from 'antd';
import CategorySales from '../CategorySales';
import TopStatesMap from '../TopStatesMap';
import TopStatesTable from '../TopStatesTable';

const InsightExamples = () => {
    const store = useStore();
    const { top5States, barData } = store.insightsDemoStore;

    const showTopStatesData = Boolean(top5States.length);
    const showCategorySalesData = Boolean(barData.values.length);

    return (
        <WhiteCover title='Insight examples for your company'>
            <Row gutter={[24, 24]}>
                {showTopStatesData ? (
                    <React.Fragment>
                        <Col span={12}>
                            <TopStatesTable />
                        </Col>
                        <Col span={12}>
                            <TopStatesMap />
                        </Col>
                    </React.Fragment>
                ) : null}
                {showCategorySalesData ? (
                    <Col span={24}>
                        <CategorySales />
                    </Col>
                ) : null}
            </Row>
        </WhiteCover>
    );
};

export default InsightExamples;
