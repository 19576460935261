import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Button, Icon, Typography, WhiteCover } from '@ui';
import { Flex } from 'antd';
import { StyledAlert } from '../../styled';
import CoverTitle from '../CoverTitle';

const IntegrationRecharge = ({ isConnected, redirectUrl }) => {
    return (
        <WhiteCover
            data-e2e='integrations_recharge_section'
            customTitle={<CoverTitle title='Recharge' icon={<Icon.RechargeLogo />} />}
            rightContent={
                <Button
                    data-e2e='integrations_go-to-recharge_link'
                    type='link'
                    padding='0'
                    onClick={() => window.open('https://admin.rechargeapps.com/admin/login', '_blank')}
                >
                    Go to my Recharge Account
                </Button>
            }
            style={BOTTOM_INDENTS.M}
        >
            <Typography.Text
                data-e2e='integrations_recharge_description'
                type='body2'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.M}
            >
                Sync Recharge Payments to manage your subscription-based products (requires an active Recharge account).
            </Typography.Text>
            <StyledAlert data-e2e='integrations_recharge-connection-status_block' $isValid={isConnected}>
                {isConnected ? (
                    <Typography.Text align='center' color={COLORS.$robinBlue} type='descriptor1'>
                        Recharge connected.
                    </Typography.Text>
                ) : (
                    <Flex justify='space-between' align='center'>
                        <Typography.Text color={COLORS.$tartRed} type='descriptor1'>
                            Please Sign-in via your Recharge Account.
                        </Typography.Text>
                        <Button
                            data-e2e='integrations_connect-recharge_btn'
                            type='primary'
                            style={{ width: 160 }}
                            onClick={() => {
                                window.location.href = redirectUrl;
                            }}
                        >
                            Connect
                        </Button>
                    </Flex>
                )}
            </StyledAlert>
        </WhiteCover>
    );
};

export default IntegrationRecharge;
