import _ from 'lodash';
import { flow, getParent, types } from 'mobx-state-tree';
import { brandsOpenApi, COLORS } from '../constants';
import { axiosBoa, getErrorMessage } from '../utils';

export const InsightsDemoStore = types
    .model('InsightsDemoStore', {
        pending: false,
        error: types.maybeNull(types.frozen()),
        data: types.maybeNull(types.frozen())
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get top5States() {
            return self.data?.demographics.states.slice(0, 5) || [];
        },
        getStateByLabel(label) {
            return self.data?.demographics.states.find((state) => state.label === label);
        },
        get tableData() {
            return self.data?.demographics.states.map((state, i) => ({
                id: i + 1,
                state: state.label || 'N/A',
                sample: state.percent,
                records: state.records
            }));
        },
        get barData() {
            if (!self.data) return { labels: [], values: [], colors: [], valuesLabels: [] };

            const filteredCategories = self.data.purchase.category.filter((c) => c.totalSold);

            const top3 = _(filteredCategories).orderBy(['totalSold'], ['desc']).take(3).value();

            const labels = filteredCategories.map((c) => c.label);
            const values = filteredCategories.map((c) => c.totalSold);
            const colors = filteredCategories.map((c) =>
                top3.find((top) => top.label === c.label) ? COLORS.$pink : 'rgba(235, 125, 184, 1)'
            );

            return { labels, values, colors };
        }
    }))
    .actions((self) => ({
        getDemoInsights: flow(function* getDemoInsights() {
            self.error = null;
            self.pending = true;
            try {
                const data = yield axiosBoa.get(brandsOpenApi.performance.demoInsights.get);
                self.data = data.data;
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pending = false;
            }
        })
    }));
