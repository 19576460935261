import React from 'react';
import { Logo } from '@ui';
import Footer from '../../layouts/MainLayout/components/Footer';
import { StyledHeader } from './styled';
const BRANDS_FAQ_URL = 'https://klickly.typeform.com/to/SsLNeI';

const RequestAccess = () => {
    return (
        <React.Fragment>
            <StyledHeader>
                <Logo />
            </StyledHeader>
            <iframe
                style={{
                    width: '100%',
                    height: '100vh'
                }}
                title='PerksPage'
                src={BRANDS_FAQ_URL}
                frameBorder='0'
            />
            <Footer />
        </React.Fragment>
    );
};

export default RequestAccess;
