import React from 'react';
export const CUSTOMER_OPTIONS = [
    {
        label: <span data-e2e='performance_all-audiences_dropdown-item'>All audiences</span>,
        value: 'all'
    },
    {
        label: <span data-e2e='performance_new-customers_dropdown-item'>New customers</span>,
        value: 'new'
    },
    {
        label: <span data-e2e='performance_returning-customers_dropdown-item'>Returning customers</span>,
        value: 'returning'
    }
];
