import { COLORS } from '@constants';
import { Steps } from 'antd';
import styled from 'styled-components';

export const StepsStyled = styled(Steps)`
    &&& {
        justify-content: space-between;
        margin-top: 20px;

        .ant-steps-item {
            flex: 0 0 48%;
        }

        .ant-steps-item-content {
            width: 100%;
        }

        .ant-steps-item-icon {
            margin-inline-start: 0;
            margin: 0;
        }

        .ant-steps-item-container {
            margin: 0 auto;
            max-width: 160px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .ant-steps-item-tail {
            padding: 0;
            margin-inline-start: 95px;
            transition: all 0.2s ease-in-out;

            &:after {
                height: 3px;
                background-image: linear-gradient(
                    to right,
                    ${({ $isColoredTail }) => ($isColoredTail ? COLORS.$pink : COLORS.$gray40Black)} 20%,
                    rgba(255, 255, 255, 0) 60%
                );
                background-position: top;
                background-size: 5px 3px;
                background-repeat: repeat-x;
                background-color: transparent;
            }
        }
    }
`;
