import React, { Fragment, useEffect } from 'react';
import { BoostRadios, SectionHeader } from '@components';
import { boosts, COLORS, ROUTE_PATHS } from '@constants';
import { useStore } from '@hooks';
import { Button, Divider, Icon, RedirectLink, Select, Typography } from '@ui';
import { Card, Col, Form, notification, Row } from 'antd';
import { observer } from 'mobx-react';

const BOOST_ID = 'boostId';
const CAMPAIGN_ID = 'campaignId';

const BoostCampaign = observer(() => {
    const store = useStore();
    const { campaigns } = store.campaignsStore;
    const { updateCampaign, pending, setPending } = store.campaignWizardStore;
    const [form] = Form.useForm();
    Form.useWatch(CAMPAIGN_ID, form);
    Form.useWatch(BOOST_ID, form);

    useEffect(() => {
        form.setFieldValue(CAMPAIGN_ID, campaigns[0].id);
        form.setFieldValue(BOOST_ID, campaigns[0].boostId);
    }, []);

    const onFinish = async ({ boostId, campaignId }) => {
        const campaign = campaigns.find((campaign) => campaign.id === Number(campaignId));
        const boost = boosts.find((boost) => boost.id === Number(boostId));
        const description = `${boost.name} has been applied to ${campaign.title} campaign`;

        setPending(true);
        await updateCampaign({ campaignId, values: { boostId } });

        form.setFieldValue(BOOST_ID, campaigns[0].boostId);
        if (!store.campaignWizardStore.error) {
            notification.success({
                message: 'Success',
                description,
                duration: 5
            });
        }

        setPending(false);
    };

    const options = campaigns.map((campaign) => ({
        value: campaign.id,
        label: campaign.title,
        'data-e2e': `orders_campaign-${campaign.id}_option`
    }));

    return (
        <Fragment>
            <Col data-e2e='orders_no-orders_block' md={8} lg={10} xl={8}>
                <Row align='center'>
                    <Col>
                        <Typography.Title
                            data-e2e='orders_no-orders_text'
                            marginBottom='24px'
                            color={COLORS.$gray60Black}
                            level={2}
                        >
                            You have no orders yet
                        </Typography.Title>
                        <Typography.Text type='badgeSmall' color={COLORS.$gray60Black}>
                            Try increasing your brand&apos;s visibility by adding a weekly boost or <br /> setting a{' '}
                            <RedirectLink
                                data-e2e='orders_set-higher-commission_link'
                                display='inline'
                                text='higher commission'
                                to={ROUTE_PATHS.CAMPAIGNS}
                            />{' '}
                            campaign
                        </Typography.Text>
                    </Col>
                </Row>
            </Col>
            <Col md={16} lg={14} xl={16}>
                <Card data-e2e='orders_weekly-boost_block'>
                    <Form form={form} onFinish={onFinish}>
                        <SectionHeader data-e2e='orders_weekly-boost_title' title='Weekly Boost' level={2}>
                            <Form.Item name={CAMPAIGN_ID} noStyle>
                                <Select
                                    data-e2e='orders_select-campaign_dropdown'
                                    style={{ width: 320, outline: 'pink' }}
                                    options={options}
                                    label='Select campaign'
                                    disabled={pending}
                                    onChange={(campaignId) => {
                                        form.setFieldValue(
                                            BOOST_ID,
                                            campaigns.find((campaign) => campaign.id === campaignId).boostId
                                        );
                                    }}
                                    dropdownRender={(menu) => (
                                        <div data-e2e='orders_campaign-dropdown_menu'>{menu}</div>
                                    )}
                                />
                            </Form.Item>
                        </SectionHeader>
                        <Divider margin='24px 0' />
                        <Form.Item name={BOOST_ID} noStyle>
                            <BoostRadios data-e2e='orders_boosts_radios' />
                        </Form.Item>
                        <Row justify='end'>
                            <Col>
                                <Button
                                    data-e2e='orders_boost-campaign_btn'
                                    style={{ width: 210 }}
                                    loading={pending}
                                    htmlType='submit'
                                    type='primary'
                                    iconRight={<Icon size='24px' component={() => <Icon.ArrowForwardIcon />} />}
                                    disabled={!form.getFieldValue(CAMPAIGN_ID) || !form.getFieldValue(BOOST_ID)}
                                >
                                    Boost My Campaign
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>
        </Fragment>
    );
});

export default BoostCampaign;
