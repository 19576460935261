import { COLORS } from '@constants';
import { Input } from '@ui';
import styled from 'styled-components';

export const StyledInput = styled(Input)`
    &&.ant-input-disabled {
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }

        .ant-input-prefix {
            margin-inline-end: 7px;
        }

        .ant-input-suffix,
        .ant-input-prefix {
            pointer-events: auto;

            button {
                padding: 0;
            }
        }
    }
`;

export const StyledDescription = styled.div`
    border-radius: 8px;
    border: 1px solid ${COLORS.$alert};
    background-color: ${COLORS.$oldLace};
    padding: 24px;

    span {
        font-weight: 500;
    }
`;
