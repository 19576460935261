import React from 'react';
import { StyledTitle } from './styled';

const Title = ({ children, color, align, marginBottom, level, ...props }) => {
    return (
        <StyledTitle
            $level={level}
            level={level || 1}
            $color={color}
            $align={align}
            $marginBottom={marginBottom}
            {...props}
        >
            {children}
        </StyledTitle>
    );
};

export default Title;
