import React, { useCallback, useState } from 'react';
import { StyledAntdModal } from './styled';

const ModalContext = React.createContext(null);

export const Modal = ({
    children,
    width = '630px',
    centered = true,
    padding = '24px',
    fullscreen = false,
    maskClosable = true,
    footer = null,
    onClose,
    ...props
}) => {
    const customProps = {
        $padding: padding,
        $fullscreen: fullscreen,
        width: fullscreen ? null : width,
        onCancel: onClose,
        maskClosable,
        centered,
        footer,
        closable: false
    };

    return (
        <StyledAntdModal {...props} {...customProps}>
            {children}
        </StyledAntdModal>
    );
};

export const ModalProvider = ({ children, ...rest }) => {
    const [modal, _setModal] = useState();
    const [settings, setSettings] = useState({});

    const closeModal = useCallback(() => {
        _setModal();
    }, [_setModal]);

    const openModal = useCallback(
        (modal, settings = {}) => {
            _setModal(modal);
            if (typeof settings !== 'undefined') {
                setSettings({ ...settings });
            }
        },
        [_setModal, setSettings]
    );

    const setParams = useCallback(
        (settings = {}) => {
            if (typeof settings !== 'undefined') {
                setSettings((prev) => ({ ...prev, ...settings }));
            }
        },
        [setSettings]
    );

    return (
        <ModalContext.Provider value={{ closeModal, openModal, setParams, modal }} {...rest}>
            {children}
            {modal && (
                <Modal open={Boolean(modal)} onClose={closeModal} {...settings}>
                    {modal}
                </Modal>
            )}
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    const context = React.useContext(ModalContext);

    if (context === undefined) {
        throw new Error('useModal must be used within a ModalProvider');
    }

    return context;
};
