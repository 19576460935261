import React from 'react';
import { useModal } from '@hooks';
import ChangePasswordForm from '../components/ProfileForm/components/ChangePasswordForm';

export const useChangePasswordModal = () => {
    const { open, close } = useModal();

    const showChangePasswordModal = () => {
        open({
            title: <span data-e2e='profile_change-password-modal_title'>Change password</span>,
            content: <ChangePasswordForm onClose={close} />,
            settings: { maskClosable: false }
        });
    };

    return { showChangePasswordModal, close };
};
