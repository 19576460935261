import styled from 'styled-components';

export const StyledMainHeader = styled.header`
    z-index: 5;
    padding: 0 24px;
    position: relative;

    @media (max-width: 1200px) {
        border-bottom: 1px solid white;
        padding: 0;
    }

    & .main-header__wrapper {
        height: 80px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid white;

        @media (max-width: 1200px) {
            height: 64px;
            border: 0;
        }
    }

    & .main-header__wrapper__logo {
        a {
            display: block;

            .header-logo {
                height: 31px;
                width: 88px;
            }

            img {
                display: block;
            }
        }
    }

    & .main-header__wrapper__links {
        justify-content: space-between;
        display: flex;
        align-items: center;
        margin-left: auto;
        gap: 24px;

        a {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            font-family: 'IBM Plex Sans', Helvetica, sans-serif;
            color: white;
            text-decoration: none;

            @media (max-width: 860px) {
                display: none;
            }

            &:last-of-type {
                font-size: 16px;
                font-weight: 500;
                display: block;
                margin-left: 104px;
                margin-right: 32px;

                @media (max-width: 860px) {
                    display: block;
                    margin-right: 0;
                    font-size: 14px;
                }
            }
        }
    }
`;
