import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { COLORS } from '@constants';
import { useStore } from '@hooks';
import { Divider, Loader, Typography } from '@ui';
import { Col, Flex, Layout, Row } from 'antd';
import { observer } from 'mobx-react';
import NextButton from '../components/NextStepButton';
import { TOTAL_STEPS } from '../constants';
import { useCalculatePriority } from '../hooks/useCalculatePriority';
import { useCampaignWizard } from '../hooks/useCampaignWizard';
import { StyledLayout } from './styled';
const { Header } = Layout;

const CampaignWizardLayout = observer(({ children }) => {
    const store = useStore();
    const { getCampaign, resetCampaign, resetPreviewProducts } = store.campaignWizardStore;
    const { isCreate, isEdit, step, campaignId } = useCampaignWizard();

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        (async () => {
            if (campaignId) {
                await getCampaign(campaignId);
            }

            setLoading(false);
        })();

        return () => {
            resetCampaign();
            resetPreviewProducts();
        };
    }, []);

    const calculatePriority = useCalculatePriority();

    useEffect(() => {
        if (((isCreate && (step === 2 || step === 3)) || isEdit) && !loading) {
            calculatePriority();
        }
    }, [step, isEdit, isCreate, loading]);

    return (
        <StyledLayout>
            <Header tagName='div' style={{ padding: 0 }}>
                <Row gutter={24} align='middle' justify='space-between'>
                    <Col>
                        <Typography.Text
                            data-e2e={isEdit ? 'campaigns_edit-campaign_title' : 'campaigns_create-campaign_title'}
                            type='em0'
                        >
                            {isEdit ? 'Edit Campaign' : 'Create Campaign'}
                        </Typography.Text>
                    </Col>
                    <Col>
                        <Flex justify='center' align='center' gap={24}>
                            {isCreate && (
                                <Typography.Text data-e2e='campaignwizard_current-step_value' type='badgeMedium'>
                                    Step {step} of {TOTAL_STEPS}
                                </Typography.Text>
                            )}
                            <NextButton />
                        </Flex>
                    </Col>
                </Row>
            </Header>
            <Flex vertical style={{ flexGrow: 1 }}>
                <Divider
                    style={{ borderBlockStart: `1px solid ${COLORS.$gray20Black}`, flexGrow: 0 }}
                    margin='48px 0'
                />
                <div style={{ position: 'relative', flexGrow: 1 }}>{loading ? <Loader /> : children || <Outlet />}</div>
            </Flex>
        </StyledLayout>
    );
});

export default CampaignWizardLayout;
