import React from 'react';
import { COLORS, RIGHT_INDENTS } from '@constants';
import { Col, Row } from 'antd';
import { Button } from '../../../../../Button';
import { Icon } from '../../../../../Icon';
import { Typography } from '../../../../../Typography';

const PaginationPrev = ({ currentPage, totalPages }) => {
    return (
        <Row align='middle' gutter={16}>
            <Col>
                <Button data-e2e='pagination_prev_btn' disabled={currentPage === 1}>
                    <Icon color={COLORS.$gray60Black} size='24px' component={() => <Icon.ArrowBackIcon />} />
                </Button>
            </Col>
            <Col
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <Typography.Text data-e2e='pagination_current-page_value' type='badgeMedium' style={RIGHT_INDENTS.XXS}>
                    Page {currentPage} of {totalPages}
                </Typography.Text>
            </Col>
        </Row>
    );
};

export default PaginationPrev;
