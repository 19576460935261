import styled, { keyframes } from 'styled-components';

// Keyframes for moveUp animation
const moveUp = keyframes`
  100% {
    transform: translateY(calc(-100% - 16px));
  }
`;

// Keyframes for moveDown animation
const moveDown = keyframes`
  100% {
    transform: translateY(0);
  }
`;

// Keyframes for moveLeft animation
const moveLeft = keyframes`
  100% {
    transform: translateX(calc(-100% - 16px));
  }
`;

// Keyframes for moveLeftSafari animation
const moveLeftSafari = keyframes`
  100% {
    left: -1637px;
  }
`;

export const StyledPoweredBy = styled.div`
    overflow: hidden;
    clip-path: polygon(0px 150px, 100% 0px, 100% calc(100% - 150px), 0px 100%);

    @media (max-width: 830px) {
        clip-path: initial;
        margin-top: 50px;
    }

    &.bg-gradient {
        &:before {
            clip-path: polygon(0px 150px, 100% 0px, 100% calc(100% - 150px), 0px 100%);

            @media (max-width: 830px) {
                height: 80%;
                clip-path: polygon(0px 0px, 100% 30px, 100% 100%, 0px calc(100% - 30px));
            }
        }
    }

    .powered-by__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
        position: relative;
        gap: 30px;

        @media (max-width: 830px) {
            flex-direction: column;
            gap: 24px;
            padding-top: 25px;
        }

        &__left {
            h2 {
                font-size: 64px;
                font-style: normal;
                font-weight: 700;
                line-height: 64px;
                margin-bottom: 24px;
                font-family: 'IBM Plex Sans', Helvetica, sans-serif;
                color: #04080f;

                @media (max-width: 830px) {
                    font-size: 48px;
                    line-height: 44px;
                    margin-bottom: 16px;
                }
            }

            p {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                font-family: 'IBM Plex Sans', Helvetica, sans-serif;
                color: #04080f;

                @media (max-width: 830px) {
                    br {
                        display: none;
                    }
                }
            }
        }

        &__slider {
            width: 400px;
            display: flex;
            justify-content: space-between;
            padding: 60px 0;
            margin-top: -40px;
            gap: 20px;
            pointer-events: none;

            @media (max-width: 830px) {
                padding: 0;
                margin: 0;
                width: 100%;
            }

            &__left {
                pointer-events: none;
                & > img {
                    animation: ${moveUp} 16s linear infinite;
                    animation-delay: 0.4s;
                }
            }

            &__right {
                pointer-events: none;
                & > img {
                    transform: translateY(calc(-100% - 16px));
                    animation: ${moveDown} 16s linear infinite;
                    animation-delay: 0.4s;
                }
            }

            &__mob {
                height: 220px;
                width: 1621px;
                position: relative;
                transform: translate3d(0, 0, 0);
                will-change: transform;
                display: flex;
                gap: 16px;
                animation-delay: 0.5s;
                -webkit-backface-visibility: hidden;

                &.safari {
                    & > img {
                        animation: ${moveLeftSafari} 20s linear infinite;
                        animation-delay: 0.5s;
                        left: 0;
                        position: relative;
                    }
                }

                & > img {
                    width: 1621px;
                    height: 100%;
                    object-fit: contain;
                    will-change: transform;
                    animation: ${moveLeft} 20s linear infinite;
                    animation-delay: 0.2s;
                }
            }

            &__left,
            &__right {
                height: 550px;
                width: 188px;
                position: relative;
                transform: translate3d(0, 0, 0);
                will-change: transform;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                gap: 16px;

                & > img {
                    width: 100%;
                    height: 928px;
                    object-fit: contain;
                    will-change: transform;
                }
            }
        }
    }
`;
