import React from 'react';
import { BOTTOM_INDENTS, COLORS, RIGHT_INDENTS } from '@constants';
import { Icon, Loader, Tooltip, Typography } from '@ui';
import { Col } from 'antd';
import { InfoIcon } from '../../../Overview/components/CheckboxCard/styled';
import HorizontalCard from '../HorizontalCard';
import { StyledCard, StyledTitleWrap, StyledTooltipWrap } from './styled';

const HorizontalCards = ({
    data = [],
    title,
    pending = true,
    hintTitle = '',
    hintBody = '',
    type = 'performance',
    ...props
}) => {
    const showHint = Boolean(hintBody);
    const isHomePage = type === 'homepage';

    return (
        <Col span={24} style={BOTTOM_INDENTS.M} {...props}>
            <StyledCard
                data-e2e='engagement-funnel_section'
                $isHomePage={isHomePage}
                title={
                    <StyledTitleWrap>
                        <Typography.Text
                            color={COLORS.$gray40Black}
                            type={isHomePage ? 'badgeLarge' : 'badgeSmall'}
                            style={RIGHT_INDENTS.S}
                        >
                            {title}
                        </Typography.Text>
                        {showHint ? (
                            <Tooltip
                                title={
                                    <StyledTooltipWrap>
                                        <Typography.Text type='badgeMedium' color='white'>
                                            {hintTitle}
                                        </Typography.Text>
                                        <Typography.Text type='body2' color='white'>
                                            {hintBody}
                                        </Typography.Text>
                                    </StyledTooltipWrap>
                                }
                                trigger='hover'
                            >
                                <Icon
                                    style={{ paddingBlock: 4 }}
                                    size='24px'
                                    color={COLORS.$gray40Black}
                                    stroke='white'
                                    component={() => <InfoIcon />}
                                />
                            </Tooltip>
                        ) : null}
                    </StyledTitleWrap>
                }
            >
                {pending ? <Loader /> : null}
                {!pending
                    ? data.map((cardValue, i) => (
                          <HorizontalCard
                              key={i}
                              data={cardValue}
                              isFirstElem={i === 0}
                              isLastElem={i + 1 === data.length}
                              isHomePage={isHomePage}
                          />
                      ))
                    : null}
            </StyledCard>
        </Col>
    );
};

export default HorizontalCards;
