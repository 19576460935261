import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { BackButton } from '@components';
import { BOTTOM_INDENTS, COLORS, ROUTE_PATHS, TOP_INDENTS } from '@constants';
import { Divider, Typography } from '@ui';
import { formatString, isIframe } from '@utils';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { StyledWrapper } from './styled';

const AuthLayout = observer(
    ({
        bottomText = null,
        description = null,
        email = '',
        leftCol = <BackButton />,
        title,
        rightCol = null,
        children
    }) => {
        const location = useLocation();

        return (
            <StyledWrapper>
                <Row style={BOTTOM_INDENTS.M} justify='center'>
                    <Fragment>
                        <Col style={TOP_INDENTS.XL_PLUS} span={24}>
                            <Row align='middle'>
                                <Col span={4}>{isIframe() ? null : leftCol}</Col>
                                <Col span={16}>
                                    <Typography.Title
                                        data-e2e={`${formatString(title)}_page_title`}
                                        align='center'
                                        level={1}
                                    >
                                        {title}
                                    </Typography.Title>
                                </Col>
                                <Col span={4}>{rightCol}</Col>
                            </Row>
                        </Col>
                        <Divider
                            style={{ borderBlockStart: `1px solid ${COLORS.$gray15Black}` }}
                            margin={location.pathname.includes(ROUTE_PATHS.LOGIN) ? '10px 0 24px 0' : '10px 0 48px 0'}
                        />
                        {description && (
                            <Col span={24} style={{ marginBottom: 48 }}>
                                <Row justify='center'>
                                    <Col md={12} lg={10} xl={8} xxl={8}>
                                        <Typography.Title
                                            data-e2e={`${formatString(title)}_page_description`}
                                            align='center'
                                            level={3}
                                        >
                                            {description}{' '}
                                            {email && (
                                                <a
                                                    data-e2e={`${formatString(title)}_email_value`}
                                                    style={{ color: COLORS.$pink }}
                                                    href={'mailto:' + email}
                                                >
                                                    {email}
                                                </a>
                                            )}
                                        </Typography.Title>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Fragment>
                    <Col md={12} lg={10} xl={8} xxl={8}>
                        {children}
                    </Col>
                </Row>
                {bottomText ? (
                    <Typography.Title align='center' level={3} style={{ ...TOP_INDENTS.XL, ...BOTTOM_INDENTS.L }}>
                        {bottomText}
                    </Typography.Title>
                ) : null}
            </StyledWrapper>
        );
    }
);
export default AuthLayout;
