import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Icon, Typography, WhiteCover } from '@ui';
import { Col, Row } from 'antd';

const MessagesThatCustomersWillReceiveFromKlickly = ({ style }) => {
    return (
        <WhiteCover
            data-e2e='notifications_klickly-messages-to-customers_section'
            title='Messages that customers will receive from Klickly'
            style={style}
        >
            <Row gutter='24' align='middle' wrap={false} style={BOTTOM_INDENTS.M}>
                <Col>
                    <Icon size='56px' color={COLORS.$gray40Black} component={() => <Icon.CreditCardIcon />} />
                </Col>
                <Col>
                    <Typography.Title level={2}>Payment confirmation</Typography.Title>
                </Col>
            </Row>
            <Typography.Text
                data-e2e='notifications_klickly-messages-to-customers_description'
                type='body2'
                color={COLORS.$gray60Black}
            >
                Sent automatically to the customer after they make a payment via Klickly. For further information,
                customers are directed to contact you via your customer service information.
            </Typography.Text>
        </WhiteCover>
    );
};

export default MessagesThatCustomersWillReceiveFromKlickly;
