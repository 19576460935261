import React, { useMemo } from 'react';
import { InAd, KlicklyProvider } from '@klickly/klickly-templates';

const InAdItem = ({ promotions, brandedData }) => {
    const staticData = useMemo(() => {
        return {
            width: 300,
            height: 600,
            promotions: promotions.slice(0, 6),
            layout: ''
        };
    }, [promotions.length]);

    if (!promotions.length) {
        return null;
    }

    return (
        <KlicklyProvider staticDataContext={staticData}>
            <InAd brandedData={brandedData} />
        </KlicklyProvider>
    );
};

export default InAdItem;
