import { COLORS } from './constants/colors';

export const THEME = {
    token: {
        fontFamily: '"IBM Plex Sans", Sans',
        colorSuccess: COLORS.$robinBlue
    },
    components: {
        Layout: {
            colorBgLayout: COLORS.$gray10Black,
            headerBg: 'transparent'
        },
        Divider: {
            colorSplit: COLORS.$gray20Black,
            lineWidth: 1
        },
        Menu: {
            colorBgContainer: COLORS.$gray15Black,
            colorPrimary: COLORS.$pink,
            colorPrimaryBorder: COLORS.$pink,
            colorText: COLORS.$gray60Black,
            colorSplit: 'transparent',
            fontFamily: '"IBM Plex Sans", Sans',
            fontSize: 16,
            lineWidthBold: 4,
            margin: 12,
            colorTextLightSolid: 'red'
        },
        Typography: {
            colorLink: COLORS.$info,
            colorLinkHover: COLORS.$info,
            colorLinkActive: COLORS.$info,
            colorText: COLORS.$gray100Black,
            colorTextHeading: COLORS.$gray100Black,
            fontSize: 16,
            lineHeight: 1.5,
            fontWeightStrong: 500,
            fontSizeHeading5: 16,
            lineHeightHeading5: 1.5
        },
        Avatar: {
            colorTextPlaceholder: COLORS.$gray40Black
        },
        Tag: {
            fontSizeSM: 14,
            borderRadiusSM: 16,
            colorTextXS: COLORS.$gray60Black,
            marginXS: 0
        },
        DatePicker: {
            controlHeight: 52,
            borderRadius: 8,
            colorIcon: COLORS.$gray60Black,
            colorBorder: COLORS.$gray20Black,
            colorPrimary: COLORS.$pink,
            colorPrimaryHover: COLORS.$pink,
            colorTextHeading: COLORS.$gray100Black,
            controlItemBgActive: COLORS.$gray20Black,
            controlHeightSM: 32,
            boxShadowSecondary: '',
            controlPaddingHorizontalSM: 0,
            controlPaddingHorizontal: 0,
            marginXS: 0,
            padding: 0,
            paddingXS: 16,
            paddingXXS: 0,
            marginXXS: 0,
            borderRadiusSM: 0,
            fontSizeLG: 20
        },
        Button: {
            autoInsertSpaceInButton: false,
            borderRadius: 8,
            controlOutlineWidth: 0,
            colorBgContainer: COLORS.$gray10Black,
            colorBgContainerDisabled: COLORS.$gray10Black,
            colorTextDisabled: COLORS.$gray20Black,
            colorBorder: COLORS.$gray20Black,
            colorText: COLORS.$gray60Black,
            colorBgTextHover: COLORS.$gray20Black,

            colorLinkHover: COLORS.$gray20Black,
            colorPrimary: COLORS.$pinkGradient,
            colorPrimaryActive: COLORS.$pinkDark,
            colorPrimaryBorder: COLORS.$pink,
            colorPrimaryHover: COLORS.$gray20Black,
            colorLink: COLORS.$gray60Black,
            colorLinkActive: COLORS.$gray60Black,
            colorBgTextActive: COLORS.$gray60Black,
            controlOutline: 'transparent',
            controlTmpOutline: 'transparent',
            lineWidth: 2,
            lineWidthFocus: 2,
            fontSize: 16,
            lineHeight: 1.5,
            controlHeight: 56,
            paddingContentHorizontal: 18
        },
        Checkbox: {
            controlInteractiveSize: 24,
            colorPrimary: COLORS.$pink,
            colorPrimaryHover: COLORS.$pink,
            colorBorder: COLORS.$gray60Black,
            colorText: COLORS.$gray60Black,
            colorTextDisabled: COLORS.$white,
            colorBgContainerDisabled: COLORS.$white
        },
        Tooltip: {
            colorBgSpotlight: COLORS.$gray80Black,
            borderRadius: 4,
            paddingSM: 48,
            paddingXS: 16,
            sizePopupArrow: 28,
            fontSize: 14,
            lineHeight: 1.5
        },
        Switch: {
            colorPrimary: COLORS.$pink,
            colorPrimaryHover: COLORS.$pink,
            colorTextQuaternary: COLORS.$white,
            colorTextTertiary: COLORS.$white,
            fontSize: 16,
            lineHeight: 1.5,
            trackHeight: 28,
            trackHeightSM: 25
        },
        Radio: {
            colorPrimary: COLORS.$pink,
            colorPrimaryHover: COLORS.$pink,
            colorTextQuaternary: COLORS.$white,
            colorTextTertiary: COLORS.$white,
            fontSize: 16,
            lineHeight: 1.5
        },
        Tabs: {
            colorPrimary: COLORS.$pink,
            colorPrimaryHover: COLORS.$gray100Black,
            colorPrimaryActive: COLORS.$gray100Black,
            colorTextDisabled: COLORS.$gray20Black,
            lineWidthBold: 4,
            lineWidth: 1,
            colorBorderSecondary: COLORS.$gray15Black,
            colorText: COLORS.$gray60Black,
            fontSize: 16
        },
        Badge: {
            colorError: COLORS.$gray60Black,
            fontSize: 16
        },
        Input: {
            controlHeight: 56,
            colorBorder: COLORS.$gray20Black,
            colorBgContainerDisabled: COLORS.$gray20Black,
            colorPrimaryHover: COLORS.$gray60Black,
            colorText: COLORS.$gray100Black,
            colorTextDisabled: COLORS.$gray100Black,
            colorTextPlaceholder: 'transparent',
            colorError: COLORS.$tartRed,
            colorErrorBorderHover: COLORS.$tartRed,
            controlOutlineWidth: 0,
            fontSize: 16,
            paddingSM: 16,
            borderRadius: 8
        },
        InputNumber: {
            controlHeight: 56,
            colorBgContainerDisabled: COLORS.$gray20Black,
            colorBorder: COLORS.$gray20Black,
            colorPrimaryHover: COLORS.$gray60Black,
            colorText: COLORS.$gray100Black,
            colorTextDisabled: COLORS.$gray100Black,
            colorTextPlaceholder: COLORS.$gray40Black,
            colorError: COLORS.$tartRed,
            colorErrorBorderHover: COLORS.$tartRed,
            controlOutlineWidth: 0,
            fontSize: 16,
            paddingSM: 16,
            borderRadius: 8
        },
        Form: {
            colorError: COLORS.$tartRed,
            marginLG: 16
        },
        Select: {
            controlHeight: 56,
            controlHeightLG: 56,
            colorBorder: COLORS.$gray20Black,
            colorBgContainerDisabled: COLORS.$gray20Black,
            colorPrimaryHover: COLORS.$gray60Black,
            colorText: COLORS.$gray100Black,
            colorTextDisabled: COLORS.$gray100Black,
            colorTextPlaceholder: 'transparent',
            colorError: COLORS.$tartRed,
            colorErrorBorderHover: COLORS.$tartRed,
            controlOutlineWidth: 0,
            fontSize: 16,
            paddingSM: 17,
            borderRadius: 8,
            fontWeightStrong: 500,
            controlItemBgHover: COLORS.$gray10Black,
            controlItemBgActive: COLORS.$gray15Black
        },
        Table: {
            lineHeight: 1.9,
            colorBgContainer: COLORS.$white,
            fontWeightStrong: 500,
            colorTextHeading: COLORS.$gray40Black,
            colorFillAlter: COLORS.$gray15Black,
            controlItemBgActive: COLORS.$white
        },
        Slider: {
            colorPrimaryBorder: COLORS.$pink,
            colorPrimaryBorderHover: COLORS.$pink,
            colorPrimary: COLORS.$pink,
            trackBg: COLORS.$pink,
            trackHoverBg: COLORS.$pink,
            railSize: 8,
            railBg: COLORS.$gray20Black,
            railHoverBg: COLORS.$gray20Black,
            colorFillSecondary: COLORS.$gray20Black,
            colorFillTertiary: COLORS.$gray20Black,
            dotSize: 1,
            handleColor: COLORS.$pink,
            handleActiveColor: COLORS.$pink,
            handleLineWidth: 8,
            handleLineWidthHover: 8,
            handleSize: 12,
            dotBorderColor: 'transparent',
            colorFillContentHover: 'transparent',
            fontSize: 12,
            colorText: COLORS.$gray100Black
        },
        Progress: {
            defaultColor: COLORS.$pink
        }
    }
};
