import styled from 'styled-components';

export const StyledInfoSection = styled.div`
    position: relative;
    padding-bottom: 64px;
    overflow: hidden;

    &.bg-gradient {
        &:before {
            clip-path: polygon(0px 150px, 100% 0px, 100% calc(100% - 150px), 0px 100%);

            @media (max-width: 1000px) {
                height: 200px;
                top: 40px;
                clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px calc(100% - 30px));
            }
        }
    }

    .info-section__wrapper {
        z-index: 1;
        position: relative;
        display: flex;
        align-items: center;
        gap: 48px;

        @media (max-width: 1000px) {
            flex-direction: column;
            gap: 5px;
        }

        &.revert {
            .info-section__wrapper__content {
                order: -1;
            }
        }

        &__image {
            width: 100%;

            @media (max-width: 1000px) {
                display: flex;
                justify-content: center;
            }

            @media (max-width: 500px) {
                justify-content: flex-end;
            }

            img {
                width: 527px;
                height: 322px;

                @media (max-width: 500px) {
                    margin-right: -20px;
                }
            }
        }

        &__content {
            @media (max-width: 430px) {
                margin-top: -50px;
            }

            h3 {
                font-size: 64px;
                font-style: normal;
                font-weight: 700;
                line-height: 64px;
                margin-bottom: 16px;
                color: #04080f;
                font-family: 'IBM Plex Sans', Helvetica, sans-serif;

                @media (max-width: 1000px) {
                    font-size: 48px;
                    line-height: 56px;
                    text-align: right;
                }
            }

            a {
                @media (max-width: 1000px) {
                    margin-left: auto;
                }
            }

            ul,
            ol {
                list-style-type: disc;
                margin-left: 20px;
                margin-bottom: 16px;

                @media (max-width: 1000px) {
                    width: fit-content;
                    margin-left: auto;
                    list-style-type: none;
                    display: flex;
                    flex-direction: column;
                }

                li {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    color: #04080f;
                    font-family: 'IBM Plex Sans', Helvetica, sans-serif;
                    position: relative;

                    &:before {
                        display: none;
                        content: '•';
                        position: absolute;
                        left: -14px;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    @media (max-width: 1000px) {
                        text-align: right;
                        width: fit-content;
                        margin-left: auto;
                        display: inline;

                        &:before {
                            display: inline;
                            position: relative;
                            transform: none;
                        }
                    }
                }
            }
        }
    }
`;
