import React, { useEffect, useRef, useState } from 'react';
import { BOTTOM_INDENTS, COLORS, FIELD_NAME } from '@constants';
import { Icon, Typography, WhiteCover } from '@ui';
import { Checkbox, Flex, Form, Input } from 'antd';
import { observer } from 'mobx-react';
import {
    StyledInput,
    StyledKeywordsItems,
    StyledTag,
    StyledTextAreaText,
    TagsContainer,
    TextareaWithTags
} from './styled';

export const GoogleAds = observer(({ negativeKeywords = [] }) => {
    const form = Form.useFormInstance();
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    const handleTextAreaClick = () => {
        inputRef.current?.focus();
    };

    const handleInputChange = (e) => {
        const { value } = e.target;

        if (value.includes(',')) {
            addTags(value);
        } else {
            setInputValue(value);
        }
    };

    const handleKeyPress = (e) => {
        if (e.code === 'Enter' && !!inputValue) {
            e.preventDefault();
            addTags(inputValue);
        }
    };

    const addTags = (value) => {
        const newTags = value
            .split(',')
            .map((tag) => tag.trim())
            .filter((tag) => tag);
        const updatedTags = [...tags, ...newTags];
        setTags(updatedTags);
        setInputValue('');
        form.setFieldValue(FIELD_NAME.GOOGLE_ADS.NEGATIVE_KEYWORDS, updatedTags);
    };

    const handleTagClose = (removedTag) => {
        const updatedTags = tags.filter((tag) => tag !== removedTag);
        setTags(updatedTags);
        form.setFieldValue(FIELD_NAME.GOOGLE_ADS.NEGATIVE_KEYWORDS, updatedTags);
    };

    useEffect(() => {
        setTags(negativeKeywords || []);
    }, [negativeKeywords]);

    return (
        <WhiteCover data-e2e='creative-settings_google-ads_block' title='AI-Enabled Klickly Search Ads'>
            <Flex gap={24} align='center'>
                <Flex vertical style={{ flexBasis: '50%' }} gap={16}>
                    <Form.Item name={FIELD_NAME.GOOGLE_ADS.ENABLED_BY_BRAND} valuePropName='checked'>
                        <Checkbox data-e2e='creative-settings_google-ads_checkbox'>
                            <Typography.Title data-e2e='creative-settings_google-ads_checkbox_description' level={3}>
                                Use AI-powered search ads in my campaign (recommended)
                            </Typography.Title>
                        </Checkbox>
                    </Form.Item>
                    <TextareaWithTags>
                        <Form.List name={FIELD_NAME.GOOGLE_ADS.NEGATIVE_KEYWORDS}>
                            {(fields) => (
                                <StyledKeywordsItems>
                                    {fields.map(({ key, name }) => (
                                        <Form.Item key={key} name={name}>
                                            <Input />
                                        </Form.Item>
                                    ))}
                                </StyledKeywordsItems>
                            )}
                        </Form.List>
                        <StyledTextAreaText type='descriptor2' color={COLORS.$gray40Black}>
                            Exclude Keywords: Copy and paste comma-separated keywords here. (optional)
                        </StyledTextAreaText>
                        <TagsContainer onClick={handleTextAreaClick}>
                            {tags.map((tag, index) => (
                                <StyledTag key={index} closable onClose={() => handleTagClose(tag)}>
                                    {tag}
                                </StyledTag>
                            ))}
                            <StyledInput
                                data-e2e='creative-settings_google-ads-keywords_input'
                                ref={inputRef}
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                            />
                        </TagsContainer>
                    </TextareaWithTags>
                </Flex>
                <Flex vertical justify='center' align='center' style={{ flexBasis: '50%' }}>
                    <Icon size='80px' component={() => <Icon.SearchAds />} style={BOTTOM_INDENTS.M} />
                    <Typography.Text
                        data-e2e='creative-settings_google-ads_description'
                        type='body2'
                        color={COLORS.$gray60Black}
                        style={BOTTOM_INDENTS.SM}
                    >
                        Enhance your campaigns with Klickly’s AI-powered search ads. Best of all – you’ll only pay when
                        they convert (not on a CPC).
                    </Typography.Text>
                    <Typography.Text
                        data-e2e='creative-settings_google-ads_hint'
                        type='body2'
                        color={COLORS.$gray60Black}
                    >
                        Note: you can avoid overlap with other search ads by excluding keywords you’re already using
                        (e.g. in Google, Bing).
                    </Typography.Text>
                </Flex>
            </Flex>
        </WhiteCover>
    );
});
