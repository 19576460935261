/* eslint-disable */

export const tooltips = {
    insights: 'Klickly Insights provides various demographic, behavioral, and intentional insights about your audience and their households. Insights are based on data from your audience (where 18 years and older), their households, and their neighborhoods, and is sourced from a combination of Klickly\'s proprietary data and algorithms, as well as public, private, self-reported, and modeled data.',
    age: 'Represents the likely age of your audience (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data)',
    gender: 'Represents the likely gender of your audience (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data)',
    ethnicity:
        'Represents the likely ethnicity of your audience (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data)',
    ageGender:
        'Represents the likely age and gender of your audience (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data)',
    topStates:
        'Represents the most common states in which individuals in your audience likely reside (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data, including information about physical location and geography derived from postal addresses, census data, and metropolitan statistics)',
    topStatesSample: '‘Sample %’ is your records for a given value as a percentage of total records for a given metric',
    topStatesRecords: '‘Records’ is the number of your users',
    location:
        'Represents the most common states in which individuals in your audience likely reside (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data, including information about physical location and geography derived from postal addresses, census data, and metropolitan statistics)',
    netWorth:
        'Represents the likely range of household net worth (the amount by which assets exceed liabilities) (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data)',
    avgHouseholdSavings:
        'Represents the likely average difference between a household\'s disposable income and its expenditures on goods and services (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data)',
    discretionaryIncome:
        'Represents the likely household income remaining after deduction of taxes, other mandatory charges, and expenditure on necessary items (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data, with a unique view of income based on consumer spending, income tax, and payment behavior)',
    creditCartType:
        'Represents the credit card types that your audiences\' households use regularly (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data, including offline financial and insurance information like credit card characteristics and utilization, installment loan and mortgage activity, and insurance behavior)',
    purchaseCategory:
        'Represents the purchase categories for purchases made by your audience and their households (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data, including online and offline purchase information compiled from retailers and catalog merchants)',
    avgOnlinePurchase:
        'Represents the average online purchase amounts made by your audiences\' households in the past 6 months (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data, including online and offline purchase information compiled from retailers and catalog merchants)',
    avgOfflinePurchase:
        'Represents the average offline purchase amounts made by your audiences\' households in the past 6 months (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data, including online and offline purchase information compiled from retailers and catalog merchants)',
    paymentMethod:
        'Represents the preferred method of payment used by your audience (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data)',
    general:
        'Represents the general interest categories of your audience and their households (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data, including charitable giving, purchase data, subscriptions, and surveys)',
    travel: 'Represents the travel interests of your audience and their households (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data, including charitable giving, purchase data, subscriptions, and surveys)',
    music: 'Represents the music interest categories of your audience and their households (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data, including charitable giving, purchase data, subscriptions, and surveys)',
    contentPreferences:
        'Represents the reading interest categories of your audience and their households (sourced from a combination of Klickly\'s proprietary insights as well as public, private, self-reported, and modeled data, including charitable giving, purchase data, subscriptions, and surveys)'
};
