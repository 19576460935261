import _ from 'lodash';

export const getRandomTemplates = (layouts, number = 3) => {
    const filteredLayouts = Object.entries(layouts)
        .filter(([_, item]) => {
            return item.isAWA !== false;
        })
        .reduce((obj, [key, item]) => {
            return {
                ...obj,
                [key]: item
            };
        }, {});

    return _.sampleSize(Object.keys(filteredLayouts), number);
};

export const priceDiff = (item) => {
    if (!item.variants[0].originalPrice || !item.variants[0].price) return 0;

    return (
        Math.round(
            Math.max(((item.variants[0].originalPrice - item.variants[0].price) / item.variants[0].originalPrice) * 100)
        ) || 0
    );
};
