import { COLORS } from '@constants';

export const CHART_OPTIONS = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
        padding: {
            top: 56,
            bottom: 0,
            left: 16,
            right: 16
        }
    },
    elements: {
        line: {
            label: '',
            tension: 0.4,
            borderWidth: 4
        },
        point: {
            radius: 0,
            hoverRadius: 8,
            hoverBorderWidth: 4,
            backgroundColor: '#fff'
        }
    },
    plugins: {
        tooltip: {
            displayColors: false,
            backgroundColor: COLORS.$white,
            borderColor: COLORS.$gray20Black,
            borderWidth: 1,
            intersect: false,
            padding: 16,
            caretSize: 0,
            xAlign: 'left',
            yAlign: 'bottom',
            bodyColor: COLORS.$gray100Black,
            titleColor: COLORS.$gray100Black,
            titleFont: {
                size: 14
            },
            bodyFont: {
                size: 14,
                weight: 'medium'
            },
            usePointStyle: true,
            pointStyle: '',
            callbacks: {
                title: (context) => {
                    return context[0].dataset.dates[context[0].dataIndex];
                }
            }
        },
        legend: {
            display: false
        },
        title: {
            display: false
        }
    },
    interaction: {
        intersect: false
    },
    scales: {
        x: {
            grid: {
                display: true,
                color: COLORS.$gray15Black,
                lineWidth: 1,
                drawBorder: false
            },
            display: true,
            title: {
                display: true
            }
        },
        y: {
            barThickness: 'flex',
            gridLines: {
                color: '#F4F4F4',
                tickMarkLength: 0,
                drawBorder: false
            },
            ticks: {
                beginAtZero: true,
                padding: 10,
                fontSize: 12,
                fontFamily: 'Arial',
                fontColor: '#77838F'
            },
            grid: {
                display: true,
                color: COLORS.$gray10Black,
                lineWidth: 1,
                drawBorder: false
            },
            display: true,
            title: {
                display: false
            },
            suggestedMin: 0
        },
        'value-axis': {
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
                tickMarkLength: 0,
                zeroLineColor: 'transparent',
                display: false,
                drawBorder: false
            },
            border: {
                color: 'transparent'
            }
        }
    }
};
