import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Loader, Typography } from '@ui';
import { formatString } from '@utils';
import { Col, Empty } from 'antd';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { bgColorPluginConfig } from '../../../../constants';
import { StyledCard } from '../../styled';
import { options } from '../../utils';
import { StyledSpace } from './styled';

const HorizontalBar = ({ data, title, pending = true }) => {
    const [selectedCategory, setSelectedCategory] = useState('views');

    const handleSelectCategory = (key) => {
        setSelectedCategory(key);
    };

    const selectedData = data?.[selectedCategory];

    const labels = selectedData?.map((element) => element.title);

    const chartData = {
        labels: labels,
        datasets: [
            {
                borderRadius: 5,
                borderSkipped: false,
                barThickness: 18,
                axis: 'y',
                label: '',
                data: selectedData?.map((element) => element.value),
                backgroundColor: [COLORS.$pink]
            }
        ]
    };

    const checkCategoriesData = () => {
        Object.entries(mapData).forEach(([key, value]) => {
            if (value) {
                handleSelectCategory(key);
            }
        });
    };

    useEffect(() => {
        // if default category has no data, change default category
        if (!selectedData) {
            checkCategoriesData();
        }
    }, [selectedCategory]);

    const mapData = {
        views: data?.views,
        interactions: data?.interactions,
        revenue: data?.revenue
    };

    const isData = selectedData && selectedData.length !== 0;

    return (
        <Col data-e2e={`performance_${formatString(title)}_section`} span={24} style={BOTTOM_INDENTS.M}>
            <StyledCard
                title={
                    <Typography.Text
                        data-e2e={`performance_${formatString(title)}_title`}
                        color={COLORS.$gray40Black}
                        type='badgeSmall'
                    >
                        {title}
                    </Typography.Text>
                }
                extra={
                    <StyledSpace size='middle'>
                        {mapData.views && (
                            <Typography.Title
                                data-e2e={`performance_${formatString(title)}-views_btn`}
                                color={selectedCategory === 'views' ? COLORS.$gray100Black : COLORS.$gray60Black}
                                level={3}
                                onClick={() => handleSelectCategory('views')}
                            >
                                Views
                            </Typography.Title>
                        )}
                        {mapData.interactions && (
                            <Typography.Title
                                data-e2e={`performance_${formatString(title)}-interactions_btn`}
                                color={selectedCategory === 'interactions' ? COLORS.$gray100Black : COLORS.$gray60Black}
                                level={3}
                                onClick={() => handleSelectCategory('interactions')}
                            >
                                Interactions
                            </Typography.Title>
                        )}
                        {mapData.revenue && (
                            <Typography.Title
                                data-e2e={`performance_${formatString(title)}-revenue_btn`}
                                color={selectedCategory === 'revenue' ? COLORS.$gray100Black : COLORS.$gray60Black}
                                level={3}
                                onClick={() => handleSelectCategory('revenue')}
                            >
                                Revenue
                            </Typography.Title>
                        )}
                    </StyledSpace>
                }
            >
                {pending ? <Loader /> : null}
                {isData && !pending ? (
                    <Bar
                        data-e2e={`performance_${formatString(title)}-charts_section`}
                        plugins={[ChartDataLabels, bgColorPluginConfig]}
                        options={options(selectedCategory)}
                        data={chartData}
                    />
                ) : null}
                {!isData && !pending ? (
                    <Empty
                        data-e2e={`performance_${formatString(title)}-no-data_placeholder`}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                ) : null}
            </StyledCard>
        </Col>
    );
};

export default HorizontalBar;
