const STATUS = {
    NOT_CONNECTED: 0,
    PENDING: 1,
    CONNECTED: 2
};

export const PURCHASE_EVENT_STATUS = { ...STATUS };
export const PRODUCT_CATALOG_STATUS = { ...STATUS };
export const ORDERS_STATUS = { ...STATUS };
export const CUSTOMERS_STATUS = { ...STATUS };
