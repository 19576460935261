import React, { useState } from 'react';
import { PaymentMethods } from '@components';
import { COLORS, LEFT_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Button, Divider, Typography } from '@ui';
import { Flex } from 'antd';
import { observer } from 'mobx-react';
import InfoContentWrap from '../InfoContentWrap';
import AddPaymentMethodContent from './components/AddPaymentMethodContent';

const PaymentInfo = observer(({ closeCollapse }) => {
    const store = useStore();
    const { paymentIntent } = store.accountsStore;
    const [showAddPaymentForm, setShowAddPaymentForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState(false);

    const handleClose = (setupIntentStatus) => {
        setShowAddPaymentForm(false);
        if (setupIntentStatus === 'succeeded') {
            closeCollapse();
        }
    };

    const handleAddPaymentClick = async () => {
        setLoading(true);
        const response = await paymentIntent();
        setClientSecret(response.clientSecret);
        setLoading(false);
        setShowAddPaymentForm(true);
    };

    return (
        <InfoContentWrap>
            <Typography.Text
                data-e2e='launchcampaign_payment-info_description'
                type='body2'
                color={COLORS.$gray60Black}
            >
                We&apos;re pretty unique in that you don&apos;t need to pay upfront to advertise. Add a payment method
                info so that we can get paid when we make a sale.
                <Typography.Text as='span' type='body2' color={COLORS.$tartRed} style={LEFT_INDENTS.XXXS}>
                    Note: your campaigns won&apos;t go live until your payment method is verified.
                </Typography.Text>
            </Typography.Text>
            <Divider margin='32px 0 16px' />
            <Flex justify='space-between' align='center'>
                <Typography.Title style={{ padding: '16px 0' }} level={3}>
                    Billing Information
                </Typography.Title>
                {!showAddPaymentForm && (
                    <Button
                        data-e2e='launchcampaign_add-payment-method_btn'
                        type='link'
                        onClick={handleAddPaymentClick}
                        loading={loading}
                    >
                        Add new payment method
                    </Button>
                )}
            </Flex>
            {showAddPaymentForm ? (
                <AddPaymentMethodContent onCancel={handleClose} clientSecret={clientSecret} />
            ) : (
                <PaymentMethods />
            )}
        </InfoContentWrap>
    );
});

export default PaymentInfo;
