import { COLORS } from '@constants';
import styled, { css } from 'styled-components';

export const StyledLabel = styled.div`
    position: relative;

    label {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 3;
        border: 2px solid transparent;
        padding: 16px;
        color: ${COLORS.$gray40Black};
        font-size: 16px;
        pointer-events: none;
        touch-action: none;
        transition: all 0.2s ease-in-out;
    }

    :has(textarea:not(:placeholder-shown)) > label,
    :has(textarea:focus) > label {
        top: -4px;
        font-size: 12px;
        transition: all 0.2s ease-in-out;
    }

    ${({ $hasPrefix }) =>
        $hasPrefix &&
        css`
            label {
                left: 40px;
            }
        `};

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
        border-width: 2px;
    }
`;
