import { Input } from 'antd';
import styled from 'styled-components';

export const StyledInputPassword = styled(Input.Password)`
    .ant-input-prefix {
        margin-inline-start: 16px;
        order: 2;
        z-index: 4;
    }

    .ant-input-suffix {
        order: -1;
        margin-inline-end: 16px;
    }
`;
