import styled from 'styled-components';

export const StyledWrapper = styled.div`
    padding-block: 4;
    display: flex;
    height: auto;

    && span.anticon:hover {
        background-color: unset;
    }
`;
