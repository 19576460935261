import { ACCOUNT_USER_STATUS } from '@enums';
import _ from 'lodash';

export const calculateMembersGroups = (bdMembers, uiMembers) => {
    const membersToCreate = uiMembers.filter((member) => member.status === ACCOUNT_USER_STATUS.PENDING);
    const membersToDelete = bdMembers.filter((member) => !uiMembers.some((m) => member.id === m.id));
    const membersToUpdate = _.intersectionBy(uiMembers, bdMembers, 'id').filter((member) => {
        return !_.isEqual(
            _.pick(member, ['role', 'user']),
            _.pick(_.find(bdMembers, { id: member.id }), ['role', 'user'])
        );
    });

    return { membersToCreate, membersToDelete, membersToUpdate };
};

export const formatMembersGroups = ({ membersToCreate, membersToDelete, membersToUpdate }) => {
    membersToCreate = membersToCreate.map(({ id, role, user }) => ({ id, role, ...user }));
    membersToUpdate = membersToUpdate.map(({ id, role, user }) => ({ id, role, ...user }));

    return { membersToCreate, membersToDelete, membersToUpdate };
};

export const formatCommissions = ({ coldCustomer, newCustomer, restOfSite }) => {
    return { coldCustomer: coldCustomer || 0, newCustomer: newCustomer || 0, restOfSite: restOfSite || 0 };
};
