export const formatInputValue = (value = '', type = 'individual') => {
    if (type === 'individual') {
        const ssn = value.replace(/\D/g, '').substr(0, 9);
        const match = ssn.match(/^(\d{0,3})(\d{0,2})(\d{0,4})$/);

        if (match) return `${match[1]}${match[2] ? '-' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
        return ssn;
    }

    const ein = value.replace(/\D/g, '').substr(0, 9);
    const match = ein.match(/^(\d{0,2})(\d{0,7})$/);

    if (match) return `${match[1]}${match[2] ? '-' : ''}${match[2]}`;
    return ein;
};
