import { COLORS } from '@constants';
import { Row } from 'antd';
import styled from 'styled-components';

export const StyledApiKey = styled(Row)`
    margin-bottom: 24px;
    border: 1px solid ${COLORS.$gray20Black};
    border-radius: 8px;
`;

export const StyledApiKeyInfo = styled.div`
    margin-bottom: 24px;
    padding: 24px;
    border: 1px solid ${({ $isValid }) => ($isValid ? COLORS.$robinBlue : COLORS.$tartRed)};
    border-radius: 8px;
    background: ${({ $isValid }) => ($isValid ? COLORS.$robinTintsBlue : COLORS.$tartRedLite)};
`;
