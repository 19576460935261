import React from 'react';
import { COLORS, RIGHT_INDENTS } from '@constants';
import { Icon, Typography } from '@ui';
import { StyledWrapper } from './styled';

const Status = ({ validationStatus }) => {
    if (validationStatus === 0) {
        return (
            <StyledWrapper>
                <Icon size='24px' component={() => <Icon.CloseCircleIcon />} style={RIGHT_INDENTS.XXS} />
                <Typography.Text type='badgeSmall' color={COLORS.$error}>
                    Rejected
                </Typography.Text>
            </StyledWrapper>
        );
    }

    if (validationStatus === 2) {
        return (
            <StyledWrapper>
                <Icon size='24px' component={() => <Icon.CheckCircleIcon />} style={RIGHT_INDENTS.XXS} />
                <Typography.Text type='badgeSmall' color={COLORS.$robinBlue}>
                    Approved
                </Typography.Text>
            </StyledWrapper>
        );
    }

    return null;
};

export default Status;
