import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledText = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${COLORS.$black};

    code {
        font-weight: 700;
    }

    ul {
        margin-left: 20px;
    }
`;

export const StyledDescription = styled.div`
    border-radius: 8px;
    border: 1px solid ${COLORS.$alert};
    background-color: ${COLORS.$oldLace};
    padding: 24px;
`;

export const StyledInvalidParams = styled.div`
    border-radius: 8px;
    border: 1px solid ${COLORS.$tartRed};
    background-color: ${COLORS.$tartRedLite};
    padding: 24px;
`;

export const StyledCode = styled.div`
    position: relative;
    padding: 24px;
    border: 1px solid ${COLORS.$gray15Black};
    background-color: ${COLORS.$gray10Black};
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;

    button {
        position: absolute;
        top: 0;
        right: 0;
    }

    code {
        padding-right: 50px;
        display: block;
    }
`;

export const StyledLink = styled.a`
    color: ${COLORS.$info};
    font-size: inherit;
    font-weight: inherit;
`;
