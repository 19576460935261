import React from 'react';
import { Logo } from '@ui';
import { Col, Row } from 'antd';
import FooterLinks from '../../components/FooterLinks';
import FooterSocialLinks from '../../components/FooterSocialLinks';
import { StyledFooter, StyledInnerWrapper } from '../../styled';

const Footer = () => {
    return (
        <StyledFooter>
            <StyledInnerWrapper>
                <Row justify='space-between' align='middle'>
                    <Col data-e2e='footer_klickly_logo'>
                        <Logo />
                    </Col>
                    <Col data-e2e='footer_klickly_links'>
                        <FooterLinks />
                    </Col>
                    <Col data-e2e='footer_social_links'>
                        <FooterSocialLinks />
                    </Col>
                </Row>
            </StyledInnerWrapper>
        </StyledFooter>
    );
};

export default Footer;
