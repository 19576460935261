export const formatInputValue = (value = '') => {
    const phone = value.replace(/\D/g, '').substr(0, 10);
    const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})(\d{0,4})$/);

    if (match) {
        return `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
    }

    return phone;
};
