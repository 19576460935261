import React, { forwardRef } from 'react';
import Label from '../Label';
import { StyledInputNumber } from './styled';

const InputNumber = forwardRef(
    ({ prefix = null, label, placeholder, controls = false, width, fontSize, fontWeight, align, ...props }, ref) => {
        return (
            <Label label={label}>
                <StyledInputNumber
                    placeholder={label || placeholder}
                    $width={width}
                    $fontSize={fontSize}
                    $fontWeight={fontWeight}
                    $align={align}
                    type='number'
                    prefix={prefix}
                    controls={controls}
                    ref={ref}
                    {...props}
                />
            </Label>
        );
    }
);

InputNumber.displayName = 'InputNumber';

export default InputNumber;
