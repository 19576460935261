import React from 'react';
import { StyledTypography } from '../../styled';

const ShutdownSuccess = () => {
    return (
        <StyledTypography data-e2e='account-management_shutdown-success-modal_description'>
            Your account will be shutdown within a day. If you&apos;d like to erase the personal information of your{' '}
            <a href='mailto:privacy@klickly.com' target='_blank' rel='noreferrer'>
                privacy@klickly.com
            </a>
            .
        </StyledTypography>
    );
};

export default ShutdownSuccess;
