import React from 'react';
import { FIELD_NAME } from '@constants';
import { useStore } from '@hooks';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import ProductsTable from '../ProductsTable';

const LeftTable = observer(({ handleRowsSelectionChange, ...props }) => {
    const store = useStore();
    const { SELECTED_PRODUCTS } = FIELD_NAME.CAMPAIGN;
    const {
        campaign,
        accountProducts,
        accountProductsMeta,
        allAccountProductsSelected,
        selectedProductsIds,
        productsSelectionPending,
        getAccountProducts,
        searchValue
    } = store.campaignWizardStore;

    const onChange = (pagination) => {
        const params = { page: pagination.current, perPage: pagination.pageSize };
        if (searchValue) params.search = `title:${searchValue}`;
        getAccountProducts({ campaignId: campaign.id, params });
    };

    return (
        <Form.Item name={SELECTED_PRODUCTS} noStyle>
            <ProductsTable
                dataSource={accountProducts}
                selectedRowKeys={selectedProductsIds}
                onRowsSelectionChange={handleRowsSelectionChange}
                loading={productsSelectionPending}
                isAllSelected={allAccountProductsSelected}
                pagination={{
                    total: accountProductsMeta.total,
                    totalPages: accountProductsMeta.totalPages,
                    pageSize: accountProductsMeta.perPage,
                    current: accountProductsMeta.page
                }}
                onChange={onChange}
                {...props}
            />
        </Form.Item>
    );
});

export default LeftTable;
