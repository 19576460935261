import { Typography } from '@ui';
import { Flex } from 'antd';
import styled from 'styled-components';

export const StyledFlex = styled(Flex)`
    max-width: 256px;
    width: 100%;
    justify-content: flex-end;
`;

export const StyledLine = styled(Flex)`
    padding: 4px 0;
`;

export const StyledStatus = styled(Flex)`
    width: 100%;
    justify-content: space-between;
`;

export const StyledText = styled(Typography.Text)`
    display: flex;
    align-items: center;
    gap: 8px;
`;
