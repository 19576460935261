export const formatPrice = (value, maxDigits = 2) => {
    const toLocaleStringOptions = {
        minimumFractionDigits: maxDigits,
        maximumFractionDigits: maxDigits
    };

    const price = Math.abs(Number(value)).toLocaleString('en-US', toLocaleStringOptions).replace(/,$/, '.');

    if (Number(value) < 0) {
        return `-$${price}`;
    }

    return `$${price}`;
};
