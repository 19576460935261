import React from 'react';
import { COLORS, ROUTE_PATHS } from '@constants';
import { Button } from '@ui';
import ContactInfo from '../components/ContactInfo';
import CustomerServiceInfo from '../components/CustomerServiceInfo';
import PaymentInfo from '../components/PaymentInfo';

const STATUS_LABEL = {
    INCOMPLETE: 'Incomplete',
    FINISHED: 'Finished'
};

export const getSections = ({ store, closeCollapse, navigate }) => {
    const { isCustomerInfoValid, isKlicklyInfoValid, paymentMethod, manualStoreSetupFinished, isManual } =
        store.accountsStore;

    const sections = [
        {
            itemKey: 'customer-service',
            title: 'How will customers contact you?',
            content: <CustomerServiceInfo closeCollapse={() => closeCollapse('customer-service')} />,
            status: isCustomerInfoValid ? STATUS_LABEL.FINISHED : STATUS_LABEL.INCOMPLETE
        },
        {
            itemKey: 'payment-info',
            title: 'How will Klickly charge you?',
            content: <PaymentInfo closeCollapse={() => closeCollapse('payment-info')} />,
            status: paymentMethod && paymentMethod.isVerified ? STATUS_LABEL.FINISHED : STATUS_LABEL.INCOMPLETE
        },
        {
            itemKey: 'contact-info',
            title: 'How will Klickly contact you?',
            content: <ContactInfo closeCollapse={() => closeCollapse('contact-info')} />,
            status: isKlicklyInfoValid ? STATUS_LABEL.FINISHED : STATUS_LABEL.INCOMPLETE
        }
    ];

    if (isManual) {
        sections.push({
            itemKey: 'script-tag',
            title: 'Have you installed the Klickly script tag?',
            content: null,
            status: manualStoreSetupFinished ? (
                STATUS_LABEL.FINISHED
            ) : (
                <Button
                    onClick={() => navigate(ROUTE_PATHS.INTEGRATIONS_SETTINGS)}
                    backgroundColor={COLORS.$white}
                    padding={0}
                    type='link'
                >
                    Account Settings
                </Button>
            )
        });
    }

    return sections;
};
