import React from 'react';
import { COLORS } from '@constants';
import { Icon } from '../../../Icon';

const ExpandIcon = ({ expanded, onExpand, record }) =>
    expanded ? (
        <Icon
            size='16px'
            color={COLORS.$gray40Black}
            component={() => <Icon.ArrowChevronUpIcon />}
            onClick={(e) => {
                e.stopPropagation();
                onExpand(record, e);
            }}
            data-e2e={`common_${record.id ? record.id : record.klicklyOrderNumber}-expand-up_icon`}
        />
    ) : (
        <Icon
            size='16px'
            color={COLORS.$gray40Black}
            onClick={(e) => {
                e.stopPropagation();
                onExpand(record, e);
            }}
            component={() => <Icon.ArrowChevronDownIcon />}
            data-e2e={`common_${record.id ? record.id : record.klicklyOrderNumber}-expand-down_icon`}
        />
    );

export default ExpandIcon;
