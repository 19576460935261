import React from 'react';
import { Flex } from 'antd';
import MenuAccountItems from '../MenuAccountItems';
import MenuActionItems from '../MenuActionItems';
import { StyledCard } from './styled';

const DropdownMenu = ({ menuItems, accounts = [] }) => {
    return (
        <StyledCard>
            <Flex data-e2e='header_user-menu_dropdown' gap={48}>
                {accounts.length > 1 && <MenuAccountItems items={accounts} />}
                <MenuActionItems items={menuItems} />
            </Flex>
        </StyledCard>
    );
};

export default DropdownMenu;
