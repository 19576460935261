import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledNoLogo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${({ $size }) => `${$size}px` || '128px'};
    height: ${({ $size }) => `${$size}px` || '128px'};
    border-radius: 50%;
    border: 1px solid ${COLORS.$gray20Black};
    background: ${COLORS.$gray10Black};

    &:hover {
        background: ${COLORS.$gray40Black};
        opacity: 0.8;

        > p {
            display: none;
        }

        .anticon {
            color: ${COLORS.$white};
        }
    }

    + .ant-btn {
        position: absolute;
        right: calc(50% - 100px);
        bottom: -5px;
    }
`;
