import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
    position: sticky;
    top: 80px;
    background-color: ${COLORS.$gray10Black};
    z-index: 9;
    padding: 12px 0 0;
    margin-bottom: 48px;
`;

export const StyledRangePickerWrapper = styled.div`
    transform: translateY(-6px);
`;

export const StyledMenu = styled.div`
    width: 100%;
    display: flex;

    border-bottom: 1px solid ${COLORS.$gray20Black};
`;
