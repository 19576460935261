import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledText = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${COLORS.$black};

    ul {
        margin-left: 20px;
    }
`;

export const StyledLink = styled.a`
    color: ${COLORS.$info};
    font-size: inherit;
    font-weight: inherit;
`;

export const StyledDescription = styled.div`
    border-radius: 8px;
    border: 1px solid ${COLORS.$alert};
    background-color: ${COLORS.$oldLace};
    padding: 24px;
`;
