import React, { useEffect } from 'react';
import { ACCOUNT_STATUS, PLATFORM_NAME_BY_PLATFORM_ID } from '@enums';
import { useNotification, useStore } from '@hooks';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { COLORS } from '../../constants';

const getErrorByAccountStatus = (status, platformId) => {
    const ERROR_BY_STATUS_MAP = {
        [ACCOUNT_STATUS.BLOCKED]: {
            message: 'Action cannot be completed due to your account status',
            description: (
                <p>
                    Please{' '}
                    <a style={{ color: COLORS.$info }} href='mailto:support@klickly.com'>
                        contact support
                    </a>{' '}
                    to resolve this issue.
                </p>
            )
        },
        [ACCOUNT_STATUS.SHUTDOWN]: {
            message: 'Action cannot be completed due to your account status',
            description: (
                <p>
                    Please{' '}
                    <a style={{ color: COLORS.$info }} href='mailto:support@klickly.com'>
                        contact support
                    </a>{' '}
                    to resolve this issue.
                </p>
            )
        },
        [ACCOUNT_STATUS.PAUSED]: {
            message: 'Action cannot be completed due to your account status',
            description: 'Please unpause your account and try again'
        },
        [ACCOUNT_STATUS.DISCONNECTED]: {
            message: 'Action cannot be completed due to your account status',
            description: `Please ensure your ${PLATFORM_NAME_BY_PLATFORM_ID[platformId]} connection is up to date`
        }
    };

    return ERROR_BY_STATUS_MAP[status] || null;
};

export const ErrorNotificationWrapper = observer(({ children }) => {
    const store = useStore();
    const notification = useNotification();

    const stores = [
        store.accountsStore,
        store.authStore,
        store.campaignsStore,
        store.campaignWizardStore,
        store.forgotPasswordStore,
        store.insightsStore,
        store.integrationsStore,
        store.invoicesStore,
        store.ordersStore,
        store.performanceStore,
        store.productsStore,
        store.profileStore
    ].filter(Boolean);

    useEffect(() => {
        const disposers = stores.map((s) =>
            reaction(
                () => s.error,
                // eslint-disable-next-line
                (error) => {
                    if (error) {
                        if (error.description === 'Invalid Account Status') {
                            const status = store.accountsStore.currentAccount.status;
                            const platformId = store.accountsStore.currentAccount.platformId;

                            const { message, description } = getErrorByAccountStatus(status, platformId);
                            notification.error({
                                message,
                                description
                            });
                        } else {
                            notification.error({
                                message: error.title,
                                description: error.description
                            });
                        }
                    }
                }
            )
        );

        return () => {
            disposers.forEach((dispose) => dispose());
        };
    }, [notification, stores]);

    return <React.Fragment>{children}</React.Fragment>;
});
