import React, { Fragment, useEffect, useState } from 'react';
import { COLORS, defaultTimeProps } from '@constants';
import { useStore } from '@hooks';
import { Divider, Loader } from '@ui';
import { observer } from 'mobx-react';
import { SetupPayoutsModal } from '../../components/SetupPayoutsModal';
import { PERFORMANCE_BLOCKS } from './PerformanceSection/constants';
import CampaignsSection from './CampaignsSection';
import HeadSection from './HeadSection';
import PerformanceSection from './PerformanceSection';
import RecommendationsSection from './RecommendationsSection';
import SupportSection from './SupportSection';

const Home = observer(() => {
    const store = useStore();
    const { getMetricsOverview, getLiveCampaigns, pendingLiveCampaigns, liveCampaigns, getBreakdowns } =
        store.performanceStore;
    const { isActive, showSetupPayoutsModal, isBlocked } = store.accountsStore;

    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(showSetupPayoutsModal);

    useEffect(() => {
        (async () => {
            await Promise.all([
                getMetricsOverview({
                    ...defaultTimeProps,
                    metrics: PERFORMANCE_BLOCKS.map((item) => item.label)
                }),
                getLiveCampaigns(defaultTimeProps),
                getBreakdowns(defaultTimeProps)
            ]);
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <Loader size='120px' />;
    }

    return (
        <Fragment>
            {!isBlocked && <SupportSection />}
            <HeadSection />
            <Divider style={{ borderBlockStart: `1px solid ${COLORS.$gray20Black}` }} margin='48px 0' />
            {!pendingLiveCampaigns && !liveCampaigns?.length && !isActive ? null : (
                <React.Fragment>
                    <CampaignsSection />
                    <Divider style={{ borderBlockStart: `1px solid ${COLORS.$gray20Black}` }} margin='48px 0' />
                </React.Fragment>
            )}
            <PerformanceSection />
            {!isBlocked && <Divider style={{ borderBlockStart: `1px solid ${COLORS.$gray20Black}` }} margin='48px 0' />}
            {!isBlocked && <RecommendationsSection />}
            {!isBlocked && <SetupPayoutsModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}
        </Fragment>
    );
});

export default Home;
