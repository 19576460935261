import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    padding: 32px 24px;
    border: 1px solid ${COLORS.$gray15Black};
    border-radius: 8px;
    box-shadow: 0 8px 48px 0 rgba(0, 0, 0, 0.16);
    position: relative;
`;
