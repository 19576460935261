import React, { useState } from 'react';
import { BOTTOM_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Card, Col, Row } from 'antd';
import * as get from 'lodash/get';
import { observer } from 'mobx-react';
import { tooltips } from '../../constants';
import { sortData } from '../../helpers';
import BaselineInfo from '../BaselineInfo';
import CardTitle from '../CardTitle';
import BarChart from '../charts/BarChart';
import DoughnutChart from '../charts/DoughnutChart';
import SortingBlock from '../SortingBlock';
import { StyledChartWrapper, StyledWrapper } from './styled';

const PurchaseSection = observer(() => {
    const store = useStore();
    const [sorting, setSorting] = useState('desc');
    const { purchaseChartsData } = store.insightsStore;

    return (
        <StyledWrapper>
            <Row style={BOTTOM_INDENTS.M}>
                <Col span={24}>
                    <Card
                        data-e2e='insights_purchase-category_card'
                        title={<CardTitle title='Purchase Category' tooltip={tooltips.purchaseCategory} />}
                    >
                        <SortingBlock
                            activeSortState={sorting}
                            onAscClick={() => setSorting('asc')}
                            onDescClick={() => setSorting('desc')}
                        />
                        <StyledChartWrapper
                            data-e2e='insights_purchase-category_chart'
                            $height={700}
                            style={BOTTOM_INDENTS.M}
                        >
                            <BarChart
                                chartType='horizontal'
                                data={sortData(get(purchaseChartsData, 'category'), sorting)}
                                chartValuePrefix='$'
                                chartValueSuffix=''
                            />
                        </StyledChartWrapper>
                        <BaselineInfo />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24} style={BOTTOM_INDENTS.M}>
                <Col span={12}>
                    <Card
                        data-e2e='insights_online-purchase_card'
                        title={<CardTitle title='Avg. Online Purchase' tooltip={tooltips.avgOnlinePurchase} />}
                    >
                        <StyledChartWrapper data-e2e='insights_online-purchase_chart' $height={400}>
                            <BarChart data={get(purchaseChartsData, 'avgOnlinePurchase')} />
                        </StyledChartWrapper>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        data-e2e='insights_offline-purchase_card'
                        title={<CardTitle title='Avg. Offline Purchase' tooltip={tooltips.avgOfflinePurchase} />}
                    >
                        <StyledChartWrapper data-e2e='insights_offline-purchase_chart' $height={400}>
                            <BarChart data={get(purchaseChartsData, 'avgOfflinePurchase')} />
                        </StyledChartWrapper>
                    </Card>
                </Col>
            </Row>
            <Row style={BOTTOM_INDENTS.M}>
                <Col span={24}>
                    <Card
                        data-e2e='insights_payment-method_card'
                        title={<CardTitle title='Payment Method' tooltip={tooltips.paymentMethod} />}
                    >
                        <StyledChartWrapper data-e2e='insights_payment-method_chart' $height={400}>
                            <DoughnutChart data={get(purchaseChartsData, 'paymentMethod')} />
                        </StyledChartWrapper>
                    </Card>
                </Col>
            </Row>
        </StyledWrapper>
    );
});

export default PurchaseSection;
