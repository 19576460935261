import React from 'react';
import { COLORS } from '@constants';
import { Icon, Table } from '@ui';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
    && {
        width: 100%;
    }
`;

export const StyledIcon = styled(({ ...props }) => <Icon {...props} />)`
    margin-left: 6px;

    &:hover {
        color: ${COLORS.$gray100Black};
    }
`;
