import React from 'react';
import { COLORS } from '@constants';
import { Typography } from '@ui';
import { formatString } from '@utils';
import { Card, Col } from 'antd';

const SinglePerformance = ({ item }) => {
    return (
        <Col md={12} lg={12} xl={6} xxl={6}>
            <Card
                data-e2e={`home_performance-${formatString(item.title)}_card`}
                style={{ border: `1px solid ${COLORS.$gray15Black}` }}
                styles={{ body: { padding: 16 } }}
            >
                <Typography.Text color={COLORS.$gray40Black} align='center' type='badgeMedium'>
                    {item.title}
                </Typography.Text>
                <Typography.Text
                    data-e2e={`home_performance-${formatString(item.title)}_value`}
                    align='center'
                    type='em0'
                >
                    {item.formattedValue}
                </Typography.Text>
            </Card>
        </Col>
    );
};

export default SinglePerformance;
