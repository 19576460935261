import styled from 'styled-components';

export const StyledLandingPage = styled.div`
    background-color: white;
    // PRIVATE POLICY
    .policy {
        padding: 50px 20px;
        width: 100%;

        &-row {
            display: flex;
            justify-content: space-between;

            @media (max-width: 480px) {
                flex-direction: column;
            }
        }

        .text-normal {
            line-height: 20px;
            margin-bottom: 10px;
        }

        .policy__title.text-normal {
            font-size: 27px;
            margin-bottom: 27px;
        }

        .policy__date.text-normal {
            font-size: 16px;
            margin-bottom: 27px;
            float: right;
        }

        &-menu {
            margin-left: 20px;
            margin-bottom: 10px;

            &__item {
                list-style-type: disc;

                &.text-normal {
                    margin-bottom: 0;
                }
            }
        }

        &-submenu {
            margin-left: 30px;
            margin-bottom: 10px;

            &__item {
                list-style-type: disc;

                &.text-normal {
                    margin-bottom: 0;
                }
            }
        }

        &__link {
            color: #da1781;
            text-decoration: none;
        }

        .help {
            &__title {
                margin-top: 30px;
                margin-bottom: 25px;
                font-size: 17px;
                font-family: proxima_nova_semibold, Helvetica, sans-serif;
            }

            &__link {
                text-decoration: underline;
                color: #0baff0;
            }
        }

        .underline {
            display: block;
        }
    }

    .privacy {
        .policy-menu {
            margin-left: 40px;
        }

        &-menu__item {
            font-size: 14px;
            line-height: 1.43;
            font-family: proxima_nova_regular, Helvetica, sans-serif;
        }
    }

    .underline {
        text-decoration: underline;
    }

    // PRE-LAUNCH-BANNER
    .banner-content {
        position: relative;
        width: 100%;
        height: calc(100vh - 70px);

        @media (max-width: 480px) {
            height: calc(100vh - 110px);
        }

        @media only screen and (min-device-width: 375px) and (min-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
            height: calc(100vh - 130px);
        }

        @media only screen and (min-device-width: 375px) and (min-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
            height: calc(100vh - 160px);
        }

        &__desc {
            width: 65%;
            margin: 0 auto;
            text-align: center;
        }

        &__title {
            position: relative;
            margin: 45px auto 25px;
            font-family: proxima_nova_bold, Helvetica, sans-serif;
            font-size: 48px;
            line-height: 51px;
            letter-spacing: -0.4px;
            text-align: center;
            color: white;

            .is-active {
                padding-right: 15px;
            }
        }

        &__label {
            position: relative;
            padding-bottom: 22px;
            font-family: proxima_nova_regular, Helvetica, sans-serif;
            font-size: 14px;
            letter-spacing: -0.1px;
            text-align: center;
            color: white;
        }

        &__form {
            display: flex;
            justify-content: center;

            .input {
                width: 436px;
                margin-right: 4px;
            }

            .btn-small {
                position: relative;
                width: 154px;
            }
        }

        .input-wrapper {
            position: relative;
        }

        &__icon {
            display: none;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
        }

        .is-error .input {
            background: #d0011b;
        }

        .is-success {
            .input {
                padding-right: 35px;
            }

            .banner-content__icon {
                display: block;
            }

            .btn-small {
                background: #87b576;
            }
        }
    }

    // SHOPIFY BANNER
    .shopify {
        .banner-content {
            &__desc {
                padding-top: 73px;

                @media (min-width: 769px) {
                    min-height: 700px;
                }

                .actions {
                    width: 270px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 28px;

                    @media (max-width: 768px) {
                        margin-bottom: 10px;
                    }

                    .btn-middle {
                        position: relative;
                        display: block;
                        width: 100%;
                        height: 44px;
                        font-family: proxima_nova_regular, Helvetica, sans-serif;
                        font-size: 20px;
                        font-weight: bold;
                        letter-spacing: -0.1px;
                        text-transform: none;
                        border-radius: 4px;
                        box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.27);
                    }

                    .call-link {
                        position: relative;
                        padding-right: 24px;
                        margin-top: 18px;
                        font-family: proxima_nova_semibold, Helvetica, sans-serif;
                        font-size: 17px;
                        text-align: left;
                        line-height: 1.3;
                        color: #0baff0;
                        text-decoration: none;
                        background: url('./images/phone-call.svg') no-repeat;
                        background-position: right center;
                        -webkit-background-size: 19px;
                        background-size: 19px;
                    }
                }
            }

            &__logo {
                position: relative;
                display: block;
                margin-top: 130px;
                margin-right: auto;
                max-height: 65px;
            }

            &__title {
                display: flex;
                flex-direction: column;
                max-width: 430px;
                margin: 30px 0 0;
                text-align: left;
                font-size: 55px;
                color: #0baff0;
                line-height: 1.07;
            }

            .pagination {
                margin-top: 10px;
            }
        }

        .slider__img {
            opacity: 1;
            background-size: cover;
            background-attachment: fixed;
        }

        .is-active {
            .slider__img {
                background-attachment: initial;
            }
        }

        &.merchant-content {
            .btn-middle {
                display: block;
                margin: 0 auto;
            }
        }
    }

    // SCROLL ARROW

    .scroll-down-arrow-wrapper {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;

        @media (max-width: 480px) {
            height: 50px;
        }

        .scroll-down-arrow_round {
            width: 36px;
            height: 36px;
            position: relative;
            background-color: #e7e7e7;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;

            @media (max-width: 480px) {
                width: 30px;
                height: 30px;
            }
        }

        .scroll-down-arrow {
            width: 12px;
            height: 12px;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            border-radius: 1px;
            transform: rotate(45deg);
            position: relative;
            top: -2px;

            @media (max-width: 480px) {
                width: 9px;
                height: 9px;
            }
        }
    }

    // TIMER PRE LAUNCH
    .timer {
        position: relative;
        width: 470px;
        margin: 0 auto;
        padding-top: 173px;

        &__list {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        &__item {
            width: 90px;
        }

        &__number {
            padding-bottom: 10px;
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
            font-size: 72px;
            text-align: center;
            color: white;
        }

        &__line {
            width: 56px;
            height: 1px;
            background: white;
            margin: 0 auto;
        }

        &__caption {
            padding-top: 25px;
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
            font-size: 14px;
            letter-spacing: 1.4px;
            text-align: center;
            color: white;
        }
    }

    .brands-carousel {
        padding: 38px 0 84px;
    }

    .platforms-carousel {
        padding: 87px 0 47px;
    }

    .slider {
        position: absolute;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 0 600px rgba(0, 0, 0, 0.5) inset;
        overflow: hidden;

        .bottom-line {
            position: absolute;
            content: '';
            bottom: 0;
            height: 4px;
            left: 0;
            background-color: #da1781;
        }
    }

    .slider__container {
        display: flex;
        height: 100%;
        margin: 0 auto;
        padding-top: 73px;
        text-align: center;
        position: relative;
        width: calc(100% * 5);
        overflow: hidden;
        transition: transform 0.8s;
    }

    .slider__block {
        background: white;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .slider__img {
        width: 100%;
        position: relative;
        opacity: 0.5;
        height: 100%;
        background-color: white;

        &.img_1,
        &.img_2,
        &.img_3,
        &.img_4,
        &.img_5 {
            background-size: cover;
            background-attachment: fixed;
        }
    }

    .next {
        position: absolute;
        width: 36px;
        height: 68px;
        top: 50%;
        transform: translateY(-50%);
        right: 40px;
        color: #c7c7c7;
        z-index: 1;
        cursor: pointer;

        &-dark:before {
            content: '';
            position: absolute;
            width: 48px;
            height: 48px;
            border-left: 4px solid #4a4a4a;
            border-bottom: 4px solid #4a4a4a;
            transform: rotate(-135deg);
            top: 9px;
            right: 9px;
        }

        &-light:before {
            content: '';
            position: absolute;
            width: 48px;
            height: 48px;
            border-left: 4px solid #c7c7c7;
            border-bottom: 4px solid #c7c7c7;
            transform: rotate(-135deg);
            top: 9px;
            right: 9px;
        }
    }

    .prev {
        position: absolute;
        width: 36px;
        height: 68px;
        top: 50%;
        transform: translateY(-50%);
        left: 40px;
        color: #c7c7c7;
        z-index: 1;
        cursor: pointer;

        &-dark:before {
            content: '';
            width: 48px;
            height: 48px;
            border-left: 4px solid #4a4a4a;
            border-bottom: 4px solid #4a4a4a;
            transform: rotate(45deg);
            position: absolute;
            top: 9px;
            left: 9px;
        }

        &-light:before {
            content: '';
            width: 48px;
            height: 48px;
            border-left: 4px solid #c7c7c7;
            border-bottom: 4px solid #c7c7c7;
            transform: rotate(45deg);
            position: absolute;
            top: 9px;
            left: 9px;
        }
    }

    .pagination {
        position: relative;
        display: flex;
        margin-top: 30px;
        z-index: 1;
        cursor: pointer;

        &__item {
            width: 14px;
            height: 14px;
            margin-right: 10px;
            border-radius: 50%;
            border: solid 1px #da1781;

            &.is-active {
                background: #da1781;
            }
        }
    }

    // MERCHANT CONTENT
    .merchant-content {
        @include flex(column, flex-start, center);
        width: 100%;

        .video-block {
            width: 343px;
            height: 190px;
            margin: 23px auto 0;
            background: #a2b1c1;
            border-radius: 5px;
            overflow: hidden;

            iframe {
                border-radius: inherit;
            }
        }

        .text-normal strong {
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
        }
    }

    // HOW WORKING
    .how-working {
        padding-top: 90px;
        padding-bottom: 30px;

        .text-bold-light {
            font-size: 28px;
            font-weight: 600;
            letter-spacing: -0.2px;
            text-align: center;
        }

        .text-normal {
            max-width: 624px;
            margin: 27px auto;
            font-size: 17px;
            line-height: 1.76;
            text-align: center;
            font-weight: 300;
        }
    }

    // ACTUALLY WORKING
    .actually-working {
        padding: 26px 0 50px;

        &__block {
            display: flex;
            justify-content: space-around;
            flex-flow: row wrap;
            width: 100%;
        }

        &__column {
            display: flex;
            flex-flow: column wrap;
            flex-basis: 33%;
            justify-content: flex-end;
            align-items: center;
            min-width: 250px;
            margin: 10px auto 0;
            text-align: center;
            color: #4a4a4a;
        }

        &__column.marketing-messages .actually-working__title {
            max-width: 250px;
        }

        &__column.performance .actually-working__text {
            max-width: 200px;
        }

        &__title {
            max-width: 137px;
            margin-top: 50px;
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
            font-size: 22px;
            line-height: normal;
        }

        &__text {
            margin-top: 30px;
            max-width: 250px;
            font-family: proxima_nova_regular, Helvetica, sans-serif;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0;
        }

        &__text strong {
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
        }

        &__img {
            max-width: 110px;
            max-height: 110px;
        }

        &__label {
            margin-top: 70px;
            margin-bottom: 30px;
            font-family: proxima_nova_light, Helvetica, sans-serif;
            font-size: 24px;
            font-weight: 600;
            color: #4a4a4a;
            letter-spacing: -0.2px;
            text-align: center;
        }

        .actions {
            width: 270px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 28px auto 0;

            .btn-middle {
                position: sticky;
                display: block;
                width: 100%;
                height: 44px;
                font-family: proxima_nova_regular, Helvetica, sans-serif;
                font-size: 20px;
                font-weight: bold;
                letter-spacing: -0.1px;
                text-transform: none;
                border-radius: 4px;
                box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.27);
            }
        }
    }

    // INFO STEPS PRE LAUNCH
    .info-steps {
        position: relative;
        padding-top: 36px;
        padding-bottom: 60px;
        background-color: #f8f8f8;

        .text-bold-light {
            padding-bottom: 90px;
            font-size: 28px;
            text-align: center;
        }

        &__item {
            position: relative;
            min-height: 136px;
            padding: 40px 25px 40px 155px;

            &__title {
                margin-bottom: 10px;
                text-align: left;
                font-size: 20px;
                text-transform: uppercase;
                color: #313d4f;
                font-family: proxima_nova_light, Helvetica, sans-serif;
            }

            .text-middle {
                width: 338px;
                text-align: left;
                line-height: 27px;
                color: #313d4f;
            }

            &:after {
                position: absolute;
                width: 44px;
                height: 44px;
                top: 40px;
                left: 65px;
            }

            &.connect {
                &:after {
                    content: '';
                    background-image: url('./components/KlicklyWorkBanner/images/connect_icon.svg');
                }

                &.is-active {
                    &:after {
                        background-image: url('./components/KlicklyWorkBanner/images/connect_active_icon.svg');
                    }
                }
            }

            &.preview {
                &:after {
                    content: '';
                    background-image: url('./components/KlicklyWorkBanner/images/preview_icon.svg');
                }

                &.is-active {
                    &:after {
                        background-image: url('./components/KlicklyWorkBanner/images/preview_active_icon.svg');
                    }
                }
            }

            &.launch {
                &:after {
                    content: '';
                    background-image: url('./components/KlicklyWorkBanner/images/launch_icon.svg');
                }

                &.is-active {
                    &:after {
                        background-image: url('./components/KlicklyWorkBanner/images/launch_active_icon.svg');
                    }
                }
            }

            &.analyze {
                &:after {
                    content: '';
                    background-image: url('./components/KlicklyWorkBanner/images/analyze_icon.svg');
                }

                &.is-active {
                    &:after {
                        background-image: url('./components/KlicklyWorkBanner/images/analyze_active_icon.svg');
                    }
                }
            }

            &.is-active {
                background-color: white;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
            }
        }
    }

    .wrapper-show-block {
        visibility: hidden;
        overflow: hidden;
        position: absolute;
        width: 440px;
        height: 670px;
        top: 124px;
        right: 0;
        padding: 0 10px;
    }

    .show-block {
        width: 440px;
        height: 648px;
        border-top-left-radius: 11px;
        border-bottom-left-radius: 11px;
        background-color: white;
        box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.3);
        overflow: hidden;

        visibility: hidden;

        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);

        -webkit-transition: visibility 0s linear 200ms, -webkit-transform 200ms linear;
        -moz-transition: visibility 0s linear 200ms, -moz-transform 200ms linear;
        -ms-transition: visibility 0s linear 200ms, -ms-transform 200ms linear;
        -o-transition: visibility 0s linear 200ms, -o-transform 200ms linear;
        transition: visibility 0s linear 200ms, transform 200ms linear;

        &.is-opened {
            visibility: visible;

            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0);

            -webkit-transition-delay: 0s;
            -moz-transition-delay: 0s;
            -ms-transition-delay: 0s;
            -o-transition-delay: 0s;
            transition-delay: 0s;
        }

        .text-middle {
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }

    .show-block img {
        max-width: 100%;
    }

    // PEOPLE SAYING PRE LAUNCH
    .people-saying {
        padding: 40px;

        .text-bold-light {
            font-size: 28px;
            text-align: center;
        }

        &__row {
            display: flex;
            justify-content: space-around;
            flex-flow: row wrap;
            width: 100%;
            margin-top: 30px;
        }

        &-card {
            width: 300px;
            height: 457px;
            margin: 25px 0 auto;
            border-radius: 3px;
            background: white;
            box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.13);
            border: solid 1px #e3e3e3;
            border-top: 5px solid #da1781;

            &__comment {
                position: relative;
                display: flex;
                align-items: center;
                min-height: 330px;
                padding-left: 30px;
                padding-right: 30px;

                &:before {
                    content: '';
                    position: absolute;
                    top: 20px;
                    left: 20px;
                    width: 40px;
                    height: 35px;
                    background: url('./images/quotes.png') 0 0 no-repeat;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    width: 40px;
                    height: 35px;
                    background: url('./images/quotes.png') 0 0 no-repeat;
                }

                .text-normal {
                    font-family: proxima_nova_light, Helvetica, sans-serif;
                    font-size: 17px;
                    line-height: 1.82;
                    letter-spacing: -0.1px;
                    text-align: center;
                }
            }

            &-author {
                display: flex;
                align-items: center;
                padding: 25px 30px;
                height: 124px;
                background: #fafafa;

                &__info {
                    margin-left: 20px;
                }

                &__photo {
                    width: 66px;
                    height: 66px;
                    border-radius: 50%;
                }

                .text-bold {
                    margin-bottom: 6px;
                    font-size: 24px;
                    color: #313d4f;
                }

                .text-normal {
                    line-height: 1.5;
                    color: #313d4f;
                }
            }
        }
    }

    // START-COMPAIGN
    .start-campaign {
        padding: 24px 40px 28px;

        &:last-of-type {
            padding: 50px 40px 82px;
        }

        .text-bold-light {
            font-size: 24px;
            text-align: center;
            font-family: proxima_nova_regular, Helvetica, sans-serif;
            font-weight: 600;
            letter-spacing: -0.2px;
        }

        .text-middle {
            width: 65%;
            margin: 24px auto;
            font-family: proxima_nova_light, Helvetica, sans-serif;
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.1px;
            text-align: center;
        }

        .text-normal {
            margin-bottom: 22px;
            text-align: center;
        }

        .actions {
            width: 270px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 28px auto 0;

            .btn-middle {
                position: sticky;
                display: block;
                width: 100%;
                height: 44px;
                font-family: proxima_nova_regular, Helvetica, sans-serif;
                font-size: 20px;
                font-weight: bold;
                letter-spacing: -0.1px;
                text-transform: none;
                border-radius: 4px;
                box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.27);
            }

            .call-link {
                position: relative;
                padding-right: 24px;
                margin-top: 18px;
                font-family: proxima_nova_semibold, Helvetica, sans-serif;
                font-size: 17px;
                text-align: left;
                line-height: 1.3;
                color: #0baff0;
                text-decoration: none;
                background: url('./images/phone-call.svg') no-repeat;
                background-position: right center;
                -webkit-background-size: 19px;
                background-size: 19px;
            }
        }
    }

    .more-questions {
        position: relative;
        width: 100%;
        margin-top: 60px;
        padding: 22px 40px 30px;
        background: #fafafa;

        &__title {
            font-size: 28px;
            text-align: center;
            letter-spacing: -0.2px;
        }

        &__block {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        &__card {
            display: flex;
            flex-direction: column;
            width: 48%;
            margin-top: 60px;
            flex-shrink: 1;

            @media (max-width: 667px) {
                width: 100%;
                margin-top: 20px;
            }

            &:last-child &__top {
                min-height: 35px;

                @media (max-width: 667px) {
                    min-height: 0;
                }
            }

            &__top {
                min-height: 60px;
                margin-bottom: 10px;

                @media (max-width: 991px) {
                    min-height: 80px;
                }

                @media (max-width: 768px) {
                    min-height: 74px;
                }

                @media (max-width: 667px) {
                    min-height: 0;
                    margin-bottom: 10px;
                }
            }
        }

        &__question {
            padding-right: 40px;
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
            font-size: 22px;
            text-align: left;
            color: #4a4a4a;
            line-height: 26px;
        }

        &__answer {
            padding-right: 20px;
            font-family: proxima_nova_regular, Helvetica, sans-serif;
            font-size: 17px;
            text-align: left;
            color: #5e5d5e;
            line-height: 26px;

            i {
                font-family: proxima_nova_regular, Helvetica, sans-serif;
                font-style: italic;
            }
        }

        &__link {
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
            color: #0baff0;
            text-decoration: none;
        }
    }

    .padding-content {
        padding-top: 50px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        //SHOPIFY
        .shopify {
            .banner-content__desc {
                & .logos {
                    width: 70%;
                }
            }
        }

        // INFO STEPS PRE LAUNCH
        .info-steps {
            &__item {
                padding: 40px 25px 40px 100px;
                &:after {
                    left: 30px;
                }

                .text-middle {
                    width: 250px;
                }
            }
        }
    }

    @media (min-width: 481px) and (max-width: 767px) {
        // SHOPIFY BANNER
        .shopify {
            width: 100%;

            .prev {
                left: 10px;
            }
            .next {
                right: 10px;
            }

            .banner-content__desc {
                top: 50%;
                padding-top: 73px;

                & .logos {
                    display: none;
                }
            }
            .banner-content__title {
                font-size: 46px;
                line-height: 1.07;
            }

            .is-active .slider__img {
                background-attachment: initial;
            }
        }

        // INFO STEPS PRE LAUNCH
        .info-steps {
            &__item {
                padding: 24px 0 25px 60px;
                min-height: 0;

                &:after {
                    left: 16px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 30px;
                    width: 30px;
                    -webkit-background-size: contain;
                    background-size: contain;
                }

                &__title {
                    margin-bottom: 5px;
                    font-size: 17px;
                }

                .text-middle {
                    width: 170px;
                    font-size: 14px;
                    line-height: 1;
                }
            }

            & .wrapper-show-block {
                width: 300px;
                height: 450px;
                padding: 0 0 0 10px;
                top: 150px;

                .show-block {
                    width: 100%;
                    height: 100%;
                    box-sizing: content-box;
                    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .people-saying {
            position: relative;

            .text-bold-light {
                display: none;
            }

            &__wrapper {
                width: 300px;
                overflow-x: hidden;
                margin: 0 auto;
                box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.13);
            }

            &__row {
                width: 300px;
                margin-top: 0;
                flex-wrap: nowrap;
                justify-content: flex-start;
                transition: 0.5s ease-out;
            }

            &-card {
                height: 100%;
                min-width: 300px;
                margin-top: 0;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }

            .arrow {
                width: 15px;
                height: 15px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                cursor: pointer;

                &_prev {
                    left: 30px;
                    border-left: 1px solid #4a4a4a;
                    border-bottom: 1px solid #4a4a4a;
                }

                &_next {
                    right: 30px;
                    border-right: 1px solid #4a4a4a;
                    border-top: 1px solid #4a4a4a;
                }
            }
        }

        .more-questions {
            margin-top: 60px;
            padding: 22px 30px 30px;

            &__title {
                font-size: 26px;
            }

            &__question {
                padding-right: 0px;
                line-height: 24px;
            }

            &__answer {
                padding-right: 0px;
                line-height: 24px;
            }
        }
    }

    @media (max-width: 480px) {
        // SHOPIFY BANNER
        .shopify {
            .prev:before,
            .next:before {
                width: 30px;
                height: 30px;
            }

            .prev {
                left: 10px;
                transform: translateY(50%);
            }
            .next {
                right: 10px;
                transform: translateY(50%);
            }

            .banner-content {
                &__desc {
                    width: 75%;
                }

                &__logo {
                    margin: 6vh auto 0;
                }

                &__title {
                    align-items: center;
                    font-size: 28px;
                    line-height: 36px;
                }

                &__desc {
                    .actions {
                        width: 60vw;
                        margin: 28px auto 0;

                        .btn-middle {
                            width: 100%;
                            height: 44px;
                        }

                        .call-link {
                            width: 75%;
                            background-position: right center;
                            -webkit-background-size: 19px;
                            background-size: 19px;
                        }
                    }

                    & .pagination {
                        justify-content: space-between;
                        max-width: 60%;
                        margin: 16vh auto 0;

                        &__item {
                            margin-right: 0;
                        }
                    }

                    & .logos {
                        display: none;
                    }
                }
            }

            .call-link {
                display: none;
            }

            .slider__img {
                opacity: 1;
                background-size: cover;
                background-attachment: fixed;
            }

            .is-active .slider__img {
                background-attachment: initial;
            }

            .btn-middle {
                position: relative;
                display: block;
                width: 270px;
                height: 44px;
                margin-top: 28px;
            }
        }

        // HOW WORKING
        .how-working {
            .text-bold-light {
                font-size: 17px;
            }

            .text-normal {
                padding: 0 10px;
                font-size: 14px;
            }
        }

        .actually-working {
            padding: 0 10px 40px;

            &__text {
                font-size: 14px;
            }

            .text-bold-light {
                font-size: 17px;
            }

            .text-normal {
                font-size: 14px;
            }

            &__label {
                font-size: 17px;
            }
        }

        // INFO STEPS PRE LAUNCH
        .info-steps {
            padding-top: 10px;
            padding-bottom: 20px;

            .text-bold-light {
                padding-bottom: 35px;
                font-size: 17px;
            }

            &__item {
                padding: 12px 0 12px 50px;
                min-height: 0;

                &__title {
                    margin-bottom: 5px;
                    font-size: 12px;
                }

                .text-middle {
                    width: 170px;
                    font-size: 10px;
                    line-height: 1;
                }

                &:after {
                    left: 16px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 18px;
                    width: 18px;
                    -webkit-background-size: contain;
                    background-size: contain;
                }
            }

            & .wrapper-show-block {
                width: 150px;
                height: 256px;
                padding: 0 0 0 10px;
                top: 55px;

                .show-block {
                    width: 100%;
                    height: 100%;
                    box-sizing: content-box;
                    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        // PEOPLE-SAYING
        .people-saying {
            padding: 40px 0;
            position: relative;

            .text-bold-light {
                display: none;
            }

            &__wrapper {
                width: 300px;
                overflow-x: hidden;
                margin: 0 auto;
                box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.13);
            }

            &__row {
                width: 100%;
                margin-top: 0;
                flex-wrap: nowrap;
                justify-content: flex-start;
                transition: 0.5s ease-out;
            }

            &-card {
                height: 100%;
                min-width: 100%;
                transition: 0.5s ease-out;
                margin-top: 0;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }

            .arrow {
                width: 15px;
                height: 15px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                cursor: pointer;

                &_prev {
                    left: 15px;
                    border-left: 1px solid #4a4a4a;
                    border-bottom: 1px solid #4a4a4a;
                }

                &_next {
                    right: 15px;
                    border-right: 1px solid #4a4a4a;
                    border-top: 1px solid #4a4a4a;
                }
            }
        }

        // START-COMPAIGN
        .start-campaign {
            padding: 24px 10px;

            .text-bold-light {
                font-size: 17px;
            }

            .text-middle {
                width: 100%;
                font-size: 14px;
            }

            .actions {
                width: auto;
            }
        }

        .more-questions {
            margin-top: 40px;
            padding: 15px 15px 20px;

            &__title {
                font-size: 17px;
            }

            &__question {
                font-size: 15px;
                padding-right: 0px;
                line-height: 22px;
            }

            &__answer {
                font-size: 14px;
                padding-right: 0px;
                line-height: 22px;
            }
        }
    }

    @media (max-width: 360px) {
        .shopify .banner-content__desc .pagination {
            margin-top: 5vh;
        }

        // MERCHANT CONTENT
        .merchant-content .video-block {
            width: 90%;
        }

        .info-steps {
            &__item .text-middle {
                width: 120px;
            }

            & .wrapper-show-block {
                top: 75px;
            }
        }

        .people-saying {
            &__wrapper {
                width: 250px;
            }

            &__row {
                width: 100%;
            }

            &-card {
                min-width: 100%;

                &__comment {
                    padding-left: 10px;
                    padding-right: 10px;
                }

                &-author {
                    padding-right: 20px;
                    padding-left: 20px;
                }
            }

            .arrow {
                &_prev {
                    left: 10px;
                }

                &_next {
                    right: 10px;
                }
            }
        }

        .actually-working {
            padding-bottom: 0;
        }
    }

    .container-fluid {
        max-width: 1328px;
        width: 100%;
        margin: 0 auto;
        padding: 0 24px;
    }

    .container-content {
        max-width: 1112px;
        width: 100%;
        margin: 0 auto;
        padding: 0 24px;
    }

    .bg-gradient {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 0;
        }

        &.bg-gradient-pink {
            &:before {
                background: linear-gradient(-110deg, #c302a4 0%, #ff6cac 52.71%);
            }
        }
        &.bg-gradient-pink-invert {
            &:before {
                background: linear-gradient(110deg, #c402a4 0%, #ff6cac 50%);
            }
        }

        &.bg-gradient-orange {
            &:before {
                background: linear-gradient(-125.98deg, #ff8e76 17.27%, #ffa74e 59.7%);
            }
        }

        &.bg-gradient-orange-invert {
            &:before {
                background: linear-gradient(125.98deg, #ff8e76 17.27%, #ffa74e 59.7%);
            }
        }
    }
`;
