import React from 'react';
import { Bar } from 'react-chartjs-2';
import { COLORS } from '@constants';
import { useStore } from '@hooks';
import { Divider, Tooltip, Typography, WhiteCover } from '@ui';
import { Flex } from 'antd';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { tooltips } from '../../../../constants';
import QuestionCircleIcon from '../../../QuestionCircleIcon';

const CategorySales = () => {
    const store = useStore();
    const { barData } = store.insightsDemoStore;

    return (
        <WhiteCover hasDivider={false}>
            <Flex gap={16} align='center'>
                <Tooltip title={tooltips.purchaseCategory} trigger='hover'>
                    <div>
                        <QuestionCircleIcon />
                    </div>
                </Tooltip>
                <Typography.Text color={COLORS.$gray40Black} type='badgeMedium'>
                    Purchase Category
                </Typography.Text>
            </Flex>
            <Divider margin='24px 0' />
            <div
                style={{
                    height: barData.labels.length * 30 + 40
                }}
            >
                <Bar
                    plugins={[ChartDataLabels]}
                    options={{
                        indexAxis: 'y', // Horizontal bar chart
                        responsive: true,
                        maintainAspectRatio: false, // makes chart to take 100% width of its parent
                        elements: {
                            bar: {
                                backgroundColor: [COLORS.$pink],
                                borderSkipped: false,
                                borderWidth: 0,
                                borderRadius: 5
                            }
                        },
                        scales: {
                            x: {
                                display: false
                            },
                            y: {
                                beginAtZero: true,
                                // ticks - main bar labels settings (outside of bar)
                                ticks: {
                                    padding: 8,
                                    font: {
                                        family: 'sans-serif',
                                        weight: 'bold',
                                        size: 14
                                    }
                                },
                                // border - border between labels and bars
                                border: { display: false },
                                grid: { display: false }
                            }
                        },
                        plugins: {
                            // datalabels - string that we show overlayed on the bar
                            datalabels: {
                                anchor: 'start', // placement on the bar
                                align: 'end', // placement relatively to anchor
                                color: COLORS.$white,
                                font: {
                                    size: 14
                                },
                                padding: 8,
                                formatter: (value) => `$${Math.round(value)}` // Display the data value on each bar
                            },
                            legend: {
                                display: false
                            }
                        }
                    }}
                    data={{
                        labels: barData.labels,
                        datasets: [
                            {
                                data: barData.values,
                                barThickness: 18,
                                backgroundColor: barData.colors,
                                hoverBackgroundColor: barData.colors,
                                borderWidth: 0
                            }
                        ]
                    }}
                />
            </div>
        </WhiteCover>
    );
};

export default CategorySales;
