import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Typography } from '@ui';
import { StyledDescription, StyledLink, StyledText } from './styled';

const CustomersModal = () => {
    return (
        <React.Fragment>
            <Typography.Text type='descriptor1' color={COLORS.$black} style={BOTTOM_INDENTS.M}>
                Klickly offers several advanced commission options, including custom commissions on new and cold
                customers. In order to enable these advanced options, Klickly’s commission system needs to be able to
                determine when a shopper last purchased from your store (if ever).
            </Typography.Text>
            <StyledText type='descriptor1' color={COLORS.$black} style={BOTTOM_INDENTS.M}>
                You can enable these advanced commission options in 2 ways:
                <ul>
                    <li>
                        <b>
                            Webhooks (<i>Recommended</i>):
                        </b>{' '}
                        Use this method to provide historical and real-time updates about the status of your customers
                        (e.g. new or returning). See the{' '}
                        <StyledLink onClick={() => window.open('/docs/api#tag/webhook_orders', '_blank')}>
                            Webhooks guide
                        </StyledLink>{' '}
                        for more information.
                    </li>
                    <li>
                        <b>CSV Upload:</b> Use this method to upload your customers using a csv file. As this method
                        relies on <i>occasional</i> customer status updates, it is not recommended for ongoing customer
                        management. See the{' '}
                        <StyledLink onClick={() => window.open('/docs/api#tag/bulk_customers', '_blank')}>
                            csv upload guide
                        </StyledLink>{' '}
                        for more information.
                    </li>
                </ul>
            </StyledText>
            <StyledDescription>
                <Typography.Text align='left' type='descriptor1'>
                    Once at least 1 customer has been correctly imported, the status of this step will automatically
                    change to Connected.
                </Typography.Text>
            </StyledDescription>
        </React.Fragment>
    );
};

export default CustomersModal;
