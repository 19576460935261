import React, { Fragment } from 'react';
import { BrandColors, FontSelector, LogoUpload, OfferMessage, ProductImagesFormat } from '@components';
import { FIELD_NAME, TOP_INDENTS } from '@constants';
import { IMAGE_FORMAT } from '@enums';
import { useStore } from '@hooks';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import Header from './components/Header';
import SubmitButtons from './components/SubmitButtons';

const CreativeSettings = observer(() => {
    const store = useStore();
    const { setCreativeSettings } = store.profileStore;
    const [form] = Form.useForm();

    const onFormSubmit = () => {
        setCreativeSettings(form.getFieldsValue());
    };

    return (
        <Fragment>
            <Header />
            <Form
                style={TOP_INDENTS.M}
                form={form}
                initialValues={{
                    [FIELD_NAME.CREATIVE.FONT]: 'Arial',
                    [FIELD_NAME.CREATIVE.PRIMARY_COLOR]: '#DA1781',
                    [FIELD_NAME.CREATIVE.OFFER_MESSAGE]: 'AVAILABLE NOW!',
                    [FIELD_NAME.CREATIVE.PRODUCT_IMAGES_FORMAT]: IMAGE_FORMAT.CROPPED
                }}
                onFinish={onFormSubmit}
            >
                <FontSelector />
                <BrandColors />
                <OfferMessage />
                <LogoUpload />
                <ProductImagesFormat />
                <SubmitButtons />
            </Form>
        </Fragment>
    );
});

export default CreativeSettings;
