import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FIELD_NAME, ROUTE_PATHS } from '@constants';
import { useStore } from '@hooks';
import { getImageFromUploadedFile, getPickerColor } from '@utils';
import { Form, Layout } from 'antd';
import { observer } from 'mobx-react';
import Preview from '../../components/Preview';
import { useFormInit } from '../../form';
import Step3Content from './components/Content';
const { Sider } = Layout;

const Step3 = observer(() => {
    const navigate = useNavigate();

    const store = useStore();
    const {
        accountProductsIds,
        campaign,
        creativeSettingsUpdate,
        setFormSubmitting,
        formSubmitting,
        previewProductsPending,
        setIsNextDisabled,
        setIsNextLoading
    } = store.campaignWizardStore;

    const { updateData: updateGoogleAds } = store.googleAdsStore;

    const form = useFormInit();

    const font = Form.useWatch(FIELD_NAME.CREATIVE.FONT, form);
    const primaryColor = Form.useWatch(FIELD_NAME.CREATIVE.PRIMARY_COLOR, form);
    const secondaryColor = Form.useWatch(FIELD_NAME.CREATIVE.SECONDARY_COLOR, form);
    const offerMessage = Form.useWatch(FIELD_NAME.CREATIVE.OFFER_MESSAGE, form);
    const logo = campaign.creatives[0].logoUrl;
    const imageFormat = Form.useWatch(FIELD_NAME.CREATIVE.PRODUCT_IMAGES_FORMAT, form);

    const nextDisabled = !logo;

    const nextLoading = formSubmitting || previewProductsPending;

    useEffect(() => {
        setIsNextLoading(nextLoading);
    }, [nextLoading]);

    useEffect(() => {
        setIsNextDisabled(nextDisabled);
    }, [nextDisabled]);

    const onFinish = async (values) => {
        setFormSubmitting(true);
        await creativeSettingsUpdate({ values });
        await updateGoogleAds(values);
        await store.customCreativesStore.updateCustomCreatives(campaign?.id);
        setFormSubmitting(false);

        const { error } = store.campaignWizardStore;
        if (!error) navigate(`${ROUTE_PATHS.CAMPAIGNS_CREATE}/step-5/${campaign?.id}`);
    };

    return (
        <Layout hasSider style={{ justifyContent: 'space-between', gap: 40 }}>
            <Form form={form} onFinish={onFinish}>
                <Step3Content />
            </Form>
            <Sider width='300px' style={{ backgroundColor: 'unset' }}>
                <Preview
                    font={font}
                    primaryColor={getPickerColor(primaryColor)}
                    secondaryColor={getPickerColor(secondaryColor)}
                    logo={getImageFromUploadedFile(logo)}
                    customSelectedProducts={accountProductsIds}
                    offerMessage={offerMessage}
                    imageFormat={imageFormat}
                    brandLink={store.accountsStore?.currentAccount?.domains?.[0]?.shopDomain}
                    brandName={store.accountsStore?.currentAccount?.companyName}
                />
            </Sider>
        </Layout>
    );
});

export default Step3;
