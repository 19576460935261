import { COLORS } from '@constants';
import { Table } from '@ui';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
    &.ant-table-wrapper .ant-table-tbody > tr.disabled > td {
        background-color: ${COLORS.$gray10Black};
    }

    &.ant-table-wrapper .ant-table-tbody > tr.disabled:hover > td {
        background-color: ${COLORS.$gray10Black};
    }
`;
