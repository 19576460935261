import React from 'react';
import { COLORS } from '@constants';
import { Icon, Typography } from '@ui';
import { formatPrice } from '@utils';
import { Flex, Tooltip } from 'antd';

const sharedOnCell = (record) => {
    if (record.showOffset) {
        return {
            colSpan: 0
        };
    }
    return {};
};

export const columnsInner = [
    {
        width: '48px'
    },
    {
        dataIndex: 'title',
        render: (title, record) =>
            !record.showOffset ? (
                <Typography.Text data-e2e={`invoices_charge-item-${record.id}-title_value`} type='descriptor2'>
                    {title}
                </Typography.Text>
            ) : (
                <Flex align='center' justify='space-between' gap={24}>
                    <Flex align='center' gap={24}>
                        <Tooltip
                            title={
                                <Flex
                                    data-e2e='invoices_charge-offset-commission-tooltip_description'
                                    vertical
                                    gap={12}
                                >
                                    <Typography.Title color={COLORS.$white} level={3}>
                                        What is this?
                                    </Typography.Title>
                                    <Typography.Text color={COLORS.$white}>
                                        Consumers will most often buy on your site, but we also allow them to buy
                                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                                        directly within Klickly’s Commerce Modules. We call these "in-Klickly sales". If
                                        consumers buy within our widgets, that means we have funds to send you.
                                    </Typography.Text>
                                    <Typography.Text color={COLORS.$white}>
                                        You had enabled Commission Offset, which meant that funds earned from Commerce
                                        Module (in-Klickly) purchases were applied towards a Klickly marketing invoice.
                                    </Typography.Text>
                                </Flex>
                            }
                        >
                            <Icon
                                data-e2e='invoices_charge-offset-commission-tooltip_icon'
                                roundedSize='24px'
                                color={COLORS.$info}
                                roundedColor={COLORS.$robinTintsBlue}
                                hoverRoundedColor={COLORS.$robinTintsBlue}
                                size='16px'
                                component={() => <Icon.InfoIcon />}
                            />
                        </Tooltip>
                        <Typography.Text
                            data-e2e='invoices_charge-offset-commission-title_value'
                            type='descriptor1'
                            color={COLORS.$info}
                        >
                            Offset from a payout balance (Payout #{record.payoutNumber})
                        </Typography.Text>
                    </Flex>
                    <Typography.Text
                        data-e2e='invoices_charge-offset-commission-amount_value'
                        type='descriptor1'
                        color={COLORS.$info}
                    >
                        -{formatPrice(record.offsetAmount)}
                    </Typography.Text>
                </Flex>
            ),
        onCell: (record) => ({
            colSpan: record.showOffset ? 5 : 1
        })
    },
    {
        dataIndex: 'sequenceNumber',
        align: 'right',
        width: '20%',
        render: (sequenceNumber, record) => (
            <span data-e2e={`invoices_charge-item-${record.id}-sequence-number_value`}>{sequenceNumber}</span>
        ),
        onCell: sharedOnCell
    },
    {
        width: '20%',
        onCell: sharedOnCell
    },
    {
        dataIndex: 'chargedAmount',
        width: '20%',
        align: 'right',
        render: (amount, record) => (
            <span data-e2e={`invoices_charge-item-${record.id}-amount_value`}>{formatPrice(amount)}</span>
        ),
        onCell: sharedOnCell
    }
];
