import React from 'react';
import { ROUTE_PATHS } from '@constants';
import { useStore } from '@hooks';
import { observer } from 'mobx-react';
import { Icon } from '../Icon';
import { StyledLink } from './styled';

export const Logo = observer(() => {
    const store = useStore();
    const { me } = store.authStore;
    return (
        <StyledLink to={me ? ROUTE_PATHS.HOME : ROUTE_PATHS.ROOT}>
            <Icon.BrandsLogoIcon />
        </StyledLink>
    );
});
