import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { ROUTE_PATHS } from '@constants';
import { ACCOUNT_STATUS, CAMPAIGN_STATUS, CONTENT_TYPE } from '@enums';
import { useInstall, useStore } from '@hooks';
import { Button, Icon } from '@ui';
import { Flex } from 'antd';
import { observer } from 'mobx-react';

const DISABLED_STATUSES = [CAMPAIGN_STATUS.DELETED, CAMPAIGN_STATUS.SUBMITTED, CAMPAIGN_STATUS.FROZEN];

const TableItemActions = observer(({ campaignId }) => {
    const store = useStore();
    const { pauseCampaign, resumeCampaign, deleteCampaign, getCampaign } = store.campaignsStore;
    const campaign = getCampaign(campaignId);
    const { status, pending } = campaign;
    const { isActive, updateAccount, isPaused, isUninstalled, isShopify } = store.accountsStore;
    const { install, loading: isInstalling } = useInstall();
    const navigate = useNavigate();

    const disabled = DISABLED_STATUSES.includes(status);
    const loading = isInstalling || pending;

    const handleCampaignClick = async () => {
        if (isInstalling || pending) {
            return;
        }

        if (isUninstalled && isShopify) {
            return install();
        }

        if (isPaused) {
            await updateAccount({ status: ACCOUNT_STATUS.ACTIVE }, CONTENT_TYPE.JSON);
        }

        if (status === CAMPAIGN_STATUS.ACTIVE) {
            pauseCampaign(campaignId);
        }

        if (status === CAMPAIGN_STATUS.PAUSED || status === CAMPAIGN_STATUS.CREATED) {
            resumeCampaign(campaignId);
        }
    };

    const buttonConfigs = [
        {
            onClick: handleCampaignClick,
            icon: loading ? (
                <LoadingOutlined style={{ fontSize: '24px' }} />
            ) : status === CAMPAIGN_STATUS.ACTIVE ? (
                <Icon.PauseIcon data-e2e={`campaigns_pause-${campaignId}-campaign_btn`} />
            ) : (
                <Icon.PlayIcon data-e2e={`campaigns_resume-${campaignId}-campaign_btn`} />
            ),
            disabled
        },
        {
            onClick: () => navigate(`${ROUTE_PATHS.CAMPAIGNS}/edit/${campaignId}`),
            icon: <Icon.EditIcon data-e2e={`campaigns_edit-${campaignId}-campaign_btn`} />,
            disabled: !isActive || status === CAMPAIGN_STATUS.FROZEN || pending
        },
        {
            onClick: () => deleteCampaign(campaignId),
            icon: campaign.isDeleting ? (
                <LoadingOutlined style={{ fontSize: '24px' }} />
            ) : (
                <Icon.TrashIcon data-e2e={`campaigns_archive-${campaignId}-campaign_btn`} />
            ),
            disabled: disabled || pending
        }
    ];

    return (
        <Flex gap={4}>
            {buttonConfigs.map((config, i) => {
                return (
                    <Button
                        key={i}
                        fontSize='24px'
                        type='noStyle'
                        onClick={config.onClick}
                        padding='8px'
                        disabled={config.disabled}
                    >
                        {config.icon}
                    </Button>
                );
            })}
        </Flex>
    );
});

export default TableItemActions;
