import { brandsOpenApi } from '@constants';
import { axiosBoa, getErrorMessage } from '@utils';
import { flow, getParent, types } from 'mobx-state-tree';
import { Charge } from './constants';

export const InvoicesStore = types
    .model('InvoicesStore', {
        error: types.maybeNull(types.model({ title: types.string, description: types.string })),
        charges: types.array(Charge),
        chargesMeta: types.frozen(),
        chargesFilters: types.model({
            status: types.maybe(types.number),
            created_at_min: types.maybe(types.string),
            created_at_max: types.maybe(types.string)
        }),
        chargesSorters: types.model({
            sort: types.maybe(types.string),
            order: types.maybe(types.string)
        }),
        payouts: types.array(types.frozen()),
        payoutsMeta: types.frozen(),
        payoutsFilters: types.model({
            status: types.maybe(types.number),
            created_at_min: types.maybe(types.string),
            created_at_max: types.maybe(types.string)
        }),
        payoutsSorters: types.model({
            sort: types.maybe(types.string),
            order: types.maybe(types.string)
        }),
        retryChargeSubmitted: false
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => ({
        resetPayoutsFilters() {
            self.payoutsFilters = {};
        },
        updatePayoutsFilters(filters) {
            Object.assign(self.payoutsFilters, filters);
        },
        resetPayoutsSorters() {
            self.payoutsSorters = {};
        },
        resetChargesFilters() {
            self.chargesFilters = {};
        },
        updateChargesFilters(filters) {
            Object.assign(self.chargesFilters, filters);
        },
        resetChargesSorters() {
            self.chargesSorters = {};
        },
        updatePayoutsSorters(sorters) {
            Object.assign(self.chargesSorters, sorters);
        },
        updateChargesSorters(sorters) {
            Object.assign(self.chargesSorters, sorters);
        },
        getInvoices: flow(function* getInvoices(transactionType, params = {}) {
            self.error = null;

            if (transactionType === 'charges') {
                Object.assign(params, self.chargesFilters, self.chargesSorters);
            }

            if (transactionType === 'payouts') {
                Object.assign(params, self.payoutsFilters, self.payoutsSorters);
            }

            try {
                const response = yield axiosBoa.get(brandsOpenApi.invoices.list, {
                    params: {
                        transaction_type: transactionType,
                        ...params
                    }
                });

                self[transactionType] = response.data;
                self[`${transactionType}Meta`] = response.meta;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        downloadInvoices: flow(function* downloadInvoices(params = { transaction_type: 'charges' }) {
            self.error = null;

            if (params.transaction_type === 'charges') {
                Object.assign(
                    params,
                    {
                        page: self.chargesMeta?.page || 1,
                        limit: self.chargesMeta?.perPage || 10,
                        download: true
                    },
                    self.chargesFilters,
                    self.chargesSorters
                );
            }

            if (params.transaction_type === 'payouts') {
                Object.assign(
                    params,
                    {
                        page: self.payoutsMeta?.page || 1,
                        limit: self.payoutsMeta?.perPage || 10,
                        download: true
                    },
                    self.payoutsFilters,
                    self.payoutsSorters
                );
            }

            try {
                const { data: file, headers } = yield axiosBoa.get(brandsOpenApi.invoices.list, {
                    params,
                    fullResponse: true
                });

                const fileName = headers['content-disposition'].split(' ')[1].split('=')[1];

                return { file, fileName };
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        retryFailedInvoiceCharge: flow(function* retryChargeInvoice({ invoiceId }) {
            self.error = null;
            self.retryChargeSubmitted = true; // global for all invoices to prevent click on rest failed invoices - retry job will handle all of them

            try {
                yield axiosBoa.post(brandsOpenApi.invoices.retryCharge, {
                    invoiceId
                });
            } catch (error) {
                self.error = getErrorMessage(error);
                self.retryChargeSubmitted = false;
            }
        })
    }));
