import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginButton } from '@components';
import { COLORS, ROUTE_PATHS } from '@constants';
import { useStore } from '@hooks';
import { AuthLayout } from '@layouts';
import { Button, Icon } from '@ui';

const ForgotPasswordSuccess = () => {
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const store = useStore();
    const { whoAmI } = store.authStore;
    const email = location?.state?.email;

    const onBackToHomeClick = async () => {
        setLoading(true);
        await whoAmI();
        navigate(ROUTE_PATHS.HOME);
    };

    return (
        <AuthLayout
            title='Success!'
            description='Your password has been successfully changed for'
            email={email}
            rightCol={<LoginButton />}
        >
            <Button
                data-e2e='forgot-password_back-to-home_btn'
                loading={loading}
                onClick={onBackToHomeClick}
                type='primary'
                size='large'
                block
                justify='between'
                iconRight={<Icon size='24px' color={COLORS.$white} component={() => <Icon.ArrowForwardIcon />} />}
            >
                Back To Homepage
            </Button>
        </AuthLayout>
    );
};

export default ForgotPasswordSuccess;
