import React from 'react';
import { COLORS } from '@constants';
import { Icon } from '../../../Icon';
import { StyledTag } from './styled';

export const Tag = ({ label, value, closable, onClose, ...props }) => {
    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <StyledTag
            closeIcon={<Icon size='12px' color={COLORS.$gray60Black} component={() => <Icon.CloseIcon />} />}
            color={value}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            {...props}
        >
            {label}
        </StyledTag>
    );
};
