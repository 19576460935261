import React, { Fragment, useCallback } from 'react';
import { BOTTOM_INDENTS, COLORS, LEFT_INDENTS, VALIDATION_RULES } from '@constants';
import { Icon, Tooltip, Typography } from '@ui';
import { Flex, Form } from 'antd';
import _ from 'lodash';
import { FIELD_NAMES } from '../../../../constants';
import { CommissionInput } from '../CommissionInput';
import { InfoIcon } from '../InfoIcon';

const RestOfSiteCommission = () => {
    const form = Form.useFormInstance();

    const onChange = useCallback(
        _.debounce((value) => {
            if (value < 0) {
                form.setFieldValue(FIELD_NAMES.REST_OF_SITE_COMMISSION, 0);
            }

            if (value > 100) {
                form.setFieldValue(FIELD_NAMES.REST_OF_SITE_COMMISSION, 100);
            }
        }, 500)
    );

    return (
        <Flex justify='space-between' align='center'>
            <Flex style={{ paddingBlock: 16 }} gap={12} align='center'>
                <Typography.Text data-e2e='account-management_ros_block' type='badgeSmall'>
                    Rest-of-site commission
                </Typography.Text>
                <Tooltip
                    title={
                        <Fragment>
                            <Typography.Title
                                data-e2e='account-management_ros-tooltip_title'
                                level={3}
                                color={COLORS.$white}
                                style={BOTTOM_INDENTS.S}
                            >
                                What is Rest-of-site commission?
                            </Typography.Title>
                            <Typography.Text
                                data-e2e='account-management_ros-tooltip_description'
                                type='badgeSmall'
                                color={COLORS.$white}
                            >
                                Your rest-of-site commission is how you incentivize Klickly to advertise and drive
                                traffic to ALL other products in your store, not just those in your campaigns. (Note:
                                this commission will only apply when a product is not in a campaign)
                            </Typography.Text>
                        </Fragment>
                    }
                    trigger='hover'
                >
                    <Icon
                        data-e2e='account-management_ros-tooltip_icon'
                        style={{ ...LEFT_INDENTS.XXXS, paddingBlock: 4 }}
                        color={COLORS.$gray40Black}
                        stroke='white'
                        size='24px'
                        component={() => <InfoIcon />}
                    />
                </Tooltip>
            </Flex>
            <Flex style={{ width: 160 }}>
                <Form.Item name={FIELD_NAMES.REST_OF_SITE_COMMISSION} rules={VALIDATION_RULES.REQUIRED}>
                    <CommissionInput
                        data-e2e='account-management_ros_input'
                        onChange={onChange}
                        suffix={<Typography.Text type='badgeLarge'>%</Typography.Text>}
                    />
                </Form.Item>
            </Flex>
        </Flex>
    );
};

export default RestOfSiteCommission;
