import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
`;

export const StyledUnitWrapper300x600 = styled.div`
    width: 100%;
    min-height: 400px;
    display: grid;
    grid-template-columns: repeat(3, 300px);
    grid-row-gap: 24px;
    grid-column-gap: 24px;
    justify-items: center;
    justify-content: center;
`;

export const StyledUnitWrapper320x100 = styled.div`
    width: 100%;
    min-height: 400px;
    display: grid;
    grid-template-columns: repeat(3, 320px);
    grid-row-gap: 24px;
    grid-column-gap: 24px;
    justify-items: center;
    justify-content: center;
`;

export const StyledUnitWrapper320x50 = styled.div`
    width: 100%;
    min-height: 400px;
    display: grid;
    grid-template-columns: repeat(3, 320px);
    grid-row-gap: 24px;
    grid-column-gap: 24px;
    justify-items: center;
    justify-content: center;
`;

export const StyledUnitWrapper728x90 = styled.div`
    width: 100%;
    min-height: 400px;
    display: grid;
    grid-template-columns: 728px;
    grid-row-gap: 24px;
    grid-column-gap: 24px;
    justify-items: center;
    justify-content: center;
`;

export const StyledUnitWrapper970x250 = styled.div`
    width: 100%;
    min-height: 400px;
    display: grid;
    grid-template-columns: 970px;
    grid-row-gap: 24px;
    grid-column-gap: 24px;
    justify-items: center;
    justify-content: center;
`;

export const StyledUnitWrapper300x250 = styled.div`
    width: 100%;
    min-height: 400px;
    display: grid;
    grid-template-columns: repeat(3, 300px);
    grid-row-gap: 24px;
    grid-column-gap: 24px;
    justify-items: center;
    justify-content: center;
`;

export const StyledUnitWrapper160x600 = styled.div`
    width: 100%;
    min-height: 400px;
    display: grid;
    grid-template-columns: repeat(4, 160px);
    grid-row-gap: 24px;
    grid-column-gap: 24px;
    justify-items: center;
    justify-content: center;
`;

export const StyledUnitWrapper336x280 = styled.div`
    width: 100%;
    min-height: 400px;
    display: grid;
    grid-template-columns: repeat(3, 336px);
    grid-row-gap: 24px;
    grid-column-gap: 24px;
    justify-items: center;
    justify-content: center;
`;
