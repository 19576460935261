import { useCallback, useEffect, useRef, useState } from 'react';

export function usePolling(asyncCallback, delay, startImmediately = false) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const isPollingRef = useRef(startImmediately);
    const timeoutId = useRef(null);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const result = await asyncCallback();
            setData(result);
            setError(null); // Clear any previous error
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
            if (isPollingRef.current) {
                timeoutId.current = setTimeout(fetchData, delay);
            }
        }
    }, [asyncCallback, delay]);

    const startPolling = useCallback(() => {
        if (!isPollingRef.current) {
            isPollingRef.current = true;
            fetchData();
        }
    }, [fetchData]);

    const stopPolling = useCallback(() => {
        isPollingRef.current = false;
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
            timeoutId.current = null;
        }
    }, []);

    useEffect(() => {
        if (startImmediately) {
            isPollingRef.current = true;
            fetchData();
        }

        return () => {
            stopPolling();
        };
    }, [startImmediately, startPolling, stopPolling]);

    return { data, error, loading, startPolling, stopPolling, isPolling: isPollingRef.current };
}
