export const getOptions = (externalTooltipHandler, chartType) => {
    const verticalChartType = chartType === 'vertical';
    const xDefaultScaleOptions = {
        stacked: true,
        grid: {
            display: false
        },
        border: {
            display: false
        },
        ticks: {
            font: {
                size: 14,
                weight: 600
            }
        }
    };

    const yDefaultScaleOptions = {
        stacked: false,
        grid: {
            display: false
        },
        border: {
            display: false
        },
        ticks: {
            display: false
        }
    };

    const xScaleOptions = verticalChartType ? xDefaultScaleOptions : yDefaultScaleOptions;
    const yScaleOptions = verticalChartType ? yDefaultScaleOptions : xDefaultScaleOptions;

    return {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false,
                position: 'nearest',
                external: externalTooltipHandler
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: verticalChartType ? 'x' : 'y',
        scales: {
            x: xScaleOptions,
            y: yScaleOptions
        },
        interaction: {
            mode: 'point'
        }
    };
};
