import { COLORS } from '@constants';
import { Input } from '@ui';
import styled from 'styled-components';

export const CommissionInput = styled(Input.Number)`
    && {
        width: 178px;
        padding-inline: 16px;
        margin-top: 10px;

        &:hover {
            border: 1px solid ${COLORS.$gray20Black};
        }

        &:focus-within {
            border-color: ${COLORS.$gray60Black};
            box-shadow: 0 0 0 0px rgba(5, 145, 255, 0.1);
            outline: 0;
        }

        &:focus-within:hover {
            border-color: ${COLORS.$gray60Black};
        }
    }

    && .ant-input-number-input {
        font-size: 32px;
        font-weight: bold;
        line-height: 44px;
        padding: 6px 0;
        text-align: center;
    }

    && .ant-input-number-affix-wrapper {
        padding-inline: 16px;
    }

    .ant-input-number-prefix {
        margin-right: 10px;
    }
`;
