import React from 'react';
import { defaultTimeRange } from '@constants';
import { RangePicker } from '@ui';
import { MENU_ITEMS } from '../../constants';
import MenuItem from './components/Item';
import { StyledMenu, StyledRangePickerWrapper, StyledWrapper } from './styled';

const Menu = ({ onDateChange, activeAnchor }) => {
    const handleDataChange = (data) => {
        if (typeof onDateChange !== 'function') return;
        onDateChange({
            date_from: data.min,
            date_to: data.max
        });
    };

    return (
        <StyledWrapper>
            <StyledMenu data-e2e='performance_menu_section'>
                {MENU_ITEMS.map((item) => (
                    <MenuItem
                        key={item.id}
                        title={item.title}
                        active={activeAnchor === item.id}
                        onClick={() => document.getElementById(item.id)?.scrollIntoView({ behavior: 'smooth' })}
                    />
                ))}
                <StyledRangePickerWrapper data-e2e='performance_date-picker_btn'>
                    <RangePicker
                        key='date-picker'
                        defaultValue={defaultTimeRange}
                        allowClear={false}
                        onChange={handleDataChange}
                    />
                </StyledRangePickerWrapper>
            </StyledMenu>
        </StyledWrapper>
    );
};

export default Menu;
