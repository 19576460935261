import React from 'react';
import { StyledLabel } from './styled';

const Label = ({ children, label, hasPrefix, isMultiple }) => (
    <StyledLabel $hasPrefix={hasPrefix} $isMultiple={isMultiple}>
        {children}
        <label>{label}</label>
    </StyledLabel>
);

export default Label;
