import { useStore } from '@hooks';
import { REQUIRED_SCRIPT_TAG_PARAMS } from '../constants';

export const useScriptTag = () => {
    const store = useStore();
    const { scriptTag } = store.accountsStore;

    const urlMatch = scriptTag.match(/src="([^"]+)"/);

    const url = urlMatch ? urlMatch[1] : '';

    let hasRequiredParams = false;

    if (url) {
        const urlObject = new URL(url);

        const extractedKeys = Array.from(urlObject.searchParams.keys());

        hasRequiredParams = REQUIRED_SCRIPT_TAG_PARAMS.every((key) => extractedKeys.includes(key));
    }

    return { hasRequiredParams, scriptTag };
};
