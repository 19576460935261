import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { useStore } from '@hooks';
import { Progress, Typography, WhiteCover } from '@ui';
import { Flex, Skeleton } from 'antd';
import { observer } from 'mobx-react';
import { getCopyByPriorityValue } from '../../../../utils';
import { StyledProgressWrapper, StyledWrapper } from './styled';

const CampaignPriority = observer(() => {
    const store = useStore();
    const { priorityPending, campaignPriority } = store.campaignWizardStore;

    const { title, description } = getCopyByPriorityValue(campaignPriority);

    return (
        <WhiteCover data-e2e='campaignwizard_priority_block' title='Priority' style={BOTTOM_INDENTS.M}>
            <StyledWrapper>
                <StyledProgressWrapper>
                    <Progress
                        data_e2e='campaignwizard_priority-progress_circle'
                        strokeColor={{ '0%': '#FFF0A3', '33%': '#FFD700', '66%': '#FF41B3', '100%': '#D633FF' }}
                        type='circle'
                        strokeWidth={10}
                        percent={campaignPriority}
                        size={184}
                        format={() =>
                            priorityPending ? (
                                <Flex justify='center'>
                                    <Skeleton className='title' paragraph={false} active />
                                </Flex>
                            ) : (
                                <div data-e2e='campaignwizard_priority-progress_value'>{campaignPriority}%</div>
                            )
                        }
                    />
                </StyledProgressWrapper>
                <Flex vertical gap={16} style={{ width: '100%' }}>
                    {priorityPending ? (
                        <Flex>
                            <Skeleton className='subtitle' paragraph={false} active />
                        </Flex>
                    ) : (
                        <Typography.Text data-e2e='campaignwizard_priority-progress_title' type='badgeLarge'>
                            {title}
                        </Typography.Text>
                    )}

                    {priorityPending ? (
                        <Flex justify='center'>
                            <Skeleton className='description' title={false} paragraph={{ rows: 2 }} active />
                        </Flex>
                    ) : (
                        <Typography.Text
                            data-e2e='campaignwizard_priority-progress_description'
                            type='body2'
                            color={COLORS.$gray60Black}
                        >
                            {description}
                        </Typography.Text>
                    )}
                </Flex>
            </StyledWrapper>
        </WhiteCover>
    );
});
export default CampaignPriority;
