import { useState } from 'react';
import { PLATFORM_NAME } from '@enums';
import { useStore } from './useStore';

export const useInstall = () => {
    const store = useStore();
    const [loading, setLoading] = useState(false);

    const { shopDomain, companyName } = store.accountsStore;
    const { getEcommerceRedirect } = store.authStore;

    const install = async () => {
        setLoading(true);

        await getEcommerceRedirect(PLATFORM_NAME.SHOPIFY, {
            shop: shopDomain,
            token_type: 'offline',
            companyName
        });

        const { error } = store.authStore;

        if (error) {
            setLoading(false);
        }
    };

    return { install, loading };
};
