import { ROUTE_PATHS } from '@constants';

export const FOOTER_LINKS = [
    {
        title: 'Terms',
        key: ROUTE_PATHS.TERMS
    },
    {
        title: 'Privacy',
        key: ROUTE_PATHS.PRIVACY
    },
    {
        title: 'Do Not Sell My Personal Information',
        key: ROUTE_PATHS.DO_NOT_SELL
    },
    {
        title: 'Limit the Use of My Sensitive Personal Information',
        key: ROUTE_PATHS.LIMIT_USE
    },
    {
        title: 'Blog',
        key: ROUTE_PATHS.BLOG
    },
    {
        title: 'Help Center',
        key: ROUTE_PATHS.HELP_CENTER
    }
];
