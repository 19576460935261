export const REQUIRED_SCRIPT_TAG_PARAMS = [
    'ga4',
    'version',
    'platform',
    'account_id',
    'shopDomain',
    'b_type_cart',
    'b_type_visit',
    'b_type_checkout'
];
