export const PERFORMANCE_BLOCKS = [
    { title: 'Revenue', label: 'revenue' },
    { title: 'Spend', label: 'spend' },
    { title: 'ROAS', label: 'roas' },
    { title: 'New Customers', label: 'new_customers' },
    { title: 'Product Views', label: 'views' },
    { title: 'Interactions', label: 'interactions' },
    { title: 'Orders', label: 'orders' },
    { title: 'Average Order Value', label: 'aov' }
];
