import React, { useContext, useRef } from 'react';
import { Form } from 'antd';

export const CampaignWizardFormContext = React.createContext();

export const CampaignWizardFormProvider = ({ children }) => {
    const formRef = useRef();

    return <CampaignWizardFormContext.Provider value={{ formRef }}>{children}</CampaignWizardFormContext.Provider>;
};

export const useFormContext = () => {
    const { formRef } = useContext(CampaignWizardFormContext);
    return formRef;
};

export const useFormInit = () => {
    const [form] = Form.useForm();
    const formRef = useFormContext();
    formRef.current = form;

    return form;
};
