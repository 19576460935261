import { Flex, Upload } from 'antd';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
    margin-top: 24px;
`;

export const StyledContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledUpload = styled(Upload)`
    .ant-upload {
        width: 100%;
    }
`;

export const StyledButtons = styled(Flex)`
    max-width: 345px;
    width: 100%;
`;

export const StyledImagePreview = styled.div`
    width: ${({ $width }) => `${$width}px`};
    height: ${({ $height }) => `${$height}px`};
    position: relative;
    padding: 0;
    margin: 0;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: contain; /* Ensure the image covers the entire area */
    }
`;

export const StyledBottomWrap = styled.div`
    width: 100%;
    padding: 0 24px;
`;
