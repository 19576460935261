import { Card } from 'antd';
import styled, { css } from 'styled-components';

export const StyledCard = styled(Card)`
    & .ant-card-head {
        border-bottom: none;
        min-height: auto;
        padding-top: 24px;
    }

    & .ant-card-body {
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: content-box;
    }

    ${({ $isHomePage }) =>
        $isHomePage
            ? css`
                  &&& {
                      background-color: transparent;
                      border-style: none;

                      .ant-card-head,
                      .ant-card-body {
                          padding-left: 0;
                          padding-right: 0;
                      }

                      .ant-card-head {
                          padding-top: 0;
                      }
                  }
              `
            : ''}
`;

export const StyledTitleWrap = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledTooltipWrap = styled.div`
    display: flex;
    flex-direction: column;
`;
