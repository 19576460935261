import { brandsOpenApi } from '@constants';
import { axiosBoa, getErrorMessage } from '@utils';
import { flow, getParent, types } from 'mobx-state-tree';

export const GoogleAdsStore = types
    .model('GoogleAdsStore', {
        pending: types.maybeNull(types.boolean),
        error: types.maybeNull(types.model({ title: types.string, description: types.string })),
        negativeKeywords: types.maybeNull(types.array(types.string)),
        enabledByBrand: types.maybeNull(types.boolean)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get negativeKeywordsString() {
            return self.negativeKeywords.length ? self.negativeKeywords.join(',') : '';
        }
    }))
    .actions((self) => ({
        getData: flow(function* get() {
            self.pending = true;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.googleAds.get);
                self.enabledByBrand = response?.enabledByBrand;
                self.negativeKeywords = response?.negativeKeywords?.keywords || [];
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pending = false;
            }
        }),
        updateData: flow(function* update({ googleAdsEnabledByBrand, googleAdsNegativeKeywords }) {
            self.pending = true;
            try {
                yield axiosBoa.post(brandsOpenApi.googleAds.update, {
                    enabledByBrand: googleAdsEnabledByBrand,
                    negativeKeywords: googleAdsNegativeKeywords
                });
                yield self.getData();
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pending = false;
            }
        })
    }));
