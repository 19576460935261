import { brandsOpenApi } from '@constants';
import { CAMPAIGN_STATUS } from '@enums';
import { axiosBoa, getErrorMessage } from '@utils';
import { flow, getParent, types } from 'mobx-state-tree';
import { models } from './constants';

export const OrdersStore = types
    .model('OrdersStore', {
        orderList: types.array(models.OrderModel),
        campaignsWithoutBoosts: types.array(models.CampaignModel),
        meta: types.maybeNull(
            types.model({
                page: types.maybeNull(types.number),
                perPage: types.maybeNull(types.number),
                total: types.maybeNull(types.number),
                totalPages: types.maybeNull(types.number)
            })
        ),
        filters: types.model({
            klickly_status: types.maybe(types.array(types.number)),
            financial_status: types.maybe(types.array(types.number)),
            fulfillment_status: types.maybe(types.array(types.number)),
            ecommerce_status: types.maybe(types.array(types.number)),
            created_at_min: types.maybe(types.string),
            created_at_max: types.maybe(types.string)
        }),
        sorters: types.model({
            sort: 'ecommerce_created_at',
            order: 'desc'
        }),
        pending: false
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => ({
        setPending(pending) {
            self.pending = pending;
        },
        resetFilters() {
            self.filters = {};
        },
        updateFilters(filters) {
            Object.assign(self.filters, filters);
        },
        resetSorters() {
            self.sorters = {
                sort: 'ecommerce_created_at',
                order: 'desc'
            };
        },
        updateSorters(sorters) {
            Object.assign(self.sorters, sorters);
        },
        onDateRangeChange: () => {
            self.orderList = [];
        },
        downloadOrders: flow(function* downloadOrders(params = {}) {
            self.pending = true;

            Object.assign(
                params,
                {
                    page: self.meta.page || 1,
                    limit: self.meta.perPage || 10,
                    download: true
                },
                self.filters,
                self.sorters
            );

            try {
                const { data: file, headers } = yield axiosBoa.get(brandsOpenApi.orders.list, {
                    fullResponse: true,
                    params
                });

                const fileName = headers['content-disposition'].split(' ')[1].split('=')[1];

                return { file, fileName };
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pending = false;
            }
        }),
        onDownloadOrderList: () => {
            return self.orderList;
        },
        getOrders: flow(function* getOrders({ params = {} } = {}) {
            self.pending = true;

            Object.assign(params, self.filters, self.sorters);

            try {
                const response = yield axiosBoa.get(brandsOpenApi.orders.list, { params });

                self.orderList = response.data;
                self.meta = response.meta;
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pending = false;
            }
        }),
        getCampaignsWithoutBoosts: flow(function* getCampaignsWithoutBoosts() {
            try {
                const response = yield axiosBoa.get(brandsOpenApi.campaigns.list, {
                    params: {
                        sort: 'title',
                        statuses: [CAMPAIGN_STATUS.ACTIVE, CAMPAIGN_STATUS.CREATED],
                        has_boost: false
                    }
                });
                self.campaignsWithoutBoosts = response.data;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        afterCreate: () => {
            self.resetSorters();
        }
    }));
