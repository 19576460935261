import React, { useEffect, useState } from 'react';
import { useConnector, useStore } from '@hooks';
import { Loader } from '@ui';
import { observer } from 'mobx-react';
import EmptyState from './components/EmptyState';
import NotConnected from './components/NotConnected';
import OrderList from './components/OrderList';

const Orders = observer(() => {
    const store = useStore();
    const { getOrders, resetFilters, resetSorters } = store.ordersStore;
    const { getCampaigns } = store.campaignsStore;
    const { isShopify } = store.accountsStore;

    const { connected } = useConnector();

    const [loading, setLoading] = useState(true);
    const [hasOrders, setHasOrders] = useState(false);

    useEffect(() => {
        (async () => {
            await getOrders();

            const hasOrders = !!store.ordersStore.orderList.length;
            setHasOrders(hasOrders);

            if (!hasOrders) {
                await getCampaigns({ sort: 'updated_at', order: 'desc' });
            }

            setLoading(false);
        })();

        return () => {
            resetFilters();
            resetSorters();
        };
    }, []);

    if (loading) {
        return <Loader size='120px' />;
    }

    if (!connected && isShopify) {
        return <NotConnected />;
    }

    if (!hasOrders) {
        return <EmptyState />;
    }

    return <OrderList />;
});

export default Orders;
