import React from 'react';
import { Tabs } from '@ui';
import { getAdExamplesItems } from './constants';
import { StyledWrapper } from './styled';

const AdExampleModal = ({ brandedData, promotions }) => {
    const items = getAdExamplesItems(brandedData, promotions);

    return (
        <StyledWrapper>
            <Tabs destroyInactiveTabPane fullWidth centered defaultActiveKey='1' items={items} />
        </StyledWrapper>
    );
};

export default AdExampleModal;
