import { COLORS } from '@constants';

export const titlePositionPluginConfig = {
    id: 'titlePositionPluginConfig',
    afterDraw: (chart) => {
        const datasets = chart?.config?._config?.data?.datasets;
        const height = chart.$context.chart.height;

        const ctx = chart.$context.chart.ctx;
        ctx.save();
        ctx.font = 'medium 14px';
        ctx.fillStyle = COLORS.$gray100Black;
        const y = 30;
        const x = 20;

        ctx.textAlign = 'left';
        ctx.fillText('Jun', 45, height - 30);
        datasets.map((item) => (item.yAxisID ? ctx.fillText('pcx', x, y) : null));

        ctx.restore();
    }
};
