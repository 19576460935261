import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginButton } from '@components';
import { COLORS, ROUTE_PATHS } from '@constants';
import { AuthLayout } from '@layouts';
import { Button, Icon } from '@ui';

const ForgotPasswordSent = () => {
    const navigate = useNavigate();

    return (
        <AuthLayout
            title='Forgot password'
            description="If the email address you entered is associated with an account, you'll receive an
                                email with instructions for resetting your password."
            rightCol={<LoginButton />}
        >
            <Button
                data-e2e='forgot-password_thanks_btn'
                onClick={() => navigate(ROUTE_PATHS.ROOT)}
                type='primary'
                size='large'
                block
                justify='between'
                iconRight={<Icon size='24px' color={COLORS.$white} component={() => <Icon.ArrowForwardIcon />} />}
            >
                Great, Thanks!
            </Button>
        </AuthLayout>
    );
};

export default ForgotPasswordSent;
