import React, { useEffect } from 'react';
import {
    BrandColors,
    CustomCreativesSwitch,
    FontSelector,
    GoogleAds,
    LogoUpload,
    OfferMessage,
    ProductImagesFormat
} from '@components';
import { BOTTOM_INDENTS, COLORS, FIELD_NAME } from '@constants';
import { useStore } from '@hooks';
import { Typography } from '@ui';
import { Form, Layout } from 'antd';
import { observer } from 'mobx-react';
import { useCampaignWizard } from '../../../../hooks/useCampaignWizard';
import { PAGE } from '../../constants';
import CreativeStatusCard from '../CreativeStatusCard';

const { Content } = Layout;

const Step3Content = observer(() => {
    const store = useStore();
    const { campaign } = store.campaignWizardStore;
    const { enabledByBrand, negativeKeywords } = store.googleAdsStore;
    const { customCreativesEnabled } = store.accountsStore;

    const { isEdit } = useCampaignWizard();

    const form = Form.useFormInstance();

    useEffect(() => {
        const creative = campaign?.creatives[0];

        const initialValues = {
            [FIELD_NAME.CREATIVE.FONT]: creative?.fontName,
            [FIELD_NAME.CREATIVE.PRIMARY_COLOR]: creative?.primaryColor,
            [FIELD_NAME.CREATIVE.SECONDARY_COLOR]: creative?.secondaryColor,
            [FIELD_NAME.CREATIVE.OFFER_MESSAGE]: creative?.offerMessage,
            [FIELD_NAME.CREATIVE.PRODUCT_IMAGES_FORMAT]: creative?.productImageBehavior,
            [FIELD_NAME.GOOGLE_ADS.ENABLED_BY_BRAND]: enabledByBrand,
            [FIELD_NAME.GOOGLE_ADS.NEGATIVE_KEYWORDS]: negativeKeywords || []
        };
        form.setFieldsValue(initialValues);
    }, []);
    return (
        <Content tagName='div' style={{ maxWidth: 867 }}>
            {!isEdit && (
                <React.Fragment>
                    <Typography.Title data-e2e='campaignwizard_step-3_title' style={BOTTOM_INDENTS.M}>
                        {PAGE.TITLE}
                    </Typography.Title>
                    <Typography.Text
                        data-e2e='campaignwizard_step-3_description'
                        type='body2'
                        style={BOTTOM_INDENTS.XS}
                        color={COLORS.$gray60Black}
                    >
                        {PAGE.DESCRIPTION1}
                    </Typography.Text>
                    <Typography.Text
                        data-e2e='campaignwizard_step-3_hint'
                        type='body2'
                        style={BOTTOM_INDENTS.M}
                        color={COLORS.$gray60Black}
                    >
                        {PAGE.DESCRIPTION2}
                    </Typography.Text>
                </React.Fragment>
            )}
            {isEdit && <CreativeStatusCard marginBottom={24} />}
            <FontSelector />
            <BrandColors />
            <OfferMessage />
            <LogoUpload />
            <ProductImagesFormat />
            {customCreativesEnabled ? <CustomCreativesSwitch /> : null}
            <GoogleAds negativeKeywords={negativeKeywords} />
        </Content>
    );
});

export default Step3Content;
