import React from 'react';
import { COLORS } from '@constants';
import { CAMPAIGN_COMMISSION_SOURCE_TYPE } from '@enums';
import { Icon } from '@ui';
import { formatString } from '@utils';
import { Flex, Tooltip as AntdTooltip } from 'antd';
import { getCommissionData } from '../../utils/getCommissionData';
import { CommissionIcon } from '../CommissionIcon/styled';
import {
    StyledFlex,
    StyledGrayBottomFlex,
    StyledHeader,
    StyledName,
    StyledValue,
    StyledYellowBottomFlex
} from './styled';

export const CommissionTooltip = ({ text, trigger = 'hover', isSubscription, metrics, orderItems, ...props }) => {
    const commissionData = getCommissionData(
        metrics.commissionTypeName,
        orderItems,
        metrics.isCommissionLimitedToSubtotal
    );
    const commissionType = {
        name: 'Commission type:',
        value: commissionData.type
    };
    const rows = [
        { name: 'Total engagements (last 90 days):', value: metrics.totalEngagements },
        {
            name: 'Days to conversion:',
            value: metrics.daysToConversionToDisplay
        },
        { name: 'Last engagement:', value: metrics.lastEngagement }
    ];

    const title = (
        <div data-e2e='orders_commission-tooltip_content'>
            <Flex color={COLORS.$white} vertical>
                <StyledHeader data-e2e='orders_commission-tooltip_title' color={COLORS.$white} type='headline3'>
                    Engagement details
                </StyledHeader>
                {rows.map((row, index) => (
                    <StyledGrayBottomFlex key={index} align='center' justify='space-between'>
                        <StyledName
                            data-e2e={`orders_tooltip-${formatString(row.name)}_row`}
                            color={COLORS.$gray60Black}
                            type='body2'
                        >
                            {row.name}
                        </StyledName>
                        <StyledValue
                            data-e2e={`orders_tooltip-${formatString(row.name)}_value`}
                            color={COLORS.$white}
                            type='body2'
                        >
                            {row.value}
                        </StyledValue>
                    </StyledGrayBottomFlex>
                ))}
                {isSubscription ? (
                    <React.Fragment>
                        <StyledGrayBottomFlex align='center' justify='space-between'>
                            <StyledName
                                data-e2e='orders_tooltip-commission-type_row'
                                color={COLORS.$gray60Black}
                                type='body2'
                            >
                                {commissionType.name}
                            </StyledName>
                            <StyledValue
                                data-e2e='orders_tooltip-commission-type_value'
                                color={COLORS.$white}
                                type='body2'
                            >
                                {commissionType.value}
                            </StyledValue>
                        </StyledGrayBottomFlex>
                        <StyledYellowBottomFlex align='center' justify='space-between'>
                            <StyledName
                                data-e2e='orders_tooltip-subscription-order_row'
                                color={COLORS.$alert}
                                type='body2'
                            >
                                Subscription order*
                            </StyledName>
                            <StyledValue
                                data-e2e='orders_tooltip-subscription-order_value'
                                color={COLORS.$alert}
                                type='body2'
                            >
                                {metrics.commissionSourceType === CAMPAIGN_COMMISSION_SOURCE_TYPE.SUBSCRIPTION
                                    ? '2/2'
                                    : '1/2'}
                            </StyledValue>
                        </StyledYellowBottomFlex>
                        <StyledFlex align='center' justify='space-between'>
                            <StyledName
                                data-e2e='orders_tooltip-subscription-order_description'
                                color={COLORS.$alert}
                                type='body2'
                            >
                                *Klickly bills only for 1st and 2nd subscription orders per customer.
                            </StyledName>
                        </StyledFlex>
                    </React.Fragment>
                ) : (
                    <StyledFlex align='center' justify='space-between'>
                        <StyledName
                            data-e2e='orders_tooltip-commission-type_row'
                            $widen={commissionData.widen}
                            color={COLORS.$gray60Black}
                            type='body2'
                        >
                            {commissionType.name}
                        </StyledName>
                        <StyledValue data-e2e='orders_tooltip-commission-type_value' color={COLORS.$white} type='body2'>
                            {commissionType.value}
                        </StyledValue>
                    </StyledFlex>
                )}
            </Flex>
        </div>
    );

    return (
        <AntdTooltip
            overlayStyle={{
                minWidth: 308,
                ...(commissionData.widen ? { maxWidth: 408 } : {}),
                minHeight: 216,
                maxHeight: 304,
                borderRadius: 4
            }}
            zIndex={20}
            title={title}
            trigger={trigger}
            {...props}
        >
            <div style={{ paddingBlock: 4, display: 'flex', height: 'auto' }}>
                <Icon size='16px' component={() => <CommissionIcon color={COLORS.$gray40Black} />} />
            </div>
        </AntdTooltip>
    );
};
