import { NavLink } from 'react-router-dom';
import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledNavLink = styled(NavLink)`
    color: ${COLORS.$gray60Black};
    &:hover {
        text-decoration: none;
        color: ${COLORS.$gray100Black};
    }
`;
