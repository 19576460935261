import React from 'react';
import { COLORS, TOP_INDENTS } from '@constants';
import { Button } from '@ui';
import { Col, Row } from 'antd';
import { StyledList } from './styled';

const HowToFindMeasurementId = ({ close }) => {
    return (
        <Row justify='center'>
            <Col span={16} justify='center' align='center'>
                <StyledList data-e2e='tracking_ga-measurement-id-modal-steps_list'>
                    <li>Log in to your Google Analytics account</li>
                    <li>Go to Admin &gt; Data Streams (in the Property column) &gt; Stream Details</li>
                    <li>At the Stream Details you will find “MEASURMENT ID”</li>
                </StyledList>
                <video width='400' controls muted data-e2e='tracking_ga-measurement-id-modal_video'>
                    <source src='https://cdn.klickly.com/boa_videos/ga4.mp4' type='video/mp4' />
                    Your browser does not support the video tag.
                </video>

                <Button
                    data-e2e='tracking_ga-measurement-id-modal-close_btn'
                    backgroundColor={COLORS.$white}
                    padding='0 58px'
                    justify='center'
                    style={TOP_INDENTS.XL_PLUS}
                    onClick={close}
                >
                    Close
                </Button>
            </Col>
        </Row>
    );
};

export default HowToFindMeasurementId;
