import { Flex } from 'antd';
import styled from 'styled-components';

const StyledMenuWrapper = styled(Flex)`
    .level-1 {
        padding-left: 24px;
    }
`;

export default StyledMenuWrapper;
