import React from 'react';
import { COLORS } from '@constants';
import { Tooltip } from '@ui';
import { Col, Row } from 'antd';
import { Icon } from '../../../Icon';
import { Typography } from '../../../Typography';
import { StyledIcon } from './styled';

export const TitleWithTooltip = ({ title, tooltip }) => {
    return (
        <Row align='middle' justify='start' gutter={8}>
            <Col>
                <Typography.Text color={COLORS.$gray40Black} type='descriptor2'>
                    {title}
                </Typography.Text>
            </Col>
            <Col
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Tooltip title={tooltip}>
                    <span>
                        <StyledIcon
                            size='16px'
                            component={() => <Icon.QuestionCircleIcon />}
                            color={COLORS.$gray40Black}
                        />
                    </span>
                </Tooltip>
            </Col>
        </Row>
    );
};
