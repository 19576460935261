import React from 'react';
import { COLORS, TOP_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Icon, Typography } from '@ui';
import { Avatar } from 'antd';
import { observer } from 'mobx-react';
import { StyledNoLogo } from './styled';

const LogoView = observer(({ size = 128 }) => {
    const store = useStore();
    const logoUrl = store.accountsStore?.currentAccount?.logoUrl;

    return logoUrl ? (
        <Avatar data-e2e='account-settings_company-logo_img' size={size} src={<img src={logoUrl} alt='avatar' />} />
    ) : (
        <StyledNoLogo data-e2e='account-settings_add-logo_btn' $size={size}>
            <Icon size='40px' color={COLORS.$gray40Black} component={() => <Icon.PlusIcon />} />
            <Typography.Text type='descriptor2' color={COLORS.$gray40Black} style={TOP_INDENTS.XXS}>
                Add logo
            </Typography.Text>
        </StyledNoLogo>
    );
});

export default LogoView;
