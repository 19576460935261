import React from 'react';
import { useModal } from '@hooks';
import HowToFindMeasurementId from '../components/HowToFindMeasurementId';

export const useMeasurementIdModal = () => {
    const { open, close } = useModal();

    const showMeasurementIdModal = () => {
        open({
            title: (
                <span data-e2e='tracking_ga-measurement-id-modal_title'>
                    Where to Find Google Analytics Measurement ID?
                </span>
            ),
            content: <HowToFindMeasurementId close={close} />,
            settings: { maskClosable: false }
        });
    };

    return { showMeasurementIdModal, close };
};
