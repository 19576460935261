import React from 'react';
import { CUSTOM_CREATIVES_SIZES, LEFT_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Icon, Tabs } from '@ui';
import { observer } from 'mobx-react';
import CustomCreativeImage from './components/CustomCreativeImage';
import { TabLabel } from './components/TabLabel';
import { StyledWrapper } from './styled';

const CustomCreativesModal = observer(() => {
    const store = useStore();
    const { getUnitBySize } = store.customCreativesStore;

    const items = CUSTOM_CREATIVES_SIZES.map(({ size, width, height }) => {
        let icon;
        const unit = getUnitBySize(size);

        if (unit?.localData?.imageSrc && unit?.localData?.validationStatus === 0) {
            icon = <Icon size='24px' component={() => <Icon.CloseCircleIcon />} style={LEFT_INDENTS.XXS} />;
        }

        if (unit?.localData?.imageSrc && unit?.localData?.validationStatus === 2) {
            icon = <Icon size='24px' component={() => <Icon.CheckCircleIcon />} style={LEFT_INDENTS.XXS} />;
        }

        return {
            key: size,
            label: <TabLabel label={size} icon={icon} />,
            children: <CustomCreativeImage size={size} width={width} height={height} />
        };
    });

    return (
        <StyledWrapper>
            <Tabs destroyInactiveTabPane fullWidth centered defaultActiveKey='1' items={items} />
        </StyledWrapper>
    );
});

export default CustomCreativesModal;
