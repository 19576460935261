import { dayjs } from '../utils/dayjs';

export const defaultTimeRange = [dayjs().utc().subtract(30, 'day').startOf('day'), dayjs().utc().endOf('day')];

export const defaultTimeProps = {
    date_from: dayjs().utc().subtract(30, 'day').startOf('day'),
    date_to: dayjs().utc().endOf('day')
};

export const defaultTimezoneOffset = new Date().getTimezoneOffset() / 60;
