import React from 'react';
import {
    ORDER_ECOMMERCE_STATUS,
    ORDER_ECOMMERCE_STATUS_LABELS,
    ORDER_FINANCE_STATUS,
    ORDER_FINANCE_STATUS_LABELS,
    ORDER_FULFILLMENT_STATUS,
    ORDER_FULFILLMENT_STATUS_LABELS
} from '@enums';
import { Image, Tag } from '@ui';
import { formatPrice } from '@utils';
import { Flex } from 'antd';
import dayjs from 'dayjs';
import { CommissionTooltip } from '../components/CommissionTooltip';
import Title from '../components/TableTitle';

export const getColumns = ({ isShopify = false, isManual = false, isBigCommerce = false }) => {
    const columns = [
        {
            title: <span data-e2e='orders_klickly-id_column'>Klickly ID</span>,
            dataIndex: 'klicklyOrderNumber',
            key: 'klicklyOrderNumber',
            sorter: (a, b) => {
                const num1 = parseInt(a.klicklyOrderNumber.replace('KL#', ''), 16);
                const num2 = parseInt(b.klicklyOrderNumber.replace('KL#', ''), 16);

                return num1 - num2;
            },
            render: (klicklyOrderNumber) => (
                <span data-e2e={`order_${klicklyOrderNumber}-klickly-id_value`}>{klicklyOrderNumber}</span>
            )
        },
        {
            title: <span data-e2e='orders_ecommerce-id_column'>Ecommerce ID</span>,
            dataIndex: 'ecommerceOrderName',
            key: 'ecommerceOrderName',
            sorter: true,
            render: (ecommerceOrderName, record) => (
                <span data-e2e={`order_${record.klicklyOrderNumber}-ecommerce-id_value`}>
                    {ecommerceOrderName || record.ecommerceOrderId}
                </span>
            )
        },
        {
            title: <span data-e2e='orders_campaigns_column'>Campaigns</span>,
            dataIndex: 'campaigns',
            key: 'campaigns',
            sorter: true,
            render: (value, record) => {
                return (
                    <span data-e2e={`order_${record.klicklyOrderNumber}-campaigns_value`}>
                        {value.map((campaign) => campaign.title).join(' | ')}
                    </span>
                );
            }
        },
        {
            title: <Title text='Created At' tooltipText='Created in your store' />,
            dataIndex: 'ecommerceCreatedAt',
            key: 'ecommerceCreatedAt',
            sorter: true,
            defaultSortOrder: 'descend',
            render: (value, record) => {
                return (
                    <span data-e2e={`order_${record.klicklyOrderNumber}-created-at_value`}>
                        {dayjs(value).format('D MMM YY')}
                    </span>
                );
            }
        },
        {
            title: <Title text='Processed At' tooltipText='Processed by Klickly' />,
            dataIndex: 'processedAt',
            key: 'processedAt',
            sorter: true,
            render: (value, record) => {
                const formattedDate = value ? dayjs(value).format('D MMM YY') : '-';
                return <span data-e2e={`order_${record.klicklyOrderNumber}-processed-at_value`}>{formattedDate}</span>;
            }
        },
        {
            title: <span data-e2e='orders_subtotal_column'>Sub-total</span>,
            dataIndex: 'subtotalPrice',
            key: 'subtotalPrice',
            align: 'right',
            sorter: true,
            render: (value, record) => {
                return <span data-e2e={`order_${record.klicklyOrderNumber}-subtotal_value`}>{formatPrice(value)}</span>;
            }
        },
        {
            title: <span data-e2e='orders_total_column'>Total</span>,
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            align: 'right',
            sorter: true,
            render: (value, record) => {
                return <span data-e2e={`order_${record.klicklyOrderNumber}-total_value`}>{formatPrice(value)}</span>;
            }
        },
        {
            title: <span data-e2e='orders_commission_column'>Commission</span>,
            dataIndex: 'metrics',
            key: 'metrics',
            align: 'right',
            sorter: true,
            render: (_, record) => {
                const { metrics, isSubscription, orderItems } = record;
                return (
                    <Flex justify='center' align='center' gap={8}>
                        <span data-e2e={`order_${record.klicklyOrderNumber}-commission_value`}>
                            {formatPrice(metrics.commissionCharged)}
                        </span>
                        <span data-e2e={`orders_${record.klicklyOrderNumber}-commission-tooltip_icon`}>
                            {' '}
                            <CommissionTooltip
                                metrics={metrics}
                                orderItems={orderItems}
                                isSubscription={isSubscription}
                            />
                        </span>
                    </Flex>
                );
            }
        }
    ];

    if (isShopify || isManual) {
        columns.push(
            {
                title: <span data-e2e='orders_financial-status_column'>Financial Status</span>,
                dataIndex: 'financialStatus',
                key: 'financialStatus',
                align: 'left',
                filters: [
                    {
                        text: (
                            <span data-e2e='orders_pending-status_filter'>
                                {ORDER_FINANCE_STATUS_LABELS[ORDER_FINANCE_STATUS.PENDING].title}
                            </span>
                        ),
                        value: ORDER_FINANCE_STATUS.PENDING
                    },
                    {
                        text: (
                            <span data-e2e='orders_voided-status_filter'>
                                {ORDER_FINANCE_STATUS_LABELS[ORDER_FINANCE_STATUS.VOID].title}
                            </span>
                        ),
                        value: ORDER_FINANCE_STATUS.VOID
                    },
                    {
                        text: (
                            <span data-e2e='orders_authorized-status_filter'>
                                {ORDER_FINANCE_STATUS_LABELS[ORDER_FINANCE_STATUS.AUTHORIZED].title}
                            </span>
                        ),
                        value: ORDER_FINANCE_STATUS.AUTHORIZED
                    },

                    {
                        text: (
                            <span data-e2e='orders_part-paid-status_filter'>
                                {ORDER_FINANCE_STATUS_LABELS[ORDER_FINANCE_STATUS.PARTIALLY_PAID].title}
                            </span>
                        ),
                        value: ORDER_FINANCE_STATUS.PARTIALLY_PAID
                    },
                    {
                        text: (
                            <span data-e2e='orders_paid-status_filter'>
                                {ORDER_FINANCE_STATUS_LABELS[ORDER_FINANCE_STATUS.PAID].title}
                            </span>
                        ),
                        value: ORDER_FINANCE_STATUS.PAID
                    },
                    {
                        text: (
                            <span data-e2e='orders_part-refunded-status_filter'>
                                {ORDER_FINANCE_STATUS_LABELS[ORDER_FINANCE_STATUS.PARTIALLY_REFUNDED].title}
                            </span>
                        ),
                        value: ORDER_FINANCE_STATUS.PARTIALLY_REFUNDED
                    },
                    {
                        text: (
                            <span data-e2e='orders_refunded-status_filter'>
                                {ORDER_FINANCE_STATUS_LABELS[ORDER_FINANCE_STATUS.REFUNDED].title}
                            </span>
                        ),
                        value: ORDER_FINANCE_STATUS.REFUNDED
                    }
                ],
                render: (status, record) => {
                    return (
                        <Tag
                            color={ORDER_FINANCE_STATUS_LABELS[status].color}
                            data-e2e={`order_${record.klicklyOrderNumber}-financial-status_value`}
                        >
                            {ORDER_FINANCE_STATUS_LABELS[status].title}
                        </Tag>
                    );
                }
            },
            {
                title: <span data-e2e='orders_fulfillment-status_column'>Fulfillment status</span>,
                dataIndex: 'fulfillmentStatus',
                key: 'fulfillmentStatus',
                align: 'left',
                filters: [
                    {
                        text: (
                            <span data-e2e='orders_unfulfilled-status_filter'>
                                {ORDER_FULFILLMENT_STATUS_LABELS[ORDER_FULFILLMENT_STATUS.UNFULFILLED].title}
                            </span>
                        ),
                        value: ORDER_FULFILLMENT_STATUS.UNFULFILLED
                    },
                    {
                        text: (
                            <span data-e2e='orders_scheduled-status_filter'>
                                {ORDER_FULFILLMENT_STATUS_LABELS[ORDER_FULFILLMENT_STATUS.SCHEDULED].title}
                            </span>
                        ),
                        value: ORDER_FULFILLMENT_STATUS.SCHEDULED
                    },
                    {
                        text: (
                            <span data-e2e='orders_onhold-status_filter'>
                                {ORDER_FULFILLMENT_STATUS_LABELS[ORDER_FULFILLMENT_STATUS.ON_HOLD].title}
                            </span>
                        ),
                        value: ORDER_FULFILLMENT_STATUS.ON_HOLD
                    },
                    {
                        text: (
                            <span data-e2e='orders_partfulfilled-status_filter'>
                                {ORDER_FULFILLMENT_STATUS_LABELS[ORDER_FULFILLMENT_STATUS.PARTIALLY_FULFILLED].title}
                            </span>
                        ),
                        value: ORDER_FULFILLMENT_STATUS.PARTIALLY_FULFILLED
                    },
                    {
                        text: (
                            <span data-e2e='orders_fulfilled-status_filter'>
                                {ORDER_FULFILLMENT_STATUS_LABELS[ORDER_FULFILLMENT_STATUS.FULFILLED].title}
                            </span>
                        ),
                        value: ORDER_FULFILLMENT_STATUS.FULFILLED
                    }
                ],
                render: (status, record) => {
                    return (
                        <Tag
                            color={ORDER_FULFILLMENT_STATUS_LABELS[status].color}
                            data-e2e={`order_${record.klicklyOrderNumber}-fulfillment-status_value`}
                        >
                            {ORDER_FULFILLMENT_STATUS_LABELS[status].title}
                        </Tag>
                    );
                }
            }
        );
    }

    if (isBigCommerce) {
        columns.push({
            title: <span data-e2e='orders_status_column'>Status</span>,
            dataIndex: 'ecommerceStatus',
            key: 'ecommerceStatus',
            align: 'left',
            filters: Object.values(ORDER_ECOMMERCE_STATUS).map((status) => ({
                text: (
                    <span data-e2e={`orders_${status}-status_filter`}>
                        {ORDER_ECOMMERCE_STATUS_LABELS[status].title}
                    </span>
                ),
                value: status
            })),
            render: (status, record) => {
                return (
                    <Tag
                        color={ORDER_ECOMMERCE_STATUS_LABELS[status].color}
                        data-e2e={`order_${record.klicklyOrderNumber}-status_value`}
                    >
                        {ORDER_ECOMMERCE_STATUS_LABELS[status].title}
                    </Tag>
                );
            }
        });
    }

    return columns;
};

export const getColumnsInner = (isManual) => {
    return [
        {
            title: <span data-e2e='orders_product-image_column'> </span>,
            width: '7%',
            dataIndex: 'image',
            key: 'image',
            render: (value, record, index) => (
                <Image
                    preview={false}
                    width={36}
                    height={36}
                    src={value}
                    alt={record.product?.title}
                    data-e2e={`orders_product-${index + 1}-image_value`}
                />
            )
        },
        {
            title: <span data-e2e='orders_product-title_column'>Product</span>,
            dataIndex: 'product',
            key: 'product',
            width: '24%',
            render: (_, record, index) => {
                if (isManual) return <span data-e2e={`orders_product-${index + 1}-title_value`}>{record.title}</span>;
                return <span data-e2e={`orders_product-${index + 1}-title_value`}>{record.product?.title || '-'}</span>;
            }
        },
        {
            title: <span data-e2e='orders_product-variant_column'>Variant</span>,
            dataIndex: 'variant',
            key: 'variant',
            render: (_, record, index) => {
                if (isManual) return <span data-e2e={`orders_product-${index + 1}-title_value`}> </span>;

                const variant = record.product?.variants?.find(
                    (variant) => variant.ecommerceId.toString() === record.ecommerceVariantId.toString()
                );

                return (
                    <span data-e2e={`orders_product-${index + 1}-variant_value`}>
                        {variant ? variant.name : 'Default Title'}
                    </span>
                );
            }
        },
        {
            title: <span data-e2e='orders_product-quantity_column'>Quantity</span>,
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'right',
            render: (value, _record, index) => (
                <span data-e2e={`orders_product-${index + 1}-quantity_value`}>{value || '-'}</span>
            )
        },
        {
            title: <span data-e2e='orders_product-sku-price_column'>SKU Price</span>,
            dataIndex: 'skuPrice',
            key: 'skuPrice',
            align: 'center',
            render: (_, record, index) => (
                <span data-e2e={`orders_product-${index + 1}-sku-price_value`}>
                    {record.price || record.price === 0 ? formatPrice(record.price) : '-'}
                </span>
            )
        },
        {
            title: <span data-e2e='orders_product-total-price_column'>Price</span>,
            dataIndex: 'price',
            key: 'totalPrice',
            align: 'right',
            render: (_, record, index) => (
                <span data-e2e={`orders_product-${index + 1}-total-price_value`}>
                    {formatPrice(record.price * record.quantity)}
                </span>
            )
        }
    ];
};
