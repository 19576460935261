import { COLORS } from '@constants';
import { Button, Typography } from 'antd';
import styled from 'styled-components';

export const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 20px;
`;

export const StyledCancel = styled(Button)`
    width: 150px;
    background-color: ${COLORS.$white};
    border-color: ${COLORS.$gray40Black};
`;

export const StyledAction = styled(Button)`
    width: 150px;
    background-color: ${COLORS.$pink};
    color: ${COLORS.$white};
`;

export const StyledDivContainer = styled.div`
    padding: 20px;
`;

export const StyledTypography = styled(Typography.Text)`
    font-size: 18px;
`;

export const StyledSpan = styled.span`
    cursor: pointer;
    color: ${COLORS.$info};
`;
