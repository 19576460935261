import { COLORS } from '@constants';
import { Typography } from '@ui';
import { Flex } from 'antd';
import styled from 'styled-components';

export const StyledHeader = styled(Typography.Text)`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
`;

export const StyledName = styled(Typography.Text)`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-right: ${({ $widen }) => ($widen ? '10px' : 0)};
`;

export const StyledValue = styled(Typography.Text)`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
`;

export const StyledFlex = styled(Flex)`
    margin-top: 16px;
`;

export const StyledGrayBottomFlex = styled(Flex)`
    border-bottom: 1px solid ${COLORS.$gray60Black};
    margin-top: 16px;
`;

export const StyledYellowBottomFlex = styled(Flex)`
    border-bottom: 1px solid ${COLORS.$alert};
    margin-top: 16px;
`;
