import React from 'react';
import { ROUTE_PATHS } from '@constants';
import { RedirectLink } from '@ui';

export const PAGE = {
    TITLE: "Set your campaign's Priority",
    DESCRIPTION: (
        <React.Fragment>
            Your Priority is how likely Klickly’s algorithm is to display your products. Change your Priority by
            adjusting the commission you pay to Klickly. For more info about Priority, fees, and attribution, visit the{' '}
            <RedirectLink
                data-e2e='campaignwizard_help-center_link'
                style={{ display: 'inline' }}
                to={ROUTE_PATHS.HELP_CENTER}
                text='Help Center.'
                target='_blank'
            />
        </React.Fragment>
    )
};
