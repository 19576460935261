import React from 'react';
import { CONTACT_VALUE_TYPE } from '@enums';
import { Button, WhiteCover } from '@ui';
import { Flex, Form } from 'antd';
import { observer } from 'mobx-react';
import { FIELD_NAMES } from '../../constants';
import NotificationContact from '../NotificationContact';

const { VALUE, VALUE_TYPE, NOTIFICATION_CONTACTS } = FIELD_NAMES;

const NotificationContacts = observer(({ style }) => {
    return (
        <Form.List name={NOTIFICATION_CONTACTS}>
            {(contacts, { add, remove }) => {
                return (
                    <WhiteCover
                        data-e2e='notifications_company-email-phone_section'
                        title='Company Email / Phone'
                        style={style}
                        rightContent={
                            <Form.Item noStyle>
                                <Button
                                    data-e2e='notifications_add-email-phone_btn'
                                    size='small'
                                    type='link'
                                    onClick={() =>
                                        add(
                                            {
                                                [VALUE_TYPE]: CONTACT_VALUE_TYPE.EMAIL,
                                                [VALUE]: ''
                                            },
                                            contacts.length
                                        )
                                    }
                                >
                                    Add email/phone
                                </Button>
                            </Form.Item>
                        }
                    >
                        <Flex vertical gap={24}>
                            {contacts.map((contact) => (
                                <NotificationContact key={contact.key} remove={remove} contact={contact} />
                            ))}
                        </Flex>
                    </WhiteCover>
                );
            }}
        </Form.List>
    );
});

export default NotificationContacts;
