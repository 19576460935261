import { COLORS } from '@constants';
import { Row } from 'antd';
import styled from 'styled-components';

export const StyledGtmCode = styled(Row)`
    position: relative;
    margin-top: 24px;
    padding: 16px 56px 16px 16px;
    border: 1px solid ${COLORS.$gray20Black};
    border-radius: 8px;
    overflow: hidden;

    .ant-btn {
        position: absolute;
        top: 0;
        right: 0;
    }
`;

export const StyledGtmInfo = styled.div`
    padding: 24px;
    border: 1px solid ${({ $isValid }) => ($isValid ? COLORS.$robinBlue : COLORS.$tartRed)};
    border-radius: 8px;
    background: ${({ $isValid }) => ($isValid ? COLORS.$robinTintsBlue : COLORS.$tartRedLite)};
`;
