import React from 'react';
import { TOP_INDENTS, VALIDATION_RULES } from '@constants';
import { Input } from '@ui';
import { Flex, Form } from 'antd';

const CustomForm = () => {
    return (
        <Flex vertical gap={16} style={TOP_INDENTS.S}>
            <Form.Item name='url' rules={VALIDATION_RULES.REQUIRED}>
                <Input data-e2e='signup_manual-store-url_input' type='text' label='Store URL' />
            </Form.Item>
            <Form.Item name='storeName' rules={VALIDATION_RULES.REQUIRED}>
                <Input data-e2e='signup_manual-store-name_input' type='text' label='Store Name' />
            </Form.Item>
        </Flex>
    );
};

export default CustomForm;
