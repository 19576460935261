import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorFallback, LoginButton } from '@components';
import { COLORS, ROUTE_PATHS, TOP_INDENTS, VALIDATION_RULES } from '@constants';
import { useStore } from '@hooks';
import { AuthLayout } from '@layouts';
import { Button, Icon, Input } from '@ui';
import { Flex, Form } from 'antd';
import { observer } from 'mobx-react';

const ForgotPasswordReset = observer(() => {
    const [loading, setLoading] = useState(false);
    const store = useStore();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const { resetPassword, errorMessage } = store.forgotPasswordStore;

    const handleSubmit = async (data) => {
        setLoading(true);
        await resetPassword({
            token: searchParams.get('token'),
            data,
            onSuccess: () => navigate(ROUTE_PATHS.FORGOT_PASSWORD_SUCCESS, { state: { email } })
        });
        setLoading(false);
    };

    return (
        <AuthLayout
            title='Forgot password'
            description='Create a new password for'
            email={email}
            rightCol={<LoginButton />}
        >
            <Form form={form} onFinish={handleSubmit}>
                {(values, formInstance) => {
                    const [passwordError] = formInstance.getFieldError('password');
                    const [confirmPasswordError] = formInstance.getFieldError('confirmPassword');

                    const error = errorMessage || passwordError || confirmPasswordError;

                    return (
                        <React.Fragment>
                            <Flex vertical gap={16}>
                                <Form.Item
                                    rules={[
                                        { required: true, message: 'The fields are required' },
                                        ...VALIDATION_RULES.PASSWORD
                                    ]}
                                    name='password'
                                    noStyle
                                >
                                    <Input
                                        suffix={
                                            <Icon
                                                data-e2e='reset-password_show-created-password_icon'
                                                size='24px'
                                                color={COLORS.$gray20Black}
                                                component={() => <Icon.LockIcon />}
                                            />
                                        }
                                        type='password'
                                        label='Create Password'
                                        data-e2e='reset-password_create-password_input'
                                    />
                                </Form.Item>
                                <Form.Item
                                    dependencies={['password']}
                                    noStyle
                                    name='confirmPassword'
                                    rules={[
                                        { required: true, message: 'The fields are required' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error('The confirmation password does not match')
                                                );
                                            }
                                        })
                                    ]}
                                >
                                    <Input
                                        suffix={
                                            <Icon
                                                data-e2e='reset-password_show-confirmed-password_icon'
                                                size='24px'
                                                color={COLORS.$gray20Black}
                                                component={() => <Icon.LockIcon />}
                                            />
                                        }
                                        type='password'
                                        label='Confirm Password'
                                        data-e2e='reset-password_confirm-password_input'
                                    />
                                </Form.Item>

                                {error ? (
                                    <ErrorFallback.Message
                                        data-e2e='reset-password_error_message'
                                        style={{ marginTop: 0, textAlign: 'center', fontWeight: 'bold' }}
                                        message={error}
                                    />
                                ) : (
                                    <div style={{ height: 16 }} />
                                )}
                            </Flex>

                            <Form.Item style={TOP_INDENTS.L} noStyle>
                                <Button
                                    data-e2e='reset-password_reset-and-login_btn'
                                    loading={loading}
                                    style={TOP_INDENTS.L}
                                    htmlType='submit'
                                    type='primary'
                                    size='large'
                                    justify='between'
                                    block
                                    iconRight={
                                        <Icon
                                            size='24px'
                                            color={COLORS.$white}
                                            component={() => <Icon.ArrowForwardIcon />}
                                        />
                                    }
                                >
                                    Reset Password & Log In
                                </Button>
                            </Form.Item>
                        </React.Fragment>
                    );
                }}
            </Form>
        </AuthLayout>
    );
});

export default ForgotPasswordReset;
