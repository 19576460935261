export const formatString = (string = '', regexp = /\s+/g, replaceWith = '-') => {
    if (string) {
        return string
            .replace(/:$/, '')
            .replace(/[_:]/g, ' ')
            .replace(/\s*\(.*?\)\s*/g, '')
            .replace(regexp, replaceWith)
            .toLowerCase();
    }
    return 'no-data';
};
