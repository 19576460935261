import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    padding: 18px 0 24px;
    position: relative;
    margin-inline: 5px;

    .ant-tooltip {
        --arrow-x: 16px !important;
        --arrow-y: 30px !important;
        inset: 18px auto auto -10px !important;
    }

    /*Slider*/
    .ant-slider {
        margin: 0;
    }

    .ant-slider-horizontal .ant-slider-dot {
        display: none;
    }
    .ant-slider .ant-slider-rail {
        border-radius: 4px;
    }
    .ant-slider .ant-slider-track {
        border-radius: 4px;
    }
    .ant-slider-horizontal .ant-slider-mark {
        margin-top: 20px;
    }
    .ant-slider .ant-slider-mark-text {
        line-height: 16px;
        font-weight: 500;
        color: ${COLORS.$gray100Black};

        &:last-child {
            margin-left: -20px;
        }
    }
    .ant-slider-horizontal.ant-slider-with-marks {
        margin-bottom: 0;
    }
    .ant-slider-handle.ant-tooltip-open {
        .ant-tooltip {
            .ant-tooltip-arrow {
                display: none;
            }
            .ant-tooltip-content .ant-tooltip-inner {
                padding: 0;
                min-height: auto;
                font-size: 16px;
                font-weight: 500;
                color: ${COLORS.$pink};
                background-color: transparent;
                box-shadow: none;
            }
        }
    }
`;
