import React from 'react';
import { COLORS } from '@constants';
import { Typography } from '@ui';
import { Flex } from 'antd';

const EmptyState = () => {
    return (
        <Flex
            vertical
            align='center'
            justify='center'
            style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
        >
            <Typography.Title data-e2e='campaignwizard_no-products_text' level={2} color={COLORS.$gray60Black}>
                You have no products
            </Typography.Title>
        </Flex>
    );
};

export default EmptyState;
