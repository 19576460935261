import React, { Fragment, useCallback } from 'react';
import { BOTTOM_INDENTS, COLORS, LEFT_INDENTS } from '@constants';
import { Icon, Tooltip, Typography } from '@ui';
import { Flex, Form } from 'antd';
import _ from 'lodash';
import { FIELD_NAMES } from '../../../../constants';
import { CommissionInput } from '../CommissionInput';
import { InfoIcon } from '../InfoIcon';

const NewCustomerCommission = () => {
    const form = Form.useFormInstance();

    const onChange = useCallback(
        _.debounce((value) => {
            if (value < 0) {
                form.setFieldValue(FIELD_NAMES.NEW_CUSTOMER_COMMISSION, 0);
            }
        }, 200)
    );

    return (
        <Flex justify='space-between' align='center'>
            <Flex style={{ paddingBlock: 16 }} gap={12} align='center'>
                <Typography.Text data-e2e='account-management_ncc_block' type='badgeSmall'>
                    New-customer commission
                </Typography.Text>
                <Tooltip
                    title={
                        <Fragment>
                            <Typography.Title
                                data-e2e='account-management_ncc-tooltip_title'
                                level={3}
                                color={COLORS.$white}
                                style={BOTTOM_INDENTS.S}
                            >
                                What is New-customer commission?
                            </Typography.Title>
                            <Typography.Text
                                data-e2e='account-management_ncc-tooltip_description'
                                type='badgeSmall'
                                color={COLORS.$white}
                            >
                                Your new-customer commission is how you incentivize Klickly to help drive sales from new
                                customers, no matter what products they buy. (Note: this commission will only apply when
                                it exceeds other commission types)
                            </Typography.Text>
                        </Fragment>
                    }
                    trigger='hover'
                >
                    <Icon
                        data-e2e='account-management_ncc-tooltip_icon'
                        style={{ ...LEFT_INDENTS.XXXS, paddingBlock: 4 }}
                        color={COLORS.$gray40Black}
                        stroke='white'
                        size='24px'
                        component={() => <InfoIcon />}
                    />
                </Tooltip>
            </Flex>
            <Flex style={{ width: 160 }}>
                <Form.Item name={FIELD_NAMES.NEW_CUSTOMER_COMMISSION}>
                    <CommissionInput
                        data-e2e='account-management_ncc_input'
                        onChange={onChange}
                        prefix={<Typography.Text type='badgeLarge'>$</Typography.Text>}
                    />
                </Form.Item>
            </Flex>
        </Flex>
    );
};

export default NewCustomerCommission;
