import React, { useState } from 'react';
import { CancelSave, ErrorFallback } from '@components';
import { BOTTOM_INDENTS, VALIDATION_RULES } from '@constants';
import { useStore } from '@hooks';
import { Input } from '@ui';
import { Flex, Form } from 'antd';
import { observer } from 'mobx-react';
import { CONFIRM_PASSWORD_RULES, FIELD_NAMES } from './constants';

const ChangePasswordForm = observer(({ onClose }) => {
    const store = useStore();
    const { CONFIRM_PASSWORD, NEW_PASSWORD, OLD_PASSWORD } = FIELD_NAMES;
    const { updateProfile, passwordUpdateErrorMessage } = store.profileStore;
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [form] = Form.useForm();

    return (
        <Form
            form={form}
            onFinish={async ({ confirmPassword, ...values }) => {
                setFormSubmitting(true);
                const data = await updateProfile(values);
                if (data) {
                    form.resetFields(Object.values(FIELD_NAMES));
                    onClose();
                }
                setFormSubmitting(false);
            }}
        >
            <Flex vertical gap={16}>
                <Form.Item data-e2e='profile_old-password_field' name={OLD_PASSWORD} rules={VALIDATION_RULES.REQUIRED}>
                    <Input.Password data-e2e='profile_old-password_input' noPrefix label='Enter old password' />
                </Form.Item>
                <Form.Item
                    data-e2e='profile_new-password_field'
                    name={NEW_PASSWORD}
                    rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.PASSWORD]}
                >
                    <Input.Password data-e2e='profile_new-password_input' noPrefix label='Enter new password' />
                </Form.Item>
                <Form.Item
                    data-e2e='profile_confirm-password_field'
                    dependencies={[NEW_PASSWORD]}
                    name={CONFIRM_PASSWORD}
                    rules={CONFIRM_PASSWORD_RULES}
                    style={BOTTOM_INDENTS.XL_PLUS}
                >
                    <Input.Password data-e2e='profile_confirm-password_input' noPrefix label='Confirm new password' />
                </Form.Item>
            </Flex>
            <CancelSave onCancel={onClose} loading={formSubmitting} />

            <ErrorFallback.Message message={passwordUpdateErrorMessage} />
        </Form>
    );
});

export default ChangePasswordForm;
