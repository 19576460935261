import styled from 'styled-components';

export const StyledSlider = styled.div`
    position: relative;
    margin-bottom: 128px;
    margin-top: 58px;

    @media (max-width: 860px) {
        margin-top: 116px;
        margin-bottom: 60px;
        overflow: hidden;
    }

    &.bg-gradient {
        &:before {
            clip-path: polygon(0px 150px, 100% 0px, 100% calc(100% - 150px), 0px 100%);

            @media (max-width: 860px) {
                display: none;
            }
        }
    }

    .v-slider__wrapper {
        z-index: 1;
        position: relative;

        h2 {
            font-size: 64px;
            font-weight: 700;
            line-height: 64px;
            margin-bottom: 24px;
            color: #04080f;
            font-family: 'IBM Plex Sans', Helvetica, sans-serif;

            @media (max-width: 860px) {
                font-size: 48px;
                line-height: 56px;
                margin-bottom: 64px;

                br {
                    display: none;
                }
            }
        }

        &__slides {
            display: flex;
            padding-bottom: 64px;

            @media (max-width: 860px) {
                padding-bottom: 0;
            }

            &__content {
                &__single {
                    @media (max-width: 860px) {
                        padding-bottom: 70px;
                        padding-top: 70px;
                        position: relative;
                        align-items: center;
                    }

                    &:first-of-type,
                    &:nth-of-type(3) {
                        &:before {
                            display: none;
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            clip-path: polygon(0px 30px, 100% 0px, 100% 100%, 0px calc(100% - 30px));
                            background: linear-gradient(230deg, #c402a4 0%, #ff6cac 50%);
                            width: calc(100% + 48px);
                            height: 100%;
                            z-index: 0;
                            margin-left: -24px;

                            @media (max-width: 860px) {
                                display: block;
                            }
                        }
                    }

                    img {
                        z-index: 1;
                        width: 430px;
                        height: 250px;
                        object-fit: contain;
                    }

                    h3 {
                        z-index: 1;
                        font-size: 48px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 44px;
                        margin-bottom: 24px;
                        color: #04080f;
                        font-family: 'IBM Plex Sans', Helvetica, sans-serif;

                        @media (max-width: 860px) {
                            font-size: 36px;
                            line-height: 44px;
                            margin-bottom: 16px;
                            margin-right: auto;
                        }
                    }

                    span {
                        z-index: 1;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        color: #04080f;
                        font-family: 'IBM Plex Sans', Helvetica, sans-serif;
                    }
                }
            }

            &__animation {
                &.Reach {
                    > img:first-of-type {
                        opacity: 1;
                    }
                }
                &.Identify {
                    > img:nth-of-type(2) {
                        opacity: 1;
                    }
                }
                &.Personalize {
                    .v-slider__personalize {
                        opacity: 1;

                        img {
                            opacity: 1;
                        }
                    }
                }
                &.Control {
                    > img:last-of-type {
                        opacity: 1;
                    }
                }
            }

            &__animation,
            &__dots {
                display: flex;
                align-items: center;
                flex: 1 1 auto;
                position: sticky;
                top: calc(50% - 190px);
                height: 400px;
                margin: 100px 0;

                @media (max-width: 860px) {
                    display: none;
                }

                img {
                    position: absolute;
                    opacity: 0;
                    width: 100%;
                    object-fit: contain;
                    transition: 0.5s ease-in-out;
                    max-height: 380px;

                    &.active {
                        opacity: 1;
                    }
                }
            }

            &__dots {
                flex: 0 0 8px;
                flex-direction: column;
                justify-content: center;
                gap: 16px;

                span {
                    background: #da1781;
                    opacity: 0.12;
                    height: 53px;
                    width: 100%;

                    &.active {
                        opacity: 1;
                    }
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                flex: 0 0 353px;
                margin-right: 48px;
                margin-left: 48px;

                @media (max-width: 860px) {
                    margin-left: 0;
                    flex: initial;
                    margin-right: 0;
                    width: 100%;
                }

                & > div {
                    height: 600px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    @media (max-width: 860px) {
                        height: initial;
                    }
                }
            }
        }
    }
`;
