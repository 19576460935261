import { COLORS } from '@constants';
import { Typography } from '@ui';
import { Input, Tag } from 'antd';
import styled from 'styled-components';

export const TextareaWithTags = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 16px;
    border: 1px solid ${COLORS.$gray20Black};
    border-radius: 8px;
    min-height: 190px;
    overflow-y: auto;
    cursor: text;
`;

export const StyledTextAreaText = styled(Typography.Text)`
    flex: 0;
`;

export const TagsContainer = styled.div`
    flex: 1;
`;

export const StyledInput = styled(Input)`
    border: none;
    outline: none;
    padding: 5px;
    margin-top: 10px;
`;

export const StyledTag = styled(Tag)`
    &&& {
        border: 1px solid ${COLORS.$gray20Black};
        border-radius: 4px;
        background-color: transparent;
        margin-top: 9px;
        padding: 2px 8px;

        display: inline-flex;

        &:not(:last-child) {
            margin-right: 9px;
        }

        & .ant-tag-close-icon {
            margin-inline: 8px 0;
            font-size: 12px;
            color: ${COLORS.$gray60Black};
        }
    }
`;

export const StyledKeywordsItems = styled.div`
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
`;
