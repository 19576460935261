import React from 'react';
import { BOTTOM_INDENTS, COLORS, FIELD_NAME } from '@constants';
import { Input, Typography, WhiteCover } from '@ui';
import { Flex, Form } from 'antd';

export const OfferMessage = () => {
    return (
        <WhiteCover data-e2e='creative-settings_offer-message_block' style={BOTTOM_INDENTS.M} title='Offer Message'>
            <Flex gap={24} align='center'>
                <Flex vertical style={{ flexBasis: '50%' }}>
                    <Form.Item
                        name={FIELD_NAME.CREATIVE.OFFER_MESSAGE}
                        rules={[
                            { required: true, message: 'Offer message is required' },
                            { max: 30, message: '' }
                        ]}
                    >
                        <Input data-e2e='creative-settings_offer-message_input' label='Offer Message' />
                    </Form.Item>
                </Flex>

                <Flex justify='center' align='center' style={{ flexBasis: '50%' }}>
                    <Typography.Text
                        data-e2e='creative-settings_offer-message_description'
                        ty='body2'
                        align='center'
                        color={COLORS.$gray60Black}
                    >
                        Your offer message can be up to 30 characters
                    </Typography.Text>
                </Flex>
            </Flex>
        </WhiteCover>
    );
};
