import React from 'react';
import { Loader } from '@ui';
import { Col } from 'antd';
import { StyledCard } from './styled';

const CheckboxCardsPlaceholder = () => {
    const CARDS_AMOUNT = 9;

    return Array.from(Array(CARDS_AMOUNT).keys()).map((item) => (
        <Col span={8} key={item}>
            <StyledCard>
                <Loader />
            </StyledCard>
        </Col>
    ));
};

export default CheckboxCardsPlaceholder;
