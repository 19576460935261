import { useCallback, useState } from 'react';
import { CAMPAIGN_TYPE_ID } from '@enums';
import { useStore } from '@hooks';
import { Icon } from '@ui';
import _ from 'lodash';
import { toJS } from 'mobx';

const useSpecificProductType = () => {
    const store = useStore();
    const { productTypes } = store.accountsStore.currentAccount;

    const [productTypesToShow, setProductTypesToShow] = useState([]);
    const [selectedProductTypes, setSelectedProductTypes] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const debouncedOnSearchChange = useCallback(
        _.debounce((string) => {
            setProductTypesToShow(
                toJS(productTypes.filter(({ title }) => title.toLowerCase().includes(string.toLowerCase())))
            );
        }, 500),
        []
    );

    const onSearchChange = (e) => {
        setSearchValue(e.target.value);
        debouncedOnSearchChange(e.target.value);
    };

    const toggleProductType = (item) => {
        if (!selectedProductTypes.find((i) => i.id === item.id)) {
            setSelectedProductTypes([...selectedProductTypes, item]);
        } else {
            setSelectedProductTypes(selectedProductTypes.filter((i) => i.id !== item.id));
        }
    };

    const unselectProductType = (item) => {
        setSelectedProductTypes(selectedProductTypes.filter((i) => i.id !== item.id));
    };

    const initialAction = () => {
        const { productTypes } = store.accountsStore.currentAccount;
        setProductTypesToShow(toJS(productTypes));
    };

    const willUnmountAction = () => {
        setSelectedProductTypes([]);
        setSearchValue('');
    };

    const specificProductTypeProps = {
        value: CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCT_TYPES,
        label: 'Specific Product Types',
        description: 'Allow Klickly to advertise using products from one or more specific product types.',
        CardIcon: Icon.ProductTypesIcon,
        expandable: true,
        expandedContentProps: {
            placeholder: 'Filter product types by name',
            listItems: productTypesToShow,
            selectedListItems: selectedProductTypes,
            onListItemClick: toggleProductType,
            onTagClose: unselectProductType,
            searchValue,
            setSearchValue,
            onSearchChange,
            initialAction,
            willUnmountAction
        }
    };

    return specificProductTypeProps;
};

export { useSpecificProductType };
