import { Typography } from '@ui';
import styled from 'styled-components';

export const StyledWrapper = styled.label`
    display: flex;
    gap: 16px;
    margin-top: 32px;
`;

export const StyledTypography = styled(Typography.Text)`
    font-size: 14px;
`;
