import React from 'react';
import nextId from 'react-id-generator';
import { CloseOutlined } from '@ant-design/icons';
import { COLORS, TOP_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Button, Divider, Icon, Typography } from '@ui';
import { Col, Modal, Row } from 'antd';
import { observer } from 'mobx-react';
import ListItem from './components/ListItem';
import { getSplitColumnsByCategory } from './utils/getSplitColumnsByCategory';
import { StyledRow } from './styled';

const CustomizeModal = observer(({ isModalOpen, setIsModalOpen, date }) => {
    const store = useStore();
    const { summaryCategoryCol, engagementsDetailsCategoryCol, orderDetailsCategoryCol } = getSplitColumnsByCategory();
    const { setDefaultReportMetrics, resetBufferReportMetric, applyReportMetric, getReports } = store.performanceStore;

    const customClose = () => {
        resetBufferReportMetric();
        setIsModalOpen(false);
    };

    return (
        <Modal
            width='50%'
            style={{ top: '10px' }}
            open={isModalOpen}
            onCancel={customClose}
            footer={null}
            maskClosable={false}
            closeIcon={
                <span data-e2e='report_close_btn' className='ant-modal-close-x'>
                    <CloseOutlined /> {}
                </span>
            }
        >
            <Row wrap={false} align='middle'>
                <Col span={12}>
                    <Typography.Title data-e2e='report_customize-columns_modal-title' level={2}>
                        Customize columns
                    </Typography.Title>
                </Col>
                <Col span={12}>
                    <StyledRow onClick={setDefaultReportMetrics} gutter={[16, 16]} align='middle'>
                        <Col style={TOP_INDENTS.XXS}>
                            <Icon size='24px' color={COLORS.$gray60Black} component={() => <Icon.ResetIcon />} />
                        </Col>
                        <Col>
                            <Typography.Title data-e2e='report_reset_btn' color={COLORS.$gray60Black} level={3}>
                                Reset to default settings
                            </Typography.Title>
                        </Col>
                    </StyledRow>
                </Col>
            </Row>
            <Divider margin='20px 0' />
            <Row gutter={[32, 32]}>
                <Col data-e2e='report_summary_section' span={8}>
                    <Typography.Title level={3}>Summary</Typography.Title>
                    {summaryCategoryCol?.map((item) => {
                        return <ListItem key={nextId()} item={item} />;
                    })}
                </Col>
                <Col data-e2e='report_engagements-details_section' span={8}>
                    <Typography.Title level={3}>Engagements details</Typography.Title>
                    {engagementsDetailsCategoryCol?.map((item) => {
                        return <ListItem key={nextId()} item={item} />;
                    })}
                </Col>
                <Col data-e2e='report_order-details_section' span={8}>
                    <Typography.Title level={3}>Order details</Typography.Title>
                    {orderDetailsCategoryCol?.map((item) => {
                        return <ListItem key={nextId()} item={item} />;
                    })}
                </Col>
            </Row>
            <Row style={TOP_INDENTS.S} gutter={10} justify='end'>
                <Col span={6}>
                    <Button data-e2e='report_cancel_btn' onClick={customClose} type='default' key='cancel' block>
                        Cancel
                    </Button>
                </Col>
                <Col span={6}>
                    <Button
                        data-e2e='report_apply_btn'
                        onClick={() => {
                            applyReportMetric();
                            getReports(date);
                            setIsModalOpen(false);
                        }}
                        type='primary'
                        key='apply'
                        block
                    >
                        Apply
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
});

export default CustomizeModal;
