import React from 'react';
import { ROUTE_PATHS } from '@constants';
import { Icon } from '../Icon';
import { StyledLink } from './styled';

export const LogoConnector = () => {
    return (
        <StyledLink to={ROUTE_PATHS.WELCOME}>
            <Icon.KlicklyConnectorLogo />
        </StyledLink>
    );
};
