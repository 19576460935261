import React from 'react';
import { Bar } from 'react-chartjs-2';
import { BOTTOM_INDENTS } from '@constants';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { convertDataToBarScheme } from '../../../helpers';
import { useCustomTooltip } from '../../../hooks';
import { StyledChartWrapper, StyledWrapper } from './styled';
import { getOptions } from './utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const TwoLevelVerticalBarChart = ({
    datasetTop,
    datasetBottom,
    height = 300,
    chartValuePrefix = '',
    chartValueSuffix = '%'
}) => {
    if (!datasetTop || !datasetBottom) return null;

    const {
        labels: labelsTop,
        seedData: seedDataTop,
        baseData: baseDataTop,
        diffData: diffDataTop,
        count: countTop,
        datasets: datasetsTop
    } = convertDataToBarScheme({ data: datasetTop, chartValuePrefix, chartValueSuffix });

    const {
        labels: labelsBottom,
        seedData: seedDataBottom,
        baseData: baseDataBottom,
        diffData: diffDataBottom,
        count: countBottom,
        datasets: datasetsBottom
    } = convertDataToBarScheme({ data: datasetBottom, chartValuePrefix, chartValueSuffix, withNegativeValues: true });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const createTooltipTop = useCustomTooltip({
        chartModel: 'bar',
        data: {
            seedData: seedDataTop,
            baseData: baseDataTop,
            count: countTop,
            diffData: diffDataTop,
            labels: labelsTop
        }
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const createTooltipBottom = useCustomTooltip({
        chartModel: 'bar',
        data: {
            seedData: seedDataBottom,
            baseData: baseDataBottom,
            count: countBottom,
            diffData: diffDataBottom,
            labels: labelsBottom
        },
        hideNegative: true
    });

    const topChartOptions = getOptions({ chart: 1, externalTooltipHandler: createTooltipTop });
    const bottomChartOptions = getOptions({ chart: 2, externalTooltipHandler: createTooltipBottom });

    const topChartData = {
        labels: labelsTop,
        datasets: datasetsTop
    };

    const bottomChartData = {
        labels: labelsBottom,
        datasets: datasetsBottom
    };

    return (
        <StyledWrapper>
            <StyledChartWrapper $height={height} style={BOTTOM_INDENTS.XXS}>
                <Bar plugins={[ChartDataLabels]} options={topChartOptions} data={topChartData} />
            </StyledChartWrapper>
            <StyledChartWrapper $height={height}>
                <Bar plugins={[ChartDataLabels]} options={bottomChartOptions} data={bottomChartData} />
            </StyledChartWrapper>
        </StyledWrapper>
    );
};

export default TwoLevelVerticalBarChart;
