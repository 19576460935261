import { CAMPAIGN_TYPE_ID } from '@enums';
import { Icon } from '@ui';

export const TOTAL_STEPS = 5;

export const CAMPAIGN_TYPES = [
    {
        label: 'My Entire Catalog',
        value: CAMPAIGN_TYPE_ID.MY_ENTIRE_CATALOG,
        CardIcon: Icon.EntireCatalogIcon,
        description: 'Allow Klickly to advertise all of your available products.'
    },
    {
        label: 'Specific Products',
        value: CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCTS,
        CardIcon: Icon.SpecificProductsIcon,
        description: 'Allow Klickly to advertise using 1 or more specific products.'
    },
    {
        label: 'Specific Collections',
        value: CAMPAIGN_TYPE_ID.SPECIFIC_COLLECTIONS,
        CardIcon: Icon.CollectionsIcon,
        disabled: true,
        description: 'Allow Klickly to advertise using products from one or more specific collections.'
    },
    {
        label: 'Specific Product Types',
        value: CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCT_TYPES,
        CardIcon: Icon.ProductTypesIcon,
        description: 'Allow Klickly to advertise using products from one or more specific product types.'
    },
    {
        label: 'My Best-sellers',
        value: CAMPAIGN_TYPE_ID.BEST_SELLERS,
        CardIcon: Icon.BestsellersSmallIcon,
        description: 'Allow Klickly to advertise your best sellers.'
    }
];
