import React, { useEffect, useState } from 'react';
import { CancelSave } from '@components';
import { COLORS } from '@constants';
import { useStore } from '@hooks';
import { Divider, Typography } from '@ui';
import { showFormNotification } from '@utils';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import KlicklyInfoFields from '../../../../../Profile/components/AccountSettings/components/CompanyInfo/components/KlicklyInfoFields';
import { FIELD_NAMES } from '../../../../../Profile/components/AccountSettings/components/CompanyInfo/constants';
import InfoContentWrap from '../InfoContentWrap';

const ContactInfo = observer(({ closeCollapse }) => {
    const store = useStore();
    const { klicklyInfoFormUpdate } = store.accountsStore;
    const [formSubmitting, setFormSubmitting] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        resetForm();
    }, []);

    const resetForm = () => {
        const { currentAccount, klicklyEmail, klicklyPhone, addressForKlickly } = store.accountsStore;
        form.setFieldsValue({
            [FIELD_NAMES.COMPANY_NAME]: currentAccount.companyName,
            [FIELD_NAMES.COMPANY_PHONE]: klicklyPhone.value?.replace('+', ''),
            [FIELD_NAMES.COMPANY_EMAIL]: klicklyEmail.value,
            [FIELD_NAMES.COMPANY_STATE]: addressForKlickly.province,
            [FIELD_NAMES.CITY]: addressForKlickly.city,
            [FIELD_NAMES.ADDRESS]: addressForKlickly.address1,
            [FIELD_NAMES.APT_NUMBER]: addressForKlickly.address2,
            [FIELD_NAMES.ZIP_CODE]: addressForKlickly.zipCode
        });
    };

    const handleSubmit = async (values) => {
        setFormSubmitting(true);
        await klicklyInfoFormUpdate(values);
        setFormSubmitting(false);
        closeCollapse();
        showFormNotification(store.accountsStore.error);
    };

    const onCancel = () => {
        resetForm();
        closeCollapse();
    };

    return (
        <InfoContentWrap>
            <Typography.Text
                data-e2e='launchcampaign_contact-info_description'
                type='body2'
                color={COLORS.$gray60Black}
            >
                It&apos;s important that we know how to contact you when we have important account information to share
                (such as when you make a sale!)
            </Typography.Text>
            <Divider margin='24px 0' />
            <Form data-e2e='launchcampaign_contact-info_form' form={form} onFinish={handleSubmit}>
                <KlicklyInfoFields />
                <Divider margin='32px 0' />
                <CancelSave onCancel={onCancel} loading={formSubmitting} />
            </Form>
        </InfoContentWrap>
    );
});

export default ContactInfo;
