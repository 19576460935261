import React, { Fragment } from 'react';
import { SectionHeader } from '@components';
import { TOP_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { observer } from 'mobx-react';
import BoostCampaign from './components/BoostCampaign';
import CreateCampaign from './components/CreateCampaign';
import { StyledRow } from './styled';

const EmptyState = observer(() => {
    const store = useStore();
    const { campaigns } = store.campaignsStore;

    return (
        <Fragment>
            <SectionHeader data-e2e='orders_page_title' description='Orders' type='em0' />
            <StyledRow style={TOP_INDENTS.XL_PLUS} justify='middle' align='center' gutter={[24, 24]}>
                {campaigns.length ? <BoostCampaign /> : <CreateCampaign />}
            </StyledRow>
        </Fragment>
    );
});

export default EmptyState;
