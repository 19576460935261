import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 300px;
    padding: 24px;
    background-color: ${COLORS.$white};
    border: 1px solid ${COLORS.$gray15Black};
    border-radius: 8px;
    position: relative;

    .title {
        width: 100px;

        .ant-skeleton-title {
            height: 40px;
        }
    }

    .subtitle {
        width: 180px;

        .ant-skeleton-title {
            height: 20px;
        }
    }

    .description {
        .ant-skeleton-title {
            height: 20px;
        }

        &.ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph > li + li {
            margin-block-start: 8px;
        }
    }
`;
