import React from 'react';
import { ErrorFallback } from '@components';
import { withRouter } from '@hocs';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ hasError: false });
        }
    }

    componentDidCatch(error, info) {
        // Log the error to an error reporting service
        console.log('Error caught by boundary:', error, info); // eslint-disable-line
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorFallback
                    description='Try to refresh this page or come back some time later'
                    title='Whoops, something went wrong'
                />
            );
        }

        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
