import React, { Fragment, useEffect, useState } from 'react';
import { CancelSave } from '@components';
import { BOTTOM_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Loader, Typography } from '@ui';
import { showFormNotification } from '@utils';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import CompanyDetails from './components/CompanyDetails';
import Standards from './components/Standards';
import { FIELD_NAMES } from './constants';

const CompanyInfo = observer(() => {
    const store = useStore();
    const [form] = Form.useForm();
    const { currentAccount, companyInfoUpdate, getContactsForKlickly, getAddressForKlickly } = store.accountsStore;

    const [loading, setLoading] = useState(true);
    const [formSubmitting, setFormSubmitting] = useState(false);

    const resetForm = () => {
        const { currentAccount, klicklyEmail, klicklyPhone, addressForKlickly } = store.accountsStore;
        form.setFieldsValue({
            [FIELD_NAMES.COMPANY_NAME]: currentAccount.companyName,
            [FIELD_NAMES.COMPANY_PHONE]: klicklyPhone.value?.replace('+', ''),
            [FIELD_NAMES.COMPANY_EMAIL]: klicklyEmail.value,
            [FIELD_NAMES.COMPANY_STATE]: addressForKlickly.province,
            [FIELD_NAMES.CITY]: addressForKlickly.city,
            [FIELD_NAMES.ADDRESS]: addressForKlickly.address1,
            [FIELD_NAMES.APT_NUMBER]: addressForKlickly.address2,
            [FIELD_NAMES.ZIP_CODE]: addressForKlickly.zipCode
        });
    };

    useEffect(() => {
        (async () => {
            await Promise.all([getContactsForKlickly(), getAddressForKlickly()]);
            resetForm();
            setLoading(false);
        })();
    }, []);

    const handleFormSubmit = async (values) => {
        setFormSubmitting(true);

        await companyInfoUpdate(values);

        resetForm();
        setFormSubmitting(false);
        showFormNotification(store.accountsStore.error);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Fragment>
            <Typography.Title data-e2e='company-info_page_title' level={2} style={BOTTOM_INDENTS.M}>
                Company Info
            </Typography.Title>
            <CompanyDetails form={form} handleFormSubmit={handleFormSubmit} style={BOTTOM_INDENTS.M} />
            <Standards currentAccount={currentAccount} style={BOTTOM_INDENTS.M} />
            <CancelSave onSave={form.submit} onCancel={resetForm} loading={formSubmitting} />
        </Fragment>
    );
});

export default CompanyInfo;
