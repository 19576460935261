import React from 'react';
import { BOTTOM_INDENTS } from '@constants';
import { Button } from '@ui';
import { Flex } from 'antd';

const DeleteBtn = ({ onClick }) => {
    return (
        <Flex justify='end' style={BOTTOM_INDENTS.S}>
            <Button data-e2e='notifications_delete_btn' size='small' type='link' onClick={onClick}>
                Delete
            </Button>
        </Flex>
    );
};

export default DeleteBtn;
