import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStore } from '@hooks';
import { Row } from 'antd';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { observer } from 'mobx-react';
import { MENU_ITEM_IDS } from '../../constants';
import { usePerformanceScrollFetch } from '../../hooks';
import HorizontalBar from './components/HorizontalBar';
import HorizontalCards from './components/HorizontalCards';
import TopProducts from './components/TopProducts';
import { isTouchpointFunnelVisible } from './utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Breakdowns = observer(({ date, onComponentViewUpdate }) => {
    const [searchParams] = useSearchParams();
    const [initialFetchDone, setInitialFetch] = useState(false);
    const store = useStore();
    const { pendingBreakdowns, breakdowns, getBreakdowns } = store.performanceStore;

    const { componentRef } = usePerformanceScrollFetch({
        initialFetchDone,
        onInitialFetch: () => {
            setInitialFetch(true);
            getBreakdowns(date);
        },
        onReFetch: () => {
            getBreakdowns(date);
        },
        onComponentViewUpdate,
        date
    });

    const touchPointFunnelVisible = searchParams.has('touchpointFunnel')
        ? true
        : isTouchpointFunnelVisible(breakdowns?.touchpointFunnel); // use search param for QA testing purpose

    return (
        <Row ref={componentRef} id={MENU_ITEM_IDS.BREAKDOWNS} style={{ scrollMarginTop: '180px' }}>
            <HorizontalCards
                data-e2e='performance_engagement-funnel_section'
                title='Engagement funnel'
                data={breakdowns?.engagementFunnel}
                pending={pendingBreakdowns}
            />
            {touchPointFunnelVisible ? (
                <HorizontalCards
                    data-e2e='performance_touchpoint-funnel_section'
                    title='Touchpoint funnel'
                    hintTitle='Touchpoint funnel'
                    hintBody='When Klickly finds a best-fit consumer and shows them your products, this is called a touchpoint. “Days from first touchpoint” is the time since we first start advertising to the consumer. “Average touchpoints” is the  number of times Klickly touches a consumer to drive them to a purchase. “Days from last touchpoint” is the amount of time from last Klickly touch to when the consumer purchases.'
                    data={breakdowns?.touchpointFunnel}
                    pending={pendingBreakdowns}
                />
            ) : null}
            <TopProducts
                data-e2e='performance_top-products_section'
                title='Top products'
                data={breakdowns?.products}
                pending={pendingBreakdowns}
            />
            <HorizontalBar
                data-e2e='performance_top-campaigns_section'
                title='Top campaigns'
                data={breakdowns?.campaigns}
                pending={pendingBreakdowns}
            />
            <HorizontalBar
                data-e2e='performance_top-locations_section'
                title='Top Locations'
                data={breakdowns?.locations}
                pending={pendingBreakdowns}
            />
            <HorizontalBar
                data-e2e='performance_top-websites_section'
                title='Top websites'
                data={breakdowns?.websites}
                pending={pendingBreakdowns}
            />
        </Row>
    );
});

export default Breakdowns;
