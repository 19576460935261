import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import { Provider } from 'mobx-react';
import { CampaignWizardFormProvider } from './pages/CampaignWIzard/form';
import { ModalProvider } from './ui/Modal';
import store from './_store';
import App from './App';
import { GlobalStyles } from './GlobalStyles';
import { THEME } from './theme';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <GlobalStyles />
        <ConfigProvider theme={THEME}>
            <ModalProvider>
                <CampaignWizardFormProvider>
                    <App />
                </CampaignWizardFormProvider>
            </ModalProvider>
        </ConfigProvider>
    </Provider>
);
