import React, { useEffect, useState } from 'react';
import { BOTTOM_INDENTS, COLORS, RIGHT_INDENTS } from '@constants';
import { Button, Icon, Input, Typography, WhiteCover } from '@ui';
import { Flex, Form } from 'antd';
import { observer } from 'mobx-react';
import { FIELD_NAMES } from '../../constants';
import { StyledAlert } from '../../styled';
import CoverTitle from '../CoverTitle';

const IntegrationLoop = observer(({ isConnected, token = '', onSubmit }) => {
    const [form] = Form.useForm();
    const loopAccessToken = Form.useWatch(FIELD_NAMES.LOOP_ACCESS_TOKEN, form);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const isTokenDiff = loopAccessToken !== token;

    useEffect(() => {
        form.setFieldValue(FIELD_NAMES.LOOP_ACCESS_TOKEN, token);
    }, [token]);

    const handleFormSubmit = async (values) => {
        setFormSubmitting(true);
        await onSubmit(values?.loopAccessToken);

        setFormSubmitting(false);
    };

    return (
        <WhiteCover
            data-e2e='integrations_loop_section'
            customTitle={<CoverTitle title='Loop' icon={<Icon.LoopIcon />} />}
            rightContent={
                <Button
                    data-e2e='integrations_go-to-loop_link'
                    type='link'
                    padding='0'
                    onClick={() => window.open('https://www.loopwork.co/', '_blank')}
                >
                    Go to Loop site
                </Button>
            }
            style={BOTTOM_INDENTS.M}
        >
            <Typography.Text
                data-e2e='integrations_loop_description'
                type='body2'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.M}
            >
                Enable Loop Subscriptions to manage your subscription-based products (requires an active Loop API Token
                with Scopes for “Read Orders”, “Read Subscriptions”, “Read Webhooks” and “Write Webhooks”).
            </Typography.Text>
            <Form form={form} onFinish={handleFormSubmit} scrollToFirstError={{ behavior: 'smooth', block: 'center' }}>
                <Form.Item name={FIELD_NAMES.LOOP_ACCESS_TOKEN} style={BOTTOM_INDENTS.M}>
                    <Input data-e2e='integrations_api-token_input' label='API Token' />
                </Form.Item>
                <StyledAlert data-e2e='integrations_loop-connection-status_block' $isValid={isConnected}>
                    {isConnected ? (
                        <Flex justify={isTokenDiff ? 'space-between' : 'center'} align='center'>
                            <Typography.Text
                                align='center'
                                color={COLORS.$robinBlue}
                                type='descriptor1'
                                style={RIGHT_INDENTS.M}
                            >
                                API Token is applied. Ensure it is the same within your Loop account settings.
                            </Typography.Text>
                            {isTokenDiff ? (
                                <Button
                                    data-e2e='integrations_update-token_btn'
                                    htmlType='submit'
                                    type='primary'
                                    style={{ width: 160 }}
                                    loading={formSubmitting}
                                >
                                    Update Token
                                </Button>
                            ) : (
                                <Button
                                    data-e2e='integrations_disconnect-loop_btn'
                                    onClick={() => onSubmit('')}
                                    type='primary'
                                    style={{ width: 160 }}
                                    loading={formSubmitting}
                                >
                                    Disconnect
                                </Button>
                            )}
                        </Flex>
                    ) : (
                        <Flex justify='space-between' align='center'>
                            <Typography.Text color={COLORS.$tartRed} type='descriptor1' style={RIGHT_INDENTS.M}>
                                Please fill in Loop API Token with Scopes for “Read Orders”, “Read Subscriptions”, “Read
                                Webhooks” and “Write Webhooks” to connect Loop Subscriptions
                            </Typography.Text>
                            <Button
                                data-e2e='integrations_update-token_btn'
                                htmlType='submit'
                                type='primary'
                                style={{ width: 160 }}
                                loading={formSubmitting}
                            >
                                Update Token
                            </Button>
                        </Flex>
                    )}
                </StyledAlert>
            </Form>
        </WhiteCover>
    );
});

export default IntegrationLoop;
