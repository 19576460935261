import React from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '@constants';
import { Typography } from '@ui';
import { formatString } from '@utils';
import { Card, Col, Flex } from 'antd';

const SingleRecommendation = ({ item }) => {
    const navigate = useNavigate();
    return (
        <Col span={12}>
            <Card
                data-e2e={`home_recommendation-${formatString(item.title)}_card`}
                styles={{ body: { padding: '48px 24px' } }}
                style={{ border: `1px solid ${COLORS.$gray15Black}` }}
                hoverable
                onClick={() => navigate(item.redirect)}
            >
                <Flex align='center' justify='center' gap={24}>
                    <Flex align='center' justify='center'>
                        {item.logo}
                    </Flex>
                    <Flex vertical gap={8}>
                        <Typography.Text type='badgeLarge'>{item.title}</Typography.Text>
                        <Typography.Text color={COLORS.$gray60Black} type='descriptor1'>
                            {item.description}
                        </Typography.Text>
                    </Flex>
                </Flex>
            </Card>
        </Col>
    );
};

export default SingleRecommendation;
