import React from 'react';
import { StyledTabs } from './styled';

export const Tabs = ({ noHighLight, noBottomLine, fullWidth, items, ...props }) => {
    return (
        <StyledTabs
            items={items}
            $itemsCount={items?.length || 1}
            $fullWidth={fullWidth}
            $noHighLight={noHighLight}
            $noBottomLine={noBottomLine}
            {...props}
        />
    );
};
