import React, { useState } from 'react';
import { BOTTOM_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Card, Col, Row } from 'antd';
import * as get from 'lodash/get';
import { observer } from 'mobx-react';
import { tooltips } from '../../constants';
import { sortData } from '../../helpers';
import BaselineInfo from '../BaselineInfo';
import CardTitle from '../CardTitle';
import BarChart from '../charts/BarChart';
import DoughnutChart from '../charts/DoughnutChart';
import SortingBlock from '../SortingBlock';
import { StyledChartWrapper, StyledWrapper } from './styled';

const InterestsSection = observer(() => {
    const store = useStore();
    const [sorting, setSorting] = useState('desc');
    const [contentSorting, setContentSorting] = useState('desc');
    const { interestsChartsData } = store.insightsStore;

    return (
        <StyledWrapper>
            <Row style={BOTTOM_INDENTS.M}>
                <Col span={24}>
                    <Card
                        data-e2e='insights_general_card'
                        title={<CardTitle title='General' tooltip={tooltips.general} />}
                    >
                        <SortingBlock
                            activeSortState={sorting}
                            onAscClick={() => setSorting('asc')}
                            onDescClick={() => setSorting('desc')}
                        />
                        <StyledChartWrapper data-e2e='insights_general_chart' $height={1200} style={BOTTOM_INDENTS.M}>
                            <BarChart
                                chartType='horizontal'
                                data={sortData(get(interestsChartsData, 'general'), sorting)}
                            />
                        </StyledChartWrapper>
                        <BaselineInfo />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24} style={BOTTOM_INDENTS.M}>
                <Col span={12}>
                    <Card
                        data-e2e='insights_travel_card'
                        title={<CardTitle title='Travel' tooltip={tooltips.travel} />}
                    >
                        <StyledChartWrapper data-e2e='insights_travel_chart' $height={400}>
                            <DoughnutChart data={get(interestsChartsData, 'travel')} />
                        </StyledChartWrapper>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card data-e2e='insights_music_card' title={<CardTitle title='Music' tooltip={tooltips.music} />}>
                        <StyledChartWrapper data-e2e='insights_music_chart' $height={400}>
                            <DoughnutChart data={get(interestsChartsData, 'music')} />
                        </StyledChartWrapper>
                    </Card>
                </Col>
            </Row>
            <Row style={BOTTOM_INDENTS.M}>
                <Col span={24}>
                    <Card
                        data-e2e='insights_content-preferences_card'
                        title={<CardTitle title='Content preferences' tooltip={tooltips.contentPreferences} />}
                    >
                        <SortingBlock
                            activeSortState={contentSorting}
                            onAscClick={() => setContentSorting('asc')}
                            onDescClick={() => setContentSorting('desc')}
                        />
                        <StyledChartWrapper data-e2e='insights_content-preferences_chart' $height={1200}>
                            <BarChart
                                chartType='horizontal'
                                data={sortData(get(interestsChartsData, 'general'), contentSorting)}
                            />
                        </StyledChartWrapper>
                    </Card>
                </Col>
            </Row>
        </StyledWrapper>
    );
});

export default InterestsSection;
