import React, { useState } from 'react';
import { CancelSave } from '@components';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { useStore } from '@hooks';
import { Icon, Typography } from '@ui';
import { Col, Flex, Modal, Row } from 'antd';
import { toJS } from 'mobx';
import { Line, StyledCarousel, StyledImgWrapper, StyledText } from './styled';

const ProductName = ({ product, name }) => {
    const store = useStore();
    const { setPrimaryImageRestPlaces } = store.campaignWizardStore;

    const primaryImage = product.primaryImage;

    const [loading, setLoading] = useState(false);

    const [selectedImage, setSelectedImage] = useState(toJS(primaryImage));

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleSave = async () => {
        if (primaryImage?.id === selectedImage?.id) {
            setIsModalOpen(false);
            return;
        }

        setLoading(true);
        await product.setPrimaryImage(selectedImage?.id);
        setPrimaryImageRestPlaces(product.id, selectedImage?.id);

        setLoading(false);
        setIsModalOpen(false);
    };

    const handleCancel = (e) => {
        e.stopPropagation();
        setIsModalOpen(false);
    };

    const slidesToShow = product.images.length > 4 ? 4 : product.images.length;

    const sliderWidth = slidesToShow * 70 + 'px';

    const showModalOnClick = product.images.length > 1;

    return (
        <Row gutter={16} align='middle' wrap={false}>
            <Col>
                <Modal
                    centered
                    onCancel={handleCancel}
                    closable={false}
                    footer={null}
                    open={isModalOpen}
                    styles={{ content: { padding: 0, overflow: 'hidden' } }}
                >
                    <Flex
                        data-e2e='campaignwizard_change-product-photo_modal'
                        vertical
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Line />
                        <div style={{ padding: 36, backgroundColor: '#fdedf6' }}>
                            <Typography.Title
                                data-e2e='campaignwizard_change-product-photo-modal_title'
                                style={BOTTOM_INDENTS.S}
                                level={1}
                                color={COLORS.$pink}
                                align='center'
                            >
                                Change Product Photo
                            </Typography.Title>
                            <Typography.Text
                                data-e2e='campaignwizard_change-product-photo-modal_description'
                                type='descriptor1'
                                color={COLORS.$pink}
                                align='center'
                            >
                                Select the product image that you&apos;d like to use in campaign
                            </Typography.Text>
                        </div>
                        <Flex vertical align='center' gap={36} style={{ padding: 36 }}>
                            <div
                                style={{
                                    width: '280px',
                                    aspectRatio: '1/1',
                                    marginBottom: 12
                                }}
                            >
                                <img
                                    data-e2e='campaignwizard_selected-product-photo'
                                    width='100%'
                                    style={{ aspectRatio: '1/1', objectFit: 'cover' }}
                                    src={selectedImage?.src}
                                    alt={name}
                                />
                            </div>
                            <div style={{ width: sliderWidth }}>
                                <StyledCarousel
                                    dots={false}
                                    slidesToShow={slidesToShow}
                                    draggable
                                    arrows
                                    infinite={false}
                                >
                                    {product.images.map((image, i) => {
                                        return (
                                            <div key={i}>
                                                <Flex
                                                    style={{}}
                                                    justify='center'
                                                    onClick={() => {
                                                        setSelectedImage(toJS(image));
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            cursor: 'pointer',
                                                            width: 60,
                                                            height: 60,
                                                            border: `2px solid ${
                                                                image.id === selectedImage?.id
                                                                    ? COLORS.$pink
                                                                    : 'transparent'
                                                            }`
                                                        }}
                                                    >
                                                        <img
                                                            data-e2e={`campaignwizard_product-photo-${image.id}_value`}
                                                            width='100%'
                                                            style={{ aspectRatio: '1/1', objectFit: 'cover' }}
                                                            src={image.src}
                                                            alt={name}
                                                        />
                                                    </div>
                                                </Flex>
                                            </div>
                                        );
                                    })}
                                </StyledCarousel>
                            </div>

                            <CancelSave onCancel={handleCancel} onSave={handleSave} loading={loading} />
                        </Flex>
                    </Flex>
                </Modal>
                <StyledImgWrapper
                    data-e2e={`campaignwizard_product-${product.id}-image_value`}
                    showModalOnClick={showModalOnClick}
                    type='button'
                    onClick={(e) => {
                        e.stopPropagation();
                        if (showModalOnClick) {
                            showModal();
                        }
                    }}
                >
                    <div className='overlay'>
                        <Icon
                            data-e2e='campaignwizard_edit-product-photo_btn'
                            size='20px'
                            color={COLORS.$gray10Black}
                            component={() => <Icon.EditIcon />}
                        />
                    </div>
                    <img src={primaryImage?.src} alt={name} />
                </StyledImgWrapper>
            </Col>
            <Col>
                <StyledText data-e2e={`campaignwizard_product-${product.id}-title_value`} title={name}>
                    {name}
                </StyledText>
            </Col>
        </Row>
    );
};

export default ProductName;
