import { PLATFORM_ID } from '@enums';

export const PLATFORMS_AUTH = [
    {
        id: PLATFORM_ID.BIGCOMMERCE,
        value: PLATFORM_ID.BIGCOMMERCE,
        label: 'Bigcommerce',
        'data-e2e': 'signup_select-bigcommerce_option'
    },
    {
        id: PLATFORM_ID.COMMERCE_CLOUD,
        value: PLATFORM_ID.COMMERCE_CLOUD,
        label: 'Commerce Cloud'
    },
    {
        id: PLATFORM_ID.MAGENTO,
        value: PLATFORM_ID.MAGENTO,
        label: 'Magento'
    },
    {
        id: PLATFORM_ID.PRESTASHOP,
        value: PLATFORM_ID.PRESTASHOP,
        label: 'PrestaShop'
    },
    {
        id: PLATFORM_ID.SHOPIFY,
        value: PLATFORM_ID.SHOPIFY,
        label: 'Shopify',
        'data-e2e': 'signup_select-shopify_option'
    },
    {
        id: PLATFORM_ID.SQUARESPACE,
        value: PLATFORM_ID.SQUARESPACE,
        label: 'Squarespace'
    },
    {
        id: PLATFORM_ID.WOOCOMMERCE,
        value: PLATFORM_ID.WOOCOMMERCE,
        label: 'WooCommerce'
    },
    {
        id: PLATFORM_ID.KLICKLY,
        value: PLATFORM_ID.KLICKLY,
        label: 'Other',
        'data-e2e': 'signup_select-create-manually_option'
    }
];
