import { COLORS } from '@constants';
import { Typography } from '@ui';
import styled, { css } from 'styled-components';

export const StyledWrap = styled.div`
    width: 300px;
    height: 330px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;

    ${({ $isHomePage }) =>
        $isHomePage
            ? css`
                  &&& {
                      background-color: ${COLORS.$white};
                      border-radius: 4px;
                      border: 1px solid ${COLORS.$gray15Black};
                      }
                  }
              `
            : ''}
`;

export const StyledImage = styled.img`
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin: 0 auto 16px;
`;

export const StyledPlaceholder = styled.div`
    width: 150px;
    height: 150px;
    background-color: ${COLORS.$gray15Black};
    margin: 0 auto 16px;
`;

export const StyledContent = styled.div``;

export const StyledMetricsWrap = styled.div`
    max-width: 200px;
`;

export const StyledText = styled(Typography.Text)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    height: 48px;
    margin-bottom: 4px;
`;
