import { brandsOpenApi, connectorApi, ROUTE_PATHS } from '@constants';
import { PLATFORM_NAME } from '@enums';
import { axiosBoa, isIframe } from '@utils';
import axios from 'axios';
import config from '../../../config';

export const platformHandlers = {
    async bigcommerce({ setOauthPayload, navigate, me, oauthPayload, isUserExist }) {
        const hasUser = await isUserExist();

        if (!hasUser && !me) {
            return navigate(ROUTE_PATHS.REQUEST_ACCESS);
        }

        if (!me) {
            return navigate(ROUTE_PATHS.LOGIN);
        }

        if (oauthPayload && me) {
            const response = await axiosBoa.get(brandsOpenApi.auth.platformCallback(PLATFORM_NAME.BIGCOMMERCE), {
                params: oauthPayload
            });

            if (response.ok) {
                if (isIframe()) {
                    return navigate(ROUTE_PATHS.APP_EMBEDDED);
                }

                setOauthPayload(null);

                return navigate(ROUTE_PATHS.HOME + '/?setup=1');
            }
        }

        return navigate(ROUTE_PATHS.APP_EMBEDDED);
    },

    async shopify({ searchParams, navigate, me, isUserExist }) {
        const shop = searchParams.get('shop');
        const isEmbedded = searchParams.has('embedded');

        if (!isEmbedded) {
            const { data } = await axios.get(connectorApi.shopify.install, {
                params: {
                    nonce: null,
                    shop,
                    token_type: 'offline'
                }
            });

            return window.location.replace(data.url);
        }

        const checkShopifyVersion = await axios.get(connectorApi.shopify.version, {
            params: { shop }
        });

        const version = checkShopifyVersion.data?.version || 'V1';

        if (version === 'V2') {
            const hasUser = await isUserExist();

            if (!hasUser && !me) {
                return navigate(ROUTE_PATHS.REQUEST_ACCESS);
            }

            if (!me) {
                return navigate(ROUTE_PATHS.LOGIN);
            }

            return navigate(ROUTE_PATHS.APP_EMBEDDED);
        }

        /* redirect to v1 */
        window.location.href = `${config.appUrlV1}/?${searchParams.toString()}`;
    }
};
