import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Icon, Radio, Typography } from '@ui';
import { Col, Row } from 'antd';
import { CampaignTypeCardWrapper } from '../../pages/Step1/components/CampaignTypeCard/styled';

const CampaignTypeCardSimple = ({
    CardIcon = () => null,
    label,
    description,
    items,
    size = 'large',
    style,
    ...props
}) => {
    const isSmall = size === 'small';
    return (
        <CampaignTypeCardWrapper style={style} $isSmall={isSmall}>
            <Radio {...props} />
            <Row gutter={24} align='middle' wrap={false}>
                <Col>
                    <Icon color={COLORS.$gray40Black} size={isSmall ? '56px' : '80px'} component={() => <CardIcon />} />
                </Col>
                <Col data-e2e='campaignwizard_campaign-type_value'>
                    <Typography.Text
                        type={isSmall ? 'badgeMedium' : 'badgeLarge'}
                        style={!isSmall ? BOTTOM_INDENTS.XXS : {}}
                    >
                        {label}
                    </Typography.Text>
                    {description && !isSmall && <Typography.Text type='descriptor1'>{description}</Typography.Text>}
                </Col>
            </Row>
        </CampaignTypeCardWrapper>
    );
};

export default CampaignTypeCardSimple;
