import React from 'react';
import Header from './components/Header';
import { StyledCollapce } from './styled';

const CustomCollapse = ({ items, activeKeys, setActiveKeys }) => {
    const handleChange = (val) => {
        setActiveKeys(val);
    };

    const collapseItems = items.map(({ content, itemKey, status, title, collapsible = true }) => ({
        key: itemKey,
        label: <Header itemKey={itemKey} status={status} title={title} />,
        children: content,
        collapsible,
        showArrow: false
    }));

    return <StyledCollapce activeKey={activeKeys} ghost onChange={handleChange} items={collapseItems} />;
};

export default CustomCollapse;
