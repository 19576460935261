import { brandsOpenApi } from '@constants';
import { axiosBoa, getErrorMessage } from '@utils';
import { flow, getParent, types } from 'mobx-state-tree';

export const ProfileStore = types
    .model('ProfileStore', {
        pending: false,
        isShowAcceptPayments: false,
        emailUpdatePending: false,
        passwordUpdatePending: false,
        memberInvitePending: false,
        memberEditPending: false,
        billingAddPending: false,
        error: types.maybeNull(types.model({ title: types.string, description: types.string })),
        emailUpdateErrorMessage: types.maybeNull(types.string),
        passwordUpdateErrorMessage: types.maybeNull(types.string),
        data: types.maybeNull(
            types.model({
                _id: types.maybeNull(types.string),
                email: types.maybeNull(types.string),
                firstName: types.maybeNull(types.string),
                lastName: types.maybeNull(types.string),
                phone: types.maybeNull(types.string)
            })
        ),
        teamMembers: types.array(
            types.model({
                _id: types.maybeNull(types.string),
                email: types.maybeNull(types.string),
                firstName: types.maybeNull(types.string),
                lastName: types.maybeNull(types.string),
                updatedAt: types.maybeNull(types.string),
                firstLastShort: types.maybeNull(types.string),
                access: types.maybeNull(types.string)
            })
        ),
        billingInfo: types.array(
            types.model({
                _id: types.maybeNull(types.string),
                last4: types.maybeNull(types.string),
                date: types.maybeNull(types.string),
                type: types.maybeNull(types.string)
            })
        )
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => ({
        updateProfile: flow(function* updateProfile(data) {
            self.error = null;
            self.pending = true;
            try {
                // TODO need to update store with new values after update
                const response = yield axiosBoa.patch(brandsOpenApi.profile.update, data);
                return response;
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pending = false;
            }
        })
    }));
