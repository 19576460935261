import { COLORS } from '@constants';
import { Tabs } from '@ui';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    background-color: ${COLORS.$white};
    padding: 16px;
    border: 1px solid ${COLORS.$gray20Black};
    border-radius: 8px;
`;

export const StyledRow = styled.div`
    width: 100%;
`;

export const StyledTabs = styled(Tabs)`
    && {
        .ant-tabs-nav {
            margin-bottom: 48px;
        }
    }
`;
