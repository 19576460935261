import React, { Fragment, useState } from 'react';
import { CancelSave } from '@components';
import { ACCOUNT_STATUS } from '@enums';
import { Input } from '@ui';
import { StyledTypography } from '../../styled';
import ShutdownSuccess from '../ShutdownSuccess/index';

const ShutdownConfirm = ({ customClose, setModalContent, changeStatus }) => {
    const [inputValue, setInputValue] = useState('');

    return (
        <Fragment>
            <StyledTypography data-e2e='account-management_shutdown-account-modal_hint'>
                You are about to confirm the permanent shutdown of your account. This action cannot be undone. To
                proceed, type SHUT DOWN in the box and click Confirm.
            </StyledTypography>
            <Input
                data-e2e='account-management_shutdown-account-modal_input'
                style={{ marginBlock: 16 }}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
            />
            <CancelSave
                disabled={inputValue !== 'SHUT DOWN'}
                onSave={async () => {
                    if (inputValue === 'SHUT DOWN') {
                        await changeStatus(ACCOUNT_STATUS.SHUTDOWN);
                        setModalContent(<ShutdownSuccess />);
                    }
                }}
                onCancel={() => {
                    customClose();
                    setInputValue('');
                }}
                saveText='Confirm'
            />
        </Fragment>
    );
};

export default ShutdownConfirm;
