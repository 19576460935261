import React from 'react';
import { COLORS } from '@constants';
import { Icon } from '../../../Icon';

const SortIcon = ({ sortOrder }) => {
    return (
        <Icon
            size='16px'
            color={sortOrder === 'descend' ? COLORS.$pink : COLORS.$gray40Black}
            component={() =>
                sortOrder === 'descend' ? (
                    <Icon.ArrowChevronUpIcon data-e2e='common_sort-descending_icon' />
                ) : (
                    <Icon.ArrowChevronDownIcon data-e2e='common_sort-ascending_icon' />
                )
            }
        />
    );
};

export default SortIcon;
