import { COLORS } from '@constants';
import { Icon } from '@ui';
import { Card } from 'antd';
import styled, { css } from 'styled-components';

export const StyledIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    ${({ $isActive }) =>
        $isActive &&
        css`
            svg {
                path {
                    fill: ${COLORS.$white};
                }

                rect:last-child {
                    opacity: 0;
                }
            }
        `};
`;

export const StyledCard = styled(Card)`
    .ant-card-body {
        padding: 16px;
    }
`;

export const InfoIcon = styled(Icon.InfoFilledIcon)`
    &:hover {
        color: ${COLORS.$gray80Black};
        cursor: pointer;
    }
`;
