import { COLORS } from '@constants';
import { Icon } from '@ui';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .ant-upload-wrapper.ant-upload-picture-card-wrapper,
    .ant-upload-wrapper.ant-upload-picture-circle-wrapper {
        width: ${({ $size }) => `${$size}px` || '130px'};
    }

    .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
    .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
        width: ${({ $size }) => `${$size}px` || '130px'};
        height: ${({ $size }) => `${$size}px` || '130px'};
        margin: 0;
        margin-inline-end: 0;

        > .ant-upload {
            display: block;
        }
    }

    .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover,
    .ant-upload-wrapper.ant-upload-picture-circle-wrapper
        .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
        border-color: transparent;
    }

    .ant-avatar {
        + .ant-btn {
            position: absolute;
            right: calc(50% - 100px);
            bottom: -5px;
        }
    }

    .ant-modal .ant-modal-content {
        padding: 24px;
    }

    .ant-modal-title {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid ${COLORS.$gray15Black};
    }

    .ant-modal .ant-modal-close {
        top: 28px;
        right: 24px;
        width: 24px;
        height: 24px;
        color: ${COLORS.$gray60Black};
        &:hover {
            background-color: transparent;
            text-decoration: none;
        }
    }

    .ant-modal-footer {
        margin: 0;
        padding-top: 24px;
        border-top: 1px solid ${COLORS.$gray15Black};

        .ant-btn {
            width: 156px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;

            &:first-child {
                margin-right: 16px;
            }

            &.ant-btn-primary {
                background: ${COLORS.$pinkGradient};
                transition: none;

                &:hover {
                    background: ${COLORS.$pinkDark};
                }
            }

            &.ant-btn-default {
                background: ${COLORS.$white};

                &:hover {
                    background: ${COLORS.$gray20Black};
                    color: ${COLORS.$white};
                }
            }
        }
    }

    .img-crop-control {
        width: 360px;
        button {
            display: block;
            color: transparent;
            background: url('data:image/svg+xml,<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Trash"><rect id="Rectangle 618" x="1" y="5" width="22" height="14" rx="2" stroke="%2377838F" stroke-width="2" stroke-linejoin="round"/><path id="Vector 119" d="M23 17L18.6596 13.0937C18.2833 12.755 17.7132 12.751 17.3321 13.0844L14.7603 15.3347C14.3417 15.7011 13.7046 15.6553 13.3425 15.233L8.77979 9.90975C8.37376 9.43605 7.63785 9.44573 7.24442 9.92995L1.5 17" stroke="%2377838F" stroke-width="2"/><circle id="Ellipse 15" cx="15" cy="9" r="2" fill="%2377838F"/></g></svg>')
                no-repeat center;

            &:first-child {
                background-size: 24px;
                width: 24px;
                margin-right: 16px;
            }
            &:last-child {
                background-size: 38px;
                width: 38px;
                margin-left: 16px;
            }
        }
    }

    .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
    .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select,
    .ant-avatar {
        border: none;
    }
`;

export const StyledCloseIcon = styled(Icon)`
    padding: 8px;
    border-radius: 4px;

    &:hover {
        background-color: ${COLORS.$gray20Black};
    }
`;
