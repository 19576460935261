import styled from 'styled-components';

export const StyledTitleWrapper = styled.div`
    .ant-tooltip {
        width: fit-content;
        max-width: 350px;
    }

    .ant-tooltip-content {
        width: fit-content;
    }

    .ant-tooltip .ant-tooltip-inner {
        padding: 16px 24px;
        width: fit-content;
    }
`;
