import React, { Fragment } from 'react';
import { COLORS } from '@constants';
import { Button, Typography } from '@ui';
import { Flex } from 'antd';
import { StyledAlert } from './styled';

const NotConnected = ({ storeUrl }) => {
    return (
        <Fragment>
            <StyledAlert>
                <Flex justify='space-between' align='center'>
                    <Typography.Text
                        data-e2e='integrations_notconnected-status_block'
                        color={COLORS.$tartRed}
                        type='descriptor1'
                    >
                        Shopify App is not connected to Klickly.
                    </Typography.Text>
                    <Button
                        data-e2e='integrations_go-to-connector-app_btn'
                        style={{ width: 184 }}
                        block
                        type='primary'
                        onClick={() => {
                            window.open(`https://${storeUrl}/admin`, '_blank');
                        }}
                    >
                        Go to my Shopify store
                    </Button>
                </Flex>
            </StyledAlert>
        </Fragment>
    );
};

export default NotConnected;
