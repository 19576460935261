import React, { useMemo } from 'react';
import KlicklyTemplates, { KlicklyProvider } from '@klickly/klickly-templates';
import { observer } from 'mobx-react';
import { StyledSlider } from '../../styled';

const UnitItem = observer(({ promotions, brandedData, layout, width = 300, height = 600 }) => {
    const filteredPromotions = useMemo(() => {
        return width === 970 && height === 250 ? promotions : promotions.slice(0, 6);
    }, [promotions, width, height]);

    const staticData = useMemo(() => {
        return {
            width,
            height,
            promotions: filteredPromotions,
            layout,
            maxPriceDifferencePercent:
                (promotions.length
                    ? Math.max(...promotions.map((item) => Number(item.maxPriceDifferencePercent)))
                    : 0) || 0
        };
    }, [promotions.length]);

    if (!promotions.length) {
        return null;
    }

    return (
        <StyledSlider $width={width} $height={height}>
            <KlicklyProvider staticDataContext={staticData}>
                <KlicklyTemplates brandedData={brandedData} />
            </KlicklyProvider>
        </StyledSlider>
    );
});

export default UnitItem;
