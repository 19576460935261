import { Flex } from 'antd';
import styled from 'styled-components';

export const StyledFlex = styled(Flex)`
    width: 100%;
    padding-right: 15px;
`;

export const StyledLink = styled.a`
    font-weight: inherit;
    font-size: 16px;
    line-height: 24px;

    &&:hover {
        text-decoration: underline;
    }
`;
