import { COLORS } from '@constants';
import styled, { css } from 'styled-components';

export const StyledLabel = styled.div`
    position: relative;

    label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: ${({ $hasPrefix }) => ($hasPrefix ? '57px' : '16px')};
        font-family: IBM Plex Sans;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        z-index: 3;
        color: ${COLORS.$gray40Black};

        pointer-events: none;
        touch-action: none;
        transition: all 0.2s ease-in-out;
        white-space: nowrap;
    }

    &:has(input:not(:placeholder-shown)) > label,
    &:has(input:focus) > label {
        top: 7px;
        left: ${({ $hasPrefix }) => ($hasPrefix ? '57px' : '16px')};
        transform: translateY(0);
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
    }

    &:has(.ant-input-prefix) > label,
    &:has(.ant-input-password) > label {
        left: 40px;
    }

    && {
        ${({ $noPrefix }) =>
            $noPrefix &&
            css`
                .ant-input-suffix {
                    display: none; /* specific Input.Password case */
                }
                > label {
                    left: 16px;
                }
            `};
    }
`;
