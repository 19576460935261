import React from 'react';
import { COLORS } from '@constants';
import { Icon } from '@ui';
import { StyledCrossIcon } from './styled';

export const SearchSuffix = ({ inputRef, onSetSearchValue }) => {
    return inputRef?.current?.input?.value ? (
        <Icon
            onClick={() => {
                inputRef.current.value = '';
                onSetSearchValue('');
            }}
            color={COLORS.$gray20Black}
            component={() => <StyledCrossIcon data-e2e='common_cross_icon' width={24} height={24} />}
        />
    ) : (
        <Icon data-e2e='common_search_icon' color={COLORS.$gray60Black} component={() => <Icon.SearchIcon />} />
    );
};
