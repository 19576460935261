import React from 'react';
import { validateDebounce, VALIDATION_RULES } from '@constants';
import { Input } from '@ui';
import { Col, Form, Row } from 'antd';
import { FIELD_NAMES } from '../../constants';

const KlicklyInfoFields = () => {
    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form.Item name={FIELD_NAMES.COMPANY_NAME} rules={VALIDATION_RULES.REQUIRED}>
                    <Input data-e2e='company-info_name_input' label='Company Name' />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item
                    name={FIELD_NAMES.COMPANY_PHONE}
                    rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.PHONE]}
                    validateDebounce={validateDebounce}
                >
                    <Input.Phone data-e2e='company-info_phone_input' label='Company Phone' />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name={FIELD_NAMES.COMPANY_EMAIL}
                    rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.EMAIL]}
                    validateDebounce={validateDebounce}
                >
                    <Input data-e2e='company-info_email_input' label='Company Email' />
                </Form.Item>
            </Col>

            <Col span={18}>
                <Form.Item name={FIELD_NAMES.ADDRESS} rules={VALIDATION_RULES.REQUIRED}>
                    <Input data-e2e='company-info_address_input' label='Address' />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name={FIELD_NAMES.APT_NUMBER}>
                    <Input data-e2e='company-info_apt-number_input' label='Apt. number' />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name={FIELD_NAMES.CITY} rules={VALIDATION_RULES.REQUIRED}>
                    <Input data-e2e='company-info_city_input' label='City' />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    validateTrigger={['onBlur', 'onChange']}
                    name={FIELD_NAMES.COMPANY_STATE}
                    rules={VALIDATION_RULES.REQUIRED}
                >
                    <Input data-e2e='company-info_state_input' label='State' />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    name={FIELD_NAMES.ZIP_CODE}
                    rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.ZIP_CODE]}
                    validateDebounce={validateDebounce}
                >
                    <Input data-e2e='company-info_zip_input' label='ZIP code' />
                </Form.Item>
            </Col>
        </Row>
    );
};

export default KlicklyInfoFields;
