import { COLORS } from '@constants';
import { Tag } from 'antd';
import styled, { css } from 'styled-components';

export const StyledWrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

export const StyledTag = styled(Tag)`
    &&& {
        border: 1px solid ${COLORS.$gray20Black};
        border-radius: 4px;
        background-color: transparent;
        margin-top: 9px;
        padding: 2px 8px;

        display: inline-flex;

        &:not(:last-child) {
            margin-right: 9px;
        }

        & .ant-tag-close-icon {
            margin-inline: 8px 0;
            font-size: 12px;
            color: ${COLORS.$gray60Black};
        }

        ${({ $style }) =>
            $style === 'rejected'
                ? css`
                      border-color: ${COLORS.$tartRed};
                      color: ${COLORS.$tartRed};

                      & .ant-tag-close-icon {
                          color: ${COLORS.$tartRed};
                      }
                  `
                : ''}
    }
`;
