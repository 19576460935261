import React from 'react';
import { COLORS, LEFT_INDENTS } from '@constants';
import { Typography } from '@ui';
import { formatNumberToUSLocale } from '../../../helpers';
import { StyledAmount, StyledWrapper } from './styled';

const HouseholdSavings = ({ data }) => {
    if (!data) return null;

    return (
        <StyledWrapper>
            <StyledAmount>
                <Typography.Text type='em0' align='center' color={COLORS.$pink}>
                    ${formatNumberToUSLocale(data.seedfilePercent)}
                </Typography.Text>
            </StyledAmount>
            <div>
                <Typography.Text type='badgeSmall' color={COLORS.$forestGreen}>
                    {data.diffPercent}%
                </Typography.Text>
                <Typography.Text type='body2'>
                    Baseline:
                    <Typography.Text as='span' type='badgeSmall' style={LEFT_INDENTS.XXS}>
                        ${formatNumberToUSLocale(data.baselinePercent)}
                    </Typography.Text>
                </Typography.Text>
                <Typography.Text type='body2'>
                    Records:
                    <Typography.Text as='span' type='badgeSmall' style={LEFT_INDENTS.XXS}>
                        {formatNumberToUSLocale(data.seedfileCount)}
                    </Typography.Text>
                </Typography.Text>
            </div>
        </StyledWrapper>
    );
};

export default HouseholdSavings;
