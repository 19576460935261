import React, { forwardRef } from 'react';
import Label from '../Label';
import { StyledInput } from './styled';

const BaseInput = forwardRef(({ readOnly, label, prefix, placeholder, ...props }, ref) => (
    <Label prefix={prefix} label={label}>
        <StyledInput
            $readOnly={readOnly}
            $hasPlaceholder={placeholder}
            $hasLabel={!!label}
            ref={ref}
            placeholder={label || placeholder}
            prefix={prefix}
            {...props}
        />
    </Label>
));

BaseInput.displayName = 'BaseInput';

export default BaseInput;
