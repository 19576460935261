import React from 'react';
import { BOTTOM_INDENTS, COLORS, FIELD_NAME } from '@constants';
import { IMAGE_FORMAT } from '@enums';
import { Radio, Typography, WhiteCover } from '@ui';
import { Flex, Form } from 'antd';
import imagePreviewExample from '../../assets/png/imagePreviewExample.png';
import { StyledImageExample } from './styled';

export const ProductImagesFormat = () => {
    const form = Form.useFormInstance();
    const productImagesFormat = Form.useWatch(FIELD_NAME.CREATIVE.PRODUCT_IMAGES_FORMAT, form);
    return (
        <WhiteCover
            data-e2e='creative-settings_product-image-format_block'
            title='Product Images'
            style={BOTTOM_INDENTS.M}
        >
            <Flex gap={24} align='center'>
                <Flex vertical style={{ flexBasis: '50%' }} gap={16}>
                    <Form.Item name={FIELD_NAME.CREATIVE.PRODUCT_IMAGES_FORMAT}>
                        <Radio.Group style={{ display: 'flex', flexDirection: 'column', padding: '16px', gap: '32px' }}>
                            <Radio.WithLabel value={IMAGE_FORMAT.CROPPED} style={{ height: 'auto' }}>
                                Cropped
                            </Radio.WithLabel>
                            <Radio.WithLabel value={IMAGE_FORMAT.ZOOMED} style={{ height: 'auto' }}>
                                Zoomed
                            </Radio.WithLabel>
                        </Radio.Group>
                    </Form.Item>
                </Flex>
                <Flex justify='center' align='center' style={{ flexBasis: '50%' }}>
                    <StyledImageExample $format={productImagesFormat}>
                        <Typography.Text color={COLORS.$pink} type='descriptor2'>
                            Displayed Area
                        </Typography.Text>
                        <img
                            data-e2e='creative-settings_product-image-format_preview'
                            src={imagePreviewExample}
                            alt=''
                        />
                    </StyledImageExample>
                </Flex>
            </Flex>
        </WhiteCover>
    );
};
