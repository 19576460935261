import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledNavLink = styled(NavLink)`
    display: ${({ $display = 'block' }) => $display};
    font-weight: ${({ $fontWeight = '500' }) => $fontWeight};
    font-size: ${({ $fontSize = '14' }) => $fontSize};
    color: ${({ $color }) => $color};
    text-align: ${({ $align = 'right' }) => $align};

    &:hover {
        text-decoration: underline;
        color: ${({ $color }) => $color};
    }
`;
