import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, COLORS, ROUTE_PATHS, TOP_INDENTS, VALIDATION_RULES } from '@constants';
import { CUSTOM_PLATFORMS, PLATFORM_ID, PLATFORM_NAME } from '@enums';
import { useStore } from '@hooks';
import { Button, Icon, Input, Select } from '@ui';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import CustomForm from './components/Forms/Custom';
import ShopifyForm from './components/Forms/Shopify';
import { PLATFORMS_AUTH } from './constants';

const { SHOPIFY, BIGCOMMERCE } = PLATFORM_ID;

const CreateAccount = observer(() => {
    const navigate = useNavigate();
    const store = useStore();
    const [form] = Form.useForm();
    const { getEcommerceRedirect, createCustomAccount, hasAccount, isFormPending } = store.authStore;
    const [activeForm, setActiveForm] = useState(SHOPIFY);
    const isKlickly = CUSTOM_PLATFORMS.includes(activeForm);
    const isShopify = activeForm === SHOPIFY;
    const isBigcommerce = activeForm === BIGCOMMERCE;
    const addonAfter = '.myshopify.com';

    const onFinishForm = async (values) => {
        if (isShopify) {
            await getEcommerceRedirect(PLATFORM_NAME.SHOPIFY, {
                shop: values.shop + addonAfter,
                token_type: 'offline',
                companyName: values.companyName
            });
        }

        if (isKlickly) {
            const isCreated = await createCustomAccount(values);

            if (isCreated) {
                navigate(ROUTE_PATHS.HOME);
            }
        }

        if (isBigcommerce) {
            await getEcommerceRedirect(PLATFORM_NAME.BIGCOMMERCE, { companyName: values.companyName });
        }
    };

    return (
        <Form data-e2e='signup_setup-store_form' form={form} onFinish={onFinishForm}>
            <Form.Item style={BOTTOM_INDENTS.S} name='companyName' rules={VALIDATION_RULES.REQUIRED}>
                <Input data-e2e='signup_company-name_input' type='text' label='Company Name' />
            </Form.Item>
            <Select
                onChange={(value, option) => {
                    setActiveForm(option.id);
                }}
                defaultValue={SHOPIFY}
                label='Which platform do you currently use?'
                options={PLATFORMS_AUTH}
                data-e2e='signup_select-platform_dropdown'
            />
            {isKlickly && <CustomForm createCustomAccount={createCustomAccount} />}
            {isShopify && <ShopifyForm getEcommerceRedirect={getEcommerceRedirect} />}
            <Form.Item style={TOP_INDENTS.L}>
                <Button
                    loading={isFormPending}
                    htmlType='submit'
                    type='primary'
                    block
                    iconRight={<Icon size='24px' color={COLORS.$white} component={() => <Icon.ArrowForwardIcon />} />}
                    data-e2e='signup_submit_btn'
                >
                    {hasAccount ? 'Add Store' : 'Continue'}
                </Button>
            </Form.Item>
        </Form>
    );
});

export default CreateAccount;
