import React, { lazy, Suspense } from 'react';
import GetStartedButton from '../GetStartedButton';
import { StyledGetStarted } from './styled';

const Phone = lazy(() => import('../LazyImages/components/Phone'));

const GetStarted = () => {
    return (
        <StyledGetStarted className='bg-gradient bg-gradient-orange-invert'>
            <div data-e2e='landing_get-started_section' className='container-content'>
                <div className='get-started__wrapper'>
                    <div className='get-started__wrapper__content'>
                        <h3 data-e2e='landing_get-started_title'>Ready to Get Started?</h3>
                        <p data-e2e='landing_get-started_description'>
                            Klickly’s proprietary, full-funnel consumer data engine unlocks end-to-end
                            distributed-commerce for brands. Contact us to learn more about how Klickly can help you
                            compete
                        </p>
                        <GetStartedButton />
                    </div>
                    <div className='get-started__wrapper__image'>
                        <Suspense fallback={<React.Fragment />}>
                            <Phone />
                        </Suspense>
                    </div>
                </div>
            </div>
        </StyledGetStarted>
    );
};

export default GetStarted;
