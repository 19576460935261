import { Typography } from '@ui';
import { Col } from 'antd';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
    position: relative;
`;

export const StyledChartWrapper = styled.div`
    position: relative;
    height: ${({ $height }) => `${$height}px` || 'auto'};
    display: flex;
    justify-content: center;
    align-content: center;
`;

export const StyledCol = styled(Col)`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 24px;
`;

export const StyledText = styled(Typography.Text)`
    transform: rotate(-90deg);
    padding: 28px 0;
`;
