import React, { Fragment } from 'react';
import { CancelSave } from '@components';
import { ACCOUNT_STATUS } from '@enums';
import { StyledButtonContainer, StyledTypography } from '../../styled';

const Pause = ({ customClose, changeStatus }) => {
    return (
        <Fragment>
            <StyledTypography data-e2e='account-management_pause-account-modal_description'>
                You&apos;re about to pause your account. This will pause all live campaigns and will restrict your
                ability to take advantage of Klickly. As an alternative option, contact your account manager for a free
                account optimization session. Remember: you can adjust your commission up or down at any time!
            </StyledTypography>
            <StyledButtonContainer>
                <CancelSave
                    saveText='Pause'
                    onCancel={() => {
                        customClose();
                    }}
                    onSave={async () => {
                        await changeStatus(ACCOUNT_STATUS.PAUSED);
                        customClose();
                    }}
                />
            </StyledButtonContainer>
        </Fragment>
    );
};

export default Pause;
