import React, { Fragment } from 'react';
import { BOTTOM_INDENTS, COLORS, LEFT_INDENTS, ROUTE_PATHS } from '@constants';
import { Icon, RedirectLink, Tooltip, Typography } from '@ui';
import { Flex } from 'antd';
import { InfoIcon } from '../InfoIcon';

const CampaignCommission = () => {
    return (
        <Flex justify='space-between' align='center'>
            <Flex style={{ paddingBlock: 12 }} gap={12} align='center'>
                <Typography.Text data-e2e='account-management_cc_block' type='badgeSmall'>
                    Campaign commission
                </Typography.Text>
                <Tooltip
                    title={
                        <Fragment>
                            <Typography.Title
                                data-e2e='account-management_cc-tooltip_title'
                                level={3}
                                color={COLORS.$white}
                                style={BOTTOM_INDENTS.S}
                            >
                                What is Campaign commission?
                            </Typography.Title>
                            <Typography.Text
                                data-e2e='account-management_cc-tooltip_description'
                                type='badgeSmall'
                                color={COLORS.$white}
                            >
                                {`Your Campaign Commission is how much you'll pay Klickly when Klickly drives
                                            sales of your products in this campaign. (Note: increasing your commission
                                            gives you a higher Priority) `}
                            </Typography.Text>
                        </Fragment>
                    }
                    trigger='hover'
                >
                    <Icon
                        data-e2e='account-management_cc-tooltip_icon'
                        style={{ ...LEFT_INDENTS.XXXS, paddingBlock: 4 }}
                        color={COLORS.$gray40Black}
                        stroke='white'
                        size='24px'
                        component={() => <InfoIcon />}
                    />
                </Tooltip>
            </Flex>
            <Flex style={{ width: 160 }} justify='end'>
                <RedirectLink
                    data-e2e='account-management_cc-varies-by-campaign_link'
                    text='Varies by campaign'
                    to={ROUTE_PATHS.CAMPAIGNS}
                />
            </Flex>
        </Flex>
    );
};

export default CampaignCommission;
