import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { BOTTOM_INDENTS, FIELD_NAME } from '@constants';
import { useStore } from '@hooks';
import { WhiteCover } from '@ui';
import { Form, Layout } from 'antd';
import { observer } from 'mobx-react';
import Boost from '../../../../components/Boost';
import CampaignCommission from '../../../../components/CampaignCommission';
import CampaignTypeCardSimple from '../../../../components/CampaignTypeCardSimple';
import ColdCustomerCommission from '../../../../components/ColdCustomerCommission';
import NewCustomerCommission from '../../../../components/NewCustomerCommission';
import ProductsSelection from '../../../../components/ProductsSelection';
import RestOfSiteCommission from '../../../../components/RestOfSiteCommission';
import { CAMPAIGN_TYPES } from '../../../../constants';
import { useCalculatePriority } from '../../../../hooks/useCalculatePriority';
import { useCampaignWizard } from '../../../../hooks/useCampaignWizard';
import { getInitialValues } from '../../utils';
import CampaignName from '../CampaignName';
import CampaignPriority from '../CampaignPriority';
import Header from '../Header';

const { Content } = Layout;

const Step5Content = observer(() => {
    const params = useParams();

    const { isEdit } = useCampaignWizard();

    const store = useStore();
    const { campaign, setPriorityPending } = store.campaignWizardStore;
    const { commissionsApplied } = store.accountsStore;
    const { commission } = store.accountsStore.currentAccount;

    const form = Form.useFormInstance();

    const calculatePriority = useCalculatePriority();

    useEffect(() => {
        const initialValues = getInitialValues(campaign, commission);
        form.setFieldsValue(initialValues);
        calculatePriority(initialValues);
    }, []);

    const instantOnInputChange = () => {
        setPriorityPending(true);
    };

    const debouncedOnInputChange = () => {
        const { COMMISSION, BOOST } = FIELD_NAME.CAMPAIGN;
        const { REST_OF_SITE_COMMISSION, NEW_CUSTOMER_COMMISSION, COLD_CUSTOMER_COMMISSION } = FIELD_NAME.ACCOUNT;

        const formValues = form.getFieldsValue([
            COMMISSION,
            BOOST,
            REST_OF_SITE_COMMISSION,
            NEW_CUSTOMER_COMMISSION,
            COLD_CUSTOMER_COMMISSION
        ]);
        calculatePriority(formValues);
    };

    const campaignTypesToShow = useMemo(() => {
        if (campaign?.id !== Number(params.id)) {
            return [];
        }

        const currentCampaignType = CAMPAIGN_TYPES.find((item) => item.value === campaign?.catalogType);
        if (currentCampaignType) {
            currentCampaignType.checked = true;
            currentCampaignType.disabled = true;
        }

        return [currentCampaignType];
    }, [campaign?.id]);

    return (
        <Content tagName='div' style={{ maxWidth: 867, minHeight: '50vh' }}>
            {!isEdit && <Header />}
            <CampaignName />
            <WhiteCover
                data-e2e='campaignwizard_campaign-type_block'
                title='Campaign type'
                style={{ position: 'relative', ...BOTTOM_INDENTS.M }}
            >
                <CampaignTypeCardSimple style={BOTTOM_INDENTS.M} size='small' {...campaignTypesToShow[0]} />
                <ProductsSelection />
            </WhiteCover>
            <CampaignPriority />
            <CampaignCommission
                instantOnInputChange={instantOnInputChange}
                debouncedOnInputChange={debouncedOnInputChange}
            />
            {!commissionsApplied ? (
                <React.Fragment>
                    <RestOfSiteCommission
                        instantOnInputChange={instantOnInputChange}
                        debouncedOnInputChange={debouncedOnInputChange}
                    />
                    <NewCustomerCommission
                        instantOnInputChange={instantOnInputChange}
                        debouncedOnInputChange={debouncedOnInputChange}
                    />
                    <ColdCustomerCommission
                        instantOnInputChange={instantOnInputChange}
                        debouncedOnInputChange={debouncedOnInputChange}
                    />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Form.Item name={FIELD_NAME.ACCOUNT.REST_OF_SITE_COMMISSION} noStyle />
                    <Form.Item name={FIELD_NAME.ACCOUNT.NEW_CUSTOMER_COMMISSION} noStyle />
                    <Form.Item name={FIELD_NAME.ACCOUNT.COLD_CUSTOMER_COMMISSION} noStyle />
                </React.Fragment>
            )}

            <Boost instantOnInputChange={instantOnInputChange} debouncedOnInputChange={debouncedOnInputChange} />
        </Content>
    );
});

export default Step5Content;
