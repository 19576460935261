import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from '@constants';
import { PLATFORM_ID, PLATFORM_NAME, SCRIPT_TAG_STATUS_TITLE } from '@enums';
import { useStore } from '@hooks';
import { Space } from 'antd';
import { observer } from 'mobx-react';
import Notification from './components/Notification';
import { ACCOUNT_BLOCKED_REASONS, createNotificationsMap, NOTIFICATION_TYPES } from './constants';
import { StyledNotificationsWrapper } from './styled';

const {
    SCRIPT_TAG_CONNECT,
    ACCOUNT_ON_PAUSE,
    ACCOUNT_UNINSTALLED,
    PAYMENT_CONNECT,
    NOTIFICATION_UPDATE,
    MANUAL_STORE_ONBOARDING
} = NOTIFICATION_TYPES;

const { CURRENCY, BAD_PLAN, INTEGRATION_INACTIVE, MISSING_US_SHIPPING_METHOD, SETUP_REQUIRED } =
    ACCOUNT_BLOCKED_REASONS;

const settings = {
    [PLATFORM_ID.SHOPIFY]: {
        currency: '/settings/general',
        badPlan: '/settings/plan',
        missedShipping: '/settings/shipping',
        integrationInactive: '/settings/app',
        setupRequired: '/settings'
    },
    [PLATFORM_ID.BIGCOMMERCE]: {
        currency: '/manage/settings/currencies',
        badPlan: 'https://manage.bigcommerce.com/account/stores/upgrade',
        missedShipping: '/manage/settings/shipping',
        integrationInactive: '/manage/marketplace/apps/my-apps'
    }
};

const NotificationsList = ({ notifications }) => {
    return (
        <Space data-e2e='notification_section' direction='vertical' size='middle' style={{ display: 'flex' }}>
            {notifications.map((notification) => (
                <Notification notification={notification} key={notification.title} />
            ))}
        </Space>
    );
};

const TopNotificationsSection = observer(() => {
    const store = useStore();
    const {
        isPaused,
        isUninstalled,
        isBlocked,
        isShopify,
        isManual,
        scriptTagIntegrationStatus,
        isPaymentOnHold,
        companyName,
        currentAccount,
        shopifySettingsLink,
        bigcommerceSettingsLink,
        manualStoreSetupFinished
    } = store.accountsStore;
    const { getEcommerceRedirect } = store.authStore;
    const { accountHasAllScopes } = store.integrationsStore;
    const notificationsToShow = [];

    const location = useLocation();
    const isVisible =
        !location.pathname.includes(ROUTE_PATHS.HELP_CENTER) &&
        !location.pathname.includes(ROUTE_PATHS.CAMPAIGNS_CREATE) &&
        !location.pathname.includes(ROUTE_PATHS.ROOT_CAMPAIGNS_EDIT);

    const domain = currentAccount?.domains[0]?.shopDomain || '';

    const updateScopes = () => {
        getEcommerceRedirect(PLATFORM_NAME.SHOPIFY, {
            companyName,
            shop: domain,
            token_type: 'online'
        });
    };

    const platformSettings = settings[currentAccount.platformId];
    const redirect = (path) => {
        const baseLink = isShopify ? shopifySettingsLink : bigcommerceSettingsLink;
        const url = path.includes('https') ? '' : baseLink;
        window.open(url + path, '_blank');
    };

    const notificationsMap = createNotificationsMap({
        [NOTIFICATION_UPDATE]: updateScopes,
        [CURRENCY]: () => redirect(platformSettings.currency),
        [BAD_PLAN]: () => redirect(platformSettings.badPlan),
        [MISSING_US_SHIPPING_METHOD]: () => redirect(platformSettings.missedShipping),
        [INTEGRATION_INACTIVE]: () => redirect(platformSettings.integrationInactive),
        [SETUP_REQUIRED]: () => redirect(platformSettings.setupRequired)
    });

    if (!manualStoreSetupFinished && isManual && !location.pathname.includes(ROUTE_PATHS.INTEGRATIONS_SETTINGS)) {
        notificationsToShow.push(notificationsMap[MANUAL_STORE_ONBOARDING]);
    }

    if (isPaused) {
        notificationsToShow.push(notificationsMap[ACCOUNT_ON_PAUSE]);
    } else if (isUninstalled && !isManual) {
        notificationsToShow.push(notificationsMap[ACCOUNT_UNINSTALLED]);
    }

    if (scriptTagIntegrationStatus === SCRIPT_TAG_STATUS_TITLE.NOT_CONNECTED && !isManual) {
        notificationsToShow.push(notificationsMap[SCRIPT_TAG_CONNECT]);
    }

    if (isPaymentOnHold) {
        notificationsToShow.push(notificationsMap[PAYMENT_CONNECT]);
    }

    if (!accountHasAllScopes && !isUninstalled && isShopify) {
        notificationsToShow.push(notificationsMap[NOTIFICATION_UPDATE]);
    }

    if (isBlocked) {
        const firstReason = notificationsMap[currentAccount.statusBlockReasons[0]];

        notificationsToShow.length = 0;
        notificationsToShow.push(firstReason);
    }

    if (!notificationsToShow.length) {
        return null;
    }

    return isVisible ? (
        <StyledNotificationsWrapper>
            <NotificationsList notifications={notificationsToShow} />
        </StyledNotificationsWrapper>
    ) : null;
});

export default TopNotificationsSection;
