import React, { Fragment } from 'react';
import { Divider } from '@ui';
import AddToCarts from './components/AddToCarts';
import Clicks from './components/Clicks';
import MeasurementId from './components/MeasurementId';
import Purchases from './components/Purchases';
import Views from './components/Views';

const Settings = () => {
    return (
        <Fragment>
            <MeasurementId />
            <Views />
            <Divider margin='24px 0' />
            <Clicks />
            <Divider margin='24px 0' />
            <AddToCarts />
            <Divider margin='24px 0' />
            <Purchases />
        </Fragment>
    );
};

export default Settings;
