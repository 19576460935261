import React, { useState } from 'react';
import { CancelSave } from '@components';
import { BOTTOM_INDENTS, validateDebounce, VALIDATION_RULES } from '@constants';
import { useStore } from '@hooks';
import { Button, Input, Loader, Typography } from '@ui';
import { showFormNotification } from '@utils';
import { Col, Flex, Form, Row } from 'antd';
import { observer } from 'mobx-react';
import { FIELD_NAMES } from '../../constants';
import { useChangeEmailModal, useChangePasswordModal } from '../../hooks';
import FieldView from './components/FieldView';
import { StyledWrapper } from './styled';

const ProfileForm = observer(() => {
    const store = useStore();
    const { EMAIL, PHONE, LAST_NAME, FIRST_NAME } = FIELD_NAMES;
    const [form] = Form.useForm();
    const { updateProfile } = store.profileStore;
    const { me, whoAmI } = store.authStore;

    const { showChangePasswordModal } = useChangePasswordModal();
    const { showChangeEmailModal } = useChangeEmailModal({ profileForm: form });

    const [formSubmitting, setFormSubmitting] = useState(false);

    const handleFinish = async (values) => {
        setFormSubmitting(true);
        await updateProfile({ firstName: values[FIRST_NAME], lastName: values[LAST_NAME], phone: values[PHONE] });
        await whoAmI();
        setFormSubmitting(false);
        showFormNotification(store.accountsStore.error);
    };

    const resetForm = () => {
        const { firstName, lastName, phone, email } = store.authStore.me;

        form.setFieldsValue({
            [FIRST_NAME]: firstName,
            [LAST_NAME]: lastName,
            [PHONE]: phone,
            [EMAIL]: email
        });
    };

    if (!me) {
        return <Loader />;
    }

    return (
        <StyledWrapper data-e2e='profile_section'>
            <Typography.Title data-e2e='profile_page-title_text' level={2} style={BOTTOM_INDENTS.XL_PLUS}>
                My profile
            </Typography.Title>
            <Form
                data-e2e='profile_inputs_form'
                form={form}
                initialValues={{
                    [FIRST_NAME]: me?.firstName || '',
                    [LAST_NAME]: me?.lastName || '',
                    [PHONE]: me?.phone || '',
                    [EMAIL]: me?.email
                }}
                scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
                onFinish={handleFinish}
            >
                <Flex vertical gap={16}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                data-e2e='profile_first-name_field'
                                name={FIRST_NAME}
                                rules={VALIDATION_RULES.NAME}
                            >
                                <Input data-e2e='profile_first-name_input' label='First Name' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                data-e2e='profile_last-name_field'
                                name={LAST_NAME}
                                rules={VALIDATION_RULES.NAME}
                            >
                                <Input data-e2e='profile_last-name_input' label='Last Name' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        data-e2e='profile_phone_field'
                        name={PHONE}
                        rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.PHONE]}
                        validateDebounce={validateDebounce}
                    >
                        <Input.Phone data-e2e='profile_phone_input' label='Phone number' />
                    </Form.Item>
                    <div data-e2e='profile_email_section'>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => (
                                <span data-e2e='profile_email_field'>
                                    <FieldView value={getFieldValue(EMAIL)} label='Email' />
                                </span>
                            )}
                        </Form.Item>
                        <Row justify='end'>
                            <Button
                                data-e2e='profile_change-email_btn'
                                type='link'
                                size='small'
                                onClick={showChangeEmailModal}
                            >
                                Change email?
                            </Button>
                        </Row>
                    </div>

                    <div data-e2e='profile_login-credentials_section'>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => (
                                <span data-e2e='profile_login-credentials_field'>
                                    <FieldView
                                        value={getFieldValue(EMAIL)}
                                        label='Login (Email)'
                                        suffix='Password: ********'
                                    />
                                </span>
                            )}
                        </Form.Item>
                        <Row justify='end' style={BOTTOM_INDENTS.XL_PLUS}>
                            <Button
                                data-e2e='profile_change-password_btn'
                                type='link'
                                size='small'
                                onClick={showChangePasswordModal}
                            >
                                Change password?
                            </Button>
                        </Row>
                    </div>
                </Flex>
                <CancelSave onCancel={resetForm} loading={formSubmitting} />
                {/* <ErrorFallback.Message message={errorMessage} /> */}
            </Form>
        </StyledWrapper>
    );
});

export default ProfileForm;
