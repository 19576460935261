import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    background: ${COLORS.$white};
    border: 1px solid ${COLORS.$gray15Black};
    border-radius: 8px;
`;

export const StyledInputWrapper = styled.div`
    padding: 24px;
`;
