import React from 'react';
import { NavLink } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import { Divider } from '@ui';
import { StyledCloseButton, StyledModal, StyledTypography, StyledTypographyHeader } from './styled'; // Importing StyledCloseButton

const SupportModal = ({ setIsModalOpen, isModalOpen }) => {
    return (
        <StyledModal
            width='630px'
            height='229px'
            open={isModalOpen}
            onCancel={() => {
                setIsModalOpen(false);
            }}
            footer={null}
            maskClosable={false}
            destroyOnClose
            closeIcon={
                <StyledCloseButton className='ant-modal-close-x'>
                    <CloseOutlined /> {/* Render the default Ant Design close icon */}
                </StyledCloseButton>
            }
        >
            <StyledTypographyHeader data-e2e='home_support-modal_title'>Help Center</StyledTypographyHeader>
            <Divider margin='24px 0' />
            <StyledTypography>Welcome to Klickly’s Help Center.</StyledTypography>
            <StyledTypography data-e2e='home_support-modal-faq_section'>
                Find answers to common questions in our {/* eslint-disable-line */}
                <NavLink data-e2e='home_support-modal-faq_link' to='/help-center'>
                    Help Center FAQ
                </NavLink>
            </StyledTypography>
            <StyledTypography data-e2e='home_support-modal-mailto_section'>
                Can’t find the answer you’re looking for? Email {/* eslint-disable-line */}
                <a data-e2e='home_support-modal-mailto_link' href='mailto:help@klickly.com'>
                    help@klickly.com
                </a>
            </StyledTypography>
        </StyledModal>
    );
};

export default SupportModal;
