import React from 'react';
import { PaymentForm } from '@components';
import { BOTTOM_INDENTS, COLORS, ROUTE_PATHS } from '@constants';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Typography } from '@ui';
import { Flex } from 'antd';
import config from '../../../../../../../../../../config';
import { APPEARANCE } from '../../../../../../../../../CampaignWIzard/pages/LaunchCheck/components/PaymentInfo/components/AddPaymentMethodContent/constants';
import { StyledCard } from './styled';

const stripePromise = loadStripe(config.stripePublishKey);

const AddPaymentMethodContent = ({ clientSecret, onCancel }) => {
    const stripeOptions = { clientSecret, appearance: APPEARANCE };
    const returnUrl = `${window.location.origin}${ROUTE_PATHS.ACCOUNT_MANAGEMENT}`;

    return (
        <Flex vertical gap={24}>
            <Typography.Text color={COLORS.$gray60Black} type='body2'>
                Klickly fees will be charged to this payment method wherever possible. Note: your campaigns won’t go
                live until your payment method is verified.
            </Typography.Text>
            <StyledCard style={{ ...BOTTOM_INDENTS.M }}>
                <React.Fragment>
                    <Typography.Title
                        data-e2e='account-management_new-payment-method_form'
                        level={3}
                        style={BOTTOM_INDENTS.M}
                    >
                        New payment method
                    </Typography.Title>
                    <Elements stripe={stripePromise} options={stripeOptions}>
                        <PaymentForm onCancel={onCancel} returnUrl={returnUrl} />
                    </Elements>
                </React.Fragment>
            </StyledCard>
        </Flex>
    );
};
export default AddPaymentMethodContent;
