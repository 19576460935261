import React from 'react';
import { COLORS } from '@constants';
import { Typography, WhiteCover } from '@ui';

const MessagesToCustomers = ({ style }) => {
    return (
        <WhiteCover data-e2e='notifications_messages-to-customers_section' title='Messages to customers' style={style}>
            <Typography.Text
                data-e2e='notifications_messages-to-customers_description'
                type='body2'
                color={COLORS.$gray60Black}
            >
                Emails and messages that customers will receive from you. We recommend you email your customers to let
                them know about major changes to their order status. You can contact your customers directly at any
                time.
            </Typography.Text>
        </WhiteCover>
    );
};

export default MessagesToCustomers;
