import React from 'react';
import { ROUTE_PATHS } from '@constants';
import { RedirectLink, Typography } from '@ui';
import { Col, Row } from 'antd';

const Boost = () => {
    return (
        <Row align='middle' gutter={[8, 8]} style={{ paddingBlock: 16 }}>
            <Col span={12}>
                <Typography.Text data-e2e='account-management_boost_block' type='badgeSmall'>
                    Boost
                </Typography.Text>
            </Col>
            <Col span={12} align='end'>
                <RedirectLink
                    data-e2e='account-management_boost-varies-by-campaign_link'
                    text='Varies by campaign'
                    to={ROUTE_PATHS.CAMPAIGNS}
                />
            </Col>
        </Row>
    );
};

export default Boost;
