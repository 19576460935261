import React, { useRef, useState } from 'react';
import { COLORS } from '@constants';
import { useStore } from '@hooks';
import { Button, Icon, Typography } from '@ui';
import { Flex, Popover } from 'antd';
import { observer } from 'mobx-react';
import { StyledButton, StyledWrapper } from './styled';

const ProductActions = observer(({ product }) => {
    const [openEdit, setOpenEdit] = useState(false);
    const onOpenEdit = (newOpen) => {
        setOpenEdit(newOpen);
    };
    const store = useStore();
    const { isShopify, isBigCommerce, currentAccount } = store.accountsStore;
    const trimmedDomain = store.accountsStore.trimmedDomain;
    const containerRef = useRef(null);

    const platformName = isShopify ? 'Shopify' : isBigCommerce ? 'Big Commerce' : 'Platform';

    const bcLink = `https://store-${currentAccount?.externalId}.mybigcommerce.com/manage/products/edit/${product.ecommerceId}`;
    const shopifyLink = `https://admin.shopify.com/store/${trimmedDomain}/products/${product.ecommerceId}`;
    const productLink = isShopify ? shopifyLink : isBigCommerce ? bcLink : '';

    return (
        (isShopify || isBigCommerce) && (
            <StyledWrapper data-e2e={`products_actions-${product.id}_section`} ref={containerRef}>
                <Popover
                    getPopupContainer={() => containerRef.current}
                    placement='left'
                    trigger='click'
                    open={openEdit}
                    onOpenChange={onOpenEdit}
                    content={
                        <Flex gap={8} align='flex-start' justify='flex-start' data-e2e='products_edit-product_popup'>
                            <Flex vertical gap={24}>
                                <Typography.Text color={COLORS.$white} type='badgeLarge'>
                                    Edit
                                </Typography.Text>
                                <Typography.Text color={COLORS.$white} type='body2'>
                                    If you would like to edit this item, please go to {platformName} to edit.
                                </Typography.Text>
                                <div>
                                    <Button
                                        data-e2e='products_go-to_btn'
                                        type='primary'
                                        onClick={() => {
                                            window.open(productLink, '_blank');
                                        }}
                                        padding='16px 60px'
                                    >
                                        Go to {platformName}
                                    </Button>
                                </div>
                            </Flex>

                            <StyledButton
                                data-e2e='products_close-popup_btn'
                                type='noStyle'
                                onClick={() => setOpenEdit(false)}
                                padding='8px'
                                fontSize='16px'
                            >
                                <Icon color={COLORS.$white} component={() => <Icon.CloseIcon />} />
                            </StyledButton>
                        </Flex>
                    }
                >
                    <Button
                        data-e2e={`products_edit-${product.id}_btn`}
                        type='noStyle'
                        fontSize='24px'
                        onClick={() => setOpenEdit(true)}
                        padding='8px'
                        style={{ display: 'inline-flex' }}
                    >
                        <Icon.EditIcon />
                    </Button>
                </Popover>
            </StyledWrapper>
        )
    );
});

export default ProductActions;
