import React from 'react';
import { COLORS } from '@constants';
import { useStore } from '@hooks';
import { Divider, Table, Tooltip, Typography, WhiteCover } from '@ui';
import { Flex } from 'antd';
import { tooltips } from '../../../../constants';
import QuestionCircleIcon from '../../../QuestionCircleIcon';
import { columns } from '../../constants';

const TopStatesTable = () => {
    const store = useStore();
    const { tableData } = store.insightsDemoStore;

    return (
        <WhiteCover hasDivider={false}>
            <Flex gap={16} align='center'>
                <Tooltip title={tooltips.topStates} trigger='hover'>
                    <div>
                        <QuestionCircleIcon />
                    </div>
                </Tooltip>
                <Typography.Text color={COLORS.$gray40Black} type='badgeMedium'>
                    Top 5 States
                </Typography.Text>
            </Flex>
            <Divider margin='24px 0' />
            <Table
                pagination={false}
                dataSource={tableData}
                columns={columns}
                rowKey={(record) => record.id}
                loading={false}
            />
        </WhiteCover>
    );
};

export default TopStatesTable;
