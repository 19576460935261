import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTE_PATHS } from '@constants';
import { useStore } from '@hooks';
import { Loader } from '@ui';
import { observer } from 'mobx-react';
import { platformHandlers } from './utils/platformHandlers';
import { StyledWrapper } from './styled';

const Connector = observer(() => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const store = useStore();
    const {
        oauthPayload,
        embeddedLoadPayload,
        setEmbeddedLoadPayload,
        whoAmI,
        me,
        pending,
        checkAccountUser,
        setOauthPayload
    } = store.authStore;

    const redirect = () => {
        const params = embeddedLoadPayload || Object.fromEntries([...searchParams]);

        const isUserExist = () => checkAccountUser(params);

        if (!embeddedLoadPayload && !oauthPayload) {
            setEmbeddedLoadPayload(params);
        }

        if (params.signed_payload || oauthPayload) {
            return platformHandlers.bigcommerce({
                setOauthPayload,
                navigate,
                me,
                oauthPayload,
                isUserExist
            });
        }

        if (params.shop) {
            return platformHandlers.shopify({ searchParams, navigate, me, isUserExist });
        }

        return navigate(ROUTE_PATHS.HOME);
    };

    useEffect(() => {
        (async () => {
            await whoAmI();
        })();
    }, []);

    useEffect(() => {
        if (!pending) {
            redirect();
        }
    }, [me]);

    return (
        <StyledWrapper justify='center' align='center'>
            <Loader size='120px' />
        </StyledWrapper>
    );
});

export default Connector;
