import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorFallback, LoginButton } from '@components';
import { COLORS, ROUTE_PATHS, TOP_INDENTS, VALIDATION_RULES } from '@constants';
import { useStore } from '@hooks';
import { AuthLayout } from '@layouts';
import { Button, Icon, Input } from '@ui';
import { Form } from 'antd';
import { observer } from 'mobx-react';

const ForgotPassword = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { pending, sendForgotPasswordLink, errorMessage } = store.forgotPasswordStore;

    const handleSubmit = async (data) => {
        await sendForgotPasswordLink({ data, onSuccess: () => navigate(ROUTE_PATHS.FORGOT_PASSWORD_SENT) });
    };

    return (
        <AuthLayout
            title='Forgot password'
            description='Enter the email address associated with your account, and we’ll email you a link to
        reset your password.'
            rightCol={<LoginButton />}
        >
            <Form form={form} onFinish={handleSubmit}>
                <Form.Item name='email' rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.EMAIL]}>
                    <Input data-e2e='forgot-password_email_input' type='email' label='Email' />
                </Form.Item>
                <ErrorFallback.Message message={errorMessage} />
                <Form.Item style={TOP_INDENTS.L}>
                    <Button
                        data-e2e='forgot-password_send-reset-link_btn'
                        htmlType='submit'
                        type='primary'
                        size='large'
                        block
                        justify='between'
                        loading={pending}
                        iconRight={
                            <Icon size='24px' color={COLORS.$white} component={() => <Icon.ArrowForwardIcon />} />
                        }
                    >
                        Send Reset Link
                    </Button>
                </Form.Item>
            </Form>
        </AuthLayout>
    );
});

export default ForgotPassword;
