import React from 'react';
import { Checkbox, Col, Row } from 'antd';
import { Typography } from '../../../../../Typography';
import { StyledCheckMark, StyledWrapper } from './styled';

const DropdownItem = ({ text, checked, onChange }) => {
    return (
        <StyledWrapper>
            <Row gutter={16} justify='space-between' align='middle'>
                <Col>
                    <Typography.Text title={text} type='badgeMedium'>
                        {text}
                    </Typography.Text>
                </Col>
                <Col>
                    <StyledCheckMark data-e2e='common_check-mark' className='custom-check-mark' />
                </Col>
            </Row>
            <Checkbox checked={checked} onChange={onChange} />
        </StyledWrapper>
    );
};

export default DropdownItem;
