import React from 'react';
import { StyledLabel } from './styled';

const Label = ({ hasPrefix, children, label }) => (
    <StyledLabel $hasPrefix={hasPrefix}>
        {children}
        <label>{label}</label>
    </StyledLabel>
);

export default Label;
