import React from 'react';
import { Col, Row } from 'antd';
import { StyledImgWrapper, StyledText } from './styled';

const ProductName = ({ image, name, id }) => {
    return (
        <Row gutter={16} align='middle' wrap={false}>
            <Col>
                <StyledImgWrapper data-e2e={`campaigns_product-${id}-image_value`}>
                    <img src={image} alt={name} />
                </StyledImgWrapper>
            </Col>
            <Col>
                <StyledText data-e2e={`campaigns_product-${id}-title_value`}>{name}</StyledText>
            </Col>
        </Row>
    );
};

export default ProductName;
