import { COLORS } from '@constants';
import styled, { css } from 'styled-components';

export const StyledWrapper = styled.div`
    position: relative;

    .ant-input {
        padding: 24px 16px;

        &:hover {
            border-color: ${COLORS.$gray20Black};
        }

        &:focus {
            border: 1px solid ${COLORS.$gray60Black};
        }
    }

    .anticon {
        font-size: 24px;
    }

    ${({ $hasPrefix }) =>
        $hasPrefix &&
        css`
            .ant-input {
                padding-left: 56px;
            }
        `};

    ${({ $hasSuffix }) =>
        $hasSuffix &&
        css`
            .ant-input {
                padding-right: 40px;
            }
        `};
`;

export const StyledPrefix = styled.span`
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 2;
    color: ${COLORS.$gray20Black};
`;

export const StyledSuffix = styled.span`
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;
    color: ${COLORS.$gray20Black};
`;
