import React from 'react';

export const PAGE = {
    TITLE: 'What products would you like to advertise?',
    DESCRIPTION: (
        <React.Fragment>
            Pick the type of products you&apos;d like to advertise. We recommend choosing your &apos;Entire
            catalog&apos;, but you can also select &apos;Best-sellers&apos;, &apos;Specific collections&apos;,
            &apos;Specific product types&apos;, or &apos;Specific products&apos;. For each of these campaign types,
            Klickly will automatically pull your products into an ad campaign and keep this campaign up-to-date based on
            changes to your catalog, best-sellers, collections, etc. And don&apos;t worry - you can review your choices
            on the next page.
        </React.Fragment>
    )
};
