import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '@hooks';
import { Menu } from 'antd';
import { observer } from 'mobx-react';
import { BLOCKED_MENU_ITEMS, MENU_ITEMS } from './constants';

const HeaderMenu = observer(() => {
    const store = useStore();
    const { hasGUID, isBlocked } = store.accountsStore;

    const navigate = useNavigate();
    const location = useLocation();

    const menuItems = useMemo(() => {
        const protoItems = isBlocked ? BLOCKED_MENU_ITEMS : MENU_ITEMS;

        let items = protoItems.map((item) => ({ ...item, active: location.pathname.includes(item.key) }));

        if (!hasGUID && !isBlocked) {
            items = items.filter((menuItem) => menuItem.label !== 'Insights');
        }

        return items;
    }, [location.pathname, hasGUID, isBlocked]);

    return (
        <Menu
            data-e2e='header_menu'
            disabledOverflow
            mode='horizontal'
            selectedKeys={[menuItems.find((item) => item.active)?.key]}
            items={menuItems.map(({ active, ...item }) => item)}
            onClick={({ key }) => navigate(key)}
        />
    );
});

export default HeaderMenu;
