export const CAMPAIGN_STATUS = {
    DELETED: 0,
    CREATED: 1,
    SUBMITTED: 2,
    PENDING: 3,
    ACTIVE: 4,
    PAUSED: 5,
    FROZEN: 6
};

export const CAMPAIGN_SUBMIT_ERROR = {
    hasProduct: 'Campaign requires selected products.',
    hasPayment: 'Payment details needed before launching.',
    hasVerifiedDomain: 'Account verification pending for campaign launch.',
    hasAllContacts: 'Complete profile required to launch campaign.',
    hasAllAddresses: 'Add address details to your profile to proceed.',
    isConnected: 'Connect store to Klickly for campaign launch.',
    hasCreative: 'No advertising creatives set for campaign.',
    hasCommission: 'Set commission to proceed with campaign.',
    hasNoHoldPayments: 'Complete outstanding invoices to run campaigns.',
    hasValidScriptTags: 'Fix script tag error before launching campaign.',
    hasProductCatalog: 'Add products to catalog before launch.',
    isValidPurchaseEvent: 'Setup purchase event before launch.'
};

export const CAMPAIGN_STATUS_LABEL_BY_ID = {
    [CAMPAIGN_STATUS.DELETED]: 'Archived',
    [CAMPAIGN_STATUS.CREATED]: 'Created',
    [CAMPAIGN_STATUS.SUBMITTED]: 'Pending',
    [CAMPAIGN_STATUS.PENDING]: 'Pending',
    [CAMPAIGN_STATUS.ACTIVE]: 'Live',
    [CAMPAIGN_STATUS.PAUSED]: 'Paused',
    [CAMPAIGN_STATUS.FROZEN]: 'Frozen'
};

export const CAMPAIGN_TYPE_ID = {
    MY_ENTIRE_CATALOG: 1,
    SPECIFIC_PRODUCTS: 2,
    CUSTOM_CREATIVE: 3,
    SPECIFIC_PRODUCT_TYPES: 4,
    SPECIFIC_COLLECTIONS: 5,
    BEST_SELLERS: 6
};

export const CAMPAIGN_TYPE_LABEL_BY_ID = {
    [CAMPAIGN_TYPE_ID.MY_ENTIRE_CATALOG]: 'Entire catalog',
    [CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCTS]: 'Specific products',
    [CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCT_TYPES]: 'Specific product types',
    [CAMPAIGN_TYPE_ID.SPECIFIC_COLLECTIONS]: 'Specific collections',
    [CAMPAIGN_TYPE_ID.CUSTOM_CREATIVE]: 'Custom creatives',
    [CAMPAIGN_TYPE_ID.BEST_SELLERS]: 'Best sellers'
};

export const CAMPAIGN_PRODUCTS_LIST_TYPES = {
    CATALOG: 1,
    INCLUDED: 2,
    EXCLUDED: 3,
    RANDOM_INCLUDED: 4
};

export const TABS_TYPES = {
    ACCOUNT_PRODUCTS: 1,
    CAMPAIGN_PRODUCTS: 2
};

export const CAMPAIGN_CREATIVE_STATUS = {
    REJECTED: 0,
    PENDING: 1,
    APPROVED: 2
};

export const CAMPAIGN_COMMISSION_TYPE = {
    REST_OF_SITE: 0,
    CAMPAIGN: 1,
    NEW_CUSTOMER: 2,
    COLD_CUSTOMER: 3
};

export const CAMPAIGN_COMMISSION_TYPE_NAME = {
    [CAMPAIGN_COMMISSION_TYPE.REST_OF_SITE]: 'Rest Of Site',
    [CAMPAIGN_COMMISSION_TYPE.CAMPAIGN]: 'Campaign',
    [CAMPAIGN_COMMISSION_TYPE.NEW_CUSTOMER]: 'New Customer',
    [CAMPAIGN_COMMISSION_TYPE.COLD_CUSTOMER]: 'Cold Customer'
};
