import { COLORS } from '@constants';
import { Button } from 'antd';
import styled, { css } from 'styled-components';

export const StyledButton = styled(Button)`
    font-weight: 500;
    font-size: ${(props) => (props.$fontSize ? props.$fontSize : '16px')};

    .button-text {
        font-size: ${(props) => (props.$fontSize ? props.$fontSize : '16px')};
        font-weight: ${(props) => (props.$fontWeight ? props.$fontWeight : 500)};
        line-height: 0;
    }

    ${({ $justify }) =>
        $justify === 'between' &&
        css`
            display: flex;
            align-items: center;
            justify-content: space-between;

            span:not(.anticon) {
                flex: 1 0 auto;
            }
        `}

    ${({ $loading }) =>
        $loading &&
        css`
            opacity: 0.65;
            cursor: default;
            pointer-events: none;
        `}

    .ant-btn-icon.ant-btn-loading-icon {
        flex-grow: 0
    }

    &.ant-btn-default {
        background-color: transparent;
        border-color: ${COLORS.$gray20Black}
    }

    &.ant-btn-default:disabled,
    &.ant-btn-default:disabled .anticon {
        border-color: ${COLORS.$gray15Black};
        color: ${COLORS.$gray15Black};
    }

    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
        color: ${COLORS.$gray60Black};
        border-color: ${COLORS.$gray20Black};
    }
    &.ant-btn-default:not(:disabled):hover {
        color: ${COLORS.$gray60Black};
        background-color: ${COLORS.$gray20Black};
    }
    &.ant-btn-primary {
        background: ${COLORS.$pinkGradient};
        border-color: ${COLORS.$pink};
        transition: none;
    }
    &.ant-btn-primary:not(:disabled):hover {
        background: ${COLORS.$pinkDark};
        border-color: ${COLORS.$pinkDark};
    }
    &.ant-btn-primary:disabled {
        color: ${COLORS.$white};
        background: ${COLORS.$gray20Black};
        border-color: ${COLORS.$gray15Black};
    }
    &.ant-btn-link:not(:disabled):hover {
        background-color: ${COLORS.$gray20Black};
        color: ${COLORS.$gray60Black};
    }
    &.ant-btn-lg {
        height: 56px;
        padding: 14.4px 16px;
    }

    .ant-wave {
        display: none;
    }

    &.ant-btn {
        ${({ $padding }) =>
            $padding &&
            css`
                padding: ${$padding};
            `})};

    ${({ $backgroundColor }) =>
        $backgroundColor &&
        css`
            background-color: ${$backgroundColor};
        `};
    }

    /* Specific custom buttons */

    ${({ $type }) =>
        ($type === 'label' || $type === 'chips') &&
        css`
            height: 32px;
            padding: 4px 12px;
            font-size: ${$type === 'label' ? '14px' : '16px'};
            font-weight: ${$type === 'label' ? '500' : 'normal'};
            line-height: 24px;
            border: 1px solid ${$type === 'label' ? COLORS.$gray100Black : COLORS.$gray20Black};
            border-radius: ${$type === 'label' ? '16px' : '4px'};
            color: ${COLORS.$gray100Black};
            background: ${COLORS.$white};

            &.ant-btn-default:not(:disabled):hover {
                color: ${COLORS.$white};
                background: ${COLORS.$gray100Black};
            }

            &.ant-btn-default:disabled {
                color: ${COLORS.$gray100Black};
                background: transparent;

                .anticon {
                    color: ${COLORS.$white};
                    background: ${COLORS.$gray100Black};
                    border: 2px solid ${COLORS.$gray100Black};
                    border-radius: 50%;
                }
            }
        `};

    ${({ $type, $color = COLORS.$info, $padding = '16px', $fontSize = '16px', $fontWeight = 500, $hoverColor }) =>
        $type === 'link' &&
        css`
            height: auto;
            padding: ${$padding};
            font-size: ${$fontSize};
            font-weight: ${$fontWeight};
            line-height: 24px;
            border: none;
            border-radius: 0;
            color: ${$color};
            background: transparent;

            &:hover {
                color: ${$hoverColor || $color};
                text-decoration: underline;
            }
        `};

    ${({ $type, $color = COLORS.$gray60Black, $padding = '16px', $fontSize = '16px', $fontWeight = 500 }) =>
        $type === 'noStyle' &&
        css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: auto;
            padding: ${$padding};
            border: none;
            color: ${$color};
            background: transparent;
            font-size: ${$fontSize};
            font-weight: ${$fontWeight};
            line-height: 24px;

            &:hover {
                background-color: ${COLORS.$gray20Black};
            }
        `};
`;
