import { useEffect, useMemo } from 'react';
import { debounce } from 'lodash';

export const useDebounce = (callback, delay = 300, options) => {
    const debouncedResults = useMemo(() => {
        return debounce(callback, delay, options);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    return debouncedResults;
};
