import React from 'react';
import { COLORS, ROUTE_PATHS } from '@constants';
import { Icon } from '@ui';

/* eslint-disable */
export const getRecommendations = [
    {
        logo: <Icon color={COLORS.$gray40Black} size='80px' component={() => <Icon.CampaignIcon />} />,
        title: 'Add new campaign',
        description: 'The more campaigns you have, the greater the chance your products will be shown to consumers!',
        redirect: ROUTE_PATHS.CAMPAIGNS_CREATE_STEP_1
    },
    {
        logo: <Icon color={COLORS.$gray40Black} size='80px' component={() => <Icon.AddMoreProductsIcon />} />,
        title: 'Add more products',
        description: 'Different consumers have different tastes. Add more products to satisfy all your customers.',
        redirect: ROUTE_PATHS.PRODUCTS
    },
    {
        logo: <Icon color={COLORS.$gray40Black} size='80px' component={() => <Icon.AnalyzePerformanceIcon />} />,
        title: 'Analyze your performance',
        description: 'There\'s a lot of data out there, but it\'s only useful if you use it! Check out your Performance for more ideas.',
        redirect: ROUTE_PATHS.PERFORMANCE
    },
    {
        logo: <Icon color={COLORS.$gray40Black} size='80px' component={() => <Icon.PerformanceIcon />} />,
        title: 'Finalize your profile',
        description: 'We know it takes time to fully set up an account, but the time is worth it!',
        redirect: ROUTE_PATHS.PROFILE
    }
];
