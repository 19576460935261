import { Typography } from '@ui';
import styled from 'styled-components';

export const StyledText = styled(Typography.Text)`
    overflow: hidden;
    max-width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: end;
`;
