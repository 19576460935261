import React, { forwardRef } from 'react';
import { Radio as AntdRadio } from 'antd';
import { StyledCheckMark, StyledLabel } from './styled';

const BaseRadio = forwardRef(({ color, ...props }, ref) => {
    return (
        <StyledLabel $color={color}>
            <StyledCheckMark data-e2e='common_radio' className='custom-check-mark' />
            <AntdRadio {...props} ref={ref} />
        </StyledLabel>
    );
});

BaseRadio.displayName = 'BaseRadio';

export default BaseRadio;
