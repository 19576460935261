import React, { Fragment } from 'react';
import { SectionHeader } from '@components';
import { COLORS, LEFT_INDENTS, orderMap, TOP_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Button, Icon, RangePicker } from '@ui';
import { camelToSnakeCase, dayjs } from '@utils';
import fileDownload from 'js-file-download';
import { observer } from 'mobx-react';
import OrderDetailsFooter from './components/OrderDetailsFooter';
import { getColumns, getColumnsInner } from './constants';
import { StyledInnerTable, StyledTable } from './styled';

const OrderList = observer(() => {
    const store = useStore();
    const { orderList, downloadOrders, meta, getOrders, updateFilters, updateSorters, filters, pending } =
        store.ordersStore;
    const { isShopify, isManual, isBigCommerce } = store.accountsStore;

    const pagination = {
        total: meta.total,
        totalPages: meta.totalPages,
        pageSize: meta.perPage,
        current: meta.page,
        showSizeChanger: false
    };

    const onTableChange = ({ current }, filters, sorter) => {
        updateSorters({
            sort: sorter.order ? camelToSnakeCase(sorter.field) : undefined,
            order: sorter.order ? orderMap[sorter.order] : undefined
        });

        updateFilters({
            klickly_status: filters.klicklyStatus || undefined,
            financial_status: filters.financialStatus || undefined,
            fulfillment_status: filters.fulfillmentStatus || undefined,
            ecommerce_status: filters.ecommerceStatus || undefined
        });

        getOrders({ params: { page: current } });
    };

    const onDatePickerChange = ({ min, max }) => {
        updateFilters({
            created_at_min: min,
            created_at_max: max
        });
        getOrders();
    };

    const dateFormat = 'YYYY/MM/DD';
    const datePickerValue =
        filters.created_at_min && filters.created_at_max
            ? [dayjs(filters.created_at_min, dateFormat), dayjs(filters.created_at_max, dateFormat)]
            : undefined;

    const onDownload = async () => {
        const { file, fileName } = await downloadOrders();
        fileDownload(file, fileName);
    };

    return (
        <Fragment>
            <SectionHeader data-e2e='orders_pape_title' description='Orders' type='em0'>
                <div data-e2e='orders_datepicker-search_input'>
                    <RangePicker onChange={onDatePickerChange} value={datePickerValue} />
                </div>
                <Button
                    style={LEFT_INDENTS.M}
                    icon={<Icon size='24px' color={COLORS.$gray60Black} component={() => <Icon.DownloadIcon />} />}
                    onClick={onDownload}
                    data-e2e='orders_download-csv_btn'
                >
                    Download CSV
                </Button>
            </SectionHeader>
            <StyledTable
                columns={getColumns({ isShopify, isManual, isBigCommerce })}
                dataSource={orderList}
                loading={pending}
                rowKey={(record) => record.klicklyOrderNumber}
                onChange={onTableChange}
                pagination={pagination}
                expandPosition='left'
                style={TOP_INDENTS.XL_PLUS}
                expandable={{
                    expandedRowRender: (record) => {
                        return (
                            <StyledInnerTable
                                data-e2e={`orders_${record.klicklyOrderNumber}-order-details_content`}
                                dataSource={record?.orderItems || []}
                                columns={getColumnsInner(isManual)}
                                rowKey={(record) => record.id}
                                pagination={false}
                                borderColor='transparent'
                                footer={() => <OrderDetailsFooter record={record} />}
                            />
                        );
                    }
                }}
                scroll={{
                    x: 1250
                }}
                data-e2e='orders_orders-list_table'
            />
        </Fragment>
    );
});

export default OrderList;
