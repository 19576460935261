import React from 'react';
import { Typography } from '@ui';
import Charges from '../components/Charges';
import Payouts from '../components/Payouts';

export const tabs = [
    {
        key: '1',
        label: (
            <Typography.Title data-e2e='invoices_charges_tab' level={3} align='center'>
                Charges
            </Typography.Title>
        ),
        children: <Charges />
    },
    {
        key: '2',
        label: (
            <Typography.Title data-e2e='invoices_payouts_tab' level={3} align='center'>
                Payouts
            </Typography.Title>
        ),
        children: <Payouts />
    }
];
