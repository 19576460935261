import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { COLORS } from '@constants';
import { Button, Icon, Typography } from '@ui';
import { Flex } from 'antd';

export const BackButton = () => {
    const navigate = useNavigate();
    const location = useLocation();

    if (location.key === 'default' || location.state?.fromLogout) {
        return null;
    }

    return (
        <Button type='noStyle' onClick={() => navigate(-1)}>
            <Flex align='center' gap={16}>
                <Icon size='24px' color={COLORS.$gray60Black} component={() => <Icon.ArrowBackIcon />} />
                <Typography.Title data-e2e='common_back_btn' level={3} color={COLORS.$gray60Black}>
                    Back
                </Typography.Title>
            </Flex>
        </Button>
    );
};
