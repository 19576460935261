export const PLATFORM_ID = {
    KLICKLY: 1,
    SHOPIFY: 2,
    BIGCOMMERCE: 3,
    WOOCOMMERCE: 4,
    MAGENTO: 5,
    PRESTASHOP: 6,
    SQUARESPACE: 7,
    COMMERCE_CLOUD: 8
};

export const CUSTOM_PLATFORMS = [
    PLATFORM_ID.KLICKLY,
    PLATFORM_ID.WOOCOMMERCE,
    PLATFORM_ID.MAGENTO,
    PLATFORM_ID.PRESTASHOP,
    PLATFORM_ID.SQUARESPACE,
    PLATFORM_ID.COMMERCE_CLOUD
];

export const PLATFORM_NAME = {
    KLICKLY: 'klickly',
    SHOPIFY: 'shopify',
    BIGCOMMERCE: 'big-commerce',
    WOOCOMMERCE: 'woo-commerce'
};

export const PLATFORM_NAME_BY_PLATFORM_ID = {
    [PLATFORM_ID.KLICKLY]: 'Klickly',
    [PLATFORM_ID.SHOPIFY]: 'Shopify',
    [PLATFORM_ID.BIGCOMMERCE]: 'Big-Commerce',
    [PLATFORM_ID.WOOCOMMERCE]: 'Woo-Commerce'
};
