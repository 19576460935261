import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { formatString, isMobileLandscapeView, isMobileView } from '@utils';
import * as cn from 'classnames';
import { StyledSlider } from './styled';

const VerticalSliderReach = lazy(() => import('../LazyImages/components/VerticalSliderReach'));
const VerticalSliderIdentify = lazy(() => import('../LazyImages/components/VerticalSliderIdentify'));
const VerticalSliderPersonalize = lazy(() => import('../LazyImages/components/VerticalSliderPersonalize'));
const VerticalSliderControl = lazy(() => import('../LazyImages/components/VerticalSliderControl'));

const content = [
    {
        title: 'Reach',
        description:
            'We have custom-built an ecosystem of 25M+ digital destinations across web, apps, email, games and more',
        image: <VerticalSliderReach />
    },
    {
        title: 'Identify',
        description:
            'We use our proprietary Consumer Data Platform (90M+ shopping-ready consumers) to identify quality targets',
        image: <VerticalSliderIdentify />
    },
    {
        title: 'Personalize',
        description:
            'Our high-engagement, AI-driven Commerce Modules ensure we surface the right product to the right consumer at the right time',
        image: <VerticalSliderPersonalize />
    },
    {
        title: 'Control',
        description:
            'And our performance-based model means you’re in control – align Klickly’s AI to your brand’s marketing strategy. ',
        image: <VerticalSliderControl />
    }
];

const VerticalSlider = () => {
    return (
        <StyledSlider className='bg-gradient bg-gradient-pink'>
            <div data-e2e='landing_pillars-section' className='container-content'>
                <div className='v-slider__wrapper'>
                    <h2 data-e2e='landing_pillars-section_title'>
                        There are 4 pillars <br />
                        to our commerce engine
                    </h2>
                    <SliderComponent />
                </div>
            </div>
        </StyledSlider>
    );
};

const SliderComponent = () => {
    const [showedImages, setShowedImages] = useState([]);
    const slidesRef = useRef();

    const isInViewport = () => {
        if (!slidesRef.current) return false;
        const elems = slidesRef.current.querySelectorAll('.v-slider__wrapper__slides__content__single');
        const res = [];
        elems.forEach((el, index) => {
            const top = el.getBoundingClientRect().top - el.clientHeight;
            if (top < 0) {
                res.push(index);
            }
        });
        if (res.length) setShowedImages(res);
    };

    useEffect(() => {
        document.querySelector('#root').addEventListener('scroll', isInViewport);

        return () => {
            document.querySelector('#root').removeEventListener('scroll', isInViewport);
        };
    }, []);

    const lastShowedImage = showedImages.length ? showedImages[showedImages.length - 1] : 0;

    const currentTitle = content[lastShowedImage].title;

    return (
        <div className='v-slider__wrapper__slides'>
            <div className='v-slider__wrapper__slides__dots'>
                {content.map((el, index) => {
                    return <span key={`dots-${index}`} className={cn(lastShowedImage === index ? 'active' : null)} />;
                })}
            </div>
            <div
                data-e2e='landing_pillars-section_description'
                className='v-slider__wrapper__slides__content'
                ref={slidesRef}
            >
                {content.map((el, index) => {
                    return (
                        <div key={`slider-content-${index}`} className='v-slider__wrapper__slides__content__single'>
                            <h3 data-e2e={`landing_${formatString(el.title)}_title`}>{`${index + 1}. ${el.title}`}</h3>
                            <span data-e2e={`landing_${formatString(el.title)}_description`}>{el.description}</span>
                            {isMobileView() || isMobileLandscapeView() ? (
                                <Suspense fallback={<React.Fragment />}>{el.image}</Suspense>
                            ) : null}
                        </div>
                    );
                })}
            </div>
            <div className={cn('v-slider__wrapper__slides__animation', currentTitle)}>
                {content.map((el, index) => {
                    return (
                        <Suspense key={`animation-${index}`} fallback={<React.Fragment />}>
                            {el.image}
                        </Suspense>
                    );
                })}
            </div>
        </div>
    );
};

export default VerticalSlider;
