import { COLORS } from '@constants';
import { Carousel, Flex } from 'antd';
import styled from 'styled-components';

export const StyledSlider = styled.div`
    width: ${({ $width }) => `${$width}px`};
    height: ${({ $height }) => `${$height}px`};
    overflow: hidden;
    background-color: ${COLORS.$white};
    border: 1px solid ${COLORS.$gray15Black};
`;

export const StyledQuestion = styled(Flex)`
    cursor: pointer;
`;

export const StyledLoader = styled.div`
    position: relative;
    width: 300px;
    height: 600px;
    overflow: hidden;
    background-color: ${COLORS.$white};
    margin-bottom: 64px;
`;

export const StyledCarousel = styled(Carousel)`
    margin-bottom: 64px;

    .slick-dots {
        li {
            text-indent: initial;
        }
    }

    > .slick-dots.slick-dots-bottom {
        bottom: -28px;
        transform: translateY(100%);
        z-index: 9;

        li {
            text-indent: initial;
            width: 8px;
            height: 8px;

            &.slick-active {
                button {
                    background-color: ${COLORS.$gray60Black};
                }
            }

            button {
                width: 8px;
                height: 8px;
                background-color: ${COLORS.$gray20Black};
                opacity: 1;
                border-radius: 50%;

                &:after {
                    display: none;
                }

                &:before {
                    display: none;
                }
            }
        }
    }
`;
