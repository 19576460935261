import React, { lazy, Suspense } from 'react';
import GetStartedButton from '../GetStartedButton';
import { StyledInfoSection } from './styled';

const Phone = lazy(() => import('../LazyImages/components/Phone'));

const InfoSection = () => {
    return (
        <StyledInfoSection className='bg-gradient bg-gradient-orange-invert'>
            <div data-e2e='landing_info-section' className='container-content'>
                <div className='info-section__wrapper'>
                    <div className='info-section__wrapper__image'>
                        <Suspense fallback={<React.Fragment />}>
                            <Phone />
                        </Suspense>
                    </div>
                    <div className='info-section__wrapper__content'>
                        <h3 data-e2e='landing_info-section_title'>Start driving sales with NO upfront spend</h3>
                        <ul data-e2e='landing_info-section_description'>
                            <li>Pay only for sales that Klickly helps drive</li>
                            <li>15 minutes to launch; seamless integration</li>
                            <li>Full service onboarding</li>
                            <li>Access to advanced data reporting</li>
                        </ul>
                        <GetStartedButton />
                    </div>
                </div>
            </div>
        </StyledInfoSection>
    );
};

export default InfoSection;
