import React, { useState } from 'react';
import { CancelSave } from '@components';
import { BOTTOM_INDENTS, validateDebounce, VALIDATION_RULES } from '@constants';
import { useStore } from '@hooks';
import { Input } from '@ui';
import { Form, notification } from 'antd';
import { observer } from 'mobx-react';
import { FIELD_NAMES } from './constants';

const ChangeEmailForm = observer(({ onClose, profileForm }) => {
    const store = useStore();
    const { updateProfile } = store.profileStore;
    const { whoAmI } = store.authStore;
    const { EMAIL, PASSWORD } = FIELD_NAMES;
    const [form] = Form.useForm();
    const [formSubmitting, setFormSubmitting] = useState(false);

    return (
        <Form
            form={form}
            onFinish={async (values) => {
                setFormSubmitting(true);
                const response = await updateProfile(values);

                if (response) {
                    profileForm.setFieldValue('email', response.email);
                    whoAmI();
                }

                form.resetFields(Object.values(FIELD_NAMES));
                setFormSubmitting(false);
                onClose();

                if (response) {
                    notification.success({ message: 'Check your email' });
                }
            }}
        >
            <Form.Item
                data-e2e='profile_password-input_field'
                name={PASSWORD}
                rules={[...VALIDATION_RULES.REQUIRED]}
                style={BOTTOM_INDENTS.S}
            >
                <Input data-e2e='profile_password_input' type='password' label='Enter password' />
            </Form.Item>
            <Form.Item
                data-e2e='profile_email-input_field'
                name={EMAIL}
                rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.EMAIL]}
                validateDebounce={validateDebounce}
                style={BOTTOM_INDENTS.XL_PLUS}
            >
                <Input data-e2e='profile_email_input' type='email' label='Enter new email' />
            </Form.Item>
            <CancelSave onCancel={onClose} loading={formSubmitting} />
            {/* <ErrorFallback.Message message={emailUpdateErrorMessage} /> */}
        </Form>
    );
});

export default ChangeEmailForm;
