import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
    position: relative;
`;

export const StyledChartWrapper = styled.div`
    position: relative;
    height: ${({ $height }) => `${$height}px` || 'auto'};
    display: flex;
    justify-content: center;
    align-content: center;
`;
