import React, { Fragment } from 'react';
import { Typography } from '@ui';

export const renderAddress = (address, type) => {
    if (!address || !address.address1)
        return <Typography.Text type='body2'>{`No ${type} address provided`}</Typography.Text>;

    const city = address.city ? `${address.city},` : '';
    return (
        <Fragment>
            <Typography.Text data-e2e={`orders_${type}-customer-name_value`} type='body2'>
                {address.firstName ? `${address.firstName} ${address.lastName || ''}` : 'No Name'}
            </Typography.Text>
            <Typography.Text data-e2e={`orders_${type}-address1_value`} type='body2'>
                {address.address1 || ''} {address.address2 || ''}
            </Typography.Text>
            <Typography.Text data-e2e={`orders_${type}-city_value`} type='body2'>
                {city} {address.countryCode || ''} {address.zip || ''}
            </Typography.Text>
        </Fragment>
    );
};
