import React, { useMemo } from 'react';
import { COLORS } from '@constants';
import { formatString, getPickerColor } from '@utils';
import { Col, ColorPicker as AntdColorPicker, Row } from 'antd';
import { Typography } from '../Typography';
import { StyledColorViewer, StyledInitiator } from './styled';

export const ColorPicker = ({ label, value = '#FFFFFF', onChange, ...props }) => {
    const bgColor = useMemo(() => getPickerColor(value), [value]);
    return (
        <AntdColorPicker value={value} {...props} onChange={(_, hex) => onChange(hex)}>
            <StyledInitiator>
                <Row justify='space-between' align='middle' wrap={false}>
                    <Col>
                        <Typography.Text type='descriptor2' color={COLORS.$gray40Black}>
                            {label}
                        </Typography.Text>
                        <Typography.Text
                            data-e2e={`creative-settings_color-picker-${formatString(label)}_value`}
                            type='descriptor1'
                            color={COLORS.$gray100Black}
                        >
                            {bgColor}
                        </Typography.Text>
                    </Col>
                    <Col>
                        <StyledColorViewer
                            data-e2e={`creative-settings_color-picker-${formatString(label)}_preview`}
                            $backgroundColor={bgColor}
                        />
                    </Col>
                </Row>
            </StyledInitiator>
        </AntdColorPicker>
    );
};
