import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

export const usePerformanceScrollFetch = ({
    initialFetchDone,
    onInitialFetch,
    onReFetch,
    onComponentViewUpdate,
    date
}) => {
    const { ref, inView } = useInView({
        threshold: 0.2 // 20% of element visible -> enable trigger
    });

    // Initial fetch when section first time in view
    useEffect(() => {
        onComponentViewUpdate(inView);

        if (!initialFetchDone && inView) {
            onInitialFetch();
        }
    }, [inView]);

    // Re-fetch when date was changed
    useEffect(() => {
        if (initialFetchDone) {
            onReFetch();
        }
    }, [date]);

    return { componentRef: ref, isComponentInView: inView };
};
