import { Card } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
    .ant-card-body {
        height: 243px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
