import React from 'react';
import { StyledLabel } from './styled';

const Label = ({ children, label, noPrefix, prefix }) => (
    <StyledLabel $hasPrefix={prefix} $noPrefix={noPrefix}>
        {children}
        <label>{label}</label>
    </StyledLabel>
);

export default Label;
