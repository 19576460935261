export const getErrorMessage = (error) => {
    if (error && error.response && error.response.data) {
        return error.response.data.errors;
    }

    if (error && error.errors) {
        return error.errors;
    }

    return { description: 'Something Went Wrong' };
};
