import React from 'react';
import { useStore } from '@hooks';
import { Button } from '@ui';
import { Col, Form, Row } from 'antd';
import { observer } from 'mobx-react';

const SubmitButtons = observer(() => {
    const store = useStore(); // eslint-disable-line
    return (
        <Row justify='end' gutter={[16, 16]}>
            <Col span={5}>
                <Form.Item>
                    <Button block>Cancel</Button>
                </Form.Item>
            </Col>
            <Col span={5}>
                <Form.Item shouldUpdate>
                    {({ getFieldsError }) => (
                        <Button
                            disabled={!!getFieldsError().filter(({ errors }) => errors.length).length}
                            htmlType='submit'
                            type='primary'
                            block
                        >
                            Save
                        </Button>
                    )}
                </Form.Item>
            </Col>
        </Row>
    );
});
export default SubmitButtons;
