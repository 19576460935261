import React from 'react';
import { Table } from 'antd';
import FilterDropdown from '../components/FilterDropdown';
import FilterIcon from '../components/FilterIcon';
import SortIcon from '../components/SortIcon';
import { TitleWithTooltip } from '../components/TitleWithTooltip';

export const getExtendedColumns = (columns, config = {}) => {
    const extendedColumns = columns.map(({ tooltip, ...column }) => {
        const columnExtended = { ...column };
        if (column.sorter) {
            columnExtended.sortIcon = SortIcon;
            columnExtended.showSorterTooltip = false;
        }
        if (column.filters) {
            columnExtended.filterDropdown = FilterDropdown;
            columnExtended.filterIcon = FilterIcon;
        }
        if (tooltip) {
            columnExtended.title = <TitleWithTooltip title={column.title} tooltip={tooltip} />;
        }
        return columnExtended;
    });

    if (config.expandPosition === 'right') {
        extendedColumns.push(Table.EXPAND_COLUMN);
    }

    return extendedColumns;
};
