import React, { useState } from 'react';
import { BOTTOM_INDENTS, brandsOpenApi, COLORS, VALIDATION_RULES } from '@constants';
import { useNotification, useStore } from '@hooks';
import { Button, Input, Typography, WhiteCover } from '@ui';
import { axiosBoa, getErrorMessage } from '@utils';
import { Flex, Form } from 'antd';

const RequestAccessCard = () => {
    const [submitting, setFormSubmitting] = useState(false);
    const notification = useNotification();
    const [form] = Form.useForm();

    const store = useStore();
    const { setGUIDRequestMessage } = store.accountsStore;

    const handleFinish = async ({ message }) => {
        try {
            setFormSubmitting(true);
            await axiosBoa.post(brandsOpenApi.performance.insights.request, { message });
            setGUIDRequestMessage(message);
        } catch (error) {
            notification.error({ message: getErrorMessage(error).description });
        }
        form.resetFields();
        setFormSubmitting(false);
    };

    return (
        <WhiteCover hasDivider={false} style={BOTTOM_INDENTS.XL_PLUS}>
            <Typography.Text type='body2' color={COLORS.$gray60Black} marginBottom='24px'>
                Klickly’s AI-powered Insights provides valuable data about your audience that can help to enhance both
                your products and your marketing. Request access to learn more.
            </Typography.Text>
            <Form form={form} scrollToFirstError={{ behavior: 'smooth', block: 'center' }} onFinish={handleFinish}>
                <Form.Item data-e2e='profile_first-name_field' name='message' rules={VALIDATION_RULES.REQUIRED}>
                    <Input.TextArea
                        data-lpignore='true'
                        style={{ marginBottom: '24px' }}
                        label='What insights are you hoping to learn about your audience?'
                    />
                </Form.Item>

                <Flex justify='end'>
                    <Button type='primary' htmlType='submit' loading={submitting}>
                        Request Access
                    </Button>
                </Flex>
            </Form>
        </WhiteCover>
    );
};

export default RequestAccessCard;
