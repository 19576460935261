import React from 'react';
import { BOTTOM_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Card, Col, Row } from 'antd';
import * as get from 'lodash/get';
import { observer } from 'mobx-react';
import { tooltips } from '../../constants';
import BaselineInfo from '../BaselineInfo';
import CardTitle from '../CardTitle';
import BarChart from '../charts/BarChart';
import DoughnutChart from '../charts/DoughnutChart';
import HouseholdSavings from '../charts/HouseholdSavings';
import { StyledChartWrapper, StyledWrapper } from './styled';

const FinancialSection = observer(() => {
    const store = useStore();
    const { financialChartsData } = store.insightsStore;

    return (
        <StyledWrapper>
            <Row style={BOTTOM_INDENTS.M}>
                <Col span={24}>
                    <Card
                        data-e2e='insights_net-worth_card'
                        title={<CardTitle title='Net Worth' tooltip={tooltips.netWorth} />}
                    >
                        <StyledChartWrapper data-e2e='insights_net-worth_chart' $height={400} style={BOTTOM_INDENTS.M}>
                            <BarChart data={get(financialChartsData, 'netWorth')} />
                        </StyledChartWrapper>
                        <BaselineInfo />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24} style={BOTTOM_INDENTS.M}>
                <Col span={12}>
                    <Card
                        data-e2e='insights_savings_card'
                        title={<CardTitle title='Avg. Household Savings' tooltip={tooltips.avgHouseholdSavings} />}
                    >
                        <StyledChartWrapper data-e2e='insights_savings_chart' $height={400}>
                            <HouseholdSavings data={get(financialChartsData, 'avgHouseholdSavings')} />
                        </StyledChartWrapper>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        data-e2e='insights_discretionary-income_card'
                        title={<CardTitle title='Discretionary Income' tooltip={tooltips.discretionaryIncome} />}
                    >
                        <StyledChartWrapper data-e2e='insights_discretionary-income_chart' $height={400}>
                            <BarChart data={get(financialChartsData, 'discretionaryIncome')} />
                        </StyledChartWrapper>
                    </Card>
                </Col>
            </Row>
            <Row style={BOTTOM_INDENTS.M}>
                <Col span={24}>
                    <Card
                        data-e2e='insights_credit-card-type_card'
                        title={<CardTitle title='Credit Card Type' tooltip={tooltips.creditCartType} />}
                    >
                        <StyledChartWrapper data-e2e='insights_credit-card-type_chart' $height={400}>
                            <DoughnutChart data={get(financialChartsData, 'creditCartType')} />
                        </StyledChartWrapper>
                    </Card>
                </Col>
            </Row>
        </StyledWrapper>
    );
});

export default FinancialSection;
