import React from 'react';
import { COLORS } from '@constants';
import { Typography } from '@ui';
import { Flex } from 'antd';
import { observer } from 'mobx-react';
import { StyledAlert } from '../../styled';

const StandardComplete = observer(() => {
    return (
        <StyledAlert $isValid>
            <Flex justify='center' align='center'>
                <Typography.Text
                    data-e2e='payments_stripe-standard-complete-status_title'
                    align='center'
                    color={COLORS.$robinBlue}
                    type='descriptor1'
                >
                    Your Stripe integration is up to date and payouts are enabled!
                </Typography.Text>
            </Flex>
        </StyledAlert>
    );
});

export default StandardComplete;
