import React, { useEffect } from 'react';
import { BOTTOM_INDENTS, COLORS, FIELD_NAME, RIGHT_INDENTS, TOP_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Button, Icon, Typography, WhiteCover } from '@ui';
import { Checkbox, Flex, Form } from 'antd';
import { observer } from 'mobx-react';
import { useCustomCreativesModal } from '../hooks/useCustomCreativesModal';
import Tags from './components/Tags';

export const CustomCreativesSwitch = observer(() => {
    const store = useStore();
    const form = Form.useFormInstance();
    const { getCustomCreatives, enabledByBrand, unitsWithImages, isRejected } = store.customCreativesStore;
    const { campaign } = store.campaignWizardStore;

    const custonCreativesEnabled = Form.useWatch(FIELD_NAME.CUSTOM_CREATIVES.ENABLED_BY_BRAND, form);

    const { openModal } = useCustomCreativesModal();

    useEffect(() => {
        getCustomCreatives(campaign?.id);
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            [FIELD_NAME.CUSTOM_CREATIVES.ENABLED_BY_BRAND]: enabledByBrand
        });
    }, [enabledByBrand, form]);

    if (store.customCreativesStore.pending) {
        return <div>loading</div>;
    }

    return (
        <WhiteCover
            title='Custom Creatives'
            rightContent={
                isRejected ? (
                    <Flex align='center'>
                        <Icon size='24px' component={() => <Icon.CloseCircleIcon />} style={RIGHT_INDENTS.XXS} />
                        <Typography.Text type='badgeSmall' color={COLORS.$tartRed}>
                            Rejected
                        </Typography.Text>
                    </Flex>
                ) : null
            }
            style={BOTTOM_INDENTS.M}
        >
            <Flex gap={24} align='start' justify='space-between'>
                <Flex vertical style={{ flexBasis: '50%' }} gap={16}>
                    <Form.Item
                        name={FIELD_NAME.CUSTOM_CREATIVES.ENABLED_BY_BRAND}
                        valuePropName='checked'
                        style={{ ...BOTTOM_INDENTS.M, ...TOP_INDENTS.XXS }}
                    >
                        <Checkbox onChange={(e) => store.customCreativesStore.setEnabledByBrand(e.target.checked)}>
                            <Typography.Title level={3}>Use custom creatives in this campaign</Typography.Title>
                        </Checkbox>
                    </Form.Item>
                    <Button
                        disabled={!custonCreativesEnabled}
                        backgroundColor={COLORS.$white}
                        block
                        onClick={openModal}
                    >
                        {unitsWithImages?.length ? 'Change custom creatives' : 'Upload custom creatives'}
                    </Button>
                    <Tags />
                </Flex>
                <Flex vertical justify='start' align='center' style={{ flexBasis: '40%' }}>
                    <Icon size='80px' component={() => <Icon.CustomCreatives />} style={BOTTOM_INDENTS.M} />
                    <Typography.Text type='body2' color={COLORS.$gray60Black} align='center' style={BOTTOM_INDENTS.SM}>
                        Upload custom creatives for Klickly to serve to consumers in this campaign
                    </Typography.Text>
                </Flex>
            </Flex>
        </WhiteCover>
    );
});
