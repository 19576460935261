import { COLORS } from '@constants';
import styled, { css } from 'styled-components';

export const CampaignTypeCardWrapper = styled.label`
    display: block;
    padding: 48px 24px;
    background: ${COLORS.$white};
    border: 1px solid ${COLORS.$gray15Black};
    border-radius: 8px;

    &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 8px 48px 0 rgba(0, 0, 0, 0.16);
        cursor: pointer;
    }

    ${({ $isSmall }) =>
        $isSmall &&
        css`
            padding: 8px 24px;
        `};

    > label {
        display: none;
    }

    &:has(.ant-radio-input:disabled):hover {
        box-shadow: none;
        cursor: default;
    }

    &:has(.ant-radio-input:checked) {
        padding: 47px 23px;
        border: 2px solid ${COLORS.$pink};

        &:hover {
            box-shadow: none;
            cursor: pointer;
        }

        ${({ $isSmall }) =>
            $isSmall &&
            css`
                padding: 7px 23px;
            `};

        .anticon:not(.ant-input-suffix .anticon) {
            color: ${COLORS.$pink};
        }

        > .ant-row .ant-col {
            > p:first-child {
                color: ${COLORS.$pink};
            }
        }
    }
`;
