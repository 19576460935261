import React from 'react';
import { formatPrice } from '@utils';
import { Table } from 'antd';
import dayjs from 'dayjs';
import Status from '../components/Status';

export const columns = [
    Table.EXPAND_COLUMN,
    {
        title: <span data-e2e='invoices_charges-date_column'>Date</span>,
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        render: (value, record) => (
            <span data-e2e={`invoices_charge-${record.id}-date_value`}>
                {dayjs(value).format('ddd, MMM D[th], YYYY')}
            </span>
        )
    },
    {
        title: <span data-e2e='invoices_charges-invoice-number_column'>Invoice number</span>,
        dataIndex: 'sequenceNumber',
        width: '20%',
        align: 'right',
        key: 'sequenceNumber',
        render: (value, record) => <span data-e2e={`invoices_charge-${record.id}-invoice-number_value`}>{value}</span>
    },
    {
        title: <span data-e2e='invoices_charges-status_column'>Status</span>,
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: '20%',
        render: (status, record) => <Status status={status} record={record} />
    },
    {
        title: <span data-e2e='invoices_charges-amount_column'>Amount</span>,
        dataIndex: 'amount',
        key: 'amount',
        width: '20%',
        align: 'right',
        render: (amount, record) => (
            <span data-e2e={`invoices_charge-${record.id}-amount_value`}>{formatPrice(amount)}</span>
        )
    }
];
