import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, COLORS, ROUTE_PATHS, TOP_INDENTS, VALIDATION_RULES } from '@constants';
import { CONTENT_TYPE } from '@enums';
import { useStore } from '@hooks';
import { Button, Icon, Input, Loader, Typography } from '@ui';
import { isIframe } from '@utils';
import { Flex, Form } from 'antd';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const StyledWrapper = styled(Flex)`
    width: 100%;
    padding-inline: 24px;
    height: 100vh;
    background: ${COLORS.$gray10Black};
`;

const CompanyName = observer(() => {
    const navigate = useNavigate();
    const store = useStore();
    const { updateAccount } = store.accountsStore;
    const { getInitialData, embeddedLoadPayload, oauthPayload } = store.authStore;

    const [formSubmitting, setFormSubmitting] = useState(false);

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            if (!store.authStore?.me || !store.authStore.me?.currentAccount) {
                await getInitialData();

                if (!store.authStore?.me || !store.authStore.me?.currentAccount) {
                    navigate(ROUTE_PATHS.LOGIN);
                }
            }
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (store.authStore.me?.currentAccount?.companyName) {
            if (embeddedLoadPayload || (oauthPayload && isIframe())) {
                navigate(ROUTE_PATHS.WELCOME);
            } else {
                navigate(ROUTE_PATHS.HOME + '?setup=1', { replace: true });
            }
        }
    }, [store.authStore.me?.currentAccount?.companyName]);

    const onFinishForm = async (values) => {
        setFormSubmitting(true);
        await updateAccount(values, CONTENT_TYPE.JSON);
        setFormSubmitting(false);
    };

    if (loading) {
        return <Loader size='120px' />;
    }

    return (
        <StyledWrapper justify='center' align='start'>
            <div style={{ width: '100%', maxWidth: '450px', marginTop: '128px' }}>
                <Typography.Title align='center' level={2} color={COLORS.$gray100Black} style={BOTTOM_INDENTS.S}>
                    Please Fill in Your Company Name
                </Typography.Title>
                <Form form={form} onFinish={onFinishForm}>
                    <Form.Item style={BOTTOM_INDENTS.S} name='companyName' rules={VALIDATION_RULES.REQUIRED}>
                        <Input type='text' label='Company Name' />
                    </Form.Item>

                    <Form.Item style={TOP_INDENTS.L}>
                        <Button
                            loading={formSubmitting}
                            htmlType='submit'
                            type='primary'
                            block
                            iconRight={
                                <Icon size='24px' color={COLORS.$white} component={() => <Icon.ArrowForwardIcon />} />
                            }
                        >
                            Proceed
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </StyledWrapper>
    );
});

export default CompanyName;
