import React from 'react';
import { ROUTE_PATHS } from '@constants';

const home = {
    key: ROUTE_PATHS.HOME,
    label: <span data-e2e='header_home_tab'>Home</span>
};

export const MENU_ITEMS = [
    home,
    {
        key: ROUTE_PATHS.PRODUCTS,
        label: <span data-e2e='header_products_tab'>Products</span>
    },
    {
        key: ROUTE_PATHS.CAMPAIGNS,
        label: <span data-e2e='header_campaigns_tab'>Campaigns</span>
    },
    {
        key: ROUTE_PATHS.ORDERS,
        label: <span data-e2e='header_orders_tab'>Orders</span>
    },
    {
        key: ROUTE_PATHS.PERFORMANCE,
        label: <span data-e2e='header_performance_tab'>Performance</span>
    },
    {
        key: ROUTE_PATHS.INSIGHTS,
        label: <span data-e2e='header_insights_tab'>Insights</span>
    },
    {
        key: ROUTE_PATHS.PERKS,
        label: <span data-e2e='header_perks_tab'>Perks</span>
    }
];

export const BLOCKED_MENU_ITEMS = [home];
