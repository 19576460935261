import React from 'react';
import { COLORS } from '@constants';
import { Typography } from '@ui';
import { formatString } from '@utils';
import { StyledNavLink } from './styled';

const MenuItem = ({ to, label, isInner }) => (
    <StyledNavLink to={to} $isInner={isInner}>
        <Typography.Text
            data-e2e={`account-settings_${formatString(label)}_menu-item`}
            type='badgeMedium'
            color={COLORS.$gray60Black}
        >
            {label}
        </Typography.Text>
    </StyledNavLink>
);

export default MenuItem;
