import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from '@constants';
import { useStore } from '@hooks';
import { Loader } from '@ui';
import { observer } from 'mobx-react';

const possibleRoutesWhenBlocked = [
    ROUTE_PATHS.ROOT,
    ROUTE_PATHS.HOME,
    ROUTE_PATHS.PROFILE,
    ROUTE_PATHS.RETURN_TO_DASHBOARD
];

const RequireAuth = observer(({ children }) => {
    const store = useStore();
    const location = useLocation();
    const { getInitialData } = store.authStore;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            await getInitialData();
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <Loader size='120px' />;
    }

    if (!store.authStore.me) {
        return <Navigate to={ROUTE_PATHS.LOGIN} state={{ from: location }} replace />;
    }

    if (!store.authStore.me.currentAccount) {
        return <Navigate to={ROUTE_PATHS.REGISTER} replace />;
    }

    if (!store.authStore.me.currentAccount.companyName && location.pathname !== ROUTE_PATHS.ADD_COMPANY_NAME) {
        return <Navigate to={ROUTE_PATHS.ADD_COMPANY_NAME} replace />;
    }

    if (store.accountsStore.isBlocked && !possibleRoutesWhenBlocked.includes(location.pathname)) {
        return <Navigate to={ROUTE_PATHS.HOME} replace />;
    }

    return children;
});

export default RequireAuth;
