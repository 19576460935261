import { Card } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
    background: transparent;
    border: 0;

    h1.ant-typography {
        margin-top: 0;
    }
`;
