import React from 'react';
import { FIELD_NAME } from '@constants';
import { useStore } from '@hooks';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import ProductsTable from '../ProductsTable';

const RightTable = observer(({ handleRowsSelectionChange, ...props }) => {
    const store = useStore();
    const { EXCLUDED_PRODUCTS } = FIELD_NAME.CAMPAIGN;
    const {
        campaign,
        campaignProducts,
        campaignProductsMeta,
        allCampaignProductsSelected,
        campaignProductsRealTimeTotalPages,
        selectedProductsIds,
        productsSelectionPending,
        getCampaignProducts,
        searchValue
    } = store.campaignWizardStore;

    const onChange = (pagination) => {
        let page;
        if (
            campaignProductsMeta.page === campaignProductsMeta.totalPages - 1 &&
            pagination.current > campaignProductsMeta.page
        ) {
            page = campaignProductsRealTimeTotalPages;
        } else {
            page = pagination.current;
        }
        const params = { page, perPage: pagination.pageSize };
        if (searchValue) params.search = `title:${searchValue}`;
        getCampaignProducts({ campaignId: campaign.id, params });
    };

    return (
        <Form.Item name={EXCLUDED_PRODUCTS} noStyle>
            <ProductsTable
                dataSource={campaignProducts}
                selectedRowKeys={selectedProductsIds}
                onRowsSelectionChange={handleRowsSelectionChange}
                loading={productsSelectionPending}
                isAllSelected={allCampaignProductsSelected}
                pagination={{
                    total: campaignProductsMeta.total,
                    totalPages: campaignProductsMeta.totalPages,
                    pageSize: campaignProductsMeta.perPage,
                    current: campaignProductsMeta.page
                }}
                onChange={onChange}
                {...props}
            />
        </Form.Item>
    );
});

export default RightTable;
