import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { useStore } from '@hooks';
import { Divider, Icon, Progress, Tooltip, Typography } from '@ui';
import { Col, Flex, Row, Skeleton } from 'antd';
import { observer } from 'mobx-react';
import { getCopyByPriorityValue } from '../../../../utils';
import { InfoIcon } from '../InfoIcon';
import { StyledWrapper } from './styled';

const CampaignPriority = observer(() => {
    const store = useStore();
    const { priorityPending, campaignPriority } = store.campaignWizardStore;

    const tooltipTitle = `Your Priority is how likely Klickly's algorithm is to display your products. A low Priority means that
other merchants' products are much more likely to be shown. A high Priority means that your products are much more likely to be shown.`;
    const hintIcon = (
        <Icon
            style={{ paddingBlock: 4 }}
            size='24px'
            stroke='white'
            color={COLORS.$gray40Black}
            component={() => <InfoIcon data-e2e='campaignwizard_priority-tooltip_icon' />}
        />
    );

    const { title, description, hint } = getCopyByPriorityValue(campaignPriority);

    return (
        <StyledWrapper data-e2e='campaignwizard_campaign-priority_preview'>
            <Row gutter={16} align='middle' justify='space-between'>
                <Col>
                    <Typography.Title data-e2e='campaignwizard_campaign-priority_title' level={3}>
                        Campaign Priority
                    </Typography.Title>
                </Col>
                <Col>
                    <Tooltip title={tooltipTitle} trigger='hover'>
                        {hintIcon}
                    </Tooltip>
                </Col>
            </Row>
            <Divider margin='16px 0' />
            <Row justify='center'>
                <Col>
                    <Progress
                        data-e2e='campaignwizard_priority-progress_circle'
                        strokeColor={{ '0%': '#FFF0A3', '33%': '#FFD700', '66%': '#FF41B3', '100%': '#D633FF' }}
                        type='circle'
                        strokeWidth={10}
                        percent={campaignPriority}
                        size={228}
                        style={BOTTOM_INDENTS.S}
                        format={() =>
                            priorityPending ? (
                                <Flex justify='center'>
                                    <Skeleton className='title' paragraph={false} active />
                                </Flex>
                            ) : (
                                <div data-e2e='campaignwizard_priority-progress_value'>{campaignPriority}%</div>
                            )
                        }
                    />

                    {priorityPending ? (
                        <Flex justify='center'>
                            <Skeleton className='subtitle' paragraph={false} active />
                        </Flex>
                    ) : (
                        <Typography.Text
                            data-e2e='campaignwizard_priority-progress_title'
                            align='center'
                            type='badgeMedium'
                        >
                            {title}
                        </Typography.Text>
                    )}
                </Col>
            </Row>
            <Divider margin='16px 0' />
            {priorityPending ? (
                <Flex justify='center' style={{ marginBottom: 40 }}>
                    <Skeleton className='description' title={false} paragraph={{ rows: 3 }} active />
                </Flex>
            ) : (
                <Typography.Text
                    data-e2e='campaignwizard_campaign-priority_description'
                    type='body2'
                    color={COLORS.$gray60Black}
                    style={BOTTOM_INDENTS.M}
                >
                    {description}
                </Typography.Text>
            )}

            {priorityPending ? (
                <Flex justify='center'>
                    <Skeleton className='description' title={false} paragraph={{ rows: 2 }} active />
                </Flex>
            ) : (
                <Typography.Text
                    data-e2e='campaignwizard_campaign-priority_hint'
                    type='body2'
                    color={COLORS.$gray60Black}
                >
                    {hint}
                </Typography.Text>
            )}
        </StyledWrapper>
    );
});

export default CampaignPriority;
