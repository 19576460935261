import React from 'react';
import { Typography } from '@ui';
import { Col, Row } from 'antd';

const CreditCardRate = () => {
    return (
        <Row align='middle' gutter={[8, 8]} style={{ paddingBlock: 16 }}>
            <Col span={12}>
                <Typography.Text data-e2e='account-management_card-rate_block' type='badgeSmall'>
                    Credit card rate
                </Typography.Text>
            </Col>
            <Col span={12}>
                <Typography.Title data-e2e='account-management_card-rate_value' align='end' level={3}>
                    2.9% + 30c
                </Typography.Title>
            </Col>
        </Row>
    );
};

export default CreditCardRate;
