import { COLORS } from '@constants';
import { Input } from 'antd';
import styled, { css } from 'styled-components';

export const StyledInput = styled(Input)`
    && {
        ${({ $readOnly }) =>
            $readOnly &&
            css`
                touch-action: none !important;
                pointer-events: none !important;
            `};
    }

    &.ant-input {
        padding: ${({ $hasLabel }) => ($hasLabel ? '24px 16px 6px' : '15px 16px')};
        border: 1px solid ${COLORS.$gray20Black};
        font-family: IBM Plex Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        &:hover {
            border-color: ${COLORS.$gray20Black};
        }

        &:focus {
            border: 1px solid ${COLORS.$gray60Black};
        }
    }

    & {
        padding: ${({ $hasLabel }) => ($hasLabel ? '24px 16px 6px' : '16px')};
        border: 1px solid ${COLORS.$gray20Black};
        font-family: IBM Plex Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .ant-input {
        line-height: 24px;
        font-family: IBM Plex Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
    }

    &.ant-input::placeholder {
        color: ${({ $hasPlaceholder }) => ($hasPlaceholder ? COLORS.$gray60Black : 'transparent')};
    }

    &:focus {
        border: 1px solid ${COLORS.$gray60Black};
    }

    .ant-input-prefix {
        margin-top: ${({ $hasLabel }) => ($hasLabel ? '-12px' : '0')};
        margin-inline-end: 16px;
        color: ${COLORS.$gray40Black};
    }

    .ant-input-suffix {
        margin-top: ${({ $hasLabel }) => ($hasLabel ? '-16px' : '0')};
        margin-inline-start: 16px;
        color: ${COLORS.$gray40Black};
    }

    .anticon {
        font-size: 24px;
    }

    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
        border-width: 2px;
    }
    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
        border-inline-end-width: 2px;
    }

    &.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
            .ant-input-affix-wrapper-borderless
        ).ant-input-affix-wrapper {
        border-width: 2px;

        .ant-input-prefix,
        .ant-input-suffix {
            color: ${COLORS.$gray40Black};
        }
    }
`;
