import React from 'react';
import { useStore } from '@hooks';
import { observer } from 'mobx-react';
import { columns, columnsInner, paginationConfig } from './constants';
import { StyledTable as Table } from './styled';

const ProductsTable = observer(
    ({ dataSource, selectedRowKeys = [], onRowsSelectionChange, loading, pagination = {}, onChange, ...props }) => {
        const store = useStore(); // eslint-disable-line
        return (
            <div>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={(record) => record.id}
                    rowSelection={{
                        selectedRowKeys: [...selectedRowKeys],
                        onChange: onRowsSelectionChange,
                        getCheckboxProps: (record) => ({
                            'data-e2e': `campaignwizard_product-${record.id}-row_checkbox`
                        })
                    }}
                    onHeaderRow={() => ({
                        'data-e2e': 'campaignwizard_product-table_header'
                    })}
                    loading={loading}
                    pagination={{ ...paginationConfig, ...pagination }}
                    borderColor='transparent'
                    expandPosition='right'
                    className='product-table'
                    data-e2e='campaignwizard_products_table'
                    expandable={{
                        expandedRowRender: (record) => (
                            <Table
                                dataSource={record?.variants || []}
                                columns={columnsInner}
                                rowKey={(record) => record.id}
                                pagination={false}
                                borderColor='transparent'
                            />
                        ),
                        rowExpandable: (record) => record.name !== 'Not Expandable'
                    }}
                    onChange={onChange}
                    {...props}
                />
            </div>
        );
    }
);

export default ProductsTable;
