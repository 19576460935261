export const INDENTS = {
    XXXXS: '2px',
    XXXS: '4px',
    XXS: '8px',
    XS: '12px',
    S: '16px',
    SM: '20px',
    M: '24px',
    L: '32px',
    XL: '40px',
    XL_PLUS: '48px',
    XXL: '64px'
};

export const TOP_INDENTS = {
    XXXXS: { marginTop: INDENTS.XXXXS },
    XXXS: { marginTop: INDENTS.XXXS },
    XXS: { marginTop: INDENTS.XXS },
    XS: { marginTop: INDENTS.XS },
    S: { marginTop: INDENTS.S },
    SM: { marginTop: INDENTS.SM },
    M: { marginTop: INDENTS.M },
    L: { marginTop: INDENTS.L },
    XL: { marginTop: INDENTS.XL },
    XL_PLUS: { marginTop: INDENTS.XL_PLUS },
    XXL: { marginTop: INDENTS.XXL }
};

export const RIGHT_INDENTS = {
    XXXXS: { marginRight: INDENTS.XXXXS },
    XXXS: { marginRight: INDENTS.XXXS },
    XXS: { marginRight: INDENTS.XXS },
    XS: { marginRight: INDENTS.XS },
    S: { marginRight: INDENTS.S },
    SM: { marginRight: INDENTS.SM },
    M: { marginRight: INDENTS.M },
    L: { marginRight: INDENTS.L },
    XL: { marginRight: INDENTS.XL },
    XL_PLUS: { marginRight: INDENTS.XL_PLUS },
    XXL: { marginRight: INDENTS.XXL }
};

export const BOTTOM_INDENTS = {
    XXXXS: { marginBottom: INDENTS.XXXXS },
    XXXS: { marginBottom: INDENTS.XXXS },
    XXS: { marginBottom: INDENTS.XXS },
    XS: { marginBottom: INDENTS.XS },
    S: { marginBottom: INDENTS.S },
    SM: { marginBottom: INDENTS.SM },
    M: { marginBottom: INDENTS.M },
    L: { marginBottom: INDENTS.L },
    XL: { marginBottom: INDENTS.XL },
    XL_PLUS: { marginBottom: INDENTS.XL_PLUS },
    XXL: { marginBottom: INDENTS.XXL }
};

export const LEFT_INDENTS = {
    XXXXS: { marginLeft: INDENTS.XXXXS },
    XXXS: { marginLeft: INDENTS.XXXS },
    XXS: { marginLeft: INDENTS.XXS },
    XS: { marginLeft: INDENTS.XS },
    S: { marginLeft: INDENTS.S },
    SM: { marginLeft: INDENTS.SM },
    M: { marginLeft: INDENTS.M },
    L: { marginLeft: INDENTS.L },
    XL: { marginLeft: INDENTS.XL },
    XL_PLUS: { marginLeft: INDENTS.XL_PLUS },
    XXL: { marginLeft: INDENTS.XXL }
};
