import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import { Icon } from '../../../Icon';
import { StyledButton } from './styled';
import { getButtonType } from './utils';

// size
const BaseButton = ({
    type, // ghost | noStyle | primary | secondary
    justify,
    icon,
    iconRight,
    children,
    color,
    padding,
    fontSize,
    fontWeight,
    hoverColor,
    backgroundColor,
    loading,
    onClick,
    ...props
}) => {
    // color, padding, fontSize, fontWeight, hoverColor is applicable only for type='link'
    const modyfiedOnClick = loading ? undefined : onClick;

    return (
        <StyledButton
            $type={type}
            $justify={justify}
            type={getButtonType(type)}
            $color={color}
            $padding={padding}
            $fontSize={fontSize}
            $fontWeight={fontWeight}
            $hoverColor={hoverColor}
            $backgroundColor={backgroundColor}
            onClick={modyfiedOnClick}
            $loading={loading}
            {...props}
        >
            <Flex justify='center' align='center' style={{ width: '100%', position: 'relative' }}>
                {loading && (
                    <Flex
                        justify='center'
                        align='center'
                        style={{ width: '100%', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                    >
                        <LoadingOutlined style={{ fontSize: '24px' }} />
                    </Flex>
                )}
                <Flex justify='center' align='center' gap={8} style={{ width: '100%', opacity: loading ? 0 : 1 }}>
                    {icon && <Icon style={{ flexGrow: 0 }} component={() => icon} />}
                    <div className='button-text' style={{ flexGrow: 1 }}>
                        {children}
                    </div>
                    {iconRight && <Icon style={{ flexGrow: 0 }} component={() => iconRight} />}
                </Flex>
            </Flex>
        </StyledButton>
    );
};

export default BaseButton;
