import { COLORS } from '@constants';
import styled from 'styled-components';

export const NotSelectedRadioStyles = styled.label`
    height: 100%;
    display: block;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid ${COLORS.$gray15Black};
    background: ${COLORS.$white};
    cursor: pointer;

    &:has(input:checked) {
        padding: 23px;
        border: 2px solid ${COLORS.$pink};

        h3 {
            color: ${COLORS.$pink};
        }
    }

    label {
        display: none;
    }

    &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 8px 48px 0 rgba(0, 0, 0, 0.16);
        cursor: pointer;
    }
`;

export const SelectedRadioStyles = styled.label`
    height: 100%;
    display: block;
    padding: 24px;
    border-radius: 8px;
    border: 2px solid ${COLORS.$pink};
    padding: 23px;
    background: ${COLORS.$white};
    cursor: pointer;

    &:has(input:checked) {
        padding: 24px;
        border: 1px solid ${COLORS.$gray15Black};

        h3 {
            color: ${COLORS.$gray100Black};
        }
    }

    &:not(:has(input:checked)) {
        h3 {
            color: ${COLORS.$pink};
        }
    }

    label {
        display: none;
    }

    &:hover {
        box-shadow: none;
        cursor: pointer;
    }
`;
