import React, { useRef } from 'react';
import { SearchSuffix } from '@components';
import { BOTTOM_INDENTS } from '@constants';
import { useDebounce } from '@hooks';
import { Input, Typography } from '@ui';
import { Col, Row } from 'antd';

const Header = ({ showSearch, setSearchValue }) => {
    const ref = useRef(null);

    const handleSearch = useDebounce((e) => {
        setSearchValue(e.target.value);
    }, 1000);

    return (
        <Row justify='space-between' align='middle' style={BOTTOM_INDENTS.XL_PLUS}>
            <Col>
                <Typography.Text data-e2e='products_page_title' type='em0'>
                    Products
                </Typography.Text>
            </Col>
            {showSearch ? (
                <Col data-e2e='products_search_bar' flex='526px'>
                    <Input
                        data-e2e='products_search_input'
                        ref={ref}
                        value={ref?.current?.value}
                        label='Search products'
                        onChange={handleSearch}
                        suffix={<SearchSuffix inputRef={ref} onSetSearchValue={setSearchValue} />}
                    />
                </Col>
            ) : null}
        </Row>
    );
};

export default Header;
