import React from 'react';
import { ROUTE_PATHS } from '@constants';
import { Icon } from '@ui';
import { StyledGetStartedButton } from './styled';

const GetStartedButton = () => {
    return (
        <StyledGetStartedButton data-e2e='landing_get-started_btn' to={ROUTE_PATHS.REQUEST_ACCESS}>
            <Icon size='16px' component={() => <Icon.ArrowChevronRightIcon />} />
            Get started
        </StyledGetStartedButton>
    );
};

export default GetStartedButton;
