import styled from 'styled-components';

export const StyledPartners = styled.div`
    margin-top: 118px;
    margin-bottom: 128px;

    @media (max-width: 900px) {
        margin-top: 0;
        margin-bottom: 96px;
    }

    .partners-section__wrapper {
        h3 {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;
            color: #04080f;
            font-family: 'IBM Plex Sans', Helvetica, sans-serif;
        }

        &__items {
            margin-top: 48px;
            margin-bottom: 48px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 48px;
            flex-wrap: wrap;

            @media (max-width: 900px) {
                margin-bottom: 0;
                justify-content: center;
            }
        }
    }
`;
