import React, { useState } from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { ACCOUNT_USER_ROLE } from '@enums';
import { useStore } from '@hooks';
import { Button, Input, Select, Tag, Typography } from '@ui';
import { dayjs } from '@utils';
import { Col, Flex, Form, Row } from 'antd';
import { observer } from 'mobx-react';
import { ACCOUNT_USER_ROLE_LABELS, ACCOUNT_USER_STATUS_LABELS } from './constants';
import { StyledCard } from './styled';

const getInitials = (firstName = '', lastName = '') => `${firstName.charAt(0)}${lastName.charAt(0)}`;

const getFullName = (firstName = '', lastName = '') => `${firstName} ${lastName}`;

const TeamMember = observer(({ member, onDelete }) => {
    const store = useStore();
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [form] = Form.useForm();

    const onEditOpen = () => {
        setIsEditOpen(true);
    };

    const onEditClose = () => {
        form.resetFields();
        setIsEditOpen(false);
    };

    const onFinish = ({ role, ...user }) => {
        member.update({ role, user });
        setIsEditOpen(false);
    };

    const initials = getInitials(member.user.firstName, member.user.lastName);
    const fullName = getFullName(member.user.firstName, member.user.lastName);
    const showEditButton = !isEditOpen && member.role !== ACCOUNT_USER_ROLE.OWNER;
    const showDeleteButton = member.role !== ACCOUNT_USER_ROLE.OWNER && store.authStore.me.email !== member.user.email;

    return (
        <StyledCard data-e2e={`account-management_member-${member.id}_card`} $edit={isEditOpen}>
            <Flex justify='space-between' align='center' style={{ marginBottom: isEditOpen ? 16 : 0 }}>
                <Flex gap={24}>
                    <Flex
                        justify='center'
                        align='center'
                        style={{ width: 64, height: 64, backgroundColor: '#D9D9D9', borderRadius: 1000 }}
                    >
                        <Typography.Title color={COLORS.$white} level={3}>
                            {initials}
                        </Typography.Title>
                    </Flex>
                    <Flex vertical gap={16} justify='space-between'>
                        <Typography.Text
                            data-e2e={`account-management_member-${member.id}-name_value`}
                            type='badgeMedium'
                        >
                            {fullName}
                        </Typography.Text>
                        <Typography.Text
                            data-e2e={`account-management_member-${member.id}-login-data_value`}
                            type='body2'
                            color={COLORS.$gray60Black}
                            style={{ alignSelf: 'end' }}
                        >
                            Last login was {dayjs(member.lastLoginAt).format('dddd, MMMM D, YYYY, h:mm a')}
                        </Typography.Text>
                    </Flex>
                </Flex>
                <Flex vertical gap={16}>
                    <Flex gap={16}>
                        {showEditButton && (
                            <Button
                                data-e2e='account-management_edit-team-member_btn'
                                type='link'
                                onClick={onEditOpen}
                                fontSize='14px'
                                padding={0}
                            >
                                Edit
                            </Button>
                        )}
                        {showDeleteButton && (
                            <Button
                                data-e2e='account-management_delete-team-member_btn'
                                type='link'
                                onClick={onDelete}
                                fontSize='14px'
                                padding={0}
                            >
                                Delete
                            </Button>
                        )}
                        <Tag
                            data-e2e={`account-management_member-${member.id}-status_value`}
                            color={ACCOUNT_USER_STATUS_LABELS[member.status].color}
                        >
                            {ACCOUNT_USER_STATUS_LABELS[member.status].title}
                        </Tag>
                    </Flex>
                    <Flex justify='end'>
                        <Typography.Text
                            data-e2e={`account-management_member-${member.id}-role_value`}
                            type='badgeMedium'
                        >
                            {ACCOUNT_USER_ROLE_LABELS[member.role].title}
                        </Typography.Text>
                    </Flex>
                </Flex>
            </Flex>
            {isEditOpen && (
                <Form form={form} onFinish={onFinish}>
                    <Row gutter={[16, 16]} style={BOTTOM_INDENTS.S}>
                        <Col span={12}>
                            <Form.Item name='firstName' initialValue={member.user.firstName}>
                                <Input
                                    data-e2e={`account-management_edit-member-${member.id}-first-name_input`}
                                    label='First Name'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='lastName' initialValue={member.user.lastName}>
                                <Input
                                    data-e2e={`account-management_edit-member-${member.id}-last-name_input`}
                                    label='Last Name'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                rules={[
                                    { required: true, message: 'Email is required' },
                                    { pattern: /^\S+@\S+\.\S+$/, message: 'Enter please a valid email' }
                                ]}
                                name='email'
                                initialValue={member.user.email}
                            >
                                <Input
                                    data-e2e={`account-management_edit-member-${member.id}-email_input`}
                                    label='Email address'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='role' initialValue={member.role}>
                                {member.role === ACCOUNT_USER_ROLE.OWNER ? (
                                    <Select
                                        data-e2e={`account-management_edit-member-${member.id}-role_dropdown`}
                                        disabled
                                        options={[
                                            {
                                                value: ACCOUNT_USER_ROLE.OWNER,
                                                label: (
                                                    <span data-e2e='account-management_select-admin_option'>
                                                        {ACCOUNT_USER_ROLE_LABELS[ACCOUNT_USER_ROLE.OWNER].title}
                                                    </span>
                                                )
                                            }
                                        ]}
                                    />
                                ) : (
                                    <Select
                                        data-e2e={`account-management_edit-member-${member.id}-role_dropdown`}
                                        options={[
                                            {
                                                value: ACCOUNT_USER_ROLE.ADMIN,
                                                label: (
                                                    <span data-e2e='account-management_select-admin_option'>
                                                        {ACCOUNT_USER_ROLE_LABELS[ACCOUNT_USER_ROLE.ADMIN].title}
                                                    </span>
                                                )
                                            },
                                            {
                                                value: ACCOUNT_USER_ROLE.MEMBER,
                                                label: (
                                                    <span data-e2e='account-management_select-member_option'>
                                                        {ACCOUNT_USER_ROLE_LABELS[ACCOUNT_USER_ROLE.MEMBER].title}
                                                    </span>
                                                )
                                            }
                                        ]}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='end'>
                        <Flex gap={16}>
                            <Button
                                data-e2e='account-management_cancel_btn'
                                onClick={onEditClose}
                                style={{ minWidth: 160 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                data-e2e='account-management_save_btn'
                                htmlType='submit'
                                style={{ minWidth: 160 }}
                                type='primary'
                            >
                                Save
                            </Button>
                        </Flex>
                    </Flex>
                </Form>
            )}
        </StyledCard>
    );
});
export default TeamMember;
