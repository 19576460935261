import React, { useEffect, useState } from 'react';
import { COLORS, defaultTimeRange, LEFT_INDENTS, orderMap, ROUTE_PATHS, TOP_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Button, Icon, RangePicker, Table, Typography } from '@ui';
import { camelToSnakeCase } from '@utils';
import { Col, Flex, Row } from 'antd';
import fileDownload from 'js-file-download';
import { observer } from 'mobx-react';
import { columns, columnsInner } from './constants';
import { StyledCard } from './styled';
import { calculateItemsFullTotalAmount, getOffsetBalance } from './utils';

const Payouts = observer(() => {
    const store = useStore();
    const [loading, setLoading] = useState(true);
    const { payouts, payoutsMeta, getInvoices, updatePayoutsFilters, downloadInvoices, updatePayoutsSorters } =
        store.invoicesStore;
    const { commissionOffsetEnabled } = store.accountsStore;
    const offsetBalance = getOffsetBalance(payouts);
    const calculatePayoutsPrices = calculateItemsFullTotalAmount(payouts);

    useEffect(() => {
        (async () => {
            await getInvoices('payouts');
            setLoading(false);
        })();
    }, []);

    const onDownload = async () => {
        const { file, fileName } = await downloadInvoices({ transaction_type: 'payouts' });
        fileDownload(file, fileName);
    };

    const onTableChange = async (pagination, filters, sorter) => {
        setLoading(true);

        const params = { page: pagination.current, perPage: pagination.pageSize };

        updatePayoutsSorters({
            sort: sorter.order ? camelToSnakeCase(sorter.field) : undefined,
            order: sorter.order ? orderMap[sorter.order] : undefined
        });

        // TODO when filters will be added
        // updateFilters({
        //     klickly_status: filters.klicklyStatus || undefined,
        //     financial_status: filters.financialStatus || undefined,
        //     fulfillment_status: filters.fulfillmentStatus || undefined
        // });

        await getInvoices('payouts', params);

        setLoading(false);
    };

    const onDateChange = async ({ min, max }) => {
        setLoading(true);
        updatePayoutsFilters({
            created_at_min: min,
            created_at_max: max
        });
        await getInvoices('payouts');
        setLoading(false);
    };

    return (
        <React.Fragment>
            <StyledCard>
                <Flex vertical align='center' gap={10}>
                    <Typography.Title data-e2e='invoices_payouts-remaining-balance_title' level={3}>
                        Remaining Balance
                    </Typography.Title>
                    <Typography.Title data-e2e='invoices_payouts-remaining-balance_value' level={1}>
                        {offsetBalance}
                    </Typography.Title>
                    {commissionOffsetEnabled ? (
                        <Typography.Text
                            data-e2e='invoices_payouts-commission-offset_description'
                            color={COLORS.$gray40Black}
                            align='center'
                            justify='center'
                            type='body2'
                        >
                            Your Balance is being used to offset your Klickly marketing invoices (per your instructions{' '}
                            <a data-e2e='invoices_payouts-payments-settings_link' href={ROUTE_PATHS.PAYMENTS_SETTINGS}>
                                here
                            </a>
                            ).
                        </Typography.Text>
                    ) : (
                        <Typography.Text
                            data-e2e='invoices_payouts-direct-payout_description'
                            color={COLORS.$gray40Black}
                            align='center'
                            justify='center'
                            type='body2'
                        >
                            We allow consumers to buy directly within Klickly’s Commerce Modules. When they do, we
                            collect the funds to send you. Please tell us how you’d like to receive these funds{' '}
                            <a data-e2e='invoices_payouts-payments-settings_link' href={ROUTE_PATHS.PAYMENTS_SETTINGS}>
                                here
                            </a>
                            .
                        </Typography.Text>
                    )}
                </Flex>
            </StyledCard>
            <Row justify='space-between' style={TOP_INDENTS.S}>
                <Col data-e2e='invoices_payouts-datepicker'>
                    <RangePicker allowClear={false} defaultValue={defaultTimeRange} onChange={onDateChange} />
                </Col>
                <Col>
                    <Button
                        data-e2e='invoices_payouts-download-csv_btn'
                        onClick={onDownload}
                        style={LEFT_INDENTS.M}
                        icon={<Icon size='24px' color={COLORS.$gray60Black} component={() => <Icon.DownloadIcon />} />}
                    >
                        Download CSV
                    </Button>
                </Col>
            </Row>
            <Table
                data-e2e='invoices_payouts_table'
                loading={loading}
                dataSource={calculatePayoutsPrices}
                columns={columns}
                rowKey={(record) => record.id}
                pagination={{
                    total: payoutsMeta?.total,
                    totalPages: payoutsMeta?.totalPages,
                    pageSize: payoutsMeta?.perPage,
                    current: payoutsMeta?.page
                }}
                onChange={onTableChange}
                expandable={{
                    expandedRowRender: (record) => {
                        if (!record) return null;

                        const dataSource = record.items.reduce((acc, item) => {
                            acc = [...acc, item];

                            if (item.offsets) {
                                item.offsets.forEach((element) => {
                                    acc = [
                                        ...acc,
                                        {
                                            showDetails: true,
                                            sequenceNumber: element.sequenceNumber,
                                            invoiceId: element.invoice?.id,
                                            amount: element.amount
                                        }
                                    ];
                                });

                                return acc;
                            }
                            return acc;
                        }, []);

                        return (
                            <Table
                                rowKey={(r) => r.id}
                                borderColor='transparent'
                                columns={columnsInner}
                                dataSource={dataSource}
                                pagination={false}
                            />
                        );
                    }
                }}
                style={TOP_INDENTS.M}
            />
        </React.Fragment>
    );
});

export default Payouts;
