import React from 'react';
import { useModal } from '@hooks';
import ChangeEmailForm from '../components/ProfileForm/components/ChangeEmailForm';

export const useChangeEmailModal = ({ profileForm }) => {
    const { open, close } = useModal();

    const showChangeEmailModal = () => {
        open({
            title: <span data-e2e='profile_change-email-modal_title'>Change email</span>,
            content: <ChangeEmailForm onClose={close} profileForm={profileForm} />,
            settings: { maskClosable: false }
        });
    };

    return { showChangeEmailModal, close };
};
