import { Radio as AntdRadio } from 'antd';
import BaseRadio from './components/BaseRadio';
import RadioWithLabel from './components/RadioWithLabel';

const Radio = BaseRadio;

Radio.Group = AntdRadio.Group;
Radio.WithLabel = RadioWithLabel;

export { Radio };
