import { TRACK_EVENT } from '@enums';
import { FIELD_NAMES } from '../components/constants';

export const mapSettingsToForm = ({ utmSettings, ga4Settings, tripleWhaleSettings }) => {
    return {
        [FIELD_NAMES.CUSTOMIZE_UTM]: utmSettings.enabled,
        [FIELD_NAMES.CUSTOMIZE_UTM_SOURCE]: utmSettings.source,
        [FIELD_NAMES.CUSTOMIZE_UTM_MEDIUM]: utmSettings.medium,
        [FIELD_NAMES.GOOGLE_ANALYTICS]: ga4Settings.enabled,
        [FIELD_NAMES.GOOGLE_ANALYTICS_MEASUREMENT_ID]: ga4Settings.measurementId,
        [FIELD_NAMES.GOOGLE_ANALYTICS_VIEWS]: ga4Settings.eventsToTrack.includes(TRACK_EVENT.VIEWS),
        [FIELD_NAMES.GOOGLE_ANALYTICS_CLICKS]: ga4Settings.eventsToTrack.includes(TRACK_EVENT.CLICKS),
        [FIELD_NAMES.GOOGLE_ANALYTICS_ADD_TO_CART]: ga4Settings.eventsToTrack.includes(TRACK_EVENT.ADD_TO_CARTS),
        [FIELD_NAMES.GOOGLE_ANALYTICS_PURCHASES]: ga4Settings.eventsToTrack.includes(TRACK_EVENT.PURCHASES),
        [FIELD_NAMES.GOOGLE_ANALYTICS_NON_LINKED_VIEWS]: ga4Settings.includeNonLinkedViews,
        [FIELD_NAMES.TRIPLE_WHALE]: tripleWhaleSettings.enabled,
        [FIELD_NAMES.TRIPLE_WHALE_VIEWS]: tripleWhaleSettings.eventsToTrack.includes(TRACK_EVENT.VIEWS),
        [FIELD_NAMES.TRIPLE_WHALE_CLICKS]: tripleWhaleSettings.eventsToTrack.includes(TRACK_EVENT.CLICKS),
        [FIELD_NAMES.TRIPLE_WHALE_ADD_TO_CART]: tripleWhaleSettings.eventsToTrack.includes(TRACK_EVENT.ADD_TO_CARTS),
        [FIELD_NAMES.TRIPLE_WHALE_PURCHASES]: tripleWhaleSettings.eventsToTrack.includes(TRACK_EVENT.PURCHASES),
        [FIELD_NAMES.TRIPLE_WHALE_NON_LINKED_VIEWS]: tripleWhaleSettings.includeNonLinkedViews
    };
};
