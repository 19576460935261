import React from 'react';
import { Typography } from '@ui';
import { formatString } from '@utils';
import { StyledWrapper } from './styled';

const MenuItem = ({ title = '', active, onClick }) => {
    return (
        <StyledWrapper $active={active} onClick={onClick}>
            <Typography.Title data-e2e={`performance_${formatString(title)}_menu-item`} level={3} align='center'>
                {title}
            </Typography.Title>
        </StyledWrapper>
    );
};

export default MenuItem;
