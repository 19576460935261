import React from 'react';
import { useStore } from '@hooks';
import { Flex } from 'antd';
import MenuItem from './components/MenuItem';
import { getMenuItems } from './constants';
import StyledMenuWrapper from './styled';

const renderMenuItems = (items, nestingLevel = 1) => {
    return items.map(({ key, label, to, children }) => (
        <Flex vertical key={key}>
            <MenuItem label={label} to={to} />

            {children && children.length > 0 && (
                <Flex vertical className={`level-${nestingLevel}`}>
                    {renderMenuItems(children, nestingLevel + 1)}
                </Flex>
            )}
        </Flex>
    ));
};

const Menu = () => {
    const store = useStore();
    const {
        setupInfo: { showPayments }
    } = store.accountsStore;

    return (
        <StyledMenuWrapper data-e2e='account-settings_menu' vertical gap={16}>
            {renderMenuItems(getMenuItems(showPayments))}
        </StyledMenuWrapper>
    );
};

export default Menu;
