import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '@constants';
import { CAMPAIGN_SUBMIT_ERROR } from '@enums';
import { useStore } from '@hooks';
import { notification } from 'antd';

export const useOnLaunch = () => {
    const navigate = useNavigate();
    const store = useStore();
    const { setFormSubmitting, submitCampaign } = store.campaignWizardStore;

    const onLaunch = async () => {
        setFormSubmitting(true);
        const data = await submitCampaign();
        setFormSubmitting(false);

        if (data.ready && !data.notConfirmed?.length) {
            navigate(ROUTE_PATHS.CAMPAIGNS);
        } else {
            Object.values(data.notConfirmed).map((error) => {
                notification.error({
                    message: 'Can not activate campaign',
                    description: CAMPAIGN_SUBMIT_ERROR[error.name],
                    duration: 0
                });
            });
        }
    };

    return onLaunch;
};
