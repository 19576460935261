import React, { useCallback, useState } from 'react';
import { BOTTOM_INDENTS, COLORS, FIELD_NAME, SUGGESTED_ROS_COMMISSION } from '@constants';
import { useStore } from '@hooks';
import { Slider, Typography, WhiteCover } from '@ui';
import { Flex, Form } from 'antd';
import _ from 'lodash';
import { CommissionInput } from '../CommissionInput';

const RestOfSiteCommission = ({ instantOnInputChange, debouncedOnInputChange }) => {
    const store = useStore();
    const { currentAccount } = store.accountsStore;
    const form = Form.useFormInstance();

    const [isTouched, setIsTouched] = useState(false);

    const suggestedPercent =
        !isTouched && currentAccount.commission.restOfSite <= SUGGESTED_ROS_COMMISSION
            ? SUGGESTED_ROS_COMMISSION
            : undefined;

    const debouncedOnChange = useCallback(
        _.debounce((value) => {
            if (value < 0) {
                form.setFieldValue(FIELD_NAME.ACCOUNT.REST_OF_SITE_COMMISSION, 1);
            }

            if (value > 100) {
                form.setFieldValue(FIELD_NAME.ACCOUNT.REST_OF_SITE_COMMISSION, 100);
            }

            debouncedOnInputChange();
        }, 500),
        []
    );

    const onChange = (value) => {
        setIsTouched(true);
        instantOnInputChange();
        debouncedOnChange(value);
    };

    return (
        <WhiteCover
            data-e2e='campaignwizard_ros_block'
            title='Rest-of-site commission'
            style={{ ...BOTTOM_INDENTS.M, paddingBottom: 40 }}
        >
            <Typography.Text
                data-e2e='campaignwizard_ros_description'
                type='body2'
                style={{ marginBottom: 60 }}
                color={COLORS.$gray60Black}
            >
                Your rest-of-site commission is how you incentivize Klickly to advertise and drive traffic to ALL other
                products in your store, not just those in your campaigns. (Note: this commission will only apply when a
                product is not in a campaign)
            </Typography.Text>
            <Flex gap={24} style={{ paddingRight: 13 }}>
                <div style={{ flex: 1 }}>
                    <Form.Item name={FIELD_NAME.ACCOUNT.REST_OF_SITE_COMMISSION} noStyle>
                        <Slider
                            marks={{ 0: '0%', 25: '25%', 50: '50%', 75: '75%', 100: '100%' }}
                            suggestedPercent={suggestedPercent}
                            min={0}
                            percentOffset={1}
                            onChange={onChange}
                        />
                    </Form.Item>
                </div>

                <Form.Item name={FIELD_NAME.ACCOUNT.REST_OF_SITE_COMMISSION} noStyle>
                    <CommissionInput
                        data-e2e='campaignwizard_ros_input'
                        onChange={onChange}
                        suffix={<Typography.Text type='badgeLarge'>%</Typography.Text>}
                    />
                </Form.Item>
            </Flex>
        </WhiteCover>
    );
};

export default RestOfSiteCommission;
