import { COLORS } from '@constants';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;

        box-sizing: border-box;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        -webkit-tap-highlight-color: transparent;
        -webkit-backface-visibility: hidden;
    }

    html,
    body,
    #root {
        position: relative;
        overflow: hidden;
        min-width: 320px;
        height: 100%;
    }

    html,
    body {
        overflow: hidden;
    }

    #root {
        overflow-y: scroll;
    }

    body {
        margin: 0;
        font-family: 'IBM Plex Sans', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #F4F4F4;
        font-size: 14px;
    }

    a {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: ${COLORS.$info};
        text-decoration: none;
        color: inherit;

        &:hover {
            color: ${COLORS.$info};
            text-decoration: underline;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        &.ant-typography {
            margin-bottom: 0;
        }
    }

    /* Checkbox */

    /* Checkbox underlay */

    /* Not checked, not disabled checkbox - underlay */
    .ant-checkbox .ant-checkbox-inner {}
    /* Hover */
    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
    .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
        transition: none;
        border-color: ${COLORS.$pink};
        border-width: 2px;
    }

    /* Checked, not disabled checkbox - underlay */
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${COLORS.$pink};
        border: 2px solid  ${COLORS.$pink};
    }
    /* Hover */
    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
        background-color: ${COLORS.$pink};
        border: 2px solid  ${COLORS.$gray60Black};
    }

    /* Disabled */
    .ant-checkbox-disabled .ant-checkbox-inner {
        border-color: ${COLORS.$gray20Black};
    }
    .ant-checkbox-disabled:has(input:checked) .ant-checkbox-inner {
        background-color: ${COLORS.$gray20Black};
    }

    /* Checkmark */
    .ant-checkbox .ant-checkbox-inner:after {
        top: 45%;
    }

    .ant-checkbox:not(.ant-checkbox-indeterminate) .ant-checkbox-inner:after {
        inset-inline-start: 22.5%;
    }

    .ant-checkbox-checked:has(input[aria-checked="mixed"]) .ant-checkbox-inner {
        background-color: ${COLORS.$white};
        &:after {
            transform: rotate(0deg) scale(1) translate(-50%, -50%);

        }
    }
    .ant-checkbox-wrapper .ant-checkbox + span {
        color: ${COLORS.$gray100Black};
        font-size: 16px;
        padding-inline-start: 16px;
    }

    /* Radio */
    .ant-radio-group {
        width: 100%;
    }

    /* Switch */
    .ant-switch .ant-switch-handle::before {
        box-shadow: none;
        background-color: ${COLORS.$gray60Black};

    }
    .ant-switch .ant-switch-inner {
        border: 2px solid ${COLORS.$gray20Black};
    }
    .ant-switch.ant-switch-checked {
        .ant-switch-inner {
            border: 2px solid ${COLORS.$pink};
        }
        .ant-switch-handle::before {
            background-color: ${COLORS.$white};
        }
    }
    .ant-switch .ant-switch-handle {
        width: 20px;
        height: 20px;
        border: 2px solid transparent;
        border-radius: 50%;
        top: 4px;
    }
    .ant-switch:hover:not(.ant-switch-disabled):not(.ant-switch-checked) {
        .ant-switch-handle::before {
            transition: none;
            background-color: ${COLORS.$pink};

        }
        .ant-switch-inner {
            border: 2px solid ${COLORS.$gray40Black};
        }
    }
    .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
        .ant-switch-handle {
            border-color: ${COLORS.$gray40Black};
        }
    }
    .ant-switch.ant-switch-loading, .ant-switch.ant-switch-disabled {
        background: ${COLORS.$white};
        .ant-switch-inner {
            border: 2px solid ${COLORS.$gray20Black};
        }
        .ant-switch-handle::before {
            background-color: ${COLORS.$gray20Black};

        }
    }

    /* Badge */
    .ant-badge .ant-badge-count {
        font-weight: 500;
    }

    /* Form */
    .ant-form-item-with-help .ant-form-item-explain {
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    /* Input */

    .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
        border-width: 2px;
    }

    /* Select */
    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
        border: 1px solid ${COLORS.$gray60Black} !important;
    }

    .ant-input-affix-wrapper:has(input:focus),
    .ant-input-affix-wrapper:focus-within {
        border-color: ${COLORS.$gray60Black}
    }

    /* Menu */
    .ant-menu-horizontal .ant-menu-item {
        padding-inline: 12px;
    }

    .ant-menu-light.ant-menu-horizontal >.ant-menu-item::after {
        inset-inline: 12px;
    }

    /* Date picker */
    .ant-picker {
        border: 2px solid ${COLORS.$gray20Black};
    }

    .ant-picker:hover {
        border: 2px solid ${COLORS.$gray20Black};
    }

    .ant-picker-focused.ant-picker {
        border: 2px solid ${COLORS.$gray60Black};
    }

    .ant-picker-focused.ant-picker:hover {
        border: 2px solid ${COLORS.$gray60Black};
    }

    .ant-input-affix-wrapper:hover {
        border-color: ${COLORS.$gray20Black};
    }

    /* Tabs */
    .ant-tabs .ant-tabs-nav-list {
        width: 99.9%; // we don't do 100% because of antd tab flickering issue https://github.com/ant-design/ant-design/issues/43541
    }

    /* Notification */
    .ant-notification .ant-notification-notice-wrapper .ant-notification-notice-icon-error.anticon {
        color: ${COLORS.$error};
    }

    .ant-notification .ant-notification-notice-wrapper .ant-notification-notice-icon-success.anticon {
        color: ${COLORS.$robinBlue};
    }

    .ant-notification .ant-notification-notice-wrapper .ant-notification-notice-icon-info.anticon {
        color: ${COLORS.$cornflowerBlue};
    }

    .ant-tooltip .ant-tooltip-inner {
        padding: 16px 24px;
        border-radius: 8px;
    }
`;
