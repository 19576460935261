import { COLORS } from '@constants';
import { Row } from 'antd';
import styled from 'styled-components';

export const StyledStoreUrl = styled(Row)`
    margin-bottom: 24px;
    padding: 16px;
    border: 1px solid ${COLORS.$gray20Black};
    border-radius: 8px;
`;
