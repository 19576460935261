import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Divider, Typography } from '@ui';
import { Card, Col, Form, Row, Switch } from 'antd';
import { FIELD_NAMES } from '../constants';
import Settings from './components/Settings';

const TWSettings = ({ style }) => {
    const form = Form.useFormInstance();
    const isDropDownSettingsVisible = Form.useWatch(FIELD_NAMES.TRIPLE_WHALE, form);

    return (
        <Card data-e2e='tracking_enable-tw_section' style={style}>
            <Row justify='space-between' align='middle'>
                <Col>
                    <Typography.Title data-e2e='tracking_enable-tw_title' level={2}>
                        Enable Triple Whale
                    </Typography.Title>
                </Col>
                <Col>
                    <Form.Item name={FIELD_NAMES.TRIPLE_WHALE} valuePropName='checked'>
                        <Switch data-e2e='tracking_enable-tw_toggle' />
                    </Form.Item>
                </Col>
            </Row>
            <Divider margin='24px 0' />
            <Typography.Text
                data-e2e='tracking_enable-tw_description'
                type='body2'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.M}
            >
                Track Klickly events in your existing TW dashboard.
            </Typography.Text>
            {isDropDownSettingsVisible && <Settings />}
        </Card>
    );
};

export default TWSettings;
