import BaseInput from './components/BaseInput';
import InputDate from './components/InputDate';
import InputNumber from './components/InputNumber';
import InputPassword from './components/InputPassword';
import InputPhone from './components/InputPhone';
import InputSearch from './components/InputSearch';
import InputSsn from './components/InputSsn';
import TextArea from './components/TextArea';

const Input = BaseInput;
Input.Password = InputPassword;
Input.Search = InputSearch;
Input.Number = InputNumber;
Input.TextArea = TextArea;
Input.Phone = InputPhone;
Input.Ssn = InputSsn;
Input.Date = InputDate;

export { Input };
