import * as get from 'lodash/get';
import * as isEmptyObj from 'lodash/isEmpty';
import { flow, getParent, types } from 'mobx-state-tree';
import { brandsOpenApi } from '../constants';
import { axiosBoa, getErrorMessage } from '../utils';

export const InsightsStore = types
    .model('InsightsStore', {
        pending: false,
        error: types.maybeNull(types.model({ title: types.string, description: types.string })),
        usMapData: types.maybeNull(types.frozen()),
        data: types.maybeNull(types.frozen())
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get isEmpty() {
            return isEmptyObj(self.data) || self.error;
        },
        get demographicsChartsData() {
            return get(this.data, 'demographics', {});
        },
        get financialChartsData() {
            return get(this.data, 'financial', {});
        },
        get purchaseChartsData() {
            return get(this.data, 'purchase', {});
        },
        get interestsChartsData() {
            return get(this.data, 'interests', {});
        }
    }))
    .actions((self) => ({
        getInsightsData: flow(function* getInsightsData() {
            self.pending = true;
            try {
                const rawUsMapData = yield fetch('https://klickly.s3.amazonaws.com/states-10m.json');
                self.usMapData = yield rawUsMapData.json();

                const data = yield axiosBoa.get(brandsOpenApi.performance.insights.get);

                self.data = {
                    demographics: get(data, 'data.demographics', {}),
                    financial: get(data, 'data.financial', {}),
                    purchase: get(data, 'data.purchase', {}),
                    interests: get(data, 'data.interests', {})
                };
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pending = false;
            }
        }),

        getUSMapData: flow(function* getUSMapData() {
            const rawUsMapData = yield fetch('https://klickly.s3.amazonaws.com/states-10m.json');
            self.usMapData = yield rawUsMapData.json();
        })
    }));
