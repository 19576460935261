import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from '@constants';
import klicklyLogoWhite from '../../images/klickly_logo_white.svg';
import { StyledMainHeader } from './styled';

const MainHeader = () => {
    return (
        <StyledMainHeader className='main-header'>
            <div className='container-fluid'>
                <div data-e2e='landing_header' className='main-header__wrapper'>
                    <div data-e2e='landing_klickly_logo' className='main-header__wrapper__logo'>
                        <Link to={ROUTE_PATHS.ROOT}>
                            <img className='header-logo' src={klicklyLogoWhite} alt='' />
                        </Link>
                    </div>
                    <div className='main-header__wrapper__links'>
                        <a
                            data-e2e='landing_press_link'
                            href='https://www.klickly.com/press'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Press
                        </a>
                        <a
                            data-e2e='landing_blog_link'
                            href='https://blog.klickly.com'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Blog
                        </a>
                        <Link data-e2e='landing_login_link' to={ROUTE_PATHS.LOGIN}>
                            Log in
                        </Link>
                    </div>
                </div>
            </div>
        </StyledMainHeader>
    );
};

export default MainHeader;
