import React, { Fragment } from 'react';
import { TOP_INDENTS, VALIDATION_RULES } from '@constants';
import { Form } from 'antd';
import { StyledInput } from './styled';

const ShopifyForm = () => {
    const addonAfter = '.myshopify.com';

    return (
        <Fragment>
            <Form.Item
                name='shop'
                rules={VALIDATION_RULES.REQUIRED}
                style={TOP_INDENTS.S}
                normalize={(value) => {
                    return value?.replace(/(?: )/g, '-').replace(/(?:(?:http:|https:)?\/\/)?(.*?)(?:\.(?:.*))?$/, '$1');
                }}
            >
                <StyledInput
                    data-e2e='signup_shopify-store-url_input'
                    type='text'
                    addonAfter={addonAfter}
                    label='store-URL'
                />
            </Form.Item>
        </Fragment>
    );
};
export default ShopifyForm;
