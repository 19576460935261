import { COLORS } from '@constants';
import { Checkbox } from 'antd';
import styled from 'styled-components';
export const StyledCheckbox = styled(Checkbox)`
    span {
        font-size: 16px;
        font-weight: 500;

        a {
            color: ${COLORS.$info};
        }
    }
`;
