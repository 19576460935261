import { types } from 'mobx-state-tree';

export const ReportsModel = types.model({
    key: types.maybeNull(types.string),
    campaign: types.maybeNull(types.string),
    revenue: types.maybeNull(types.frozen()),
    spend: types.maybeNull(types.frozen()),
    roas: types.maybeNull(types.frozen()),
    views: types.maybeNull(types.frozen()),
    interactions: types.maybeNull(types.frozen()),
    orders: types.maybeNull(types.frozen()),
    aov: types.maybeNull(types.frozen()),
    audience: types.maybeNull(types.frozen()),
    avgDaysFromFirstTouchPoint: types.maybeNull(types.frozen()),
    avgDaysFromLastTouchPoint: types.maybeNull(types.frozen()),
    newCustomers: types.maybeNull(types.frozen()),
    totalProducts: types.maybeNull(types.frozen()),
    cpa: types.maybeNull(types.frozen())
});
