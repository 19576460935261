import _ from 'lodash';

export const getProductPrimaryImage = (images) => {
    if (!images?.length) {
        return undefined;
    }

    const klicklyPrimaryImage = images.find((image) => image.isPrimary);
    const ecommercePrimaryImage = images.find((image) => image.isEcommercePrimary);

    return klicklyPrimaryImage || ecommercePrimaryImage || _.minBy(images, 'position');
};
