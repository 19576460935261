import { COLORS } from '@constants';
import { getColors } from './colors';
import { formatNumberToUSLocale } from './data';

export const convertDataToBarScheme = ({
    data,
    withNegativeValues = false,
    chartValuePrefix = '',
    chartValueSuffix = '',
    decimalLabelCount = 0
}) => {
    const labels = [];
    const seedData = [];
    const baseData = [];
    const diffData = [];
    const count = [];

    if (!data || !data.length) {
        return {};
    }

    data.forEach((obj) => {
        const seed = obj.seedfilePercent.replace(/[^.,a-zA-Z0-9]/g, '');
        const base = obj.baselinePercent.replace(/[^.,a-zA-Z0-9]/g, '');

        labels.push(obj.label);
        seedData.push(withNegativeValues ? `-${seed}` : seed);
        baseData.push(withNegativeValues ? `-${base}` : base);
        diffData.push(obj.diffPercent);
        count.push(obj.seedfileCount);
    });

    const formatter = (value) => {
        const numberFormat = Number(value).toFixed(decimalLabelCount);
        const labelValue = withNegativeValues
            ? formatNumberToUSLocale(Math.abs(numberFormat))
            : formatNumberToUSLocale(numberFormat);

        return `${chartValuePrefix}${labelValue}${chartValueSuffix}`;
    };

    const datasets = [
        {
            label: 'Dataset 1',
            data: seedData,
            order: 1,
            categoryPercentage: 0.5,
            borderRadius: 4,
            backgroundColor: COLORS.$pink,
            datalabels: {
                anchor: withNegativeValues ? 'end' : 'start',
                align: withNegativeValues ? 'bottom' : 'end',
                color: COLORS.$white,
                font: {
                    size: 14
                },
                formatter
            }
        },
        {
            label: 'Dataset 2',
            data: baseData,
            order: 2,
            backgroundColor: COLORS.$gray20Black,
            categoryPercentage: 0.8,
            borderRadius: 4,
            datalabels: {
                display: false
            },
            tooltip: {
                display: false
            }
        }
    ];

    return { labels, seedData, baseData, diffData, count, datasets };
};

export const convertDataToDonutScheme = (data) => {
    const labels = [];
    const seedData = [];
    const baseData = [];
    const diffData = [];
    const count = [];

    if (!data || !data.length) {
        return {};
    }

    data.forEach((obj) => {
        labels.push(obj.label);
        seedData.push(obj.seedfilePercent);
        baseData.push(obj.baselinePercent);
        diffData.push(obj.diffPercent);
        count.push(obj.seedfileCount);
    });

    const datasets = [
        {
            data: seedData,
            backgroundColor: getColors(data.length)
        }
    ];

    return { labels, seedData, baseData, diffData, count, datasets };
};
