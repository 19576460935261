import React, { useRef, useState } from 'react';
import ReactCropper from 'react-cropper';
import { LoadingOutlined } from '@ant-design/icons';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { useStore } from '@hooks';
import { Button, Icon, Typography, WhiteCover } from '@ui';
import { Flex, Modal, notification, Slider } from 'antd';
import { observer } from 'mobx-react';
import { CancelSave } from '..';
import { StyledLogoPreview, StyledUpload, StyledWrapper } from './styled';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';

export const LogoUpload = observer(() => {
    const store = useStore();
    const logo = store.campaignWizardStore.campaign.creatives[0].logoUrl;
    const { campaignCreativeImageUpload } = store.campaignWizardStore;

    // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Upload
    const [hasError, setHasError] = useState(false);
    const [fileContent, setFileContent] = useState();
    const onFileUpload = ({ file }) => {
        if (file.size > 101000) {
            setHasError(true);
            notification.error({ message: 'File size is too big' });
            return;
        }

        const reader = new FileReader();

        reader.onload = () => {
            const base64String = reader.result;
            setFileContent(base64String);
            setIsModalOpen(true);
        };

        reader.onerror = () => {
            setHasError(true);
        };

        reader.readAsDataURL(file);
    };

    // Cropper
    const cropperConfig = {
        style: { width: 321, height: 321 / 3, margin: '0 auto' },
        guides: false,
        aspectRatio: 3,
        minCropBoxWidth: 321,
        minCropBoxHeight: 321 / 3,
        cropBoxResizable: false,
        center: false
    };

    const cropperRef = useRef();

    const [isSaving, setIsSaving] = useState(false);
    const [zoom, setZoom] = useState(0.1);
    const [minRatio, setMinRatio] = useState(0.1);
    const [ready, setReady] = useState(false);
    const maxRatio = minRatio !== 0.1 ? minRatio * 5 : 1.5;

    const onCropperReady = () => {
        const cropper = cropperRef.current?.cropper;
        const canvasData = cropper.getCanvasData();
        const minRatio = canvasData.width / canvasData.naturalWidth;

        setMinRatio(minRatio);
        setZoom(minRatio);
        setReady(true);
        cropper.zoomTo(minRatio);
    };

    const onRangeInputZoom = (value) => {
        const cropper = cropperRef.current?.cropper;

        if (cropper && ready) {
            setZoom(value);
            cropper.zoomTo(value);
        }
    };

    const onWheelZoom = (e) => {
        setZoom(e.detail.ratio);
    };

    const onCropSave = () => {
        const cropper = cropperRef.current?.cropper;
        cropper.getCroppedCanvas().toBlob(async (croppedImage) => {
            setIsSaving(true);
            setHasError(false);
            await campaignCreativeImageUpload({ values: { logo: croppedImage } });
            setIsSaving(false);
            closeModal();
        });
    };

    return (
        <React.Fragment>
            <Modal
                destroyOnClose
                centered
                onCancel={closeModal}
                closable={false}
                footer={null}
                open={isModalOpen}
                styles={{ content: { padding: 0, overflow: 'hidden' } }}
            >
                <div style={{ padding: 36, position: 'relative' }}>
                    <Button
                        type='noStyle'
                        padding='8px'
                        onClick={closeModal}
                        style={{ position: 'absolute', right: 12, top: 12 }}
                    >
                        <Icon size='24px' color={COLORS.$gray60Black} component={() => <Icon.CloseIcon />} />
                    </Button>
                    <Typography.Text type='badgeMedium' marginBottom='12px'>
                        Change your logo
                    </Typography.Text>

                    <div style={{ padding: 16, backgroundColor: COLORS.$gray40Black, ...BOTTOM_INDENTS.M }}>
                        <ReactCropper
                            zoom={onWheelZoom}
                            ref={cropperRef}
                            src={fileContent}
                            ready={onCropperReady}
                            {...cropperConfig}
                        />
                    </div>
                    <Flex align='center' gap={20} style={BOTTOM_INDENTS.M}>
                        <Icon size='16px' color={COLORS.$gray60Black} component={() => <Icon.ImgLandscape />} />
                        <Slider
                            style={{ flexGrow: 1 }}
                            tooltip={{ formatter: null }}
                            min={minRatio}
                            max={maxRatio}
                            value={zoom}
                            step={0.0001}
                            onChange={onRangeInputZoom}
                        />
                        <Icon size='24px' color={COLORS.$gray60Black} component={() => <Icon.ImgLandscape />} />
                    </Flex>

                    <CancelSave loading={isSaving} onSave={onCropSave} onCancel={closeModal} />
                </div>
            </Modal>

            <WhiteCover data-e2e='creative-settings_logo-upload_block' style={BOTTOM_INDENTS.M} title='Logo'>
                <Flex gap={24} align='center' justify={logo ? 'flex-start' : 'center'}>
                    <Flex vertical style={{ flexBasis: '50%' }} gap={16}>
                        <StyledWrapper>
                            <StyledUpload
                                customRequest={onFileUpload}
                                accept='.png, .jpg, .jpeg'
                                maxCount={1}
                                showUploadList={false}
                                style={{ marginBottom: 16 }}
                            >
                                <Button
                                    disabled={isSaving}
                                    data-e2e='creative-settings_logo-upload_btn'
                                    backgroundColor={COLORS.$white}
                                    block
                                >
                                    <Flex justify='center' align='center' gap={8}>
                                        <Icon
                                            size='24px'
                                            component={() => (isSaving ? <LoadingOutlined /> : <Icon.EditIcon />)}
                                        />
                                        <Typography.Text type='badgeMedium' color={COLORS.$gray60Black}>
                                            {logo ? 'Change image' : 'Add image'}
                                        </Typography.Text>
                                    </Flex>
                                </Button>
                            </StyledUpload>
                        </StyledWrapper>
                        {hasError ? (
                            <Typography.Text
                                data-e2e='creative-settings_logo-upload_error'
                                ty='body2'
                                align='center'
                                color={COLORS.$tartRed}
                            >
                                Please upload the logo in the .png or .jpg format (size shouldn&apos;t exceed 100KB)
                            </Typography.Text>
                        ) : (
                            <Typography.Text
                                data-e2e='creative-settings_logo-upload_description'
                                ty='body2'
                                align='center'
                                color={COLORS.$gray60Black}
                            >
                                Click on the box to upload an official logo. This logo will be used in ads and marketing
                                messages. Logos must be less than 100KB and in .png or .jpg formats
                            </Typography.Text>
                        )}
                    </Flex>

                    {logo && (
                        <div style={{ flexBasis: '50%' }}>
                            <div style={{ border: `1px solid ${COLORS.$gray40Black}` }}>
                                <StyledLogoPreview data-e2e='creative-settings_logo-upload_preview'>
                                    <img src={logo} alt='' />
                                </StyledLogoPreview>
                            </div>
                        </div>
                    )}
                </Flex>
            </WhiteCover>
        </React.Fragment>
    );
});
