import React from 'react';
import { templates } from '@klickly/klickly-templates';
import { Typography } from '@ui';
import { Flex } from 'antd';
import UnitItem from '../../UnitItem';
import TabHead from '../components/TabHead';
import {
    StyledUnitWrapper160x600,
    StyledUnitWrapper300x250,
    StyledUnitWrapper300x600,
    StyledUnitWrapper320x50,
    StyledUnitWrapper320x100,
    StyledUnitWrapper336x280,
    StyledUnitWrapper728x90,
    StyledUnitWrapper970x250
} from '../styled';

export const getAdExamplesItems = (brandedData, promotions) => {
    return [
        {
            key: '970x250',
            label: (
                <Typography.Title
                    data-e2e='campaignwizard_adexample-970x250_tab'
                    level={3}
                    align='center'
                    color='unset'
                >
                    970x250
                </Typography.Title>
            ),
            children: (
                <Flex vertical>
                    <TabHead />
                    <StyledUnitWrapper970x250>
                        {Object.entries(templates.Unit970x250.LAYOUTS)
                            .filter(([_, item]) => item.isAWA !== false)
                            .map(([layout]) => {
                                return (
                                    <UnitItem
                                        key={layout}
                                        brandedData={brandedData}
                                        promotions={promotions}
                                        layout={layout}
                                        width={970}
                                        height={250}
                                    />
                                );
                            })}
                    </StyledUnitWrapper970x250>
                </Flex>
            )
        },
        {
            key: '728x90',
            label: (
                <Typography.Title data-e2e='campaignwizard_adexample-728x90_tab' level={3} align='center' color='unset'>
                    728x90
                </Typography.Title>
            ),
            children: (
                <Flex vertical>
                    <TabHead />
                    <StyledUnitWrapper728x90>
                        {Object.entries(templates.Unit728x90.LAYOUTS)
                            .filter(([_, item]) => item.isAWA !== false)
                            .map(([layout]) => {
                                return (
                                    <UnitItem
                                        key={layout}
                                        brandedData={brandedData}
                                        promotions={promotions}
                                        layout={layout}
                                        width={728}
                                        height={90}
                                    />
                                );
                            })}
                    </StyledUnitWrapper728x90>
                </Flex>
            )
        },
        {
            key: '320x50',
            label: (
                <Typography.Title data-e2e='campaignwizard_adexample-320x50_tab' level={3} align='center' color='unset'>
                    320x50
                </Typography.Title>
            ),
            children: (
                <Flex vertical>
                    <TabHead />
                    <StyledUnitWrapper320x50>
                        {Object.entries(templates.Unit320x50.LAYOUTS)
                            .filter(([_, item]) => item.isAWA !== false)
                            .map(([layout]) => {
                                return (
                                    <UnitItem
                                        key={layout}
                                        brandedData={brandedData}
                                        promotions={promotions}
                                        layout={layout}
                                        width={320}
                                        height={50}
                                    />
                                );
                            })}
                    </StyledUnitWrapper320x50>
                </Flex>
            )
        },
        {
            key: '320x100',
            label: (
                <Typography.Title
                    data-e2e='campaignwizard_adexample-320x100_tab'
                    level={3}
                    align='center'
                    color='unset'
                >
                    320x100
                </Typography.Title>
            ),
            children: (
                <Flex vertical>
                    <TabHead />
                    <StyledUnitWrapper320x100>
                        {Object.entries(templates.Unit320x100.LAYOUTS)
                            .filter(([_, item]) => item.isAWA !== false)
                            .map(([layout]) => {
                                return (
                                    <UnitItem
                                        key={layout}
                                        brandedData={brandedData}
                                        promotions={promotions}
                                        layout={layout}
                                        width={320}
                                        height={100}
                                    />
                                );
                            })}
                    </StyledUnitWrapper320x100>
                </Flex>
            )
        },
        {
            key: '300x250',
            label: (
                <Typography.Title
                    data-e2e='campaignwizard_adexample-300x250_tab'
                    level={3}
                    align='center'
                    color='unset'
                >
                    300x250
                </Typography.Title>
            ),
            children: (
                <Flex vertical>
                    <TabHead />
                    <StyledUnitWrapper300x250>
                        {Object.entries(templates.Unit300x250.LAYOUTS)
                            .filter(([_, item]) => item.isAWA !== false)
                            .map(([layout]) => {
                                return (
                                    <UnitItem
                                        key={layout}
                                        brandedData={brandedData}
                                        promotions={promotions}
                                        layout={layout}
                                        width={300}
                                        height={250}
                                    />
                                );
                            })}
                    </StyledUnitWrapper300x250>
                </Flex>
            )
        },
        {
            key: '300x600',
            label: (
                <Typography.Title
                    data-e2e='campaignwizard_adexample-300x600_tab'
                    level={3}
                    align='center'
                    color='unset'
                >
                    300x600
                </Typography.Title>
            ),
            children: (
                <Flex vertical>
                    <TabHead />
                    <StyledUnitWrapper300x600>
                        {Object.entries(templates.Unit300x600.LAYOUTS)
                            .filter(([_, item]) => item.isAWA !== false)
                            .map(([layout]) => {
                                return (
                                    <UnitItem
                                        key={layout}
                                        brandedData={brandedData}
                                        promotions={promotions}
                                        layout={layout}
                                    />
                                );
                            })}
                    </StyledUnitWrapper300x600>
                </Flex>
            )
        },
        {
            key: '336x280',
            label: (
                <Typography.Title
                    data-e2e='campaignwizard_adexample-336x280_tab'
                    level={3}
                    align='center'
                    color='unset'
                >
                    336x280
                </Typography.Title>
            ),
            children: (
                <Flex vertical>
                    <TabHead />
                    <StyledUnitWrapper336x280>
                        {Object.entries(templates.Unit336x280.LAYOUTS)
                            .filter(([_, item]) => item.isAWA !== false)
                            .map(([layout]) => {
                                return (
                                    <UnitItem
                                        key={layout}
                                        brandedData={brandedData}
                                        promotions={promotions}
                                        layout={layout}
                                        width={336}
                                        height={280}
                                    />
                                );
                            })}
                    </StyledUnitWrapper336x280>
                </Flex>
            )
        },
        {
            key: '160x600',
            label: (
                <Typography.Title
                    data-e2e='campaignwizard_adexample-160x600_tab'
                    level={3}
                    align='center'
                    color='unset'
                >
                    160x600
                </Typography.Title>
            ),
            children: (
                <Flex vertical>
                    <TabHead />
                    <StyledUnitWrapper160x600>
                        {Object.entries(templates.Unit160x600.LAYOUTS)
                            .filter(([_, item]) => item.isAWA !== false)
                            .map(([layout]) => {
                                return (
                                    <UnitItem
                                        key={layout}
                                        brandedData={brandedData}
                                        promotions={promotions}
                                        layout={layout}
                                        width={160}
                                    />
                                );
                            })}
                    </StyledUnitWrapper160x600>
                </Flex>
            )
        }
    ];
};
