export const calculateContactGroups = (initialContacts, resultContacts) => {
    const resultContactIds = new Set(resultContacts.map((contact) => contact.id));
    const initialContactIds = new Set(initialContacts.map((contact) => contact.id));

    const contactsToDelete = initialContacts.filter((initialContact) => {
        if (!resultContactIds.has(initialContact.id)) {
            return true;
        }
        const resultContact = resultContacts.find((contact) => contact.id === initialContact.id);
        return resultContact.value === '';
    });

    const contactsToCreate = resultContacts.filter((contact) => contact.id === undefined && contact.value !== '');

    const contactsToUpdate = resultContacts.filter((resultContact) => {
        if (!resultContact.id || !initialContactIds.has(resultContact.id)) {
            return false;
        }
        const initialContact = initialContacts.find((contact) => contact.id === resultContact.id);

        const valueChanged = resultContact.value !== '' && initialContact.value !== resultContact.value;
        const notifyOnActionsChanged =
            initialContact.notifyOnActions.sort().join(',') !== resultContact.notifyOnActions.sort().join(',');

        return valueChanged || notifyOnActionsChanged;
    });

    return { contactsToCreate, contactsToUpdate, contactsToDelete };
};
