import { Typography } from '@ui';
import styled from 'styled-components';
import OnlineBankingExample from '../../../../assets/svg/online-banking.svg?react';

export const StyledExampleImg = styled(OnlineBankingExample)`
    width: 100%;
    height: 173px;
    object-fit: contain;
`;

export const StyledText = styled(Typography)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
`;
