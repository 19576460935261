import { CAMPAIGN_COMMISSION_TYPE, CAMPAIGN_COMMISSION_TYPE_NAME } from '@enums';

const { REST_OF_SITE, CAMPAIGN } = CAMPAIGN_COMMISSION_TYPE;

export const getCommissionData = (defaultType, orderItems, isLimited) => {
    const hasROS = orderItems.some(({ restOfSiteCommission }) => restOfSiteCommission);
    const hasCC = orderItems.some(({ campaignCommission }) => campaignCommission);

    const isCombined = hasROS && hasCC && defaultType === CAMPAIGN_COMMISSION_TYPE_NAME[CAMPAIGN];
    const combinedType = `${CAMPAIGN_COMMISSION_TYPE_NAME[REST_OF_SITE]}, ${CAMPAIGN_COMMISSION_TYPE_NAME[CAMPAIGN]}`;

    return {
        type: isCombined ? combinedType : isLimited ? `${defaultType} (adjusted)` : defaultType,
        widen: isCombined || isLimited
    };
};
