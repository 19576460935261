export const getOptions = (externalTooltipHandler) => {
    return {
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    usePointStyle: true,
                    boxWidth: 100,
                    font: {
                        size: 14,
                        weight: 500
                    },
                    generateLabels(chart) {
                        const data = chart.data;
                        if (data.labels.length && data.datasets.length) {
                            return data.labels.map((label, i) => {
                                const meta = chart.getDatasetMeta(0);
                                const style = meta.controller.getStyle(i);

                                return {
                                    text: `${label}: ${data.datasets[0].data[i]}%`,
                                    fillStyle: style.backgroundColor,
                                    strokeStyle: style.borderColor,
                                    lineWidth: style.borderWidth,
                                    pointStyle: 'circle',
                                    index: i
                                };
                            });
                        }
                        return [];
                    }
                }
            },
            tooltip: {
                enabled: false,
                position: 'nearest',
                external: externalTooltipHandler,
                callbacks: {
                    label: function (context) {
                        return `${context?.formattedValue}%`;
                    }
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false
    };
};
