import { COLORS } from '@constants';
import { IMAGE_FORMAT } from '@enums';
import styled, { css } from 'styled-components';

export const StyledImageExample = styled.div`
    position: relative;
    width: 252px;
    height: 252px;
    padding: 40px 0;
    border: 2px dashed transparent;

    > img {
        width: 248px;
        height: 172px;
    }

    p {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    ${({ $format }) =>
        $format === IMAGE_FORMAT.CROPPED &&
        css`
            &:before {
                content: '';
                position: absolute;
                top: 40px;
                right: 38px;
                bottom: 40px;
                left: 38px;
                border: 2px dashed ${COLORS.$pink};
            }

            p {
                top: 22px;
            }
        `};

    ${({ $format }) =>
        $format === IMAGE_FORMAT.ZOOMED &&
        css`
            border: 2px dashed ${COLORS.$pink};

            p {
                top: -20px;
            }
        `};
`;
