import React from 'react';
import { Input } from 'antd';
import Label from './components/Label';
import { StyledPrefix, StyledSuffix, StyledWrapper } from './styled';

const TextArea = ({ label, prefix, suffix, ...props }) => {
    return (
        <StyledWrapper $hasPrefix={Boolean(prefix)} $hasSuffix={Boolean(suffix)}>
            {prefix && <StyledPrefix>{prefix}</StyledPrefix>}
            <Label $hasPrefix={Boolean(prefix)} label={label}>
                <Input.TextArea autoSize={{ minRows: 3 }} placeholder={label} {...props} />
            </Label>
            {suffix && <StyledSuffix>{suffix}</StyledSuffix>}
        </StyledWrapper>
    );
};

export default TextArea;
