import React, { Fragment, useEffect, useState } from 'react';
import { orderMap } from '@constants';
import { useConnector, useStore } from '@hooks';
import { Loader, Table } from '@ui';
import { camelToSnakeCase } from '@utils';
import { observer } from 'mobx-react';
import CampaignProductsTable from './components/CampaignProductsTable';
import EmptyState from './components/EmptyState';
import Header from './components/Header';
import NotConnected from './components/NotConnected';
import { getColumns } from './constants';

const CampaignsPage = observer(() => {
    const store = useStore();
    const {
        campaigns,
        meta,
        getCampaigns,
        getCampaignProducts,
        pending,
        updateSorters,
        updateFilters,
        resetFilters,
        resetSorters
    } = store.campaignsStore;
    const { isActive: isAccountActive, isShopify, currentAccount } = store.accountsStore;
    const { getCampaignCommissions, campaignCommissions } = store.campaignsStore;

    const { connected } = useConnector();

    const [loading, setLoading] = useState(true);
    const [hasCampaigns, setHasCampaigns] = useState(false);

    useEffect(() => {
        (async () => {
            const promises = [getCampaigns()];

            if (!campaignCommissions.length) {
                promises.push(getCampaignCommissions());
            }

            await Promise.all(promises);

            const hasCampaigns = !!store.campaignsStore.campaigns.length;
            setHasCampaigns(hasCampaigns);

            setLoading(false);
        })();

        return () => {
            resetFilters();
            resetSorters();
        };
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {
        const page = pagination?.current;

        updateSorters({
            sort: sorter.order ? camelToSnakeCase(sorter.field) : undefined,
            order: sorter.order ? orderMap[sorter.order] : undefined
        });

        updateFilters({
            statuses: filters.status || undefined,
            campaign_commissions: filters.commission || undefined,
            catalog_types: filters.catalogType || undefined
        });

        getCampaigns({ page });
    };

    const handleSearch = (search) => {
        updateFilters({
            search: search ? `title:${search}` : undefined
        });
        getCampaigns();
    };

    if (loading) return <Loader size='120px' />;

    if (!connected && isShopify) {
        return <NotConnected />;
    }

    if (!hasCampaigns) {
        return <EmptyState />;
    }

    return (
        <Fragment>
            <Header setSearchValue={handleSearch} />
            <Table
                columns={getColumns(campaignCommissions, currentAccount.platformId)}
                dataSource={campaigns}
                rowKey={(record) => record.id}
                loading={pending}
                pagination={{
                    pageSize: meta.perPage,
                    total: meta.total,
                    current: meta.page,
                    totalPages: meta.totalPages
                }}
                onChange={handleTableChange}
                expandable={
                    isAccountActive
                        ? {
                              onExpand: (expanded, record) => (expanded ? getCampaignProducts(record.id) : null),
                              expandedRowRender: (record) => {
                                  return <CampaignProductsTable campaignId={record.id} />;
                              }
                          }
                        : false
                }
                data-e2e='campaigns_campaigns-list_table'
                scroll={{
                    x: 1250
                }}
            />
        </Fragment>
    );
});
export default CampaignsPage;
