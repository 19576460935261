import { ROUTE_PATHS } from '@constants';

export const getMenuItems = (showPayments) => {
    const MENU_ITEMS = [
        {
            label: 'Company Info',
            to: ROUTE_PATHS.COMPANY_INFO,
            key: 'company-info',
            children: []
        },
        {
            label: 'Account Management',
            to: ROUTE_PATHS.ACCOUNT_MANAGEMENT,
            key: 'account-management',
            children: [
                {
                    label: 'Invoices',
                    to: ROUTE_PATHS.INVOICES_SETTINGS,
                    key: 'invoices-settings'
                },
                {
                    label: 'Tracking',
                    to: ROUTE_PATHS.TRACKING_SETTINGS,
                    key: 'tracking-settings'
                }
            ]
        },
        {
            label: 'Customer Management',
            to: ROUTE_PATHS.CUSTOMER_MANAGEMENT,
            key: 'customer-management',
            children: [
                showPayments
                    ? {
                          label: 'Payments',
                          to: ROUTE_PATHS.PAYMENTS_SETTINGS,
                          key: 'payments-settings'
                      }
                    : undefined
            ].filter(Boolean)
        },
        {
            label: 'Notifications',
            to: ROUTE_PATHS.NOTIFICATIONS_SETTINGS,
            key: 'notifications-settings',
            children: []
        },
        {
            label: 'Integrations',
            to: ROUTE_PATHS.INTEGRATIONS_SETTINGS,
            key: 'integrations-settings',
            children: []
        }
    ];

    return MENU_ITEMS;
};
