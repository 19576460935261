import { COLORS } from '@constants';
import styled, { css } from 'styled-components';

export const StyledWrapper = styled.div`
    flex: 1 1 0px;
    padding: 16px 0px;
    transition: all 0.3s;
    cursor: pointer;

    ${({ $active }) =>
        $active
            ? css`
                  opacity: 1;
                  border-bottom: 4px solid ${COLORS.$pink};
              `
            : css`
                  opacity: 0.5;
                  border-bottom: 4px solid transparent;
              `}

    & a,
    a:hover,
    a:active,
    a:visited {
        text-decoration: none;
    }
`;
