import styled from 'styled-components';

export const StyledTableWrapper = styled.div`
    .ant-table-expanded-row-level-1 > .ant-table-cell {
        padding: 0 !important;
    }

    .ant-table-expanded-row-level-1 > .ant-table-cell {
        border: none;
        padding: 0 !important;
    }

    .ant-table .ant-table {
        border: none;
    }
`;
