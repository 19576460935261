export const getColors = (length) => {
    const pallet = [
        '#FFC53A',
        '#00398F',
        '#F25858',
        '#DA1781',
        '#26A6A6',
        '#87F1FF',
        '#C0F5FA',
        '#582B11',
        '#22333B',
        '#9EE493',
        '#70566D',
        '#9ED0E6',
        '#B796AC'
    ];
    const colors = [];

    for (let i = 0; i < length; i++) {
        colors.push(pallet[i % (pallet.length - 1)]);
    }

    return colors;
};
