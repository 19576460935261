import React from 'react';
import { COLORS } from '@constants';
import { Button, Icon, Typography } from '@ui';
import { Flex } from 'antd';
import { StyledUpload } from './styled';

const Upload = ({ beforeUpload, handleChange, loading, uploaded }) => {
    return (
        <StyledUpload
            beforeUpload={beforeUpload}
            customRequest={({ file, onSuccess }) => {
                setTimeout(() => onSuccess('ok'), 0); // Simulates a successful server response
            }}
            onChange={handleChange}
            accept='.png, .jpg, .jpeg'
            maxCount={1}
            showUploadList={false}
            style={{ marginBottom: 16 }}
        >
            <Button disabled={loading} backgroundColor={COLORS.$white} block>
                <Flex justify='center' align='center' gap={8}>
                    <Icon size='24px' component={() => <Icon.EditIcon />} />
                    <Typography.Text type='badgeMedium' color={COLORS.$gray60Black}>
                        {uploaded ? 'Change image' : 'Add image'}
                    </Typography.Text>
                </Flex>
            </Button>
        </StyledUpload>
    );
};

export default Upload;
