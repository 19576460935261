import React from 'react';
import { Typography } from '@ui';
import { Col, Row } from 'antd';

const Attribution = ({ attributionWindow }) => {
    return (
        <Row align='middle' gutter={[8, 8]} style={{ paddingBlock: 16 }}>
            <Col span={12}>
                <Typography.Text data-e2e='account-management_attribution_block' type='badgeSmall'>
                    Attribution
                </Typography.Text>
            </Col>
            <Col span={12}>
                <Typography.Title data-e2e='account-management_attribution_value' align='end' level={3}>
                    {attributionWindow?.numberOfViewDays} day view; {attributionWindow?.numberOfClickDays} day click
                </Typography.Title>
            </Col>
        </Row>
    );
};

export default Attribution;
