import { COLORS } from '@constants';
import { Tabs } from '@ui';
import styled from 'styled-components';

export const StyledChart = styled.div`
    height: 450px;
    width: 100%;
    background: ${COLORS.$white};
    border: 1px solid ${COLORS.$gray15Black};
    border-radius: 8px;
    position: relative;
`;

export const StyledTabs = styled(Tabs)`
    margin-bottom: 48px;

    & .ant-tabs-nav-list {
        justify-content: center;
    }
`;
