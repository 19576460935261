import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Typography } from '@ui';
import { Col, Form, Row, Switch } from 'antd';
import { FIELD_NAMES } from '../../../../../constants';

const Purchases = () => {
    return (
        <Row align='middle' gutter={[48, 48]} wrap={false} justify='space-between'>
            <Col>
                <Typography.Text data-e2e='tracking_purchases_block' type='badgeSmall' style={BOTTOM_INDENTS.XXS}>
                    Purchases
                </Typography.Text>
                <Typography.Text
                    data-e2e='tracking_purchases_description'
                    color={COLORS.$gray60Black}
                    type='descriptor2'
                >
                    Track purchases in Google Analytics, including both in-widget and in-Klickly purchases
                </Typography.Text>
            </Col>
            <Col>
                <Form.Item name={FIELD_NAMES.GOOGLE_ANALYTICS_PURCHASES} valuePropName='checked'>
                    <Switch data-e2e='tracking_purchases_toggle' />
                </Form.Item>
            </Col>
        </Row>
    );
};

export default Purchases;
