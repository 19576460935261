import React from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS, ROUTE_PATHS } from '@constants';
import { Button, Icon, Typography } from '@ui';
import { Flex } from 'antd';

const NotConnected = () => {
    const navigate = useNavigate();
    return (
        <Flex vertical style={{ flexGrow: 1 }}>
            <Typography.Text data-e2e='orders_page_title' type='em0'>
                Orders
            </Typography.Text>
            <Flex align='center' justify='center' style={{ flexGrow: 1 }}>
                <Flex data-e2e='orders_not-connected-state_content' vertical align='center' gap={24}>
                    <Typography.Title data-e2e='orders_connect-store_text' level={2} color={COLORS.$gray60Black}>
                        Connect your store to Klickly to view your orders
                    </Typography.Title>
                    <Button
                        data-e2e='orders_complete-setup_btn'
                        onClick={() => navigate(ROUTE_PATHS.INTEGRATIONS_SETTINGS)}
                        type='primary'
                        size='large'
                        iconRight={
                            <Icon size='24px' color={COLORS.$white} component={() => <Icon.ArrowForwardIcon />} />
                        }
                    >
                        Complete Set-Up
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default NotConnected;
