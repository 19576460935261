export const FIELD_NAME = {
    CREATIVE: {
        FONT: 'fontName',
        PRIMARY_COLOR: 'primaryColor',
        SECONDARY_COLOR: 'secondaryColor',
        OFFER_MESSAGE: 'offerMessage',
        LOGO: 'file',
        PRODUCT_IMAGES_FORMAT: 'productImageBehavior'
    },
    CAMPAIGN: {
        NAME: 'title',
        TYPE: 'catalogType',
        COMMISSION: 'campaignCommission',
        BOOST: 'boostId',
        SELECTED_PRODUCTS: 'selectedProducts',
        EXCLUDED_PRODUCTS: 'excludedProducts'
    },
    ACCOUNT: {
        REST_OF_SITE_COMMISSION: 'restOfSiteCommission',
        NEW_CUSTOMER_COMMISSION: 'newCustomerCommission',
        COLD_CUSTOMER_COMMISSION: 'coldCustomerCommission'
    },
    GOOGLE_ADS: {
        ENABLED_BY_BRAND: 'googleAdsEnabledByBrand',
        NEGATIVE_KEYWORDS: 'googleAdsNegativeKeywords'
    },
    CUSTOM_CREATIVES: {
        ENABLED_BY_BRAND: 'customCreativesEnabledByBrand',
        LANDING_PAGE_URL: 'customCreativesLandingPageUrl'
    }
};

export const CUSTOMER_INFO_FIELD_NAMES = {
    EMAIL: 'email',
    PHONE_NUMBER: 'phone',
    STATE: 'province',
    CITY: 'city',
    ADDRESS: 'address1',
    APT_NUMBER: 'address2',
    ZIP_CODE: 'zipCode',
    LENGTH: 'length',
    WIDTH: 'width',
    HEIGHT: 'height',
    WEIGHT: 'weight',
    STATES_LIST: 'statesList'
};
