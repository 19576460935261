import React from 'react';
import { COLORS } from '@constants';
import { useStore } from '@hooks';
import { Icon, Tooltip, Typography } from '@ui';
import { formatString } from '@utils';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { METRICS } from '../../../../constants';
import { graphColorsMap } from '../../constants';
import { InfoIcon, StyledCard, StyledIcon } from './styled';

const CheckboxCard = observer(({ date, metric, hideInfo }) => {
    const store = useStore();
    const { addMetricChart, selectedChartMetrics, getChartData, deleteMetricChart } = store.performanceStore;
    const isSelected = !!selectedChartMetrics.find((item) => item === metric.label);

    const onMetric = () => {
        if (isSelected) {
            deleteMetricChart(metric.label);
        } else {
            addMetricChart(metric.label);
            getChartData({
                ...date,
                selectedMetric: metric.label
            });
        }
    };

    return (
        <Col span={8} onClick={onMetric}>
            <StyledCard data-e2e={`performance_${formatString(metric.title)}_card`} hoverable>
                <Row justify='space-between' gutter={[16, 16]}>
                    <Col span={6}>
                        {hideInfo ? null : (
                            <Tooltip title={METRICS[metric.label].tooltip} trigger='hover'>
                                <Icon
                                    data-e2e={`performance_${formatString(metric.title)}_tooltip`}
                                    style={{ paddingBlock: 4 }}
                                    size='24px'
                                    color={COLORS.$gray40Black}
                                    stroke='white'
                                    component={() => <InfoIcon />}
                                />
                            </Tooltip>
                        )}
                    </Col>
                    <Col span={12}>
                        <Typography.Text
                            data-e2e={`performance_${formatString(metric.title)}_card-title`}
                            align='center'
                            type='badgeMedium'
                            color={COLORS.$gray40Black}
                        >
                            {metric.title}
                        </Typography.Text>
                    </Col>
                    <Col data-e2e={`performance_${formatString(metric.title)}-displayed_section`} align='end' span={6}>
                        {hideInfo ? null : (
                            <StyledIcon $isActive={isSelected}>
                                {isSelected && (
                                    <Typography.Text
                                        data-e2e={`performance_${formatString(metric.title)}-displayed_text`}
                                        color={COLORS.$gray40Black}
                                        type='descriptor2'
                                    >
                                        Displayed
                                    </Typography.Text>
                                )}
                                <Icon
                                    data-e2e={`performance_${formatString(metric.title)}-displayed_icon`}
                                    size='24px'
                                    color={isSelected ? graphColorsMap[metric.id] : COLORS.$white}
                                    component={() => <Icon.ChartIcon />}
                                />
                            </StyledIcon>
                        )}
                    </Col>
                    <Col span={24}>
                        <Typography.Text
                            data-e2e={`performance_${formatString(metric.title)}_value`}
                            align='center'
                            type='em0'
                        >
                            {metric.formattedValue}
                        </Typography.Text>
                    </Col>
                </Row>
            </StyledCard>
        </Col>
    );
});

export default CheckboxCard;
