import React from 'react';
import { formatPrice } from '@utils';
import ProductName from '../components/ProductName';

export const PAGE_SIZE = 10;

export const paginationConfig = {
    pageSize: PAGE_SIZE,
    position: ['bottomCenter'],
    showQuickJumper: false,
    showSizeChanger: false
};

export const columns = [
    {
        title: <span data-e2e='campaignwizard_product_column'>Product</span>,
        dataIndex: 'title',
        key: 'title',
        render: (value, record) => <ProductName name={value} product={record} />
    },
    // {
    //     title: 'SKU ID',
    //     dataIndex: 'ecommerceId',
    //     key: 'ecommerceId',
    //     align: 'right',
    //     render: (value) => value || '-'
    // },
    {
        title: <span data-e2e='campaignwizard_product-type_column'>Product Type</span>,
        dataIndex: 'type',
        key: 'type',
        align: 'right',
        render: (value, record) => (
            <span data-e2e={`campaignwizard_product-${record.id}-type_value`}>{value.title || '-'}</span>
        )
    },
    {
        title: <span data-e2e='campaignwizard_inventory_column'>Inventory</span>,
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'right',
        render: (value, record) => (
            <span data-e2e={`campaignwizard_product-${record.id}-inventory_value`}>{value || '-'}</span>
        )
    },
    {
        title: <span data-e2e='campaignwizard_last-30-day-sales_column'>Last 30-day Sales</span>,
        dataIndex: 'last30DaysGMV',
        key: 'last30DaysGMV',
        align: 'right',
        render: (value, record) => (
            <span data-e2e={`campaignwizard_product-${record.id}-30days-sales_value`}>
                {value ? formatPrice(value) : '-'}
            </span>
        )
    }
];
export const columnsInner = [
    {
        title: <span data-e2e='campaignwizard_variants-variants_column'>Variants</span>,
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => <span data-e2e={`campaignwizard_variant-${record.id}-name_value`}>{name}</span>
    },
    {
        title: <span data-e2e='campaignwizard_variants-sku-id_column'>SKU ID</span>,
        dataIndex: 'sku',
        key: 'sku',
        align: 'right',
        render: (value, record) => (
            <span data-e2e={`campaignwizard_variant-${record.id}-sku-id_value`}>{value || '-'}</span>
        )
    },
    {
        title: <span data-e2e='campaignwizard_variants-inventory_column'>Inventory</span>,
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'right',
        render: (value, record) => (
            <span data-e2e={`campaignwizard_variant-${record.id}-inventory_value`}>{value || '-'}</span>
        )
    },
    {
        title: <span data-e2e='campaignwizard_variants-original-price_column'>Original Price</span>,
        dataIndex: 'originalPrice',
        key: 'price',
        align: 'right',
        render: (value, record) => (
            <span data-e2e={`campaignwizard_variant-${record.id}-original-price_value`}>
                {value || value === 0 ? formatPrice(value) : '-'}
            </span>
        )
    },
    {
        title: <span data-e2e='campaignwizard_variants-sale-price_column'>Sale Price</span>,
        dataIndex: 'price',
        key: 'totalPrice',
        align: 'right',
        render: (value, record) => (
            <span data-e2e={`campaignwizard_variant-${record.id}-sale-price_value`}>
                {value || value === 0 ? formatPrice(value) : '-'}
            </span>
        )
    }
];
