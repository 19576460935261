import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    padding: 24px;
    background-color: ${COLORS.$white};
    border: 1px solid ${COLORS.$gray15Black};
    border-radius: 8px;
    position: relative;
`;
