import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS, ROUTE_PATHS } from '@constants';
import { Button, Icon, Typography } from '@ui';
import { Card, Col, Row } from 'antd';

const EmptyState = () => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <Card>
                <Row gutter={[16, 16]} align='middle'>
                    <Col>
                        <Icon
                            data-e2e='home_campaign_icon'
                            color={COLORS.$gray60Black}
                            size='80px'
                            component={() => <Icon.CampaignIcon />}
                        />
                    </Col>
                    <Col flex='1 0 auto'>
                        <Typography.Text data-e2e='home_campaign_title' marginBottom='8px' type='badgeSmall'>
                            Add your first campaign
                        </Typography.Text>
                        <Typography.Text
                            data-e2e='home_campaign_description'
                            color={COLORS.$gray100Black}
                            type='descriptor1'
                        >
                            The more campaigns you have, the greater the chance your products will be shown to
                            consumers!
                        </Typography.Text>
                    </Col>
                    <Col>
                        <Button
                            data-e2e='home_campaign-create_btn'
                            onClick={() => navigate(ROUTE_PATHS.CAMPAIGNS_CREATE_STEP_1)}
                            type='primary'
                            size='large'
                            iconRight={<Icon size='24px' color={COLORS.$white} component={() => <Icon.PlusIcon />} />}
                        >
                            Add New Campaign
                        </Button>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
};

export default EmptyState;
