import React, { useEffect, useState } from 'react';
import { COLORS } from '@constants';
import { useStore } from '@hooks';
import { Loader } from '@ui';
import { observer } from 'mobx-react';
import ExpandIcon from './components/ExpandIcon';
import { StyledTable } from './styled';
import { getDefaultPaginationProps, getExpandable, getExtendedColumns } from './utils';

export const Table = observer(
    ({
        dataSource = [],
        columns,
        rowSelection,
        expandPosition = 'left', // 'left' | 'right',
        borderColor = COLORS.$gray15Black,
        pagination = {}, // {} | false
        expandable,
        loading = false,
        onChange,
        ...props
    }) => {
        const [expandedRowKeys, setExpandedRowKeys] = useState([]);
        const store = useStore(); // eslint-disable-line
        const extendedColumns = getExtendedColumns(columns, { expandPosition });
        const paginationProps = getDefaultPaginationProps(pagination.current || 1, pagination.totalPages || 1);

        useEffect(() => {
            document.querySelectorAll('.ant-pagination-options-quick-jumper input').forEach((element) => {
                element.setAttribute('data-e2e', 'pagination_input');
            });
        }, []);

        return (
            <StyledTable
                dataSource={[...dataSource]}
                columns={extendedColumns}
                rowSelection={rowSelection ? { ...rowSelection, columnWidth: 48 } : null}
                loading={{ spinning: loading, indicator: <Loader /> }}
                pagination={typeof pagination === 'boolean' ? pagination : { ...paginationProps, ...pagination }}
                onChange={onChange}
                expandable={getExpandable(expandable)}
                expandIcon={ExpandIcon}
                sortDirections={['ascend', 'descend', 'ascend']}
                $borderColor={borderColor}
                rowClassName={(record, index) => (expandedRowKeys.includes(index) ? 'expanded' : '')}
                onRow={(record, index) => {
                    return {
                        onClick: (e) => {
                            if (!e.target.closest('.ant-table-row-expand-icon-cell > .anticon')) {
                                return;
                            }
                            setExpandedRowKeys((prev) => {
                                if (prev.includes(index)) {
                                    return prev.filter((key) => key !== index);
                                } else {
                                    return [...prev, index];
                                }
                            });
                        }
                    };
                }}
                $paginationInsideBorder={pagination?.position?.includes('bottomCenter')}
                {...props}
            />
        );
    }
);
