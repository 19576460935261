const boaUrl = process.env.REACT_APP_BRANDS_OPEN_API_URL;
const connectorAppUrl = process.env.REACT_APP_CONNECTOR_APP_URL;
const connectorApiUrl = process.env.REACT_APP_CONNECTOR_API_URL;
const stripePublishKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
const stripeClientId = process.env.REACT_APP_STRIPE_CLIENT_ID;
const stage = process.env.REACT_APP_NODE_ENV;
const appUrl = process.env.REACT_APP_BRANDS_OPEN_APP_URL;
const appUrlV1 = process.env.REACT_APP_V1_APP_URL;

const config = {
    boaUrl,
    connectorAppUrl,
    connectorApiUrl,
    appUrl,
    appUrlV1,
    stripePublishKey,
    stripeClientId,
    stage
};

export default config;
