import { COLORS } from '@constants';
import { Divider } from 'antd';
import styled, { css } from 'styled-components';

export const StyledDivider = styled(Divider)`
    ${({ $margin = '24px 0' }) => css`
        &.ant-divider-horizontal {
            margin: ${typeof $margin === 'number' ? `${$margin}px` : $margin};
            border-block-start: 1px solid ${COLORS.$gray15Black};
        }
    `};
`;
