import React from 'react';
import { BOTTOM_INDENTS, FIELD_NAME } from '@constants';
import { Radio, WhiteCover } from '@ui';
import { Col, Form, Row } from 'antd';
import { StyledFontExample } from './styled';

export const FontSelector = () => {
    const form = Form.useFormInstance();
    const font = Form.useWatch(FIELD_NAME.CREATIVE.FONT, form);
    return (
        <WhiteCover data-e2e='creative-settings_font-selector_block' style={BOTTOM_INDENTS.M} title='Font'>
            <Row align='middle' justify='space-between' gutter={24}>
                <Col span={12}>
                    <Form.Item name={FIELD_NAME.CREATIVE.FONT} noStyle>
                        <Radio.Group style={{ display: 'flex', flexDirection: 'column', padding: '16px', gap: '32px' }}>
                            <Radio.WithLabel value='Arial' style={{ height: 'auto' }}>
                                Arial
                            </Radio.WithLabel>
                            <Radio.WithLabel value='Times New Roman' style={{ height: 'auto' }}>
                                Times New Roman
                            </Radio.WithLabel>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <StyledFontExample data-e2e='creative-settings_font_preview' $font={font}>
                        Aa
                    </StyledFontExample>
                </Col>
            </Row>
        </WhiteCover>
    );
};
