import React from 'react';
import { COLORS } from '@constants';
import { Typography } from '@ui';
import { Flex } from 'antd';
import Header from '../Header';

const EmptyState = () => {
    return (
        <Flex data-e2e='products_empty_section' vertical style={{ flexGrow: 1 }}>
            <Header />
            <Flex align='center' justify='center' style={{ flexGrow: 1 }}>
                <Typography.Title data-e2e='products_no-products_text' level={2} color={COLORS.$gray60Black}>
                    You have no products
                </Typography.Title>
            </Flex>
        </Flex>
    );
};

export default EmptyState;
