export const getCopyByPriorityValue = (priority) => {
    if (priority < 40) {
        return {
            title: 'Priority is low',
            description: 'A low Priority means other merchants with similar products are more likely to be shown.',
            hint: '(Hint: increase your Commission or add a Boost).'
        };
    }
    return priority < 60
        ? {
              title: 'Priority is average',
              description:
                  'An average Priority means you’re likely to be shown about the same amount as merchants with similar products.',
              hint: '(Hint: increase your Commission or add a Boost).'
          }
        : {
              title: 'Priority is very high',
              description:
                  'A very high Priority means you’re likely to be shown significantly more than merchants with similar products.',
              hint: '(Hint: your Commission and Boost are good for now!).'
          };
};
