import React from 'react';
import BaseInput from '../BaseInput';
import { formatInputValue } from './utils';

const InputSsn = ({ value, onChange, numberType = 'individual', ...props }) => {
    return (
        <BaseInput
            value={formatInputValue(value, numberType)}
            onChange={({ target: { value: v } }) => onChange(v.replace(/\D/g, '').substr(0, 10))}
            {...props}
        />
    );
};

export default InputSsn;
