import React, { useEffect, useState } from 'react';
import { Divider, Loader, Typography } from '@ui';
import { observer } from 'mobx-react';
import { COLORS } from '../../../../constants';
import { useStore } from '../../../../hooks';
import InsightExamples from './components/InsightExamples';
import RequestAccessCard from './components/RequestAccessCard';
import RequestSentMessage from './components/RequestSentMessage';

const RequestInsights = observer(() => {
    const store = useStore();
    const { getDemoInsights, top5States, barData, data } = store.insightsDemoStore;
    const { getUSMapData } = store.insightsStore;
    const { hasGUIDRequest } = store.accountsStore;

    const showInsightsExamples = Boolean(top5States.length || barData.values.length);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!data) {
            (async () => {
                setLoading(true);
                await getDemoInsights();
                await getUSMapData();
                setLoading(false);
            })();
        }
    }, []);

    if (loading) {
        return <Loader size='120px' />;
    }

    return (
        <React.Fragment>
            <Typography.Text data-e2e='products_page_title' type='em0'>
                Request Access to Insights
            </Typography.Text>
            <Divider style={{ borderBlockStart: `1px solid ${COLORS.$gray20Black}` }} margin='48px 0' />
            {hasGUIDRequest ? <RequestSentMessage /> : <RequestAccessCard />}

            {showInsightsExamples && <InsightExamples />}
        </React.Fragment>
    );
});

export default RequestInsights;
