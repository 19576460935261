import React from 'react';
import { LoginForm } from '@components';
import { ROUTE_PATHS } from '@constants';
import { useStore } from '@hooks';
import { AuthLayout } from '@layouts';
import { RedirectLink } from '@ui';

const Login = () => {
    const store = useStore();
    const hasUser = store.authStore.hasUser;

    let rightCol;

    if (hasUser) {
        rightCol = (
            <RedirectLink data-e2e='login_sign-up_btn' fontSize='16px' text='Sign up' to={ROUTE_PATHS.REGISTER} />
        );
    } else {
        rightCol = (
            <RedirectLink
                data-e2e='login_request-access_link'
                text='Request Access'
                to={ROUTE_PATHS.REQUEST_ACCESS}
                fontSize='16px'
            />
        );
    }

    return (
        <AuthLayout description='Please log into your account' title='Log in' rightCol={rightCol}>
            <LoginForm />
        </AuthLayout>
    );
};

export default Login;
