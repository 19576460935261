import { COLORS } from '@constants';
import { Checkbox, Form } from 'antd';
import styled, { css } from 'styled-components';

export const StyledCheckbox = styled(Checkbox)`
    && {
        span {
            color: ${COLORS.$gray60Black};
            font-size: 16px;
            font-weight: 500;

            a {
                color: ${COLORS.$info};
            }
        }
    }
`;

export const StyledText = styled.div`
    text-align: left;
`;

export const StyledForm = styled(Form)`
    ${({ $pending }) =>
        $pending &&
        css`
            filter: blur(2px);
        `};
}
`;
