import React from 'react';
import { BOTTOM_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Button, Typography } from '@ui';
import { observer } from 'mobx-react';
import { useSetupModal } from '../../hooks/useSetupModal';
import StatusBadge from '../StatusBadge';
import { StyledFlex, StyledLine, StyledStatus } from './styled';

const LineItem = observer(({ setupKey, data, setModal, modal }) => {
    const store = useStore();
    const { openModal } = useSetupModal(store, data, modal, setupKey);

    const onModalOpen = () => {
        setModal(setupKey);
        openModal(setupKey);
    };

    return (
        <StyledLine justify='space-between' align='center' style={BOTTOM_INDENTS.M}>
            <Typography.Title level={3}>{data.title}</Typography.Title>
            <StyledFlex justify='space-between' align='center'>
                {data.viewMode ? (
                    <Button type='link' padding='0' onClick={onModalOpen}>
                        View
                    </Button>
                ) : (
                    <StyledStatus>
                        <StatusBadge status={data.status} />
                        <Button type='link' padding='0' onClick={onModalOpen}>
                            {data.status ? 'Edit' : 'Set connection'}
                        </Button>
                    </StyledStatus>
                )}
            </StyledFlex>
        </StyledLine>
    );
});

export default LineItem;
