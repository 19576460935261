import { COLORS } from '@constants';
import { ACCOUNT_STATUS } from '@enums';

export const ACCOUNT_STATUSES_MAP = {
    [ACCOUNT_STATUS.INACTIVE]: { title: 'Inactive', color: COLORS.$gray40Black },
    [ACCOUNT_STATUS.DISCONNECTED]: { title: 'Disconnected', color: COLORS.$gray40Black },
    [ACCOUNT_STATUS.ACTIVE]: { title: 'Active', color: COLORS.$robinBlue },
    [ACCOUNT_STATUS.PAUSED]: { title: 'Paused', color: COLORS.$cornflowerBlue },
    [ACCOUNT_STATUS.SHUTDOWN]: { title: 'Shutdown', color: COLORS.$gray40Black },
    [ACCOUNT_STATUS.BLOCKED]: { title: 'Blocked', color: COLORS.$gray40Black }
};
