import React from 'react';
import { COLORS, FIELD_NAME } from '@constants';
import { PLATFORM_ID } from '@enums';
import { Typography } from '@ui';
import { Col, Form, Radio, Row } from 'antd';
import CampaignTypeCard from '../CampaignTypeCard';
import CardExpandedContent from '../CardExpandedContent';

const platformDescription = (platformId) => {
    switch (platformId) {
        case PLATFORM_ID.BIGCOMMERCE:
            return `Pick the type of products you'd like to advertise. We recommend choosing your 'Entire
                    catalog', but you can also select 'Best-sellers' or 'Specific products'.
                    For each of these campaign types, Klickly will automatically pull your products into an ad campaign
                    and keep this campaign up-to-date based on changes to your catalog, best-sellers, etc. And
                    don't worry - you can review your choices on the next page.`;
        default:
            return `Pick the type of products you'd like to advertise. We recommend choosing your 'Entire
                    catalog', but you can also select 'Best-sellers', 'Specific collections',
                    'Specific product types', or 'Specific products'. For each of these campaign
                    types, Klickly will automatically pull your products into an ad campaign and keep this campaign
                    up-to-date based on changes to your catalog, best-sellers, collections, etc. And don't worry -
                    you can review your choices on the next page.`;
    }
};

const CampaignTypeCards = ({ platformId, campaignTypes = [], selectedType }) => {
    return (
        <Form.Item
            name={FIELD_NAME.CAMPAIGN.TYPE}
            rules={[{ required: true, message: 'Please, choose one of campaign types' }]}
        >
            <Radio.Group>
                <Row gutter={[16, 16]}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center', color: COLORS.$gray60Black }}>
                        <Typography.Text color={COLORS.$gray60Black} data-e2e='campaignwizard_step-1_description'>
                            {platformDescription(platformId)}
                        </Typography.Text>
                    </Col>

                    {campaignTypes.map(({ expandable, expandedContentProps, ...campaignTypeProps }) => {
                        return (
                            <Col span={12} key={campaignTypeProps.label}>
                                <CampaignTypeCard
                                    {...campaignTypeProps}
                                    expandedContent={expandable && <CardExpandedContent {...expandedContentProps} />}
                                    expanded={selectedType === campaignTypeProps.value}
                                />
                            </Col>
                        );
                    })}
                </Row>
            </Radio.Group>
        </Form.Item>
    );
};

export default CampaignTypeCards;
