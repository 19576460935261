import React from 'react';
import { Button } from '@ui';
import { StyledTypography } from '../../styled';
import Pause from '../Pause/index';
import Shutdown from '../Shutdown/index';

const Choice = ({ setModalContent, changeStatus, customClose }) => {
    return (
        <StyledTypography data-e2e='account-management_pause-and-shutdown-modal_description'>
            Your account is currently active and enjoying the benefits of Klickly. Click{' '}
            <Button
                data-e2e='account-management_pause-account-modal_link'
                type='link'
                onClick={() => {
                    setModalContent(<Pause changeStatus={changeStatus} customClose={customClose} />);
                }}
                padding={0}
                fontSize='18px'
            >
                here
            </Button>{' '}
            if you want to pause your account, or{' '}
            <Button
                data-e2e='account-management_shutdown-account-modal_link'
                type='link'
                onClick={() => {
                    setModalContent(
                        <Shutdown
                            changeStatus={changeStatus}
                            setModalContent={setModalContent}
                            customClose={customClose}
                        />
                    );
                }}
                padding={0}
                fontSize='18px'
            >
                here
            </Button>{' '}
            if you want to shut down your account. All advertising activity will cease for your entire account.
        </StyledTypography>
    );
};

export default Choice;
