import React, { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { IMAGE_FORMAT } from '@enums';
import { useStore } from '@hooks';
import { templates } from '@klickly/klickly-templates';
import { Button, Icon, Loader, Typography } from '@ui';
import { getImageFromUploadedFile } from '@utils';
import { Flex, Row, Tooltip } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import CustomCreativesUnit from './components/CustomCreativesUnit';
import InAdItem from './components/InAdItem';
import UnitItem from './components/UnitItem';
import { useAdExamplesModal } from './hooks';
import { StyledCarousel, StyledLoader, StyledQuestion } from './styled';
import { getRandomTemplates, priceDiff } from './utils';

const Preview = observer(
    ({
        logo,
        brandLink,
        brandName,
        secondaryColor,
        font = 'Arial',
        imageFormat = IMAGE_FORMAT.CROPPED,
        offerMessage,
        primaryColor
    }) => {
        const carouselRef = useRef(null);
        const store = useStore();
        const params = useParams();
        const { campaign, previewProducts, previewProductsPending, getPreviewProducts, resetPreviewProducts } =
            store.campaignWizardStore;
        const { customCreativePreviewSrc, enabledByBrand } = store.customCreativesStore;

        const promotions = useMemo(() => {
            const mappedPromotions = toJS(previewProducts).map((item) => ({
                ...item,
                brandName,
                creativeSettings: { adImageView: imageFormat },
                maxPriceDifferencePercent: priceDiff(item),
                variants: item.variants.map((variant) => ({
                    ...variant,
                    price: variant.price?.toString() ?? null,
                    originalPrice: variant.originalPrice?.toString() ?? null
                }))
            }));

            while (mappedPromotions.length && mappedPromotions.length < 8) {
                const randomIndex = Math.floor(Math.random() * mappedPromotions.length);
                mappedPromotions.push({ ...mappedPromotions[randomIndex] });
            }

            return mappedPromotions;
        }, [toJS(previewProducts)]);

        if (logo) {
            logo = typeof logo === 'string' ? logo : getImageFromUploadedFile(logo);
        }

        const randomTemplates = useMemo(() => {
            return getRandomTemplates(templates.Unit300x600.LAYOUTS, 3);
        }, [templates.Unit300x600.LAYOUTS]);

        const brandedData = useMemo(() => {
            return {
                buttonStyles: {
                    backgroundColor: primaryColor,
                    fontFamily: font
                },
                messageStyles: {
                    backgroundColor: secondaryColor,
                    color: primaryColor,
                    fontFamily: font
                },
                colors: {
                    primaryColor,
                    secondaryColor
                },
                fontStyles: { fontFamily: font },
                logoSrc: logo,
                offerMessage
            };
        }, [primaryColor, secondaryColor, offerMessage, logo, font]);

        const { openModal } = useAdExamplesModal(brandedData, promotions);

        useEffect(() => {
            if (campaign?.id === Number(params.id)) {
                getPreviewProducts();
            }

            return () => {
                resetPreviewProducts();
            };
        }, [campaign?.id]);

        return (
            <Flex data-e2e='campaignwizard_preview_block' vertical style={{ position: 'relative' }}>
                <div style={BOTTOM_INDENTS.M}>
                    <Row justify='space-between' wrap={false} align='middle' style={BOTTOM_INDENTS.L}>
                        <Typography.Title data-e2e='campaignwizard_preview_title'>Design preview</Typography.Title>
                    </Row>
                    <Flex align='center' justify='space-between' gap={10} style={BOTTOM_INDENTS.M}>
                        <Flex flex={1}>
                            <Icon.AwaIcon width='110px' height='66px' />
                        </Flex>
                        <StyledQuestion vertical flex={0} gap={8}>
                            <Typography.Text type='descriptor2' color={COLORS.$gray60Black}>
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                Your creative's appearance is powered by Klickly’s AWA technology
                            </Typography.Text>
                            <Tooltip
                                title={
                                    <React.Fragment>
                                        <Typography.Text strong color={COLORS.$white} type='badgeMedium'>
                                            What is AWA?
                                        </Typography.Text>
                                        <Typography.Text color={COLORS.$white} type='body2'>
                                            AWA (Adaptive Web Assets) is an innovative, real-time AI system that adapts
                                            ad layouts daily for optimal campaign performance.
                                        </Typography.Text>
                                    </React.Fragment>
                                }
                                trigger={['click', 'hover']}
                            >
                                <Flex gap={8} align='center'>
                                    <Icon.QuestionCircleIcon width='24px' height='24px' color={COLORS.$info} />
                                    <Typography.Text color={COLORS.$info} type='descriptor2'>
                                        What is AWA?
                                    </Typography.Text>
                                </Flex>
                            </Tooltip>
                        </StyledQuestion>
                    </Flex>
                    {previewProductsPending || !previewProducts.length ? (
                        <StyledLoader>
                            <Loader />
                        </StyledLoader>
                    ) : null}
                    {!previewProductsPending && promotions.length > 0 ? (
                        <StyledCarousel
                            autoplaySpeed={4000}
                            autoplay
                            ref={carouselRef}
                            slidesPerRow={1}
                            slidesToShow={1}
                            slidesToScroll={1}
                        >
                            <UnitItem brandedData={brandedData} promotions={promotions} layout={randomTemplates[0]} />
                            <InAdItem brandedData={brandedData} promotions={promotions} />
                            <UnitItem brandedData={brandedData} promotions={promotions} layout={randomTemplates[1]} />
                            <UnitItem brandedData={brandedData} promotions={promotions} layout={randomTemplates[2]} />
                            {customCreativePreviewSrc && enabledByBrand ? <CustomCreativesUnit /> : null}
                        </StyledCarousel>
                    ) : null}
                    <Flex justify='center'>
                        <Button data-e2e='campaignwizard_see-all-examples_btn' type='link' onClick={openModal}>
                            View all AWA design variations
                        </Button>
                    </Flex>
                </div>
                <Typography.Text data-e2e='campaignwizard_preview_description' type='body2' color={COLORS.$gray60Black}>
                    This is an example of your creative using a{' '}
                    <Typography.Text type='badgeSmall' as='span'>
                        random set of your selected products
                    </Typography.Text>
                    . The exact units will vary depending on publisher requirements and consumer preferences.
                </Typography.Text>
            </Flex>
        );
    }
);

export default Preview;
