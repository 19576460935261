import { COLORS } from '@constants';
import { Icon } from '@ui';
import styled from 'styled-components';

export const TableTitleIcon = styled(Icon.QuestionCircleIcon)`
    &:hover {
        color: ${COLORS.$gray80Black};
        cursor: pointer;
    }
`;
