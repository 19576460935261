import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Typography, WhiteCover } from '@ui';
import { Form } from 'antd';
import KlicklyInfoFields from '../KlicklyInfoFields';

const CompanyDetails = ({ form, handleFormSubmit, style }) => {
    return (
        <WhiteCover
            data-e2e='company-info_my-company_section'
            title='My Company'
            style={{ position: 'relative', ...style }}
        >
            <Typography.Text
                data-e2e='company-info_my-company_description'
                type='body2'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.M}
            >
                Your company details are used by Klickly to contact you when we have important account information to
                share (such as when you make a sale!)
            </Typography.Text>
            <Form
                data-e2e='company-info_form'
                scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
                form={form}
                onFinish={handleFormSubmit}
            >
                <KlicklyInfoFields />
            </Form>
        </WhiteCover>
    );
};

export default CompanyDetails;
