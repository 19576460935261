import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { PLATFORM_NAME } from '@enums';
import { useConnector, useStore } from '@hooks';
import { Button, Icon, Loader, Typography, WhiteCover } from '@ui';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import CoverTitle from '../CoverTitle';
import Connected from './Connected/index';
import NotConnected from './NotConnected/index';

const IntegrationShopify = observer(({ storeUrl, isValid = false }) => {
    const trimmedDomain = storeUrl.replace(/\.myshopify\.com$/, '');
    const store = useStore();
    const { getEcommerceRedirect, isFormPending } = store.authStore;
    const { companyName } = store.accountsStore;

    const { connected } = useConnector();

    const updateScopes = () => {
        getEcommerceRedirect(PLATFORM_NAME.SHOPIFY, {
            companyName,
            shop: storeUrl,
            token_type: 'online'
        });
    };

    return (
        <Spin spinning={isFormPending} indicator={<Loader />}>
            <WhiteCover
                data-e2e='integrations_connector-app_section'
                customTitle={<CoverTitle title='Shopify' icon={<Icon.ShopifyLogo />} />}
                style={BOTTOM_INDENTS.M}
                rightContent={
                    <Button
                        data-e2e='integrations_go-to-connector-app_link'
                        type='link'
                        padding='0'
                        onClick={() => {
                            window.open(`https://${storeUrl}/admin`, '_blank');
                        }}
                    >
                        Go to my Shopify store
                    </Button>
                }
            >
                <Typography.Text
                    data-e2e='integrations_connector-app_description'
                    type='body2'
                    color={COLORS.$gray60Black}
                    style={BOTTOM_INDENTS.M}
                >
                    Sync Shopify to manage inventory, product details and fulfill orders all from Shopify.
                </Typography.Text>
                {connected ? (
                    <Connected isValid={isValid} trimmedDomain={trimmedDomain} updateScopes={updateScopes} />
                ) : (
                    <NotConnected storeUrl={storeUrl} />
                )}
            </WhiteCover>
        </Spin>
    );
});

export default IntegrationShopify;
