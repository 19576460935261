import React, { useState } from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { useStore } from '@hooks';
import { Button, Divider, Icon, Typography } from '@ui';
import { Flex } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react';
import LandingUrl from './components/LandingUrl';
import Status from './components/Status';
import Upload from './components/Upload';
import { StyledBottomWrap, StyledButtons, StyledContent, StyledImagePreview, StyledWrapper } from './styled';

const CustomCreativeImage = observer(({ size = '300x600', width = 300, height = 600 }) => {
    const store = useStore();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const unit = store.customCreativesStore.getUnitBySize(size);
    const imageSrc = _.get(unit, 'localData.imageSrc');
    const validationStatus = _.get(unit, 'localData.validationStatus');

    const beforeUpload = (file) => {
        if (file.size > 1048576) {
            return setError(
                'There was an error uploading the file. Please ensure file is .jpeg or .png formatted and is less than 1 MB.'
            );
        }

        return new Promise((resolve, reject) => {
            setError(null);
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    if (img.width === width && img.height === height) {
                        resolve(file);
                    } else {
                        setError(`There was an error uploading the file. Please upload image with ${size} resolution.`);
                        reject(new Error('Invalid image dimensions'));
                    }
                };
            };
            reader.readAsDataURL(file);
        });
    };

    const handleChange = ({ file }) => {
        if (file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (file.status === 'done') {
            setLoading(false);
            const reader = new FileReader();
            reader.onload = () => {
                const base64Image = reader.result; // Base64 string of the image
                store.customCreativesStore.updateUnitField(size, 'imageSrc', base64Image);
                store.customCreativesStore.updateUnitField(size, 'validationStatus', 1);
            };
            reader.readAsDataURL(file.originFileObj);
        }
    };

    const handleImageDelete = () => {
        store.customCreativesStore.updateUnitField(size, 'imageSrc', '');
        store.customCreativesStore.updateUnitField(size, 'validationStatus', 1);
    };

    return (
        <StyledWrapper>
            <StyledContent>
                <StyledButtons style={BOTTOM_INDENTS.S} gap={16}>
                    <Flex style={{ flexBasis: imageSrc ? '50%' : '100%' }}>
                        <Upload
                            beforeUpload={beforeUpload}
                            handleChange={handleChange}
                            loading={loading}
                            uploaded={!!imageSrc}
                        />
                    </Flex>

                    {imageSrc ? (
                        <Flex style={{ flexBasis: '50%' }}>
                            <Button backgroundColor={COLORS.$white} block onClick={handleImageDelete}>
                                <Flex justify='center' align='center' gap={8}>
                                    <Icon size='24px' component={() => <Icon.TrashIcon />} />
                                    <Typography.Text type='badgeMedium' color={COLORS.$gray60Black}>
                                        Delete image
                                    </Typography.Text>
                                </Flex>
                            </Button>
                        </Flex>
                    ) : null}
                </StyledButtons>
                <Typography.Text
                    align='center'
                    type='body2'
                    color={error ? COLORS.$error : COLORS.$gray60Black}
                    style={{ width: '400px', ...BOTTOM_INDENTS.M }}
                >
                    {error ||
                        'This image will be used in ads and marketing messages. Image must be less than 1MB and in .png or .jpeg formats'}
                </Typography.Text>
                {imageSrc ? (
                    <StyledImagePreview $width={width} $height={height}>
                        <img src={imageSrc} alt='' />
                    </StyledImagePreview>
                ) : null}
                <Status validationStatus={validationStatus} />
                <StyledBottomWrap>
                    <Divider margin='24px 0' />
                    <LandingUrl />
                </StyledBottomWrap>
            </StyledContent>
        </StyledWrapper>
    );
});

export default CustomCreativeImage;
