import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Divider, Typography, WhiteCover } from '@ui';
import { Form } from 'antd';
import Attribution from './components/Attribution';
import Boost from './components/Boost';
import CampaignCommission from './components/CampaignCommission';
import ColdCustomerCommission from './components/ColdCustomerCommission';
import CreditCardRate from './components/CreditCardRate';
import NewCustomerCommission from './components/NewCustomerCommission';
import RestOfSiteCommission from './components/RestOfSiteCommission';

const Pricing = ({ form, handleFormSubmit, attributionWindow, style = {} }) => {
    return (
        <WhiteCover data-e2e='account-management_pricing_section' title='Pricing' style={{ ...style }}>
            <Typography.Text
                data-e2e='account-management_pricing_description'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.M}
            >
                Prices apply on both a campaign and account basis. A $0.50 minimum transaction fee applies to every
                order, meaning commission amounts lower than $0.50 will be converted to that amount.
            </Typography.Text>
            <CampaignCommission />
            <Divider margin='24px 0' />
            <Form scrollToFirstError={{ behavior: 'smooth', block: 'center' }} onFinish={handleFormSubmit} form={form}>
                <RestOfSiteCommission />
                <Divider margin='24px 0' />
                <NewCustomerCommission />
                <Divider margin='24px 0' />
                <ColdCustomerCommission />
            </Form>
            <Divider margin='24px 0' />
            <Attribution attributionWindow={attributionWindow} />
            <Divider margin='24px 0' />
            <Boost />
            <Divider margin='24px 0' />
            <CreditCardRate />
        </WhiteCover>
    );
};

export default Pricing;
