import { brandsOpenApi } from '@constants';
import { axiosBoa, getErrorMessage } from '@utils';
import { flow, getParent, types } from 'mobx-state-tree';
import { models } from './constants';

export const ProductsStore = types
    .model('ProductsStore', {
        products: types.array(models.ProductModel),
        productTypes: types.array(
            types.model({
                id: types.number,
                title: types.string
            })
        ),
        productCollections: types.array(
            types.model({
                id: types.number,
                title: types.string
            })
        ),
        meta: types.maybeNull(
            types.model({
                page: types.maybeNull(types.number),
                perPage: types.maybeNull(types.number),
                total: types.maybeNull(types.number),
                totalPages: types.maybeNull(types.number)
            })
        ),
        filters: types.model({
            klickly_statuses: types.maybe(types.array(types.number)),
            ecommerce_statuses: types.maybe(types.array(types.number)),
            typeIds: types.maybe(types.array(types.number)),
            search: types.maybe(types.string)
        }),
        sorters: types.model({
            sort: 'title',
            order: 'asc'
        }),
        pending: false
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => ({
        setPending(pending) {
            self.pending = pending;
        },
        resetFilters() {
            self.filters = {};
        },
        updateFilters(filters) {
            Object.assign(self.filters, filters);
        },
        resetSorters() {
            self.sorters = {
                sort: 'title',
                order: 'asc'
            };
        },
        updateSorters(sorters) {
            Object.assign(self.sorters, sorters);
        },
        getProduct(productId) {
            return self.products.find((product) => product.id === productId);
        },
        getProducts: flow(function* getProducts(params = {}) {
            self.pending = true;

            Object.assign(params, self.filters, self.sorters);

            try {
                const data = yield axiosBoa.get(brandsOpenApi.products.list, { params });
                self.products = data.data;
                self.meta = data.meta;
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pending = false;
            }
        }),
        getProductVariants: flow(function* getProductVariants(productId, page = 1) {
            try {
                const product = self.getProduct(productId);
                product.variants.loading = true;

                const response = yield axiosBoa.get(brandsOpenApi.productVariants.list(productId), {
                    params: {
                        page,
                        limit: 10
                    }
                });

                Object.assign(product.variants, response);

                product.variants.loading = false;
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pending = false;
            }
        }),
        getProductTypes: flow(function* getProductTypes(params = {}) {
            self.typesPending = true;
            try {
                const result = yield axiosBoa.get(brandsOpenApi.productTypes.list, { params });
                self.productTypes = result.data;
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.typesPending = false;
            }
        }),
        getProductCollections: flow(function* getProductCollections(params = {}) {
            self.collectionsPending = true;
            try {
                const result = yield axiosBoa.get(brandsOpenApi.productCollections.list, { params });
                self.productTypes = result.data;
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.collectionsPending = false;
            }
        })
    }));
