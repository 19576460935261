import React from 'react';
import { COLORS } from '@constants';
import { useStore } from '@hooks';
import { Button, Typography } from '@ui';
import { Flex } from 'antd';
import { observer } from 'mobx-react';
import { StyledAlert } from '../../styled';

const Incomplete = observer(() => {
    const { accountsStore } = useStore();

    return (
        <StyledAlert>
            <Flex justify='space-between' align='center'>
                <Typography.Text
                    data-e2e='payments_stripe-incomplete-status_title'
                    color={COLORS.$tartRed}
                    type='descriptor1'
                >
                    Stripe has requested additional information to process your payouts
                </Typography.Text>
                <Button
                    data-e2e='payments_stripe-update_btn'
                    style={{ width: 184 }}
                    block
                    type='primary'
                    onClick={async () => {
                        await accountsStore.handleUpdateStripeAccount('account_onboarding');
                    }}
                >
                    Update
                </Button>
            </Flex>
        </StyledAlert>
    );
});

export default Incomplete;
