import { brandsOpenApi, INITIAL_CCC_COMMISSION, INITIAL_NCC_COMMISSION, INITIAL_ROS_COMMISSION } from '@constants';
import {
    ACCOUNT_STATUS,
    ADDRESS_TYPE,
    CAMPAIGN_STATUS,
    CONNECTOR_KLICKLY_STATUS,
    CONTACT_TYPE,
    CONTACT_VALUE_TYPE,
    CONTENT_TYPE,
    DOMAIN_CLASS,
    DOMAIN_STATUS,
    PLATFORM_ID,
    PLATFORM_NAME_BY_PLATFORM_ID,
    PURCHASE_EVENT_STATUS,
    SCRIPT_TAG_STATUS,
    SCRIPT_TAG_STATUS_TITLE,
    TOKEN_STATUS
} from '@enums';
import { axiosBoa, getErrorMessage } from '@utils';
import dayjs from 'dayjs';
import _ from 'lodash';
import { destroy, flow, getParent, types } from 'mobx-state-tree';
import config from '../config';
import {
    Address,
    Contact,
    CurrentAccountModel,
    SetupInfo,
    State,
    TeamMember,
    UnsubscribeList,
    Verification
} from './constants';
import { mapPaymentMethod } from './utils';

export const AccountsStore = types
    .model('AccountsStore', {
        error: types.maybeNull(types.model({ title: types.string, description: types.string })),
        currentAccount: CurrentAccountModel,
        // * setup info
        setupInfoPending: types.maybeNull(types.boolean),
        accountStatusPending: types.maybeNull(types.boolean),
        setupInfo: types.maybeNull(SetupInfo),
        // * states
        states: types.array(State),
        // * team members
        uiTeamMembers: types.array(TeamMember),
        bdTeamMembers: types.maybe(types.frozen(types.array(TeamMember))),
        // * contacts, addresses
        contactsForCustomers: types.array(Contact),
        addressForCustomers: types.maybe(Address),
        contactsForKlickly: types.array(Contact),
        addressForKlickly: types.maybe(Address),
        notificationContacts: types.array(Contact),
        // * others
        unsubscribeList: types.array(UnsubscribeList),
        verification: types.maybeNull(Verification),
        pending: true,
        hasLaunchedCampaign: false,
        inviteCompletePending: false,
        purchaseSetupPending: false,
        historicalSetupOrdersPending: false,
        historicalSetupCustomersPending: false,
        scriptTagSetupPending: types.maybeNull(types.boolean)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get platformName() {
            return PLATFORM_NAME_BY_PLATFORM_ID[self.currentAccount.platformId];
        },
        get isShopify() {
            return self.currentAccount?.platformId === PLATFORM_ID.SHOPIFY;
        },
        get isBigCommerce() {
            return self.currentAccount?.platformId === PLATFORM_ID.BIGCOMMERCE;
        },
        get isManual() {
            return self.currentAccount?.platformId === PLATFORM_ID.KLICKLY;
        },
        get productsSyncProgress() {
            return self?.currentAccount?.syncProgress.find((progress) => progress.resourceName === 'products');
        },
        get hasProducts() {
            return (
                self?.currentAccount?.syncProgress.find((progress) => progress.resourceName === 'products')
                    ?.totalCount > 0
            );
        },
        get logoUrl() {
            return self?.currentAccount?.logoUrl;
        },
        get stripeAccountStatus() {
            return self?.currentAccount?.stripeSettings?.stripeAccountStatus;
        },
        get companyName() {
            return self?.currentAccount?.companyName;
        },
        get companyInitials() {
            if (!self.companyName) {
                return '';
            }

            const words = self.companyName.split(' ');
            let initials = '';

            if (words.length >= 2) {
                initials = words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase();
            } else if (words.length === 1) {
                initials = words[0].charAt(0).toUpperCase();
            }

            return initials;
        },
        get isPaused() {
            return self.currentAccount?.status === ACCOUNT_STATUS.PAUSED;
        },
        get isActive() {
            return self.currentAccount?.status === ACCOUNT_STATUS.ACTIVE;
        },
        get isShutDown() {
            return self.currentAccount?.status === ACCOUNT_STATUS.SHUTDOWN;
        },
        get isPaymentOnHold() {
            return self.currentAccount?.stripeSettings?.holdPayments;
        },
        get apiToken() {
            return self.setupInfo?.apiTokens?.find((token) => token.status === TOKEN_STATUS.ACTIVE)?.token || '';
        },
        get isUninstalled() {
            return self.currentAccount?.status === ACCOUNT_STATUS.DISCONNECTED;
        },
        get isBlocked() {
            return self.currentAccount?.status === ACCOUNT_STATUS.BLOCKED;
        },
        get isConnectorConnected() {
            return self.currentAccount?.connectorStatus === CONNECTOR_KLICKLY_STATUS.CONNECTED;
        },
        get hasAccounts() {
            // return Boolean(self.accounts.length); // TODO: uncomment/delete after review logic
            return true;
        },
        get hasPaymentMethod() {
            return !!self.setupInfo?.paymentMethod;
        },
        get hasGUID() {
            return !!self.currentAccount?.thirdPartyAppsSettings?.transUnionGUID;
        },
        get hasGUIDRequest() {
            return !!self.currentAccount?.thirdPartyAppsSettings?.transUnionRequestMessage;
        },
        get commissionsApplied() {
            const commission = self.currentAccount?.commission;

            if (!commission) return false;

            return (
                commission.restOfSite !== INITIAL_ROS_COMMISSION ||
                commission.newCustomer !== INITIAL_NCC_COMMISSION ||
                commission.coldCustomer !== INITIAL_CCC_COMMISSION
            );
        },
        get manualStoreSetupFinished() {
            return (
                self.setupInfo.scriptTag?.status === SCRIPT_TAG_STATUS.CONNECTED &&
                self.setupInfo.purchaseEventStatus === PURCHASE_EVENT_STATUS.CONNECTED &&
                self.setupInfo.productCatalog > 0
            );
        },
        get scriptTagIntegrationStatus() {
            const connected = self.currentAccount?.domainScripts?.every(
                ({ status }) => status === SCRIPT_TAG_STATUS.CONNECTED
            );

            if (connected) {
                return SCRIPT_TAG_STATUS_TITLE.CONNECTED;
            }

            const pending = self.currentAccount?.domainScripts?.some(
                ({ status }) => status === SCRIPT_TAG_STATUS.PENDING
            );

            const hasDspQueryParams = self.currentAccount?.domainScripts.every(
                ({ templateVariables }) => !!templateVariables.searchParams.b_type_visit
            );

            if (pending || !hasDspQueryParams) {
                return SCRIPT_TAG_STATUS_TITLE.PENDING;
            }

            return SCRIPT_TAG_STATUS_TITLE.NOT_CONNECTED;
        },
        get paymentMethod() {
            return self.setupInfo?.paymentMethod;
        },
        getInitValuesUnsubscribe() {
            return self.unsubscribeList.reduce((initialValues, account) => {
                initialValues[account.contactId] = account.notifications;
                return initialValues;
            }, {});
        },
        get showSetupPayoutsModal() {
            return (
                self.currentAccount?.settings?.showModal?.setupPayouts &&
                !self.currentAccount.stripeSettings.stripeAccountId
            );
        },
        get commissionOffsetEnabled() {
            return self.currentAccount?.commission?.commissionOffsetEnabled;
        },
        get registerDate() {
            return dayjs(self.currentAccount?.createdAt).format('MMM DD, YYYY');
        },
        get scriptTag() {
            return self.setupInfo?.scriptTag?.src;
        },
        get shopDomain() {
            return self.currentAccount?.domains?.[0]?.shopDomain;
        },
        get mainEcommerceShopDomain() {
            return self.currentAccount?.domains.find((domain) => domain.class === DOMAIN_CLASS.MAIN_ECOMMERCE)
                ?.shopDomain;
        },
        get primaryShopDomain() {
            return self.currentAccount?.domains.find((domain) => domain.isPrimary === true)?.shopDomain;
        },
        get domainsUrls() {
            return self.currentAccount.domains.map((domain) => domain.shopDomain);
        },
        get settingsLink() {
            if (self.currentAccount.platformId === PLATFORM_ID.SHOPIFY) {
                const shopDomain = self.mainEcommerceShopDomain;
                return `https://admin.shopify.com/store/${shopDomain?.replace(/\.myshopify\.com$/, '')}/admin/settings`;
            } else if (self.currentAccount.platformId === PLATFORM_ID.BIGCOMMERCE) {
                return `https://store-${self.currentAccount.externalId}.mybigcommerce.com/manage/settings-list`;
            } else {
                return '';
            }
        },
        get shopifySettingsLink() {
            const shopDomain = self.canonicalShopDomain;
            return `https://admin.shopify.com/store/${shopDomain?.replace(/\.myshopify\.com$/, '')}`;
        },
        get bigcommerceSettingsLink() {
            const shopDomain = self.canonicalShopDomain;
            return `https://${shopDomain}`;
        },
        // * contacts, addresses
        get klicklyEmail() {
            return self.contactsForKlickly?.find((contact) => contact.valueType === CONTACT_VALUE_TYPE.EMAIL);
        },
        get klicklyPhone() {
            return self.contactsForKlickly?.find((contact) => contact.valueType === CONTACT_VALUE_TYPE.PHONE);
        },
        get customersEmail() {
            return self.contactsForCustomers?.find((contact) => contact.valueType === CONTACT_VALUE_TYPE.EMAIL);
        },
        get customersPhone() {
            return self.contactsForCustomers?.find((contact) => contact.valueType === CONTACT_VALUE_TYPE.PHONE);
        },
        get isCustomerInfoValid() {
            return !!self.customersEmail?.value && !!self.customersPhone?.value;
        },
        get isKlicklyInfoValid() {
            return !!self.klicklyEmail?.value && !!self.klicklyPhone?.value && !!self.addressForKlickly?.confirmed;
        },
        get trimmedDomain() {
            if (!Array.isArray(self.currentAccount?.domains)) return '';
            const domain = self.currentAccount.domains.find((domain) => domain.status === DOMAIN_STATUS.VERIFIED);
            return domain ? domain.shopDomain.replace(/\.myshopify\.com$/, '') : '';
        },
        get customCreativesEnabled() {
            return self.currentAccount?.settings?.customCreativesEnabled;
        }
    }))
    .actions((self) => ({
        // * Account
        getCurrentAccount: flow(function* getCurrentAccount() {
            self.error = null;
            try {
                const account = yield axiosBoa.get(brandsOpenApi.accounts.getCurrent, {
                    params: {
                        include:
                            'domains,domain_scripts,third_party_apps_settings,account_products_sync_progress,klickly_api_tokens,commission,stripe_settings,settings'
                    }
                });
                // just rename
                account.syncProgress = account.accountProductsSyncProgress;
                delete account.accountProductsSyncProgress;

                if (self.currentAccount) {
                    Object.assign(self.currentAccount, account);
                } else {
                    self.currentAccount = account;
                }
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.pending = false;
            }
        }),
        updateAccount: flow(function* updateAccount(data, type = CONTENT_TYPE.FORM) {
            self.error = null;

            const contentType = {
                json: 'application/json',
                form: 'multipart/form-data'
            };

            try {
                const account = yield axiosBoa.patch(brandsOpenApi.accounts.update, data, {
                    headers: {
                        'Content-Type': contentType[type]
                    }
                });

                Object.assign(self.currentAccount, account);
                self.root.authStore.updateAccount(self.currentAccount.id, account);
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        // * syncProgress
        getAccountSyncProgress: flow(function* getAccountSyncProgress() {
            self.error = null;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.accounts.getCurrent, {
                    params: {
                        select: 'account_products_sync_progress'
                    }
                });

                response.accountProductsSyncProgress.forEach((newItem) => {
                    const existingIndex = _.findIndex(self.currentAccount.syncProgress, { id: newItem.id });
                    if (existingIndex !== -1) {
                        _.assign(self.currentAccount.syncProgress[existingIndex], newItem);
                    } else {
                        self.currentAccount.syncProgress.push(newItem);
                    }
                });
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        // * Addresses
        getAddressForCustomers: flow(function* getAddressForCustomers() {
            self.error = null;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.addresses.list, {
                    params: {
                        type: [ADDRESS_TYPE.FOR_CUSTOMER]
                    }
                });
                self.addressForCustomers = response.data[0];
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        getAddressForKlickly: flow(function* getAddressForCustomers() {
            self.error = null;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.addresses.list, {
                    params: {
                        type: [ADDRESS_TYPE.FOR_KLICKLY]
                    }
                });
                self.addressForKlickly = response.data[0];
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        // * Contacts
        getContactsForCustomers: flow(function* getContactsForCustomers() {
            self.error = null;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.contacts.list, {
                    params: {
                        type: [CONTACT_TYPE.FOR_CUSTOMER]
                    }
                });

                self.contactsForCustomers = response.data;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        getContactsForKlickly: flow(function* getContactsForCustomers() {
            self.error = null;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.contacts.list, {
                    params: { type: [CONTACT_TYPE.FOR_KLICKLY] }
                });

                self.contactsForKlickly = response.data;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        getNotificationContacts: flow(function* getNotificationContacts() {
            self.error = null;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.contacts.list, {
                    params: {
                        limit: 50,
                        type: CONTACT_TYPE.NOTIFICATION
                    }
                });

                self.notificationContacts = response.data.map((contact) => ({
                    ...contact,
                    value:
                        contact.valueType === CONTACT_VALUE_TYPE.PHONE ? contact.value.replace('+', '') : contact.value
                }));
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        getUnsubscribeList: flow(function* unsubscribe(token) {
            self.error = null;
            try {
                self.unsubscribeList = yield axiosBoa.get(brandsOpenApi.notifications.unsubscribeList(token));
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        updateContacts: flow(function* updateContacts(body) {
            self.error = null;
            try {
                yield axiosBoa.patch(brandsOpenApi.notifications.unsubscribe, body);
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        resetNotificationContacts() {
            self.notificationContacts = [];
        },
        //  * Campaigns
        checkLaunchedCampaign: flow(function* checkLaunchedCampaign() {
            self.error = null;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.campaigns.list, {
                    params: {
                        statuses: [CAMPAIGN_STATUS.ACTIVE],
                        limit: 1
                    }
                });

                self.hasLaunchedCampaign = !!response.data.length;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        // * Commissions
        getAccountCommissions: flow(function* getAccountCommissions() {
            self.error = null;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.accounts.getCurrent, {
                    params: { select: 'commission' }
                });
                self.currentAccount.commission = response.commission;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        updateCommissions: flow(function* updateCommissions(commission) {
            self.error = null;
            try {
                const response = yield axiosBoa.patch(brandsOpenApi.accounts.update, { commission });

                Object.assign(self.currentAccount.commission, response.commission);
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        // * productTypes
        getAccountProductTypes: flow(function* getAccountProductTypes() {
            self.error = null;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.accounts.getCurrent, {
                    params: { select: 'product_types' }
                });

                self.currentAccount.productTypes = response.productTypes.filter(({ title }) => Boolean(title));
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        resetProductTypes() {
            self.currentAccount.productTypes = [];
        },
        // * collections
        getAccountCollections: flow(function* getAccountCollections() {
            self.error = null;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.accounts.getCurrent, {
                    params: {
                        select: 'product_collections'
                    }
                });

                self.currentAccount.productCollections = response.productCollections;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        resetProductCollections() {
            self.currentAccount.productCollections = [];
        },
        // * Settings
        getAccountSettings: flow(function* getAccountSettings() {
            self.error = null;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.accounts.getCurrent, {
                    params: {
                        select: 'settings,attribution_window_settings'
                    }
                });

                self.currentAccount.settings = response.settings;
                self.currentAccount.attributionWindowSettings = response.attributionWindowSettings;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        getTrackingSettings: flow(function* getTrackingSettings() {
            self.error = null;
            try {
                const account = yield axiosBoa.get(brandsOpenApi.accounts.getCurrent, {
                    params: {
                        select: 'ga4_settings,utm_settings,triple_whale_settings'
                    }
                });

                self.currentAccount.ga4Settings = account.ga4Settings;
                self.currentAccount.utmSettings = account.utmSettings;
                self.currentAccount.tripleWhaleSettings = account.tripleWhaleSettings;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        updateTrackingSettings: flow(function* updateTrackingSettings(settings) {
            self.error = null;
            try {
                const updatedAccount = yield axiosBoa.patch(brandsOpenApi.accounts.update, settings);

                self.currentAccount.ga4Settings = updatedAccount.ga4Settings;
                self.currentAccount.utmSettings = updatedAccount.utmSettings;
                self.currentAccount.tripleWhaleSettings = updatedAccount.tripleWhaleSettings;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        updateCommissionOffset: flow(function* updateCommissionOffset(commissionOffset) {
            try {
                const updatedAccount = yield axiosBoa.patch(brandsOpenApi.accounts.update, commissionOffset);

                self.currentAccount.commission.commissionOffsetEnabled =
                    updatedAccount.commission.commissionOffsetEnabled;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),

        //  * Setup info
        getSetupInfo: flow(function* getSetupInfo() {
            try {
                const response = yield axiosBoa.get(brandsOpenApi.setup.info);

                response.paymentMethod = mapPaymentMethod(response.paymentMethod);

                self.setupInfo = response;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        // * States
        getStates: flow(function* getStates() {
            self.error = null;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.taxSettings.get);
                self.states = response.data;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        // * Team Members
        getTeamMembers: flow(function* getTeamMembers() {
            self.error = null;
            try {
                const response = yield axiosBoa.get(brandsOpenApi.teamMembers.list);
                self.uiTeamMembers = response?.data;
                self.bdTeamMembers = response?.data;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        removeUIMember(member) {
            destroy(member);
        },
        addUIMember(member) {
            const teamMember = self.uiTeamMembers.find((m) => m.user.email === member.user.email);

            if (teamMember) {
                throw new Error('User with such email is already added member of your account');
            }

            self.uiTeamMembers.push(member);
        },
        resetUIMembers() {
            self.uiTeamMembers = self.bdTeamMembers;
        },
        // * Handle customers info form save (launch check)
        customerInfoFormUpdate: flow(function* customerInfoFormUpdate(values) {
            self.error = null;
            try {
                const [updatedEmail, updatedPhone, updatedAddress] = yield Promise.all([
                    axiosBoa.patch(brandsOpenApi.contacts.update(self.customersEmail.id), {
                        type: CONTACT_TYPE.FOR_CUSTOMER,
                        confirmed: true,
                        valueType: CONTACT_VALUE_TYPE.EMAIL,
                        value: values.email
                    }),
                    axiosBoa.patch(brandsOpenApi.contacts.update(self.customersPhone.id), {
                        type: CONTACT_TYPE.FOR_CUSTOMER,
                        confirmed: true,
                        valueType: CONTACT_VALUE_TYPE.PHONE,
                        value: values.phone ? `+${values.phone}` : values.phone
                    }),
                    axiosBoa.patch(brandsOpenApi.addresses.update(self.addressForCustomers.id), {
                        country: 'US',
                        province: values.province,
                        city: values.city,
                        address1: values.address1,
                        address2: values.address2,
                        zipCode: values.zipCode?.toString() || '',
                        confirmed: true
                    })
                ]);

                Object.assign(self.customersEmail, updatedEmail);
                Object.assign(self.customersPhone, updatedPhone);
                Object.assign(self.addressForCustomers, updatedAddress);
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        klicklyInfoFormUpdate: flow(function* klicklyInfoFormUpdate(values) {
            self.error = null;
            try {
                const [updatedEmail, updatedPhone, updatedAddress] = yield Promise.all([
                    axiosBoa.patch(brandsOpenApi.contacts.update(self.klicklyEmail.id), {
                        type: CONTACT_TYPE.FOR_KLICKLY,
                        confirmed: true,
                        valueType: CONTACT_VALUE_TYPE.EMAIL,
                        value: values.email
                    }),
                    axiosBoa.patch(brandsOpenApi.contacts.update(self.klicklyPhone.id), {
                        type: CONTACT_TYPE.FOR_KLICKLY,
                        confirmed: true,
                        valueType: CONTACT_VALUE_TYPE.PHONE,
                        value: values.phone ? `+${values.phone}` : values.phone
                    }),
                    axiosBoa.patch(brandsOpenApi.addresses.update(self.addressForKlickly.id), {
                        country: 'US',
                        province: values.province,
                        city: values.city,
                        address1: values.address1,
                        address2: values.address2,
                        zipCode: values.zipCode?.toString() || '',
                        confirmed: true
                    })
                ]);

                Object.assign(self.klicklyEmail, updatedEmail);
                Object.assign(self.klicklyPhone, updatedPhone);
                Object.assign(self.addressForKlickly, updatedAddress);
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),

        // * Handle settings pages save
        companyInfoUpdate: flow(function* companyInfoUpdate(values) {
            self.error = null;
            try {
                const [{ companyName }, updatedEmail, updatedPhone, updatedAddress] = yield Promise.all([
                    axiosBoa.patch(brandsOpenApi.accounts.update, {
                        companyName: values.companyName
                    }),
                    axiosBoa.patch(brandsOpenApi.contacts.update(self.klicklyEmail.id), {
                        type: CONTACT_TYPE.FOR_KLICKLY,
                        confirmed: true,
                        valueType: CONTACT_VALUE_TYPE.EMAIL,
                        value: values.email
                    }),
                    axiosBoa.patch(brandsOpenApi.contacts.update(self.klicklyPhone.id), {
                        type: CONTACT_TYPE.FOR_KLICKLY,
                        confirmed: true,
                        valueType: CONTACT_VALUE_TYPE.PHONE,
                        value: `+${values.phone}`
                    }),
                    axiosBoa.patch(brandsOpenApi.addresses.update(self.addressForKlickly.id), {
                        country: 'US',
                        province: values.province,
                        city: values.city,
                        address1: values.address1,
                        address2: values.address2,
                        zipCode: values.zipCode?.toString() || '',
                        confirmed: true
                    })
                ]);

                self.root.authStore.updateAccount(self.currentAccount.id, { companyName });
                self.currentAccount.companyName = companyName;
                Object.assign(self.klicklyEmail, updatedEmail);
                Object.assign(self.klicklyPhone, updatedPhone);
                Object.assign(self.addressForKlickly, updatedAddress);
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        handleAccountManagementSave: flow(function* handleAccountManagementSave({
            coldCustomer,
            newCustomer,
            restOfSite,
            membersToCreate,
            membersToUpdate,
            membersToDelete
        }) {
            self.error = null;

            try {
                yield Promise.allSettled([
                    self.updateCommissions({
                        coldCustomer,
                        newCustomer,
                        restOfSite
                    }),
                    ...membersToDelete.map((member) => axiosBoa.delete(brandsOpenApi.teamMembers.delete(member.id))),
                    ...membersToUpdate.map((member) =>
                        axiosBoa.patch(brandsOpenApi.teamMembers.update(member.id), member)
                    )
                ]);

                yield Promise.allSettled(
                    membersToCreate.map((member) => axiosBoa.post(brandsOpenApi.teamMembers.create, member))
                );

                if (membersToCreate.length || membersToUpdate.length || membersToDelete.length) {
                    yield self.getTeamMembers();
                }
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        customerManagementUpdate: flow(function* customerManagementUpdate(values) {
            self.error = null;
            try {
                const promises = [];
                const isChanged = JSON.stringify(self.states) !== JSON.stringify(values.statesList);

                if (isChanged) {
                    const updateTaxSettings = axiosBoa.post(brandsOpenApi.taxSettings.set, {
                        states: values.statesList
                    });
                    promises.push(updateTaxSettings);
                }

                const [updatedEmail, updatedPhone, updatedAddress, updatedAccount] = yield Promise.all([
                    axiosBoa.patch(brandsOpenApi.contacts.update(self.customersEmail.id), {
                        type: CONTACT_TYPE.FOR_CUSTOMER,
                        confirmed: true,
                        valueType: CONTACT_VALUE_TYPE.EMAIL,
                        value: values.email
                    }),
                    axiosBoa.patch(brandsOpenApi.contacts.update(self.customersPhone.id), {
                        type: CONTACT_TYPE.FOR_CUSTOMER,
                        confirmed: true,
                        valueType: CONTACT_VALUE_TYPE.PHONE,
                        value: values.phone ? `+${values.phone}` : values.phone
                    }),
                    axiosBoa.patch(brandsOpenApi.addresses.update(self.addressForCustomers.id), {
                        country: 'US',
                        province: values.province,
                        city: values.city,
                        address1: values.address1,
                        address2: values.address2,
                        zipCode: values.zipCode?.toString() || '',
                        confirmed: true
                    }),
                    axiosBoa.patch(brandsOpenApi.accounts.update, {
                        packageSize: {
                            length: values.length,
                            width: values.width,
                            height: values.height,
                            weight: values.weight
                        }
                    }),
                    ...promises
                ]);

                Object.assign(self.customersEmail, updatedEmail);
                Object.assign(self.customersPhone, updatedPhone);
                Object.assign(self.addressForCustomers, updatedAddress);
                Object.assign(self.currentAccount.settings.packageSize, updatedAccount.settings.packageSize);

                self.states = values.statesList;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        handleNotificationContactsSave: flow(function* handleNotificationContactsSave({
            contactsToCreate,
            contactsToUpdate,
            contactsToDelete
        }) {
            self.error = null;
            try {
                yield Promise.allSettled([
                    ...contactsToCreate.map((contact) =>
                        axiosBoa.post(brandsOpenApi.contacts.create, {
                            ...contact,
                            type: CONTACT_TYPE.NOTIFICATION,
                            value: contact.valueType === CONTACT_VALUE_TYPE.PHONE ? `+${contact.value}` : contact.value
                        })
                    ),
                    ...contactsToUpdate.map((contact) =>
                        axiosBoa.patch(brandsOpenApi.contacts.update(contact.id), {
                            ...contact,
                            type:
                                contact.id === self.klicklyEmail.id
                                    ? CONTACT_TYPE.FOR_KLICKLY
                                    : CONTACT_TYPE.NOTIFICATION,
                            value: contact.valueType === CONTACT_VALUE_TYPE.PHONE ? `+${contact.value}` : contact.value
                        })
                    ),
                    ...contactsToDelete.map((contact) => axiosBoa.delete(brandsOpenApi.contacts.delete(contact.id)))
                ]);
                yield self.getNotificationContacts();
                if (contactsToUpdate.find((contact) => contact.id === self.klicklyEmail.id)) {
                    yield self.getContactsForKlickly();
                }
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        // * Stripe connected acount
        handleCreateStripeAccount: flow(function* handleCreateStripeAccount() {
            self.error = null;

            try {
                const connectOnboardingLink = yield axiosBoa.post(brandsOpenApi.accounts.createStripeAccount);
                window.location.href = connectOnboardingLink.url;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        handleUpdateStripeAccount: flow(function* handleUpdateStripeAccount(type) {
            self.error = null;

            try {
                const connectOnboardingLink = yield axiosBoa.get(brandsOpenApi.accounts.updateStripeAccount(type));
                window.location.href = connectOnboardingLink.url;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        handleConnectToStripeAccount() {
            self.error = null;

            const params = new URLSearchParams({
                response_type: 'code',
                client_id: config.stripeClientId,
                scope: 'read_write',
                redirect_uri: `${window.location.origin}/profile/account-settings/payments-settings`
            });

            const link = `https://connect.stripe.com/oauth/authorize?${params.toString()}`;
            window.location.href = link;
        },
        stripeAccountOauthCallback: flow(function* stripeAccountOauthCallback(code) {
            self.error = null;
            try {
                const { status } = yield axiosBoa.post(brandsOpenApi.accounts.stripeOauthCallback, { code });
                self.currentAccount.stripeSettings.stripeAccountStatus = status;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        stripeAccountInfo: flow(function* stripeAccountInfo() {
            self.error = null;
            self.accountStatusPending = true;
            try {
                const { status } = yield axiosBoa.get(brandsOpenApi.accounts.stripeAccountInfo);
                self.currentAccount.stripeSettings.stripeAccountStatus = status;
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.accountStatusPending = false;
            }
        }),

        //  * Others
        setupScriptTag: flow(function* setupScriptTag() {
            self.error = null;
            self.scriptTagSetupPending = true;
            try {
                yield axiosBoa.post(brandsOpenApi.setup.processor, { type: 'script-tag' });
                self.currentAccount.domainScripts.forEach(
                    (domainScript) => (domainScript.status = SCRIPT_TAG_STATUS.PENDING)
                );
                self.setupInfo.scriptTag.status = SCRIPT_TAG_STATUS.PENDING;
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.scriptTagSetupPending = false;
            }
        }),
        setupPurchase: flow(function* setupPurchase() {
            self.error = null;
            self.purchaseSetupPending = true;
            try {
                const response = yield axiosBoa.post(brandsOpenApi.setup.processor, { type: 'purchase' });
                self.setupInfo.purchaseEventStatus = response.status;
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.purchaseSetupPending = false;
            }
        }),
        setupHistorical: flow(function* setupHistorical(type) {
            self.error = null;
            if (type === 'orders') {
                self.historicalSetupOrdersPending = true;
            } else {
                self.historicalSetupCustomersPending = true;
            }
            try {
                yield axiosBoa.post(brandsOpenApi.setup.processor, { type: 'historical' });
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        getDomainScripts: flow(function* getDomainScripts() {
            self.error = null;
            try {
                const account = yield axiosBoa.get(brandsOpenApi.accounts.getCurrent, {
                    params: { select: 'domain_scripts' }
                });
                Object.assign(self.currentAccount, account);
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        paymentIntent: flow(function* paymentIntent() {
            self.error = null;

            try {
                const response = yield axiosBoa.post(brandsOpenApi.setup.paymentIntent.get);
                return response;
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        paymentMethodUpdate: flow(function* paymentMethodUpdate(data = {}) {
            self.error = null;

            try {
                const response = yield axiosBoa.post(brandsOpenApi.accounts.paymentMethodUpdate, data);
                self.setupInfo.paymentMethod = mapPaymentMethod(response.paymentMethod);
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        verifyToken: flow(function* verifyToken(token) {
            self.error = null;

            try {
                self.verification = yield axiosBoa.get(brandsOpenApi.teamMembers.verifyToken(token));
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        }),
        onInviteComplete: flow(function* onInviteComplete(action, token, values) {
            self.error = null;
            self.inviteCompletePending = true;

            try {
                yield axiosBoa.post(brandsOpenApi.teamMembers.userComplete, { action, token, ...values });
            } catch (error) {
                self.error = getErrorMessage(error);
            } finally {
                self.inviteCompletePending = false;
            }
        }),
        setGUIDRequestMessage(message) {
            self.currentAccount.thirdPartyAppsSettings.transUnionRequestMessage = message;
        }
    }));
