import React from 'react';
import { Navigate, useMatch, useSearchParams } from 'react-router-dom';
import { COLORS, ROUTE_PATHS } from '@constants';
import { useStore } from '@hooks';
import { Divider, Logo } from '@ui';
import { Flex, Layout as AntdLayout } from 'antd';
import { observer } from 'mobx-react';
import SetupWizard from '../../components/SetupWizard';
import CampaignWizardFooter from '../../pages/CampaignWIzard/components/StickyFooter';
import HelpCenter from '../../pages/HelpCenter';
import Perks from '../../pages/Perks';
import Footer from './components/Footer';
import MainMenu from './components/MainMenu';
import TopNotificationsSection from './components/TopNotificationsSection';
import UserInfoWithDropDownMenu from './components/UserInfoWithDropDownMenu';
import { StyledContent, StyledHeader, StyledInnerWrapper } from './styled';

const MainLayout = observer(({ children }) => {
    const [searchParams] = useSearchParams();
    const isPerksPage = useMatch(ROUTE_PATHS.PERKS);
    const isHelpCenter = useMatch(ROUTE_PATHS.HELP_CENTER);

    const showModalSetup = !!searchParams.get('setup');

    const store = useStore();
    const { oauthPayload, embeddedLoadPayload } = store.authStore;

    if (oauthPayload || embeddedLoadPayload) {
        return <Navigate to={ROUTE_PATHS.CONNECTOR} replace />;
    }

    return (
        <AntdLayout>
            {showModalSetup ? <SetupWizard /> : null}
            <StyledHeader>
                <StyledInnerWrapper>
                    <Flex data-e2e='header_section' align='center' justify='space-between'>
                        <span data-e2e='header_klickly_logo'>
                            <Logo />
                        </span>
                        <MainMenu />
                        <UserInfoWithDropDownMenu />
                    </Flex>
                </StyledInnerWrapper>
            </StyledHeader>
            <TopNotificationsSection />
            {isPerksPage ? <Perks /> : isHelpCenter ? <HelpCenter /> : <StyledContent>{children}</StyledContent>}
            <StyledInnerWrapper>
                <Divider style={{ borderBlockStart: `1px solid ${COLORS.$gray20Black}` }} margin='48px 0' />
            </StyledInnerWrapper>
            <CampaignWizardFooter />
            <Footer />
        </AntdLayout>
    );
});

export default MainLayout;
