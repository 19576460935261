import React, { Fragment } from 'react';
import { BOTTOM_INDENTS, COLORS, LEFT_INDENTS } from '@constants';
import { useStore } from '@hooks';
import NextButton from '@pages/CampaignWIzard/components/NextStepButton';
import { Icon, Progress, Tooltip, Typography } from '@ui';
import { Flex, Skeleton } from 'antd';
import { observer } from 'mobx-react';
import { useCampaignWizard } from '../../hooks/useCampaignWizard';
import PrevStepButton from '../PrevStepButton';
import { STEP_HINT } from './constants';
import { InfoIcon, SkeletonWrapper } from './styled';
import { getValuesByPriority } from './utils';

const CampaignWizardFooter = observer(() => {
    const { step } = useCampaignWizard();

    const store = useStore();

    const isVisible = step >= 1 && step <= 5;

    if (!isVisible) return null;

    const { campaignPriority: priority, priorityPending } = store.campaignWizardStore;

    const { title, color } = getValuesByPriority(priority, step);

    return (
        <div
            style={{
                backgroundColor: 'white',
                padding: '24px 80px',
                position: 'sticky',
                bottom: '0px',
                boxShadow: '0px -4px 20px rgba(0, 0, 0, .15)',
                zIndex: 20
            }}
        >
            <div data-e2e='campaignwizard_footer' style={{ width: '100%', maxWidth: 1300, marginInline: 'auto' }}>
                <Flex align='center' justify='space-between' gap={12}>
                    <PrevStepButton />
                    <Typography.Text
                        data-e2e='campaignwizard_footer-priority_description'
                        type='descriptor2'
                        color={COLORS.$gray60Black}
                    >
                        Your campaign&apos;s Priority is <span style={{ color: COLORS.$pink }}>{priority}%</span>.{' '}
                        {STEP_HINT[step]}
                    </Typography.Text>

                    <Flex gap={12}>
                        <Flex align='end' style={{ marginTop: '-50px' }}>
                            <div style={{ backgroundColor: COLORS.$white, width: 96, height: 96, borderRadius: 1000 }}>
                                <Progress
                                    data-e2e='campaignwizard_footer-priority-progress_circle'
                                    format={(percent) => {
                                        return priorityPending ? (
                                            <SkeletonWrapper>
                                                <Skeleton className='title' paragraph={false} active />
                                                <Skeleton className='description' paragraph={false} active />
                                                <Skeleton className='description' paragraph={false} active />
                                            </SkeletonWrapper>
                                        ) : (
                                            <Flex vertical justify='center' align='center'>
                                                <Typography.Text
                                                    data-e2e='campaignwizard_footer-priority-progress_value'
                                                    color={color}
                                                    type='badgeLarge'
                                                >
                                                    {percent}%
                                                </Typography.Text>

                                                <Typography.Text
                                                    data-e2e='campaignwizard_footer-priority-progress_title'
                                                    color={color}
                                                    align='center'
                                                    type='badgeTiny'
                                                    style={{ paddingInline: 28 }}
                                                >
                                                    {title}
                                                </Typography.Text>
                                            </Flex>
                                        );
                                    }}
                                    strokeColor={{ '0%': COLORS.$yellow, '100%': COLORS.$magenta }}
                                    type='circle'
                                    strokeWidth={14}
                                    percent={priority}
                                    size={96}
                                />
                            </div>

                            <Tooltip
                                title={
                                    <Fragment>
                                        <Typography.Title
                                            data-e2e='campaignwizard_priority-tooltip_title'
                                            level={3}
                                            color={COLORS.$white}
                                            style={BOTTOM_INDENTS.S}
                                        >
                                            What is your campaign Priority?
                                        </Typography.Title>
                                        <Typography.Text
                                            data-e2e='campaignwizard_priority-tooltip_description'
                                            type='badgeSmall'
                                            color={COLORS.$white}
                                        >
                                            Your Priority is how likely Klickly&apos;s algorithm is to display your
                                            products. Change your Priority by increasing the number of products in your
                                            campaign, the quality of creatives, and the commission you pay Klickly.
                                        </Typography.Text>
                                    </Fragment>
                                }
                                overlayStyle={{ maxWidth: 300 }}
                                trigger='hover'
                            >
                                <Icon
                                    data-e2e='campaignwizard_priority-tooltip_icon'
                                    stroke={COLORS.$gray60Black}
                                    style={{ ...LEFT_INDENTS.XXXS, paddingBlock: 4 }}
                                    color={COLORS.$gray10Black}
                                    size='24px'
                                    component={() => <InfoIcon />}
                                />
                            </Tooltip>
                        </Flex>
                        <NextButton />
                    </Flex>
                </Flex>
            </div>
        </div>
    );
});

export default CampaignWizardFooter;
