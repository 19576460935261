import { COLORS } from '@constants';
import { Checkbox, Divider } from 'antd';
import styled from 'styled-components';

const CheckboxGroup = Checkbox.Group;

export const StyledCheckboxGroup = styled(CheckboxGroup)`
    flex-direction: column;
    gap: 8px;
`;

export const StyledDivider = styled(Divider)`
    border-top: 1px solid ${COLORS.$gray15Black};
    margin: 10px 0 48px 0;
`;

export const StyledWrapper = styled.div`
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    background: ${COLORS.$gray10Black};
    max-width: 860px;
    margin: 0 auto;
`;
