import { COLORS } from '@constants';
import { InputNumber } from 'antd';
import styled from 'styled-components';

export const StyledInputNumber = styled(InputNumber)`
    width: ${({ $width }) => $width || '100%'};
    max-width: 100%;

    &.ant-input-number {
        &:hover {
            border-color: ${COLORS.$gray20Black};
        }

        &&-focused {
            border-color: ${COLORS.$gray60Black};

            &:hover {
                border-color: ${COLORS.$gray60Black};
            }
        }
    }

    &.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(
            .ant-input-number-affix-wrapper-borderless
        ).ant-input-number-affix-wrapper,
    &.ant-input-number-status-error {
        border-width: 2px;
    }

    &.ant-input-number-affix-wrapper {
        padding-inline-start: 24px;
        padding-inline-end: 16px;
    }

    &:focus-within {
        border-color: ${COLORS.$gray60Black};
    }

    .ant-input-number-input {
        caret: yellow block;
        padding: 24px 16px 6px;
        font-family: 'IBM Plex Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .ant-input-number-input::placeholder {
        color: transparent;
    }

    .ant-input-number-prefix {
        margin-inline-end: 24px;
    }
`;
