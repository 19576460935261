import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Icon, Typography } from '@ui';
import { Col, Row } from 'antd';
import { ErrorMessage } from '../ErrorMessage';
import { StyledRow } from './styled';

const ErrorFallback = ({ title, description, icon = <Icon.SettingsIcon /> }) => {
    return (
        <StyledRow align='middle' justify='center'>
            <Col>
                <Row justify='center' style={BOTTOM_INDENTS.XXL}>
                    <Icon
                        roundedColor={COLORS.$white}
                        hoverRoundedColor={COLORS.$white}
                        roundedSize='120px'
                        size='40px'
                        color={COLORS.$gray60Black}
                        component={() => icon}
                    />
                </Row>
                {title && (
                    <Typography.Title align='center' color={COLORS.$gray60Black} level={2} style={BOTTOM_INDENTS.M}>
                        {title}
                    </Typography.Title>
                )}
                {description && (
                    <Typography.Text align='center' color={COLORS.$gray60Black}>
                        {description}
                    </Typography.Text>
                )}
            </Col>
        </StyledRow>
    );
};

ErrorFallback.Message = ErrorMessage;

export { ErrorFallback };
