import React from 'react';
export const DEVICES_OPTIONS = [
    {
        label: <span data-e2e='performance_all-devices_dropdown-item'>All devices</span>,
        value: 'all'
    },
    {
        label: <span data-e2e='performance_mobile_dropdown-item'>Mobile</span>,
        value: 'mobile'
    },
    {
        label: <span data-e2e='performance_desktop_dropdown-item'>Desktop</span>,
        value: 'desktop'
    },
    {
        label: <span data-e2e='performance_tablet_dropdown-item'>Tablet</span>,
        value: 'tablet'
    }
];
