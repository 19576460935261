import React, { useEffect, useState } from 'react';
import { BackButton, LoginButton } from '@components';
import { useStore } from '@hooks';
import { AuthLayout } from '@layouts';
import { Loader } from '@ui';
import { Flex } from 'antd';
import { observer } from 'mobx-react';
import CreateAccount from './components/CreateAccount';
import CreateUser from './components/CreateUser';
import { StepsStyled } from './styled';
import { getSteps } from './utils';

const Register = observer(() => {
    const store = useStore();
    const { hasAccount, whoAmI, me } = store.authStore;
    const steps = getSteps(store);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            if (me === undefined) {
                await whoAmI();
            }
            setLoading(false);
        })();
    }, []);

    return (
        <React.Fragment>
            {loading && <Loader size='120px' />}
            <AuthLayout
                leftCol={me ? null : <BackButton />}
                title={hasAccount ? 'Add new store' : 'Let’s create your account'}
                rightCol={me ? null : <LoginButton />}
            >
                {!loading && (
                    <Flex vertical gap={48}>
                        {!hasAccount && (
                            <StepsStyled $isColoredTail={false} labelPlacement='vertical' current={2} items={steps} />
                        )}

                        {me ? <CreateAccount /> : <CreateUser />}
                    </Flex>
                )}
            </AuthLayout>
        </React.Fragment>
    );
});

export default Register;
