import styled from 'styled-components';

export const StyledImagePreview = styled.div`
    width: 300px;
    height: 600px;
    position: relative;
    padding: 0;
    margin: 0;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: contain; /* Ensure the image covers the entire area */
    }
`;
