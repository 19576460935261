export const ACCOUNT_STATUS = {
    INACTIVE: 0,
    ACTIVE: 1,
    PAUSED: 2,
    SHUTDOWN: 3,
    DISCONNECTED: 4,
    BLOCKED: 5
};

export const CONNECTOR_KLICKLY_STATUS = {
    DISCONNECTED: 1,
    CONNECTED: 2,
    PENDING: 3
};

export const NOTIFICATION_ACTIONS = {
    ACCOUNT: 1,
    MARKETING: 2,
    CAMPAIGN: 3,
    FULFILLMENT: 4
};

export const ACCOUNT_USER_STATUS = {
    BLOCKED: 0,
    ACTIVE: 1,
    INVITED: 2,
    PENDING: 3,
    DECLINED: 4
};

export const ACCOUNT_USER_ROLE = {
    OWNER: 1,
    ADMIN: 2,
    MEMBER: 3
};

export const TRACK_EVENT = {
    VIEWS: 1,
    CLICKS: 2,
    ADD_TO_CARTS: 3,
    PURCHASES: 4
};

export const DOMAIN_STATUS = {
    VERIFIED: 1
};

export const DOMAIN_CLASS = {
    MAIN_ECOMMERCE: 1,
    REST_ECOMMERCE: 2,
    MAIN_CUSTOM: 3,
    REST_CUSTOM: 4
};
