import { COLORS } from '@constants';
import { Tag } from 'antd';
import styled from 'styled-components';

export const StyledTag = styled(Tag)`
    color: ${COLORS.$white};
    background-color: ${COLORS.$pink};
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    svg {
        width: 12px;
        height: 12px;
        fill: none;
        color: ${COLORS.$white};
        margin-left: 8px;
    }

    svg:hover {
        color: ${COLORS.$gray15Black};
    }
`;

export const StyledListItem = styled.div`
    padding: 12px 12px 12px 0;
    border-bottom: 1px solid ${COLORS.$gray15Black};
`;

export const ScrollContainer = styled.div`
    max-height: 250px;
    overflow: auto;
    /* For WebKit browsers (Chrome, Safari) */
    /* Track */
    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background-color: ${COLORS.$gray40Black};
        border-radius: 6px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background-color: ${COLORS.$gray60Black};
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: ${COLORS.$gray20Black};
    }
`;
