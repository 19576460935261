export const SCRIPT_TAG_STATUS = {
    NOT_CONNECTED: 0,
    PENDING: 1,
    CONNECTED: 2
};

export const SCRIPT_TAG_STATUS_TITLE = {
    CONNECTED: 'Connected',
    PENDING: 'Pending',
    NOT_CONNECTED: 'Not Connected'
};
