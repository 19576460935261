import { renderToString } from 'react-dom/server';
import { formatNumberToUSLocale, getBarTooltipBody, getDonutTooltipBody, getOrCreateTooltip } from '../helpers';

export const useCustomTooltip = ({
    chartModel,
    data,
    hideNegative = false,
    chartValuePrefix,
    chartValueSuffix,
    chartType
}) => {
    return (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Create or update element
        let tooltipBody = '';
        let diff = 0;
        if (tooltip.dataPoints && tooltip.dataPoints.length) {
            const { seedData = [], baseData = [], count = [], diffData = [], labels = [] } = data;
            const datasetIndex = tooltip.dataPoints[0].datasetIndex;
            const index = tooltip.dataPoints[0].dataIndex;
            const label = labels[index];
            const value = !hideNegative ? seedData[index] : seedData[index].replace('-', '');
            const baselineValue = !hideNegative ? baseData[index] : baseData[index].replace('-', '');
            const records = formatNumberToUSLocale(count[index]);
            diff = diffData[index];

            const barTooltipBody = getBarTooltipBody(
                label,
                value,
                diff,
                baselineValue,
                records,
                chartValuePrefix,
                chartValueSuffix
            );
            const donutTooltipBody = getDonutTooltipBody(label, value, records);
            const tooltipBodyMap = {
                bar: barTooltipBody,
                donut: donutTooltipBody
            };
            tooltipBody = datasetIndex === 0 ? tooltipBodyMap[chartModel] : '';

            const tooltipContent = tooltipEl.getElementsByClassName('insights-tooltip')[0];

            tooltipContent.innerHTML = renderToString(tooltipBody);
        }

        const verticalBarChart = chartType === 'vertical';
        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
        let left = positionX + tooltip.caretX;
        left = left > chart.width * 0.8 ? left * 0.8 : left;

        let top = positionY + tooltip.caretY;
        top = top > chart.height * 0.8 && verticalBarChart ? top * 0.6 : top;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = left + 'px';
        tooltipEl.style.top = top + 'px';
        tooltipEl.style.font = tooltip.options.bodyFont.string;
    };
};
