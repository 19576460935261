export const columns = [
    {
        title: 'No.',
        dataIndex: 'id',
        key: 'id',
        width: '10%',
        align: 'center',
        render: (id) => (id.toString().length === 1 ? `0${id}` : id)
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        width: '30%',
        align: 'left'
    },
    {
        title: 'Sample %',
        dataIndex: 'sample',
        key: 'sample',
        width: '30%',
        align: 'right',
        render: (p) => `${Math.round(p)} %`
    },
    {
        title: 'Records',
        dataIndex: 'records',
        key: 'records',
        width: '30%',
        align: 'right'
    }
];

export const offsets = {
    VT: [50, -8],
    NH: [34, 2],
    MA: [30, -1],
    RI: [28, 2],
    CT: [35, 10],
    NJ: [34, 1],
    DE: [33, 0],
    MD: [47, 10],
    DC: [49, 21]
};

export const stateAbbreviations = {
    '01': 'AL',
    '02': 'AK',
    '04': 'AZ',
    '05': 'AR',
    '06': 'CA',
    '08': 'CO',
    '09': 'CT',
    10: 'DE',
    11: 'DC',
    12: 'FL',
    13: 'GA',
    15: 'HI',
    16: 'ID',
    17: 'IL',
    18: 'IN',
    19: 'IA',
    20: 'KS',
    21: 'KY',
    22: 'LA',
    23: 'ME',
    24: 'MD',
    25: 'MA',
    26: 'MI',
    27: 'MN',
    28: 'MS',
    29: 'MO',
    30: 'MT',
    31: 'NE',
    32: 'NV',
    33: 'NH',
    34: 'NJ',
    35: 'NM',
    36: 'NY',
    37: 'NC',
    38: 'ND',
    39: 'OH',
    40: 'OK',
    41: 'OR',
    42: 'PA',
    44: 'RI',
    45: 'SC',
    46: 'SD',
    47: 'TN',
    48: 'TX',
    49: 'UT',
    50: 'VT',
    51: 'VA',
    53: 'WA',
    54: 'WV',
    55: 'WI',
    56: 'WY'
};
