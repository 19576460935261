import { COLORS } from '@constants';
import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledHeader = styled(Layout.Header)`
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: ${COLORS.$white};
    border-bottom: 1px solid ${COLORS.$gray15Black};
    padding: 0 24px;
`;
