import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledWrapper = styled.label`
    display: block;
    height: 32px;
    padding: 4px 8px;
    cursor: pointer;

    p {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 200px;
        overflow: hidden;
    }

    .ant-checkbox-wrapper {
        display: none;
    }

    &:has(input:checked) {
        .custom-check-mark {
            border-bottom: 2px solid ${COLORS.$forestGreen};
            border-left: 2px solid ${COLORS.$forestGreen};
        }
    }
`;

export const StyledCheckMark = styled.div`
    width: 12px;
    height: 6px;
    border-bottom: 2px solid ${COLORS.$white};
    border-left: 2px solid ${COLORS.$white};
    background: ${COLORS.$white};
    transform: rotate(-45deg) translate(1px, -1px);
`;
