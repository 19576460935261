import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Divider, Typography, WhiteCover } from '@ui';
import { dayjs } from '@utils';
import { Col, Row } from 'antd';

const Standards = ({ currentAccount, style }) => {
    const timeZone = currentAccount.timeZone;
    const offset = dayjs().tz(timeZone).utcOffset();
    const displayTimeZone = `(GMT${offset > 0 ? '+' : ''}${offset / 60}:00) ${timeZone}`;

    return (
        <WhiteCover
            data-e2e='company-info_standards-and-formats_section'
            title='Standards and formats'
            style={{ position: 'relative', paddingBottom: 40, ...style }}
        >
            <Typography.Text
                data-e2e='company-info_standards-and-formats_description'
                type='body2'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.XL}
            >
                Standards and formats are used to calculate product prices, shipping weights, order times, and more.
            </Typography.Text>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Typography.Text data-e2e='company-info_time-zone_block' type='badgeSmall'>
                        Time zone
                    </Typography.Text>
                </Col>
                <Col span={12}>
                    <Typography.Title data-e2e='company-info_time-zone_value' align='end' level={3}>
                        {displayTimeZone}
                    </Typography.Title>
                </Col>
            </Row>
            <Divider margin='40px 0' />
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Typography.Text data-e2e='company-info_units_block' type='badgeSmall'>
                        Units
                    </Typography.Text>
                </Col>
                <Col span={12}>
                    <Typography.Title data-e2e='company-info_units_value' align='end' level={3}>
                        {currentAccount.unitSystem}
                    </Typography.Title>
                </Col>
            </Row>
            <Divider margin='40px 0' />
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Typography.Text data-e2e='company-info_country_block' type='badgeSmall'>
                        Country
                    </Typography.Text>
                </Col>
                <Col span={12}>
                    <Typography.Title data-e2e='company-info_country_value' align='end' level={3}>
                        {`${currentAccount.country} (${currentAccount.countryCode})`}
                    </Typography.Title>
                </Col>
            </Row>
            <Divider margin='40px 0' />
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Typography.Text data-e2e='company-info_currency_block' type='badgeSmall'>
                        Currency
                    </Typography.Text>
                </Col>
                <Col span={12}>
                    <Typography.Title data-e2e='company-info_currency_value' align='end' level={3}>
                        {currentAccount.currency}
                    </Typography.Title>
                </Col>
            </Row>
            <Divider margin='40px 0' />
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Typography.Text data-e2e='company-info_default-weight_block' type='badgeSmall'>
                        Default weight
                    </Typography.Text>
                </Col>
                <Col span={12}>
                    <Typography.Title data-e2e='company-info_default-weight_value' align='end' level={3}>
                        {currentAccount.weightUnit}
                    </Typography.Title>
                </Col>
            </Row>
        </WhiteCover>
    );
};
export default Standards;
