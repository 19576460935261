import React from 'react';
import { BOTTOM_INDENTS, COLORS, ROUTE_PATHS } from '@constants';
import { Button, Typography } from '@ui';
import { useScriptTag } from '../../../../../../../../../../components/IntegrationKlickly/hooks/useScriptTag';
import { StyledCode, StyledDescription, StyledInvalidParams, StyledLink, StyledText } from './styled';

const AnalyticsScriptModal = () => {
    const { scriptTag, hasRequiredParams } = useScriptTag();

    return (
        <React.Fragment>
            <Typography.Text type='descriptor1' color={COLORS.$black} style={BOTTOM_INDENTS.M}>
                In order to identify, build, and target high priority consumers, you&apos;ll need to install your
                site&apos;s analytics script. This script will allow Klickly&apos;s AI models to build consumer
                audiences that most closely match your highest value customers, and supports analytics across your
                campaigns.
            </Typography.Text>
            <StyledText type='descriptor1' color={COLORS.$black} style={BOTTOM_INDENTS.M}>
                To install your analytics script:
                <br />
                <ul>
                    <li>Copy the below script</li>
                    <li>
                        Paste it into the <code>&lt;head&gt;</code> section of your website’s code
                    </li>
                    <li>Click “Confirm connection” to allow Klickly to confirm installation</li>
                </ul>
            </StyledText>
            {hasRequiredParams ? (
                <React.Fragment>
                    <StyledCode style={BOTTOM_INDENTS.M}>
                        <Button.Copy value={scriptTag} />
                        <code>{scriptTag}</code>
                    </StyledCode>
                    <StyledDescription>
                        <Typography.Text align='left' type='descriptor1' style={BOTTOM_INDENTS.M}>
                            <b>Warning:</b> Any changes you make to your reporting features and integrations (e.g.{' '}
                            <StyledLink onClick={() => window.open(ROUTE_PATHS.TRACKING_SETTINGS, '_self')}>
                                GA4
                            </StyledLink>
                            ) will change your analytics script, requiring that it be reinstalled. We highly recommend
                            setting up your reporting features and integrations prior to installing your analytics
                            script.
                        </Typography.Text>
                        <Typography.Text align='left' type='descriptor1'>
                            Once correctly installed, the status of this step will automatically change to Connected.
                        </Typography.Text>
                    </StyledDescription>
                </React.Fragment>
            ) : (
                <StyledInvalidParams style={BOTTOM_INDENTS.M}>
                    <Typography.Text color={COLORS.$tartRed} align='left' type='descriptor1'>
                        The script will be available shortly. Please refresh the page in a few minutes.
                    </Typography.Text>
                </StyledInvalidParams>
            )}
        </React.Fragment>
    );
};

export default AnalyticsScriptModal;
