import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '@constants';
import { CONTENT_TYPE } from '@enums';
import { useStore } from '@hooks';
import { Button, Divider } from '@ui';
import { Col } from 'antd';
import { observer } from 'mobx-react';
import {
    ButtonGroup,
    CheckboxText,
    StyledBrandsLogoIcon,
    StyledCheckbox,
    StyledModal,
    StyledRow,
    StyledTypography,
    StyledTypographyHeader
} from './styled';

export const SetupPayoutsModal = observer(({ setIsModalOpen, isModalOpen }) => {
    const navigate = useNavigate();
    const store = useStore();
    const { updateAccount } = store.accountsStore;

    return (
        <StyledModal
            width='630px'
            height='229px'
            open={isModalOpen}
            onCancel={() => {
                setIsModalOpen(false);
            }}
            footer={null}
            maskClosable={false}
            closable={false}
            destroyOnClose
        >
            <div data-e2e='home_setup-payouts_modal'>
                <StyledBrandsLogoIcon data-e2e='home_setup-payouts_klickly_logo' />
                <br />
                <br />
                <StyledTypographyHeader data-e2e='home_setup-payouts_title'>
                    You&apos;ve made an in-Klickly sale!
                    <br />
                    How should we pay you?
                </StyledTypographyHeader>
                <Divider margin='24px 0' />
                <div data-e2e='home_setup-payouts_description'>
                    <StyledTypography>
                        Congrats – consumers are buying your products directly within Klickly&apos;s Commerce Modules!
                    </StyledTypography>
                    <StyledTypography>
                        That means we have funds to send you. Please tell us how you&apos;d like to receive these funds.
                    </StyledTypography>
                    <StyledTypography>
                        (Reminder: consumers will most often purchase on your site, but we also allow them to buy in-ad.
                        When this happens, we send the orders to your eCommerce backend and your funds according to your
                        payout settings.)
                    </StyledTypography>
                </div>
                <StyledRow justify='space-evenly' align='middle'>
                    <Col>
                        <StyledCheckbox
                            data-e2e='home_setup-payouts_checkbox'
                            onChange={async (event) => {
                                await updateAccount(
                                    { showModal: { setupPayouts: !event.target.checked } },
                                    CONTENT_TYPE.JSON
                                );
                            }}
                        >
                            <CheckboxText data-e2e='home_setup-payouts_checkbox_description'>
                                Don&apos;t show again
                            </CheckboxText>
                        </StyledCheckbox>
                    </Col>
                    <ButtonGroup>
                        <Button
                            data-e2e='home_setup-payouts_skip_btn'
                            onClick={() => setIsModalOpen(false)}
                            type='default'
                            key='cancel'
                        >
                            Skip for now
                        </Button>
                        <Button
                            data-e2e='home_setup-payouts_enable_btn'
                            onClick={() => navigate(ROUTE_PATHS.PAYMENTS_SETTINGS)}
                            type='primary'
                            key='apply'
                        >
                            Enable payouts
                        </Button>
                    </ButtonGroup>
                </StyledRow>
            </div>
        </StyledModal>
    );
});
