import React, { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { BOTTOM_INDENTS } from '@constants';
import { useInstall, useStore } from '@hooks';
import { Divider, Icon, Typography } from '@ui';
import { Card, Flex } from 'antd';
import { observer } from 'mobx-react';
import PauseAndShutdownModal from './components/PauseAndShutdownModal';
import { ACCOUNT_STATUSES_MAP } from './constants';
import { StyledButton } from './styled';

const AccountStatus = observer(() => {
    const store = useStore();
    const { registerDate, currentAccount, isUninstalled, isPaused, isActive, isShutDown } = store.accountsStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { install, loading: isInstalling } = useInstall();

    const onStatusClick = () => {
        if (isUninstalled) {
            return install();
        }

        if (isPaused || isActive) {
            setIsModalOpen(true);
        }
    };

    return (
        <Card style={BOTTOM_INDENTS.M}>
            <Flex justify='space-between'>
                <Typography.Text data-e2e='account-management_member-since_block' type='badgeSmall'>
                    Member Since
                </Typography.Text>
                <Typography.Text data-e2e='account-management_member-since_value' align='end' type='badgeSmall'>
                    {registerDate}
                </Typography.Text>
            </Flex>
            <Divider margin='16px 0' />
            <Flex justify='space-between' align='center'>
                <Typography.Text data-e2e='account-management_status-of-account_block' type='badgeSmall'>
                    Status of Account
                </Typography.Text>
                <PauseAndShutdownModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
                <StyledButton
                    data-e2e='account-management_account-status_btn'
                    $isShutDown={isShutDown}
                    onClick={onStatusClick}
                    backgroundcolor={ACCOUNT_STATUSES_MAP[currentAccount.status].color}
                >
                    <span data-e2e='account-management_account-status_value'>
                        {ACCOUNT_STATUSES_MAP[currentAccount.status].title}
                    </span>
                    {isInstalling ? (
                        <LoadingOutlined style={{ fontSize: '16px', color: 'white' }} />
                    ) : (
                        <Icon
                            size='16px'
                            color={ACCOUNT_STATUSES_MAP[currentAccount.status].color}
                            component={() => <Icon.QuestionCircleIcon />}
                        />
                    )}
                </StyledButton>
            </Flex>
        </Card>
    );
});

export default AccountStatus;
