import React, { useState } from 'react';
import { BOTTOM_INDENTS, COLORS, LEFT_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Button, Icon, Table } from '@ui';
import { formatString } from '@utils';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { MENU_ITEM_IDS } from '../../constants';
import { usePerformanceScrollFetch } from '../../hooks';
import CustomizeModal from './components/CustomizeModal';

const Reports = observer(({ date, onComponentViewUpdate }) => {
    const [initialFetchDone, setInitialFetch] = useState(false);
    const store = useStore();
    const { reportsList, activeReportMetrics, downloadReports, pendingReports, getReports } = store.performanceStore;

    const { componentRef } = usePerformanceScrollFetch({
        initialFetchDone,
        onInitialFetch: () => {
            setInitialFetch(true);
            getReports(date);
        },
        onReFetch: () => {
            getReports(date);
        },
        onComponentViewUpdate,
        date
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    const generateColumns = (activeReportMetrics) => {
        return activeReportMetrics.map((metric) => ({
            ...metric,
            onHeaderCell: () => ({
                'data-e2e': `performance-table_${formatString(metric.key)}_column-header`
            }),
            onCell: (record) => {
                const rowName = record[activeReportMetrics[0].dataIndex];
                return {
                    'data-e2e': `performance-table_${formatString(rowName)}-row_${formatString(metric.key)}-cell_value`
                };
            }
        }));
    };

    const columns = generateColumns(activeReportMetrics);

    return (
        <Row ref={componentRef} id={MENU_ITEM_IDS.REPORTS}>
            <CustomizeModal isModalOpen={isModalOpen} date={date} setIsModalOpen={setIsModalOpen} />
            <Col align='end' span={24} style={BOTTOM_INDENTS.M}>
                <Button
                    data-e2e='performance_customize-columns_btn'
                    onClick={() => setIsModalOpen(true)}
                    style={LEFT_INDENTS.M}
                    icon={<Icon size='24px' color={COLORS.$gray60Black} component={() => <Icon.TableIcon />} />}
                    disabled={pendingReports}
                >
                    Customize columns
                </Button>
                <Button
                    data-e2e='performance_download-scv_bottom-btn'
                    onClick={() => downloadReports(date)}
                    style={LEFT_INDENTS.M}
                    icon={<Icon size='24px' color={COLORS.$gray60Black} component={() => <Icon.DownloadIcon />} />}
                    disabled={pendingReports}
                >
                    Download CSV
                </Button>
            </Col>
            <Col span={24}>
                <Table
                    data-e2e='performance_table'
                    dataSource={reportsList}
                    columns={columns}
                    loading={pendingReports}
                    pagination={{
                        showQuickJumper: true
                    }}
                    expandPosition='left'
                />
            </Col>
        </Row>
    );
});

export default Reports;
