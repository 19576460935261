import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Divider, Typography } from '@ui';
import { Card, Col, Form, Row, Switch } from 'antd';
import { FIELD_NAMES } from '../constants';
import Settings from './components/Settings';

const CustomizeUTM = ({ style }) => {
    const form = Form.useFormInstance();
    const isDropDownSettingsVisible = Form.useWatch(FIELD_NAMES.CUSTOMIZE_UTM, form);

    return (
        <Card data-e2e='tracking_customize-utms_section' style={style}>
            <Row justify='space-between' align='middle'>
                <Col>
                    <Typography.Title data-e2e='tracking_customize-utms_title' level={2}>
                        Customize UTMs
                    </Typography.Title>
                </Col>
                <Col>
                    <Form.Item name={FIELD_NAMES.CUSTOMIZE_UTM} valuePropName='checked'>
                        <Switch data-e2e='tracking_customize-utms_toggle' />
                    </Form.Item>
                </Col>
            </Row>
            <Divider margin='24px 0' />
            <Typography.Text
                data-e2e='tracking_customize-utms_description'
                type='body2'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.M}
            >
                Customize your Source, Medium, Campaign, and Term URL parameters.
            </Typography.Text>
            {isDropDownSettingsVisible && <Settings />}
        </Card>
    );
};

export default CustomizeUTM;
