import React from 'react';
import { BOTTOM_INDENTS, COLORS, CUSTOMER_INFO_FIELD_NAMES } from '@constants';
import { Button, Input, Typography, WhiteCover } from '@ui';
import { Col, Form, Row } from 'antd';

const Shipping = ({ style }) => {
    return (
        <WhiteCover data-e2e='customer-management_shipping_section' title='Shipping' style={style}>
            <Typography.Text
                data-e2e='customer-management_shipping_description'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.M}
            >
                Shipping is calculated based on both your default box size and other information provided by your
                shopping cart provider. You can edit your default box size below. To edit your other shipping
                information, please visit{' '}
                <Button data-e2e='customer-management_shopify_link' padding='0' fontSize='14px' type='link'>
                    Shopify
                </Button>
                .
            </Typography.Text>
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <Form.Item name={CUSTOMER_INFO_FIELD_NAMES.LENGTH}>
                        <Input.Number
                            data-e2e='customer-management_shipping-length_input'
                            label='Length (IN)'
                            min='0'
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={CUSTOMER_INFO_FIELD_NAMES.WIDTH}>
                        <Input.Number data-e2e='customer-management_shipping_width_input' label='Width (IN)' min='0' />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={CUSTOMER_INFO_FIELD_NAMES.HEIGHT}>
                        <Input.Number
                            data-e2e='customer-management_shipping_height_input'
                            label='Height (IN)'
                            min='0'
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={CUSTOMER_INFO_FIELD_NAMES.WEIGHT}>
                        <Input.Number
                            data-e2e='customer-management_shipping_weight_input'
                            label='Weight (IN)'
                            min='0'
                        />
                    </Form.Item>
                </Col>
            </Row>
        </WhiteCover>
    );
};

export default Shipping;
