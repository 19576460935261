import { Tag } from 'antd';
import styled from 'styled-components';

export const StyledTag = styled(Tag)`
    font-weight: 500;
    line-height: 24px;
    border: none;

    padding: ${({ $padding = '4px 12px' }) => $padding};
`;
