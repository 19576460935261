import React from 'react';
import { COLORS, TOP_INDENTS } from '@constants';
import { Icon, Typography } from '@ui';
import Step2 from '../components/CreateAccount';
import Step1 from '../components/CreateUser';

export const getSteps = (store) => {
    const { me } = store.authStore;
    const steps = [
        {
            icon: {
                outlineColor: COLORS.$pink,
                roundedColor: me ? COLORS.$white : COLORS.$pink,
                hoverColor: me ? COLORS.$white : COLORS.$pink,
                color: me ? COLORS.$pink : COLORS.$white,
                icon: <Icon.PersonIcon />
            },
            title: {
                color: me ? COLORS.$gray100Black : COLORS.$gray40Black,
                text: 'Create your account'
            },
            descriptionColor: me ? COLORS.$gray100Black : COLORS.$gray40Black,
            content: Step1
        },
        {
            icon: {
                outlineColor: me ? COLORS.$pink : COLORS.$gray40Black,
                roundedColor: me ? COLORS.$pink : COLORS.$white,
                hoverColor: me ? COLORS.$pink : COLORS.$white,
                color: me ? COLORS.$white : COLORS.$gray40Black,
                icon: <Icon.CartIcon />
            },
            title: {
                color: me ? COLORS.$gray100Black : COLORS.$gray40Black,
                text: 'Set up your store'
            },
            descriptionColor: me ? COLORS.$gray100Black : COLORS.$gray40Black,
            content: Step2
        }
    ];

    return steps.map((step, key) => {
        return {
            key,
            icon: (
                <Icon
                    outlineColor={step.icon.outlineColor}
                    roundedColor={step.icon.roundedColor}
                    hoverRoundedColor={step.icon.hoverColor}
                    roundedSize='56px'
                    size='24px'
                    color={step.icon.color}
                    component={() => step.icon.icon}
                />
            ),
            title: (
                <Typography.Title
                    data-e2e={`signup_step-${key + 1}_title`}
                    color={step.title.color}
                    style={TOP_INDENTS.S}
                    align='center'
                    level={3}
                >
                    Step {key + 1}.
                </Typography.Title>
            ),
            description: (
                <Typography.Title
                    data-e2e={`signup_step-${key + 1}_description`}
                    color={step.descriptionColor}
                    align='center'
                    level={3}
                >
                    {step.title.text}
                </Typography.Title>
            ),
            content: step.content
        };
    });
};
