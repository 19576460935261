export const FIELD_NAMES = {
    COMPANY_NAME: 'companyName',
    COMPANY_PHONE: 'phone',
    COMPANY_EMAIL: 'email',
    COMPANY_STATE: 'province',
    CITY: 'city',
    ADDRESS: 'address1',
    APT_NUMBER: 'address2',
    ZIP_CODE: 'zipCode',
    PHONE_ID: 'phoneId',
    PHONE_TYPE: 'phoneType',
    PHONE_TYPE_VALUE: 'phoneValueType',
    ADDRESS_ID: 'addressId',
    ADDRESS_TYPE: 'addressType'
};
