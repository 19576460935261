import { COLORS } from '@constants';
import { Table } from '@ui';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
    &.product-table {
        .ant-checkbox-inner,
        .ant-checkbox-input,
        .ant-checkbox,
        .ant-checkbox-wrapper {
            width: 24px;
            height: 24px;
        }

        .ant-table-tbody > tr:last-child > td {
            border-bottom: none;
        }
    }

    &.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
        background: ${COLORS.$gray10Black};
    }

    .ant-checkbox:not(.ant-checkbox-indeterminate) .ant-checkbox-inner::after {
        width: 8.571428571428571px;
        height: 13.714285714285714px;
    }
`;
