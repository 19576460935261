import { NavLink } from 'react-router-dom';
import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledNavLink = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 12px 0 8px;
    border-bottom: 4px solid transparent;

    &.active {
        border-bottom: 4px solid ${COLORS.$pink};

        p {
            color: ${COLORS.$gray100Black};
        }
    }

    &:hover {
        text-decoration: none;
        p {
            color: ${COLORS.$gray100Black};
        }
    }
`;
