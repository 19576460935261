import React from 'react';
import Label from '../Label';
import { StyledInputSearch } from './styled';

const InputSearch = ({ label, ...props }) => {
    return (
        <Label label={label}>
            <StyledInputSearch placeholder={label} {...props} />
        </Label>
    );
};

export default InputSearch;
