import React from 'react';
import { Typography } from '@ui';

export const TabLabel = ({ label, icon }) => {
    return (
        <Typography.Title level={3} align='center' color='unset'>
            {label}
            {icon || null}
        </Typography.Title>
    );
};
