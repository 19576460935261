import React, { lazy, Suspense } from 'react';
import { device } from '@utils';
import * as cn from 'classnames';
import GetStartedButton from '../GetStartedButton';
import StyledHeroSection from './styled';

const HeroLogos = lazy(() => import('../LazyImages/components/HeroLogos'));
const HeroMain = lazy(() => import('../LazyImages/components/HeroMain'));

const HeroSection = () => {
    const isSafari = device.isSafari();

    return (
        <StyledHeroSection className='bg-gradient bg-gradient-pink'>
            <div data-e2e='landing_hero-section' className='container-content'>
                <div className='hero-section__wrapper'>
                    <div className='hero-section__wrapper__left'>
                        <div data-e2e='landing_hero-section_title' className='hero-section__wrapper__left__title'>
                            Data-driven <br /> distributed <br /> commerce
                        </div>
                        <div
                            data-e2e='landing_hero-section_description'
                            className='hero-section__wrapper__left__description'
                        >
                            The world’s only full-funnel consumer data <br />
                            software platform, built to power customer <br />
                            acquisition for leading consumer brands
                        </div>
                        <GetStartedButton />
                    </div>
                    <div className='hero-section__wrapper__right'>
                        <Suspense fallback={<React.Fragment />}>
                            <HeroMain />
                        </Suspense>
                    </div>
                </div>
            </div>
            <div data-e2e='landing_hero-section_slider' className='hero-section__slider'>
                <div className={cn('hero-section__slider__wrapper', isSafari ? 'safari' : null)}>
                    <Suspense fallback={<React.Fragment />}>
                        <HeroLogos />
                    </Suspense>
                </div>
            </div>
        </StyledHeroSection>
    );
};

export default HeroSection;
