import { COLORS } from '@constants';
import { Table } from 'antd';
import styled, { css } from 'styled-components';

export const StyledTable = styled(Table)`
    ${({ $paginationInsideBorder }) =>
        $paginationInsideBorder
            ? css`
                  border: 1px solid ${({ $borderColor }) => $borderColor || COLORS.$gray15Black};
                  border-radius: 8px;

                  && {
                      &.ant-table-wrapper .ant-table-pagination.ant-pagination {
                          height: 104px;
                          margin-top: 0;
                          padding: 24px 0;
                          background: ${COLORS.$white};
                          border-radius: 0 0 8px 8px;

                          .ant-btn {
                              background: ${COLORS.$white};

                              &:hover:not(:disabled) {
                                  background: ${COLORS.$gray20Black};
                              }
                          }
                      }
                  }
              `
            : css`
                  .ant-table {
                      border: 1px solid ${({ $borderColor }) => $borderColor || COLORS.$gray15Black};
                      border-radius: 8px;
                  }
              `};

    border-radius: 8px;

    .ant-btn:disabled {
        cursor: not-allowed;
    }
    &.ant-table-wrapper .ant-table-thead > tr {
        > th,
        td {
            font-size: 12px;
            background: ${COLORS.$white};

            &:before {
                content: none;
            }
        }
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
                [colspan]
            )::before,
        > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
                [colspan]
            )::before {
            content: none;
        }
    }

    &.ant-table-wrapper .ant-table-filter-trigger:hover {
        background: transparent;
    }

    &.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
        background: transparent;
    }

    .ant-checkbox-wrapper,
    .ant-checkbox,
    .ant-checkbox .ant-checkbox-inner {
        width: 16px;
        height: 16px;
    }

    .ant-checkbox-wrapper {
        margin-top: 4px;
    }

    .ant-checkbox .ant-checkbox-inner:after {
        width: 6px;
        height: 10px;
    }

    .ant-checkbox-indeterminate.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
        border: 2px solid ${COLORS.$gray60Black};
    }

    .ant-checkbox-indeterminate.ant-checkbox:not(.ant-checkbox-disabled) .ant-checkbox-inner {
        background-color: ${COLORS.$pink};
        border: 2px solid ${COLORS.$pink};
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
        background-color: ${COLORS.$white};
        top: 50%;
        width: 12px;
        height: 2px;
    }

    &.ant-table-wrapper .ant-table-tbody > tr > td {
        transition: border-color 0s;
    }

    &.ant-table-wrapper .ant-table-filter-column {
        justify-content: normal;
    }

    &.ant-table-wrapper .ant-table-column-sorters {
        justify-content: normal;
    }

    &.ant-table-wrapper .ant-table-column-title {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
    }

    &.ant-table-wrapper .title-right .ant-table-column-title {
        margin-left: auto;
    }

    &.ant-table-wrapper td.ant-table-column-sort {
        background: ${COLORS.$white};
    }

    &.ant-table-wrapper td.ant-table-column-sort.ant-table-cell-row-hover {
        background: ${COLORS.$white} !important;
    }

    &.ant-table-wrapper tr.ant-table-expanded-row > td {
        background: ${COLORS.$white};
    }

    &.ant-table-wrapper .ant-table-expanded-row-fixed,
    .ant-table-expanded-row.ant-table-expanded-row-level-1 > .ant-table-cell {
        z-index: 3;
        border-radius: 0 0 8px 8px;
        padding: 4px 48px 24px;
        box-shadow: rgba(0, 0, 0, 0.16) 0 15px 15px -6px;
    }

    .ant-table-row {
        &.expanded {
            border-radius: 8px 8px 0 0;
            box-shadow: rgba(0, 0, 0, 0.16) 0 -13px 15px -6px;
        }
    }

    .ant-table-cell {
        &:has(.ant-table-expanded-row-fixed) {
            /* border-bottom: none; */
        }
    }

    .ant-table-row.ant-table-row-level-0:has(+ .ant-table-expanded-row:not([style*='display: none'])) > td {
        border-bottom: none;
    }

    .ant-table-column-sorters,
    .ant-table-filter-column {
        > span.anticon {
            margin-left: 8px;
        }

        span.anticon {
            color: ${COLORS.$gray60Black};
            padding: 4px;
            border-radius: 8px;

            &:hover {
                background-color: ${COLORS.$gray20Black};
            }
        }
    }

    /* When at least one filter choosen for a column */
    &.ant-table-wrapper .ant-table-filter-trigger.active {
        span.anticon {
            color: ${COLORS.$pink};
        }
    }

    /* When sorter is apllied for a column */
    .ant-table-column-sort .ant-table-column-sorters {
        span.anticon {
            color: ${COLORS.$pink};
        }
    }

    &.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
        background: initial;
    }

    &.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover.ant-table-column-sort {
        background: ${COLORS.$gray15Black};
    }

    .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
        margin: 0;
        transform: translate(-50%, -50%);
    }

    /* Pagination */

    &.ant-table-wrapper .ant-table-pagination.ant-pagination {
        height: 56px;
        margin: 48px 0 0;
    }

    .ant-pagination .ant-pagination-item {
        display: none;
    }

    .ant-pagination-disabled {
        .ant-btn:disabled {
            .anticon {
                color: ${COLORS.$gray20Black};
            }
        }
    }

    .ant-pagination .ant-pagination-options-quick-jumper {
        position: relative;
        height: 56px;

        &:before {
            content: 'Page #';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 24px;
            font-size: 16px;
            color: ${COLORS.$gray40Black};
            z-index: 1;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 10px;
            bottom: 0;
            width: 82px;
            border-radius: 8px;
            background: ${COLORS.$white};
        }

        &:has(input:focus),
        &:has(input[value^='0']),
        &:has(input[value^='1']),
        &:has(input[value^='2']),
        &:has(input[value^='3']),
        &:has(input[value^='4']),
        &:has(input[value^='5']),
        &:has(input[value^='6']),
        &:has(input[value^='7']),
        &:has(input[value^='8']),
        &:has(input[value^='9']) {
            :before {
                display: none;
            }
        }

        input {
            width: 84px;
            height: 56px;
            border-radius: 8px;
            background: transparent;
            z-index: 2;
            border: 1px solid ${COLORS.$gray20Black};

            &:focus {
                border: 1px solid ${COLORS.$gray60Black};
                box-shadow: none;
            }

            ~ span > .ant-btn {
                cursor: not-allowed;
                border: 2px solid ${COLORS.$gray15Black};
                color: ${COLORS.$gray20Black};

                &:hover {
                    border: 2px solid ${COLORS.$gray15Black};
                    color: ${COLORS.$gray20Black};
                    background: ${COLORS.$gray10Black};
                }
            }

            &[value^='0'],
            &[value^='1'],
            &[value^='2'],
            &[value^='3'],
            &[value^='4'],
            &[value^='5'],
            &[value^='6'],
            &[value^='7'],
            &[value^='8'],
            &[value^='9'] {
                ~ span > .ant-btn {
                    cursor: pointer;
                    border: 2px solid ${COLORS.$gray20Black};
                    color: ${COLORS.$gray60Black};

                    &:hover {
                        border: 2px solid ${COLORS.$gray20Black};
                        color: ${COLORS.$gray60Black};
                        background: ${COLORS.$gray20Black};
                    }
                }
            }
        }

        /* End Pagination */
    }

    ${({ innerTable }) =>
        innerTable &&
        css`
            .ant-table .ant-table-tbody > tr:last-child > td {
                border-bottom: none;
            }

            &.ant-table-wrapper .ant-table-pagination.ant-pagination {
                height: 56px;
                margin: 24px 0 0;
            }
        `};

    .ant-table-row-expand-icon-cell .anticon {
        padding: 4px;
        border-radius: 8px;
        color: ${COLORS.$gray60Black};

        &:hover {
            background-color: ${COLORS.$gray20Black};
        }
    }
`;

export const StyledInnerTable = styled(StyledTable)``;
