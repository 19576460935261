import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { convertDataToDonutScheme } from '../../../helpers';
import { useCustomTooltip } from '../../../hooks';
import { getOptions } from './utils';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ data }) => {
    if (!data) return null;

    const { labels, seedData, count, datasets } = convertDataToDonutScheme(data);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const createTooltip = useCustomTooltip({
        chartModel: 'donut',
        data: { seedData, count, labels }
    });

    const options = getOptions(createTooltip);

    const chartData = {
        labels,
        datasets
    };

    return <Doughnut options={options} data={chartData} />;
};

export default DoughnutChart;
