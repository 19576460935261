import React, { Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SectionHeader } from '@components';
import { BOTTOM_INDENTS, COLORS, defaultTimeRange, TOP_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Divider, Loader, RangePicker, RedirectLink } from '@ui';
import { Row } from 'antd';
import { observer } from 'mobx-react';
import HorizontalCards from '../../Performance/components/Breakdowns/components/HorizontalCards';
import TopProducts from '../../Performance/components/Breakdowns/components/TopProducts';
import { isTouchpointFunnelVisible } from '../../Performance/components/Breakdowns/utils';
import SinglePerformance from './components/SinglePerformance';
import { PERFORMANCE_BLOCKS } from './constants';
import EmptyState from './EmptyState';

const PerformanceSection = observer(() => {
    const [searchParams] = useSearchParams();
    const store = useStore();
    const { pendingMetricsOverview, performanceList, getMetricsOverview, getBreakdowns, breakdowns } =
        store.performanceStore;
    const { isBlocked } = store.accountsStore;

    if (!performanceList.length) return <EmptyState />;

    const touchPointFunnelVisible = searchParams.has('touchpointFunnel')
        ? true
        : isTouchpointFunnelVisible(breakdowns?.touchpointFunnel); // use search param for QA testing purpose

    return (
        <div data-e2e='home_performance_section' style={{ position: 'relative' }}>
            {pendingMetricsOverview && <Loader />}
            <SectionHeader data-e2e='home_performance_title' title='Performance overview'>
                <div data-e2e='home_performance-date-picker_btn'>
                    <RangePicker
                        allowClear={false}
                        key='date-picker'
                        defaultValue={defaultTimeRange}
                        onChange={(data) => {
                            getMetricsOverview({
                                date_from: data.min,
                                date_to: data.max,
                                metrics: PERFORMANCE_BLOCKS.map((item) => item.label)
                            });
                            getBreakdowns({
                                date_from: data.min,
                                date_to: data.max
                            });
                        }}
                    />
                </div>
            </SectionHeader>
            <Row data-e2e='home_performance-cards_row' style={TOP_INDENTS.L} gutter={[16, 16]}>
                {pendingMetricsOverview
                    ? PERFORMANCE_BLOCKS.map((item) => <SinglePerformance key={item.title} item={item} />)
                    : performanceList.map((item) => <SinglePerformance key={item.id} item={item} />)}
            </Row>
            {!isBlocked && (
                <Fragment>
                    <RedirectLink
                        data-e2e='home_performance_link'
                        style={{ ...TOP_INDENTS.XL_PLUS, paddingRight: 16 }}
                        text='See detailed performance'
                        fontSize='16px'
                        to='/performance'
                    />
                    <Divider style={{ borderBlockStart: `1px solid ${COLORS.$gray20Black}` }} margin='48px 0' />
                    <SectionHeader
                        data-e2e='home_breakdowns_title'
                        title='Performance breakdowns'
                        style={BOTTOM_INDENTS.L}
                    />
                    <HorizontalCards
                        data-e2e='home_engagements_section'
                        title='Engagement funnel'
                        data={breakdowns?.engagementFunnel}
                        pending={false}
                        type='homepage'
                    />
                    {touchPointFunnelVisible ? (
                        <HorizontalCards
                            title='Touchpoint funnel'
                            hintTitle='Touchpoint funnel'
                            hintBody='When Klickly finds a best-fit consumer and shows them your products, this is called a touchpoint. “Days from first touchpoint” is the time since we first start advertising to the consumer. “Average touchpoints” is the  number of times Klickly touches a consumer to drive them to a purchase. “Days from last touchpoint” is the amount of time from last Klickly touch to when the consumer purchases.'
                            data={breakdowns?.touchpointFunnel}
                            pending={false}
                            type='homepage'
                            data-e2e='home_touchpoints_section'
                        />
                    ) : null}
                    <TopProducts
                        data-e2e='home_top-products_section'
                        title='Top products'
                        data={breakdowns?.products}
                        pending={false}
                        type='homepage'
                    />
                    <RedirectLink
                        data-e2e='home_performance-breakdowns_link'
                        style={{ ...TOP_INDENTS.XL_PLUS, paddingRight: 16, marginTop: 0 }}
                        text='See detailed performance breakdowns'
                        fontSize='16px'
                        to='/performance'
                    />
                </Fragment>
            )}
        </div>
    );
});

export default PerformanceSection;
