/* eslint-disable react/react-in-jsx-scope */
import { notification } from 'antd';

export const showFormNotification = (error) => {
    if (error) {
        notification.error({
            message: <span data-e2e='common_error-occurred_notification'>An error has occurred</span>
        });
    } else {
        notification.success({
            message: <span data-e2e='common_updated-successfully_notification'>Updated successfully</span>
        });
    }
};
