import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { useStore } from '@hooks';
import { Divider, Loader, Typography } from '@ui';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import CustomCollapse from './components/Collapse';
import { StyledCard } from './styled';
import { getSections } from './utils';

const LaunchCheck = observer(() => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [activeKeys, setActiveKeys] = useState([]);

    const store = useStore();
    const {
        getContactsForCustomers,
        getAddressForCustomers,
        getContactsForKlickly,
        getAddressForKlickly,
        isKlicklyInfoValid,
        isCustomerInfoValid,
        hasPaymentMethod,
        platformName,
        settingsLink
    } = store.accountsStore;
    const { setIsNextDisabled, setIsNextLoading, formSubmitting } = store.campaignWizardStore;

    useEffect(() => {
        (async () => {
            await Promise.all([
                getContactsForCustomers(),
                getAddressForCustomers(),
                getContactsForKlickly(),
                getAddressForKlickly()
            ]);
            setLoading(false);
        })();
        store.accountsStore.paymentIntent();
    }, []);

    const nextDisabled = !isKlicklyInfoValid || !isCustomerInfoValid || !hasPaymentMethod;

    const nextLoading = formSubmitting;

    useEffect(() => {
        setIsNextLoading(nextLoading);
    }, [nextLoading]);

    useEffect(() => {
        setIsNextDisabled(nextDisabled);
    }, [nextDisabled]);

    const closeCollapse = (key) => {
        setActiveKeys(activeKeys.filter((val) => val !== key));
    };

    if (loading) {
        return <Loader size='120px' />;
    }

    return (
        <Row gutter={64}>
            <Col span={17}>
                <StyledCard>
                    <Typography.Title data-e2e='campaignwizard_launchcheck_title' level={2} style={BOTTOM_INDENTS.M}>
                        Whoops...
                    </Typography.Title>
                    <Typography.Text
                        data-e2e='campaignwizard_launchcheck_description'
                        type='body2'
                        color={COLORS.$gray60Black}
                    >
                        We&apos;re missing some critical information that we need to be able to advertise and sell your
                        products. Please complete the following to launch.
                    </Typography.Text>
                    <Divider margin='24px 0' />

                    <CustomCollapse
                        activeKeys={activeKeys}
                        setActiveKeys={setActiveKeys}
                        items={getSections({ store, closeCollapse, navigate })}
                    />
                </StyledCard>
            </Col>
            <Col span={7}>
                <Typography.Text data-e2e='campaignwizard_launchcheck_hint' type='body2' color={COLORS.$gray60Black}>
                    We hate filling out info too, but we can&apos;t process your sales without more details - we need
                    things like your deposit account info, customer service details, and a CC in order to accept orders
                    and payments from future customers. But don&apos;t worry! Most of this information is easily
                    available in your{' '}
                    <a style={{ color: COLORS.$info }} href={settingsLink} target='_blank' rel='noopener noreferrer'>
                        {platformName} Settings
                    </a>
                    . And while we can&apos;t copy this information directly for security reasons, we&apos;ve provided a
                    link to help make your job a little easier.
                </Typography.Text>
            </Col>
        </Row>
    );
});

export default LaunchCheck;
