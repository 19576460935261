import React, { Fragment } from 'react';
import { COLORS } from '@constants';
import { Button, Typography } from '@ui';
import { Col, Flex } from 'antd';
import { StyledAlert } from '../../../styled';
import { StyledStoreUrl } from './styled';

const Connected = ({ isValid, trimmedDomain, updateScopes }) => {
    return (
        <Fragment>
            <StyledStoreUrl align='middle' justify='space-between' wrap={false}>
                <Col>
                    <Typography.Text data-e2e='integrations_bigcommerce-domain_value' type='descriptor1'>
                        {trimmedDomain}
                    </Typography.Text>
                </Col>
                <Col>
                    <Typography.Text type='descriptor1' color={COLORS.$gray40Black}>
                        .mybigcommerce.com
                    </Typography.Text>
                </Col>
            </StyledStoreUrl>
            <StyledAlert data-e2e='integrations_bigcommerce-connection-status_block' $isValid={isValid}>
                {isValid ? (
                    <Typography.Text align='center' color={COLORS.$robinBlue} type='descriptor1'>
                        BigCommerce connection is up to date.
                    </Typography.Text>
                ) : (
                    <Flex justify='space-between' align='center'>
                        <Typography.Text color={COLORS.$tartRed} type='descriptor1'>
                            Please update your BigCommerce access scopes.
                        </Typography.Text>
                        <Button
                            data-e2e='integrations_update-bc-connection_btn'
                            style={{ width: 160 }}
                            block
                            type='primary'
                            onClick={updateScopes}
                        >
                            Update
                        </Button>
                    </Flex>
                )}
            </StyledAlert>
        </Fragment>
    );
};

export default Connected;
