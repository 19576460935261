import React, { useState } from 'react';
import SupportModal from './components/SupportModal/index';
import { StyledCloseOutlined, StyledSupportIcon, StyledVerticalButton } from './styled';

const SupportSection = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <React.Fragment>
            <StyledVerticalButton
                data-e2e='home_support_modal'
                onClick={() => {
                    setIsModalOpen(!isModalOpen);
                }}
            >
                <span>
                    Support
                    {isModalOpen ? <StyledCloseOutlined data-e2e='home_close-modal_icon' /> : <StyledSupportIcon />}
                </span>
            </StyledVerticalButton>
            <SupportModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </React.Fragment>
    );
};

export default SupportSection;
