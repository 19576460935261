import styled, { keyframes } from 'styled-components';

// Keyframes for animation
const moveLeftHero = keyframes`
  100% {
    transform: translateX(calc(-100% - 40px)) translateZ(0);
  }
`;

const moveLeftHeroSafari = keyframes`
  100% {
    left: -1104px;
  }
`;

// Styled component for the hero section
const StyledHeroSection = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 1;

    &.bg-gradient {
        &&:before {
            height: 341px;
            top: -80px;
            clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 150px), 0px 100%);
            z-index: -1;

            @media (max-width: 860px) {
                top: -64px;
                clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 30px), 0px 100%);
            }
        }
    }

    & > div {
        @media (max-width: 1200px) {
            padding: 0;
        }
    }

    & .hero-section__wrapper {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 1;
        position: relative;
        margin-top: 19px;
        gap: 5px;

        @media (max-width: 1200px) {
            padding-left: 24px;
            margin-top: 0;
        }

        @media (max-width: 860px) {
            flex-direction: column-reverse;
            gap: 0;
            align-items: flex-start;
        }

        &__right {
            height: 544px;

            @media (max-width: 860px) {
                height: 361px;
            }

            img {
                display: block;

                @media (max-width: 860px) {
                    width: 524px;
                    height: 361px;
                    object-fit: contain;
                    margin-left: -24px;
                }
            }
        }

        &__left {
            @media (max-width: 1200px) {
                padding-right: 24px;
            }

            &__title {
                font-size: 64px;
                font-weight: 700;
                line-height: 64px;
                margin-bottom: 24px;
                font-family: 'IBM Plex Sans', Helvetica, sans-serif;
                color: #04080f;

                @media (max-width: 860px) {
                    font-size: 48px;
                    line-height: 56px;

                    br {
                        display: none;
                    }
                }
            }

            &__description {
                margin-bottom: 24px;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                font-family: 'IBM Plex Sans', Helvetica, sans-serif;
                color: #04080f;

                @media (max-width: 860px) {
                    br {
                        display: none;
                    }
                }
            }
        }
    }

    & .hero-section__slider {
        margin-top: 96px;
        overflow: hidden;

        &__wrapper {
            height: 35px;
            position: relative;
            display: flex;
            gap: 40px;
            max-width: 1064px;
            margin: 0 auto;
            pointer-events: none;

            &.safari {
                & > img {
                    @media (max-width: 1064px) {
                        animation: ${moveLeftHeroSafari} 16s linear infinite;
                        animation-delay: 0.2s;
                        left: 0;
                        position: relative;
                    }
                }
            }

            & > img {
                width: 1064px;
                height: 100%;
                object-fit: contain;

                @media (max-width: 1064px) {
                    animation: ${moveLeftHero} 16s linear infinite;
                    animation-delay: 0.2s;
                }

                &:last-of-type {
                    display: none;

                    @media (max-width: 1064px) {
                        display: block;
                    }
                }
            }
        }
    }
`;

export default StyledHeroSection;
