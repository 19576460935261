import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledInitiator = styled.div`
    padding: 4px 16px;
    border: 1px solid ${COLORS.$gray20Black};
    border-radius: 8px;
    background: ${COLORS.$white};
`;

export const StyledColorViewer = styled.div`
    width: 24px;
    height: 24px;
    border: 1px solid ${COLORS.$gray20Black};
    border-radius: 4px;
    background: ${({ $backgroundColor }) => $backgroundColor || COLORS.$white};
    margin: 11px 0;
`;
