import { COLORS } from '@constants';
import { Button } from '@ui';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    .ant-popover-inner {
        width: 370px;
        padding: 20px;
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 18px 8px;
        background-color: ${COLORS.$gray80Black};
    }
`;

export const StyledButton = styled(Button)`
    &.ant-btn-link:not(:disabled):hover {
        background-color: ${COLORS.$gray60Black};
    }
`;
