import React from 'react';
import { Button } from '@ui';
import { Flex } from 'antd';
import { observer } from 'mobx-react';
import StatusBadge from '../StatusBadge';

const Footer = observer(
    ({
        confirm,
        status,
        close,
        confirmDisable = false,
        showConfirm = true,
        showStatus = true,
        pending = false,
        fetching = false,
        error = null
    }) => {
        return (
            <React.Fragment>
                {error}
                <Flex justify={showStatus ? 'space-between' : 'flex-end'} align='center'>
                    {showStatus ? <StatusBadge status={status} /> : null}
                    <Flex justify='flex-end' gap={16}>
                        <Button style={{ width: '160px' }} padding='8px' onClick={close}>
                            Close
                        </Button>
                        {showConfirm ? (
                            <Button
                                loading={fetching}
                                disabled={confirmDisable || pending}
                                type='primary'
                                style={{ width: '176px' }}
                                padding='8px'
                                onClick={confirm}
                            >
                                {pending ? 'Pending' : 'Confirm connection'}
                            </Button>
                        ) : null}
                    </Flex>
                </Flex>
            </React.Fragment>
        );
    }
);

export default Footer;
