import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS, ROUTE_PATHS, TOP_INDENTS, VALIDATION_RULES } from '@constants';
import { useStore } from '@hooks';
import { Button, Icon, Input, RedirectLink, Typography } from '@ui';
import { Col, Flex, Form, Row } from 'antd';
import { observer } from 'mobx-react';
import { StyledCheckbox } from './styled';

const CreateUser = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { isFormPending, signUp, oauthPayload, embeddedLoadPayload } = store.authStore;

    const onSubmit = async (values) => {
        const response = await signUp(values);

        if (embeddedLoadPayload) {
            return navigate(ROUTE_PATHS.APP_EMBEDDED);
        }

        if (oauthPayload) {
            return navigate(ROUTE_PATHS.CONNECTOR);
        }

        if (response?.connectorData?.isPending) {
            navigate(ROUTE_PATHS.CONNECTOR);
        }

        if (response?.connectorData?.isAccountAttached) {
            navigate(ROUTE_PATHS.HOME);
        }
    };

    return (
        <Fragment>
            <Form data-e2e='signup_create-user_form' form={form} onFinish={onSubmit}>
                <Flex vertical gap={16}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item name='firstName' rules={VALIDATION_RULES.REQUIRED}>
                                <Input data-e2e='signup_first-name_input' type='text' label='First Name' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='lastName' rules={VALIDATION_RULES.REQUIRED}>
                                <Input data-e2e='signup_last-name_input' type='text' label='Last Name' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name='email' rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.EMAIL]}>
                        <Input data-e2e='signup_email_input' type='email' label='Email' />
                    </Form.Item>
                    <Form.Item name='password' rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.PASSWORD]}>
                        <Input
                            suffix={
                                <Icon
                                    data-e2e='signup_show-created-password_icon'
                                    size='24px'
                                    color={COLORS.$gray20Black}
                                    component={() => <Icon.LockIcon />}
                                />
                            }
                            type='password'
                            label='Create Password'
                            data-e2e='signup_create-password_input'
                        />
                    </Form.Item>
                    <Form.Item
                        data-e2e='test'
                        dependencies={['password']}
                        name='confirmPassword'
                        rules={[
                            {
                                required: true,
                                message: (
                                    <span data-e2e='signup_required-confirm-password_error'>
                                        This field is required
                                    </span>
                                )
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The confirmation password does not match'));
                                }
                            })
                        ]}
                    >
                        <Input
                            suffix={
                                <Icon
                                    data-e2e='signup_show-confirmed-password_icon'
                                    size='24px'
                                    color={COLORS.$gray20Black}
                                    component={() => <Icon.LockIcon />}
                                />
                            }
                            type='password'
                            label='Confirm Password'
                            data-e2e='signup_confirm-password_input'
                        />
                    </Form.Item>
                </Flex>
                <Form.Item
                    style={TOP_INDENTS.L}
                    name='agreement'
                    valuePropName='checked'
                    rules={VALIDATION_RULES.CHECKBOX}
                >
                    <StyledCheckbox data-e2e='signup_agreement_checkbox' value='agreement'>
                        <Typography.Text
                            data-e2e='signup_agreement_text'
                            color={COLORS.$gray60Black}
                            type='descriptor1'
                        >
                            I have read and agree to the{' '}
                            <RedirectLink
                                data-e2e='signup_terms-of-service_link'
                                text='Terms of Service'
                                style={{ display: 'inline' }}
                                fontSize='16px'
                                to={ROUTE_PATHS.TERMS}
                                target='_blank'
                            />{' '}
                            and{' '}
                            <RedirectLink
                                data-e2e='signup_privacy-policy_link'
                                text='Privacy Policy.'
                                style={{ display: 'inline' }}
                                fontSize='16px'
                                to={ROUTE_PATHS.PRIVACY}
                                target='_blank'
                            />
                        </Typography.Text>
                    </StyledCheckbox>
                </Form.Item>
                <Form.Item style={TOP_INDENTS.L}>
                    <Button
                        data-e2e='signup_next-step_btn'
                        htmlType='submit'
                        type='primary'
                        size='large'
                        justify='between'
                        block
                        loading={isFormPending}
                        iconRight={
                            <Icon size='24px' color={COLORS.$white} component={() => <Icon.ArrowForwardIcon />} />
                        }
                    >
                        Next Step
                    </Button>
                </Form.Item>
            </Form>
            <Col style={TOP_INDENTS.L}>
                <Row data-e2e='signup_signin-hint_text' justify='center'>
                    <Col>
                        <Typography.Text color={COLORS.$gray60Black} type='descriptor1' level={3}>
                            Already have a Klickly account?{' '}
                            <RedirectLink
                                data-e2e='signup_signin_link'
                                text='Sign in'
                                to={ROUTE_PATHS.LOGIN}
                                fontSize='16px'
                                style={{ display: 'inline' }}
                            />
                        </Typography.Text>
                    </Col>
                </Row>
            </Col>
        </Fragment>
    );
});

export default CreateUser;
