import React from 'react';
import { COLORS } from '@constants';
import { Icon, Tooltip, Typography } from '@ui';
import { Col, Row } from 'antd';
import { StyledIcon } from './styled';

export const TitleWithTooltip = ({ title, type = 'em0', iconSize = '24px', tooltip }) => {
    return (
        <Row align='middle' justify='start' gutter={8}>
            <Col>
                <Typography.Text color={COLORS.$gray80Black} type={type}>
                    {title}
                </Typography.Text>
            </Col>
            <Col
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Tooltip title={tooltip} placement='right' trigger={['click', 'hover']}>
                    <span>
                        <StyledIcon
                            size={iconSize}
                            component={() => <Icon.QuestionCircleIcon />}
                            color={COLORS.$gray40Black}
                        />
                    </span>
                </Tooltip>
            </Col>
        </Row>
    );
};
