import React, { useEffect, useState } from 'react';
import { COLORS, defaultTimeRange, LEFT_INDENTS, orderMap, TOP_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Button, Icon, RangePicker, Table } from '@ui';
import { camelToSnakeCase } from '@utils';
import { Col, Row } from 'antd';
import fileDownload from 'js-file-download';
import { observer } from 'mobx-react';
import { columns, columnsInner } from './constants';
import { StyledTableWrapper } from './styled';

const Charges = observer(() => {
    const store = useStore();

    const [loading, setLoading] = useState(true);

    const { charges, chargesMeta, getInvoices, downloadInvoices, updateChargesFilters, updateChargesSorters } =
        store.invoicesStore;
    const ids = [1, 2, 3];

    useEffect(() => {
        (async () => {
            await getInvoices('charges');
            setLoading(false);
        })();
    }, []);

    const onDownload = async () => {
        const { file, fileName } = await downloadInvoices({ transaction_type: 'charges' });
        fileDownload(file, fileName);
    };

    const getTitle = (invoiceItem) => {
        return invoiceItem.boost
            ? `${invoiceItem.boost.title} for ${invoiceItem.campaigns[0].campaign.title}`
            : `Commission for order ${invoiceItem.order?.klicklyOrderNumber}`;
    };

    const getDataSource = (invoiceItems) => {
        return invoiceItems.map((invoiceItem) => ({
            id: invoiceItem.id,
            title: getTitle(invoiceItem),
            sequenceNumber: invoiceItem.sequenceNumber,
            chargedAmount: invoiceItem.chargedAmount,
            showOffset: !!invoiceItem.showOffset,
            offsetAmount: invoiceItem.offsetAmount,
            payoutNumber: invoiceItem.payoutNumber
        }));
    };

    const onTableChange = async (pagination, filters, sorter) => {
        setLoading(true);

        const params = { page: pagination.current, perPage: pagination.pageSize };

        updateChargesSorters({
            sort: sorter.order ? camelToSnakeCase(sorter.field) : undefined,
            order: sorter.order ? orderMap[sorter.order] : undefined
        });

        // TODO when filters will be added
        // updateFilters({
        //     klickly_status: filters.klicklyStatus || undefined,
        //     financial_status: filters.financialStatus || undefined,
        //     fulfillment_status: filters.fulfillmentStatus || undefined
        // });

        await getInvoices('charges', params);

        setLoading(false);
    };

    const onDateChange = async ({ min, max }) => {
        setLoading(true);
        updateChargesFilters({
            created_at_min: min,
            created_at_max: max
        });
        await getInvoices('charges');
        setLoading(false);
    };

    return (
        <React.Fragment>
            <Row justify='space-between' style={TOP_INDENTS.S}>
                <Col data-e2e='invoices_charges-datepicker'>
                    <RangePicker allowClear={false} defaultValue={defaultTimeRange} onChange={onDateChange} />
                </Col>
                <Col>
                    <Button
                        data-e2e='invoices_charges-download-csv_btn'
                        onClick={onDownload}
                        style={LEFT_INDENTS.M}
                        icon={<Icon size='24px' color={COLORS.$gray60Black} component={() => <Icon.DownloadIcon />} />}
                    >
                        Download CSV
                    </Button>
                </Col>
            </Row>
            <StyledTableWrapper>
                <Table
                    data-e2e='invoices_charges_table'
                    loading={loading}
                    columns={columns}
                    dataSource={charges}
                    rowClassName={(record) => (ids.includes(record.id) ? 'shadow' : 'light')}
                    rowKey={(record) => record.id}
                    pagination={{
                        total: chargesMeta?.total,
                        totalPages: chargesMeta?.totalPages,
                        pageSize: chargesMeta?.perPage,
                        current: chargesMeta?.page,
                        showSizeChanger: false
                    }}
                    onChange={onTableChange}
                    expandable={{
                        expandedRowRender: (record) => {
                            if (!record) return null;

                            let items = record.items;

                            if (record.payoutOffsets?.length) {
                                const amount = record.payoutOffsets.reduce(
                                    (acc, payoutOffset) => acc + payoutOffset.amount,
                                    0
                                );

                                items = [
                                    ...items,
                                    {
                                        offsetAmount: amount,
                                        showOffset: true,
                                        payoutNumber: record.payoutOffsets?.[0]?.payoutItem?.payout?.sequenceNumber
                                    }
                                ];
                            }

                            return (
                                <Table
                                    dataSource={getDataSource(items)}
                                    rowKey={(r) => r.id}
                                    showHeader={false}
                                    borderColor='transparent'
                                    columns={columnsInner}
                                    pagination={false}
                                />
                            );
                        }
                    }}
                    style={TOP_INDENTS.M}
                />
            </StyledTableWrapper>
        </React.Fragment>
    );
});

export default Charges;
