import React, { useEffect, useState } from 'react';
import { BOTTOM_INDENTS, TOP_INDENTS } from '@constants';
import { CONTENT_TYPE } from '@enums';
import { useStore } from '@hooks';
import { Modal, Typography } from 'antd';
import { observer } from 'mobx-react';
import Choice from './components/Choice/index';
import Unpause from './components/Unpause/index';
import { StyledDivContainer } from './styled';

const PauseAndShutdownModal = observer(({ setIsModalOpen, isModalOpen }) => {
    const store = useStore();
    const { updateAccount, isPaused } = store.accountsStore;

    const [modalContent, setModalContent] = useState(null);

    const customClose = () => {
        setIsModalOpen(false);
        const content = defaultModal(isPaused);
        setModalContent(content);
    };

    const changeStatus = async (status) => {
        await updateAccount({ status }, CONTENT_TYPE.JSON);
    };

    const defaultModal = (isPaused) => {
        return isPaused ? (
            <Unpause changeStatus={changeStatus} customClose={customClose} />
        ) : (
            <Choice setModalContent={setModalContent} changeStatus={changeStatus} customClose={customClose} />
        );
    };

    useEffect(() => {
        const content = defaultModal(isPaused);
        setModalContent(content);
    }, [isPaused]);

    return (
        <Modal
            width='650px'
            style={TOP_INDENTS.XL}
            open={isModalOpen}
            onCancel={customClose}
            footer={null}
            maskClosable={false}
            destroyOnClose
        >
            <StyledDivContainer data-e2e='account-management_pause-and-shutdown_modal'>
                <Typography.Title
                    data-e2e='account-management_account-status-modal_title'
                    style={{ ...BOTTOM_INDENTS.M }}
                >
                    Account status management
                </Typography.Title>
                {modalContent}
            </StyledDivContainer>
        </Modal>
    );
});

export default PauseAndShutdownModal;
