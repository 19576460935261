export const device = {
    Android: () => Boolean(navigator.userAgent.match(/Android/i)),
    iOSMobile: () => Boolean(navigator.userAgent.match(/iPhone|iPod/i)),
    iOS: () => Boolean(navigator.userAgent.match(/iPhone|iPad|iPod/i)),
    isMobile: () => device.Android() || device.iOSMobile(),
    isSafari: () =>
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1,
    isChrome: () => Boolean(window.chrome) && (Boolean(window.chrome.webstore) || Boolean(window.chrome.runtime))
};

export const isMobileView = () => window.innerWidth <= 480;

export const isMobileLandscapeView = () => window.innerWidth > 480 && window.innerWidth <= 830;
