import React, { Fragment } from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { IMAGE_FORMAT } from '@enums';
import { useStore } from '@hooks';
import { Button, Typography } from '@ui';
import { observer } from 'mobx-react';
import { useAdExamplesModal, usePreviewProducts } from '../../../../../../../CampaignWIzard/components/Preview/hooks';

const Header = observer(
    ({
        logo,
        brandLink = 'https://giftly.klickly.com/marketplace',
        font = 'arial',
        backgroundColor,
        customSelectedProducts = [],
        primaryColor,
        offerMessage,
        imageFormat = IMAGE_FORMAT.CROPPED
    }) => {
        const store = useStore();
        const { accountProducts } = store.campaignWizardStore;
        const previewProducts = usePreviewProducts(accountProducts, customSelectedProducts);
        const { openModal } = useAdExamplesModal({
            brandLink,
            font,
            backgroundColor,
            logo,
            products: previewProducts,
            primaryColor,
            offerMessage,
            imageFormat
        });
        return (
            <Fragment>
                <Typography.Title level={2} style={BOTTOM_INDENTS.M}>
                    Creative settings
                </Typography.Title>
                <Typography.Text style={BOTTOM_INDENTS.XS} color={COLORS.$gray60Black}>
                    While your creatives will be dynamically created, you can control various elements, including the
                    logo, colors, and font types. Review your creative settings below. These changes will apply to all
                    campaigns.
                </Typography.Text>
                <Typography.Text style={BOTTOM_INDENTS.XS} color={COLORS.$gray60Black}>
                    Note: Any changes to your creative settings will be reviewed prior to going live.
                </Typography.Text>
                <Typography.Text color={COLORS.$gray60Black}>
                    To see examples of your creatives,{' '}
                    <Button fontSize='14px' padding='0' type='link' display='inline' onClick={openModal}>
                        click here.
                    </Button>
                </Typography.Text>
            </Fragment>
        );
    }
);

export default Header;
