import React from 'react';
import Pagination from '../components/Pagination';

// eslint-disable-next-line react/display-name
export const getPaginationItemRender = (currentPage, totalPages) => (page, type) => {
    switch (type) {
        case 'prev':
            return <Pagination.Prev currentPage={currentPage} totalPages={totalPages} />;
        case 'next':
            return <Pagination.Next currentPage={currentPage} totalPages={totalPages} />;
        default:
            return null;
    }
};
