import React, { Fragment, useEffect, useState } from 'react';
import { CancelSave } from '@components';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { useStore } from '@hooks';
import { Loader, Typography } from '@ui';
import { showFormNotification } from '@utils';
import { Form } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react';
import MessagesThatCustomersWillReceiveFromKlickly from './components/MessagesThatCustomersWillReceiveFromKlickly';
import MessagesToCustomers from './components/MessagesToCustomers';
import NotificationContacts from './components/NotificationContacts';
import { FIELD_NAMES } from './constants';
import { calculateContactGroups, mapContactsToForm } from './utils';

const Notifications = observer(() => {
    const store = useStore();
    const [form] = Form.useForm();
    const {
        handleNotificationContactsSave,
        getNotificationContacts,
        getContactsForKlickly,
        resetNotificationContacts
    } = store.accountsStore;

    const [loading, setLoading] = useState(true);
    const [formSubmitting, setFormSubmitting] = useState(false);

    const resetForm = () => {
        const { notificationContacts, klicklyEmail } = store.accountsStore;
        const storeContactsCopy = _.cloneDeep(notificationContacts);
        if (klicklyEmail.value) {
            storeContactsCopy.unshift(_.cloneDeep(klicklyEmail));
        }

        form.setFieldValue(FIELD_NAMES.NOTIFICATION_CONTACTS, mapContactsToForm(storeContactsCopy));
    };

    useEffect(() => {
        (async () => {
            await getNotificationContacts();
            await getContactsForKlickly();
            resetForm();
            setLoading(false);
        })();

        return () => {
            resetNotificationContacts();
        };
    }, []);

    const handleFinish = async (values) => {
        setFormSubmitting(true);

        const { notificationContacts, klicklyEmail } = store.accountsStore;
        const storeContactsCopy = _.cloneDeep(notificationContacts);
        if (klicklyEmail.value) {
            storeContactsCopy.push(_.cloneDeep(klicklyEmail));
        }

        const contactGroups = calculateContactGroups(storeContactsCopy, values[FIELD_NAMES.NOTIFICATION_CONTACTS]);
        await handleNotificationContactsSave(contactGroups);

        resetForm();
        setFormSubmitting(false);
        showFormNotification(store.accountsStore.error);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Fragment>
            <Typography.Title data-e2e='notifications_page_title' level={2} style={BOTTOM_INDENTS.M}>
                Messages from Klickly
            </Typography.Title>
            <Typography.Text
                data-e2e='notifications_page_description'
                type='body2'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.M}
            >
                Emails and messages that you&apos;ll receive from Klickly
            </Typography.Text>
            <Form
                form={form}
                onFinish={handleFinish}
                scrollToFirstError={{ behavior: 'smooth', block: 'center', scrollMode: 'always' }}
            >
                <NotificationContacts style={BOTTOM_INDENTS.M} />
                <MessagesToCustomers style={BOTTOM_INDENTS.M} />
                <MessagesThatCustomersWillReceiveFromKlickly style={BOTTOM_INDENTS.M} />
                <CancelSave onCancel={resetForm} loading={formSubmitting} />
            </Form>
        </Fragment>
    );
});
export default Notifications;
