import { Upload } from 'antd';
import styled from 'styled-components';

export const StyledWrapper = styled.div``;

export const StyledUpload = styled(Upload)`
    width: 100%;

    .ant-upload {
        width: 100%;
    }
`;
