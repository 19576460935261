import React from 'react';
import { COLORS } from '@constants';
import { Icon } from '@ui';
import styled from 'styled-components';

export const StyledIcon = styled(({ ...props }) => <Icon {...props} />)`
    margin-top: 4px;

    &:hover {
        color: ${COLORS.$gray100Black};
    }
`;
