import config from '../config';

const makeURL = (pathname) => {
    return `${config.connectorApiUrl}${pathname}`;
};

export const brandsOpenApi = {
    auth: {
        me: '/auth/whoami',
        signUp: '/auth/signup',
        embedded: '/auth/embedded',
        signIn: '/auth/signin',
        logout: '/auth/logout',
        createCustomAccount: '/auth/signup/account',
        switchAccount: '/auth/switch/account',
        userAccountConnect: '/auth/connect/user-account',
        getEcommerceRedirect: (platform) => `/ecommerce-oauth/${platform}/redirect`,
        platformCallback: (platform) => `/ecommerce-oauth/${platform}/callback`,
        checkAccountUser: '/auth/user-existence',
        password: {
            reset: '/auth/password/reset/request',
            confirm: '/auth/password/reset/confirm'
        }
    },
    forgotPassword: {
        request: '/password/reset/request',
        confirm: (token) => `/password/reset/confirm?token=${token}`
    },
    setup: {
        info: '/setup/info',
        processor: '/setup/processor',
        paymentIntent: {
            get: '/setup/payment-intent'
        }
    },
    campaigns: {
        list: '/v1/campaigns',
        patch: (campaignId) => `/v1/campaigns/${campaignId}`,
        submit: (campaignId) => `/v1/campaigns/${campaignId}/submit`,
        get: (campaignId) => `/v1/campaigns/${campaignId}`,
        create: '/v1/campaigns',
        products: {
            listPost: (campaignId) => `/v1/campaigns/${campaignId}/products`,
            list: (campaignId) => `/v1/campaigns/${campaignId}/products`,
            set: (campaignId) => `/v1/campaigns/${campaignId}/products`,
            add: (campaignId) => `/v1/campaigns/${campaignId}/products/add`,
            remove: (campaignId) => `/v1/campaigns/${campaignId}/products/remove`
        },
        creative: {
            create: (campaignId) => `/v1/campaigns/${campaignId}/creative`,
            update: (campaignId) => `/v1/campaigns/${campaignId}/creative`
        },
        creativeImage: {
            upload: (campaignId) => `/v1/campaigns/${campaignId}/creative-image`
        },
        priority: {
            calculate: (campaignId) => `/v1/campaigns/${campaignId}/priority`,
            update: (campaignId) => `/v1/campaigns/${campaignId}/priority`
        }
    },
    accounts: {
        getCurrent: '/v1/accounts/current',
        update: '/v1/accounts',
        paymentMethodUpdate: '/v1/account-payment/update-payment-method',
        createStripeAccount: '/v1/account-payment/create',
        updateStripeAccount: (type) => `/v1/account-payment/link?type=${type}`,
        stripeOauthCallback: '/v1/account-payment/oauth-callback',
        stripeAccountInfo: '/v1/account-payment/info',

        commissions: {
            get: '/v1/accounts/commissions',
            update: '/v1/accounts/commissions'
        }
    },
    products: {
        list: '/v1/products'
    },
    productVariants: {
        list: (productId) => `/v1/products/${productId}/variants`
    },
    productImages: {
        update: (productId, imageId) => `/v1/products/${productId}/images/${imageId}`
    },
    productTypes: {
        list: '/v1/product-types'
    },
    productCollections: {
        list: '/v1/product-collections'
    },
    contacts: {
        list: '/v1/contacts',
        delete: (contactId) => `/v1/contacts/${contactId}`,
        get: (contactId) => `/v1/contacts${contactId ? `/${contactId}` : ''}`,
        update: (contactId) => `/v1/contacts/${contactId}`,
        create: '/v1/contacts'
    },
    notifications: {
        unsubscribeList: (token) => `/notifications/unsubscribe/${token}`,
        unsubscribe: '/notifications/unsubscribe/'
    },
    addresses: {
        list: '/v1/addresses',
        delete: (addressId) => `/v1/addresses/${addressId}`,
        get: (addressId) => `/v1/addresses${addressId ? `/${addressId}` : ''}`,
        update: (addressId) => `/v1/addresses/${addressId}`,
        create: '/v1/addresses'
    },
    profile: {
        update: '/profile'
    },
    invoices: {
        list: '/v1/invoices',
        retryCharge: '/invoice/retry-charge'
    },
    taxSettings: {
        get: '/tax-settings',
        set: '/tax-settings'
    },
    teamMembers: {
        list: '/v1/accounts/team-members',
        get: (teamMemberId) => `/v1/accounts/team-members/${teamMemberId}`,
        create: '/v1/accounts/team-members',
        update: (teamMemberId) => `/v1/accounts/team-members/${teamMemberId}`,
        delete: (teamMemberId) => `/v1/accounts/team-members/${teamMemberId}`,
        verifyToken: (token) => `/team-members/invites/verify?token=${token}`,
        userComplete: '/team-members/invites/complete'
    },
    performance: {
        breakdowns: {
            get: '/v1/performance/breakdowns'
        },
        demoInsights: {
            get: '/insights/demo'
        },
        insights: {
            get: '/v1/performance/insights',
            request: '/insights/request'
        },
        liveCampaigns: {
            get: '/v1/performance/campaigns'
        },
        metricsChart: {
            get: (metricName) => `/v1/performance/metrics/${metricName}/chart`
        },
        metricsOverview: {
            get: '/v1/performance/metrics'
        },
        reports: {
            get: '/v1/performance/reports'
        },
        campaigns: {
            list: '/performance/campaigns'
        }
    },
    orders: {
        list: '/v1/orders'
    },
    recharge: {
        getRedirectUrl: '/recharge-oauth/redirect'
    },
    loop: {
        setup: '/loop/setup',
        delete: '/loop/delete'
    },
    googleAds: {
        get: '/google-ads/get',
        update: '/google-ads/update'
    },
    customCreatives: {
        get: (campaignId) => `/custom-creatives/${campaignId}`,
        update: (campaignId) => `/custom-creatives/${campaignId}`
    }
};

export const connectorApi = {
    auth: {
        me: '/auth/whoami'
    },
    shopify: {
        install: makeURL('/app/install'),
        version: makeURL('/shopify/version')
    }
};
