import { Table } from '@ui';
import styled from 'styled-components';

export const StyledInnerTable = styled(Table)`
    .ant-table-footer {
        background-color: transparent;
        padding: 20px 0;
    }

    .ant-table-thead tr th {
        padding: 10px;
    }

    .ant-table-tbody tr td {
        padding: 10px;
    }
`;

export const StyledTable = styled(Table)`
    .ant-table-thead tr th {
        padding: 16px 10px;
    }

    .ant-table-tbody tr td {
        padding: 16px 10px;
    }
`;
