import React, { useEffect, useState } from 'react';
import Footer from '../../layouts/MainLayout/components/Footer';
const BRANDS_FAQ_URL = 'https://faq.brands.klickly.com';

const HelpCenter = () => {
    const [iframePathName, setIframePathName] = useState('');

    useEffect(() => {
        const root = document.querySelector('#root');
        const prevOverflowY = root.style.overflowY;
        root.style.overflowY = 'hidden';

        const pushHistory = (event) => {
            if (event.data.type === 'link') {
                const eventLink = new URL(event.data.link);
                const pathname = eventLink.pathname;
                const hash = eventLink.hash.substring(1);
                const newUrl = `?link=${pathname}${hash ? `&hash=${hash}` : ''}`;
                window.history.pushState({}, '', newUrl);
            }
        };
        updateIframeUrl();

        window.addEventListener('message', pushHistory);

        return () => {
            window.removeEventListener('message', pushHistory);
            root.style.overflowY = prevOverflowY;
        };
    }, []);

    const updateIframeUrl = () => {
        const searchParams = new URLSearchParams(location.search);
        const pathname = searchParams.get('link');
        const hash = searchParams.get('hash');
        const updatedPath = pathname ? `${pathname}${hash ? `#${hash}` : ''}` : '';
        setIframePathName(updatedPath);
    };

    return (
        <React.Fragment>
            <iframe
                style={{ width: '100%', minHeight: 'calc(100vh - 170px)' }}
                title='PerksPage'
                className='perks-page__iframe'
                src={`${BRANDS_FAQ_URL}${iframePathName}`}
                frameBorder='0'
            />
            <Footer />
        </React.Fragment>
    );
};

export default HelpCenter;
