import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, COLORS, ROUTE_PATHS } from '@constants';
import { CAMPAIGN_TYPE_ID } from '@enums';
import { useStore } from '@hooks';
import { Typography } from '@ui';
import { Form, Layout } from 'antd';
import { observer } from 'mobx-react';
import Preview from '../../components/Preview';
import ProductsSelection from '../../components/ProductsSelection';
import { useFormInit } from '../../form';
import { PAGE } from './constants';

const { Sider, Content } = Layout;

const Step2 = observer(() => {
    const navigate = useNavigate();

    const store = useStore();
    const {
        campaign,
        syncSelectionWithServer,
        setFormSubmitting,
        formSubmitting,
        productsSelectionPending,
        setIsNextDisabled,
        setIsNextLoading
    } = store.campaignWizardStore;

    const form = useFormInit();

    const nextDisabled = !campaign?.productsCount;

    const nextLoading = formSubmitting || productsSelectionPending;

    useEffect(() => {
        setIsNextLoading(nextLoading);
    }, [nextLoading]);

    useEffect(() => {
        setIsNextDisabled(nextDisabled);
    }, [nextDisabled]);

    const onFinish = async () => {
        setFormSubmitting(true);
        await syncSelectionWithServer();
        setFormSubmitting(false);

        const { error } = store.campaignWizardStore;
        if (!error) navigate(`${ROUTE_PATHS.CAMPAIGNS_CREATE}/step-3/${campaign.id}`);
    };

    return (
        <Layout hasSider style={{ justifyContent: 'space-between', gap: 40 }}>
            <Form form={form} onFinish={onFinish}>
                <Content tagName='div' style={{ maxWidth: 867 }}>
                    <Typography.Title data-e2e='campaignwizard_step-2_title' style={BOTTOM_INDENTS.M}>
                        {PAGE.TITLE}
                    </Typography.Title>
                    <Typography.Text
                        data-e2e='campaignwizard_step-2_description'
                        type='body2'
                        style={BOTTOM_INDENTS.XS}
                        color={COLORS.$gray60Black}
                    >
                        {campaign.catalogType === CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCTS
                            ? PAGE.DESCRIPTION1_SPECIFIC_PRODUCTS
                            : PAGE.DESCRIPTION1_COMMON}
                    </Typography.Text>
                    <Typography.Text
                        data-e2e='campaignwizard_step-2_hint'
                        type='body2'
                        style={BOTTOM_INDENTS.M}
                        color={COLORS.$gray60Black}
                    >
                        {PAGE.DESCRIPTION2}
                    </Typography.Text>
                    <ProductsSelection />
                </Content>
            </Form>
            <Sider width='300px' style={{ backgroundColor: 'unset' }}>
                <Preview
                    logo={campaign?.creatives?.[0]?.logoUrl}
                    brandLink={store.accountsStore?.currentAccount?.domains?.[0]?.shopDomain}
                />
            </Sider>
        </Layout>
    );
});

export default Step2;
