import React from 'react';
import {
    CAMPAIGN_STATUS,
    CAMPAIGN_STATUS_LABEL_BY_ID,
    CAMPAIGN_TYPE_ID,
    CAMPAIGN_TYPE_LABEL_BY_ID,
    PLATFORM_ID
} from '@enums';
import { formatString } from '@utils';
import { Tag } from '../../../ui';
import ProductName from '../components/ProductName';
import Status from '../components/Status';
import TableItemActions from '../components/TableItemActions';

const VALID_CAMPAIGN_TYPE_IDS_BY_PLATFORM_ID = {
    [PLATFORM_ID.SHOPIFY]: [
        CAMPAIGN_TYPE_ID.MY_ENTIRE_CATALOG,
        CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCTS,
        CAMPAIGN_TYPE_ID.SPECIFIC_COLLECTIONS,
        CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCT_TYPES,
        CAMPAIGN_TYPE_ID.BEST_SELLERS
    ],
    [PLATFORM_ID.BIGCOMMERCE]: [
        CAMPAIGN_TYPE_ID.MY_ENTIRE_CATALOG,
        CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCTS,
        CAMPAIGN_TYPE_ID.BEST_SELLERS
    ],
    [PLATFORM_ID.KLICKLY]: [
        CAMPAIGN_TYPE_ID.MY_ENTIRE_CATALOG,
        CAMPAIGN_TYPE_ID.SPECIFIC_PRODUCTS,
        CAMPAIGN_TYPE_ID.BEST_SELLERS
    ]
};

export const getColumns = (accountCampaignCommissions = [], platformId) => {
    return [
        {
            title: <span data-e2e='campaigns_campaign_column'>Campaign</span>,
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            render: (title, record) => <span data-e2e={`campaign_${record.id}-title_value`}>{title}</span>
        },
        {
            title: <span data-e2e='campaigns_type_column'>Type</span>,
            dataIndex: 'catalogType',
            key: 'catalogType',
            filters: VALID_CAMPAIGN_TYPE_IDS_BY_PLATFORM_ID[platformId].map((campaignTypeId) => ({
                text: (
                    <span data-e2e={`campaigns_type-${formatString(CAMPAIGN_TYPE_LABEL_BY_ID[campaignTypeId])}_filter`}>
                        {CAMPAIGN_TYPE_LABEL_BY_ID[campaignTypeId]}
                    </span>
                ),
                value: Number(campaignTypeId)
            })),
            render: (typeId, record) => (
                <span data-e2e={`campaign_${record.id}-type_value`}>{CAMPAIGN_TYPE_LABEL_BY_ID[typeId]}</span>
            )
        },
        {
            title: <span data-e2e='campaigns_products-count_column'>Products</span>,
            align: 'right',
            className: 'title-right',
            dataIndex: 'productsCount',
            key: 'productsCount',
            sorter: true,
            render: (productsCount, record) => (
                <span data-e2e={`campaign_${record.id}-products-count_value`}>{productsCount}</span>
            )
        },
        {
            title: <span data-e2e='campaigns_priority_column'>Priority</span>,
            align: 'right',
            className: 'title-right',
            dataIndex: 'priority',
            key: 'priority',
            sorter: true,
            render: (priority, record) => (
                <span data-e2e={`campaign_${record.id}-priority_value`}>{`${priority}%`}</span>
            )
        },
        {
            title: <span data-e2e='campaigns_commission_column'>Commission</span>,
            align: 'right',
            className: 'title-right',
            dataIndex: 'commission',
            key: 'commission',
            filters: accountCampaignCommissions.map((commission) => ({
                text: <span data-e2e='campaigns_commission-filter_option'>{`${commission}%`}</span>,
                value: commission
            })),
            render: (commission, record) => (
                <span data-e2e={`campaign_${record.id}-commission_value`}>{`${commission}%`}</span>
            )
        },
        {
            title: <span data-e2e='campaigns_boost_column'>Boost</span>,
            align: 'right',
            className: 'title-right',
            dataIndex: 'boost',
            key: 'boost',
            sorter: true,
            render: (boost, record) => (
                <span data-e2e={`campaign_${record.id}-boost_value`}>{boost?.price ? `$${boost?.price}` : '$0'}</span>
            )
        },
        {
            title: <span data-e2e='campaigns_status_column'>Status</span>,
            dataIndex: 'status',
            key: 'status',
            filters: [
                {
                    text: (
                        <span data-e2e='campaigns_created-status_filter'>
                            {CAMPAIGN_STATUS_LABEL_BY_ID[CAMPAIGN_STATUS.CREATED]}
                        </span>
                    ),
                    value: CAMPAIGN_STATUS.CREATED
                },
                {
                    text: (
                        <span data-e2e='campaigns_live-status_filter'>
                            {CAMPAIGN_STATUS_LABEL_BY_ID[CAMPAIGN_STATUS.ACTIVE]}
                        </span>
                    ),
                    value: CAMPAIGN_STATUS.ACTIVE
                },
                {
                    text: (
                        <span data-e2e='campaigns_paused-status_filter'>
                            {CAMPAIGN_STATUS_LABEL_BY_ID[CAMPAIGN_STATUS.PAUSED]}
                        </span>
                    ),
                    value: CAMPAIGN_STATUS.PAUSED
                },
                {
                    text: (
                        <span data-e2e='campaigns_deleted-status_filter'>
                            {CAMPAIGN_STATUS_LABEL_BY_ID[CAMPAIGN_STATUS.DELETED]}
                        </span>
                    ),
                    value: CAMPAIGN_STATUS.DELETED
                }
            ],
            shouldCellUpdate: () => true,
            render: (_, record) => <Status campaignId={record.id} />
        },
        {
            title: <span data-e2e='campaigns_actions_column'>Actions</span>,
            align: 'left',
            key: 'actions',
            width: '160px',
            render: (_, record) => <TableItemActions campaignId={record.id} />
        }
    ];
};

export const columnsInner = [
    {
        title: <span data-e2e='campaigns_product_column'>Product</span>,
        dataIndex: 'title',
        key: 'title',
        width: '38%',
        render: (title, record) => <ProductName name={title} image={record.primaryImage?.src} id={record.id} />
    },
    {
        title: <span data-e2e='campaigns_product-status_column'>Status</span>,
        dataIndex: 'status',
        key: 'status',
        width: '15.5%',
        render: (_, record) => (
            <Tag data-e2e={`campaigns_product-${record.id}-status_value`} color={record.status.color}>
                {record.status.text}
            </Tag>
        )
    },
    {
        title: <span data-e2e='campaigns_product-type_column'>Product type</span>,
        dataIndex: 'type',
        key: 'type',
        width: '15.5%',
        render: (type, record) => (
            <span data-e2e={`campaigns_product-${record.id}-type_value`}>{type?.title || '-'}</span>
        )
    },
    {
        title: <span data-e2e='campaigns_sku-id_column'>SKU ID</span>,
        key: 'variants',
        width: '15.5%',
        render: (row, record) => (
            <span data-e2e={`campaigns_product-${record.id}-sku-id_value`}>{row.variants[0]?.sku || '-'}</span>
        )
    },
    {
        title: <span data-e2e='campaigns_units-sold_column'>Units sold</span>,
        dataIndex: 'unitsSold',
        key: 'unitsSold',
        width: '15.5%',
        render: (unitsSold, record) => (
            <span data-e2e={`campaigns_product-${record.id}-units-sold_value`}>{unitsSold}</span>
        )
    }
];
