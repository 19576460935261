import React from 'react';
import { Typography } from '@ui';
import { Col, Row, Space } from 'antd';

export const SectionHeader = ({ description, title, children, ...props }) => {
    return (
        <Row align='middle'>
            <Col flex='1 0 auto'>
                {title && <Typography.Title {...props}>{title}</Typography.Title>}
                {description && <Typography.Text {...props}>{description}</Typography.Text>}
            </Col>
            <Col>
                <Space>{children}</Space>
            </Col>
        </Row>
    );
};
