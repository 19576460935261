import React, { useState } from 'react';
import { useStore } from '@hooks';
import { Button } from '@ui';
import { Flex } from 'antd';
import { observer } from 'mobx-react';

const NoAccount = observer(() => {
    const { accountsStore } = useStore();
    const [connectAccLoading, setConnectAccLoading] = useState(false);
    const [createAccLoading, setCreateAccLoading] = useState(false);

    return (
        <Flex justify='flex-end' gap={16}>
            <Button
                data-e2e='payments_have-stripe-account_btn'
                disabled={createAccLoading}
                loading={connectAccLoading}
                onClick={async () => {
                    setConnectAccLoading(true);
                    await accountsStore.handleConnectToStripeAccount();
                }}
                type='primary'
            >
                I have Stripe account
            </Button>
            <Button
                data-e2e='payments_no-stripe-account_btn'
                disabled={connectAccLoading}
                loading={createAccLoading}
                onClick={async () => {
                    setCreateAccLoading(true);
                    await accountsStore.handleCreateStripeAccount();
                }}
                type='primary'
            >
                I do not have a Stripe account
            </Button>
        </Flex>
    );
});

export default NoAccount;
