import React, { useEffect, useState } from 'react';
import { COLORS } from '@constants';
import { copyToClipboard } from '@utils';
import { Icon } from '../../../Icon';
import BaseButton from '../BaseButton';

const CopyButton = ({ value }) => {
    const [showCopied, setShowCopied] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowCopied(null);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [showCopied]);
    return (
        <BaseButton
            data-e2e='common_copy-value_btn'
            onClick={async () => {
                await copyToClipboard((typeof value === 'string' ? value : value?.props?.html) || '');
                setShowCopied(true);
            }}
            type='link'
            icon={
                <Icon
                    size='24px'
                    color={showCopied ? COLORS.$forestGreen : COLORS.$gray60Black}
                    component={() => (showCopied ? <Icon.CheckIcon /> : <Icon.CloneIcon />)}
                />
            }
        />
    );
};

export default CopyButton;
