import React from 'react';
import { COLORS } from '@constants';
import { Tag, Typography } from '@ui';
import { formatPrice } from '@utils';
import { Flex } from 'antd';
import dayjs from 'dayjs';
import { PAYOUT_STATUS_LABELS } from '../../../constants';

export const columns = [
    {
        title: <span data-e2e='invoices_payouts-date_column'>Date</span>,
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '32%',
        render: (value, record) => (
            <span data-e2e={`invoices_payout-${record.id}-date_value`}>
                {dayjs(value).format('ddd, MMM D[th], YYYY')}
            </span>
        )
    },
    {
        title: <span data-e2e='invoices_payouts-statement-descriptor_column'>Statement descriptor</span>,
        dataIndex: 'sequenceNumber',
        key: 'sequenceNumber',
        render: (value, record) => (
            <span data-e2e={`invoices_payout-${record.id}-statement-descriptor_value`}>{`KLICKLY#${value}`}</span>
        )
    },
    {
        title: <span data-e2e='invoices_payouts-status_column'>Status</span>,
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (status, record) => (
            <Tag color={PAYOUT_STATUS_LABELS[status].color} data-e2e={`invoices_payout-${record.id}-status_value`}>
                {PAYOUT_STATUS_LABELS[status].title}
            </Tag>
        )
    },
    {
        title: <span data-e2e='invoices_payouts-owed_column'>Owed</span>,
        dataIndex: 'owed',
        key: 'owed',
        align: 'right',
        render: (_, record) => {
            return (
                <Flex vertical align='flex-end'>
                    <Typography.Text data-e2e={`invoices_payout-${record.id}-owed_value`} type='descriptor1'>
                        {formatPrice(record.fullTotal + record.paid)}
                    </Typography.Text>
                    {record.refunded ? (
                        <Typography.Text
                            data-e2e={`invoices_payout-${record.id}-refunded_value`}
                            color={COLORS.$gray60Black}
                            type='descriptor2'
                        >
                            Refunded: {formatPrice(record.refunded)}
                        </Typography.Text>
                    ) : null}
                </Flex>
            );
        }
    },
    {
        title: <span data-e2e='invoices_payouts-paid_column'>Paid</span>,
        dataIndex: 'paid',
        key: 'paid',
        align: 'right',
        render: (value, record) => (
            <span data-e2e={`invoices_payout-${record.id}-paid_value`}>{formatPrice(value)}</span>
        )
    },
    {
        width: '7%'
    }
];
