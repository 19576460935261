import React from 'react';
import { BOTTOM_INDENTS, COLORS, TOP_INDENTS } from '@constants';
import { Typography } from '@ui';
import { Col, Row } from 'antd';
import { StyledWrapper } from './styled';

const FieldView = ({ value, label, suffix }) => {
    return (
        <StyledWrapper>
            <Row align='middle' justify='space-between'>
                <Col>
                    {label && (
                        <Typography.Text
                            data-e2e='profile_email_descriptor'
                            type='descriptor2'
                            color={COLORS.$gray40Black}
                            style={BOTTOM_INDENTS.XXXXS}
                        >
                            {label}
                        </Typography.Text>
                    )}
                    {value && (
                        <Typography.Text data-e2e='profile_email_displayed-value' type='descriptor1'>
                            {value}
                        </Typography.Text>
                    )}
                </Col>
                {suffix && (
                    <Col>
                        <Typography.Text
                            data-e2e='profile_password_displayed-value'
                            type='descriptor1'
                            style={TOP_INDENTS.XXXXS}
                        >
                            {suffix}
                        </Typography.Text>
                    </Col>
                )}
            </Row>
        </StyledWrapper>
    );
};

export default FieldView;
