import { COLORS } from '@constants';

export const options = (selectedCategory) => {
    return {
        plugins: {
            backgroundColor: {
                color: '#F4F4F4'
            },
            datalabels: {
                anchor: 'start',
                align: 'end',
                color: COLORS.$white,
                font: {
                    size: 14
                },
                formatter: function (value) {
                    const result = Math.round(Number(value));
                    const prefix = selectedCategory === 'revenue' ? '$' : '';

                    return prefix + result;
                }
            },
            title: { display: false },
            legend: { display: false }
        },
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
            x: {
                display: false
            },
            y: {
                ticks: {
                    mirror: true,
                    padding: -300,
                    beginAtZero: true,
                    color: COLORS.$gray100Black,
                    font: {
                        size: 14,
                        weight: 500
                    },
                    callback(value) {
                        const label = this.getLabelForValue(value);
                        return label && label.length > 25 ? `${label.substring(0, 35)}...` : label;
                    }
                },
                beginAtZero: true,
                barThickness: 'flex',
                border: {
                    color: 'transparent'
                },
                grid: {
                    tickMarkLength: 0,
                    zeroLineColor: 'transparent',
                    display: false,
                    drawBorder: false
                },
                display: true,
                title: {
                    display: false
                },
                afterFit(scale) {
                    scale.width = 300;
                }
            }
        }
    };
};
