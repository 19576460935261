import React from 'react';
import { COLORS } from '@constants';
import { CAMPAIGN_CREATIVE_STATUS } from '@enums';
import { useStore } from '@hooks';
import { Divider, Icon, Typography } from '@ui';
import { Card, Flex } from 'antd';
import { observer } from 'mobx-react';

const ApprovedStatusCard = ({ marginBottom }) => {
    return (
        <Card color={COLORS.$robinBlue} style={{ backgroundColor: COLORS.$robinBlue, marginBottom }}>
            <Flex data-e2e='creative-settings_status_block' justify='space-between' align='center'>
                <Typography.Text type='headline2' color={COLORS.$white}>
                    Creative Materials Moderation Status
                </Typography.Text>
                <div style={{ padding: '4px 12px' }}>
                    <Flex align='center' gap={6}>
                        <Flex
                            justify='center'
                            align='center'
                            style={{
                                borderRadius: '1000px',
                                backgroundColor: COLORS.$white,
                                width: 24,
                                height: 24
                            }}
                        >
                            <Icon.CheckIcon width={16} height={16} color={COLORS.$robinBlue} />
                        </Flex>
                        <Typography.Text
                            data-e2e='creative-settings_approved-status_title'
                            type='badgeLarge'
                            color={COLORS.$white}
                        >
                            Approved
                        </Typography.Text>
                    </Flex>
                </div>
            </Flex>
        </Card>
    );
};

const PendingStatusCard = ({ marginBottom }) => {
    return (
        <Card color={COLORS.$robinBlue} style={{ backgroundColor: '#FF8E76', marginBottom }}>
            <Flex data-e2e='creative-settings_status_block' justify='space-between' align='center'>
                <Typography.Text type='headline2' color={COLORS.$white}>
                    Creative Materials Moderation Status
                </Typography.Text>
                <div style={{ padding: '4px 12px' }}>
                    <Typography.Text
                        data-e2e='creative-settings_pending-status_title'
                        type='badgeLarge'
                        color={COLORS.$white}
                    >
                        Pending Review...
                    </Typography.Text>
                </div>
            </Flex>
        </Card>
    );
};

const RejectedStatusCard = ({ rejectedReason, marginBottom }) => {
    return (
        <Card
            color={COLORS.$robinBlue}
            style={{ backgroundColor: COLORS.$tartRed, display: 'flex', flexDirection: 'column', marginBottom }}
        >
            <Flex data-e2e='creative-settings_status_block' justify='space-between' align='center'>
                <Typography.Text type='headline2' color={COLORS.$white}>
                    Creative Materials Moderation Status
                </Typography.Text>
                <div style={{ padding: '4px 12px' }}>
                    <Flex align='center' gap={6}>
                        <Flex
                            justify='center'
                            align='center'
                            style={{
                                borderRadius: '1000px',
                                backgroundColor: COLORS.$white,
                                width: 24,
                                height: 24
                            }}
                        >
                            <Icon.CloseIcon width={16} height={16} color={COLORS.$tartRed} />
                        </Flex>
                        <Typography.Text
                            data-e2e='creative-settings_rejected-status_title'
                            type='badgeLarge'
                            color={COLORS.$white}
                        >
                            Rejected
                        </Typography.Text>
                    </Flex>
                </div>
            </Flex>
            <Divider />
            <Typography.Text data-e2e='creative-settings_rejected-reason_value' color={COLORS.$white} type='body2'>
                {rejectedReason}
            </Typography.Text>
        </Card>
    );
};

const creativeStatusCardsMap = {
    [CAMPAIGN_CREATIVE_STATUS.APPROVED]: ApprovedStatusCard,
    [CAMPAIGN_CREATIVE_STATUS.PENDING]: PendingStatusCard,
    [CAMPAIGN_CREATIVE_STATUS.REJECTED]: RejectedStatusCard
};

const CreativeStatusCard = observer(({ marginBottom = 0 }) => {
    const store = useStore();
    const creative = store.campaignWizardStore?.campaign?.creatives[0];

    if (!creative) {
        return null;
    }

    const { status, rejectedReason } = creative;

    const Card = creativeStatusCardsMap[status];

    return <Card marginBottom={marginBottom} rejectedReason={rejectedReason} />;
});

export default CreativeStatusCard;
