import React from 'react';
import { boosts, BOTTOM_INDENTS } from '@constants';
import { Radio } from '@ui';
import { formatString } from '@utils';
import { Col, Row } from 'antd';
import BoostRadio from './components/BoostRadio';

export const BoostRadios = ({ name = 'boostId', ...props }) => {
    return (
        <Radio.Group name={name} {...props}>
            <Row gutter={16} style={BOTTOM_INDENTS.M}>
                {boosts.map((boost) => (
                    <Col data-e2e={`boosts_${formatString(boost.name)}_radio`} key={boost.id} span={6}>
                        <BoostRadio
                            name={name}
                            value={boost.id}
                            price={boost.price}
                            views={boost.views}
                            label={boost.name}
                        />
                    </Col>
                ))}
            </Row>
        </Radio.Group>
    );
};
