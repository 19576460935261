import React, { useCallback } from 'react';
import { useModal as useAntdModal } from '../ui/Modal';
import { ModalContainer } from '../ui/ModalContainer';

export const useModal = () => {
    const { openModal, closeModal, setParams, modal } = useAntdModal();

    const close = useCallback(() => {
        closeModal();
    }, [closeModal]);

    const open = useCallback(
        ({ title, content, settings = {}, addStyle }) => {
            openModal(
                <ModalContainer title={title} onClose={close} addStyle={addStyle}>
                    {content}
                </ModalContainer>,
                {
                    ...settings
                }
            );
        },
        [openModal, closeModal]
    );

    const update = useCallback(
        ({ settings = {} }) => {
            setParams({ ...settings });
        },
        [setParams, close]
    );

    return { open, close, update, modal };
};
