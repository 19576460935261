import React from 'react';
import { BOTTOM_INDENTS, COLORS, validateDebounce, VALIDATION_RULES } from '@constants';
import { ACCOUNT_USER_ROLE } from '@enums';
import { Button, Input, Select, Typography, WhiteCover } from '@ui';
import { Col, Flex, Form, Row } from 'antd';
import { ACCOUNT_USER_ROLE_LABELS } from '../TeamMember/constants';

const AddMemberForm = ({ form, onFinish, onClose }) => {
    return (
        <WhiteCover
            data-e2e='account-management_new-team-member_form'
            customTitle={<Typography.Text type='badgeMedium'>New team member</Typography.Text>}
            hasDivider={false}
            style={{ backgroundColor: COLORS.$gray10Black, borderColor: COLORS.$gray15Black, marginBottom: 24 }}
        >
            <Form preserve={false} form={form} onFinish={onFinish}>
                <Row gutter={[16, 16]} style={BOTTOM_INDENTS.M}>
                    <Col span={12}>
                        <Form.Item name='firstName'>
                            <Input data-e2e='account-management_first-name_input' label='First Name' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='lastName'>
                            <Input data-e2e='account-management_last-name_input' label='Last Name' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name='email'
                            rules={[...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.EMAIL]}
                            validateDebounce={validateDebounce}
                        >
                            <Input data-e2e='account-management_email_input' label='Email address' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='role' initialValue={ACCOUNT_USER_ROLE.MEMBER}>
                            <Select
                                data-e2e='account-management_select-role_dropdown'
                                options={[
                                    {
                                        value: ACCOUNT_USER_ROLE.MEMBER,
                                        label: (
                                            <span data-e2e='account-management_select-member_option'>
                                                {ACCOUNT_USER_ROLE_LABELS[ACCOUNT_USER_ROLE.MEMBER].title}
                                            </span>
                                        )
                                    },
                                    {
                                        value: ACCOUNT_USER_ROLE.ADMIN,
                                        label: (
                                            <span data-e2e='account-management_select-admin_option'>
                                                {ACCOUNT_USER_ROLE_LABELS[ACCOUNT_USER_ROLE.ADMIN].title}
                                            </span>
                                        )
                                    }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Flex justify='end'>
                    <Flex gap={16}>
                        <Button data-e2e='account-management_cancel_btn' onClick={onClose} style={{ minWidth: 160 }}>
                            Cancel
                        </Button>
                        <Button
                            data-e2e='account-management_save_btn'
                            htmlType='submit'
                            style={{ minWidth: 160 }}
                            type='primary'
                        >
                            Save
                        </Button>
                    </Flex>
                </Flex>
            </Form>
        </WhiteCover>
    );
};

export default AddMemberForm;
