import React from 'react';
import { Typography } from '@ui';
import { formatString } from '@utils';
import { Badge, Col, Row } from 'antd';

const TabLabel = ({ count, label }) => {
    return (
        <Row gutter={8} align='middle' justify='center'>
            <Col>
                <Typography.Text data-e2e={`campaignwizard_${formatString(label)}_tab`} type='badgeMedium'>
                    {label}
                </Typography.Text>
            </Col>
            <Col>
                <Badge data-e2e={`campaignwizard_${formatString(label)}-products_count`} showZero count={count || 0} />
            </Col>
        </Row>
    );
};

export default TabLabel;
