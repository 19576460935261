import { VALIDATION_RULES } from '@constants';

export const FIELD_NAMES = {
    OLD_PASSWORD: 'password',
    NEW_PASSWORD: 'newPassword',
    CONFIRM_PASSWORD: 'confirmPassword'
};

export const CONFIRM_PASSWORD_RULES = [
    ...VALIDATION_RULES.REQUIRED,
    ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue(FIELD_NAMES.NEW_PASSWORD) === value) return Promise.resolve();
            return Promise.reject(new Error('The new password that you entered do not match!'));
        }
    })
];
