import React, { Fragment } from 'react';
import { COLORS } from '@constants';
import { Button, Typography } from '@ui';
import { Flex } from 'antd';
import { StyledAlert } from './styled';

const NotConnected = ({ url }) => {
    return (
        <Fragment>
            <StyledAlert>
                <Flex justify='space-between' align='center'>
                    <Typography.Text
                        data-e2e='integrations_notconnected-status_block'
                        color={COLORS.$tartRed}
                        type='descriptor1'
                    >
                        Klickly Dashboard is not connected to Big Commerce.
                    </Typography.Text>
                    <Button
                        data-e2e='integrations_connect-bc_btn'
                        style={{ width: 184 }}
                        block
                        type='primary'
                        onClick={() => {
                            window.open(url, '_blank');
                        }}
                    >
                        Connect
                    </Button>
                </Flex>
            </StyledAlert>
        </Fragment>
    );
};

export default NotConnected;
