import React from 'react';
import { useModal } from '@hooks';
import CustomCreativesModal from '../CustomCreativesModal';

export const useCustomCreativesModal = () => {
    const { open, close } = useModal();

    const openModal = () =>
        open({
            title: <span>Custom creatives</span>,
            content: <CustomCreativesModal close={close} />,
            addStyle: {
                borderBottom: '1px solid transparent',
                marginBottom: '0'
            },
            settings: {
                width: '1064px'
            }
        });

    return { openModal };
};
