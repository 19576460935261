import { brandsOpenApi } from '@constants';
import { TABS_TYPES } from '@enums';
import { axiosBoa, getErrorMessage, getProductPrimaryImage } from '@utils';
import { flow, types } from 'mobx-state-tree';
import { ProductImageModel } from './productModel';

const Meta = types.model({
    page: types.optional(types.number, 1),
    perPage: types.optional(types.number, 10),
    total: types.optional(types.number, 0),
    totalPages: types.optional(types.number, 1)
});

const ProductVariant = types.model({
    id: types.number,
    name: types.string,
    sku: types.maybeNull(types.string),
    originalPrice: types.maybeNull(types.union(types.float, types.number)),
    price: types.union(types.float, types.number),
    quantity: types.number
});

export const Product = types
    .model({
        id: types.number,
        title: types.string,
        description: types.string,
        images: types.array(ProductImageModel),
        variants: types.array(ProductVariant),
        options: types.maybeNull(
            types.array(
                types.model({
                    id: types.number,
                    name: types.string,
                    values: types.string
                })
            )
        ),
        quantity: types.maybeNull(types.number),
        last30DaysGMV: types.maybeNull(types.number),
        type: types.maybeNull(
            types.model({
                title: types.maybeNull(types.string)
            })
        )
    })
    .views((self) => ({
        get primaryImage() {
            return getProductPrimaryImage(self.images);
        }
    }))
    .actions((self) => ({
        setPrimaryImageLocally(imageId) {
            self.images.forEach((image) => (image.isPrimary = false));

            const newPrimaryImage = self.images.find((image) => image.id === imageId);
            newPrimaryImage.isPrimary = true;
        },
        setPrimaryImage: flow(function* setPrimaryImage(imageId) {
            self.error = null;
            try {
                yield axiosBoa.patch(brandsOpenApi.productImages.update(self.id, imageId), { isPrimary: true });
                self.setPrimaryImageLocally(imageId);
            } catch (error) {
                self.error = getErrorMessage(error);
            }
        })
    }));

const AccountProduct = Product.named('AccountProduct').props({
    inCatalog: types.boolean
});

const Creative = types.model({
    id: types.number,
    campaignId: types.maybeNull(types.number),
    accountId: types.number,
    fontName: types.maybeNull(types.string),
    primaryColor: types.maybeNull(types.string),
    secondaryColor: types.maybeNull(types.string),
    productImageBehavior: types.string,
    logoUrl: types.maybeNull(types.string),
    customPicUrl: types.maybeNull(types.string),
    offerMessage: types.maybeNull(types.string),
    status: types.number,
    rejectedReason: types.maybeNull(types.string),
    rejectedAt: types.maybeNull(types.string),
    createdAt: types.string,
    updatedAt: types.string
});

export const Campaign = types.model({
    id: types.maybeNull(types.identifierNumber),
    accountId: types.maybeNull(types.number),
    boostId: types.maybeNull(types.number),
    catalogType: types.maybeNull(types.number),
    commission: types.maybeNull(types.number),
    creatives: types.array(Creative),
    createdAt: types.maybeNull(types.string),
    deletedAt: types.maybeNull(types.string),
    pausedAt: types.maybeNull(types.string),
    priority: types.number,
    productsCount: types.maybeNull(types.number),
    status: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string)
});

export const SelectionTable = types.model({
    accountProducts: types.model({
        data: types.array(AccountProduct),
        meta: Meta
    }),
    campaignProducts: types.model({
        data: types.array(Product),
        meta: Meta,
        computedCount: 0
    }),
    userExcludedIds: types.array(types.number),
    userIncludedIds: types.array(types.number),
    selectedProductsIds: types.array(types.number),
    currentTabType: TABS_TYPES.ACCOUNT_PRODUCTS,
    searchValue: ''
});

export const defaultSelectionTableState = {
    accountProducts: {
        data: [],
        meta: {}
    },
    campaignProducts: {
        data: [],
        meta: {},
        computedCount: 0
    },
    userExcludedIds: [],
    userIncludedIds: [],
    selectedProductsIds: [],
    currentTabType: TABS_TYPES.ACCOUNT_PRODUCTS,
    searchValue: ''
};
