import React from 'react';
import { COLORS } from '@constants';
import { Icon } from '../../../Icon';

const FilterIcon = (filtered) => {
    return (
        <Icon
            data-e2e='common_filter_icon'
            size='16px'
            color={filtered ? COLORS.$pink : COLORS.$gray40Black}
            component={() => <Icon.FilterIcon />}
        />
    );
};

export default FilterIcon;
