export const FONTS = {
    headline1: 'normal 500 32px/44px "IBM Plex Sans", Sans',
    headline2: 'normal 500 24px/36px "IBM Plex Sans", Sans',
    headline3: 'normal 500 16px/24px "IBM Plex Sans", Sans',
    em0: 'normal 500 48px/64px "IBM Plex Sans", Sans',
    body1: 'normal normal 20px/28px "IBM Plex Sans", Sans',
    body2: 'normal normal 14px/24px "IBM Plex Sans", Sans',
    descriptor1: 'normal normal 16px/24px "IBM Plex Sans", Sans',
    descriptor2: 'normal 500 12px/16px "IBM Plex Sans", Sans',
    badgeLarge: 'normal 500 20px/28px "IBM Plex Sans", Sans',
    badgeMedium: 'normal 500 16px/24px "IBM Plex Sans", Sans',
    badgeSmall: 'normal 500 14px/24px "IBM Plex Sans", Sans',
    badgeTiny: 'normal 500 10px/12px "IBM Plex Sans", Sans',
    caps: 'Capitalized normal 14px/20px "IBM Plex Sans", Sans, SemiBold'
};
