import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS, defaultTimeRange, ROUTE_PATHS, TOP_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Button, Icon, Loader, RangePicker, RedirectLink, Typography } from '@ui';
import { Flex, Row } from 'antd';
import { observer } from 'mobx-react';
import EmptyState from './components/EmptyState';
import SingleCampaign from './components/SingleCampaign';

const CampaignsSection = observer(() => {
    const store = useStore();
    const { isActive } = store.accountsStore;
    const { pendingLiveCampaigns, getLiveCampaigns, liveCampaignsList, liveCampaigns } = store.performanceStore;
    const navigate = useNavigate();

    const handleDateChange = (date) => {
        getLiveCampaigns({
            date_from: date.min,
            date_to: date.max
        });
    };

    if (!pendingLiveCampaigns && !liveCampaigns?.length && isActive) return <EmptyState />;

    return (
        <Fragment>
            <Flex align='center' justify='center' wrap='wrap'>
                <Typography.Title data-e2e='home_campaigns-section_title' style={{ marginRight: 'auto' }}>
                    Live campaigns
                </Typography.Title>
                <Flex gap={24} wrap='wrap'>
                    <Button
                        data-e2e='home_campaign-add_btn'
                        onClick={() => navigate(ROUTE_PATHS.CAMPAIGNS_CREATE_STEP_1)}
                        iconRight={<Icon size='24px' color={COLORS.$white} component={() => <Icon.PlusIcon />} />}
                        type='primary'
                        key='add-campaign'
                    >
                        Add new campaign
                    </Button>
                    <div data-e2e='home_campaign-date-picker_btn'>
                        <RangePicker
                            allowClear={false}
                            key='date-picker'
                            defaultValue={defaultTimeRange}
                            onChange={handleDateChange}
                        />
                    </div>
                </Flex>
            </Flex>
            {pendingLiveCampaigns ? (
                <Row style={{ height: '260px' }}>
                    <Loader
                        data-e2e='home_pending-campaign_loader'
                        style={TOP_INDENTS.L}
                        position='relative'
                        size='120px'
                    />
                </Row>
            ) : (
                <Row data-e2e='homepage_campaigns_row' gutter={[16, 16]} style={TOP_INDENTS.L}>
                    {liveCampaignsList.map((campaign) => (
                        <SingleCampaign key={campaign.id} item={campaign} />
                    ))}
                </Row>
            )}
            <RedirectLink
                data-e2e='homepage_see-all-campaigns_link'
                style={{ ...TOP_INDENTS.XL_PLUS, paddingRight: 16 }}
                text='See all campaigns'
                fontSize='16px'
                to='/campaigns'
            />
        </Fragment>
    );
});

export default CampaignsSection;
