import styled from 'styled-components';

export const StyledContent = styled.div`
    max-width: 423px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 0;
    margin: 0 auto;
`;
