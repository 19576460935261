import React, { useEffect } from 'react';
import config from '../../config';

const Docs = () => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://cdn.redoc.ly/redoc/latest/bundles/redoc.standalone.js';
        script.async = true;
        script.onload = () => {
            window.Redoc.init(
                `${config.boaUrl}/v1/docs`,
                {
                    hideDownloadButton: true
                },
                document.getElementById('redoc-container')
            );
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return <div id='redoc-container' />;
};

export default Docs;
