import React, { useEffect, useRef, useState } from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { useStore } from '@hooks';
import { Button, Icon, Input, Typography } from '@ui';
import { Flex } from 'antd';
import { observer } from 'mobx-react';
import { StyledInputWrap, StyledWrapper } from './styled';

const LandingUrl = observer(() => {
    const store = useStore();
    const customCreativeUrl = store.customCreativesStore.customCreativeUrl;
    const { domainsUrls, primaryShopDomain, mainEcommerceShopDomain } = store.accountsStore;
    const [value, setValue] = useState(customCreativeUrl || '');
    const [isApplied, setIsApplied] = useState(true);
    const [error, setError] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        if (!customCreativeUrl) {
            const domain = primaryShopDomain || mainEcommerceShopDomain || '';
            const formattedUrl = formatUrl(domain);
            setValue(formattedUrl);
            store.customCreativesStore.setCreativeUrl(formattedUrl);
        }
    }, []);

    const formatUrl = (value) => {
        if (!value.startsWith('http://') && !value.startsWith('https://')) {
            return `https://${value}`;
        }

        return value;
    };

    const handleInputChange = (e) => {
        setIsApplied(false);
        setError('');
        setValue(e.target.value);
        inputRef.current?.focus();
    };

    const handleValidateAndSubmit = () => {
        const formattedUrl = formatUrl(value);
        const formattedDomains = domainsUrls.map(formatUrl);

        // Custom validation logic
        if (!formattedUrl || !formattedDomains.some((url) => formattedUrl.startsWith(url))) {
            setError('Please, ensure the landing page URL is published within your domain');
            return;
        }

        store.customCreativesStore.setCreativeUrl(formattedUrl);
        setIsApplied(true);
    };
    return (
        <StyledWrapper>
            <Flex gap={16} align='start' justify='space-between' style={BOTTOM_INDENTS.XXS}>
                <StyledInputWrap>
                    <Input
                        ref={inputRef}
                        label='Landing page URL'
                        value={value}
                        suffix={<Icon component={() => (isApplied ? <Icon.CheckCircleIcon /> : null)} />}
                        onChange={handleInputChange}
                    />
                </StyledInputWrap>

                <Button backgroundColor={COLORS.$white} onClick={handleValidateAndSubmit}>
                    Apply URL
                </Button>
            </Flex>
            <Typography.Text type='descriptor2' color={COLORS.$error} style={BOTTOM_INDENTS.M}>
                {error}
            </Typography.Text>
            <Typography.Text type='body2' color={COLORS.$gray60Black}>
                Please note this URL must be published within your parent website domain
            </Typography.Text>
        </StyledWrapper>
    );
});

export default LandingUrl;
