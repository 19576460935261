import { precisionNumberFormatter } from '@utils';
import { types } from 'mobx-state-tree';
import { METRICS } from '../../pages/Performance/constants';

export const PerformanceModel = types
    .model({
        id: types.maybeNull(types.number),
        label: types.string,
        value: types.frozen()
    })
    .views((self) => ({
        get title() {
            return METRICS[self.label].title;
        },
        get formattedValue() {
            switch (self.title) {
                case METRICS.aov.title:
                    return `$${precisionNumberFormatter(self.value)}`;
                case METRICS.avg_days_from_first_touch_point.title:
                    return precisionNumberFormatter(self.value, true);
                case METRICS.avg_days_from_last_touch_point.title:
                    return precisionNumberFormatter(self.value, true);
                case METRICS.audience.title:
                    return precisionNumberFormatter(self.value, true);
                case METRICS.cpa.title:
                    return `$${precisionNumberFormatter(self.value)}`;
                case METRICS.orders.title:
                    return precisionNumberFormatter(self.value, true);
                case METRICS.revenue.title:
                    return `$${precisionNumberFormatter(self.value)}`;
                case METRICS.spend.title:
                    return `$${precisionNumberFormatter(self.value)}`;
                case METRICS.new_customers.title:
                    return precisionNumberFormatter(self.value, true);
                case METRICS.views.title:
                    return precisionNumberFormatter(self.value, true);
                case METRICS.interactions.title:
                    return precisionNumberFormatter(self.value, true);
                case METRICS.campaign.title:
                    return self.value;
                case METRICS.roas.title:
                    return `${precisionNumberFormatter(self.value)}x`;
                case METRICS.total_products.title:
                    return precisionNumberFormatter(self.value, true);
                default:
                    return 5;
            }
        }
    }));
