import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
`;

export const StyledInputWrap = styled.div`
    &&& {
        flex-grow: 1;
    }
`;
