import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '@constants';
import { useStore } from '@hooks';
import { observer } from 'mobx-react';
import { Loader } from '../ui';

const RequireUnAuth = observer(({ children }) => {
    const store = useStore();
    const location = useLocation();
    const navigate = useNavigate();
    const { getInitialData } = store.authStore;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (store.authStore.me) {
            setLoading(false);
            navigate(ROUTE_PATHS.HOME, { state: { from: location }, replace: true });
            return;
        }

        (async () => {
            // executes when hard reload for example on /login page
            if (store.authStore.me === undefined) {
                await getInitialData();
            }
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <Loader size='120px' />;
    }

    if (store.authStore.me) {
        return <Navigate to={ROUTE_PATHS.HOME} state={{ from: location }} replace />;
    }

    return children || <Outlet />;
});

export default RequireUnAuth;
