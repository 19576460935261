import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '@constants';
import { Button, Icon, Loader, Typography } from '@ui';
import { formatString } from '@utils';
import { Card, Flex, Spin } from 'antd';

const Notification = ({ notification }) => {
    const [loading, setLoading] = useState(false);
    const { title, description, buttonText, link, action } = notification;
    const navigate = useNavigate();

    return (
        <Card data-e2e={`notification_${formatString(title)}_card`} style={{ border: '2px solid #EF2E2E' }}>
            <Spin spinning={loading} indicator={<Loader />}>
                <Flex vertical gap='middle'>
                    <Typography.Title data-e2e={`notification_${formatString(title)}_title`} level={2}>
                        {title}
                    </Typography.Title>
                    <Flex justify='space-between' gap={48}>
                        <Typography.Text
                            data-e2e={`notification_${formatString(title)}_description`}
                            type='headline3'
                            style={{ fontWeight: 400 }}
                        >
                            {description}
                        </Typography.Text>

                        <Button
                            data-e2e={`notification_${formatString(title)}_btn`}
                            onClick={() => {
                                if (action) {
                                    setLoading(true);
                                    action();
                                } else {
                                    navigate(link);
                                }
                            }}
                            type='primary'
                            size='large'
                            iconRight={
                                <Icon size='24px' color={COLORS.$white} component={() => <Icon.ArrowForwardIcon />} />
                            }
                        >
                            {buttonText}
                        </Button>
                    </Flex>
                </Flex>
            </Spin>
        </Card>
    );
};

export default Notification;
