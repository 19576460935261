import React, { Fragment } from 'react';
import { CompanyLogo } from '@components';
import { BOTTOM_INDENTS } from '@constants';
import StyledMenu from './components/Menu';

const SideBar = () => {
    return (
        <Fragment>
            <CompanyLogo style={BOTTOM_INDENTS.XL_PLUS} />
            <StyledMenu />
        </Fragment>
    );
};

export default SideBar;
