import { COLORS } from '@constants';
import { Flex } from 'antd';
import styled from 'styled-components';

export const StyledPriceSection = styled(Flex)`
    width: 50%;
    margin-left: auto;

    a {
        font-weight: 400;
    }
`;

export const StyledRow = styled(Flex)`
    width: 100%;
    padding: ${({ $padding = '20px 16px' }) => $padding};
    border-top: 1px solid ${({ $borderColor = 'transparent' }) => $borderColor};

    .ant-row {
        width: 100%;
    }

    a {
        font-weight: 400;
    }
`;

export const StyledSubscription = styled(Flex)`
    background-color: #fff7e5;
    border: 1px solid ${COLORS.$alert};
    border-radius: 4px;
    padding: 16px;
`;
