import React from 'react';
import { Typography } from '@ui';
import { StyledIcon, StyledWrapper } from './styled';

const BaselineInfo = () => {
    return (
        <StyledWrapper>
            <StyledIcon />
            <Typography.Text data-e2e='insights_baseline_description' type='body2'>
                Baseline: US Internet Population
            </Typography.Text>
        </StyledWrapper>
    );
};

export default BaselineInfo;
