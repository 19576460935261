import React from 'react';
import { ROUTE_PATHS } from '@constants';
import { Button } from '@ui';
import { Col } from 'antd';
import { StyledRow } from './styled';

const Return = () => {
    return (
        <StyledRow justify='center' align='middle'>
            <Col>
                <Button
                    type='primary'
                    onClick={() => {
                        window.open(ROUTE_PATHS.HOME, '_blank');
                    }}
                    block
                >
                    Return to dashboard
                </Button>
            </Col>
        </StyledRow>
    );
};

export default Return;
