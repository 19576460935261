import styled from 'styled-components';

export const StyledImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const StyledText = styled.p`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
`;
