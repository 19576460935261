export const boosts = [
    {
        id: 1,
        price: 10,
        name: 'Basic Boost',
        views: 5000
    },
    {
        id: 2,
        price: 24,
        name: 'Better Boost',
        views: 15000
    },
    {
        id: 3,
        price: 99,
        name: 'Best Boost',
        views: 75000
    },
    {
        id: 4,
        price: 199,
        name: 'Beyond Boost',
        views: 175000
    }
];
