import { Collapse } from 'antd';
import styled from 'styled-components';

export const StyledCollapce = styled(Collapse)`
    &&& {
        &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            padding: 8px 16px;
            color: rgba(0, 0, 0, 0.88);
            line-height: 1.5714285714285714;
            cursor: pointer;
            transition: all 0.3s, visibility 0s;
        }

        .ant-collapse-content-box {
            padding-block: 0;

            > div {
                margin: 12px 0;
            }
        }
    }
`;
