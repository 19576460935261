import { TRACK_EVENT } from '@enums';

export const mapFormToSettings = ({
    customizeUtm,
    customizeUtmMedium,
    customizeUtmSource,
    googleAnalytics,
    googleAnalyticsMeasurementId,
    googleAnalyticsViews,
    googleAnalyticsNonLinkedViews,
    googleAnalyticsClicks,
    googleAnalyticsAddToCarts,
    googleAnalyticsPurchases,
    tripleWhale,
    tripleWhaleViews,
    tripleWhaleClicks,
    tripleWhaleAddToCarts,
    tripleWhalePurchases,
    tripleWhaleNonLinkedViews
}) => {
    let utmSettings;
    if (customizeUtm) {
        utmSettings = {
            enabled: customizeUtm,
            source: customizeUtmSource,
            medium: customizeUtmMedium
        };
    } else {
        utmSettings = {
            enabled: customizeUtm
        };
    }

    let ga4Settings;
    if (googleAnalytics) {
        const eventsToTrack = [
            googleAnalyticsViews && TRACK_EVENT.VIEWS,
            googleAnalyticsClicks && TRACK_EVENT.CLICKS,
            googleAnalyticsAddToCarts && TRACK_EVENT.ADD_TO_CARTS,
            googleAnalyticsPurchases && TRACK_EVENT.PURCHASES
        ].filter(Boolean);

        ga4Settings = {
            enabled: googleAnalytics,
            eventsToTrack,
            includeNonLinkedViews: googleAnalyticsNonLinkedViews,
            measurementId: typeof googleAnalyticsMeasurementId === 'string' ? googleAnalyticsMeasurementId : undefined
        };
    } else {
        ga4Settings = { enabled: googleAnalytics };
    }

    let tripleWhaleSettings;

    if (tripleWhale) {
        const eventsToTrack = [
            tripleWhaleViews && TRACK_EVENT.VIEWS,
            tripleWhaleClicks && TRACK_EVENT.CLICKS,
            tripleWhaleAddToCarts && TRACK_EVENT.ADD_TO_CARTS,
            tripleWhalePurchases && TRACK_EVENT.PURCHASES
        ].filter(Boolean);

        tripleWhaleSettings = {
            enabled: tripleWhale,
            eventsToTrack,
            includeNonLinkedViews: tripleWhaleNonLinkedViews
        };
    } else {
        tripleWhaleSettings = { enabled: tripleWhale };
    }

    return { ga4Settings, utmSettings, tripleWhaleSettings };
};
