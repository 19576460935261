import { COLORS } from '@constants';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
`;

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: ${({ $borderBottom = `1px solid ${COLORS.$gray15Black}` }) => $borderBottom};
    padding-bottom: 24px;
    margin-bottom: ${({ $marginBottom = '48px' }) => $marginBottom};
    & > h2 {
        flex: 1 0 auto;
    }

    & > button.ant-btn {
        flex: 0 0 auto;
    }
`;

export const StyledContent = styled.div``;
