import { FIELD_NAME } from '@constants';
import _ from 'lodash';

export const getInitialValues = (campaign, accountCommission) => {
    const res = {
        [FIELD_NAME.CAMPAIGN.NAME]: campaign?.title,
        [FIELD_NAME.CAMPAIGN.TYPE]: campaign?.catalogType,
        [FIELD_NAME.CAMPAIGN.COMMISSION]: campaign?.commission,
        [FIELD_NAME.ACCOUNT.REST_OF_SITE_COMMISSION]: accountCommission?.restOfSite,
        [FIELD_NAME.ACCOUNT.NEW_CUSTOMER_COMMISSION]: accountCommission?.newCustomer,
        [FIELD_NAME.ACCOUNT.COLD_CUSTOMER_COMMISSION]: accountCommission?.coldCustomer,
        [FIELD_NAME.CAMPAIGN.BOOST]: campaign?.boostId || null
    };

    if (campaign?.creatives?.length) {
        const creative = campaign.creatives[0];
        _.merge(res, {
            [FIELD_NAME.CREATIVE.FONT]: creative.fontName,
            [FIELD_NAME.CREATIVE.PRIMARY_COLOR]: creative.primaryColor,
            [FIELD_NAME.CREATIVE.SECONDARY_COLOR]: creative.secondaryColor,
            [FIELD_NAME.CREATIVE.OFFER_MESSAGE]: creative.offerMessage,
            [FIELD_NAME.CREATIVE.PRODUCT_IMAGES_FORMAT]: creative.productImageBehavior
        });
    }

    return res;
};
