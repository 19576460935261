import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Radio, Typography } from '@ui';
import { formatString } from '@utils';
import { Col, Row } from 'antd';
import { CampaignTypeCardWrapper } from './styled';

const CampaignTypeCard = ({ CardIcon, label, description, expanded = false, expandedContent, ...radioProps }) => {
    return (
        <CampaignTypeCardWrapper data-e2e={`campaignwizard_${formatString(label)}-type_card`}>
            <Radio {...radioProps} />
            <Row gutter={24} align='middle' wrap={false}>
                <Col>
                    <CardIcon
                        data-e2e={`campaignwizard_${formatString(label)}-type_icon`}
                        color={COLORS.$gray40Black}
                        width='80px'
                        height='80px'
                    />
                </Col>
                <Col>
                    <Typography.Text
                        data-e2e={`campaignwizard_${formatString(label)}-type_title`}
                        style={BOTTOM_INDENTS.XXS}
                        type='badgeLarge'
                    >
                        {label}
                    </Typography.Text>
                    {description && (
                        <Typography.Text
                            data-e2e={`campaignwizard_${formatString(label)}-type_description`}
                            type='descriptor1'
                        >
                            {description}
                        </Typography.Text>
                    )}
                </Col>
            </Row>
            {expanded && expandedContent}
        </CampaignTypeCardWrapper>
    );
};

export default CampaignTypeCard;
