import { COLORS } from '@constants';
import { Flex } from 'antd';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
    min-height: 100%;
    padding: 48px 24px;
    background: ${COLORS.$gray10Black};
    max-width: 1328px;
    margin: 0 auto;
`;

export const StyledLoaderWrapper = styled(Flex)`
    width: 100%;
    padding-inline: 24px;
    height: 100vh;
    background: ${COLORS.$gray10Black};
`;

export const StyledFlex = styled(Flex)`
    height: 104px;
`;
