import { Button } from '@ui';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 0 16px;
`;

export const StyledButton = styled(Button)`
    padding: 0 16px;
`;
