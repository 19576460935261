import React, { useState } from 'react';
import { BOTTOM_INDENTS, COLORS, validateDebounce, VALIDATION_RULES } from '@constants';
import { CONTACT_VALUE_TYPE, NOTIFICATION_ACTIONS } from '@enums';
import { useStore } from '@hooks';
import { Input, Select, Typography, WhiteCover } from '@ui';
import { Checkbox, Col, Form, Row } from 'antd';
import { observer } from 'mobx-react';
import { CONTACT_METHODS_OPTIONS, FIELD_NAMES } from '../../constants';
import DeleteBtn from '..//DeleteBtn';

const { VALUE_TYPE, VALUE, NOTIFY_ON_ACTIONS, NOTIFICATION_CONTACTS } = FIELD_NAMES;

const NotificationContact = observer(({ contact, remove }) => {
    const store = useStore();
    const { klicklyEmail } = store.accountsStore;

    const form = Form.useFormInstance();

    const [valueType, setValueType] = useState(form.getFieldValue([NOTIFICATION_CONTACTS, contact.name, VALUE_TYPE]));
    const contactItem = form.getFieldValue([NOTIFICATION_CONTACTS, contact.name]);

    const isKlicklyEmail = contactItem.id === klicklyEmail.id;

    const input = valueType === CONTACT_VALUE_TYPE.EMAIL ? <Input disabled={isKlicklyEmail} /> : <Input.Phone />;
    const rules =
        valueType === CONTACT_VALUE_TYPE.EMAIL
            ? [...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.EMAIL]
            : [...VALIDATION_RULES.REQUIRED, ...VALIDATION_RULES.PHONE];

    return (
        <WhiteCover data-e2e='notifications_contact-method_form' hasDivider={false}>
            {!isKlicklyEmail && (
                <DeleteBtn
                    onClick={() => {
                        remove(contact.name);
                    }}
                />
            )}

            <Row gutter={16} style={BOTTOM_INDENTS.S}>
                <Col span={8}>
                    <Form.Item name={[contact.name, VALUE_TYPE]}>
                        <Select
                            data-e2e='notifications_contact-methods_dropdown'
                            onChange={(value) => {
                                setValueType(value);
                                form.setFields([
                                    {
                                        name: [NOTIFICATION_CONTACTS, contact.name, VALUE],
                                        value: '',
                                        errors: [],
                                        touched: false,
                                        validated: false
                                    }
                                ]);
                            }}
                            disabled={isKlicklyEmail}
                            label='Contact via'
                            options={CONTACT_METHODS_OPTIONS}
                        />
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <Form.Item
                        data-e2e='notifications_contact-method_input'
                        rules={rules}
                        name={[contact.name, VALUE]}
                        validateDebounce={validateDebounce}
                    >
                        {input}
                    </Form.Item>
                </Col>
            </Row>
            <Typography.Text type='body2' color={COLORS.$gray60Black} style={BOTTOM_INDENTS.S}>
                Select the types of notifications to receive
            </Typography.Text>
            <Form.Item name={[contact.name, NOTIFY_ON_ACTIONS]}>
                <Checkbox.Group style={{ display: 'flex', width: '100%' }}>
                    <Row>
                        <Col span={6}>
                            <Checkbox
                                data-e2e='notifications_account_checkbox'
                                disabled={isKlicklyEmail}
                                label='account'
                                value={NOTIFICATION_ACTIONS.ACCOUNT}
                            >
                                Account
                            </Checkbox>
                        </Col>
                        <Col span={6}>
                            <Checkbox
                                data-e2e='notifications_marketing_checkbox'
                                label='marketing'
                                value={NOTIFICATION_ACTIONS.MARKETING}
                            >
                                Marketing
                            </Checkbox>
                        </Col>
                        <Col span={6}>
                            <Checkbox
                                data-e2e='notifications_campaign_checkbox'
                                label='campaign'
                                value={NOTIFICATION_ACTIONS.CAMPAIGN}
                            >
                                Campaign
                            </Checkbox>
                        </Col>
                        <Col span={6}>
                            <Checkbox
                                data-e2e='notifications_fulfillment_checkbox'
                                label='fulfillment'
                                value={NOTIFICATION_ACTIONS.FULFILLMENT}
                            >
                                Fulfillment
                            </Checkbox>
                        </Col>
                    </Row>
                </Checkbox.Group>
            </Form.Item>
        </WhiteCover>
    );
});

export default NotificationContact;
