import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { CAMPAIGN_COMMISSION_SOURCE_TYPE } from '@enums';
import { Typography } from '@ui';
import { formatPrice, formatString } from '@utils';
import { Col, Flex, Row } from 'antd';
import { getCommissionData } from '../../utils/getCommissionData';
import { renderAddress } from '../../utils/renderAddress';
import { StyledPriceSection, StyledRow, StyledSubscription } from './styled';

const PriceLine = ({ label, value, borderColor, type }) => {
    return (
        <StyledRow $borderColor={borderColor} gap={20} justify='space-between'>
            <Typography.Text data-e2e={`orders_${formatString(label)}_row`} type={type}>
                {label}
            </Typography.Text>
            <Typography.Text data-e2e={`orders_${formatString(label)}_value`} type={type}>
                {value}
            </Typography.Text>
        </StyledRow>
    );
};

const ColumnDetails = ({ label, value }) => {
    return (
        <Col span={6}>
            <Flex vertical gap={4}>
                <Typography.Text
                    data-e2e={`orders_${formatString(label)}_column`}
                    color={COLORS.$gray40Black}
                    type='descriptor2'
                >
                    {label}
                </Typography.Text>
                <Typography.Text data-e2e={`orders_${formatString(label)}_value`} type='body2'>
                    {value}
                </Typography.Text>
            </Flex>
        </Col>
    );
};

const OrderDetailsFooter = ({ record }) => {
    const commissionData = getCommissionData(
        record.metrics.commissionTypeName,
        record.orderItems,
        record.metrics.isCommissionLimitedToSubtotal
    );

    return (
        <Flex vertical>
            <StyledPriceSection data-e2e='orders_price_block' style={BOTTOM_INDENTS.S} vertical>
                <PriceLine type='body2' label='Sub-total' value={formatPrice(record.subtotalPrice)} />
                <PriceLine
                    type='body2'
                    borderColor={COLORS.$gray15Black}
                    label='Shipping'
                    value={formatPrice(record.shippingPrice)}
                />
                <PriceLine
                    type='body2'
                    borderColor={COLORS.$gray15Black}
                    label='Tax'
                    value={formatPrice(record.taxPrice)}
                />
                <PriceLine
                    type='badgeSmall'
                    borderColor={COLORS.$gray100Black}
                    label='Total Price'
                    value={formatPrice(record.totalPrice)}
                />
            </StyledPriceSection>
            <StyledRow data-e2e='orders_billing-details_block' $borderColor={COLORS.$gray15Black} $padding='32px 16px'>
                <Row>
                    <Col span={6}>
                        <Flex vertical>
                            <Typography.Text
                                data-e2e='orders_billing-address_column'
                                color={COLORS.$gray40Black}
                                type='descriptor2'
                            >
                                Billing Address
                            </Typography.Text>
                            {renderAddress(record.billingAddress, 'billing')}
                        </Flex>
                    </Col>
                    <Col span={6}>
                        <Flex vertical>
                            <Typography.Text
                                data-e2e='orders_shipping-address_column'
                                color={COLORS.$gray40Black}
                                type='descriptor2'
                            >
                                Shipping Address
                            </Typography.Text>
                            {renderAddress(record.shippingAddress, 'shipping')}
                        </Flex>
                    </Col>
                    <Col span={6}>
                        <Flex vertical gap={4}>
                            <Typography.Text
                                data-e2e='orders_customer-email_column'
                                color={COLORS.$gray40Black}
                                type='descriptor2'
                            >
                                Customer Email
                            </Typography.Text>
                            <Typography.Text data-e2e='orders_customer-email_value' type='descriptor1'>
                                {record.email ? (
                                    <a
                                        className='tets'
                                        href={`mailto:${record.email}`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {record.email}
                                    </a>
                                ) : (
                                    '-'
                                )}
                            </Typography.Text>
                        </Flex>
                    </Col>
                    <Col span={6}>
                        <Flex vertical gap={4}>
                            <Typography.Text
                                data-e2e='orders_phone_column'
                                color={COLORS.$gray40Black}
                                type='descriptor2'
                            >
                                Phone
                            </Typography.Text>
                            <Typography.Text data-e2e='orders_phone_value' type='descriptor1'>
                                {record.phone || '-'}
                            </Typography.Text>
                        </Flex>
                    </Col>
                </Row>
            </StyledRow>
            <StyledRow
                data-e2e='orders_engagement-details_block'
                $borderColor={COLORS.$gray15Black}
                $padding='32px 16px'
            >
                <Row>
                    <ColumnDetails label='Total engagements (last 90 days)' value={record.metrics.totalEngagements} />
                    <ColumnDetails label='Days to conversion' value={record.metrics.daysToConversionToDisplay} />
                    <ColumnDetails label='Last engagement' value={record.metrics.lastEngagement} />
                    <ColumnDetails label='Commission type' value={commissionData.type} />
                </Row>
            </StyledRow>
            {record.isSubscription ? (
                <StyledSubscription data-e2e='orders_subscription-order_block' justify='space-between' align='center'>
                    <Flex align='flex-end' gap={6}>
                        <Typography.Text
                            data-e2e='orders_subscription-order_title'
                            color={COLORS.$alert}
                            type='badgeMedium'
                        >
                            Subscription order
                        </Typography.Text>
                        <Typography.Text
                            data-e2e='orders_subscription-order_value'
                            color={COLORS.$alert}
                            type='badgeLarge'
                        >
                            {record?.metrics?.commissionSourceType === CAMPAIGN_COMMISSION_SOURCE_TYPE.SUBSCRIPTION
                                ? '2/2'
                                : '1/2'}
                        </Typography.Text>
                    </Flex>
                    <Typography.Text
                        data-e2e='orders_subscription-order_description'
                        color={COLORS.$alert}
                        type='badgeSmall'
                    >
                        Klickly bills only for 1st and 2nd subscription orders per customer.
                    </Typography.Text>
                </StyledSubscription>
            ) : null}
        </Flex>
    );
};

export default OrderDetailsFooter;
