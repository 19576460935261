import { getParent, types } from 'mobx-state-tree';

export const ContactModel = types
    .model('ContactModel', {
        id: types.number,
        accountId: types.number,
        type: types.number,
        value: types.maybeNull(types.string),
        valueType: types.number,
        verified: types.boolean,
        confirmed: types.boolean,
        notifyOnActions: types.array(types.frozen())
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        return {};
    });
