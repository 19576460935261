import styled from 'styled-components';

export const StyledWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledAmount = styled.div`
    margin-right: 64px;
`;
