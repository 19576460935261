import { useLocation, useParams, useSearchParams } from 'react-router-dom';

export const useCampaignWizard = () => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const stepRegExp = /^\/campaigns\/create\/step-([1-5])(\/(\d+))?$/;
    const match = location.pathname.match(stepRegExp);
    const step = match ? Number(match[1]) : null;
    const isCreate = Boolean(match);

    let campaignId;

    if (isCreate) {
        if (step === 1) {
            campaignId = Number(searchParams.get('fromCampaign'));
        }

        if (match && match[3]) {
            campaignId = Number(match[3]);
        }
    }

    const isEdit = location.pathname.includes('edit');
    if (isEdit) {
        if (params.id) {
            campaignId = Number(params.id);
        }
    }

    const isLaunchCheck = location.pathname.includes('launch-check');
    if (isLaunchCheck) {
        if (params.id) {
            campaignId = Number(params.id);
        }
    }

    return {
        isLaunchCheck,
        isEdit,
        isCreate,
        step,
        campaignId
    };
};
