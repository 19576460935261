import styled from 'styled-components';

export const StyledWrapper = styled.div`
    display: flex;
    position: relative;
    align-items: center;

    .title {
        width: 100px;

        .ant-skeleton-title {
            height: 40px;
        }
    }

    .subtitle {
        width: 180px;

        .ant-skeleton-title {
            height: 20px;
        }
    }

    .description {
        .ant-skeleton-title {
            height: 14px;
        }

        &.ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph > li + li {
            margin-block-start: 8px;
        }
    }
`;

export const StyledProgressWrapper = styled.div`
    width: fit-content;
    padding: 0 32px;

    * {
        -webkit-backface-visibility: unset;
    }
`;
