import React from 'react';
import image310 from '../../images/310.svg';
import aneseImage from '../../images/anese.svg';
import bloomImage from '../../images/bloom.svg';
import riotImage from '../../images/riot.svg';
import utzImage from '../../images/utz.svg';
import vegamourImage from '../../images/vegamour.svg';
import { StyledTrustedSection } from './styled';

const brandsReview = [
    {
        text:
            '“I love Klickly. They enable brands to immediately stop wasting money on worthless ' +
            'CPM media buys and focus on driving real revenue and lowering my CPA.”',
        image: aneseImage
    },
    {
        text:
            '“I appreciate Klickly’s regular check-ins and have been pleased with the performance we’re seeing from Klickly ... ' +
            'Setup was quick and didn’t take much of my time which is important.”',
        image: bloomImage
    },
    {
        text:
            '“We have been more than pleased with performance. Klickly continues to meet our expectations! ' +
            'Setup was seamless and love the commission-based model, makes it easy to …get the most ROAS possible. ' +
            'I appreciate Klickly’s regular guidance with strategy when needed.”',
        image: vegamourImage
    },
    {
        text:
            '“Klickly has been one of the most effective tools that we’ve used. ' +
            'The ability that Klickly has to get me a high return has actually blown everything else out of the water that we’ve tried.”',
        image: utzImage
    },
    {
        text: '“I’ve been excited with the performance we’re seeing from Klickly. Setup was seamless … It’s nice to have complete control of my ROAS.”',
        image: image310
    },
    {
        text: '“Your platform is a game changer and should be pushed throughout the eCommerce space.”',
        image: riotImage
    }
];

const TrustedBrands = () => {
    return (
        <StyledTrustedSection>
            <div data-e2e='landing_trusted-brands_section' className='container-content'>
                <div className='trusted__wrapper'>
                    <h2 data-e2e='landing_trusted-brands-section_title'>Trusted by top brands</h2>
                    <p data-e2e='landing_trusted-brands-section_description'>
                        Unlock the full potential of your e-commerce store with Klickly - let AI drive sales for you on
                        a risk-free basis. No need for costly upfront ad spend. Our advanced automation technology
                        generates substantial revenue for businesses of all types. Trust us to boost your online sales
                        and take your brand to the next level.
                    </p>
                    <div className='trusted__wrapper__brands'>
                        {brandsReview.map((item, index) => {
                            return (
                                <div key={index} className='trusted__wrapper__brands__single'>
                                    <img alt={item.text} src={item.image} />
                                    <p data-e2e={`landing_feedback-${index}_text`}>{item.text}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </StyledTrustedSection>
    );
};

export default TrustedBrands;
