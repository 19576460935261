import React, { Fragment, useState } from 'react';
import { BOTTOM_INDENTS, defaultTimeProps, ROUTE_PATHS } from '@constants';
import { Divider, RedirectLink, Typography } from '@ui';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import Breakdowns from './components/Breakdowns';
import Menu from './components/Menu';
import Overview from './components/Overview';
import Reports from './components/Reports';
import { MENU_ITEM_IDS } from './constants';

const Performance = observer(() => {
    const [activeAnchor, setActiveAnchor] = useState(MENU_ITEM_IDS.OVERVIEW);
    const [oldAnchor, setOldAnchor] = useState(MENU_ITEM_IDS.OVERVIEW);
    const [date, setDate] = useState(defaultTimeProps);

    // TODO: rewrite EmptyState component and it's logic after PO's review
    // if (!overviewPending && !chartDatasets.length) return <EmptyState />;

    const onComponentInViewUpdate = ({ newAnchor, isInView }) => {
        if (isInView) {
            setOldAnchor(activeAnchor);
            setActiveAnchor(newAnchor);
        }

        if (!isInView && newAnchor === activeAnchor && oldAnchor) {
            setOldAnchor(activeAnchor);
            setActiveAnchor(oldAnchor);
        }
    };

    return (
        <Fragment>
            <Typography.Text data-e2e='performance_page-title' style={BOTTOM_INDENTS.XL_PLUS} type='em0'>
                Performance
            </Typography.Text>
            <Row style={BOTTOM_INDENTS.XL_PLUS} gutter={[16, 16]} justify='middle'>
                <Col data-e2e='performance_page-description' flex='1 0 auto'>
                    Access performance metrics for your marketing campaigns. For 3rd-party reporting settings (e.g. GA),
                    click{' '}
                    <RedirectLink
                        data-e2e='performance_settings-redirect_link'
                        display='inline'
                        text='here'
                        fontSize='14px'
                        fontWeight='400'
                        to={ROUTE_PATHS.TRACKING_SETTINGS}
                    />
                    .
                </Col>
            </Row>

            <Menu onDateChange={setDate} activeAnchor={activeAnchor} />

            <Overview
                date={date}
                onComponentViewUpdate={(isInView) =>
                    onComponentInViewUpdate({ newAnchor: MENU_ITEM_IDS.OVERVIEW, isInView })
                }
            />
            <Divider margin='64px 0' />
            <Breakdowns
                date={date}
                onComponentViewUpdate={(isInView) =>
                    onComponentInViewUpdate({ newAnchor: MENU_ITEM_IDS.BREAKDOWNS, isInView })
                }
            />
            <Divider margin='64px 0' />
            <Reports
                date={date}
                onComponentViewUpdate={(isInView) =>
                    onComponentInViewUpdate({ newAnchor: MENU_ITEM_IDS.REPORTS, isInView })
                }
            />
        </Fragment>
    );
});

export default Performance;
