import React from 'react';
import { BOTTOM_INDENTS, COLORS, CUSTOMER_INFO_FIELD_NAMES } from '@constants';
import { useStore } from '@hooks';
import { Typography, WhiteCover } from '@ui';
import { formatString } from '@utils';
import { Checkbox, Col, Form, Row } from 'antd';
import { observer } from 'mobx-react';

const SalesTaxes = observer(({ style }) => {
    const store = useStore();
    const states = store.accountsStore.states;

    return (
        <WhiteCover data-e2e='customer-management_sales-taxes_section' title='Sales Taxes' style={style}>
            <Typography.Text
                data-e2e='customer-management_sales-taxes_description'
                type='body2'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.M}
            >
                Select the states or regions in which your business is liable for sales tax.
            </Typography.Text>
            <Form.Item name={CUSTOMER_INFO_FIELD_NAMES.STATES_LIST}>
                <Checkbox.Group>
                    <Row gutter={[32, 32]}>
                        {states.map((state) => (
                            <Col span={8} key={state.id}>
                                <Checkbox
                                    data-e2e={`customer-management_taxes-${formatString(state.name)}_checkbox`}
                                    value={state.id}
                                >
                                    {state.name}
                                </Checkbox>
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>
            </Form.Item>
        </WhiteCover>
    );
});

export default SalesTaxes;
