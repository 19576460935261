import React from 'react';
import { COLORS } from '@constants';
import { Typography } from '@ui';
import { StyledMetric } from './styled';

const Metrics = ({ title, value }) => {
    return (
        <StyledMetric>
            <Typography.Text type='badgeSmall' color={COLORS.$gray40Black}>
                {title}
            </Typography.Text>
            <Typography.Text type='badgeSmall' color={COLORS.$gray100Black}>
                {value}
            </Typography.Text>
        </StyledMetric>
    );
};

export default Metrics;
