import React, { lazy, Suspense } from 'react';
import { device, isMobileLandscapeView, isMobileView } from '@utils';
import * as cn from 'classnames';
import { StyledPoweredBy } from './styled';

const SliderImageLeft = lazy(() => import('../LazyImages/components/PoweredByLeft'));
const SliderImageRight = lazy(() => import('../LazyImages/components/PoweredByRight'));
const SliderImageMob = lazy(() => import('../LazyImages/components/PoweredByMobile'));

const PoweredBy = () => {
    const isSafari = device.isSafari();

    return (
        <StyledPoweredBy className='bg-gradient bg-gradient-orange-invert'>
            <div data-e2e='landing_powered-by-ai_section' className='container-content'>
                <div className='powered-by__wrapper'>
                    <div className='powered-by__wrapper__left'>
                        <h2 data-e2e='landing_powered-by-ai_title'>
                            Powered{isMobileView() || isMobileLandscapeView() ? <br /> : null} by AI
                        </h2>
                        <p data-e2e='landing_powered-by-ai_description'>
                            AI – combined with proprietary data – drives brand awareness, product
                            <br />
                            discovery, and sales for the most exciting brands online
                        </p>
                    </div>
                    <div className='powered-by__wrapper__slider'>
                        {isMobileView() || isMobileLandscapeView() ? (
                            <div className={cn('powered-by__wrapper__slider__mob', isSafari ? 'safari' : null)}>
                                <Suspense fallback={<React.Fragment />}>
                                    <SliderImageMob />
                                </Suspense>
                            </div>
                        ) : (
                            <React.Fragment>
                                <div className='powered-by__wrapper__slider__left'>
                                    <Suspense fallback={<React.Fragment />}>
                                        <SliderImageLeft />
                                    </Suspense>
                                </div>
                                <div className='powered-by__wrapper__slider__right'>
                                    <Suspense fallback={<React.Fragment />}>
                                        <SliderImageRight />
                                    </Suspense>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </StyledPoweredBy>
    );
};

export default PoweredBy;
