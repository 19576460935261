import { COLORS } from '@constants';

export const bgColorPluginConfig = {
    id: 'backgroundColor',
    beforeDraw: function (chart) {
        const ctx = chart.$context.chart.ctx;
        ctx.fillStyle = 'transparent';

        const chartArea = chart.chartArea;
        ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
    },
    afterDatasetsDraw: function (chartInstance) {
        const ctx = chartInstance.$context.chart.ctx;
        const width = chartInstance.chartArea.right;
        chartInstance.data.datasets.forEach(function (dataset, datasetIndex) {
            const datasetMeta = chartInstance.getDatasetMeta(datasetIndex);
            datasetMeta.data.forEach(function (segment) {
                const height = segment.height;
                const posX = segment.tooltipPosition().x;
                const posY = segment.tooltipPosition().y - height / 2;
                ctx.save();
                ctx.fillStyle = COLORS.$gray10Black;
                ctx.fillRect(posX, posY, width - posX, height);
                ctx.restore();
            });
        });
    }
};
