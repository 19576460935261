import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, COLORS, RIGHT_INDENTS, ROUTE_PATHS } from '@constants';
import { useStore } from '@hooks';
import { Button, Typography } from '@ui';
import { observer } from 'mobx-react';
import Header from '../Header';
import { StyledContent } from './styled';

const Empty = observer(({ title, message, tryAgain = true }) => {
    const store = useStore();
    const { getInsightsData } = store.insightsStore;
    const navigate = useNavigate();

    const handleHomeRedirect = () => {
        navigate(ROUTE_PATHS.HOME);
    };

    return (
        <div data-e2e='insights_page'>
            <Header />
            <StyledContent data-e2e='insights_empty_page'>
                <Typography.Title
                    data-e2e='insights_empty_description'
                    level={2}
                    align='center'
                    color={COLORS.$gray60Black}
                    style={BOTTOM_INDENTS.M}
                >
                    {title || 'Something went wrong'}
                </Typography.Title>
                <Typography.Text
                    data-e2e='insights_contact-support-or-try-again_description'
                    type='body2'
                    align='center'
                    color={COLORS.$gray60Black}
                    style={BOTTOM_INDENTS.M}
                >
                    {message ||
                        'Unfortunately, the analytics data server is not responding at the moment. Please try again later.'}
                </Typography.Text>
                <div>
                    {tryAgain ? (
                        <Button data-e2e='insights_try-again_btn' style={RIGHT_INDENTS.XXS} onClick={getInsightsData}>
                            Try again
                        </Button>
                    ) : null}
                    <Button data-e2e='insights_go-home_btn' type='primary' onClick={handleHomeRedirect}>
                        Back to Home
                    </Button>
                </div>
            </StyledContent>
        </div>
    );
});

export default Empty;
