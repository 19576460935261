import React from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { Typography } from '@ui';
import { StyledDescription, StyledLink, StyledText } from './styled';

const ProductCatalogModal = () => {
    return (
        <React.Fragment>
            <Typography.Text type='descriptor1' color={COLORS.$black} style={BOTTOM_INDENTS.M}>
                Import your product catalog to enable seamless management and campaign integration.
            </Typography.Text>
            <StyledText type='descriptor1' color={COLORS.$black} style={BOTTOM_INDENTS.M}>
                You can import products in 3 ways:
                <ul>
                    <li>
                        <b>
                            Webhooks (<i>Recommended</i>):
                        </b>{' '}
                        Use this method to dynamically manage your product catalog. This method allows for real-time
                        product updates, automatically adding or removing products to campaigns based on inventory
                        levels and campaign settings. See the{' '}
                        <StyledLink onClick={() => window.open('/docs/api#tag/webhook_products', '_blank')}>
                            Webhooks guide
                        </StyledLink>{' '}
                        for more information.
                    </li>
                    <li>
                        <b>API Endpoints:</b> Use this method to programmatically manage your product catalog. As this
                        method relies on <i>occasional</i> programmatic instructions, it is not recommended for ongoing
                        product management. See the{' '}
                        <StyledLink onClick={() => window.open('/docs/api#tag/products', '_blank')}>
                            Products resource
                        </StyledLink>{' '}
                        for more information.
                    </li>
                    <li>
                        <b>CSV Upload:</b> Use this method to upload your product catalog using a csv file. As this
                        method relies on <i>occasional</i> product uploads, it is not recommended for ongoing product
                        management. See the{' '}
                        <StyledLink onClick={() => window.open('/docs/api#tag/bulk_products', '_blank')}>
                            csv upload guide
                        </StyledLink>{' '}
                        for more information.
                    </li>
                </ul>
            </StyledText>
            <StyledDescription>
                <Typography.Text align='left' type='descriptor1'>
                    Once at least 1 product has been correctly imported, the status of this step will automatically
                    change to Connected.
                </Typography.Text>
            </StyledDescription>
        </React.Fragment>
    );
};

export default ProductCatalogModal;
