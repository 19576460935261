import axios from 'axios';
import config from '../config';

const axiosBoa = axios.create({
    baseURL: config.boaUrl,
    timeout: 30000,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});

axiosBoa.interceptors.response.use((response) => {
    if (response.config.fullResponse) {
        return response;
    }
    return response.data;
});

export { axiosBoa };
