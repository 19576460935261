import { COLORS } from '@constants';
import { PRODUCT_CONNECTION_STATUS, PRODUCT_ECOMMERCE_STATUS, PRODUCT_VALIDATION_STATUS } from '@enums';
import { getProductPrimaryImage } from '@utils';
import { types } from 'mobx-state-tree';
import { ProductImageModel } from './productModel';

export const CampaignModel = types.model({
    id: types.maybeNull(types.number),
    accountId: types.maybeNull(types.number),
    boostId: types.maybeNull(types.number),
    boost: types.maybeNull(
        types.model({
            price: types.maybe(types.number)
        })
    ),
    catalogType: types.maybeNull(types.number),
    commission: types.maybeNull(types.number),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    pausedAt: types.maybeNull(types.string),
    estimatedViews: types.maybeNull(types.number),
    priority: types.maybeNull(types.number),
    productsCount: types.maybeNull(types.number),
    retargetingViews: types.maybeNull(types.number),
    status: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    products: types.maybeNull(
        types.model({
            data: types.array(
                types
                    .model({
                        id: types.maybeNull(types.number),
                        title: types.maybeNull(types.string),
                        quantity: types.maybeNull(types.number),
                        connectionStatus: types.maybeNull(types.number),
                        ecommerceStatus: types.maybeNull(types.number),
                        validationStatus: types.maybeNull(types.number),
                        unitsSold: types.maybeNull(types.number),
                        rejectedReason: types.maybeNull(types.string),
                        hasValidVariants: types.maybeNull(types.boolean),
                        images: types.maybeNull(types.array(ProductImageModel)),
                        type: types.model({ id: types.number, title: types.maybe(types.string) }),
                        variants: types.array(
                            types.model({
                                sku: types.maybeNull(types.string)
                            })
                        )
                    })
                    .views((self) => ({
                        get status() {
                            if (self.ecommerceStatus === PRODUCT_ECOMMERCE_STATUS.DRAFT) {
                                return {
                                    text: 'Draft',
                                    color: COLORS.$gray40Black
                                };
                            }

                            if (!self.hasValidVariants) {
                                return {
                                    text: 'Out Of Stock',
                                    color: COLORS.$gray40Black
                                };
                            }

                            if (self.validationStatus === PRODUCT_VALIDATION_STATUS.PENDING) {
                                return {
                                    text: 'In Review',
                                    color: COLORS.$alert
                                };
                            }

                            if (self.validationStatus === PRODUCT_VALIDATION_STATUS.INVALID) {
                                return {
                                    text: 'Rejected',
                                    color: COLORS.$tartRed
                                };
                            }

                            if (self.connectionStatus === PRODUCT_CONNECTION_STATUS.NOT_CONNECTED) {
                                return {
                                    text: 'Accepted',
                                    color: COLORS.$robinBlue
                                };
                            }

                            return {
                                text: 'Connected',
                                color: COLORS.$robinBlue
                            };
                        },

                        get primaryImage() {
                            return getProductPrimaryImage(self.images);
                        }
                    }))
            ),
            meta: types.maybeNull(
                types.model({
                    page: types.maybeNull(types.number),
                    perPage: types.maybeNull(types.number),
                    total: types.maybeNull(types.number),
                    totalPages: types.maybeNull(types.number)
                })
            )
        })
    ),
    pending: types.optional(types.boolean, false),
    productsPending: types.optional(types.boolean, false),
    refetching: types.optional(types.boolean, false),
    isDeleting: types.optional(types.boolean, false)
});
