import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, COLORS, ROUTE_PATHS } from '@constants';
import { Button, Tag, Typography, WhiteCover } from '@ui';
import { Col, Row } from 'antd';

const AcceptPayments = () => {
    const navigate = useNavigate();

    return (
        <WhiteCover
            style={BOTTOM_INDENTS.M}
            title='Accept payments from customers'
            rightContent={
                <Row gutter={[16, 16]} align='middle' justify='center'>
                    <Col>
                        <Tag color={COLORS.$tartRed}>Not set up</Tag>
                    </Col>
                    <Col>
                        <Button size='small' type='link' onClick={() => navigate(ROUTE_PATHS.PAYMENTS_SETTINGS)}>
                            Edit
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Typography.Text type='body2' color={COLORS.$gray60Black}>
                Before your customer sales can be deposited into your bank account, you need to provide some additional
                information.
            </Typography.Text>
        </WhiteCover>
    );
};
export default AcceptPayments;
