import React, { Fragment, useEffect, useState } from 'react';
import { BOTTOM_INDENTS, COLORS } from '@constants';
import { useStore } from '@hooks';
import { Loader, Typography } from '@ui';
import { observer } from 'mobx-react';
import IntegrationBig from './components/IntegrationBig';
import IntegrationLoop from './components/IntegrationLoop';
import IntegrationRecharge from './components/IntegrationRecharge';
import IntegrationShopify from './components/IntegrationShopify';
import StoreSetupManual from './components/StoreSetupManual';

const Integrations = observer(() => {
    const store = useStore();
    const { isShopify, isManual, isBigCommerce } = store.accountsStore;

    const {
        rechargeRedirectUrl,
        isRechargeConnected,
        accountHasAllScopes,
        getRechargeRedirect,
        getRechargeToken,
        getLoopToken,
        handleLoopToken,
        loopAccessToken,
        isLoopConnected
    } = store.integrationsStore;

    const domain = store.accountsStore?.currentAccount?.domains[0]?.shopDomain || '';

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            await Promise.all([
                isShopify || isBigCommerce ? getRechargeToken() : null,
                (isShopify || isBigCommerce) && !rechargeRedirectUrl ? getRechargeRedirect() : null,
                isShopify ? getLoopToken() : null
            ]);

            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <Fragment>
            <Typography.Title data-e2e='integrations_page_title' level={2} style={BOTTOM_INDENTS.M}>
                Integrations
            </Typography.Title>
            <Typography.Text
                data-e2e='integrations_page_description'
                type='body2'
                color={COLORS.$gray60Black}
                style={BOTTOM_INDENTS.M}
            >
                Integrations let you streamline product upload, user tracking, and order fulfillment.
            </Typography.Text>
            {isManual && <StoreSetupManual />}
            {isBigCommerce && <IntegrationBig storeUrl={domain} isValid />}
            {isShopify && <IntegrationShopify storeUrl={domain} isValid={accountHasAllScopes} />}
            {(isShopify || isBigCommerce) && (
                <IntegrationRecharge isConnected={isRechargeConnected} redirectUrl={rechargeRedirectUrl} />
            )}
            {isShopify && (
                <IntegrationLoop isConnected={isLoopConnected} token={loopAccessToken} onSubmit={handleLoopToken} />
            )}
        </Fragment>
    );
});

export default Integrations;
