import React from 'react';
import { BOTTOM_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Card, Col, Row } from 'antd';
import * as get from 'lodash/get';
import { observer } from 'mobx-react';
import { tooltips } from '../../constants';
import BaselineInfo from '../BaselineInfo';
import CardTitle from '../CardTitle';
import BarChart from '../charts/BarChart';
import DoughnutChart from '../charts/DoughnutChart';
import LocationChart from '../charts/GeoChart';
import TopStates from '../charts/TopStates';
import TwoLevelVerticalBarChart from '../charts/TwoLevelVerticalBarChart';
import { StyledChartWrapper, StyledCol, StyledText, StyledWrapper } from './styled';

const DemographicsSection = observer(() => {
    const store = useStore();
    const { demographicsChartsData, usMapData } = store.insightsStore;

    return (
        <StyledWrapper>
            <Row style={BOTTOM_INDENTS.M}>
                <Col span={24}>
                    <Card data-e2e='insights_age_card' title={<CardTitle title='Age' tooltip={tooltips.age} />}>
                        <StyledChartWrapper data-e2e='insights_age_chart' $height={400} style={BOTTOM_INDENTS.M}>
                            <BarChart data={get(demographicsChartsData, 'age')} />
                        </StyledChartWrapper>
                        <BaselineInfo />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24} style={BOTTOM_INDENTS.M}>
                <Col span={12}>
                    <Card
                        data-e2e='insights_gender_card'
                        title={<CardTitle title='Gender' tooltip={tooltips.gender} />}
                    >
                        <StyledChartWrapper data-e2e='insights_gender_chart' $height={500}>
                            <DoughnutChart data={get(demographicsChartsData, 'gender')} />
                        </StyledChartWrapper>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        data-e2e='insights_ethnicity_card'
                        title={<CardTitle title='Ethnicity' tooltip={tooltips.ethnicity} />}
                    >
                        <StyledChartWrapper data-e2e='insights_ethnicity_chart' $height={500}>
                            <DoughnutChart data={get(demographicsChartsData, 'ethnicity')} />
                        </StyledChartWrapper>
                    </Card>
                </Col>
            </Row>
            <Row style={BOTTOM_INDENTS.M}>
                <Col span={24}>
                    <Card
                        data-e2e='insights_age-and-gender_card'
                        title={<CardTitle title='Age and Gender' tooltip={tooltips.ageGender} />}
                    >
                        <Row style={BOTTOM_INDENTS.M}>
                            <StyledCol span={1}>
                                <StyledText type='badgeMedium'>Female</StyledText>
                                <StyledText type='badgeMedium'>Male</StyledText>
                            </StyledCol>
                            <Col span={23}>
                                <StyledChartWrapper data-e2e='insights_age-and-gender_chart' $height={300}>
                                    <TwoLevelVerticalBarChart
                                        datasetTop={get(demographicsChartsData, 'ageFemale')}
                                        datasetBottom={get(demographicsChartsData, 'ageMale')}
                                    />
                                </StyledChartWrapper>
                            </Col>
                        </Row>
                        <Row>
                            <BaselineInfo />
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row gutter={24} style={BOTTOM_INDENTS.M}>
                <Col span={12}>
                    <Card
                        data-e2e='insights_top-5-states_table-card'
                        title={<CardTitle title='Top 5 States' tooltip={tooltips.topStates} />}
                    >
                        <StyledChartWrapper data-e2e='insights_top-5-states_table' $height={350}>
                            <TopStates data={get(demographicsChartsData, 'topStates')} />
                        </StyledChartWrapper>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        data-e2e='insights_top-5-states_locations-card'
                        title={<CardTitle title='Top 5 States' tooltip={tooltips.topStates} />}
                    >
                        <StyledChartWrapper data-e2e='insights_top-5-states_chart' $height={350}>
                            <LocationChart data={get(demographicsChartsData, 'location')} mapData={usMapData} />
                        </StyledChartWrapper>
                    </Card>
                </Col>
            </Row>
        </StyledWrapper>
    );
});

export default DemographicsSection;
