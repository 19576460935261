import React, { useRef, useState } from 'react';
import { ErrorMessage } from '@components';
import { useStore } from '@hooks';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Divider } from '@ui';
import { notification } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { CancelSave } from '../CancelSave';

export const PaymentForm = observer(({ returnUrl, onCancel }) => {
    const paymentTypeRef = useRef();
    const store = useStore();
    const { paymentMethodUpdate } = store.accountsStore;

    const stripe = useStripe();
    const elements = useElements();

    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const onElementsChange = (e) => {
        const availablePaymentTypes = ['card', 'us_bank_account'];

        const { type } = e.value;

        if (availablePaymentTypes.includes(type)) {
            paymentTypeRef.current = type;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) return;

        setLoading(true);

        const result = await stripe.confirmSetup({
            elements,
            confirmParams: { return_url: returnUrl },
            redirect: 'if_required'
        });

        if (result.error) {
            notification.error({ message: result.error.message });
            setError(result.error.message);
        } else {
            await paymentMethodUpdate({
                paymentMethodId: result.setupIntent.payment_method,
                paymentMethodType: paymentTypeRef.current,
                setupIntentId: result.setupIntent.id,
                setupIntentStatus: result.setupIntent.status,
                verificationLink: _.get(
                    result,
                    'setupIntent.next_action.verify_with_microdeposits.hosted_verification_url'
                )
            });
            notification.success({ message: 'Payment method added successfully' });
            onCancel(result.setupIntent.status);
        }

        setLoading(false);
    };

    const paymentElementOptions = {
        layout: 'tabs',
        wallets: { applePay: 'never', googlePay: 'never' }
    };

    return (
        <form id='payment-form' onSubmit={handleSubmit}>
            <PaymentElement id='payment-element' options={paymentElementOptions} onChange={onElementsChange} />
            {error && <ErrorMessage message={error} id='payment-message' />}
            <Divider margin='32px 0' />
            <CancelSave loading={loading} onCancel={onCancel} />
        </form>
    );
});
