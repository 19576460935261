import React from 'react';
import { BOTTOM_INDENTS, COLORS, TOP_INDENTS } from '@constants';
import { Icon, Typography } from '@ui';
import { StyledDescription } from './styled';

const TabHead = () => {
    return (
        <StyledDescription align='center' gap={24} style={{ ...BOTTOM_INDENTS.S, ...TOP_INDENTS.S }}>
            <Icon.AwaIcon width='110px' height='66px' />
            <Typography.Text type='body2' color={COLORS.$gray60Black}>
                AWA (Adaptive Web Assets) is an innovative, real-time AI system that adapts ad layouts daily for optimal
                campaign performance. You can view a list of potential designs below.
            </Typography.Text>
        </StyledDescription>
    );
};

export default TabHead;
